import { Spinner } from '@blueprintjs/core';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-react-router';
import React, { Component } from 'react';

import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { Toast } from '../components/SeesoBoard/Common';

import '../static/seesoboard/css/style.css';

import { AppStore } from '../store/AppStore';
import BulletinContainer from './BulletinContainer';

// import { Header, IHeaderProps } from '../components/Header';
// import { Bulletin, IBulletinProps } from "../components/Bulletin/Bulletin";
import FilterContainer from './FilterContainer';
import HeaderContainer from './HeaderContainer';
import SelectionContainer from './SelectionContainer';
import TaskDetailContainer from './TaskDetailContainer';
import TaskListContainer from './TaskListContainer';

// layout - container

interface IProps {
  groupId: string;
}
// interface IProps extends RouteComponentProps<IPageParams> {}
interface InjectedProps extends IProps {
  appStore: AppStore;
  routerStore: RouterStore;
}

@inject('appStore')
@inject('routerStore')
@observer
export default class SeesoBoard extends Component<IProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  get location() {
    return this.injected.routerStore.location;
  }

  get dashboardStore() {
    return this.injected.appStore.dashboardStore;
  }

  get boardStore() {
    return this.injected.appStore.boardStore;
  }

  get uiStore() {
    return this.injected.appStore.uiStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  @computed
  get currentTaskId() {
    return this.uiStore.selectedIds.length === 1
      ? this.uiStore.selectedIds[0]
      : undefined;
  }

  checkPermission = () => {
    // const { clientId, email } = this.userStore;
    // const { taskBoard } = this.boardStore;
    // const { projectGroup } = this.dashboardStore;

    // if (
    //   taskBoard
    //   && projectGroup
    //   && !email.endsWith("@seeso.kr")
    //   && projectGroup.client !== clientId
    // ) {
    //   this.uiStore.showToast(true, true, '접근 권한이 없습니다.');
    // }
    // 임시, 시소내부인력만 접근 허락. (xxx@seeso.kr)
    if (
      this.userStore.email.endsWith('@seeso.kr') ||
      this.userStore.email === 'violet.jeju@gmail.com'
    ) {
      this.boardStore.setHasPermission(true);
    } else {
      this.boardStore.setHasPermission(false);
    }
  };

  async componentDidMount() {
    // 프로젝트보드 랜더링 시, favicon 변경.
    let link = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    if (link) {
      link.href = `/pb-favicon.ico`;
    }

    const { groupId } = this.props;
    const { history } = this.injected.routerStore;

    // // 권한체크
    this.checkPermission();

    // 프로젝트그룹 정보 가져오기 + 권한 체크
    // TODO: board 멤버로 체킹하도록 수정 필요.
    // await this.dashboardStore.fetchById(groupId)
    // .then( e => {
    //   this.boardStore.setHasPermission(true);
    // })
    // .catch( e => {
    //   console.log('no permisstion for'+ groupId);
    //   console.log(this.injected.routerStore);
    //   this.boardStore.setHasPermission(false);
    //   alert('접근 권한이 없습니다.')
    //   history.push('/nopermission');
    // })

    if (this.boardStore.hasPermission) {
      // 프로젝트보드 정보 가져오기.
      try {
        await this.dashboardStore.fetchById(groupId);
      } catch {
        alert(
          '해당 프로젝트그룹의 멤버가 아닙니다.\n(일부 기능에 제한이 있을 수 있습니다. 시소메니저에게 문의하세요.)',
        );
      }

      await Promise.all([
        // await this.dashboardStore.fetchById(groupId),
        this.boardStore.fetchTaskBoard(groupId),
        this.boardStore.fetchTaskBulletin(groupId),
      ]);

      if (!this.boardStore.taskBoard) {
        alert('프로젝트 보드 정보가 없습니다.');
        this.injected.routerStore.history.push('/nopermission');
      }

      if (this.location) {
        const query = new URLSearchParams(this.location.search);
        const taskId = query.get('tk');
        const bulletinId = query.get('bl');
        const postId = query.get('po');

        taskId && this.uiStore.setReadTask(taskId);
        this.boardStore.setCurrentBulletinId(String(bulletinId));
        this.boardStore.setCurrentBulletinPostId(String(postId));
      }

      // 알림 가져오기 ( for. 알림 갯수 표시 )
      if (this.boardStore.taskBoard) {
        await Promise.all([
          this.injected.appStore.notificationStore.fetchBoardNotification(
            this.boardStore.taskBoard.taskBoardId,
          ),
        ]);
      }

      // 슬랙 설정값 가져오기
      // try {
      //   if(this.userStore.isPM) {
      //     await Promise.all([
      //       this.boardStore.slack.checkSlackInstalled(),
      //       this.boardStore.slack.fetchChannels(),
      //       this.boardStore.slack.fetchMembers(),
      //       this.boardStore.slack.fetchSettings()
      //     ])
      //   }
      // } catch (e) {}
    } else {
      alert('접근 권한이 없습니다.');
      history.push('/nopermission');
    }
  }

  private onDragEnd = async (result: DropResult) => {
    console.log(result);

    const { destination, draggableId, source, type } = result;

    if (!this.boardStore.taskBoard) return;
    if (!destination) return;

    if (type === 'LIST') {
      // 태스크목록 이동
      await this.boardStore.moveTaskList(draggableId, destination.index);

      return; // 걱 if문 마지막 return 팔요.
    }

    if (type === 'TASK') {
      // 태스크 이동
      await this.boardStore.moveTask(
        draggableId,
        source.droppableId,
        destination.droppableId,
        destination.index < 0 ? 0 : destination.index,
      );

      return;
    }

    if (type === 'BULLETIN') {
      //'자주보는리스트 이동'
      this.boardStore.changeBulletinPos(source.index, destination.index);
      await this.boardStore.moveTaskBulletin(
        Number(draggableId.replace(/\D/g, '')),
        destination.index,
      );

      return;
    }

    if (type === 'POST') {
      // '자주보는리스트 포스트 이동'
      const targetPostId = Number(draggableId.replace(/\D/g, ''));
      const srcBulletinId = Number(source.droppableId.replace(/\D/g, ''));
      const dstBulletinId = Number(destination.droppableId.replace(/\D/g, ''));

      await this.boardStore.moveTaskBulletinPost(
        targetPostId,
        dstBulletinId,
        destination.index,
      );

      await this.boardStore.fetchTaskBulletinByID(srcBulletinId);
      await this.boardStore.fetchTaskBulletinByID(dstBulletinId);

      return;
    }

    if (type === 'CHECKLIST') {
      // 태스크 카드 내, 체크리스트 이동
      const { taskForm, fetch, moveSubTaskList } =
        this.injected.appStore.taskStore;
      const { selectedIds } = this.injected.appStore.uiStore;
      const currentTaskId =
        selectedIds.length === 1 ? selectedIds[0] : undefined;
      const targetOrder = Number(draggableId.replace(/\D/g, ''));
      const targetList = taskForm.subTaskListForms.find(
        list => list.id === targetOrder,
      );

      if (!currentTaskId || !targetList) return;
      await moveSubTaskList(
        targetOrder,
        Number(source.index),
        Number(destination.index),
      );
      await fetch(Number(currentTaskId));

      return;
    }

    if (type === 'SUBCHECKLIST') {
      // 태스크 카드 내, 서브체크리스트 이동
      const { fetch, moveSubTask } = this.injected.appStore.taskStore;
      const { selectedIds } = this.injected.appStore.uiStore;
      const targetOrder = Number(draggableId.replace(/\D/g, ''));
      const currentTaskId =
        selectedIds.length === 1 ? selectedIds[0] : undefined;

      if (!currentTaskId) return;
      await moveSubTask(
        targetOrder,
        Number(source.droppableId.replace(/\D/g, '')),
        Number(destination.droppableId.replace(/\D/g, '')),
        source.index,
        destination.index,
      );
      await fetch(Number(currentTaskId));

      return;
    }

    console.log(`해당 타입의 드래그앤드랍 액션이 없습니다. : ${type}.`);
  };

  render() {
    const { taskBoard: board, hasPermission } = this.boardStore;

    return (
      <>
        {board && hasPermission ? (
          <div id="seeso-board">
            <DragDropContext onDragEnd={this.onDragEnd}>
              <HeaderContainer />

              {/* <hr /> */}

              <div className="wrapper">
                <BulletinContainer />

                <main id="snContent">
                  {!this.boardStore.taskMultiSelect.archivedMode &&
                  this.boardStore.taskMultiSelect.countTask > 0 ? (
                    <SelectionContainer />
                  ) : (
                    <FilterContainer />
                  )}

                  <TaskListContainer />

                  {this.uiStore.selectedCategory === 'TASK' &&
                    this.uiStore.selectedMode === 'READ' &&
                    this.currentTaskId && <TaskDetailContainer />}
                </main>
                {
                  <Toast
                    isShow={!!this.uiStore.toastMessage}
                    isNagative={this.uiStore.isNagativeToast}
                    manualClose={this.uiStore.isManualClose}
                    message={this.uiStore.toastMessage}
                    onClose={() => {
                      this.uiStore.closeToase();
                    }}
                  />
                }
              </div>
            </DragDropContext>
          </div>
        ) : (
          <Spinner className="seeso-board-spinner" />
        )}
      </>
    );
  }
}

import { types } from 'mobx-state-tree';
import moment from 'moment';

export interface IRufreeMatchingResponse {
  matched_time: string | null;
  id: number;
  manager: string;
  status: string;
  created_at: string;
  updated_at: string;
  role: string;
  sprint_num: number;
  date_due: string | null;
  date_request: string | null;
  matched_rufree: string | null;
  matched_rufree_name: string | null;
}

export const mapper = (x: IRufreeMatchingResponse) =>
  Object.assign(
    { ...x },
    {
      created_at: moment(x.created_at).toDate(),
      matched_time: x.matched_time ? moment(x.matched_time).toDate() : null,
      date_due: moment(x.date_due).toDate(),
      date_request: moment(x.date_request).toDate(),
      updated_at: moment(x.updated_at).toDate(),
      matched_rufree: x.matched_rufree ? x.matched_rufree : '',
      matched_rufree_name: x.matched_rufree_name ? x.matched_rufree_name : '',
    },
  );

export const RufreeMatchingModel = types
  .model('RufreeMatching', {
    id: types.identifierNumber,
    created_at: types.Date,
    date_due: types.maybeNull(types.Date),
    date_request: types.maybeNull(types.Date),
    manager: types.string,
    matched_rufree: types.string,
    matched_rufree_name: types.string,
    matched_time: types.maybeNull(types.Date),
    role: types.maybeNull(types.string),
    status: types.string,
    sprint_num: types.optional(types.number, 0),
    updated_at: types.Date,
  })
  .actions(self => ({}));

type RufreeMatchingType = typeof RufreeMatchingModel.Type;
export interface IRufreeMatching extends RufreeMatchingType {}

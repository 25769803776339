import styled from 'styled-components/macro';

export const PartnersWrapper = styled.main`
  table {
    @include sublay;
    position: relative;
    z-index: 2;
    margin-top: -77px !important;
    border-radius: 2px;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #f2f2f2;
    background-color: #fff;
    thead {
      tr {
        th {
          height: 77px;
          padding: 0 10px;
          font-size: 16px;
          background: rgba(251, 251, 252, 0.7);
          border-bottom: 1px solid #f4f5f7;
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 30px 10px;
          border-bottom: 1px solid #f4f5f7;
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    table {
      width: 95%;
      margin: 0 auto;
      //margin-top: 0 !important;
      thead {
        tr {
          th {
            height: auto;
            padding: 10px 5px;
            font-size: 12px;
            letter-spacing: -1px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 10px 5px;
            font-size: 12px;
            letter-spacing: -1px;
          }
        }
      }
    }
    .spacial-r {
      padding: 30px 20px;
      &:before {
        display: none;
      }
      .inner {
        h3 {
          font-size: 20px;
        }
      }
    }
    .hope-bnr {
      height: 150px;
      padding-top: 20px;
      background: {
        size: 100% auto;
        position: 0 90%;
      }
      .inner {
        padding: 0 20px;
        p {
          font-size: 16px;
        }
        a {
          width: 180px;
          height: 40px;
          margin-top: 15px;
          line-height: 40px;
        }
      }
    }
  }
`;

export const DataWrap = styled.div`
  position: relative;
  height: 950px;
  margin-top: -70px;
  padding-top: 270px;
  background-image: url('${require('../../../static/images/partners_bg.png')}'),
    linear-gradient(-90deg, #fff 0%, #f4f4f9 100%);
  background-repeat: no-repeat;
  background-position: 50% 100%;
  box-sizing: border-box;
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 275px;
    background: #5834ff;
  }
  .inner {
    width: 1100px;
    position: relative;
    margin: 0 auto;
    height: 100%;
    > p {
      color: #161d2e;
      font-size: 28px;
      font-weight: normal;
    }
    text-align: right;
    line-height: 1.6;
    em {
      font-weight: bold;
      font-style: normal;
    }
    div {
      position: absolute;
      z-index: 2;
      bottom: 100px;
      width: 100%;
      box-sizing: border-box;
      ul {
        @include clearfix;
        list-style: none outside;
        li {
          position: relative;
          float: left;
          width: 33.3%;
          padding-bottom: 30px;
          color: #fff;
          text-align: center;
          i {
            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            color: #9da6fc;
            font-size: 15px;
            transform: translate(-50%, 0);
            font-style: normal;
          }
          strong {
            font-size: 50px;
            letter-spacing: 3px;
            span {
              color: #9da6fc;
              font-size: 20px;
            }
          }
          &:before {
            content: '';
            display: block;
            width: 52px;
            height: 52px;
            margin: 0 auto -15px;
            background: url('${require('../../../static/images/data_img.png')}')
              no-repeat 8px 5px;
          }
          &:nth-child(2) {
            &:before {
              background-position: -64px 5px;
            }
          }
          &:nth-child(3) {
            &:before {
              background: url('${require('../../../static/images/m_data_img_white.png')}')
                no-repeat 7px 0;
              background-position: -137px 3px;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    height: 375px;
    margin-top: -60px;
    padding-top: 100px;
    background-size: auto 80%;
    background-position: 50% 3px;
    margin-bottom: -72px;
    &:after {
      content: '';
      display: block;
      position: relative;
      clear: both;
      bottom: 0;
      width: 100%;
      height: 0px;
      background: #5834ff;
    }
    .inner {
      width: 100%;
      p {
        padding-right: 20px;
        font-size: 16px;
        line-height: 1.4;
      }
      > div {
        bottom: 95px;
        ul {
          li {
            &:before {
              display: none;
            }
            i {
              font-size: 12px;
              letter-spacing: -1px;
              white-space: nowrap;
            }
            strong {
              display: block;
              margin-bottom: -12px;
              font-size: 25px;
              letter-spacing: 0;
              span {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

export const specialR = styled.div`
  position: relative;
  padding: 85px 0 90px;
  background: #fff;
  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 100%;
    height: 368px;
    background: url('${require('../../../static/images/spacial_bg.png')}')
      no-repeat 100% 0;
    transform: translate(-50%, 0);
  }
  .inner {
    width: 1100px;
    margin: 0 auto;
    h3 {
      color: #161d2e;
      font-size: 28px;
    }
    ul {
      padding-top: 30px;
      list-style: none outside;
      li {
        position: relative;
        margin-top: 25px;
        padding-left: 60px;
        color: #161d2e;
        font-size: 22px;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 0;
        }

        &:before {
          width: 32px;
          height: 32px;
          border: 2px solid #4b34ff;
          border-radius: 50%;
          box-sizing: border-box;
        }
        &:after {
          top: 8px;
          left: 12px;
          width: 6px;
          height: 10px;
          border-color: #4b34ff;
          border-style: solid;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
      @media only screen and (max-width: 640px) {
        padding-top: 10px;
        li {
          margin-top: 10px;
          padding-left: 30px;
          font-size: 16px;
          &:before {
            width: 20px;
            height: 20px;
          }
          &:after {
            top: 4px;
            left: 7px;
            width: 4px;
            height: 9px;
          }
        }
      }
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 640px) {
    padding: 85px 20px 90px;
    &:before {
      content: unset;
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 368px;
      background: url('${require('../../../static/images/spacial_bg.png')}')
        no-repeat 100% 0;
      transform: translate(-50%, 0);
    }
    ul {
      padding-top: 10px;
      li {
        margin-top: 10px;
        padding-left: 30px;
        font-size: 16px;
      }
      &:before {
        width: 20px;
        height: 20px;
      }
      &:after {
        top: 4px;
        left: 7px;
        width: 4px;
        height: 9px;
      }
    }
  }
`;

export const hopeBnr = styled.div`
  position: relative;
  height: 250px;
  padding: 45px 0;
  background: #5834ff url('${require('../../../static/images/hope_bg.png')}')
    no-repeat 50% 26px;
  box-sizing: border-box;
  .inner {
    width: 1100px;
    margin: 0 auto;
    p {
      font-size: 25px;
      color: #fff;
      line-height: 1.6;
      font-weight: bold;
      @media only screen and (max-width: 640px) {
        font-size: 22px;
      }
    }
    a {
      display: block;
      width: 210px;
      height: 51px;
      margin-top: 30px;
      border-radius: 3px;
      background-color: #fff;
      color: #5834ff;
      font-size: 15px;
      text-align: center;
      line-height: 51px;
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 640px) {
    padding: 45px 20px;
    text-align: center;
    height: 295px;
    p {
      font-size: 22px;
      word-break: keep-all;
    }
    a {
      margin: 0 auto;
    }
  }
`;

import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IDeposit } from '../../../store/Calculate/models/Deposit';
import { SprintReport } from '../../../store/Dashboard/models';
import { ISprintReportStore } from '../../../store/SprintReport';

import { Payouts as Comps } from '../../molecules/Calculate';
import SprintReportListPopup from './SprintReportListPopup';

interface Props {
  className?: string;
  includeVAT: boolean;
  deposits: IDeposit[];
  sprintReportStore: ISprintReportStore;
}

const Payouts: FC<Props> = observer(props => {
  const params = new URLSearchParams(useLocation().search);
  const selectedProject = params.get('project');
  const selectedSprint = params.get('sprint');

  const { sprintReportStore } = props;

  const [selectedReports, setSelectedReports] = useState<
    SprintReport.IReport[]
  >([]);

  const refs: any = props.deposits.reduce<any>((acc, value, index) => {
    acc[value.id] = React.createRef<React.RefObject<HTMLDivElement>>();
    return acc;
  }, {});

  const scroll = (id: string) => {
    if (refs[id].current) {
      refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const openReportPopup = async (projectGroupId: string, sprint: number) => {
    await sprintReportStore.fetchByProjectGroup(projectGroupId);

    const reports = sprintReportStore.getBySprint(sprint);
    if (reports.length > 0) {
      setSelectedReports(reports);
    } else {
      alert('아직 작성된 스프린트 보고서가 없습니다');
    }
  };

  useEffect(() => {
    if (props.deposits.length > 0 && selectedProject) {
      const selected = props.deposits.filter(
        deposit =>
          deposit.projectGroup === selectedProject &&
          deposit.sprints.includes(
            parseInt(selectedSprint ? selectedSprint : '-999'),
          ),
      );
      if (selected.length > 0) {
        scroll(String(selected[0].id));
      }
    }
  }, [refs]);
  // console.log( props.deposits)
  return (
    <Comps.Wrapper>
      {props.deposits
        .filter((deposit: IDeposit) => !deposit.allDeposit && deposit.dateIssue)
        .map((deposit, i) => (
          <Comps.Payout
            key={i}
            reff={refs[deposit.id]}
            includeVAT={props.includeVAT}
            deposit={deposit}
            handleOpenReportPopup={async () => {
              await openReportPopup(deposit.projectGroup, deposit.sprint);
            }}
          />
        ))}

      {props.deposits
        .filter(deposit => !deposit.allDeposit && !deposit.dateIssue)
        .map((deposit, i) => (
          <Comps.Payout
            key={i}
            reff={refs[deposit.id]}
            includeVAT={props.includeVAT}
            deposit={deposit}
            handleOpenReportPopup={async () => {
              await openReportPopup(deposit.projectGroup, deposit.sprint);
            }}
          />
        ))}

      {props.deposits
        .filter(deposit => deposit.allDeposit)
        .map((deposit, i) => (
          <Comps.Payout
            key={i}
            reff={refs[deposit.id]}
            includeVAT={props.includeVAT}
            deposit={deposit}
            handleOpenReportPopup={async () => {
              await openReportPopup(deposit.projectGroup, deposit.sprint);
            }}
          />
        ))}

      <SprintReportListPopup
        isOpen={selectedReports.length > 0}
        reports={selectedReports}
        handleClose={() => setSelectedReports([])}
      />
    </Comps.Wrapper>
  );
});

export default Payouts;

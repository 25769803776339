import { sortBy } from 'lodash';

import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';
import { Alert } from '../components/SeesoBoard/Common';
import * as Fields from '../components/SeesoBoard/Common/Fields';

import { AppStore } from '../store/AppStore';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class BulletinPostDetailContainer extends Component<IProps> {
  // @observable task: ITask | null = null;
  ref: React.RefObject<HTMLElement> | null = React.createRef();
  state = {
    showDeleteAlert: false,
  };

  get appStore() {
    return (this.props as InjectedProps).appStore;
  }

  @computed
  get bulletin() {
    const { currentBulletinId: bulletinId } = this.appStore.boardStore;
    return this.appStore.boardStore.taskBulletins.find(
      bulletin => bulletin.task_bulletin_id === bulletinId,
    );
  }

  @computed
  get post() {
    const { currentBulletinPostId: postId } = this.appStore.boardStore;
    const bulletin = this.bulletin;

    if (bulletin) {
      return bulletin.getPostByPostId(postId);
    }
    return undefined;
  }

  handleClick = (e: any) => {
    if (this.ref && this.ref.current) {
      if (this.ref.current?.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      this.appStore.boardStore.setCurrentBulletinPostId('');
    }
  };

  handleAddLinkClick = (e: any) => {
    this.post?.addEmptyLink();
  };

  onAddFileClick = (e: any) => {
    e.preventDefault();
    document.getElementById('upload-post-file')?.click();
  };

  render() {
    if (!this.post) {
      return <></>;
    }

    const {
      currentBulletinPostId,
      setCurrentBulletinPostId,
      updateBulletinPostFile,
    } = this.appStore.boardStore;
    const {
      id,
      task_bulletin_post_id,
      links,
      files,
      title,
      content,
      setContent,
      setTitle,
    } = this.post;
    const className = currentBulletinPostId ? 'dimd-layer' : '';

    return (
      <section ref={this.ref} className={className}>
        <div className="inner">
          <div className="task-detail">
            <div className="hgroup">
              <Fields.Title
                isEditable={true}
                title={title}
                onChange={async (title: string) => await setTitle(title)}
              />
              {/* <div className="tit">
                <h1>{ title }</h1>
              </div> */}
              <div className="menu">
                <button type="button">
                  <span>태스크 메뉴 열림</span>
                </button>
                <div>
                  <ul>
                    <li
                      onClick={() => {
                        let url = window.location.href.replace('#', '');
                        url =
                          url.indexOf('?') > 0
                            ? url.substring(0, url.indexOf('?'))
                            : url;
                        const link = `${url}?bl=${this.bulletin?.task_bulletin_id}&po=${task_bulletin_post_id}`;
                        let tempElem = document.createElement('textarea');
                        tempElem.value = link;
                        document.body.appendChild(tempElem);

                        tempElem.select();
                        document.execCommand('copy');
                        document.body.removeChild(tempElem);

                        this.appStore.uiStore.showToast(
                          false,
                          false,
                          '자주보는 아이템 공유 링크가 복사되었습니다.',
                        );
                      }}
                    >
                      <a href="#">아이템 공유</a>
                    </li>
                    <li
                      onClick={() => {
                        this.setState({ showDeleteAlert: true });
                      }}
                    >
                      <a href="#">아이템 삭제</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="task-input">
              <div className="cnts-w s-guide">
                <Fields.Description
                  description={content}
                  isEditable={true}
                  onChange={(content: string) => setContent(content)}
                />
                <div className="in-cnts file">
                  <h2>첨부 자료</h2>
                  <div className="btn">
                    <button type="button" onClick={this.handleAddLinkClick}>
                      링크
                    </button>
                    <button type="button" onClick={this.onAddFileClick}>
                      파일
                    </button>
                    <input
                      type="file"
                      id="upload-post-file"
                      hidden={true}
                      onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                        const fileList = e.currentTarget.files;
                        if (this.bulletin && fileList) {
                          this.appStore.uiStore.showToast(
                            false,
                            true,
                            '파일 업로드 중입니다.',
                          );
                          let i = 0;
                          while (i < fileList.length) {
                            // localize file var in the loop
                            var file = fileList[i];
                            console.log(file);
                            await updateBulletinPostFile(
                              {
                                postId: task_bulletin_post_id,
                                file: file,
                                bulletinId: this.bulletin.task_bulletin_id,
                              },
                              'post',
                            );
                            i++;
                          }

                          this.appStore.uiStore.closeToase();
                        }
                      }}
                    />
                  </div>
                  <div className="d-box">
                    <div className="file-list">
                      <ul>
                        {sortBy(files.slice(), ['id'], ['asc']).map(file => (
                          <Fields.File
                            key={file.id}
                            id={file.id}
                            filename={file.filename}
                            file={file.file}
                            date={file.updated_at}
                            onChangeFilename={async (filename: string) => {
                              this.bulletin &&
                                (await updateBulletinPostFile(
                                  {
                                    bulletinId: this.bulletin.task_bulletin_id,
                                    postId: task_bulletin_post_id,
                                    fileId: file.id,
                                    filename: filename,
                                  },
                                  'patch',
                                ));
                            }}
                            onDelete={() => {
                              this.bulletin &&
                                updateBulletinPostFile(
                                  {
                                    postId: task_bulletin_post_id,
                                    fileId: file.id,
                                    bulletinId: this.bulletin.task_bulletin_id,
                                  },
                                  'delete',
                                );
                            }}
                          />
                        ))}

                        {sortBy(links.slice(), ['id'], ['asc']).map(link => (
                          <Fields.Link
                            key={link.id}
                            id={link.id}
                            link={link.link}
                            date={link.updated_at}
                            handleUpdate={async (value: string) => {
                              if (!value) {
                                this.post?.removeEmptyLink();
                              } else {
                                if (value !== link.link) {
                                  if (link.id > 0) {
                                    // 수정
                                    await link.update(value);
                                  } else {
                                    // 생성
                                    // try {
                                    await link.create(value);
                                    // } catch(e) {
                                    //   if(e.status === 400 && e.data.link.length > 0) {
                                    //     this.appStore.uiStore.showToast(true, false, e.data.link[0]);
                                    //     this.post?.fetchSelf();
                                    //   }
                                    // }
                                  }
                                }
                              }
                            }}
                            handleDelete={async () => {
                              if (link.id > -1) {
                                await this.post?.removeLink(link.id);
                              }
                            }}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={() => {
                setCurrentBulletinPostId('');
              }}
            >
              <span>태스크 닫기</span>
            </button>
          </div>
        </div>

        <Alert
          isOpen={this.state.showDeleteAlert}
          onCancel={async () => {
            this.setState({ showDeleteAlert: false });
          }}
          onConfirm={async () => {
            if (this.bulletin) {
              await this.bulletin.removePost(id);
              setCurrentBulletinPostId('');
            }

            this.setState({ showDeleteAlert: false });
          }}
        >
          <p>
            선택한 아이템을
            <br /> 삭제하시겠습니까?
          </p>
        </Alert>
      </section>
    );
  }
}

import axios from 'axios';

import { SprintReport } from '../models';

export const getSprintReport = async (groupId: string) => {
  const { data }: { data: SprintReport.IResponse[] } = await axios.get(
    `/projectGroups/${groupId}/sprintReports`,
  );

  return data;
};

export const downloadSprintReport = async (
  groupId: string,
  reportId: number,
) => {
  const response = await axios.get(
    `/projectGroups/${groupId}/sprintReports/${reportId}/download`,
    {
      responseType: 'blob',
    },
  );

  return response;
};

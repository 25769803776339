import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';

const WIDTH = 405;

const Wrapper = styled.div`
  width: ${WIDTH}px;
  height: 15px;
  display: flex;
  flex-direction: row;

  div:first-child {
    border-radius: 7.5px 0px 0px 7.5px;
  }
  div:last-child {
    border-radius: 0px 7.5px 7.5px 0px;
  }

  .scheduled {
    width: 100%;
    background-color: #ffe007;
  }

  .ongoing {
    width: 100%;
    background-color: #1effc5;
  }

  .done {
    width: 100%;
    background-color: #9a9a9a;
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

interface ISprintBar {
  className?: string;
  doneCount: number;
  ongoingCount: number;
  totalCount: number;
}

const SprintBar: FC<ISprintBar> = ({
  className = '',
  doneCount = 0,
  ongoingCount = 0,
  totalCount = 0,
}) => {
  const scheduled_count = totalCount - ongoingCount - doneCount;

  return (
    <Wrapper className={className}>
      {scheduled_count > 0 &&
        [...Array(scheduled_count)].map((value, index) => (
          <div key={index} className="scheduled">
            &nbsp;
          </div>
        ))}

      {ongoingCount > 0 &&
        [...Array(ongoingCount)].map((value, index) => (
          <div key={index} className="ongoing">
            &nbsp;
          </div>
        ))}

      {doneCount > 0 &&
        [...Array(doneCount)].map((value, index) => (
          <div key={index} className="done">
            &nbsp;
          </div>
        ))}
    </Wrapper>
  );
};

export default SprintBar;

/*
 * 백앤드 REST 호출 response 관련 인터페이스는 interfaces.ts에 있습니다.
 * 백앤드 REST 호출 관련 로직은 apis.ts에 있습니다.
 */

import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { toJS } from 'mobx';
import { flow, getParent, types } from 'mobx-state-tree';

import { AppStore } from '../AppStore';
import { IUiStore } from '../UIStore';

import * as API from './apis';
import {
  TaskActivityPaginatedResponse,
  TaskBulletinPostResponse,
  TaskBulletinResponse,
  TaskResponse,
} from './interfaces';
import { BulletinPostFile } from './models/BulletinPostFile';
import { SlackModel } from './models/Slack';
import { TaskModel } from './models/Task';

import { TaskActivityModel } from './models/TaskActivity';
import { mapTaskBoard, TaskBoardModel } from './models/TaskBoard';
import { TaskBulletin, TaskBulletinModel } from './models/TaskBulletin';
import {
  TaskBulletinPost,
  TaskBulletinPostModel,
} from './models/TaskBulletinPost';
import { TaskFilterModel } from './models/TaskFilter';
import { TaskMultiSelectModel } from './models/TaskMultiSelect';

export const TaskManagerStoreModel = types
  .model('TaskManagerStore')
  .props({
    projectGroupId: types.maybe(types.string),
    hasPermission: types.maybe(types.boolean), // 해당 유저가 프로젝트보드 권한을 가지고 있는지
    taskBoard: types.maybe(TaskBoardModel),
    // TODO: Bulletin 스토어 별도 분리??
    taskBulletins: types.array(TaskBulletinModel),
    taskFilters: types.optional(TaskFilterModel, {}),

    taskEditing: types.optional(types.array(types.number), []),
    taskListEditing: types.optional(types.array(types.number), []),
    boardDraggable: types.optional(types.boolean, true),

    // 선택한 태스크 카드들 (편집)
    taskMultiSelect: types.optional(TaskMultiSelectModel, {}),

    // 선택한 태스크 카드 (상세보기)
    // TODO: formStore로 옮길까..?
    currentTaskListId: types.optional(types.string, ''),
    currentTaskId: types.optional(types.string, ''),
    // 선택한 자주보는리스트 아이템
    currentBulletinId: types.optional(types.string, ''),
    currentBulletinPostId: types.optional(types.string, ''),

    // 활동내역
    activities: types.array(TaskActivityModel),
    currentActivitiesPage: types.optional(types.number, 0),
    unReadNotificationCount: types.optional(types.number, 0),

    slack: types.optional(SlackModel, {}),
  })
  .views(self => ({
    get UIStore(): IUiStore | undefined {
      const rootStore = getParent(self);
      if (rootStore.hasOwnProperty('uiStore')) {
        return (rootStore as AppStore).uiStore;
      }
      return undefined;
    },

    get currentBulletins() {
      return self.taskBulletins;
    },
    get sortedBulletins() {
      return sortBy(self.taskBulletins.slice(), ['order']).reverse();
    },

    /*
     * 보관된 태스크
     */
    get archivedTasks() {
      return self.taskBoard
        ? self.taskBoard.taskLists
            .flatMap(taskList => taskList.tasks.slice())
            .filter(task => task.is_archived)
        : [];
    },

    /*
     * 활동내역
     */
    get activitiesByRecent() {
      return sortBy(self.activities.slice(), 'created_at').reverse();
    },
  }))
  .actions(self => ({
    setHasPermission(permission: boolean) {
      self.hasPermission = permission;
    },
    showInfoToast(message: string) {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.showToast(false, true, message ? message : '로딩중입니다.');
      }
    },

    closeInfoToast() {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.closeToase();
      }
    },
  }))
  .actions(self => {
    const setCurrentTaskId = (value: string) => {
      self.currentTaskId = value;
    };
    const setCurrentBulletinPostId = (value: string) => {
      self.currentBulletinPostId = value;
    };
    const setCurrentBulletinId = (value: string) => {
      self.currentBulletinId = value;
    };
    const setUnReadNotificationCount = (value: number) => {
      self.unReadNotificationCount = value;
    };
    // 수정 중 태스크, 태스크 목록 관련 함수 (D&D 제어용)
    const setDraggable = (value: boolean) => {
      self.boardDraggable = value;
    };
    const addEditingTaskId = (value: number) => {
      if (!self.taskEditing.includes(value)) {
        self.taskEditing.push(value);
      }
    };
    const delEditingTaskId = (value: number) => {
      const idx = self.taskEditing.indexOf(value);
      if (idx > -1) self.taskEditing.splice(idx, 1);
    };
    const addEditingTaskListId = (value: number) => {
      if (!self.taskListEditing.includes(value)) {
        self.taskListEditing.push(value);
      }
    };
    const delEditingTaskListId = (value: number) => {
      const idx = self.taskListEditing.indexOf(value);
      if (idx > -1) self.taskListEditing.splice(idx, 1);
    };
    const clearMultiSelectTaskIds = () => {
      self.taskMultiSelect.task_ids.clear();
      self.taskMultiSelect.person_in_charge_list.clear();
      self.taskMultiSelect.duedate = '';
      self.taskMultiSelect.label_title_list.clear();
      self.taskMultiSelect.sprint_title_list.clear();
    };

    const archiveTasks = flow(function* (taskIds: number[], is_archived) {
      const params = taskIds.map(id => ({
        task_id: id,
        is_archived: is_archived,
      }));
      if (self.taskBoard) {
        try {
          yield API.Task.archiveTasks(params);
        } catch (e) {
          throw e;
        }
      }
    });

    // 태스크 보드 관련 함수
    const createTaskBoard = flow(function* (projectGroupId: string) {
      try {
        yield axios.put(`/taskmgr/taskboard/create`, {
          project_group_id: projectGroupId,
        });
      } catch (e) {
        console.log('createTaskBoard error', e);
        throw e;
      }
    });
    const fetchTaskBoard = flow(function* (projectGroupId?: string) {
      // BO: 하나의 프로젝트그룹에 여러 보드가 생성될 수 있는 구조.
      // 현재는 첫번재 보드만 관리한다!
      try {
        self.projectGroupId = projectGroupId;
        self.showInfoToast(
          '프로젝트보드 로딩중입니다. ( 서버 응답속도 문제 임시알림 )',
        );
        const groupId = projectGroupId
          ? projectGroupId
          : self.taskBoard?.projectGroup;

        if (!groupId) return;

        const data = yield API.Board.getByGroupId(groupId);
        if (data.length > 0) {
          self.taskBoard = TaskBoardModel.create(mapTaskBoard(data[0]));
          try {
            self.unReadNotificationCount =
              data[0].notification_viewed.viewed_count;
          } catch (e) {
            console.error("Cannot read property 'viewed_count' of undefined");
          }
        }
      } catch (e) {
        console.error('fetchTaskBoard  error', e);
        throw e;
      } finally {
        self.closeInfoToast();
      }
    });

    const moveTaskList = flow(function* (
      targetListId: string,
      orderTo: number,
    ) {
      if (!self.taskBoard) return;

      const { taskLists, changeTaskListPosition, fetchTaskList } =
        self.taskBoard;

      const targetList = taskLists.find(
        list => list.task_list_id === targetListId,
      );

      if (!targetList || targetList.order === orderTo) return;

      changeTaskListPosition(targetList.order, orderTo);

      try {
        // self.showInfoToast('태스크 리스트 이동중입니다. ( 서버 응답속도 문제 임시알림 )')
        yield API.TaskList.moveTaskList(targetList.id, orderTo);
      } catch (e) {
        changeTaskListPosition(orderTo, targetList.order);
        yield Promise.all([
          fetchTaskList(targetList.task_list_id),
          // fetchTaskList(destinationList.task_list_id)
        ]);
      } finally {
        // self.closeInfoToast();
      }
    });

    const deleteTaskList = flow(function* (taskListId: string) {
      try {
        yield axios.delete(`/taskmgr/tasklist/${taskListId}`);
      } catch (e) {
        console.log('deleteTaskList error', e);
        throw e;
      }
    });

    // task 관련 함수
    const postTask = flow(function* (taskListId: string, title: string) {
      if (self.taskBoard) {
        try {
          const { data }: { data: TaskResponse } = yield axios.post(
            `/taskmgr/task`,
            {
              title: title,
              task_list_id: taskListId,
            },
          );

          const tasklist = self.taskBoard.taskLists.find(
            x => String(x.id) === taskListId,
          );
          tasklist &&
            tasklist.tasks.push(
              TaskModel.create({
                id: data.id,
                created_at: data.created_at,
                updated_at: data.updated_at,
                order: data.order,
                task_id: data.task_id,
                task_no: data.task_no,
                title: data.title,
                description: data.description,
                due_date: data.due_date,
                is_archived: data.is_archived,
                date_archived: data.date_archived,
                task_board: data.task_board,
                task_list: data.task_list,
                person_in_charge_list: data.person_in_charge_list,
                sprint_list: [],
                label_list: [],
                sub_task_lists: [],
                task_links: [],
                task_files: [],
                task_comments: [],
              }),
            );

          return data;
        } catch (e) {
          console.log('postTask error', e);
          throw e;
        }
      }
    });

    const moveTask = flow(function* (
      taskId: string,
      srcTaskListId: string,
      tgtTaskListId: string,
      orderTo: number,
    ) {
      if (!self.taskBoard) return;

      const { taskLists, moveTaskToList, fetchTaskList } = self.taskBoard;

      const sourceList = taskLists.find(
        list => list.task_list_id === srcTaskListId,
      );
      const destinationList = taskLists.find(
        list => list.task_list_id === tgtTaskListId,
      );
      const targetTask = sourceList?.tasks.find(
        task => task.task_id === taskId,
      );

      if (!sourceList || !destinationList || !targetTask) return;

      // 태스크 이동 처리
      const newTask = TaskModel.create(toJS(targetTask)); //0. 이동시킬 태스크 복사해서 새로 생성 후,
      newTask.setOrder(orderTo); // 1. 이동할 리스트 내 순서 설정하고,
      moveTaskToList(destinationList, newTask); // 2. 이동할 리스트 내 츄가.
      sourceList.removeTask(targetTask); // 3. 기존 태스크 제거, 추가 > 삭제 순서 중요 mobx

      try {
        yield axios.put(`/taskmgr/task/moved`, {
          task_id: targetTask.id,
          src_tasklist_id: sourceList.id,
          tgt_tasklist_id: destinationList.id,
          order_to: orderTo,
        });
      } catch (e) {
        // throw e;
      } finally {
        yield Promise.all([
          fetchTaskList(sourceList.task_list_id),
          fetchTaskList(destinationList.task_list_id),
        ]);
      }
    });

    const deleteTask = flow(function* (taskId: number) {
      try {
        yield API.Task.delete(taskId);
      } catch (e) {
        console.log('archiveTask error', e);
        throw e;
      }
    });

    const deleteTasks = flow(function* (ids: number[]) {
      try {
        yield API.Task.deleteTasks(ids);
      } catch (e) {
        throw e;
      }
    });

    // 운영정보목록, 항목 관련 함수
    const fetchTaskBulletin = flow(function* (projectGroupId: string) {
      try {
        const params = {
          search: projectGroupId,
        };
        const data: TaskBulletinResponse[] = yield API.TaskBulletin.get(params);

        self.taskBulletins.replace(
          data.map(x => TaskBulletinModel.create(mapTaskBulletin(x))),
        );
      } catch (e) {
        throw e;
      }
    });

    const fetchTaskBulletinByID = flow(function* (id: number) {
      // id로 특정 bulletin만 업데이트.
      try {
        const data: TaskBulletinResponse = yield API.TaskBulletin.getById(id);
        const newBulletin = TaskBulletinModel.create(mapTaskBulletin(data));
        const findIndex = self.taskBulletins.findIndex(
          bulletin => bulletin.id === newBulletin.id,
        );

        self.taskBulletins.replace([
          ...self.taskBulletins.slice(0, findIndex),
          newBulletin,
          ...self.taskBulletins.slice(findIndex + 1),
        ]);
      } catch (e) {
        throw e;
      }
    });

    const postTaskBulletin = flow(function* (
      taskBoardId: string,
      title: string,
    ) {
      try {
        const data: TaskBulletinResponse = yield API.TaskBulletin.post(
          taskBoardId,
          title,
        );
        return data;
      } catch (e) {
        throw e;
      }
    });

    const deleteTaskBulletin = flow(function* (id: number) {
      try {
        // yield axios.delete(`/taskmgr/bulletin/${id}`);
        yield API.TaskBulletin.delete(id);
      } catch (e) {
        throw e;
      }
    });

    const fetchTaskBulletinPost = flow(function* (id: number) {
      try {
        const { data }: { data: TaskBulletinPostResponse } = yield axios.get(
          `/taskmgr/bulletinpost/${id}`,
        );
        return TaskBulletinPostModel.create(mapTaskBulletinPost(data));
      } catch (e) {}
    });

    const putTaskBulletinPost = flow(function* (
      id: number,
      title: string,
      content: string,
    ) {
      try {
        const { data }: { data: TaskBulletinPostResponse } = yield axios.patch(
          `/taskmgr/bulletinpost/${id}`,
          {
            title: title,
            content: content,
          },
        );
        return data;
      } catch (e) {
        console.log('postTaskBulletinPost error', e);
        throw e;
      }
    });

    const changeBulletinPos = async (order_from: number, order_to: number) => {
      const bulletins = self.sortedBulletins;

      bulletins[order_from].order = order_to;
      if (order_from > order_to) {
        // 아래에서 위로 올라간 경우
        for (let i = order_to; i < order_from; i++) {
          bulletins[i].order = bulletins[i].order + 1;
        }
      } else {
        // 위에서 아래로 내려간 경우
        for (let i = order_from + 1; i <= order_to; i++) {
          bulletins[i].order = bulletins[i].order - 1;
        }
      }
      self.taskBulletins.replace(bulletins);
    };
    const changeBulletinPostPos = async (
      src_bulletin_id: number,
      dst_bulletin_id: number,
      order_from: number,
      order_to: number,
    ) => {
      // 깜빡거림을 막기 위해 모델 리프레시 전에 위치값을 조정해준다
      const bulletins = self.currentBulletins;
      const src_bulletin =
        self.currentBulletins[
          bulletins.findIndex(b => b.id === src_bulletin_id)
        ];
      const dst_bulletin =
        self.currentBulletins[
          bulletins.findIndex(b => b.id === dst_bulletin_id)
        ];

      if (src_bulletin === dst_bulletin) {
        // bulletin 내부에서 이동
        const bulletin_posts = dst_bulletin.sortedTaskBulletinPosts;

        bulletin_posts[order_from].order = order_to;
        if (order_from > order_to) {
          // 아래에서 위로 올라간 경우
          for (let i = order_to; i < order_from; i++) {
            bulletin_posts[i].order = bulletin_posts[i].order + 1;
          }
        } else {
          // 위에서 아래로 내려간 경우
          for (let i = order_from + 1; i <= order_to; i++) {
            bulletin_posts[i].order = bulletin_posts[i].order - 1;
          }
        }
        dst_bulletin.task_bulletin_posts.replace(bulletin_posts);
      } else {
        // bulletin 외부로 이동
        const src_bulletin_posts = src_bulletin.sortedTaskBulletinPosts;
        const dst_bulletin_posts = dst_bulletin.sortedTaskBulletinPosts;

        let src_post = src_bulletin_posts.splice(order_from, 1)[0];
        for (let i = order_from; i < src_bulletin_posts.length; i++) {
          src_bulletin_posts[i].order -= 1;
        }

        const dst_post = TaskBulletinPostModel.create({
          id: src_post.id,
          createdAt: src_post.createdAt,
          updatedAt: src_post.updatedAt,
          order: order_to,
          task_bulletin_post_id: src_post.task_bulletin_post_id,
          title: src_post.title,
          content: src_post.content,
          task_bulletin: dst_bulletin.task_bulletin_id,
          writer: src_post.writer,
          links: src_post.links.slice(),
          files: src_post.files.slice(),
        });

        dst_bulletin_posts.splice(order_to, 0, dst_post);
        for (let i = order_to + 1; i < dst_bulletin_posts.length; i++) {
          dst_bulletin_posts[i].order += 1;
        }

        src_bulletin.task_bulletin_posts.replace(src_bulletin_posts);
        dst_bulletin.task_bulletin_posts.replace(dst_bulletin_posts);
      }
    };

    const moveTaskBulletin = flow(function* (id: number, order_to: number) {
      try {
        yield axios.put(`/taskmgr/bulletin/${id}/moved`, {
          order_to: order_to,
        });
      } catch (e) {
        console.log('moveTaskBulletin error', e);
        throw e;
      }
    });
    const moveTaskBulletinPost = flow(function* (
      src_post_id: number,
      dst_bulletin_id: number,
      order_to: number,
    ) {
      try {
        yield API.TaskBulletinPost.move(src_post_id, dst_bulletin_id, order_to);
      } catch (e) {
        throw e;
      }
    });

    const updateBulletinPostFile = flow(function* (item: any, method: string) {
      //TODO: TaskBulletinPost 모델으로 이동.
      try {
        if (method == 'post') {
          const data = yield API.PostFile.create(item.postId, item.file);
          const taskBulletinPost = boundBulletinPost(
            String(item.bulletinId),
            item.postId,
          );
          taskBulletinPost && taskBulletinPost.files.replace(data);
        } else if (method == 'patch') {
          const bulletin = self.taskBulletins.find(
            bulletin => bulletin.task_bulletin_id === item.bulletinId,
          );
          if (!bulletin) return;

          const post = bulletin.task_bulletin_posts.find(
            post => post.task_bulletin_post_id === item.postId,
          );
          if (!post) return;

          const file = post.files.find(file => file.id === item.fileId);
          if (!file) return;

          const { data }: any = yield axios.patch(
            `/taskmgr/taskbulletinpostfile/${file.id}`,
            {
              filename: item.filename,
            },
          );
          file.filename = data.filename;
        } else if (method == 'delete') {
          const { data }: { data: BulletinPostFile[] } = yield axios.delete(
            `/taskmgr/taskbulletinpostfile/${item.fileId}`,
          );
          const taskBulletinPost = boundBulletinPost(
            String(item.bulletinId),
            item.postId,
          );
          taskBulletinPost && taskBulletinPost.files.replace(data);
        }
      } catch (e) {
        console.log('TaskManageStore=>updateBulletinPostLink error', e);
        throw e;
      }
    });

    const boundBulletinPost = (
      task_bulletin: string,
      task_bulletin_post_id: string,
    ): TaskBulletinPost | undefined => {
      console.log(task_bulletin, task_bulletin_post_id);
      const taskBulletin: TaskBulletin | undefined = self.taskBulletins.find(
        (bulletin: TaskBulletin) => {
          if (bulletin.task_bulletin_id === task_bulletin) return true;
        },
      );
      const taskBulletinPost: TaskBulletinPost | undefined =
        taskBulletin &&
        taskBulletin.task_bulletin_posts.find(
          bulletinPost =>
            bulletinPost.task_bulletin_post_id === task_bulletin_post_id,
        );

      return taskBulletinPost;
    };

    /*
     * 활동내역
     */
    const fetchActivities = flow(function* (page: number) {
      // self.currentActivitiesPage = page;
      if (self.taskBoard && self.currentActivitiesPage !== page) {
        try {
          const data: TaskActivityPaginatedResponse =
            yield API.TaskActivity.get(self.taskBoard.taskBoardId, page);

          if (data.results.length === 0) return;

          const activities = data.results
            .filter(x => x.message.length > 0)
            .map(x => TaskActivityModel.create(x));

          if (self.currentActivitiesPage === 1) {
            self.activities.replace(activities);
          } else {
            if (data.page !== self.currentActivitiesPage) {
              self.activities.replace(self.activities.concat(activities));
            }
          }
          self.currentActivitiesPage = data.page;
        } catch (e) {
          throw e;
        }
      }
    });

    return {
      setCurrentTaskId,
      setCurrentBulletinId,
      setCurrentBulletinPostId,
      setUnReadNotificationCount,
      // 수정 중 draggable 설정
      setDraggable,
      addEditingTaskId,
      delEditingTaskId,
      addEditingTaskListId,
      delEditingTaskListId,

      // 복수 선택
      clearMultiSelectTaskIds,

      // 검색, 필터

      // 태스크 보드
      createTaskBoard,
      fetchTaskBoard,

      // 태스크 목록
      moveTaskList,
      deleteTaskList,

      // 태스크
      postTask,
      moveTask,
      archiveTasks,
      deleteTask,
      deleteTasks,

      // 운영정보
      fetchTaskBulletin,
      fetchTaskBulletinByID,
      postTaskBulletin,
      deleteTaskBulletin,
      fetchTaskBulletinPost,
      putTaskBulletinPost,
      updateBulletinPostFile,
      changeBulletinPos,
      changeBulletinPostPos,
      moveTaskBulletin,
      moveTaskBulletinPost,

      // 활동 내역
      fetchActivities,
    };
  })
  .actions(self => ({
    afterCreate() {
      console.log('afterCreate');
    },
    afterAttach() {
      console.log('afterAttach');
    },
  }));

type TaskManagerStoreModelType = typeof TaskManagerStoreModel.Type;

export interface TaskManagerStore extends TaskManagerStoreModelType {}

export const mapTaskBulletinPost = (x: TaskBulletinPostResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    order: x.order,
    task_bulletin_post_id: x.task_bulletin_post_id,
    title: x.title,
    content: x.content,
    task_bulletin: x.task_bulletin,
    writer: x.writer,
    links: x.task_bulletin_post_links,
    files: x.task_bulletin_post_files,
  };
};

export const mapTaskBulletin = (x: TaskBulletinResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    order: x.order,
    task_bulletin_id: x.task_bulletin_id,
    title: x.title,
    taskBoardId: x.task_board,
    task_bulletin_posts: x.task_bulletin_posts
      ? x.task_bulletin_posts.map(mapTaskBulletinPost)
      : [],
  };
};

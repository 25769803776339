import React, { FC } from 'react';
import styled from 'styled-components/macro';

const StyledSkipNav = styled.div`
  a {
    position: absolute;
    left: -3000%;
  }

  :focus {
    display: block;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100%;
    height: 50px;
    line-height: 50px;
    background: #000;
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
`;

const SkipNav: FC = () => {
  return (
    <StyledSkipNav>
      <ul>
        <li>
          <a href="#snContent">본문바로가기</a>
        </li>
        <li>
          <a href="#snMenu">주메뉴 바로가기</a>
        </li>
      </ul>
    </StyledSkipNav>
  );
};

export { SkipNav };

import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { cast, flow, types } from 'mobx-state-tree';

import { APP_NAME } from '../utils/firebase/push-notification';

const _pushIfNotExist = (_to: string[], _value: string) => {
  const index = _to.indexOf(_value);
  if (index === -1) {
    _to.push(_value);
  }
};

const _removeIfExist = (_to: string[], _value: string) => {
  const index = _to.indexOf(_value);
  if (index > -1) {
    _to.splice(index, 1);
  }
};

export interface notiProjectSummariesResponse {
  receptionCount: number;
  inspectionsCount: number;
  pGCount: number;
  ongoingPgs: string[];
  pGFinishedSprintsCount: number;
}

export const mapper = (x: notiProjectSummariesResponse) => {
  return {
    receptionCount: x.receptionCount,
    inspectionsCount: x.inspectionsCount,
    pGCount: x.pGCount,
    ongoingPgs: x.ongoingPgs,
    pGFinishedSprintsCount: x.pGFinishedSprintsCount,
  };
};
export const notiProjectSummariesModel = types.model(
  'notiProjectSummariesModel',
  {
    receptionCount: types.maybeNull(types.number),
    inspectionsCount: types.maybeNull(types.number),
    pGCount: types.maybeNull(types.number),
    ongoingPgs: types.array(types.string),
    pGFinishedSprintsCount: types.maybeNull(types.number),
  },
);

export const TaskBoardNotiModel = types.model('TaskBoardNotiModel', {
  cmd: types.array(types.string),
  date_created: types.string,
  date_read: types.maybeNull(types.string),
  deleted: types.maybeNull(types.boolean),
  id: types.number,
  parameter: types.string,
  parameter_json: types.model('BoardNotiParameterModel', {
    notification_data: types.model('BoardNotiDataModel', {
      body_template: types.string,
      event: types.string,
      task_board_id: types.string, // "TB000004"
      task_id: types.maybe(types.number), // 446
    }),
  }),
  related_app_name: types.string,
  related_key: types.string,
});

export const NotificationStoreModel = types
  .model('NotificationStore', {
    inspectionNewCount: types.optional(types.number, 0),
    receptionIds: types.array(types.string),
    dashboardIds: types.array(types.string),
    calculateIds: types.array(types.string),
    notiProjectSummaries: types.optional(notiProjectSummariesModel, {}),

    boardNotifications: types.array(TaskBoardNotiModel),
  })
  .views(self => ({
    get boardNotificationsByRecent() {
      return sortBy(self.boardNotifications, 'date_created').reverse();
    },
  }))
  .actions(self => ({
    setInspectionNewCount(val: number) {
      self.inspectionNewCount = val;
    },
    /*
     * BroadcastChannel 리스너에서 받은 실시간 웹 노티 추가 루틴
     * TODO: 하나의 리스너로 변경
     */
    newReceptionNoti(noti: string) {
      _pushIfNotExist(self.receptionIds, noti);
    },
    newDashboardNoti(noti: string) {
      _pushIfNotExist(self.dashboardIds, noti);
    },
    newCalculateNoti(noti: string) {
      _pushIfNotExist(self.calculateIds, noti);
    },
  }))
  .actions(self => {
    const fetchNotiProjectSummaries = flow(function* () {
      try {
        const { data }: { data: any } = yield axios.get(
          `notifications/projects/summaries`,
        );
        self.notiProjectSummaries = cast(mapper(data));
      } catch (e) {
        console.log('fetchNotiProjectSummaries error', e);
        throw e;
      }
    });

    const fetchNotification = flow(function* () {
      try {
        const { data }: { data: any } = yield axios.get('/notificationLog', {
          params: {
            // related_app_name: related_app_name,
            cmd: 'read_check',
            date_read: true,
          },
        });
        // 초기화
        self.inspectionNewCount = 0;

        for (const noti of data) {
          /*
           * noti.related_app_name: 앱 구분자
           * noti.related_key: 앱 내 아이템 구분을 위한 각 아이템의 고유 id.
           */

          switch (noti.related_app_name) {
            case APP_NAME.project_inspection:
              // 검수서 노티 분배
              self.inspectionNewCount += 1;
              break;
            case APP_NAME.project_reception:
              // 의뢰 노티 분배
              _pushIfNotExist(self.receptionIds, noti.related_key);
              break;
            case APP_NAME.project_dashboard:
              // 대시보드 노티 분배
              _pushIfNotExist(self.dashboardIds, noti.related_key);
              break;
            case APP_NAME.project_calculate:
              // 정산 노티 분배
              _pushIfNotExist(self.calculateIds, noti.related_key);
              break;
            default:
              break;
          }
        }

        return data;
      } catch (e) {
        throw e;
      }
    });

    const fetchBoardNotification = flow(function* (boardId: string) {
      try {
        const { data }: { data: any } = yield axios.get('/notificationLog', {
          params: {
            related_app_name: APP_NAME.taskmgr_notification,
            cmd: 'read_check',
            related_key: boardId,
          },
        });
        // 초기화
        self.boardNotifications.replace([]);

        data
          .filter((d: any) => d.hasOwnProperty('parameter_json'))
          .map((d: any) => self.boardNotifications.push(d));
      } catch (e) {
        throw e;
      }
    });

    const readNotification = flow(function* (
      related_app_name: string,
      related_key: string,
    ) {
      try {
        const { data }: { data: any } = yield axios.put(
          '/notificationLog/read_logs',
          {
            read_list: [
              {
                related_app_name: related_app_name,
                related_key: related_key,
                // date_read: true
              },
            ],
          },
        );

        switch (related_app_name) {
          case APP_NAME.project_inspection:
            break;
          case APP_NAME.project_reception:
            console.log('remove 되려나요! ', related_key);
            _removeIfExist(self.receptionIds, related_key);
            break;
          case APP_NAME.project_dashboard:
            _removeIfExist(self.dashboardIds, related_key);
          case APP_NAME.project_calculate:
            _removeIfExist(self.calculateIds, related_key);
          default:
            break;
        }
      } catch (e) {
        throw e;
      }
    });

    const readBoardNotification = flow(function* (id: number) {
      const notification = self.boardNotifications.find(
        notification => notification.id === id,
      );

      if (!notification) return;

      try {
        const { data }: { data: any } = yield axios.put(
          '/notificationLog/read_logs',
          {
            read_list: [
              {
                id: id,
              },
            ],
          },
        );

        // self.boardNotifications.splice(index, 1);
        notification.date_read = new Date().toString();
      } catch (e) {
        throw e;
      }
    });

    const deleteBoardNotification = flow(function* (id: number) {
      const itemIndex = self.boardNotifications.findIndex(
        notification => notification.id === id,
      );

      if (itemIndex < 0) return;

      try {
        const { data }: { data: any } = yield axios.delete(
          `/notificationLog/${id}`,
        );
        self.boardNotifications.replace([
          ...self.boardNotifications.slice(0, itemIndex),
          ...self.boardNotifications.slice(itemIndex + 1),
        ]);
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchNotiProjectSummaries,
      fetchNotification,
      readNotification,

      readBoardNotification,
      deleteBoardNotification,
      fetchBoardNotification,
    };
  });

type NotificationModelType = typeof NotificationStoreModel.Type;

export interface Notification extends NotificationModelType {}

import React, { Component } from 'react';

import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';

const Wrapper = styled.div``;
const Label = styled.label`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['greyish-brown']};
  cursor: pointer;
`;

const LabelSpan = styled.span`
  line-height: 20px;
`;

const Button = styled.input.attrs({ type: 'radio' })`
  display: none;

  :checked + span {
    transition: 300ms;
    border: 1px solid ${PALETTE['purpleish-blue']};
    background-color: ${PALETTE['purpleish-blue']};
  }
`;

const PseudoButton = styled.span`
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: top;
  margin-top: 3px;
  margin-left: 10px;
  border-radius: 3px;
  border: 1px solid ${PALETTE['greyish-brown']};
`;

interface CheckButtonProps {
  name: string;
  value: string;
  checked: boolean;
  readonly: boolean;
  onChange: (value: boolean) => void;
}

class CheckButton extends Component<CheckButtonProps> {
  static defaultProps = {
    checked: false,
    readonly: false,
  };

  render() {
    return (
      <Wrapper>
        <Label>
          <LabelSpan>{this.props.value}</LabelSpan>
          <Button
            name={this.props.name}
            value={this.props.value}
            checked={this.props.checked}
            onChange={(e: any) => {
              if (!this.props.checked && !this.props.readonly) {
                this.props.onChange(true);
              }
            }}
            onClick={(e: any) => {
              if (this.props.checked && !this.props.readonly) {
                this.props.onChange(false);
              }
            }}
          />
          <PseudoButton />
        </Label>
      </Wrapper>
    );
  }
}

export default CheckButton;

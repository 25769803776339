import React, { useEffect, useRef, useState } from 'react';

export interface IAddTaskProps {
  isShow: boolean;
  onAddTask?: (title: string) => void;
  onCloseTaskField: () => void;
  // onShowTaskField: () => void;
}

export const AddTask: React.SFC<IAddTaskProps> = props => {
  const inpuRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState(true);
  // const [ isAluralEditing, setIsAluralEditing] = useState(false);

  const [title, setTitle] = useState('');
  // const [ titles, setTitles ] = useState('');

  // 단일 태스트 생성
  const onBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (document.activeElement !== inpuRef.current) {
      props.onAddTask &&
        props.onAddTask((e.target as HTMLTextAreaElement).value);

      setTitle('');
      // setIsEditing(false);
      props.onCloseTaskField();
    }
  };
  const onChange = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      props.onAddTask &&
        props.onAddTask((e.target as HTMLTextAreaElement).value);

      setTitle('');
      // setIsEditing(false);
      props.onCloseTaskField();
    }

    if (e.key === 'Escape') {
      setTitle('');
      // setIsEditing(false);
      props.onCloseTaskField();
    }
  };
  // 단일 태스트 생성 END

  // 복수 태스트 생성
  // const onChangeAlural = (e: React.FocusEvent<HTMLTextAreaElement>) => {
  //   setTitles(e.target.value);
  // }
  // 복수 태스트 생성 END

  // const onDocumentClick = (e: MouseEvent) => {
  //   // console.log(e.composedPath().find( path => (path as HTMLElement).className?.includes('add-task-input')));
  //   const clickedTarget = e.composedPath().find( path => (path as HTMLElement).className?.includes('add-task-input'));

  //   if(clickedTarget) return;

  //   if(isEditing) {
  //     if(title) {
  //       props.onAddTask && props.onAddTask(title);

  //       setTitle('');
  //       setIsEditing(false);
  //     }
  //   }
  // };

  useEffect(() => {
    // document.addEventListener("mousedown", onDocumentClick);
    // setIsEditing(props.isShow)
  }, []);

  return (
    <>
      {isEditing && (
        // <section className="card-wrap">
        //   <div className="inner add-task-input">
        //     <input
        //       ref={ inpuRef }
        //       type="text"
        //       placeholder="태스크 타이틀"
        //       autoFocus={ true }
        //       value={ title }
        //       onBlur={ onBlur }
        //       onChange={ onChange }
        //       onKeyDown={ onKeyPress }
        //       />
        //   </div>
        // </section>

        <div className="tit-add">
          <textarea
            placeholder="태스크 타이틀"
            autoFocus={true}
            value={title}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyPress}
          ></textarea>
        </div>
      )}
    </>
  );
};

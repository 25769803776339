import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

export const initializeFirebaseConfig = async () => {
  firebase.initializeApp(firebaseConfig);
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('token:', token);

    return messaging;
  } catch (error) {
    console.error(error);
  }
};

export const EVENT = {
  taskmgr: {
    TaskList_reload: 'TaskList_reload',
  },
  project: {
    Calculate_updated: 'Calculate_updated',
    Inspection_published_updated: 'Inspection_published_updated',
    ProjectGroup_updated: 'ProjectGroup_updated',
    Reception_Approved: 'Reception_Approved',
    Reception_Rejected: 'Reception_Rejected',
  },
};
export const APP_NAME = {
  taskmgr_notification: 'taskmgr_notification',
  project_inspection: 'project_inspection',
  project_reception: 'project_reception',
  project_dashboard: 'project_dashboard',
  project_calculate: 'project_calculate',
};

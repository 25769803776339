import styled from 'styled-components/macro';
import { PC, TABLET } from '../../../constants/media';

import ScrollSVG from '../../../static/icons/icon-scroll.svg';

export const Wrapper = styled.div`
  .bp3-spinner {
    margin: 0 auto;
    width: 100%;
    min-height: 500px;
    @media only screen and (max-width: ${TABLET}) {
      min-height: 380px;
    }
  }
`;
export const PageWrapper = styled.div`
  width: 1440px;
  margin: 0 auto;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

export const ScrollIcon = styled.div<{ toUp?: boolean }>`
  display: none;
  @media only screen and (max-width: ${PC}) {
    display: block;
  }
  background: url(${ScrollSVG}) no-repeat center center;

  position: fixed;
  width: 40px;
  height: 40px;
  z-index: 10;
  cursor: pointer;
  right: 15px;
  bottom: 40px;
  transform: rotate(${p => (p.toUp ? 0 : 180)}deg);
`;

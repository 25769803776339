import { types } from 'mobx-state-tree';

/*
 * Response interface
 */
export interface IInspectionRufreeRoleResponse {
  role: string;
  sprints: boolean[];
  wage: number;
}

/*
 * mapper
 */
export const mapper = (resp: IInspectionRufreeRoleResponse) => {
  return {
    role: resp.role,
    wage: resp.wage,
    sprints: resp.sprints,
  };
};

/*
 * Model
 */
export const InspectionRufreeRoleModel = types
  .model('InspectionRufreeRole', {
    role: types.string,
    wage: types.number,
    sprints: types.array(types.boolean),
  })
  .views(self => ({}));
type InspectionRufreeRoleType = typeof InspectionRufreeRoleModel.Type;
export interface IInspectionRufreeRole extends InspectionRufreeRoleType {}

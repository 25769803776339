import { groupBy, sortBy } from 'lodash';
import { getParent, types } from 'mobx-state-tree';
import moment from 'moment';

import { EProjectStatus } from '../constants';
import { IProjectGroup } from './ProjectGroup';

export interface ISprintResponse {
  id: number;
  payment: number;
  commission: number;
  calculate_payment: number;
  calculate_commission: number;
  service_fee: number;
  status: string;
  rufree: string;
  rufree_name: string;
  role: string;
  manager: string;
  sprint: number;
  date_start: string;
  date_end: string;
  expected_date_end: string;
  date_calculate: string;
  comment: string;
}

export const mapperRufreeOfRole = (x: ISprintResponse) => {
  const today = moment();
  const dateEnd = moment(x.date_end); // 실제 종료처리일
  const dateExpectedEnd = moment(x.expected_date_end); // 운영일정 종료일
  const dateCalculate = moment(x.date_calculate);
  const dateStart = moment(x.date_start);
  const status =
    dateEnd.isValid() && dateEnd.isBefore(today)
      ? EProjectStatus.END
      : EProjectStatus.ONGOING;

  return {
    dateEnd: dateEnd.isValid() ? dateEnd.toDate() : null,
    dateExpectedEnd: dateExpectedEnd.isValid()
      ? dateExpectedEnd.toDate()
      : null,
    datePayout: dateCalculate.isValid() ? dateCalculate.toDate() : null,
    dateStart: dateStart.isValid() ? dateStart.toDate() : null,
    role: x.role,
    rufreeId: x.rufree,
    rufreeName: x.rufree_name,
    status: status,
  };
};
export const RufreeOfRoleModel = types
  .model('RufreeOfRole', {
    dateEnd: types.maybeNull(types.Date),
    dateExpectedEnd: types.maybeNull(types.Date),
    datePayout: types.maybeNull(types.Date),
    dateStart: types.maybeNull(types.Date),
    role: types.string,
    rufreeId: types.string,
    rufreeName: types.string,
    status: types.string,
  })
  .actions(self => ({
    setDateEnd(date: Date | null) {
      self.dateEnd = date;
    },
    setDateExpectedEnd(date: Date | null) {
      self.dateExpectedEnd = date;
    },
    setDatePayout(date: Date | null) {
      self.datePayout = date;
    },
    setDateStart(date: Date | null) {
      self.dateStart = date;
    },
    setRufreeId(rufreeId: string) {
      self.rufreeId = rufreeId;
    },
    setRufreeName(name: string) {
      self.rufreeName = name;
    },
    setStatus(status: string) {
      self.status = status;
    },
  }));
type RufreeOfRoleType = typeof RufreeOfRoleModel.Type;
export interface IRufreeOfRole extends RufreeOfRoleType {}

export const mapper = (responses: ISprintResponse[]) => {
  const groupsBySprint = groupBy(
    sortBy(responses, response => response.sprint),
    response => response.sprint,
  );

  return Object.entries(groupsBySprint).map(([sprintNum, sprints]) => {
    const dateStart = moment.min(
      sprints.map(sprint => moment(sprint.date_start)),
    );
    const dateEnd = moment.max(
      sprints.map(sprint => moment(sprint.expected_date_end)),
    );

    return SprintModel.create({
      dateStart: dateStart.isValid() ? dateStart.toDate() : null,
      dateEnd: dateEnd.isValid() ? dateEnd.toDate() : null,
      rufrees: sprints.map(sprint => mapperRufreeOfRole(sprint)),
      sprint: parseInt(sprintNum),
    });
  });
};

export const SprintModel = types
  .model('Sprint', {
    dateStart: types.maybeNull(types.Date),
    dateEnd: types.maybeNull(types.Date),
    rufrees: types.array(RufreeOfRoleModel),
    sprint: types.number,
  })
  .views(self => ({
    get projectGroup(): IProjectGroup {
      return getParent(self, 3);
    },
  }))
  .views(self => ({
    get title() {
      if (self.sprint === 0) {
        return 'PRE';
      }
      return `S${self.sprint}`;
    },

    get roles() {
      return self.rufrees.map(rufree => rufree.role);
    },

    get dueDateHold(): Date | null {
      return self.projectGroup.dueDateHold;
    },

    get status() {
      const today = moment();

      if (
        self.rufrees.every(
          rufree => !!rufree.dateEnd && moment(rufree.dateEnd).isBefore(today),
        )
      ) {
        return EProjectStatus.END;
      }

      const { projectGroup } = self;
      if (projectGroup) {
        if (projectGroup.status === EProjectStatus.HOLDING) {
          return EProjectStatus.HOLDING;
        }
      }

      if (!self.dateStart || moment(self.dateStart).isAfter(today)) {
        return EProjectStatus.SCHEDULE;
      }

      return EProjectStatus.ONGOING;
    },
  }))
  .actions(self => ({
    setDateStart(date: Date | null) {
      self.dateStart = date;
    },
    setDateEnd(date: Date | null) {
      self.dateEnd = date;
    },
  }));

type SprintType = typeof SprintModel.Type;
export interface ISprint extends SprintType {}

import { Dialog as bp3_Dialog } from '@blueprintjs/core';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import MobilCloseSvg from '../../../static/icons/btn-mo-modalclose.svg';

import CloseSvg from '../../../static/icons/icon-close-white.svg';
import FileSvg from '../../../static/icons/icon-file-blue.svg';

export const BP3Dialog = styled(bp3_Dialog)`
  background: #ffffff;
  border-radius: 0px;
  box-shadow: none;

  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  width: 785px;
  height: fit-content;

  position: relative;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    margin: 70px 16px 330px 16px;
  }
`;

export const Header = styled.div`
  height: 83px;
  padding-left: 40px;
  background-color: ${PALETTE['color-emerald']};
  line-height: 83px;
  font-size: 18px;
  font-weight: bold;
  div {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${PC}) {
    height: 67px;
    padding: 20px 0px 0px 16px;
    line-height: 27px;
    font-size: 18px;
  }
`;
export const Body = styled.div`
  padding: 35px 40px 0px 40px;

  @media only screen and (max-width: ${PC}) {
    padding: 30px 16px 0 16px;
    position: relative;
  }
`;
export const Footer = styled.div``;

export const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 64px;
  line-height: 64px;
  font-size: 13px;
  text-align: right;
  color: ${PALETTE['brownish-grey']};
  border-bottom: solid 1px ${PALETTE['very-light-pink-two']};

  div:first-child {
    font-size: 15px;
    color: ${PALETTE['purpleish-blue']};
    cursor: pointer;
    padding-left: 22px;
    &::before {
      content: '';
      background: url('${FileSvg}') no-repeat center center;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 25px;
      left: 0;
    }
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    text-align: left;
    height: fit-content;
    padding-bottom: 20px;
    div {
      height: fit-content;
      line-height: 28px;
    }

    div:first-child {
      &::before {
        top: 7px;
      }
    }
  }
`;

export const CloseButton = styled.div`
  background: url('${CloseSvg}') no-repeat center center;
  position: absolute;
  left: 805px;
  top: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media only screen and (max-width: ${PC}) {
    width: 53px;
    background: url('${MobilCloseSvg}') no-repeat center center;

    top: auto;
    bottom: -33px;
    left: auto;
    right: 16px;
  }
`;

import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ICalculateStore } from '../../../store/Calculate';
import { EDepositType, EPayoutType } from '../../../store/Calculate/constants';

import { PayoutFilter as Comps } from '../../molecules/Calculate';

interface Props {
  className?: string;
  store: ICalculateStore;
}

const PayoutFilters: FC<Props> = observer(props => {
  return (
    <Comps.Wrapper>
      <Comps.DivFilter
        title="상태"
        value={props.store.filterDeposit}
        options={Object.values(EDepositType)}
        handleChange={(value: string) => {
          props.store.setFilterDeposit(value);
        }}
      />

      <Comps.DivFilter
        title="지출 종류"
        value={props.store.filterPayout}
        options={Object.values(EPayoutType)}
        handleChange={(value: string) => {
          props.store.setFilterPayout(value);
        }}
      />
    </Comps.Wrapper>
  );
});

export default PayoutFilters;

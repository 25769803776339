import React from 'react';

export interface ICloseButtonProps {
  handleClick: () => void;
}

export const CloseButton: React.SFC<ICloseButtonProps> = props => {
  return (
    <button type="button" className="btn-close" onClick={props.handleClick}>
      <span>태스크 닫기</span>
    </button>
  );
};

import React, { useEffect, useState } from 'react';

export interface ITitleProps {
  isEditable: boolean;
  title: string;
  onChange?: (title: string) => void;
}

export const Title: React.SFC<ITitleProps> = props => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(props.title);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    e.currentTarget.setSelectionRange(title.length, title.length);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      setTitle((e.target as HTMLTextAreaElement).value);
      onSave();
    }
  };
  const onSave = () => {
    title !== props.title && props.onChange && props.onChange(title);
    setIsEditing(false);
  };

  return (
    <div className="tit">
      {isEditing && (
        <textarea
          autoFocus={true}
          title="타이틀 수정"
          value={title}
          onFocus={onFocus}
          onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => {
            setTitle((e.target as HTMLTextAreaElement).value);
            onSave();
          }}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setTitle(e.target.value)
          }
          onKeyPress={onKeyPress}
        />
      )}
      <h1 onClick={() => setIsEditing(true)}>{props.title}</h1>
    </div>
  );
};

import { types } from 'mobx-state-tree';

export const MyPageFileModel = types
  .model('MyPageFile', {
    id: types.maybeNull(types.number),
    uuid: types.optional(types.string, ''),
    filetype: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    format: types.optional(types.string, ''),
    client: types.optional(types.string, ''),
    userId: types.optional(types.number, -1),
    filename: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
  })
  .volatile(self => ({
    file: null as File | null,
  }))
  .actions(self => ({
    setId(value: number) {
      self.id = value;
    },
    setUuid(value: string) {
      self.uuid = value;
    },
    setFiletype(value: string) {
      self.filetype = value;
    },
    setName(value: string) {
      self.name = value;
    },
    setFormat(value: string) {
      self.format = value;
    },
    setClient(value: string) {
      self.client = value;
    },
    setUserId(value: number) {
      self.userId = value;
    },
    setFilename(value: string) {
      self.filename = value;
    },
    setUrl(value: string) {
      self.url = value;
    },
    setFile(file: File) {
      self.file = file;
    },
  }));

type MyPageFileType = typeof MyPageFileModel.Type;
export interface MyPageFile extends MyPageFileType {}

import axios from 'axios';
import React, { useState } from 'react';

import {
  FloatingButton,
  InputField,
  ReportModal,
  SendSuccessAlert,
  TextareaField,
} from '../../molecules/MainTemplate/ErrorReport';

const SEND_REPORT_URL =
  '/notificationCenter/mailTextTemplate/1/send_error_report';
/*
  * 백앤드 API 설명.
    - url (id부분은 1로 설정)
      /notificationCenter/mailTextTemplate/1/send_error_report

    - body data:
      {
      "receiver_name":"Dan", //이름
      "receiver_mail_address":"mlldaniel@naver.com", // 받을 이메일
      "mail":"error please fix this" // 내용
      }
*/

const ErrorReport: React.FC<{}> = props => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameValid, setNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [messageValid, setMessageValid] = useState(true);

  const resetAllValid = () => {
    setNameValid(true);
    setEmailValid(true);
    setMessageValid(true);
  };

  const resetAllValues = () => {
    setName('');
    setEmail('');
    setMessage('');
    setIsLoading(false);
  };

  const checkFieldsValid = () => {
    resetAllValid();

    if (name.length === 0) setNameValid(false);
    if (email.length === 0) setEmailValid(false);
    if (message.length === 0) setMessageValid(false);

    if (name.length > 0 && email.length > 0 && message.length > 0) {
      return true;
    }
    return false;
  };

  const closeModal = () => {
    resetAllValues();
    resetAllValid();

    setShowModal(false);
  };

  const handleSend = async () => {
    try {
      setIsLoading(true);
      await axios.post(SEND_REPORT_URL, {
        receiver_name: name,
        receiver_mail_address: email,
        mail: message,
      });
      closeModal();
      setShowSuccessAlert(true);

      setTimeout(
        await function () {
          setShowModal(false);
          setShowSuccessAlert(false);
        },
        5000,
      );
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <ReportModal
        isOpen={showModal}
        isLoading={isLoading}
        onClose={closeModal}
        onSend={async () => {
          const isValidAll = checkFieldsValid();

          if (!isValidAll) return;

          await handleSend();
        }}
      >
        <InputField
          name="error-report-name"
          title="이름"
          disabled={isLoading}
          isValid={nameValid}
          value={name}
          onChange={value => {
            setName(value);
            if (value.length > 0) setNameValid(true);
          }}
        />

        <InputField
          name="error-report-email"
          title="연락 받을 이메일 주소"
          disabled={isLoading}
          value={email}
          isValid={emailValid}
          onChange={value => {
            setEmail(value);
            if (value.length > 0) setEmailValid(true);
          }}
        />

        <TextareaField
          name="error-report-message"
          title="오류 내용"
          placeholder="오류가 난 상황을 최대한 자세히 적어주세요."
          disabled={isLoading}
          value={message}
          isValid={messageValid}
          onChange={value => {
            setMessage(value);
            if (value.length > 0) setMessageValid(true);
          }}
        />
      </ReportModal>

      <SendSuccessAlert
        isOpen={showSuccessAlert}
        onClose={() => setShowSuccessAlert(false)}
      />

      <FloatingButton
        className={showModal ? 'active' : ''}
        onClick={() => setShowModal(!showModal)}
      />
    </>
  );
};

export default ErrorReport;

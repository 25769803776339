import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { flow, types } from 'mobx-state-tree';
import { ERecType } from './constants';

import {
  IReception,
  mapper,
  ReceptionModel,
  ReceptionResponse,
} from './ReceptionModel';

const getToken = () => {
  const authToken = window.localStorage.getItem('authtoken');
  if (authToken) {
    return window.localStorage.getItem('authtoken');
  }
  return false;
};

const Request = (url: any, option: any = {}) => {
  const token = getToken();

  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  if (option['method'] == 'POST') {
    return axios.post(url, option['data']);
  }
  return axios.get(url);
};

export const ReceptionStoreModel = types
  .model('ReceptionStore', {
    receptions: types.array(ReceptionModel),
    filterReception: types.optional(types.string, ERecType.ALL),
  })

  .views(self => ({
    get sortedReceptions() {
      let receptions: IReception[] = self.receptions;
      const OrderStatus: any = {
        // 순서 맞추기 위해서 status 사용
        accepted: 1,
        pending: 2,
        rejected: 3,
        canceled: 4,
      };
      receptions = sortBy(
        self.receptions.slice(),
        reception => reception.id,
      ).reverse(); // 복수차 줄이기 위해서 먼저 최신순으로 정렬해놓음.
      receptions = receptions.sort(
        (a, b) => OrderStatus[a.status] - OrderStatus[b.status],
      );

      if (self.filterReception === ERecType.ALL) {
        return receptions;
      }
      let selectedType: string = '';
      switch (self.filterReception) {
        case '모두 보기':
          selectedType = 'ALL';
          break;
        case '승인됨':
          selectedType = 'ACCEPTED';
          break;
        case '접수완료':
          selectedType = 'PENDING';
          break;
        case '거절됨':
          selectedType = 'REJECTED';
          break;
        case '의뢰 취소':
          selectedType = 'CANCELED';
          break;
        default:
          break;
      }

      return receptions.filter(
        item => item.status === selectedType.toLowerCase(),
      );
    },
  }))
  .actions(self => ({
    init() {
      this.setFilterReception(ERecType.ALL);
    },
    getReceptionDetail(id: number) {
      const found =
        self.receptions.find(reception => {
          return reception.id === id;
        }) || undefined;
      return found;
    },
    setFilterReception(val: string) {
      self.filterReception = val;
    },
  }))
  .actions(self => {
    const fetchReceptions = flow(function* (created_after?: string) {
      try {
        const { data }: { data: ReceptionResponse[] } = yield Request(
          '/reception',
        );
        const newReceptions = data.map(x => ReceptionModel.create(mapper(x)));
        self.receptions.replace(newReceptions);
      } catch (e) {
        console.log('fetchReception error', e);
        throw e;
      }
    });

    const fetchReception = flow(function* (receptionId: number) {
      try {
        const { data }: { data: ReceptionResponse } = yield Request(
          `/reception/${receptionId}`,
        );

        const newReception = ReceptionModel.create(mapper(data));

        self.receptions.forEach(x => {
          if (x.id === receptionId) {
            self.receptions.remove(x);
          }
        });

        self.receptions.push(newReception);
      } catch (e) {
        console.log('fetchReception error', e);
        throw e;
      }
    });

    const cancelReception = flow(function* (
      receptionId: number,
      reasonData: any,
    ) {
      try {
        const { data }: { data: any } = yield Request(
          `/reception/${receptionId}/cancel`,
          {
            method: 'POST',
            data: reasonData,
          },
        );

        if (!data['error']) {
          fetchReception(receptionId);
        }
        return data['error'];
      } catch (e) {
        console.log('cancelReception error', e);
        throw e;
      }
    });

    const addReception = flow(function* (receptionData: any) {
      try {
        const { data }: { data: any } = yield Request(`/reception`, {
          method: 'POST',
          data: receptionData,
        });
        return data;
      } catch (e) {
        console.log('addReception error', e);
        throw e;
      }
    });

    const uploadReceptionFile = flow(function* (fileData: any) {
      try {
        delete axios.defaults.headers['accept'];
        const encodeName = encodeURI(fileData.file.name);
        let filename =
          encodeName
            .substring(0, encodeName.lastIndexOf('.'))
            .substring(0, 96) +
          encodeName.substring(encodeName.lastIndexOf('.'));

        let formData = new FormData();
        formData.append('file', fileData.file);
        formData.append('reception', fileData.reception);

        let headers = {
          'Content-Disposition': '',
          'content-type': '',
        };
        headers['Content-Disposition'] = `attachment; filename=${filename}`;
        headers['content-type'] = 'multipart/form-data';

        const { data }: { data: any } = yield axios({
          url: `/receptionFile`,
          method: 'POST',
          data: formData,
          headers: headers,
        });

        return data;
      } catch (e) {
        console.log('addReception error', e);
        throw e;
      }
    });

    return {
      fetchReceptions,
      fetchReception,
      cancelReception,
      addReception,
      uploadReceptionFile,
    };
  });

type ReceptionStoreType = typeof ReceptionStoreModel.Type;
export interface IReceptionStore extends ReceptionStoreType {}

import React, { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { PC } from '../../../constants/media';

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const TwoLine = styled.div`
  width: 558px;
  padding-top: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media only screen and (max-width: ${PC}) {
    width: 260px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-break: break-all;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;

export const DivTextLink: FC = props => {
  const ref = useRef(null);
  // const [ isOpen, setIsOpen ] = useState(false);

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      console.log('ref.current', ref.current);
      if (ref != null && ref.current != null) {
        const el = ref.current!;
        // @ts-ignore
        const divHeight = el.offsetHeight;
        // @ts-ignore
        const lineHeight = parseInt(el.style.lineHeight);
        const lines = divHeight / lineHeight;
        console.log('divHeight', divHeight);
        // console.log("el.style",el.style)
        console.log('lineHeight', lineHeight);
        console.log('lines', lines);
      }
      if (!(ref.current! as any).contains(e.target)) {
        // setIsOpen(false);
        console.log('HELLO');
      } else {
        console.log('BYE');
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => {
      document.removeEventListener('click', clickOutside, true);
    };
  });

  return (
    <Wrapper>
      <TwoLine ref={ref} onClick={() => clickOutside}>
        {props.children}
        {/*<div onClick={()=>clickOutside}>{ref.current}</div>*/}
      </TwoLine>
    </Wrapper>
  );
};

import axios from 'axios';

import {
  SlackBotResponse,
  TaskActivityPaginatedResponse,
  TaskBoardResponse,
  TaskBulletinPostResponse,
  TaskBulletinResponse,
  TaskLabelSimpleResponse,
  TaskListResponse,
  TaskResponse,
  TaskSprintSimpleResponse,
} from './interfaces';

import { BulletinPostFile } from './models/BulletinPostFile';

/*
 * TODO: 액션이름으로 되어 있는 함수 다 의미이름으로 바꿀것.
 */

/*
 * 태스크보드
 */
export const Board = {
  baseURL: '/taskmgr/taskboard',

  getByGroupId: async function (groupId: string) {
    try {
      return (
        (await axios.get(`${this.baseURL}`, {
          params: {
            search: groupId,
          },
        })) as { data: TaskBoardResponse[] }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  getLables: async function (id: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}/${id}/label`)) as {
          data: TaskLabelSimpleResponse[];
        }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  getSprints: async function (id: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}/${id}/sprint`)) as {
          data: TaskSprintSimpleResponse[];
        }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  createLabel: async function (id: number, label: string) {
    try {
      return await axios.put(`${this.baseURL}/${id}/label`, {
        label_title: label,
      });
    } catch (e) {
      throw e;
    }
  },
  deleteLabel: async function (id: number, label: string) {
    try {
      await axios.delete(`${this.baseURL}/${id}/label`, {
        data: {
          label_title: label,
        },
      });
    } catch (e) {
      throw e;
    }
  },
  createSprint: async function (id: number, sprint: string) {
    try {
      return await axios.put(`${this.baseURL}/${id}/sprint`, {
        sprint_title: sprint,
      });
    } catch (e) {
      throw e;
    }
  },
  deleteSprint: async function (id: number, sprint: string) {
    try {
      await axios.delete(`${this.baseURL}/${id}/sprint`, {
        data: {
          sprint_title: sprint,
        },
      });
    } catch (e) {
      throw e;
    }
  },
  resetNotificationCount: async function (id: number) {
    try {
      await axios.put(`${this.baseURL}/${id}/notificationViewed`);
    } catch (e) {
      throw e;
    }
  },
};

/*
 * 태스크리스트
 */
export const TaskList = {
  baseURL: '/taskmgr/tasklist',

  create: async function (boardId: string, title: string) {
    try {
      return await axios.post(`${this.baseURL}`, {
        task_board: boardId,
        title: title,
      });
    } catch (e) {
      throw e;
    }
  },
  delete: async function (id: number) {
    try {
      return await axios.delete(`${this.baseURL}/${id}`);
    } catch (e) {
      throw e;
    }
  },
  get: async function (id: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}/${id}`)) as { data: TaskListResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  moveTaskList: async function (targetListId: number, orderTo: number) {
    try {
      return await axios.put(`${this.baseURL}/moved`, {
        target_list_id: targetListId,
        order_to: orderTo,
      });
    } catch (e) {
      throw e;
    }
  },
  put: async function (
    id: number,
    boardId: string,
    param: string,
    content: string,
  ) {
    try {
      return await axios.put(`${this.baseURL}/${id}`, {
        task_board: boardId,
        [param]: content,
      });
    } catch (e) {
      throw e;
    }
  },
  updatePersonInCharge: async function (id: number, userIds: number[]) {
    try {
      return await axios.put(`${this.baseURL}/${id}/person-in-charge`, {
        person_in_charge_list: userIds,
      });
    } catch (e) {
      throw e;
    }
  },
};

/*
 * 태스크
 */
export const Task = {
  baseURL: '/taskmgr/task',

  archived: async function (id: number, is_archived: boolean) {
    try {
      return await axios.put(`${this.baseURL}/archived`, {
        task_id: id,
        is_archived: is_archived,
      });
    } catch (e) {
      throw e;
    }
  },
  archiveTasks: async function (
    tasks: { task_id: number; is_archived: boolean }[],
  ) {
    try {
      return await axios.put(`${this.baseURL}/archiveds`, {
        list: tasks,
      });
    } catch (e) {
      throw e;
    }
  },
  create: async function (boardId: string, listId: string, title: string) {
    try {
      return (
        (await axios.post(`${this.baseURL}`, {
          // task_board: boardId,
          task_list_id: listId,
          title: title,
        })) as { data: TaskResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  delete: async function (id: number) {
    try {
      return await axios.delete(`${this.baseURL}/${id}`);
    } catch (e) {
      throw e;
    }
  },
  deleteTasks: async function (ids: number[]) {
    try {
      return await axios.delete(`${this.baseURL}/destroys`, {
        data: { list: ids },
      });
    } catch (e) {
      throw e;
    }
  },
  fetch: async function (id: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}/${id}`)) as { data: TaskResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  updateTitle: async function (id: number, title: string) {
    try {
      return (
        (await axios.patch(`${this.baseURL}/${id}`, {
          title: title,
        })) as { data: TaskResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  updateTaskList: async function (id: number, listId: string) {
    try {
      return await axios.patch(`${this.baseURL}/${id}`, {
        task_list: listId,
      });
    } catch (e) {
      throw e;
    }
  },
  updateMultiTasks: async function (params: any) {
    try {
      return await axios.put(`${this.baseURL}/updatemultitask`, params);
    } catch (e) {
      throw e;
    }
  },
  resetMultitask: async function (
    ids: number[],
    resourceType: 'person_in_charge' | 'due_date' | 'label' | 'sprint',
  ) {
    try {
      return await axios.put(`${this.baseURL}/resetmultitask`, {
        task_ids: ids,
        resource_type: resourceType,
      });
    } catch (e) {
      throw e;
    }
  },
};

// export const getTask = async(taskId: number) => {
//   const { data }: { data: TaskResponse[] } = await axios.get(
//     `/taskmgr/task/${taskId}`
//   );
//   return data
// }

export const postTaskLabel = async (
  task_id: number,
  label_id: number,
  title: string,
) => {
  const { data }: { data: TaskListResponse } = await axios.post(
    `/taskmgr/task/${task_id}/label`,
    {
      task_label_id: label_id,
      title: title,
    },
  );
  return data;
};

/*
 * 자주보는리스트
 */
export const putBulletinTitle = async (id: number, title: string) => {
  try {
    await axios.put(`/taskmgr/bulletin/${id}/title`, {
      title: title,
    });
  } catch (e) {
    throw e;
  }
};

/*
 * 자주보는리스트
 */
export const TaskBulletin = {
  baseURL: '/taskmgr/bulletin',

  delete: async function (id: number) {
    try {
      await axios.delete(`${this.baseURL}/${id}`);
      return id;
    } catch (e) {
      throw e;
    }
  },
  get: async function (params?: { search: string }) {
    try {
      return (
        (await axios.get(`${this.baseURL}`, {
          params: params,
        })) as { data: TaskBulletinResponse[] }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  getById: async function (id: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}/${id}`)) as {
          data: TaskBulletinResponse;
        }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  post: async function (taskBoardId: string, title: string) {
    return (
      (await axios.post(`/taskmgr/bulletin`, {
        title: title,
        task_board: taskBoardId,
      })) as { data: TaskBulletinResponse }
    ).data;
  },
};

export const TaskBulletinPost = {
  baseURL: '/taskmgr/bulletinpost',

  fetch: async function (id: number) {
    await axios
      .get(`${this.baseURL}/${id}`)
      .then(response => {
        console.log(response);
        console.log((response as { data: TaskBulletinPostResponse }).data);
        return (response as { data: TaskBulletinPostResponse }).data;
      })
      .catch(function (e) {
        throw e.response;
      });
  },
  move: async function (
    src_post_id: number,
    dst_bulletin_id: number,
    order_to: number,
  ) {
    try {
      return await axios.put(`${this.baseURL}/${src_post_id}/moved`, {
        dst_bulletin_id: dst_bulletin_id,
        order_to: order_to,
      });
    } catch (e) {
      throw e;
    }
  },
  // updateTitle : async function(
  //   id: number,
  //   task_bulletin: string,
  //   title: string
  // ) {
  //   try{
  //     return await axios.put(
  //       `${this.baseURL}/${id}/title`,
  //       {
  //         title: title,
  //         task_bulletin: task_bulletin
  //       }
  //     );
  //   } catch(e) {
  //     throw e;
  //   }
  // },
  update: async function (id: number, param: string, content: string) {
    try {
      const { data }: { data: TaskBulletinPostResponse } = await axios.patch(
        `${this.baseURL}/${id}`,
        {
          [param]: content,
        },
      );

      return data;
    } catch (e) {
      throw e;
    }
  },
  create: async function (
    task_bulletin: string,
    writer: string,
    title: string,
  ) {
    try {
      return (
        (await axios.post(`${this.baseURL}`, {
          title: title,
          task_bulletin: task_bulletin,
          writer: writer,
        })) as { data: TaskBulletinPostResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
  delete: async function (id: number) {
    return await axios.delete(`/taskmgr/bulletinpost/${id}`);
  },
};

/*
 * 자주보는리스트 - 아이템 - 첨부 자료 - 파일
 */
export const PostFile = {
  baseURL: '/taskmgr/taskbulletinpostfile',

  create: async function (postId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('task_bulletin_post', postId);

    try {
      return (
        (await axios.post(`/taskmgr/taskbulletinpostfile`, formData)) as {
          data: BulletinPostFile[];
        }
      ).data;
    } catch (e) {
      throw e;
    }
  },
};

/*
 * 자주보는리스트 - 아이템 - 첨부 자료 - 링크 추가
 */
export const postTaskBulletinPostLink = async (
  post_id: string,
  link: string,
) => {
  // await axios.post(
  //   `/taskmgr/taskbulletinpostlink`,
  //   {
  //     "task_bulletin_post": post_id,
  //     "link": link
  //   }
  // )
  // .then(response => {
  //   return response.data.find( (d: any) => d.link === link);
  // })
  // .catch(function (e) {
  //   throw e.response;
  // });
  try {
    const { data }: { data: any[] } = await axios.post(
      `/taskmgr/taskbulletinpostlink`,
      {
        task_bulletin_post: post_id,
        link: link,
      },
    );
    return data.find(d => d.link === link);
  } catch (e) {
    throw e;
  }
};

export const patchTaskBulletinPostLink = async (id: number, link: string) => {
  try {
    const { data }: { data: any[] } = await axios.patch(
      `/taskmgr/taskbulletinpostlink/${id}`,
      {
        link: link,
      },
    );
    return data.find(d => d.id === id);
  } catch (e) {
    throw e;
  }
};

export const deleteTaskBulletinPostLink = async (id: number) => {
  try {
    await axios.delete(`/taskmgr/taskbulletinpostlink/${id}`);
    return id;
  } catch (e) {
    throw e;
  }
};

/*
 * 활동내역
 */
export const TaskActivity = {
  baseURL: '/taskmgr/activity',

  get: async function (taskBoardId: string, page: number) {
    try {
      return (
        (await axios.get(`${this.baseURL}`, {
          params: {
            task_board_id: taskBoardId,
            page: page,
            page_size: 50,
          },
        })) as { data: TaskActivityPaginatedResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
};

/*
 * 슬랙 설정
 */
// export const Slack = {
//   baseURL: '/slackboard',

//   getChannels: async function(groupId: string) {
//     try{
//       return (await axios.get(
//         `${this.baseURL}/channels`, {
//           params: {
//             project_group_id: groupId
//           }
//         }
//       ) as { data: SlackChannelResponse[] }).data;
//     } catch(e) {
//       throw e;
//     }
//   },

//   getMembers: async function(groupId: string) {
//     try{
//       return (await axios.get(
//         `${this.baseURL}/members`, {
//           params: {
//             project_group_id: groupId
//           }
//         }
//       ) as { data: SlackChannelResponse[] }).data;
//     } catch(e) {
//       throw e;
//     }
//   },

//   getSettings: async function(groupId: string) {
//     try{
//       return (await axios.get(
//         `${this.baseURL}/settings`, {
//           params: {
//             project_group_id: groupId
//           }
//         }
//       ) as { data: SlackSettingResponse }).data;
//     } catch(e) {
//       throw e;
//     }
//   },

//   updateSettings: async function(
//     groupId: string,
//     channelId: string,
//     memberMappings: {[k:string]: number}
//   ) {
//     try{
//       await axios.put(
//         `${this.baseURL}/settings`,
//         {
//           project_group_id: groupId,
//           channel: channelId,
//           member_mapping_updates: memberMappings
//         }
//       );
//     } catch(e) {
//       throw e;
//     }
//   },

//   sync: async function(groupId: string) {
//     try{
//       await axios.put(
//         `${this.baseURL}/sync`,
//         {
//           project_group_id: groupId,
//         }
//       );
//     } catch(e) {
//       throw e;
//     }
//   }
// }

/*
 * 슬랙봇
 */
export const SlackBot = {
  baseURL: '/seesobot',

  checkSlackInstalled: async function (groupId: string) {
    try {
      return (
        (await axios.get(`${this.baseURL}/check-installed`, {
          params: {
            project_group_id: groupId,
          },
        })) as { data: SlackBotResponse }
      ).data;
    } catch (e) {
      throw e;
    }
  },
};

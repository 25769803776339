import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ProjectBox } from '.';

import { EProjectStatus, IDashboardStore } from '../../../store/Dashboard';

import { ProjectList as Comps } from '../../molecules/Dashboard';

interface Props {
  notiIds: string[];
  status: EProjectStatus;
  title: string;
  store: IDashboardStore;
}

const ProjectList: FC<Props> = observer(props => {
  return (
    <Comps.Wrapper>
      <Comps.Title>{props.title}</Comps.Title>

      {props.store.isLoading ? (
        <Comps.SpinnerBody>
          <Spinner />
        </Comps.SpinnerBody>
      ) : (
        <Comps.Body>
          {props.status === EProjectStatus.ONGOING ? (
            <>
              {
                // 진행중
                props.store.onGoings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 대기중
                props.store.waitings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 매칭중
                props.store.matchings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 홀딩중
                props.store.holdings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
            </>
          ) : (
            <>
              {
                // 종료-하자보수 접수 가능
                props.store.flaw_accepts.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 종료-하자보수 진행 중
                props.store.flawings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 종료-유지보수 접수가능
                props.store.keep_accepts.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 종료 -유지보수 진행중
                props.store.keepings.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 종료
                props.store.ends.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
              {
                // 종료 - 장기홀드
                props.store.endholds.map((item, i) => (
                  <ProjectBox
                    key={i}
                    item={item}
                    isAlert={
                      props.notiIds.indexOf(item.groupId) > -1 ? true : false
                    }
                    className="Project-list-item"
                  />
                ))
              }
            </>
          )}
        </Comps.Body>
      )}
    </Comps.Wrapper>
  );
});

export default ProjectList;

import { Button } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import { MOBILE } from '../../../constants/media';
import BgSVG from '../../../static/images/bg-seeso.png';
import { AppStore } from '../../../store/AppStore';

const Wrapper = styled.div`
  background: ${PALETTE['purpleish-blue']} url('${BgSVG}') no-repeat 50% 100%;
  width: 100% !important;
  height: 920px;
  text-align: center;
  margin-top: 37px;
  @media only screen and (max-width: ${MOBILE}) {
    height: 610px;
    background-size: 100%;
    margin-top: 0px;
  }
`;

const IconSection = styled.div`
  padding-top: 30vh;
  font-size: 30px;
  @media only screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
`;
const MessageSection = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding-top: 12px;
  @media only screen and (max-width: ${MOBILE}) {
    font-size: 18px;
  }
`;

const RequestButton = styled(Button)`
  margin-top: 40px;
  width: 302px;
  height: 56px;
  border-radius: 3px;
  background-color: ${PALETTE['color-emerald']} !important;
  background-image: none !important;
  @media only screen and (max-width: ${MOBILE}) {
    width: 200px;
  }
  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${PALETTE['color-blue']};
    @media only screen and (max-width: ${MOBILE}) {
      font-size: 15px;
    }
  }
`;

const ASection = styled.a`
  display: block;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
`;
const LinkSection = styled(ASection)`
  margin-top: 96px;
  color: #fff !important;
  text-decoration: none;
  :hover {
    color: ${PALETTE['color-emerald']} !important;
    text-decoration: under-line;
  }
  @media only screen and (max-width: ${MOBILE}) {
    font-size: 12px;
  }
`;
const MailToSection = styled(ASection)`
  margin-top: 17px;
  color: #fff !important;
  text-decoration: none;
  :hover {
    color: ${PALETTE['color-emerald']} !important;
    text-decoration: under-line;
  }
  @media only screen and (max-width: ${MOBILE}) {
    font-size: 12px;
  }
`;

interface Props extends RouteComponentProps {
  history: any;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

// @observer
@inject('appStore')
@observer
class NoReceptionPage extends Component<Props> {
  handleRedirectToHome = () => {
    this.userStore.updateCurrentThemeStatus(false);
    this.props.history.push(`/`);
  };
  get injected() {
    return this.props as InjectedProps;
  }
  get userStore() {
    return this.injected.appStore.userStore;
  }

  render() {
    return (
      <Wrapper>
        <IconSection>👀</IconSection>
        <MessageSection>프로젝트 의뢰내역이 없습니다.</MessageSection>

        {/*<RequestButton */}
        {/*  onClick={ () => { this.props.history.push(`/receptions/start`)}}*/}
        {/*  text={'프로젝트 의뢰하기'} />*/}

        <LinkSection
          onClick={() => {
            this.handleRedirectToHome();
          }}
        >
          시소 홈페이지 이동
        </LinkSection>
        <MailToSection href="mailto:partner@seeso.kr">
          의뢰 접수한 내용이 안보인다면?
        </MailToSection>
      </Wrapper>
    );
  }
}

export default withRouter(NoReceptionPage);

import axios from 'axios';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component, FormEvent } from 'react';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import Button from '../MyPage/Button';
import { Inspection as Comps } from './index';
import { DivTextLink } from './TextLinkLine';

const DisabledInput = styled.div`
  @media only screen and (max-width: ${PC}) {
    word-break: break-all;
    width: 260px;
    height: 40px;
  }
`;
const Input = styled.input``;
// function Input(props: any) {
//     return <input className={props.className}></input>
// }

/* // .input {
     width: 545px;
     height: 40px;
     border:${p => p.blur ? "0" : ""}
 // }*/
const AttachInput = styled(Input)<{
  blur: boolean;
}>`
  width: 545px;
  height: 40px;
  padding-left: ${p => (p.blur ? '0px' : '15px')};
  border: solid 1px ${PALETTE['very-light-pink-three']};
  font-size: 14px;
  color: ${p => (p.blur ? '#000000' : PALETTE['brown-grey'])};

  @media only screen and (max-width: ${PC}) {
    && {
      width: 260px;
      height: 40px;
    }
  }
`;
const UploadButtonContainer = styled.div`
  margin_top: 15px;
  margin_bottom: 14px;
  margin_left: 10px;
  width: 111px;
  height: 40px;
  border_radius: 3px @media only screen and (max-width: ${PC}) {
    margin-left: 28px;
  }
`;
const ButtonLabel = styled.label`
  // margin-left:10px;
  // display: "inline-block";
  // @media only screen and (max-width: ${PC}) {
  //     margin-left:28px;
  // }
`;

const AttachButton = styled.div`
  width: 130px;
  height: 48px;
  opacity: 1;
  border-radius: 100px;
  box-shadow: 0 8px 16px 0 rgba(0; 0, 0, 0.08);
  cursor: pointer;

  margin-right: 15px;
  // background-color: var(--button-style);

  // width: 74px;
  // height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #1c2126;
`;

interface Props {
  index: number;
  title: string;
  changeTitle: (title: string) => void;
  changeLine: () => void;
  isFile: boolean;
  addNewHandler: () => void;
  removeHandler: () => void;
  selectFileHandler: (file: File) => void;
}

@observer
class NewReferenceBar extends Component<Props> {
  @observable changed = false;
  @observable blur = false;
  @observable tempTitle = '';
  fileUpload = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      console.log('fileUpload');
      this.tempTitle = e.currentTarget.files[0].name;
      this.props.selectFileHandler(e.currentTarget.files[0]);

      // const newNode = PdfToUploadFormModel.create();
      // newNode.setFile(e.currentTarget.files[0]);
      // this.formStore.updateFile({
      //     fileModel: newNode,
      //     // file: e.currentTarget.files[0],
      //     taskId: this.props.taskId
      // }, "post");
    }
  };

  @computed
  get disableInput() {
    return this.props.isFile || this.props.title.length > 0;
  }

  render() {
    return (
      // <div style={{height: "69px"}}>
      <div>
        <Comps.NewReferenceButton
          onClick={() => {
            if (this.disableInput) this.props.removeHandler();
            else this.props.addNewHandler();
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              {this.disableInput ? (
                <Comps.RemoveButton></Comps.RemoveButton>
              ) : (
                <Comps.AddButton></Comps.AddButton>
              )}
            </div>
          </div>
        </Comps.NewReferenceButton>

        <Comps.NewReferenceInput>
          {!this.disableInput ? (
            <AttachInput
              style={{
                border: this.disableInput ? 'none' : '',
                background: this.disableInput ? 'white' : '',
              }}
              blur={this.disableInput}
              disabled={this.disableInput}
              type="text"
              placeholder="링크 직접입력"
              value={this.tempTitle}
              onChange={(event: any) => {
                this.tempTitle = event.target.value;
                // this.props.changeTitle(this.tempTitle);
              }}
              onKeyDown={(event: any) => {
                if (event.key === 'Enter') {
                  event.target.style.border = 'none';
                  event.target.disabled = true;
                  event.target.style.background = 'white';
                  event.target.style.color = 'black';
                  this.blur = true;

                  this.props.changeTitle(this.tempTitle);
                  this.props.changeLine();

                  axios
                    .post(`/inspections/getTitleFromUrl`, {
                      link: this.tempTitle,
                    })
                    .then(data => {
                      console.log(data, 'DATA');
                      this.tempTitle = `${data.data['title']} - ${data.data['link']}`;
                    });
                }
              }}
              /*onBlur={(event: any) => {
                                console.log("BURRRR")
                                // event.target.style.border = 'none'
                                // event.target.disabled = true
                                // this.blur = true;
                            }}*/
            />
          ) : (
            <DivTextLink>{this.tempTitle}</DivTextLink>
          )}
          {/*<DisabledInput>{this.tempTitle}</DisabledInput>*/}
        </Comps.NewReferenceInput>
        {!this.disableInput && (
          <Comps.UploadFileButtonSection>
            <label htmlFor={`upload-button${this.props.index}`}>
              <Button
                style={{
                  /*marginTop:"15px", marginBottom:"14px", marginLeft:"10px", */
                  width: '111px',
                  height: '40px',
                  borderRadius: '3px',
                }}
                text="파일 업로드"
                theme="purple-wire"
                small
                textStyle={{
                  width: '81px',
                  height: '20px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'center',
                }}
                onClick={() => {
                  /*var input = document.getElementById("bnk_file");
                                if(input) {
                                    input.click();
                                }*/
                }}
              />
            </label>
          </Comps.UploadFileButtonSection>
        )}
        <input
          // disabled={true}
          onChange={this.fileUpload}
          type="file"
          id={`upload-button${this.props.index}`}
          style={{ display: 'none' }}
        />
      </div>
    );
  }
}

export default NewReferenceBar;

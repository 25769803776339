import React, { FC, useState } from 'react';
import { EProjectStatus } from '../../../store/Dashboard';
import { IRufreeMatching } from '../../../store/Dashboard/models/RufreeMatching';
import { ISchedule } from '../../../store/Dashboard/models/Schedule';
import { ISprintReport } from '../../../store/Dashboard/models/SprintReport';

import { SprintSection as Comps } from '../../molecules/Dashboard';

import SprintList from './SprintList';
import SprintListReportOnly from './SprintListReportOnly';

interface IProps {
  className?: string;
  projectStatus: EProjectStatus;
  isEnd: boolean;
  reports: ISprintReport[];
  selectedSprint: number;
  // selectedReportId: string | null;
  isProjectHolding: boolean; //리팩토링 필요! Context 사용??
  schedule: ISchedule;
  machings: IRufreeMatching[];
}
const SprintSection: FC<IProps> = ({
  className,
  projectStatus,
  isEnd,
  isProjectHolding,
  machings,
  reports,
  schedule,
  selectedSprint,
}) => {
  const { sprintIndexies, sprints } = schedule;
  const totalCount = sprints.length;
  const doneCount = schedule ? schedule.doneSprints.length : 0;
  const scheduledCount = schedule ? schedule.scheduledSprints.length : 0;
  const ongoing = schedule
    ? schedule.ongoingSprints.map(sprint => sprint.sprint)
    : [];

  const [reportOnly, setReportOnly] = useState(false);

  return (
    <Comps.SprintWrapper className={className}>
      <Comps.SubTitle>스프린트 현황</Comps.SubTitle>

      <Comps.TotalSprint>
        전체 스프린트 <span className="highlight">{totalCount}개</span>
      </Comps.TotalSprint>

      <Comps.SprintMenu>
        {isEnd ? (
          <>
            <div>종료</div>
            <Comps.ReportViewButton
              isOn={reportOnly}
              handleClick={() => setReportOnly(!reportOnly)}
            />
          </>
        ) : (
          ongoing.length > 0 && (
            <>
              <div className="active">{`${ongoing.join(', ')}번 째 ${
                isProjectHolding ? '홀딩중' : '진행중'
              }`}</div>
              {scheduledCount > 0 && <div>진행예정 {scheduledCount}개</div>}
              <div>종료 {doneCount}개</div>
            </>
          )
        )}
      </Comps.SprintMenu>

      {reportOnly ? (
        <SprintListReportOnly
          reports={reports}
          schedule={schedule}
          selectedSprint={selectedSprint}
        />
      ) : (
        <SprintList
          isProjectEndHold={projectStatus === EProjectStatus.ENDHOLD}
          isProjectHolding={isProjectHolding}
          machings={machings}
          reports={reports}
          schedule={schedule}
          selectedSprint={selectedSprint}
        />
      )}
    </Comps.SprintWrapper>
  );
};

export default SprintSection;

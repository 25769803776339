import { flow, types } from 'mobx-state-tree';

import { getDeposits } from './apis';
import { EDepositType, EPayoutType, ETypeOptions } from './constants';

import {
  CalculateModel,
  convertFromResp,
  ICalculate,
} from './models/Calculate';
import {
  DepositModel,
  IDeposit,
  IDepositResponse,
  mapper as mapDeposit,
} from './models/Deposit';

export const CalculateStoreModel = types
  .model('CalculateStore', {
    deposits: types.array(DepositModel),
    // payoutsBySprint: types.array(PayoutBySprintModel),  // 스프린트 지출내역 + 프로젝트관련없는 입금
    calculates: types.array(CalculateModel),

    client: types.optional(types.string, ''),
    baseDate: types.maybeNull(types.Date),
    includeVAT: types.optional(types.boolean, true),
    filterDeposit: types.optional(types.string, EDepositType.ALL),
    filterPayout: types.optional(types.string, EPayoutType.ALL),

    isLoading: types.maybe(types.boolean),
  })
  .views(self => ({
    get balance() {
      return 0;
    },
    get deposited() {
      return self.deposits.reduce((prev, curr) => prev + curr.deposited, 0);
    },
    get shortage() {
      return self.deposits.reduce((prev, curr) => prev + curr.shortage, 0);
    },
    get depositSum() {
      return self.calculates
        .filter(calculate => calculate.type === ETypeOptions.DEPOSIT)
        .reduce((prev, curr) => prev + curr.amount, 0);
    },
    get payoutSum() {
      return self.calculates
        .filter(calculate => calculate.type === ETypeOptions.PAYOUT)
        .reduce((prev, curr) => prev + curr.amount, 0);
    },
    get filteredDeposits() {
      let deposits: IDeposit[] = self.deposits;

      switch (self.filterDeposit) {
        case EDepositType.WAIT:
          deposits = deposits.filter(deposit => !deposit.allDeposit);
          break;
        case EDepositType.CONFIRM:
          deposits = deposits.filter(deposit => deposit.allDeposit);
          break;
        default:
          break;
      }

      switch (self.filterPayout) {
        case EPayoutType.SPRINT:
          deposits = deposits.filter(deposit => deposit.sprints.length > 0);
          break;
        case EPayoutType.ETC:
          deposits = deposits.filter(deposit => deposit.sprints.length === 0);
          break;
        default:
          break;
      }

      return deposits;
    },
    // get payouts() {
    //   let payouts: IPayoutBySprint[] = self.payoutsBySprint;

    //   switch(self.filterDeposit) {
    //     case EDepositType.WAIT:
    //       payouts = payouts.filter(payout => !payout.isDeposited())
    //       break;
    //     case EDepositType.CONFIRM:
    //       payouts = payouts.filter(payout => payout.isDeposited())
    //       break;
    //     default:
    //       break;
    //   }
    //   if(self.filterPayout === EPayoutType.ALL) {
    //     return payouts;
    //   }
    //   return payouts.filter(payout => payout.payoutType === self.filterPayout)
    // }
  }))
  .actions(self => ({
    init(client: string) {
      this.setClient(client);

      this.setFilterDeposit(EDepositType.ALL);
      this.setFilterPayout(EPayoutType.ALL);
    },
    setClient(client: string) {
      self.client = client;
    },
    setFilterDeposit(deposit: string) {
      self.filterDeposit = deposit;
    },
    setFilterPayout(payout: string) {
      self.filterPayout = payout;
    },
    setBaseDate(date: Date | null) {
      self.baseDate = date;
    },
    setIncludeVAT(include: boolean) {
      self.includeVAT = include;
    },
  }))
  .actions(self => ({
    reverseCalculatesByDate() {
      self.calculates.replace(self.calculates.slice().reverse());
    },
    checkCalculateBalances() {
      let currenBalance = 0;

      self.calculates.replace(
        self.calculates.map(calculate => {
          if (calculate.type === ETypeOptions.DEPOSIT) {
            currenBalance += calculate.amount;
          } else if (calculate.type === ETypeOptions.PAYOUT) {
            currenBalance -= calculate.amount;
          } else {
          }

          return Object.assign({}, calculate, { balance: currenBalance });
        }),
      );
    },
  }))
  .actions(self => {
    const fetchDeposit = flow(function* () {
      const depositsData: IDepositResponse[] = yield getDeposits();

      const deposits = depositsData
        .filter(x => x.date_issue)
        .map(x => DepositModel.create(mapDeposit(x)));
      self.deposits.replace(deposits);
    });

    const fetchAll = flow(function* () {
      self.isLoading = true;

      const depositsData: IDepositResponse[] = yield getDeposits();
      const deposits = depositsData
        .filter(x => x.date_issue)
        .map(x => DepositModel.create(mapDeposit(x)));
      self.deposits.replace(deposits);

      // const payoutData: IPayoutResponse[] = yield getPayout();

      // const calculates: ICalculate[] = convertFromResp(
      //   depositsData.filter(x => x.date_issue),
      //   payoutData.filter(x => x.date_payment)
      // );
      const calculates: ICalculate[] = convertFromResp(
        depositsData.filter(x => x.date_issue),
      );
      self.calculates.replace(
        calculates.sort((a, b) =>
          a.date && b.date && a.date > b.date ? 1 : -1,
        ),
      );
      if (self.calculates.length > 0) {
        self.setBaseDate(calculates[calculates.length - 1].date);
      }

      // const depositsNoSprint = deposits.filter(deposit => !deposit.projectGroup || deposit.sprints.length === 0)
      // self.payoutsBySprint.replace(convertPayoutBySprint(payoutData, depositsNoSprint));

      self.checkCalculateBalances();

      self.isLoading = false;
    });

    return {
      fetchAll,
      fetchDeposit,
    };
  });

type CalculateStoreModelType = typeof CalculateStoreModel.Type;
export interface ICalculateStore extends CalculateStoreModelType {}

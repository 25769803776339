import React from 'react';

import useVisible from '../Common/hooks/useVisible';

export interface IAssigneePersonProps {
  id: number;
  title: string;
}

export interface IAssigneeProps {
  isArchived?: boolean;
  assignees?: IAssigneePersonProps[]; // 담당자 후보군들
  values: string[]; // 선택 담당자 값
  handleAdd?: (value: number) => void;
  handleRemove?: (value: number) => void;
  handleClose?: () => void; // 뭔가 마무리작업이 필요할 때, 편집 모드가 끝나면 호출
}

export const Assignee: React.SFC<IAssigneeProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, dataset } = e.target;

    if (checked) {
      props.handleAdd && props.handleAdd(Number(dataset.id));
    } else {
      props.handleRemove && props.handleRemove(Number(dataset.id));
    }
  };

  return (
    <li>
      <div className={`select-w ${isVisible ? 'selected' : ''}`} ref={ref}>
        <button
          type="button"
          disabled={props.isArchived ? true : false}
          onClick={() => {
            setIsVisible(!isVisible);
            // setIsSelected(!isSelected);
          }}
        >
          담당자
        </button>
        <div className="option-box">
          <ul>
            {props.assignees &&
              props.assignees.map(assignee => (
                <li key={assignee.id}>
                  <input
                    type="checkbox"
                    id={`asCh${assignee.id}`}
                    data-id={assignee.id}
                    checked={props.values.includes(assignee.title)}
                    onChange={handleChange}
                  />
                  <label htmlFor={`asCh${assignee.id}`}>{assignee.title}</label>
                </li>
              ))}
          </ul>
        </div>
        <div className="member">
          {props.values.map((assignee, i) => (
            <a key={i}>{assignee}</a>
          ))}
        </div>
      </div>
    </li>
  );
};

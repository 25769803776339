import { Dialog as bp3_Dialog } from '@blueprintjs/core';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import MobilCloseSvg from '../../../static/icons/btn-mo-modalclose.svg';
import CloseSvg from '../../../static/icons/icon-close-white.svg';

import ArrowUpSVG from '../../../static/icons/icon-date-arrow-1.svg';
import ArrowDownSVG from '../../../static/icons/icon-date-arrow-2.svg';

import { ETypeOptions } from '../../../store/Calculate/constants';

const BP3DetailDialog = styled(bp3_Dialog)`
  background: #ffffff;
  border-radius: 0px;
  box-shadow: none;

  margin: 0;
  padding: 0;
  width: 785px;
  min-height: 700px;

  position: relative;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    margin-bottom: 158px;
  }
`;

const DialogHeader = styled.div`
  height: 124px;
  padding: 30px 0px 0px 40px;
  background-color: ${PALETTE['color-emerald']};
  line-height: 27px;
  font-size: 18px;
  font-weight: bold;
  div {
    margin-bottom: 10px;
  }

  @media only screen and (max-width: ${PC}) {
    height: 103px;
    padding: 20px 0px 0px 16px;
    line-height: 24px;
    font-size: 16px;
  }
`;
const DialogBody = styled.div`
  padding: 35px 40px 0px 40px;

  @media only screen and (max-width: ${PC}) {
    padding: 30px 16px 0 16px;
    position: relative;
  }
`;
const DialogFooter = styled.div``;

const TypeMenuWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: ${PC}) {
    justify-content: center;
    margin-bottom: 43px;
  }
`;
const TypeMenuItem = styled.div<{ isActive: boolean }>`
  line-height: 27px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  ${p =>
    p.isActive &&
    `
      font-weight: bold;
      color: ${PALETTE['purpleish-blue']};
      padding-bottoom: 2px;
      border-bottom: solid 2px ${PALETTE['purpleish-blue']};
      `}

  & + & {
    margin-left: 30px;
  }

  @media only screen and (max-width: ${PC}) {
    line-height: 24px;
    font-size: 16px;
  }
`;

const DateInfoRow = styled.div`
  display: flex;
  justify-content: flex-end;

  line-height: 19px;
  font-size: 13px;
  color: ${PALETTE['brownish-grey']};
  margin: 34px 0px 15px 0px;

  span + span {
    margin-left: 20px;
  }
  @media only screen and (max-width: ${PC}) {
    position: absolute;
    right: 16px;
    margin: 0px;
    span + span {
      margin-left: 15px;
    }
  }
`;

const RowWrapper = styled.div`
  margin-bottom: 20px;
  & > div:last-child {
    border-bottom: solid 1px ${PALETTE['very-light-pink-three']};
  }
`;
const Row = styled.div<{ isDeposit?: boolean }>`
  line-height: 44px;
  display: flex;
  color: ${PALETTE['black-two']};

  div:nth-child(1) {
    width: 370px;
    padding-left: 15px;
    font-weight: 500;
    color: ${p => (p.isDeposit ? '#002fd8' : '#fc4545')};
  }
  div:nth-child(2) {
    width: 110px;
  }
  div:nth-child(3) {
    width: 120px;
    color: ${p => (p.isDeposit ? '#002fd8' : '#fc4545')};

    span::before {
      content: '${p => (p.isDeposit ? '+ ' : '- ')} ￦ ';
    }
  }
  div:nth-child(4) {
    span::before {
      content: '￦ ';
    }
  }

  & + & {
    border-top: solid 1px ${PALETTE['very-light-pink-two']};
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    line-height: 19px;

    &:not(:first-child) {
      position: relative;
    }
    div:nth-child(1) {
      padding-left: 0;
      line-height: 22px;
      font-size: 15px;
      font-weight: 500;
      margin-top: 15px;
      margin-bottom: 10px;
    }
    div:nth-child(3) {
      font-weight: 500;
      line-height: 22px;
      position: absolute;
      right: 0;
      top: 32px;
      text-align: right;
    }
    div:nth-child(4) {
      color: ${PALETTE['brownish-grey']};
      font-size: 13px;
      margin-top: 8px;
      margin-bottom: 15px;
      text-align: right;
    }
  }
`;
const Column = styled.div``;
const HeaderRow = styled(Row)<{ isAsc: boolean }>`
  line-height: 32px;
  background-color: #fafafa;
  border-bottom: solid 1px ${PALETTE['very-light-pink-three']};

  div:nth-child(2) {
    cursor: pointer;
    span::after {
      content: '';
      background: url('${p => (p.isAsc ? ArrowDownSVG : ArrowUpSVG)}') no-repeat
        center center;
      width: 12px;
      height: 12px;
      position: absolute;
      top: 11px;
      left: 28px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    background-color: #ffffff;
    div:nth-child(1),
    div:nth-child(3),
    div:nth-child(4) {
      display: none;
    }
    div {
      line-height: 19px;
      font-size: 13px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    div:nth-child(2) {
      span::after {
        top: 3px;
      }
    }
  }
`;
const HeaderColumn = styled.div`
  line-height: 32px;
  font-size: 13px;
  font-weight: bold;
  color: ${PALETTE['black-two']} !important;
  position: relative;
`;

const AmountSumWrapper = styled.div`
  width: 328px;
  border-radius: 10px;
  border: solid 1px ${PALETTE['very-light-pink-two']};
  background-color: ${PALETTE['white']};
  padding: 5px 20px;
  margin-left: 377px;
  margin-bottom: 70px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    margin-left: 0px;
  }
`;
const AmountSumRow = styled.div`
  height: 44px;
  display: flex;
  justify-content: space-between;
  & + & {
    border-top: solid 1px ${PALETTE['very-light-pink-two']};
  }

  div:first-child {
    width: 52px;
    line-height: 44px;
    font-size: 14px;
    font-weight: 500;
  }
  div:last-child {
    width: 236px;
    line-height: 42px;
    font-size: 16px;
    font-weight: 500;
    text-align: right;

    ::before {
      content: '￦ ';
    }
  }
`;
const RemainSumRow = styled(AmountSumRow)`
  div:last-child {
    color: ${PALETTE['bright-orange']};
  }
`;
const NeedDepositSumRow = styled(AmountSumRow)`
  div:last-child {
    color: ${PALETTE['purpleish-blue']};
  }
`;

export const CloseButton = styled.div`
  background: url('${CloseSvg}') no-repeat center center;
  position: absolute;
  left: 805px;
  top: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  @media only screen and (max-width: ${PC}) {
    width: 53px;
    background: url('${MobilCloseSvg}') no-repeat center center;

    top: auto;
    bottom: -33px;
    left: auto;
    right: 16px;
  }
`;

const TypeMenu: FC<{
  selected: ETypeOptions;
  handleChange: (type: ETypeOptions) => void;
}> = props => {
  return (
    <TypeMenuWrapper>
      {Object.values(ETypeOptions).map((option, i) => (
        <TypeMenuItem
          key={i}
          isActive={option === props.selected}
          onClick={e => props.handleChange(option)}
        >
          {option}
        </TypeMenuItem>
      ))}
    </TypeMenuWrapper>
  );
};

export {
  BP3DetailDialog,
  ETypeOptions,
  AmountSumRow,
  AmountSumWrapper,
  Column,
  DateInfoRow,
  DialogBody,
  DialogHeader,
  DialogFooter,
  HeaderRow,
  HeaderColumn,
  NeedDepositSumRow,
  RemainSumRow,
  Row,
  RowWrapper,
  TypeMenu,
};

import React from 'react';
import { CustomArrowProps } from 'react-slick';
import { useMedia } from 'react-use';

const CarouselArrow = ({
  className,
  style,
  onClick,
  title,
}: CustomArrowProps & { title: '다음' | '이전' }) => {
  const isWide = useMedia('(min-width: 786px)');

  return isWide ? null : (
    <button className={className} style={{ ...style }} onClick={onClick}>
      {title}
    </button>
  );
};

export { CarouselArrow };

import axios from 'axios';
import { flow, types } from 'mobx-state-tree';

import { MyPageFile, MyPageFileModel } from './MyPageModel';

export const MyPageStoreModel = types
  .model('MyPageStore', {
    companyName: types.optional(types.string, ''),
    companyNameError: types.optional(types.string, ''),
    companyType: types.optional(types.string, '기업'),
    representativeName: types.optional(types.string, ''),
    representativeNameError: types.optional(types.string, ''),
    bizNo: types.optional(types.string, ''),
    bizAddress: types.optional(types.string, ''),
    userName: types.optional(types.string, ''),
    userNameError: types.optional(types.string, ''),
    userEmail: types.optional(types.string, ''),
    userEmailError: types.optional(types.string, ''),
    userPhone: types.optional(types.string, ''),
    userPhoneError: types.optional(types.string, ''),
    contractPersonName: types.optional(types.string, ''),
    contractPersonPhone: types.optional(types.string, ''),
    contractPersonEmail: types.optional(types.string, ''),
    taxPersonName: types.optional(types.string, ''),
    taxPersonPhone: types.optional(types.string, ''),
    taxPersonEmail: types.optional(types.string, ''),
    hasSameContractTaxPerson: types.optional(types.boolean, false),
    toolSlack: types.optional(types.string, ''),
    toolTrello: types.optional(types.string, ''),
    toolZeplin: types.optional(types.string, ''),
    toolGithub: types.optional(types.string, ''),

    clientId: types.optional(types.number, -1),
    clientCLId: types.optional(types.string, ''),

    clientPersonalId: types.optional(types.number, -1),

    bizFile: types.optional(MyPageFileModel, {}),
    bnkFile: types.optional(MyPageFileModel, {}),

    isFileUploading: types.optional(types.boolean, false),
  })
  .views(self => ({}))
  // setters
  .actions(self => ({
    init() {
      this.setCompanyName('');
      this.setCompanyType('기업');
      this.setRepresentativeName('');
      this.setUserName('');
      this.setUserEmail('');
      this.setUserPhone('');
      this.setBizNo('');
      this.setBizAddress('');
      this.setContractPersonName('');
      this.setContractPersonEmail('');
      this.setContractPersonPhone('');
      this.setTaxPersonName('');
      this.setTaxPersonEmail('');
      this.setTaxPersonPhone('');
      this.setHasSameContractTaxPerson(false);
      this.setToolSlack('');
      this.setToolTrello('');
      this.setToolZeplin('');
      this.setToolGithub('');
      this.setClientId(-1);
      this.setClientCLId('');
      self.bizFile = MyPageFileModel.create();
      self.bnkFile = MyPageFileModel.create();
      this.setIsFileUploading(false);
    },
    setCompanyName(value: string) {
      self.companyName = value;
      if (value.trim() === '') {
        self.companyNameError = '회사 이름을 입력해주세요.';
      } else {
        self.companyNameError = '';
      }
    },
    setCompanyType(value: string) {
      self.companyType = value;
    },
    setRepresentativeName(value: string) {
      self.representativeName = value;
      if (value.trim() === '') {
        self.representativeNameError = '대표자 이름을 입력해주세요.';
      } else {
        self.representativeNameError = '';
      }
    },
    setUserName(value: string) {
      self.userName = value;
      if (value.trim() === '') {
        self.userNameError = '이름을 입력해주세요.';
      } else {
        self.userNameError = '';
      }
    },
    setUserEmail(value: string) {
      self.userEmail = value;
      if (value.trim() === '') {
        self.userEmailError = '이메일을 입력해주세요.';
      } else {
        self.userEmailError = '';
      }
    },
    setUserPhone(value: string) {
      self.userPhone = value;
      if (value.trim() === '') {
        self.userPhoneError = '연락처를 입력해주세요.';
      } else {
        self.userPhoneError = '';
      }
    },
    setBizNo(value: string) {
      self.bizNo = value;
    },
    setBizAddress(value: string) {
      self.bizAddress = value;
    },
    setContractPersonName(value: string) {
      self.contractPersonName = value;
    },
    setContractPersonPhone(value: string) {
      self.contractPersonPhone = value;
    },
    setContractPersonEmail(value: string) {
      self.contractPersonEmail = value;
    },
    setTaxPersonName(value: string) {
      self.taxPersonName = value;
    },
    setTaxPersonPhone(value: string) {
      self.taxPersonPhone = value;
    },
    setTaxPersonEmail(value: string) {
      self.taxPersonEmail = value;
    },
    setHasSameContractTaxPerson(value: boolean) {
      self.hasSameContractTaxPerson = value;
    },
    setToolSlack(value: string) {
      self.toolSlack = value;
    },
    setToolTrello(value: string) {
      self.toolTrello = value;
    },
    setToolZeplin(value: string) {
      self.toolZeplin = value;
    },
    setToolGithub(value: string) {
      self.toolGithub = value;
    },
    setClientId(value: number) {
      self.clientId = value;
    },
    setClientCLId(value: string) {
      self.clientCLId = value;
    },
    setIsFileUploading(value: boolean) {
      self.isFileUploading = value;
    },
    handleClientResponse(data: any) {
      this.setCompanyName(data.name);
      this.setCompanyType(data.client_type);
      this.setRepresentativeName(data.representative_name);
      this.setBizNo(data.identity_number);
      this.setBizAddress(data.address);
      this.setContractPersonName(data.contract_person.name);
      this.setContractPersonPhone(data.contract_person.phone);
      this.setContractPersonEmail(data.contract_person.email);
      this.setTaxPersonName(data.tax_person.name);
      this.setTaxPersonPhone(data.tax_person.phone);
      this.setTaxPersonEmail(data.tax_person.email);
      this.setHasSameContractTaxPerson(data.has_same_contract_tax_person);

      if (data.tool_accounts) {
        let slack_account = data.tool_accounts.find(
          (tool_account: any) => tool_account.tool === 'slack',
        );
        if (slack_account) {
          this.setToolSlack(slack_account.account);
        }
        let trello_account = data.tool_accounts.find(
          (tool_account: any) => tool_account.tool === 'trello',
        );
        if (trello_account) {
          this.setToolTrello(trello_account.account);
        }
        let zeplin_account = data.tool_accounts.find(
          (tool_account: any) => tool_account.tool === 'zeplin',
        );
        if (zeplin_account) {
          this.setToolZeplin(zeplin_account.account);
        }
        let github_account = data.tool_accounts.find(
          (tool_account: any) => tool_account.tool === 'github',
        );
        if (github_account) {
          this.setToolGithub(github_account.account);
        }
      }

      if (data.client_id) {
        this.setClientCLId(data.client_id);
      }
    },
  }))
  // ajax functions
  .actions(self => {
    const requestParamClientPersonal = (userId: number) => {
      return {
        auth_id: userId,
        user_phone: self.userPhone,
        name: self.companyName,
        representative_name: self.representativeName,
        address: self.bizAddress,
        identity_number: self.bizNo,
        client_type: self.companyType,
        contract_person: {
          name: self.contractPersonName,
          email: self.contractPersonEmail,
          phone: self.contractPersonPhone,
        },
        tax_person: {
          name: self.taxPersonName,
          email: self.taxPersonEmail,
          phone: self.taxPersonPhone,
        },
        has_same_contract_tax_person: self.hasSameContractTaxPerson,
        tool_accounts: [
          { tool: 'slack', label: '슬랙', account: self.toolSlack },
          { tool: 'trello', label: '트렐로', account: self.toolTrello },
          { tool: 'zeplin', label: '제플린', account: self.toolZeplin },
          { tool: 'github', label: '깃헙', account: self.toolGithub },
        ],
      };
    };

    const requestParamClient = () => {
      return {
        name: self.companyName,
        representative_name: self.representativeName,
        identity_number: self.bizNo,
        address: self.bizAddress,
        client_type: self.companyType,
        contract_person: {
          name: self.contractPersonName,
          email: self.contractPersonEmail,
          phone: self.contractPersonPhone,
        },
        tax_person: {
          name: self.taxPersonName,
          email: self.taxPersonEmail,
          phone: self.taxPersonPhone,
        },
        has_same_contract_tax_person: self.hasSameContractTaxPerson,
      };
    };

    const fetchClient = flow(function* (clientId: number) {
      try {
        const { data }: { data: any } = yield axios.get(
          `/clients?id=${clientId}`,
        );
        if (data.length) {
          self.handleClientResponse(data[0]);
        }
      } catch (e) {
        console.log('MyPageStore > fetchClientPersonal error', e);
        throw e;
      }
    });

    const fetchClientPersonal = flow(function* (userId: number) {
      try {
        const { data }: { data: any } = yield axios.get(
          `/clientPersonal?auth_id=${userId}`,
        );

        self.setUserName(data[0].user_name);
        self.setUserEmail(data[0].user_email);
        self.setUserPhone(data[0].user_phone);
        self.setClientId(Number(data[0].client_id));

        if (data.length > 1) {
          self.handleClientResponse(data[1]);
          self.clientPersonalId = data[1].id;
        }
      } catch (e) {
        console.log('MyPageStore > fetchClientPersonal error', e);
        throw e;
      }
    });

    const postClientPersonal = flow(function* (userId: number) {
      try {
        yield axios.post(`/clientPersonal`, requestParamClientPersonal(userId));
      } catch (e) {
        console.log('MyPageStore > postClientPersonal error', e);
        throw e;
      }
    });
    const putClientPersonal = flow(function* (userId: number) {
      try {
        yield axios.put(
          `/clientPersonal/${self.clientPersonalId}`,
          requestParamClientPersonal(userId),
        );
      } catch (e) {
        console.log('MyPageStore > putClientPersonal error', e);
        throw e;
      }
    });
    const patchClient = flow(function* () {
      try {
        yield axios.patch(`/clients/${self.clientCLId}`, requestParamClient());
      } catch (e) {
        console.log('MyPageStore > patchUser error', e);
        throw e;
      }
    });

    const fetchClientFile = flow(function* (clientId: string, userId: number) {
      try {
        let param = clientId
          ? {
              params: { client: clientId },
            }
          : {
              params: { auth_id: userId },
            };
        const { data }: { data: any[] } = yield axios.get(
          `/clientFiles`,
          param,
        );

        const biz = data.find(x => x.filetype === 'BIZ');
        if (biz) {
          self.bizFile = MyPageFileModel.create({
            id: biz.id,
            uuid: biz.uuid,
            filetype: biz.filetype,
            name: biz.name,
            format: biz.format,
            client: biz.client,
            userId: biz.auth_id,
            filename: biz.filename,
            url: biz.file,
          });
        }

        const bnk = data.find(x => x.filetype === 'BNK');
        if (bnk) {
          self.bnkFile = MyPageFileModel.create({
            id: bnk.id,
            uuid: bnk.uuid,
            filetype: bnk.filetype,
            name: bnk.name,
            format: bnk.format,
            client: bnk.client,
            userId: bnk.auth_id,
            filename: bnk.filename,
            url: bnk.file,
          });
        }
      } catch (e) {
        console.log('fetchClientFile error', e);
        throw e;
      }
    });

    const updateClientFile = flow(function* (
      fileModel: MyPageFile,
      method: string,
    ) {
      try {
        if (method === 'post') {
          const formData = new FormData();
          formData.append('file', fileModel.file!);
          formData.append('filetype', fileModel.filetype);
          formData.append('auth_id', String(fileModel.userId));
          formData.append('filename', fileModel.filename);
          if (fileModel.client) {
            formData.append('client', fileModel.client);
          }

          const { data }: { data: any } = yield axios.post(
            '/clientFiles',
            formData,
          );
          fileModel.setId(data.id);
          fileModel.setUrl(data.file);
        } else if (method === 'put') {
          const formData = new FormData();
          formData.append('file', fileModel.file!);
          formData.append('filetype', fileModel.filetype);
          formData.append('auth_id', String(fileModel.userId));
          formData.append('filename', fileModel.filename);
          if (fileModel.client) {
            formData.append('client', fileModel.client);
          }

          const { data }: { data: any } = yield axios.put(
            `/clientFiles/${fileModel.id}`,
            formData,
          );
          fileModel.setUrl(data.file);
        } else if (method === 'delete') {
          yield axios.delete(`/clientFiles/${fileModel.id}`);
          fileModel.setId(-1);
        }
      } catch (e) {
        console.log('MyPageStore => updateClientFile error', e);
        throw e;
      }
    });

    return {
      fetchClient,
      fetchClientPersonal,
      postClientPersonal,
      putClientPersonal,
      patchClient,
      fetchClientFile,
      updateClientFile,
    };
  });

type MyPageStoreModelType = typeof MyPageStoreModel.Type;
export interface MyPageStore extends MyPageStoreModelType {}

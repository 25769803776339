import { types } from 'mobx-state-tree';
import moment from 'moment';

export interface IDepositDetailResponse {
  date: string;
  payout: number;
}
export const DepositDetailModel = types.model('DepositDetail', {
  date: types.Date,
  payout: types.number,
});

export interface IDepositResponse {
  id: number;
  calculate: number;
  project_group: string;
  project_group_name: string;
  sprints: number[];
  contract: string;
  contract_name: string;
  client_id: string;
  client_name: string;
  created_at: string;
  date_issue: string;
  date_last_deposit: string;
  payout: number;
  deposits: IDepositDetailResponse[];
  all_deposit: boolean;
}

export const mapper = (x: IDepositResponse) => {
  return {
    id: x.id,
    projectGroup: x.project_group || '',
    projectGroupName: x.project_group_name || '',
    sprints: x.sprints,
    clientId: x.client_id || '',
    clientName: x.client_name || '',
    dateIssue: moment(x.date_issue).toDate(),
    dateDeposit: moment(x.date_last_deposit).toDate(),
    payout: x.payout,
    deposits: x.deposits.map(deposit => ({
      date: moment(deposit.date).toDate(),
      payout: deposit.payout,
    })),
    allDeposit: x.all_deposit,
  };
};

export const DepositModel = types
  .model('Deposit', {
    id: types.identifierNumber,
    projectGroup: types.string,
    projectGroupName: types.string,
    sprints: types.array(types.number),
    clientId: types.string,
    clientName: types.string,
    dateIssue: types.maybeNull(types.Date),
    dateDeposit: types.maybeNull(types.Date),
    payout: types.number,
    deposits: types.array(DepositDetailModel),
    allDeposit: types.boolean,
  })
  .views(self => ({
    get sprint() {
      return self.sprints.length > 0 ? self.sprints[0] : -1;
    },
    get sprintTitle() {
      const sprint = this.sprint;
      return sprint > 0 ? `S${this.sprint}` : '';
    },
    get title() {
      if (self.projectGroup) {
        return `${self.projectGroupName} ${self.sprints.map(sprint =>
          sprint === 0 ? 'PRE' : `S${sprint}`,
        )}`;
      }
      return '기타 입금';
    },
    get deposited() {
      return self.deposits.reduce((prev, curr) => prev + curr.payout, 0);
    },
    get shortage() {
      return self.payout - this.deposited;
    },
    // get dateDeposit() {
    //   return sortBy(self.deposits, deposit => deposit.date)[self.deposits.length-1].date
    // }
  }))
  .actions(self => ({
    // isAllDeposited(): boolean {
    //   return self.payout <= self.deposited ? true : false;
    // }
  }))
  .actions(self => {
    return {};
  });

type DepositModelType = typeof DepositModel.Type;
export interface IDeposit extends DepositModelType {}

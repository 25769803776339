import { flow, types } from 'mobx-state-tree';

// import axios from "axios";

export interface FeaturedResponse {
  id: number;
  type: string;
  type_etc: string;
  tags: string;
  title: string;
  thumbnail: string;
  featured: boolean;
  status: string;
}

export const Mapper = (x: FeaturedResponse) => {
  return {
    id: x.id,
    type: x.type,
    typeEtc: x.type_etc,
    tags: x.tags,
    title: x.title,
    thumbnail: x.thumbnail,
    featured: x.featured,
    status: x.status,
  };
};

export const FeaturedProjectModel = types.model('FeaturedProject', {
  id: types.number,
  type: types.string,
  typeEtc: types.string,
  tags: types.string,
  title: types.string,
  thumbnail: types.string,
  featured: types.boolean,
  status: types.string,
});

type FeaturedProjectModelType = typeof FeaturedProjectModel.Type;
export interface IFeaturedProjectModel extends FeaturedProjectModelType {}

export const FeaturedProjectStoreModel = types
  .model('FeaturedProjectStore', {
    results: types.array(FeaturedProjectModel),
  })
  .views(self => ({}))
  .actions(self => {
    const fetchFeaturedProject = flow(function* () {
      try {
        const data = [
          {
            // 기본 디폴트로 오는 거라 일단 디폴트로 정리 By 동동 - 나중에 API만 연결하면 됨
            id: 23,
            type: 'develop',
            type_etc: 'WEBSITE',
            title: '제주 with kakao',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/01_%E1%84%8F%E1%85%A1%E1%84%8F%E1%85%A1%E1%84%8B%E1%85%A9%E1%84%8C%E1%85%A6%E1%84%8C%E1%85%AE.jpg',
            tags: '#제주 #카카오 #카카오스페이스 #Jspace',
            featured: true,
            status: 'complete',
          },
          {
            id: 22,
            type: 'develop',
            type_etc: 'APP',
            title: '자연을 읽다 x 세상을 잇다',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/02_%E1%84%82%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8E%E1%85%A7%E1%84%85%E1%85%B5%E1%86%BC.jpg',
            tags: '#자연관찰 #시민과학프로젝트 #생태지도',
            featured: true,
            client: {
              id: 'b9de579d-e42e-445c-ae79-05e997ce9103',
              affiliation: '시소',
            },
            rufrees: [
              {
                id: 'a8325587-f4e5-4ade-a458-f1dabdc0d529',
                photo: null,
                nickname: '시소(seeso)',
                job: 'Project Manager',
              },
            ],
            date_register: '2018-06-13T17:37:53.924117',
            status: 'complete',
          },
          {
            id: 21,
            type: 'develop',
            type_etc: 'WEBSITE',
            title: 'LIVE, WORK, PLAY TOGETHER',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/03_%E1%84%89%E1%85%B0%E1%84%8B%E1%85%A5%E1%84%8B%E1%85%AF%E1%86%AB.jpg',
            tags: '#쉐어원 #공유 #라이프스타일 #일상생활상',
            featured: true,
            client: {
              id: 'b9de579d-e42e-445c-ae79-05e997ce9103',
              affiliation: '시소',
            },
            rufrees: [
              {
                id: 'a8325587-f4e5-4ade-a458-f1dabdc0d529',
                photo: null,
                nickname: '시소(seeso)',
                job: 'Project Manager',
              },
            ],
            date_register: '2018-06-13T17:37:20.788377',
            status: 'complete',
          },
          {
            id: 20,
            type: 'design',
            type_etc: 'BRANDING',
            title: '새로운 흐름이 일상이 되도록',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/04_yellowdog.jpg',
            tags: '#벤처캐피탈 #소셜임팩트',
            featured: true,
            client: {
              id: 'b9de579d-e42e-445c-ae79-05e997ce9103',
              affiliation: '시소',
            },
            rufrees: [
              {
                id: 'a8325587-f4e5-4ade-a458-f1dabdc0d529',
                photo: null,
                nickname: '시소(seeso)',
                job: 'Project Manager',
              },
            ],
            date_register: '2018-06-13T17:36:32.942361',
            status: 'complete',
          },
          {
            id: 19,
            type: 'develop',
            type_etc: 'WEBSITE',
            title: '공장의 단골이 되다',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/05_%E1%84%83%E1%85%A1%E1%86%AB%E1%84%80%E1%85%A9%E1%86%AF%E1%84%80%E1%85%A9%E1%86%BC%E1%84%8C%E1%85%A1%E1%86%BC.jpg',
            tags: '#단골공장 #크라우드펀딩 #소셜벤처',
            featured: true,
            client: {
              id: 'b9de579d-e42e-445c-ae79-05e997ce9103',
              affiliation: '시소',
            },
            rufrees: [
              {
                id: 'a8325587-f4e5-4ade-a458-f1dabdc0d529',
                photo: null,
                nickname: '시소(seeso)',
                job: 'Project Manager',
              },
            ],
            date_register: '2018-06-13T17:35:57.501482',
            status: 'complete',
          },
          {
            id: 18,
            type: 'develop',
            type_etc: 'WEBSITE',
            title: 'Benefit Plus, BPLUS',
            thumbnail:
              'https://seeso-backend.s3.amazonaws.com/uploads/projects/06_%E1%84%87%E1%85%B5%E1%84%91%E1%85%B3%E1%86%AF%E1%84%85%E1%85%A5%E1%84%89%E1%85%B3.jpg',
            tags: '#소셜투자 #금융플랫폼 #소셜임팩트',
            featured: true,
            client: {
              id: 'b9de579d-e42e-445c-ae79-05e997ce9103',
              affiliation: '시소',
            },
            rufrees: [
              {
                id: 'a8325587-f4e5-4ade-a458-f1dabdc0d529',
                photo: null,
                nickname: '시소(seeso)',
                job: 'Project Manager',
              },
            ],
            date_register: '2018-06-13T17:35:15.710819',
            status: 'complete',
          },
        ];
        const newFeatureds = data.map(x =>
          FeaturedProjectModel.create(Mapper(x)),
        );
        self.results.replace(newFeatureds);
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchFeaturedProject,
    };
  });

type FeaturedProjectStoreModelType = typeof FeaturedProjectStoreModel.Type;
export interface IFeaturedProjectStoreModel
  extends FeaturedProjectStoreModelType {}

import React, { useState } from 'react';

import useVisible from '../Common/hooks/useVisible';

export interface ISprintItemProps {
  id: string;
  title: string;
}

export interface ISprintProps {
  isArchived?: boolean;
  sprints: ISprintItemProps[]; // 스프린트 후보군들
  values: string[]; // 선택 스프린트 값
  handleAdd?: (id: string) => void;
  handleRemove?: (id: string) => void;
  handleCreate?: (sprint: string) => void;
  handleDelete?: (sprint: string) => void;
}

export const Sprint: React.SFC<ISprintProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [newSprint, setNewSprint] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, dataset } = e.target;

    if (dataset.id) {
      if (checked) {
        props.handleAdd && props.handleAdd(dataset.id);
      } else {
        props.handleRemove && props.handleRemove(dataset.id);
      }
    }
  };

  return (
    <li>
      <div className={`select-w ${isVisible ? 'selected' : ''}`} ref={ref}>
        {/*<!-- 하위메뉴 열림 "slelected" className 추가 -->*/}
        <button
          type="button"
          disabled={props.isArchived ? true : false}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          스프린트
        </button>
        <div className="option-box">
          <fieldset className="add">
            <legend>
              <label htmlFor="laAdd">라벨추가</label>
            </legend>
            <input
              type="text"
              id="laAdd"
              placeholder="추가"
              value={newSprint}
              onChange={e => {
                setNewSprint(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  props.handleCreate &&
                    newSprint &&
                    props.handleCreate(newSprint);
                  setNewSprint('');
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                props.handleCreate &&
                  newSprint &&
                  props.handleCreate(newSprint);
                setNewSprint('');
              }}
            >
              <span>추가</span>
            </button>
          </fieldset>
          <ul>
            {props.sprints.map(sprint => (
              <li key={sprint.id}>
                <input
                  type="checkbox"
                  id={`as${sprint.id}`}
                  data-id={sprint.id}
                  checked={props.values.includes(sprint.title)}
                  onChange={handleChange}
                />
                <label htmlFor={`as${sprint.id}`}>{sprint.title}</label>

                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => {
                    props.handleDelete && props.handleDelete(sprint.title);
                  }}
                >
                  <span>라벨삭제</span>
                </button>
              </li>
            ))}

            {/* <li><input type="checkbox" id="asCh23" checked={ true } onChange={ () => {} } /><label htmlFor="asCh23">선택값 3</label></li>
                  <li><input type="checkbox" id="asCh24" /><label htmlFor="asCh24">선택값 4</label></li>
                  <li><input type="checkbox" id="asCh25" /><label htmlFor="asCh25">선택값 5</label></li> */}
          </ul>
        </div>
        <div className="cate-w">
          {props.values.map((sprint, i) => (
            <span key={i} className="spt">
              {sprint}
            </span>
          ))}
        </div>
      </div>
    </li>
  );
};

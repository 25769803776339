import React, { Dispatch, FC } from 'react';
import { SkipNav } from '../molecules/MainTemplate/SkipNav';
import { MainSection } from '../organisms/MainTemplate/MainSection';

type SectionName = 'top' | 'intro' | 'partners' | 'service' | 'rnd';

interface HomePageProps {
  isSideMenuOpen?: boolean;
  setIsSideMenuOpen?: Dispatch<React.SetStateAction<boolean>>;
  focusedSection?: SectionName;
  setFocusedSection?: Dispatch<React.SetStateAction<SectionName>>;
}

const HomePage: FC<HomePageProps> = () => {
  return (
    <>
      <SkipNav />
      <MainSection />
    </>
  );
};

export default HomePage;
export type { SectionName, HomePageProps };

import { groupBy } from 'lodash';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { Alert } from '../components/SeesoBoard/Common';
import { TaskCard } from '../components/SeesoBoard/Task';
import { KeywordField } from '../components/SeesoBoard/TaskFilter';

import { AppStore } from '../store/AppStore';
import { ITask } from '../store/SeesoBoard/models/Task';
import { EActiveCompoment } from '../store/UIStore';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}
interface IState {
  keyword: string;
  batched: boolean;
  selectedTask: ITask | null;
}

@inject('appStore')
@observer
export default class DeletedTaskContainer extends Component<IProps, IState> {
  state = {
    batched: false,
    keyword: '',
    selectedTask: null,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  get boardStore() {
    return this.injected.appStore.boardStore;
  }

  get uiStore() {
    return this.injected.appStore.uiStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  private getAllArchivedTasksId() {
    const { archivedTasks: tasks } = this.boardStore;

    return tasks.map(task => task.id);
  }

  private getArchivedTasksByDate() {
    const { taskBoard: board, archivedTasks: tasks } = this.boardStore;
    const { keyword } = this.state;

    return board
      ? groupBy(
          tasks
            .filter(task => task.title.includes(keyword))
            .sort((a, b) => {
              //순서 최신 아카이브순
              const dateB = new Date(b.date_archived ? b.date_archived : 0);
              const dateA = new Date(a.date_archived ? a.date_archived : 0);
              return dateB.valueOf() - dateA.valueOf();
            }),
          t => moment(t.date_archived).format('YYYY/MM/DD'),
        )
      : [];
  }

  private getAssigneeName(id: number) {
    return this.boardStore.taskBoard?.members
      .slice()
      .find(member => member.id === id)?.name;
  }

  private isSelectedTask(taskId: number) {
    return this.uiStore.selectedIds.includes(String(taskId));
  }

  @computed
  get selectedIds() {
    return this.boardStore.taskMultiSelect.task_ids.slice();
  }

  render() {
    const { taskBoard: board, taskMultiSelect } = this.boardStore;
    const { activeComponent, setActiveCompoment } = this.uiStore;
    const className =
      activeComponent === EActiveCompoment.DELETEDTASK
        ? 'deleted-task selected'
        : 'deleted-task';

    if (!board) return <></>;

    const archivedTasks: any = this.getArchivedTasksByDate();
    const allArchivedTasksIds: number[] = this.getAllArchivedTasksId();

    return (
      <section className={className}>
        <h1>삭제된 태스크</h1>
        <div className="dt-box">
          <KeywordField
            value={this.state.keyword}
            handleSearch={keyword =>
              this.setState({
                keyword: keyword,
              })
            }
          />

          <p>
            <input
              type="checkbox"
              id="tChoice"
              checked={
                allArchivedTasksIds.length === taskMultiSelect.countTask
                  ? true
                  : false
              }
              onChange={e => {
                if (allArchivedTasksIds.length === taskMultiSelect.countTask) {
                  // taskMultiSelect.removeTaskIds(allArchivedTasksIds);
                  taskMultiSelect.clearTaskIds();
                } else {
                  taskMultiSelect.addTaskIds(allArchivedTasksIds);
                }
              }}
            />
            <label htmlFor="tChoice">전체 선택</label>
          </p>

          <div className="viewport">
            {Object.keys(archivedTasks).map((key, i) => {
              const tasks: ITask[] = archivedTasks[key];
              // console.log(archivedTasks[key]);
              return (
                <div key={key} className="in-box">
                  <p>{key}</p>

                  {tasks.map(task => {
                    return (
                      <div
                        key={task.id}
                        className="card-chk"
                        onClick={(
                          e: React.MouseEvent<HTMLInputElement, MouseEvent>,
                        ) => {
                          e.preventDefault();
                          e.stopPropagation();
                          console.log('click: ', task.id);

                          if (e.shiftKey) {
                            console.log('Ctrl+click has just happened!');
                            taskMultiSelect.toggleTaskId(task.id);
                          } else {
                            // this.boardStore.setCurrentTaskId(String(task.id));
                            this.uiStore.setReadTask(String(task.id));
                          }
                        }}
                      >
                        <input
                          type="checkbox"
                          id={`cdChk${task.task_id}`}
                          checked={this.isSelectedTask(task.id)}
                          readOnly={true}
                        />
                        <label htmlFor={`cdChk${task.task_id}`}>
                          <span>{task.title} 선택</span>
                        </label>

                        <TaskCard
                          isSelected={this.selectedIds.includes(task.id)}
                          title={task.title}
                          dueDate={task.due_date}
                          assignees={task.person_in_charge_list.map(person =>
                            this.getAssigneeName(person),
                          )}
                          sprints={task.sprint_list.map(sprint => sprint.title)}
                          labels={task.label_list.map(label => label.title)}
                          subtaskCount={task.sub_task_lists.length}
                          doneSubtaskCount={0}
                          attachmentCount={
                            task.task_links.length + task.task_files.length
                          }
                          commentCount={task.task_comments.length}
                          onClick={() => {
                            console.log(task.id);
                          }}
                          onClickWithShift={() => {
                            taskMultiSelect.toggleTaskId(task.id);
                          }}
                        />

                        <div className="s-btn">
                          <button
                            type="button"
                            className="refresh"
                            onClick={async e => {
                              e.stopPropagation();

                              this.uiStore.showToast(
                                true,
                                true,
                                '선택한 태스크 카드 복구중입니다. ( 서버 응답속도 문제 임시알림 )',
                              );
                              await task.archive(false);

                              if (this.boardStore.taskBoard) {
                                await this.boardStore.taskBoard?.fetchTaskList(
                                  task.task_list,
                                );
                                // await this.boardStore.fetchTaskBoard();
                                // await this.boardStore.taskBoard.fetchTaskList(task.task_list);
                              }
                              this.uiStore.closeToase();
                            }}
                          >
                            <span>복구</span>
                          </button>

                          <button
                            type="button"
                            className="delete"
                            onClick={async e => {
                              e.stopPropagation();

                              this.setState({ selectedTask: task });
                            }}
                          >
                            <span>삭제</span>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>

        {taskMultiSelect.countTask > 0 && (
          <div className="btn">
            <button
              type="button"
              onClick={async () => {
                this.setState({ batched: true });
              }}
            >
              완전 삭제
            </button>
            <button
              type="button"
              onClick={async () => {
                this.uiStore.showToast(
                  true,
                  true,
                  '선택한 태스크 카드 복구중입니다. ( 서버 응답속도 문제 임시알림 )',
                );
                await this.boardStore.archiveTasks(
                  taskMultiSelect.task_ids.slice(),
                  false,
                );
                if (this.boardStore.taskBoard) {
                  taskMultiSelect.clearTaskIds();
                  await this.boardStore.fetchTaskBoard();
                }
                this.uiStore.closeToase();
              }}
            >
              복구
            </button>
          </div>
        )}

        <button
          type="button"
          className="btn-close"
          onClick={() => {
            this.setState({ keyword: '' });

            setActiveCompoment();
          }}
        >
          <span>알림 창 닫기</span>
        </button>

        {/* 태스크 삭제 확인 Alert */}
        <Alert
          isOpen={!!this.state.selectedTask || this.state.batched}
          onConfirm={async () => {
            if (this.state.batched) {
              this.uiStore.showToast(
                true,
                true,
                '선택한 태스크 카드 완전삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
              );
              //복수 태스크 일괄 삭제 처리
              await this.boardStore.deleteTasks(
                taskMultiSelect.task_ids.slice(),
              );
              if (this.boardStore.taskBoard) {
                await this.boardStore.fetchTaskBoard();
              }
              this.uiStore.closeToase();
            } else {
              //단일 태스크 삭제 처리
              if (this.state.selectedTask !== null) {
                const { selectedTask } = this.state;
                this.uiStore.showToast(
                  true,
                  true,
                  '선택한 태스크 카드 완전삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
                );
                if (selectedTask) {
                  // @ts-ignores
                  await this.boardStore.deleteTasks([selectedTask.id]);
                }
                if (this.boardStore.taskBoard) {
                  await this.boardStore.fetchTaskBoard();
                }
                this.uiStore.closeToase();
              }
            }

            taskMultiSelect.clearTaskIds();
            this.setState({ batched: false, selectedTask: null });
          }}
          onCancel={() => this.setState({ batched: false, selectedTask: null })}
        >
          <p>
            선택한 태스크를
            <br /> 완전 삭제하시겠습니까?
          </p>
        </Alert>
      </section>
    );
  }
}

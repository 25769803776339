import React from 'react';

import { IMessage, Message } from './Message';

export interface INotification {
  notifications: IMessage[];
  onClose: () => void;
}
export const Notification: React.SFC<INotification> = ({
  notifications,
  onClose,
}) => {
  return (
    <div className="noti-box">
      <h4>알림</h4>
      <ul>
        {notifications.map(notification => (
          <Message key={notification.id} {...notification} />
        ))}
      </ul>

      <button type="button" className="btn-close" onClick={onClose}>
        <span>알림 창 닫기</span>
      </button>
    </div>
  );
};

import { types } from 'mobx-state-tree';

export const TaskLinkModel = types
  .model('TaskLink', {
    id: types.number,
    created_at: types.string,
    updated_at: types.string,
    link: types.string,
    task: types.string,
    writer: types.number,
  })
  .actions(self => ({
    // getEmpty() {
    //   return TaskLinkModel.create({
    //     id: -1,
    //     created_at: '',
    //     updated_at: '',
    //     link: '',
    //     task: '',
    //     writer: -1
    //   })
    // }
  }));
type TaskLinkType = typeof TaskLinkModel.Type;
export interface TaskLink extends TaskLinkType {}

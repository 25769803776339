import React, { useState } from 'react';

import { DraggableProvided } from 'react-beautiful-dnd';

import { AddPost } from './AddPost';

export interface IBulletinProps {
  id: number;
  title: string;

  dndProvided: DraggableProvided; //for react-beautiful-dnd

  handleChangeTitle: (title: string) => void;
  handleDelete: () => void;
  handleAddPost: (title: string) => void;
}

export const Bulletin: React.SFC<IBulletinProps> = props => {
  const [isEditing, setIsEditing] = useState<boolean>(
    props.id < 0 ? true : false,
  );
  const [title, setTitle] = useState<string>(props.title);
  const [showAddPost, setShowAddPost] = useState<boolean>(false);

  const placeholder = document.createElement('li');
  placeholder.draggable = true;
  placeholder.className = 'placeholder';

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const title = (e.target as HTMLInputElement).value;
      props.handleChangeTitle(title);
      setTitle(title);
      setIsEditing(false);
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.handleChangeTitle((e.target as HTMLInputElement).value);
    setIsEditing(false);
  };

  return (
    <li
      ref={props.dndProvided.innerRef}
      {...props.dndProvided.draggableProps}
      {...props.dndProvided.dragHandleProps}
    >
      <div className={`${isEditing ? 'modify' : ''}`}>
        {isEditing ? (
          <input
            type="text"
            value={title}
            title="메뉴입력"
            autoFocus={true}
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
          ></input>
        ) : (
          <a href="#">{title}</a>
        )}

        <button type="button" className="btn-move">
          <span>메뉴이동</span>
        </button>

        <span className="btn-md">
          <button
            type="button"
            className="modify"
            onClick={() => setIsEditing(true)}
          >
            <span>수정</span>
          </button>

          <button type="button" className="delete" onClick={props.handleDelete}>
            <span>삭제</span>
          </button>
        </span>
      </div>

      {/*
       * drop 가능한 영억으로 설정.
       * https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API
       * onDrop, onDragOver
       */}

      {props.children}

      {showAddPost && (
        <AddPost
          handleAdd={async (title: string) => {
            props.handleAddPost(title);
            setShowAddPost(false);
          }}
          handleCancel={() => {
            setShowAddPost(false);
          }}
        />
      )}

      <button
        type="button"
        className="btn-item-add"
        onClick={() => setShowAddPost(true)}
      >
        아이템 추가
      </button>
    </li>
  );
};

import { flow, types } from 'mobx-state-tree';
import { EResponseState } from '../../enums';

import * as API from '../apis';
import { SubTaskListModel } from './SubTaskList';
import { TaskCommentModel } from './TaskComment';
import { TaskFileModel } from './TaskFile';
import { TaskLabelModel } from './TaskLabel';
import { TaskLinkModel } from './TaskLink';

import { TaskSprintModel } from './TaskSprint';

/*
  TODO: store 설계 룰
  * collection사용시, types.map과 types.array 속성을 잘 이해하여 설계한다.
  ** 왠만하면 types.map 사용하기.
  * types.identifier (혹은 types.identifierNumber) 설정할 것.
  * 각 모델은 state 값을 가진다.
  ** 서버 호출 응닶 상태 값.
  ** 필드명: state
  ** 상태 값: initial / pending / done / error
*/

export const TaskModel = types
  .model('Task', {
    id: types.number,
    created_at: types.string,
    updated_at: types.string,
    order: types.number,
    // task_id: types.string,
    task_id: types.identifier,
    task_no: types.number,
    title: types.string,
    description: types.string,
    due_date: types.union(types.string, types.null),
    is_archived: types.boolean,
    date_archived: types.union(types.string, types.null),
    task_board: types.string,
    task_list: types.string,
    person_in_charge_list: types.optional(types.array(types.number), []),
    sprint_list: types.array(TaskSprintModel),
    label_list: types.array(TaskLabelModel),
    sub_task_lists: types.array(SubTaskListModel),
    task_links: types.array(TaskLinkModel),
    task_files: types.array(TaskFileModel),
    task_comments: types.array(TaskCommentModel),

    isLoading: true,
    state: EResponseState.INITIAL, // API 호출 응답 상태값.
    // state: types.enumeration("State", EResponseState)
  })
  .views(self => ({
    get labelsTitle() {
      return self.label_list.map(label => label.title);
    },
    get sprintsTitle() {
      return self.sprint_list.map(sprint => sprint.title);
    },
    get subTasks() {
      return self.sub_task_lists.flatMap(subTasks => subTasks.sub_tasks);
    },
    get doneSubtask() {
      return this.subTasks.filter(subTask => subTask.checked);
    },
    get attachmentCount() {
      return self.task_files.length + self.task_links.length;
    },
  }))
  .actions(self => ({
    setOrder(order: number) {
      self.order = order;
    },
  }))
  .actions(self => {
    const setState = (state: string) => {
      self.state = state;
    };

    const archive = flow(function* (is_archived: boolean) {
      setState(EResponseState.PENDING);
      try {
        yield API.Task.archived(self.id, is_archived);

        setState(EResponseState.DONE);
      } catch (e) {
        setState(EResponseState.ERROR);
        throw e;
      }
    });
    const deleteSelf = flow(function* () {
      {
        try {
          yield API.Task.delete(self.id);
        } catch (e) {
          throw e;
        }
      }
    });
    const updateTitle = flow(function* (title: string) {
      self.isLoading = true;
      setState(EResponseState.PENDING);

      try {
        yield API.Task.updateTitle(self.id, title);
        self.title = title;

        setState(EResponseState.DONE);
      } catch (e) {
        setState(EResponseState.ERROR);
        throw e;
      }

      self.isLoading = true;
    });

    return {
      archive,
      deleteSelf,
      updateTitle,
    };
  });
type TaskType = typeof TaskModel.Type;
export interface ITask extends TaskType {}

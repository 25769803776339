import * as React from 'react';

export interface IMessage {
  id: string;
  isNew: boolean;
  innerRef?: React.RefObject<HTMLLIElement> | null;
  useDelete: boolean;
  message: string;
  date: string;
  onClick: () => void;
  onDelete?: () => void;
}
export const Message: React.SFC<IMessage> = ({
  isNew,
  innerRef,
  message,
  date,
  useDelete,
  onClick,
  onDelete,
}) => (
  <li className={isNew ? 'new' : ''} onClick={onClick} ref={innerRef || null}>
    {message}
    <span>{date}</span>
    {useDelete && (
      <button
        type="button"
        onClick={e => {
          e.stopPropagation();
          onDelete?.();
        }}
      >
        <span>알림삭제</span>
      </button>
    )}
  </li>
);

import moment from 'moment';
import React, { useEffect, useState } from 'react';

export interface ILinkProps {
  // isEditing?: boolean;
  id: number;
  link: string;
  date: string;
  handleUpdate?: (link: string) => void;
  handleDelete?: () => void;
}

export const Link: React.SFC<ILinkProps> = props => {
  const [isEditing, setIsEditing] = useState(props.id < 0);
  const [link, setLink] = useState(props.link);

  useEffect(() => {
    setIsEditing(props.id < 0);
  }, [props.id]);

  const save = (value: string) => {
    // let link = value;
    // if(!link.startsWith('http')) {
    //   link = 'http://' + link;
    // }
    props.handleUpdate && props.handleUpdate(value);

    setLink(value);
    setIsEditing(false);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    save((e.target as HTMLInputElement).value);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      save((e.target as HTMLInputElement).value);
    }

    if (e.key === 'Escape') {
      save((e.target as HTMLInputElement).value);
    }
  };

  const handleMoveLink = () => {
    if (!props.link) return;
    window.open(props.link, '_blank');
  };

  return (
    <li className={`${isEditing ? 'edit' : ''}`} onClick={handleMoveLink}>
      <i className="link">
        <span>링크</span>
      </i>
      <div>
        <strong>
          {isEditing ? (
            <input
              autoFocus={true}
              type="text"
              value={link}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              onClick={e => e.stopPropagation()}
            />
          ) : (
            props.link
          )}
        </strong>

        {props.date && (
          <span>{moment(props.date).format('YYYY/MM/DD hh:mm A')}</span>
        )}
      </div>

      {props.id > 0 && (
        <span className="btn-md">
          <button
            type="button"
            className="modify"
            onClick={e => {
              e.stopPropagation();
              setIsEditing(true);
            }}
          >
            <span>수정</span>
          </button>
          <button
            type="button"
            className="delete"
            onClick={e => {
              e.stopPropagation();
              props.handleDelete && props.handleDelete();
            }}
          >
            <span>삭제</span>
          </button>
        </span>
      )}
    </li>
  );
};

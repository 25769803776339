import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import axios from 'axios';

import * as historyLibrary from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import React, { useEffect } from 'react';
import { Router } from 'react-router';
import styled from 'styled-components/macro';
import CheckLogin from './CheckLogin';
import Routes from './Routes';
import { AppStoreModel } from './store/AppStore';
import {
  askForPermissioToReceiveNotifications,
  initializeFirebaseConfig,
} from './utils/firebase/push-notification';

axios.defaults.baseURL = 'http://api-dev.cpms.seeso.kr:8000';

if (process.env.REACT_APP_BASEURL) {
  axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
}

const routerStore = new RouterStore();
const rootStore = AppStoreModel.create({});
const browserHistory = historyLibrary.createBrowserHistory();
const history = syncHistoryWithStore(browserHistory, routerStore);

const Wrapper = styled.div`
  min-height: 100vh;

  .seeso-board-spinner {
    min-height: 1150px;
  } // 시소보드 스피너
`;

const App: React.FC = () => {
  useEffect(() => {
    async function initializeFirebase() {
      await initializeFirebaseConfig();
      const messaging = await askForPermissioToReceiveNotifications();
      if (messaging) {
        messaging.onMessage((payload: any) => {
          if (payload['data']['cmd'].includes('action')) {
            let listener = new BroadcastChannel(payload['data']['event']);
            listener.postMessage(payload['data']);
          }
        });

        const fcm_token = await messaging.getToken();
        window.localStorage.setItem('fcm_token', fcm_token);
        // updateFcmToken to UserStore
      }
    }
    initializeFirebase()
      // .then(()=>console.log("fcm ok"))
      .catch(() => console.log('fcm failed'));
  }, []);
  return (
    <Provider routerStore={routerStore} appStore={rootStore}>
      <Wrapper className="App">
        <Router history={history}>
          <CheckLogin appStore={rootStore}>
            <Routes />
          </CheckLogin>
        </Router>
      </Wrapper>
    </Provider>
  );
};

export default App;

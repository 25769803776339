import React from 'react';

import { Calendar } from '../Common';
import useVisible from '../Common/hooks/useVisible';

export interface IDueDateFieldProps {
  title: string;
  value: string;
  onChange: (date: string) => void;
}

export const DueDateField: React.SFC<IDueDateFieldProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  // const [ showCalendar, setShowCalendar ] = useState(false);

  let className = 'select-w';
  className += isVisible ? ' selected' : '';

  return (
    <div className={className} ref={ref}>
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {props.title}
      </button>

      {isVisible && (
        <Calendar
          style={{ right: 0 }}
          selectedDate={props.value || undefined}
          handleSelect={(date: string) => {
            props.onChange(date);

            setIsVisible(!isVisible);
          }}
        />
      )}
    </div>
  );
};

import React, { useRef, useState } from 'react';

import { MentionDropdown } from '../Common/Fields/MentionDropdown';

export interface IAddCommentProps {
  members: string[];
  handleCreate?: (value: string) => void;
}

export const AddComment: React.SFC<IAddCommentProps> = props => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [comment, setComment] = useState<string>('');

  // 멘션 기능
  const [mentionPosition, setMentionPosition] = useState<number>(-1);
  const [toggleMention, setToggleMention] = useState(false);

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    (e.target as HTMLTextAreaElement).selectionStart = comment.length + 1;
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    const { key, keyCode } = e;
    const atKey = keyCode == 50 && key === '@',
      deleteKey = keyCode == 46,
      backspaceKey = keyCode == 8;

    if (atKey) {
      // @ 눌렀을 때,
      setToggleMention(true);

      setMentionPosition(target.selectionStart + 1);
      return;
    }

    if (deleteKey || backspaceKey) {
      // @ 지웠을 때,
      const deletedChar = target.value[target.selectionStart - 1];
      if (deletedChar === '@') {
        setToggleMention(false);
        return;
      }
    }
  };

  const moveTextareaPosition = (position: number) => {
    if (!textAreaRef.current) return;

    const target = textAreaRef.current as HTMLTextAreaElement;

    target.focus();
    target.selectionStart = target.selectionEnd = position;
  };

  const onMentionSelect = (member: string) => {
    setComment(
      comment.slice(0, mentionPosition) +
        member +
        ' ' +
        comment.slice(mentionPosition),
    );
    moveTextareaPosition(mentionPosition + member.length + 1);
    setToggleMention(false);
  };

  const onCancel = () => {
    setComment('');
  };
  const onSave = () => {
    comment.length !== 0 && props.handleCreate && props.handleCreate(comment);
    setComment('');
  };

  return (
    <>
      <textarea
        id="cmt"
        placeholder="댓글 입력"
        ref={textAreaRef}
        value={comment}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setComment(e.target.value);
        }}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
      />

      {toggleMention && (
        <MentionDropdown
          members={props.members}
          onSelect={(member: string) => {
            onMentionSelect(member);
          }}
        />
      )}

      {comment.length > 0 && (
        <div className="btn-def">
          <a href="#" onClick={onCancel}>
            취소
          </a>
          <a href="#" onClick={onSave}>
            저장
          </a>
        </div>
      )}
    </>
  );
};

/*
자동 크기 늘리기
https://medium.com/@lucasalgus/creating-a-custom-auto-resize-textarea-component-for-your-react-web-application-6959c0ad68bc
*/

import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from '../Common';

import { MentionDropdown } from '../Common/Fields/MentionDropdown';

export interface ICommentProps {
  showAction?: boolean;
  username: string;
  comment: string;
  date: string;
  members: string[];
  handleChange?: (value: string) => void;
  handleDelete?: () => void;
}

export const Comment: React.SFC<ICommentProps> = props => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const [comment, setComment] = useState<string>(props.comment);
  const [mentionPosition, setMentionPosition] = useState<number>(-1);
  const [isEdit, setIsEdit] = useState(false);
  const [toggleMention, setToggleMention] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  useEffect(() => {
    setComment(props.comment);
  }, [props.comment]);

  const moveTextareaPosition = (position: number) => {
    if (!textAreaRef.current) return;

    const target = textAreaRef.current as HTMLTextAreaElement;

    target.focus();
    target.selectionStart = target.selectionEnd = position;
  };

  const reg_comment = (comment: string) => {
    return comment
      .replace(/(@\S+)/gi, '<em>$1</em>')
      .replace(/(\n{1})/gi, '<br/>');
  };

  const onFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    (e.target as HTMLTextAreaElement).selectionStart = comment.length + 1;
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const target = e.target as HTMLTextAreaElement;
    const keyCode = e.keyCode;
    const atKey = keyCode == 50,
      deleteKey = keyCode == 46,
      backspaceKey = keyCode == 8;

    if (atKey) {
      // @ 눌렀을 때,
      setToggleMention(true);

      setMentionPosition(target.selectionStart + 1);
      return;
    }

    if (deleteKey || backspaceKey) {
      // @ 지웠을 때,
      const deletedChar = target.value[target.selectionStart - 1];
      if (deletedChar === '@') {
        setToggleMention(false);
        return;
      }
    }
  };

  const onMentionSelect = (member: string) => {
    setComment(
      comment.slice(0, mentionPosition) +
        member +
        ' ' +
        comment.slice(mentionPosition),
    );
    moveTextareaPosition(mentionPosition + member.length + 1);
    setToggleMention(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const onCancel = () => {
    setComment(props.comment);
    setIsEdit(false);
  };
  const onSave = () => {
    comment.length !== 0 && props.handleChange && props.handleChange(comment);
    setComment(comment);
    setIsEdit(false);
  };

  return (
    <>
      <li className={`${isEdit ? 'edit' : ''}`}>
        <i>{props.username}</i>
        {isEdit ? (
          <div>
            <textarea
              autoFocus={true}
              title="댓글수정"
              value={comment}
              ref={textAreaRef}
              onChange={onChange}
              onKeyDown={onKeyDown}
              onFocus={onFocus}
            ></textarea>
            {toggleMention && (
              <MentionDropdown
                members={props.members}
                onSelect={(member: string) => {
                  onMentionSelect(member);
                }}
              />
            )}

            <span className="date">
              {moment(props.date).format('YYYY/MM/DD hh:mm A')}
            </span>

            <div className="btn-def">
              <a href="#" onClick={onCancel}>
                취소
              </a>
              <a href="#" onClick={onSave}>
                저장
              </a>
            </div>
          </div>
        ) : (
          <div>
            <p
              dangerouslySetInnerHTML={{ __html: reg_comment(props.comment) }}
            />
            <span className="date">
              {moment(props.date).format('YYYY/MM/DD hh:mm A')}
            </span>
          </div>
        )}

        {!isEdit && props.showAction && (
          <span className="btn-md">
            <button
              type="button"
              className="modify"
              onClick={() => setIsEdit(true)}
            >
              <span>수정</span>
            </button>
            <button
              type="button"
              className="delete"
              onClick={() => setShowDeleteAlert(true)}
            >
              <span>삭제</span>
            </button>
          </span>
        )}
      </li>

      <Alert
        isOpen={showDeleteAlert}
        onCancel={() => setShowDeleteAlert(false)}
        onConfirm={() => {
          props.handleDelete && props.handleDelete();
          setShowDeleteAlert(false);
        }}
      >
        <p>
          선택한 댓글을
          <br /> 삭제하시겠습니까?
        </p>
      </Alert>
    </>
  );
};

import moment from 'moment';
import React, { useState } from 'react';

import { Calendar } from '../Common';
import useVisible from '../Common/hooks/useVisible';

export interface IDueDateRangeFieldProps {
  title: string;
  startValue: string;
  endValue: string;
  handleChangeStart: (date: string) => void;
  handleChangeEnd: (date: string) => void;
}

export const DueDateRangeField: React.SFC<IDueDateRangeFieldProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const [dateType, setDateType] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);

  let className = 'select-w';
  className += isVisible ? ' selected' : '';

  const clickInput = (dateType: string) => {
    setDateType(dateType);
    setShowCalendar(true);
  };

  return (
    <div className={className} ref={ref}>
      <button
        type="button"
        onClick={() => {
          if (isVisible) {
            setShowCalendar(false);
          }
          setIsVisible(!isVisible);
        }}
      >
        {props.title}
      </button>
      <div className="option-box">
        <h5>마감일 기간</h5>
        <div className="date-input">
          <input
            type="text"
            placeholder="YYYY/MM/DD"
            title="시작일 입력"
            value={
              props.startValue
                ? moment(props.startValue).format('YYYY/MM/DD')
                : ''
            }
            onClick={() => clickInput('start')}
            onChange={() => {}}
          />

          <span>~</span>

          <input
            type="text"
            placeholder="YYYY/MM/DD"
            title="마감일 입력"
            value={
              props.endValue ? moment(props.endValue).format('YYYY/MM/DD') : ''
            }
            onClick={() => clickInput('end')}
            onChange={() => {}}
          />
        </div>

        <button
          className="btn-close"
          onClick={() => {
            setShowCalendar(false);
            setIsVisible(!isVisible);
          }}
        >
          <span>마감일 기한 입력창 닫기</span>
        </button>
      </div>

      {isVisible && showCalendar && (
        <Calendar
          selectedDate={
            dateType === 'start'
              ? props.startValue || undefined
              : props.endValue || undefined
          }
          handleSelect={(date: string) => {
            if (dateType === 'start') {
              props.handleChangeStart(date);
            } else {
              props.handleChangeEnd(date);
            }

            setShowCalendar(false);
          }}
        />
      )}
    </div>
  );
};

import { types } from 'mobx-state-tree';

export const BoardMemberModel = types.model('BoardMember', {
  id: types.number,
  name: types.string,
  email: types.string,
});
type BoardMemberType = typeof BoardMemberModel.Type;

export interface BoardMember extends BoardMemberType {}

import React, { FC } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components/macro';
import { CarouselArrow } from './CarouselArrow';
import { DummyData, SliderItem } from './SliderItem';

const StyledSlider = styled(Slider)`
  .slick-arrow {
    font-size: 14px;
    width: 26px;
    height: 32px;
    border-top: 1px solid #0d1240;
    position: absolute;
    bottom: 24px;
    z-index: 1;
  }
  .slick-prev {
    left: 24px;
  }

  .slick-next {
    right: 24px;
  }
`;

const data: DummyData[] = [
    {
      partnerLogo: require('../../../static/images/factory.png'),
      desc: '"창업과 동시에, 같은 에스오피오오엔지 투자사인 시소와 함께 디자인, 개발 프로젝트를 진행했습니다. 기획은 자체적으로 하며 디자이너, 백엔드 및 프론트 개발자와 한 팀이 되어 협업을 하면서 IT프로젝트에 대해서 단순한 외주로 홈페이지를 받아보는 것이 아니라, 홈페이지 구축과정에서의 개발자 디자이너와의 협업 프로세스도 익힐 수 있었습니다. 최근에도 개발자 채용전까지 지속적으로 프로젝트 협업을 해왔습니다."',
    },
    {
      partnerLogo: require('../../../static/images/freecn.png'),
      desc: '"시소와 함께 많은 신사업 프로젝트를 진행하며, 빠르고 효율적으로 일을 할 수 있었습니다. 내부의 개발팀이나 채용을 통해서 이뤄지는 과정에 비해 2~3배 빠르게 진행할 수 있었고, 새로운 시도들을 해나갈 수 있었습니다."',
    },
    {
      partnerLogo: require('../../../static/images/spacecloud.png'),
      desc: '"스페이스 클라우드는 시소를 통해서 내부 개발팀이 있더라도 쉽지 않은 대규모 이관 프로젝트를 협업하였습니다. 내부의 개발팀이 없지만 시소와 함께 어려운 개발적 비즈니스적 과제를 같이 해결해나가는 진정한 파트너사의 역할을 하고 있습니다."',
    },
    {
      partnerLogo: require('../../../static/images/awesome.png'),
      desc: '"어썸스쿨 서비스의 온라인화의 초석을 함께 만들었습니다. 시소의 초창기부터 함께 기획, 디자인, 개발을 해왔고 지금까지 여러가지 협업 서비스들을 함께 만들었습니다. 지금은 내부 개발팀의 필요성을 느끼면서 채용과 팀 세팅 과정까지 함께 고민하고 실행해주는 시소와 지속적인 협업을 하고 있습니다."',
    }
];

const settings: Settings = {
  arrows: true,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <CarouselArrow title={'다음'} />,
  prevArrow: <CarouselArrow title={'이전'} />,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
      },
    },
  ],
};

const Carousel: FC = () => {
  return (
    <StyledSlider {...settings} className="pjt-list">
      {data.map((itemData, index) => (
        <SliderItem
          key={itemData.partnerLogo + index}
          index={index}
          {...itemData}
        />
      ))}
    </StyledSlider>
  );
};

export { Carousel };

import { Spinner } from '@blueprintjs/core';
import moment from 'moment';
import React, { FC } from 'react';
// import { observer } from "mobx-react";
import { DepositSummary as Comps } from '../../molecules/Calculate';

interface Props {
  className?: string;
  isLoading?: boolean;
  balance: number;
  total: number;
  shortage: number;
}

const DepositSummary: FC<Props> = props => {
  const shortage =
    Math.round((Math.floor(Math.round(props.shortage) / 10) * 10) / 100) * 100;
  const balance =
    Math.round((Math.floor(Math.round(props.balance) / 10) * 10) / 100) * 100;
  const total =
    Math.round((Math.floor(Math.round(props.total) / 10) * 10) / 100) * 100;

  return (
    <Comps.Wrapper>
      {moment().format('YYYY.MM.DD HH:mm')} 현재 기준
      <Comps.AmountRow>
        {props.isLoading ? (
          <Spinner />
        ) : (
          <>
            <Comps.Amount highlight={true}>
              <span>입금 필요</span>
              <span>{Math.abs(shortage).toLocaleString('en-US')}</span>
            </Comps.Amount>

            {balance > 0 && (
              <Comps.Amount>
                <span>잔액</span>
                <span className="highlight">
                  {balance.toLocaleString('en-US')}
                </span>
              </Comps.Amount>
            )}

            <Comps.Amount>
              <span>입금누적</span>
              <span>{total.toLocaleString('en-US')}</span>
            </Comps.Amount>
          </>
        )}
      </Comps.AmountRow>
    </Comps.Wrapper>
  );
};

export default DepositSummary;

import React, { useState } from 'react';

export interface IAddBulletinProps {
  handleAdd: (title: string) => void;
  handleCancel?: () => void;
}

export const AddBulletin: React.SFC<IAddBulletinProps> = props => {
  const [title, setTitle] = useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).value &&
        props.handleAdd((e.target as HTMLInputElement).value);
      props.handleCancel && props.handleCancel();
      setTitle('');
    }
    if (e.key === 'Escape') {
      props.handleCancel && props.handleCancel();
      setTitle('');
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value &&
      props.handleAdd((e.target as HTMLInputElement).value);
    props.handleCancel && props.handleCancel();
    setTitle('');
  };

  return (
    <li>
      <div className={'modify'}>
        <input
          type="text"
          value={title}
          title="메뉴입력"
          autoFocus={true}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        ></input>
      </div>
    </li>
  );
};

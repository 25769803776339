import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import { PageWrapper } from '../MainTemplate/MainTemplate';

export const Wrapper = styled(PageWrapper)`
  // width: 1440px;
  min-height: 700px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  padding: 40px 0px 155px 110px;
  min-height: 700px;
  background-color: ${PALETTE['pale-grey']};

  .section + .section {
    padding-left: 85px;
  }

  .section {
    .child-section + .child-section {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    margin-top: 40px;
    padding: 25px 16px 42px 16px;
    display: block;

    .section + .section {
      padding: 0px;
      margin-top: 52px;
    }
  }
`;

export const LinkSection = styled.div`
  padding-left: 30px;
  display: flex;
  margin-top: 40px;
  color: #5834ff;

  @media only screen and (max-width: ${PC}) {
    padding-left: 0px;
    flex-direction: column;
  }
`;

export const Link = styled.a`
  width: 190px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: center;
  color: ${PALETTE['purpleish-blue']};
  border: solid 1px ${PALETTE['purpleish-blue']};
  border-radius: 3px;
  & + & {
    margin-left: 10px;
  }

  @media only screen and (max-width: ${PC}) {
    margin: 0 auto;

    & + & {
      margin: 15px auto;
    }
  }
`;

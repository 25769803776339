import sortBy from 'lodash/sortBy';
import { flow, types } from 'mobx-state-tree';

import * as API from '../apis';

import { TaskBulletinPostModel } from './TaskBulletinPost';

export const TaskBulletinModel = types
  .model('TaskBulletin', {
    id: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    order: types.number,
    task_bulletin_id: types.string,
    title: types.string,
    taskBoardId: types.string,
    task_bulletin_posts: types.array(TaskBulletinPostModel),
  })
  .views(self => ({
    get sortedTaskBulletinPosts() {
      return sortBy(self.task_bulletin_posts.slice(), ['order']);
    },
  }))
  .actions(self => ({
    getPostByPostId(postId: string) {
      return self.task_bulletin_posts.find(
        post => post.task_bulletin_post_id === postId,
      );
    },
    removePost(postId: number) {
      const findIndex = self.task_bulletin_posts.findIndex(
        post => post.id === postId,
      );
      if (findIndex > -1) {
        try {
          self.task_bulletin_posts[findIndex].remove();
          self.task_bulletin_posts.splice(findIndex, 1);
        } catch (e) {
          throw e;
        }
      }
    },
  }))
  .actions(self => {
    const addPost = flow(function* (title: string, writer: string) {
      try {
        const data = yield API.TaskBulletinPost.create(
          self.task_bulletin_id,
          writer,
          title,
        );
        return data;
      } catch (e) {
        throw e;
      }
    });
    const setTitle = flow(function* (title: string) {
      try {
        yield API.putBulletinTitle(self.id, title);
        self.title = title;
      } catch (e) {
        throw e;
      }
    });
    return {
      addPost,
      setTitle,
    };
  });
type TaskBulletinType = typeof TaskBulletinModel.Type;

export interface TaskBulletin extends TaskBulletinType {}

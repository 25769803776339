import React, { useRef } from 'react';

export interface IMentionDropdownProps {
  members: string[];
  onSelect: (member: string) => void;
}

export const MentionDropdown: React.SFC<IMentionDropdownProps> = props => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div className="user-box">
      <ul>
        {props.members.map((member: string, i: number) => (
          <li
            key={i}
            onClick={() => {
              props.onSelect(member);
            }}
          >
            <a href="#">{member}</a>
          </li>
        ))}
      </ul>
    </div>
  );
};

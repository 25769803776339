export enum EProjectStatus {
  END = '종료',
  KEEP_ACCEPT = '종료 - 유지보수 접수 가능',
  ENDHOLD = '종료 - 장기 홀드',
  ONGOING = '진행중',
  KEEPING = '종료 - 유지보수 진행중',
  FLAWING = '종료 - 하자보수 진행중',
  FLAW_ACCEPT = '종료 - 하자보수 접수 가능',
  HOLDING = '홀딩중',
  MATCHING = '알유프리 매칭중',
  WAITING = '대기중',
  SCHEDULE = '진행예정',
  DEPOSIT_WAIT = 'deposit_wait',
  DEPOSIT_CONFIRM = 'deposit_confirm',
  NONE = '',
}

export const END_STATUS = [
  EProjectStatus.KEEP_ACCEPT,
  EProjectStatus.END,
  EProjectStatus.ENDHOLD,
  EProjectStatus.KEEPING,
  EProjectStatus.KEEP_ACCEPT,
  EProjectStatus.FLAWING,
  EProjectStatus.FLAW_ACCEPT,
];

export enum EDAYS {
  FLAW_ACCEPT = 14,
  HOLDING = 90,
  KEEP_ACCEPT = 90,
}

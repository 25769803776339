import moment from 'moment';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EProjectStatus } from '../../../store/Dashboard';
import { IRufreeMatching } from '../../../store/Dashboard/models/RufreeMatching';
import { IRufreeOfRole, ISprint } from '../../../store/Dashboard/models/Sprint';
import { ISprintReport } from '../../../store/Dashboard/models/SprintReport';

import {
  ProjectStatusTag,
  Sprint as Comps,
  SprintReport,
} from '../../molecules/Dashboard';

const Sprint: FC<{
  innerRef: React.RefObject<HTMLDivElement>;
  isOpen: boolean;
  isProjectEndHold: boolean;
  isProjectHolding: boolean;
  reports?: ISprintReport;
  sprintStatus: EProjectStatus;
  sprint: ISprint;
  machings: IRufreeMatching[];
}> = ({
  innerRef,
  isOpen,
  isProjectEndHold,
  isProjectHolding,
  reports,
  sprintStatus,
  sprint,
  machings,
}) => {
  // @ts-ignore
  const { id } = useParams();
  const [isShow, setIsShow] = useState(isOpen);

  const dayINeed = moment().week() % 2 === 0 ? 5 : 12;
  const datePayoutScheduled = moment().isoWeekday(dayINeed);

  return (
    <Comps.Box active={sprintStatus === EProjectStatus.ONGOING} ref={innerRef}>
      <Comps.BoxHeader isOpened={isShow} onClick={() => setIsShow(!isShow)}>
        <Comps.BoxHeaderLeft>
          <div>{sprint.title}</div>
          {isProjectEndHold ? (
            sprintStatus === EProjectStatus.END ? (
              <div>
                {sprint.dateEnd &&
                  `${moment(sprint.dateEnd).format('MM.DD')} 종료`}
              </div>
            ) : (
              <div>종료-장기홀드</div>
            )
          ) : (
            <>
              {!isProjectHolding && sprintStatus === EProjectStatus.ONGOING && (
                <div>
                  {sprint.dateStart && moment(sprint.dateStart).format('MM.DD')}
                  {sprint.dateEnd &&
                    ` ~ 종료예정 ${moment(sprint.dateEnd).format('MM.DD')}`}
                </div>
              )}
              {sprintStatus === EProjectStatus.END && (
                <div>
                  {sprint.dateEnd &&
                    `${moment(sprint.dateEnd).format('MM.DD')} 종료`}
                </div>
              )}
              {sprintStatus === EProjectStatus.SCHEDULE && (
                <div>
                  시작예정{' '}
                  {sprint.dateStart && moment(sprint.dateStart).format('MM.DD')}
                </div>
              )}

              {
                sprint.status === EProjectStatus.HOLDING &&
                  // {
                  (sprint.dateStart ? (
                    <div>
                      <div>
                        {`${moment(sprint.dateStart).format('MM.DD')} ~`}
                      </div>
                      <div>
                        홀딩유효 기간{' '}
                        {moment(sprint.dueDateHold).diff(moment(), 'days')}일
                        남음
                      </div>
                    </div>
                  ) : (
                    <div>
                      홀딩유효 기간{' '}
                      {moment(sprint.dueDateHold).diff(moment(), 'days')}일 남음
                    </div>
                  ))
                // }
              }
            </>
          )}
        </Comps.BoxHeaderLeft>

        <Comps.BoxHeaderRight>
          <div>
            {isProjectEndHold ? (
              <ProjectStatusTag status={EProjectStatus.END} />
            ) : (
              <ProjectStatusTag status={sprint.status} />
            )}
          </div>
          <Comps.BoxToggle isShow={isShow} />
        </Comps.BoxHeaderRight>
      </Comps.BoxHeader>

      <Comps.BoxBody isShow={isShow}>
        <Comps.BoxBodyHeaderRow>
          <div>
            <div className="role">담당</div>
          </div>
          <div>
            <div className="rufree">알유프리</div>
            <div className="period">기간</div>
            <div className="status">상태</div>
            <div className="payment-status">비용 지급상태</div>
          </div>
          <div>
            <div className="payment-date">지급일</div>
            <div></div>
          </div>
        </Comps.BoxBodyHeaderRow>

        {sprint.rufrees.map((rufree: IRufreeOfRole, i: number) => {
          const {
            dateEnd,
            dateExpectedEnd,
            datePayout,
            dateStart,
            role,
            rufreeName,
            rufreeId,
            status,
          } = rufree;

          return (
            <Comps.BoxBodyRow key={i}>
              <div>
                <div
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {/* 담당 */}
                  {/* { role.length > 5 ? `${role.slice(0, 6)}...` : role } */}
                  {role}
                </div>
              </div>

              <div>
                <div
                  className={
                    isProjectEndHold && sprint.status !== EProjectStatus.END
                      ? 'endhold'
                      : sprint.status === EProjectStatus.SCHEDULE
                      ? 'scheduled'
                      : ''
                  }
                >
                  {/* 알유프리 */}
                  {isProjectEndHold && sprint.status !== EProjectStatus.END ? (
                    <span className="scheduled-or-matching">종료-장기홀드</span>
                  ) : rufreeName ? (
                    <a
                      href={`https://rufree.kr/users?uid=${rufreeId}`}
                      target="_blank"
                    >
                      {rufreeName}
                    </a>
                  ) : (
                    <span className="scheduled-or-matching">
                      {machings.findIndex(maching => maching.role === role) > -1
                        ? '매칭중'
                        : '진행예정'}
                    </span>
                  )}
                </div>

                {/* 기간 */}
                {sprint.status === EProjectStatus.SCHEDULE ? (
                  <>
                    {dateStart && (
                      <div>
                        <span>
                          {`시작예정 ${moment(dateStart).format('MM.DD')}`}
                        </span>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className={!dateStart && !dateEnd ? 'empty' : ''}>
                      <span
                        className={
                          status === EProjectStatus.ONGOING ? 'highlight' : ''
                        }
                      >
                        {dateStart ? moment(dateStart).format('MM.DD') : ''}
                      </span>
                      <span>{dateStart ? '~' : ''}</span>
                      <span
                        className={
                          status === EProjectStatus.END ? 'highlight' : ''
                        }
                      >
                        {dateEnd
                          ? moment(dateEnd).format('MM.DD')
                          : dateExpectedEnd
                          ? moment(dateExpectedEnd).format('MM.DD')
                          : ''}
                      </span>
                    </div>
                  </>
                )}

                {/* 상태 */}
                {sprint.status === EProjectStatus.HOLDING ? (
                  <div>홀딩중</div>
                ) : sprint.status === EProjectStatus.SCHEDULE ||
                  status === '' ? (
                  ''
                ) : (
                  <div>{status} </div>
                )}

                {/* 비용 지급상태 */}
                {status === EProjectStatus.END && (
                  <div className="">
                    {datePayout ? '지급 완료' : '지급 대기'}
                  </div>
                )}
              </div>

              <div>
                <div>
                  {datePayout
                    ? moment(datePayout).format('MM.DD')
                    : status === EProjectStatus.END && (
                        <span className="payout-scheduled">
                          예정: {datePayoutScheduled.format('MM.DD')}
                        </span>
                      )}
                </div>

                {datePayout && (
                  <div>
                    <Comps.CalculateColumn
                      onClick={() =>
                        window.open(
                          `/calculate?project=${id}&sprint=${sprint.sprint}`,
                          '_blank',
                        )
                      }
                    >
                      정산상세
                    </Comps.CalculateColumn>
                  </div>
                )}
              </div>
            </Comps.BoxBodyRow>
          );
        })}

        {sprint.status === EProjectStatus.END && reports && (
          <SprintReport reports={reports?.reports} />
        )}
      </Comps.BoxBody>
    </Comps.Box>
  );
};

export default Sprint;

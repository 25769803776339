import { flow, types } from 'mobx-state-tree';

import * as API from '../apis';

export const TaskMultiSelectModel = types
  .model('TaskMultiSelect', {
    archivedMode: types.optional(types.boolean, false),
    task_ids: types.optional(types.array(types.number), []),
    person_in_charge_list: types.optional(types.array(types.number), []),
    duedate: types.optional(types.string, ''),
    label_title_list: types.optional(types.array(types.string), []),
    sprint_title_list: types.optional(types.array(types.string), []),
  })
  .views(self => ({
    get countTask() {
      return self.task_ids.length;
    },
  }))
  .actions(self => ({
    setArchivedMode(archived: boolean) {
      self.archivedMode = archived;
    },
    toggleTaskId(id: number) {
      if (self.task_ids.includes(id)) {
        self.task_ids.remove(id);
      } else {
        self.task_ids.push(id);
      }
    },
    addTaskIds(ids: number[]) {
      ids.forEach((id: number) => {
        if (!self.task_ids.includes(id)) {
          self.task_ids.push(id);
        }
      });
    },
    clearTaskIds() {
      self.person_in_charge_list.replace([]);
      self.duedate = '';
      self.label_title_list.replace([]);
      self.sprint_title_list.replace([]);
      self.task_ids.replace([]);
    },
    removeTaskIds(ids: number[]) {
      ids.forEach((id: number) => {
        if (self.task_ids.includes(id)) {
          self.task_ids.remove(id);
        }
      });
    },

    togglePersonInChange(id: number) {
      if (self.person_in_charge_list.includes(id)) {
        self.person_in_charge_list.remove(id);
      } else {
        self.person_in_charge_list.push(id);
      }
    },
    toggleLabel(label: string) {
      if (self.label_title_list.includes(label)) {
        self.label_title_list.remove(label);
      } else {
        self.label_title_list.push(label);
      }
    },
    toggleSprint(sprint: string) {
      if (self.sprint_title_list.includes(sprint)) {
        self.sprint_title_list.remove(sprint);
      } else {
        self.sprint_title_list.push(sprint);
      }
    },
    setDueDate(dueDate: string) {
      self.duedate = dueDate;
    },
  }))
  .actions(self => {
    const previewMultiSelectInputs = (inputs: any) => {
      self.person_in_charge_list = inputs.person_in_charge_list;
      self.duedate = inputs.duedate;
      self.label_title_list = inputs.label_title_list;
      self.sprint_title_list = inputs.sprint_title_list;
    };

    const update = flow(function* (boardId: string) {
      try {
        yield API.Task.updateMultiTasks({
          task_board_id: boardId,
          task_ids: self.task_ids,
          person_in_charge_list: self.person_in_charge_list,
          duedate: self.duedate,
          label_title_list: self.label_title_list,
          sprint_title_list: self.sprint_title_list,
        });
      } catch (e) {
        throw e;
      }
    });

    const clearLabel = flow(function* () {
      try {
        yield API.Task.resetMultitask(self.task_ids, 'label');
      } catch (e) {
        throw e;
      }
    });

    const clearSprint = flow(function* () {
      try {
        yield API.Task.resetMultitask(self.task_ids, 'sprint');
      } catch (e) {
        throw e;
      }
    });

    const clearPersonInCharge = flow(function* () {
      try {
        yield API.Task.resetMultitask(self.task_ids, 'person_in_charge');
      } catch (e) {
        throw e;
      }
    });

    return {
      clearPersonInCharge,
      clearLabel,
      clearSprint,

      previewMultiSelectInputs,
      update,
    };
  });
type TaskMultiSelectType = typeof TaskMultiSelectModel.Type;
export interface TaskMultiSelect extends TaskMultiSelectType {}

import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';

export const Wrapper = styled.div`
  min-height: 415px;
  @media only screen and (max-width: ${PC}) {
    margin-bottom: 80px;
  }
`;

export const Title = styled.div`
  width: 121px;
  height: 27px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 40px;

  @media only screen and (max-width: ${PC}) {
    margin-bottom: 52px;
    width: 100%;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-height: 388px;

  @media only screen and (max-width: ${PC}) {
    display: block;
  }
`;

export const SpinnerBody = styled(Body)`
  justify-content: center;
`;

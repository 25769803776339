import { types } from 'mobx-state-tree';

export const FileToUploadFormModel = types
  .model('FileToUploadForm', {
    title: types.string,
    linkTitle: types.string,
  })
  .volatile(self => ({
    file: null as File | null,
  }))
  .actions(self => ({
    setTitle(title: string) {
      self.title = title;
    },
    setLinkTitle(val: string) {
      self.linkTitle = val;
    },
    setFile(file: File) {
      self.file = file;
      self.title = file.name;
    },
  }))
  .views(self => ({
    get hasFile() {
      return self.file != null;
    },
  }));
type FileToUploadFormType = typeof FileToUploadFormModel.Type;

export interface FileToUploadForm extends FileToUploadFormType {}

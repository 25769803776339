import React, { ChangeEvent } from 'react';

import { Menu as _Comps, PCMenu as Comps } from '../../molecules/MainTemplate';

const PCMenu: React.FC<{
  current: string;
  isLoggedIn: boolean;
  username: string;
  handleChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  handleLogin: () => void;
  handleLogout: () => void;
  inspectionTotalCount: number;
  inspectionNewCount: number;
  receptionNewCount: number;
  dashboardNewCount: number;
  calculateNewCount: number;
  isCpmsTheme: boolean;
  changeToCpmsTheme: (val: boolean) => void;
  handleRedirectLogo: () => void;
  notiProjectSummaries: any;
}> = props => {
  return props.isLoggedIn ? (
    <Comps.Wrapper>
      <Comps.Logo onClick={props.handleRedirectLogo} />
      <Comps.Body>
        <_Comps.Menu
          hasNewInspection={props.inspectionNewCount > 0}
          hasNewReception={props.receptionNewCount > 0}
          hasNewDashboard={props.dashboardNewCount > 0}
          hasNewCalculate={props.calculateNewCount > 0}
          current={props.current}
          notiProjectSummaries={props.notiProjectSummaries}
          handleClick={() => {}}
        />
      </Comps.Body>
    </Comps.Wrapper>
  ) : null;
};

export default PCMenu;

import styled from 'styled-components/macro';

export const TermsWrapper = styled.main`
  width: 1200px;
  margin: 0 auto;
  padding: 160px 50px;
  word-break: keep-all;
  font-size: 16px;
  line-height: 1.6;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }
  h1 {
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 40px 20px;
    line-height: 1.4;
    h1 {
      margin-bottom: 30px;
    }
  }
`;

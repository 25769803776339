import axios from 'axios';
import { GoogleSpreadsheetRow } from 'google-spreadsheet';
import sortBy from 'lodash/sortBy';
import { flow, types } from 'mobx-state-tree';
import moment from 'moment';
import * as googlesheet_apis from './apis/googleSheet';
import { EProjectStatus } from './constants';

import { ManagingTip, ProjectGroup } from './models';

enum SHEET_ROW_INDEX {
  ID = 0,
  CATEGORY = 1,
  SUBJECT = 2,
  ROLE = 3,
  URL = 4,
  THUMNAILURL = 5,
  TAG = 6,
  DATE = 7,
}

const sortDescByGroupId = (projectGroups: ProjectGroup.IProjectGroup[]) =>
  sortBy(projectGroups, project => project.groupId).reverse();

export const DashboardStoreModel = types
  .model('DashboardStore', {
    projectGroup: types.maybeNull(ProjectGroup.ProjectGroupModel),
    projectGroups: types.array(ProjectGroup.ProjectGroupModel),

    managingTips: types.array(ManagingTip.ManagingTipModel),

    isLoading: types.maybe(types.boolean),
  })
  .views(self => ({
    // 진행중
    get onGoings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.ONGOING,
        ),
      );
    },
    // 대기중
    get waitings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.WAITING,
        ),
      );
    },
    // 매칭중
    get matchings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.MATCHING,
        ),
      );
    },
    // 홀딩중
    get holdings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.HOLDING,
        ),
      );
    },

    // 종료-하자보수 접수 가능
    get flaw_accepts() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.FLAW_ACCEPT,
        ),
      );
    },
    // 종료-하자보수 진행 중
    get flawings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.FLAWING,
        ),
      );
    },
    // 종료-유지보수 접수가능
    get keep_accepts() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.KEEP_ACCEPT,
        ),
      );
    },
    // 종료 -유지보수 진행중
    get keepings() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.KEEPING,
        ),
      );
    },
    // 종료
    get ends() {
      return sortDescByGroupId(
        self.projectGroups.filter(item => item.status === EProjectStatus.END),
      );
    },
    // 종료 - 장기홀드
    get endholds() {
      return sortDescByGroupId(
        self.projectGroups.filter(
          item => item.status === EProjectStatus.ENDHOLD,
        ),
      );
    },
  }))
  .actions(self => ({
    isNewGroupId(groupId: string) {
      return self.projectGroups.map(pg => pg.groupId).indexOf(groupId) > -1
        ? false
        : true;
    },
    getManagingTips(category?: string, filters?: string[]) {
      let tips: ManagingTip.IManagingTip[] = self.managingTips;
      if (category && filters) {
        if (category === 'role') {
          // const difference = arr1.filter(x => arr2.includes(x))
          tips = tips.filter(tip => {
            const diff = tip.roles.filter(role => filters.includes(role));
            if (diff.length > 0) return tip;
          });
        } else {
          tips = tips.filter(tip => tip.category === filters[0]);
        }
      }

      return tips.sort(function (a, b) {
        if (!b.date) {
          return -1;
        }
        if (!a.date) {
          return -1;
        }
        return moment(b.date).diff(moment(a.date));
      });
    },
  }))
  .actions(self => {
    const fetchAll = flow(function* () {
      self.isLoading = true;

      self.projectGroups.replace([]);
      try {
        const { data }: { data: ProjectGroup.IProjectGroupResponse[] } =
          yield axios.get(`/projectGroups`);

        const projectGroups = data
          .filter(x => !!x.client)
          .map(x =>
            ProjectGroup.ProjectGroupModel.create(ProjectGroup.mapper(x)),
          );

        self.projectGroups.replace(projectGroups);
      } catch (e) {
        throw e;
      } finally {
        self.isLoading = false;
      }
    });

    const fetchById = flow(function* (group_id: string) {
      self.isLoading = true;

      try {
        const { data }: { data: ProjectGroup.IProjectGroupResponse } =
          yield axios.get(`/projectGroups/${group_id}`);
        self.projectGroup = ProjectGroup.ProjectGroupModel.create(
          ProjectGroup.mapper(data),
        );

        yield self.projectGroup.fetchSchedule();
        yield self.projectGroup.fetchSprint();
        yield self.projectGroup.fetchSprintReport();
      } catch (e) {
        throw e;
      } finally {
        self.isLoading = false;
      }
    });

    const fetchManagingTips = flow(function* () {
      const sheetRows: GoogleSpreadsheetRow[] =
        yield googlesheet_apis.getRowsFromSheet();

      const managingTips = sheetRows
        .filter(row => row._rawData.length > 6)
        .map(row =>
          ManagingTip.ManagingTipModel.create({
            id: row._rawData[SHEET_ROW_INDEX.ID],
            category: row._rawData[SHEET_ROW_INDEX.CATEGORY],
            subject: row._rawData[SHEET_ROW_INDEX.SUBJECT],
            roles: row._rawData[SHEET_ROW_INDEX.ROLE]
              ? row._rawData[SHEET_ROW_INDEX.ROLE].split(',')
              : [],
            url: row._rawData[SHEET_ROW_INDEX.URL],
            thumnailUrl: row._rawData[SHEET_ROW_INDEX.THUMNAILURL],
            tags: row._rawData[SHEET_ROW_INDEX.TAG]
              ? row._rawData[SHEET_ROW_INDEX.TAG].split(',')
              : [],
            date: moment(row._rawData[SHEET_ROW_INDEX.DATE]).toDate(),
          }),
        );

      self.managingTips.replace(managingTips);
    });

    return {
      fetchAll,
      fetchById,

      fetchManagingTips,
    };
  });

type DashboardStoreModelType = typeof DashboardStoreModel.Type;
export interface IDashboardStore extends DashboardStoreModelType {}

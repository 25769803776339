import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import ToggleSvg from '../../../static/icons/icon-arrow-01.svg';

import MoveSvg from '../../../static/icons/icon-movearrow-blue.svg';

export const Box = styled.div<{ active?: boolean }>`
  width: 100%;
  background-color: #ffffff;
  border: solid 1px
    ${p =>
      p.active ? PALETTE['purpleish-blue'] : PALETTE['very-light-pink-three']};
  border-radius: 10px;

  & + & {
    margin-top: 30px;
  }

  .hidden {
    display: none;
  }

  @media only screen and (max-width: ${PC}) {
    & + & {
      margin-top: 20px;
    }
  }
`;

export const BoxHeader = styled.div<{ isOpened?: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 52px;
  align-items: center;
  padding: 12px 20px;
  border-bottom: solid 1px ${PALETTE['very-light-pink-two']};
  cursor: pointer;
  border-radius: ${p => (p.isOpened ? '0' : '10px')};

  .sprint-title-toggle {
    margin-left: 20px;
  }

  @media only screen and (max-width: ${PC}) {
    padding: 12px 20px;
    position: relative;
  }
`;

export const BoxHeaderLeft = styled.div`
  display: flex;

  & > div:first-child {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: ${PALETTE['purpleish-blue']};

    @media only screen and (max-width: ${PC}) {
      position: absolute;
      top: 14px;
      left: 15px;
    }
  }
  & > div:last-child {
    line-height: 24px;
    font-size: 14px;
    font-weight: normal;
    padding-left: 30px;
    display: flex;

    @media only screen and (max-width: ${PC}) {
      font-size: 13px;
      padding-left: 10px;
      display: block;
      line-height: 52px;

      position: absolute;
      left: 45px;
      top: 0;

      & > div:first-child {
        margin-top: 6px;
      }

      & > div {
        line-height: 20px;
      }
    }
  }
`;
export const BoxHeaderRight = styled.div`
  display: flex;
`;

export const BoxToggle = styled.div<{
  isShow: boolean;
}>`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
  margin-top: 4px;
  margin-left: 20px;
  background: url(${ToggleSvg}) no-repeat center center;
  transform: rotate(${p => (p.isShow ? 180 : 0)}deg);
`;

export const BoxBody = styled.div<{ isShow?: boolean }>`
  min-height: 113px;
  padding: 15px 0px 20px 0px;
  display: ${p => (p.isShow ? 'block' : 'none')};

  @media only screen and (max-width: ${PC}) {
    min-height: initial;
  }
`;
export const BoxBodyRow = styled.div`
  font-size: 13px;
  display: flex;
  height: 44px;
  line-height: 44px;
  padding: 0px 20px;

  div {
    display: flex;
    position: relative;
  }

  span {
    color: ${PALETTE['brownish-grey']};
  }
  span.highlight {
    color: #000000;
  }

  .scheduled {
    color: ${PALETTE['brownish-grey']} !important;
  }
  span.scheduled-or-matching {
    font-weight: normal;
  }

  div:nth-child(1) {
    width: 93px;
  }
  div:nth-child(2) {
    width: 365px;

    div:nth-child(1) {
      width: 75px;
      // color: ${PALETTE['purpleish-blue']};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      a {
        color: ${PALETTE['purpleish-blue']};
      }
    }
    div:nth-child(2) {
      width: 110px;
    }
    div:nth-child(3) {
      width: 70px;
    }
    div:nth-child(4) {
      width: 110px;
    }
  }
  div:last-child {
    div:nth-child(1) {
      width: 90px;
    }
    div:nth-child(2) {
      color: ${PALETTE['purpleish-blue']};
    }
  }

  .endhold {
    width: fit-content !important;
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    height: auto;
    & {
      margin-bottom: 20px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
    & > div {
      margin-top: 5px;
    }
    // &:first-child {
    //   margin-top: 0px;
    // }

    div {
      width: fit-content !important;
      // height: 19px;
      line-height: 19px;
      div {
        width: auto;
      }
      & + & {
        margin-top: 5px;
      }
    }

    span.payout-scheduled::before {
      content: '지급일 : ';
      width: 50px;
    }

    div:nth-child(1) {
      font-weight: bold;
    }
    div:nth-child(2) {
      width: auto;
      div + div::before {
        color: ${PALETTE['brown-grey']};
        content: '|';
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    div.empty::before {
      content: '' !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    div:last-child {
      div:nth-child(1) {
        font-weight: 500;
      }

      // div:nth-child(1)::before {
      //   content: '지급일 : ';
      //   width: 50px;
      //  }
      div:nth-child(2) {
        color: ${PALETTE['purpleish-blue']};
        margin-left: 19px;
      }
    }
  }
`;

export const BoxBodyHeaderRow = styled(BoxBodyRow)`
  padding: 0px 20px;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
  color: ${PALETTE['very-light-pink-three']};

  div:nth-child(2) {
    div:nth-child(1) {
      color: ${PALETTE['very-light-pink-three']};
    }
  }

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;

export const Column = styled.span``;

export const CalculateColumn = styled(Column)`
  position: relative;
  color: ${PALETTE['purpleish-blue']} !important;
  font-weight: 500;

  cursor: pointer;
  &::after {
    content: '';
    background: url('${MoveSvg}') no-repeat center center;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 16px;
    left: 50px;
  }

  @media only screen and (max-width: ${PC}) {
    &::after {
      top: 5px;
    }
  }
`;

export const CalculateColumnReportView = styled(CalculateColumn)`
  margin-right: 14px;
  &::after {
    top: 3px;
  }
`;

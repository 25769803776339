import { Intent } from '@blueprintjs/core';
import React, { FC, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import MoreSVGDefaultColor from '../../../static/icons/icon-blue.svg';
import MoreSVGDtype2Color from '../../../static/icons/icon-more-cyan.svg';
import MoreSVG from '../../../static/icons/icon-more.svg';
import SwitchSVG from '../../../static/icons/icon-switch.svg';
import ActvSwitchSVG from '../../../static/icons/icon-swith-cyan.svg';
import { AppToaster } from '../../molecules/MyPage/AppToaster';

const DropdownIcon = styled.div<{
  isRotate?: boolean;
}>`
  background: url('${MoreSVG}') no-repeat center center;
  width: 27px;
  height: 27px;
  margin-left: 15px;
  margin-top: -3px;
  cursor: pointer;
  transform: rotate(${p => (p.isRotate ? 0 : 180)}deg);
`;

const UserMenu = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  text-align: right;
  vertical-align: middle;
  & > div:first-child {
    // margin-top: 5px;
  }
`;

const Dropdown = styled.div<{ isOpen: boolean }>`
  position: absolute;
  margin-top: 16px;
  z-index: 999;
  right: 0;
  width: 154px;
  height: 131px;
  border-radius: 3px;
  border: solid 1px #000000;
  background-color: ${PALETTE['button-style']};
  display: ${p => (p.isOpen ? 'block' : 'none')};
`;

const DropdownMenu = styled.div`
  line-height: 43px;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;

  & + & {
    border-top: solid 1px #000000;
  }

  &:hover {
    font-weight: bold;
  }
`;

const DropdownMenuMove = styled(DropdownMenu)`
  &::after {
    content: '';
    background: url('${SwitchSVG}') no-repeat center center;
    width: 18px;
    height: 16px;

    display: inline-block;
    margin-left: 6px;
  }
`;

const UserDropdownWrapper = styled.div`
  z-index: 10;

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
  

  &.default {
    color: #5834ff;
    ${Dropdown} {
      background-color: #4b34ff;
    }
    ${DropdownIcon} {
      background: url('${MoreSVGDefaultColor}') no-repeat center center;  
    }
    ${DropdownMenu} {
      color: #FFF;
    }
    ${DropdownMenuMove} { 
      color: #1effc5;
      &::after {
        background:url('${ActvSwitchSVG}') no-repeat center center;
    }
  }
  &.default.dtype2 {
    color: #FFF;
    ${Dropdown} {
      background-color: #FFF;
    }
    ${DropdownIcon} {
      background: url('${MoreSVGDtype2Color}') no-repeat center center;  
    }
    ${DropdownMenu} {
      color: #4b34ff;
    }
    ${DropdownMenuMove} { 
      color: #1effc5;
      &::after {
        background:url('${ActvSwitchSVG}') no-repeat center center;
    }
  }
  
  } Gb
  
  &.default.dtype2 {
    color: #FFF;
  } 
  
  
`;

const UserDropdown: FC<{
  current: string;
  username: string;
  handleLogout: () => void;
  isCpmsTheme: boolean;
  changeToCpmsTheme: (val: boolean, urlRedirecTo: string) => void;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}> = props => {
  const ref = useRef(null);

  const handleClickOutside = (e: any) => {
    props.setIsOpen(false);
  };
  const changeTheme = (val: any) => {
    props.setIsOpen(false);
    props.changeToCpmsTheme(val, '');
  };
  const handleGoToReceptionStart = () => {
    props.setIsOpen(false);
    props.changeToCpmsTheme(true, `receptions/start`);
    /* AppToaster.show({
      message: (
        <p>
          안녕하세요.
          <br />
          <br /> 현재 시소 서비스 리뉴얼로 인해, 당분간 신규 고객사의 프로젝트
          의뢰를 받지 않고 있습니다.
          <br />
          오픈 소식 업데이트를 위해 웨이팅리스트에 등록해주세요.
          <br />
          <br /> 감사합니다.
        </p>
      ),
      intent: Intent.WARNING,
    }); */
  };
  const history = useHistory();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });
  const getClassName = () => {
    const classType: boolean =
      props.current == '' || props.current == 'pricing' ? true : false;
    let clsName: string = '';
    props.isCpmsTheme ? (clsName += 'cpms') : (clsName += 'default');
    if (classType) {
      clsName += ' dtype2';
    }
    return clsName;
  };

  return (
    <UserDropdownWrapper className={getClassName()}>
      <UserMenu>
        <div>{`${props.username} 님`}</div>
        <DropdownIcon
          isRotate={!props.isOpen}
          onClick={() => props.setIsOpen(!props.isOpen)}
        />
      </UserMenu>

      <Dropdown isOpen={props.isOpen} ref={ref}>
        {props.isCpmsTheme ? (
          <DropdownMenuMove
            onClick={() => {
              changeTheme(false);
            }}
          >
            시소 홈페이지 이동
          </DropdownMenuMove>
        ) : (
          <DropdownMenuMove
            onClick={() => {
              changeTheme(true);
            }}
          >
            프로젝트 관리모드
          </DropdownMenuMove>
        )}
        <DropdownMenu
          onClick={() => {
            handleGoToReceptionStart();
          }}
        >
          프로젝트 신규 의뢰
        </DropdownMenu>
        <DropdownMenu onClick={props.handleLogout}>로그아웃</DropdownMenu>
      </Dropdown>
    </UserDropdownWrapper>
  );
};

export default UserDropdown;

import { types } from 'mobx-state-tree';

export const TaskFileModel = types.model('TaskFile', {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  file: types.string,
  task: types.string,
  writer: types.number,
  is_image: types.boolean,
  filename: types.string,
});
type TaskFileType = typeof TaskFileModel.Type;

export interface TaskFile extends TaskFileType {}

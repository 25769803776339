import React, { ChangeEvent, useState } from "react";
import { Link } from "react-router-dom";
import {
  Footer as Comps,
} from '../../molecules/MainTemplate';

const Footer: React.FC<{}> = (props) => {

  return (
      <Comps.Footer>
        <div className="inner">
          <Comps.FooterInfo>
            <em>(주)시소 (대표이사:박병규)</em>
            <address>제주특별자치도 제주시 중앙로217 제주벤쳐마루 3층 &amp; 서울특별시 성동구 성수일로3길 5-12, 2층</address>
            <p>통신판매업신고번호: 2018-서울강남-04590 | 사업자등록번호: 171-88-00464</p>
            <p>개인정보보호관리자 : 김은성</p>

            <p>Copyright © seeso Inc.</p>
          </Comps.FooterInfo>
          <Comps.FMenu>
            <ul>
              {/* <li><a href="#">서비스소개</a></li> */}
              <li><Link to="/terms/agreement">서비스 이용약관</Link></li>
              <li><Link to="/terms/privatePolicy">개인정보처리방침</Link></li>
              {/* <li><a href="#">자주묻는 질문</a></li> */}
              <li><a href="mailto:partner@seeso.kr">제휴 및 제안</a></li>
              <li className="notionSeeso">
                <a href="https://seesorufree.notion.site/b8c011f0949145e295924757775ec0e2" target="_blank"><img src={require("../../../static/icons/notion_icon.svg") } alt="notion-seeso"/>시소 노션 페이지</a>
              </li>
            </ul>
          </Comps.FMenu>
        </div>
      </Comps.Footer>
  )
}

export default Footer;
import React from 'react';

export interface IResetButton {
  handleClick?: () => void;
}
export const ResetButton: React.SFC<IResetButton> = ({ handleClick }) => (
  <button type="button" className="btn-reset" onClick={handleClick}>
    필터 초기화
  </button>
);

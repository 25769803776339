import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

const Wrapper = styled.div`
  display: flex;
`;

const ClientName = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    line-height: 29px;
    font-size: 20px;
  }
`;

const ClientNameLabel = styled.div`
  margin-left: 8px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 33px;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    line-height: 29px;
    font-size: 20px;
  }
`;

const VLine = styled.div`
  width: 1px;
  height: 16px;
  margin-top: 12px;
  margin-left: 18px;
  margin-right: 11px;
  background-color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    height: 12px;
    margin-top: 11px;
    margin-left: 14px;
    background-color: ${PALETTE['greyish-brown']};
  }
`;

const ClientNoLabel = styled.div`
  padding-top: 10px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  color: ${PALETTE['greyish-brown']};

  @media only screen and (max-width: ${PC}) {
    padding-top: 8px;
    line-height: 18px;
    font-weight: normal;
    font-size: 12px;
  }
`;

const ClientNo = styled.div`
  padding-top: 10px;
  margin-left: 6px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0.88px;
  color: ${PALETTE['greyish-brown']};

  @media only screen and (max-width: ${PC}) {
    padding-top: 8px;
    line-height: 18px;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.75px;
  }
`;

interface TitleContentProps {
  clientName: string;
  clientNo: string;
}

class TitleContent extends Component<TitleContentProps> {
  render() {
    return (
      <Wrapper>
        <ClientName>{this.props.clientName}</ClientName>
        <ClientNameLabel>님</ClientNameLabel>
        {this.props.clientNo && (
          <>
            <VLine />
            <ClientNoLabel>클라이언트 번호</ClientNoLabel>
            <ClientNo>{this.props.clientNo}</ClientNo>
          </>
        )}
      </Wrapper>
    );
  }
}

export default TitleContent;

import React, { useState } from 'react';

import { DraggableProvided } from 'react-beautiful-dnd';

export interface ISubChecklistProps {
  id: number;
  isDone: boolean;
  title: string;
  isEditable: boolean;

  dndProvided: DraggableProvided;

  onChangeTitle: (title: string) => void;
  onDelete: () => void;
  onToggleDone: () => void;
}

export const SubChecklist: React.SFC<ISubChecklistProps> = props => {
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState<string>(props.title);

  const _onChangeTitle = (title: string) => {
    if (title !== props.title) {
      // 타이틀이 수정되었으면,
      props.onChangeTitle(title);
    }

    setTitle(title);
    setIsEdit(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      _onChangeTitle((e.target as HTMLInputElement).value);
    }
    if (e.key === 'Escape') {
      setTitle(props.title);
      setIsEdit(false);
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    _onChangeTitle((e.target as HTMLInputElement).value);
  };

  return (
    <li
      className={`${isEdit ? 'edit' : ''}`}
      ref={props.dndProvided.innerRef}
      {...props.dndProvided.draggableProps}
      {...props.dndProvided.dragHandleProps}
    >
      {props.isEditable && (
        <button type="button" className="btn-move">
          <span>메뉴이동</span>
        </button>
      )}

      <input
        type="checkbox"
        id={`scl_${props.id}`}
        checked={props.isDone}
        disabled={props.isEditable ? false : true}
        onChange={props.onToggleDone}
      />

      {isEdit ? (
        <input
          autoFocus={true}
          type="text"
          value={title}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
        />
      ) : (
        <label htmlFor={`scl_${props.id}`}>{props.title}</label>
      )}

      {props.isEditable && (
        <span className="btn-md">
          <button
            type="button"
            className="modify"
            onClick={() => setIsEdit(true)}
          >
            <span>수정</span>
          </button>
          <button type="button" className="delete" onClick={props.onDelete}>
            <span>삭제</span>
          </button>
        </span>
      )}
    </li>
  );
};

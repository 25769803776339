import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { Carousel } from '../../molecules/MainTemplate/Carousel';

const StyledMain = styled.main`
  padding-top: 78px;

  .slg {
    padding-top: 42px;
  }

  .slg .inner {
    display: flex;
    flex-direction: column;
  }

  .slg .inner p {
    color: #0d1240;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    line-height: 48px;
  }

  .slg .inner:after {
    content: '';
    width: 375px;
    height: 316px;
    margin: 0 auto;
    background: url(${require('../../../static/images/main_sn01.png')}) no-repeat 50% 50%;
    background-size: contain;
  }

  @media (min-width: 786px) {
    .slg {
      background: #fff;
    }

    .slg .inner {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1040px;
      margin: 0 auto;
      padding: 0 40px;
    }

    .slg .inner p {
      text-align: left;
    }

    .slg .inner:after {
      width: 60%;
      max-width: 600px;
      height: 505px;
      margin: 0;
    }
  }

  .sesso-history {
    padding: 64px 24px 80px;
    background: #0d1240;
    color: #fff;
  }

  .sesso-history .inner {
    max-width: 1040px;
    margin: 0 auto;
    text-align: center;
  }

  .sesso-history .inner .def-info .cnts {
    line-height: 22px;
  }

  .sesso-history .inner .def-info .cnts p {
    margin-top: 30px;
  }

  .sesso-history .inner .def-info .cnts p:first-child {
    margin-top: 0;
  }

  .sesso-history .inner .def-info:after {
    content: '';
    display: block;
    width: 327px;
    height: 204px;
    margin: 24px auto 0;
    background: url(${require('../../../static/images/main_sn02.png')})
      no-repeat 0 0;
    background-size: contain;
  }

  .sesso-history .inner .history {
    margin-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }

  .sesso-history .inner .history ol li {
    margin-top: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    line-height: 26px;
  }

  .sesso-history .inner .history ol li:before {
    content: '';
    display: block;
    width: 64px;
    height: 64px;
    margin: 0 auto 16px;
    background: url(${require('../../../static/images/main_sn0202.png')})
      no-repeat 0 0;
    background-size: auto 100%;
  }

  .sesso-history .inner .history ol li em {
    font-weight: bold;
  }

  .sesso-history .inner .history ol li:nth-child(2):before {
    background-position: -64px 0;
  }

  .sesso-history .inner .history ol li:nth-child(3):before {
    background-position: -128px 0;
  }

  @media (min-width: 786px) {
    .sesso-history {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .sesso-history .inner .def-info {
      display: flex;
      align-items: center;
    }

    .sesso-history .inner .def-info .cnts {
      flex: 1;
      padding-right: 80px;
      font-size: 22px;
      text-align: left;
      line-height: 34px;
    }

    .sesso-history .inner .def-info:after {
      width: 50%;
      max-width: 480px;
      height: 300px;
      margin-top: 0;
    }

    .sesso-history .inner .history {
      margin-top: 80px;
    }

    .sesso-history .inner .history ol {
      display: flex;
      justify-content: space-between;
      padding-top: 80px;
    }

    .sesso-history .inner .history ol li {
      width: 30%;
      margin: 0;
    }

    .sesso-history .inner .history ol li:nth-child(2) {
      width: 40%;
    }
  }

  .partners {
    padding: 40px 24px 50px;
  }

  .partners .bnr-list {
    padding-top: 16px;
  }

  .partners .bnr-list ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .partners .bnr-list ul li {
    margin-top: 16px;
    box-sizing: border-box;
  }

  .partners .bnr-list ul li img {
    width: 160px;
  }

  @media (min-width: 786px) {
    .partners {
      padding: 120px 0;
    }

    .partners .bnr-list {
      max-width: 1040px;
      margin: 0 auto;
      padding-top: 48px;
    }

    .partners .bnr-list ul li {
      padding-left: 10px;
    }

    .partners .bnr-list ul li img {
      width: 200px;
    }

    .partners .bnr-list ul li:nth-child(5n + 1) {
      padding-left: 0;
    }
  }

  .service {
    padding: 64px 24px;
    background: #5539ff;
  }

  .service h2 {
    color: #fff;
    font-size: 28px;
    text-align: center;
  }

  .service .inner {
    max-width: 1040px;
    margin: 0 auto;
    text-align: center;
  }

  .service .inner > div {
    max-width: 440px;
    margin: 40px auto 0;
    color: #fff;
  }

  .service .inner > div:before {
    content: '';
    display: block;
    width: 280px;
    height: 280px;
    margin: 0 auto;
    background: url(${require('../../../static/images/main02.png')}) no-repeat
      50% 50%;
    background-size: contain;
  }

  .service .inner > div h3 {
    margin-top: 24px;
    color: #fff;
    font-size: 22px;
  }

  .service .inner > div p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 26px;
  }

  .service .inner > div p.desc {
    font-size: 14px;
  }

  .service .inner > div > a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 52px;
    margin-top: 24px;
    background: #0d1240;
    color: #fff;
    font-size: 16px;
  }

  .service .inner > div:nth-child(2):before {
    background-image: url(${require('../../../static/images/main03.png')});
  }

  @media (min-width: 786px) {
    .service {
      padding-top: 120px;
      padding-bottom: 124px;
    }

    .service h2 {
      font-size: 32px;
    }

    .service .inner {
      display: flex;
      justify-content: space-between;
      max-width: 960px;
      margin-top: 40px;
    }

    .service .inner h3 {
      font-size: 32px;
    }

    .service .inner > div {
      width: calc(50% - 40px);
      margin: 0;
    }

    .service .inner > div h3 {
      margin-top: 40px;
    }
  }

  .rnd {
    padding: 64px 24px 80px;
    text-align: center;
  }

  .rnd h2 {
    font-size: 28px;
  }

  .rnd ul li {
    margin-top: 16px;
    padding: 32px 24px;
    background: rgba(85, 57, 255, 0.03);
    border-radius: 8px;
    color: #0d1240;
    box-sizing: border-box;
  }

  .rnd ul li:before {
    content: '';
    display: block;
    width: 180px;
    height: 180px;
    margin: 0 auto;
    background: url(${require('../../../static/images/main_sn03.png')})
      no-repeat 0 0;
    background-size: auto 100%;
  }

  .rnd ul li em {
    display: block;
    margin-top: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .rnd ul li p {
    margin-top: 16px;
    line-height: 22px;
  }

  .rnd ul li:nth-child(2):before {
    background-position: -180px 0;
  }

  .rnd ul li:nth-child(3):before {
    background-position: -360px 0;
  }

  @media (min-width: 786px) {
    .rnd {
      max-width: 960px;
      margin: 0 auto;
      padding-top: 120px;
      padding-bottom: 120px;
    }

    .rnd h3 {
      font-size: 32px;
    }

    .rnd ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 40px;
    }

    .rnd ul li {
      width: calc(33.3% - 16px);
      margin-top: 0;
    }
  }
`;

interface MainProps {}

const MainSection: FC<MainProps> = ({}) => {
  return (
    <StyledMain id="snContent">
      <div id="top" className="slg">
        <div className="inner">
          <p style={{ wordBreak: 'keep-all', fontSize: 28, letterSpacing: -0.8 }}>
            검증된 인력들로 구성된 <br/> 리모트 팀과 프로젝트를 진행해보세요.
            <div style={{ display: 'flex', marginTop: 16, alignItems: 'center', justifyContent: 'center' }}>
              <button
                style={{
                  height: 52,
                  width: 440,
                  maxWidth: '90vw',
                  background: '#0d1240',
                  color: 'white',
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
                onClick={() => window.location.href = '/receptions/start'}
              >
                시작하기
              </button>
            </div>
          </p>
        </div>
      </div>
      <div id="intro" className="sesso-history">
        <div className="inner">
          <div className="def-info">
            <div className="cnts">
              <p>
                시소는 2016년부터 IT분야의 아웃소싱 프로젝트를 수행하며{' '}
                <em>1000여명의 전문가</em>와 함께 1000여개의 스프린트를 직접
                운영하였습니다. 그 과정에서 스프린트 단위의 고용과 정산, 리모트
                TF팀 등의 다양한 시도를 업계에서 가장 먼저 시도하고
                정착시켰습니다.
              </p>
              <p>
                또한 급변하는 기술에 따라 '일(work)'과 '노동(labor)'이 다양한
                양식으로 분화해 나가는 가운데, '*Work, Future, Free'* 를
                기준으로 채용과 아웃소싱 시장의 변화를 주도하는 스타트업이
                되고자 합니다.
              </p>
            </div>
          </div>
          <div className="history">
            <ol>
              <li>
                <em>2016 - 2017</em>
                <p>
                  법인 설립
                  <br />
                  에스오피오오엔지 투자유치
                  <br />
                  프리랜서 커뮤니티 알유프리 론칭
                </p>
              </li>
              <li>
                <em>2018 - 2019</em>
                <p>
                  애자일 스크럼 방식 운영 방식 적용
                  <br />
                  제주창조경제혁신센터 데모데이 최우수상
                  <br />
                  매쉬업엔젤스 투자유치
                  <br />
                  제주창조경제혁신센터 투자유치
                  <br />
                  TIPS 프로그램 선정
                </p>
              </li>
              <li>
                <em>2020 - 현재</em>
                <p>
                  누적 스프린트 1000개 돌파
                  <br />
                  사내 그룹웨어 개발
                  <br />
                  프로젝트 운영 소프트웨어 개발
                  <br />
                  인공지능 온라인 경진대회 우수 성과 기업
                  <br />
                  프로젝트 채용 플랫폼 &lt;세컨팀&gt; 론칭
                </p>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div id="partners" className="partners">
        <Carousel />
        <div className="bnr-list">
          <ul>
            <li>
              <a
                  href="https://www.kakaocorp.com/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/kakao.png')}
                    alt="kakao"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://magazine-b.co.kr/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/magazineb.png')}
                    alt="magazineb"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://rootimpact.org/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/rootimpact.png')}
                    alt="rootimpact"
                />
              </a>
            </li>
            <li>
              <a href="https://tumblbug.com/" target="_blank" title="새창열림">
                <img
                    src={require('../../../static/images/tumblbug.png')}
                    alt="tumblbug"
                />
              </a>
            </li>
            <li>
              <a href="http://freecn.tv/" target="_blank" title="새창열림">
                <img
                    src={require('../../../static/images/freecn.png')}
                    alt="freecn"
                />
              </a>
            </li>

            <li>
              <a
                  href="https://www.spacecloud.kr/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/spacecloud.png')}
                    alt="spacecloud"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://localstitch.kr/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/localstitch.png')}
                    alt="local stitch"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://www.cardoc.co.kr/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/cardoc.png')}
                    alt="cardoc"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://www.dangolgongjang.com/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/factory.png')}
                    alt="단골공장"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://benefitplus.kr/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/bplus.png')}
                    alt="bplus"
                />
              </a>
            </li>

            <li>
              <a href="https://matazoo.net/" target="_blank" title="새창열림">
                <img
                    src={require('../../../static/images/matazoo.png')}
                    alt="마타주"
                />
              </a>
            </li>
            <li>
              <a href="https://i-get.link/" target="_blank" title="새창열림">
                <img
                    src={require('../../../static/images/iget.png')}
                    alt="아이겟"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://www.naturing.net/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/naturing.png')}
                    alt="네이처링"
                />
              </a>
            </li>
            <li>
              <a
                  href="http://hero-school.net/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/awesome.png')}
                    alt="HERO SCHOOL"
                />
              </a>
            </li>
            <li>
              <a
                  href="https://www.blabla.audio/"
                  target="_blank"
                  title="새창열림"
              >
                <img
                    src={require('../../../static/images/blabla.png')}
                    alt="blabla"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div id="service" className="service">
        <h2>서비스 소개</h2>
        <div className="inner">
          <div>
            <h3>세컨팀</h3>
            <p>
              시소가 제안하는 &lt;프로젝트 채용&gt; 플랫폼 입니다. 프로젝트에
              필요한 전문가를 주 단위로 빠르게 고용할 수 있습니다.
            </p>
            <a href="https://second-team.com" target="_blank">바로가기</a>
          </div>
          <div>
            <h3>아웃소싱 서비스</h3>
            <p>
              프로젝트의 플래닝부터 리모트 팀 빌딩과 모니터링을 제공합니다.
              시소의 스프린트 프로세스에 맞춰 운영됩니다.{' '}
            </p>
            <a href="/receptions/start">시작하기</a>
            <p className="desc">
              *의뢰 내용 검토후 진행 가능 여부를 알려 드립니다
            </p>
          </div>
        </div>
      </div>
      <div id="rnd" className="rnd">
        <h2>R&amp;D</h2>
        <ul>
          <li>
            <em>시소봇</em>
            <p>
              자체 제작한 슬랙봇을 통해 프로젝트의 분석과 원만한 운영을 위한
              각종 알림을 제공합니다.
            </p>
          </li>
          <li>
            <em>클레이</em>
            <p>
              슬랙봇을 통해 수집한 슬랙의 메세지 데이터를 머신러닝을 통해
              분석하여 프로젝트의 상태나 리스크를 파악할 수 있습니다.
            </p>
          </li>
          <li>
            <em>타임머신</em>
            <p>
              트렐로 보드의 데이터 분석 툴로써, 프로젝트 현황을 한눈에 파악할수
              있어 애자일 방식의 운영에 도움을 줍니다.
            </p>
          </li>
        </ul>
      </div>
    </StyledMain>
  );
};

export { MainSection };
export type { MainProps };

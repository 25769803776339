import * as React from 'react';

export interface IMenuProps {
  isShow: boolean;
  isManager: boolean;
  onLogout: () => void;
  toggleShow: () => void;
  toggleSlackSettingShow: () => void;
  toggleActivityShow: () => void;
  toggleDeletedTaskShow: () => void;
}

export const Menu: React.SFC<IMenuProps> = ({
  isShow,
  isManager,

  onLogout,
  toggleShow,
  toggleActivityShow,
  toggleDeletedTaskShow,
  toggleSlackSettingShow,
}) => {
  const className = isShow ? 'menu selected' : 'menu';

  return (
    <div className={className}>
      <button type="button" onClick={toggleShow}>
        <span>메뉴</span>
      </button>
      <div className="menu-box">
        <ul className="def-m">
          <li onClick={toggleActivityShow}>
            <a href="#">활동 내역</a>
          </li>
          <li onClick={toggleDeletedTaskShow}>
            <a href="#">삭제된 태스크</a>
          </li>
          {/* {
            isManager &&
              <li
                onClick={ toggleSlackSettingShow }
              ><a href="#">슬랙 설정</a></li>
          } */}
        </ul>
        <ul>
          <li
            className="move"
            onClick={() => (document.location.href = '/dashboard')}
          >
            <a href="#">시소프로젝트 모드 이동</a>
          </li>
          <li onClick={onLogout}>
            <a href="#">로그아웃</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

import React from 'react';
import styled from 'styled-components/macro';

const StyledSection = styled.div`
  @media (min-width: 786px) {
    height: 370px;
  }
  height: 550px;
  padding: 24px 24px 80px;
  background: rgba(13, 18, 64, 0.05);
  border-radius: 8px;
  box-sizing: border-box;
  margin-left: 12px;
  margin-right: 12px;

  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background: url(${require('../../../static/images/main_sn_icon.png')})
      no-repeat 0 0;
    background-size: contain;
  }

  em {
    display: block;
    margin-top: 16px;
    img {
      margin-left: -20px;
    }
  }

  p {
    margin-top: 24px;
    font-size: 16px;
    line-height: 26px;
    padding: 0 10px;
  }
`;

interface DummyData {
  partnerLogo: string;
  desc: string;
}

type SliderItemProps = DummyData & {
  index: number;
};

const SliderItem = ({ partnerLogo, desc, index }: SliderItemProps) => {
  return (
    <StyledSection>
      <em>
        <img width={200} src={partnerLogo}/>
      </em>
      <p>
        {desc}
      </p>
    </StyledSection>
  );
};

export type { DummyData };
export { SliderItem };

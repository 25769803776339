import { observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { Inspection } from '../../../store/Inspection/Models/Inspection';
import CardItem from './CardItem';

const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  max-width: 1320px;
`;

interface Props {
  inspections: Inspection[];
}

@observer
class CardList extends Component<Props> {
  render() {
    return (
      <List>
        {this.props.inspections.map((value, index) => {
          return (
            <CardItem
              key={`${value.inspectionId}ci${index}`}
              inspection={value}
            />
          );
        })}
      </List>
    );
  }
}

export default CardList;

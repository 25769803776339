import axios from 'axios';
import { flow, types } from 'mobx-state-tree';
import moment from 'moment';
import { FileToUploadFormModel } from '../../components/molecules/Inspection/FileToUploadForm';
import {
  Inspection,
  InspectionModel,
  InspectionResponse,
  mapInspection,
} from './Models/Inspection';

export const InspectionStoreModel = types
  .model('InspectionStore', {
    inspections: types.array(InspectionModel),
    inspectionTotalCount: types.optional(types.number, 0),
    inspectionNewCount: types.optional(types.number, 0),
    cancelPdfDownload: types.optional(types.boolean, false),
    // For Pagination Query
    // currentPage: types.optional(types.number, 1),
    // pageGroup: types.optional(types.number, 1),
    // pageSize: types.optional(types.number, 15),
    // totalPages: types.optional(types.number, 1)
    // inspection
    inspectionNewAttachmentFormList: types.array(FileToUploadFormModel),

    isPDFdownloading: types.optional(types.boolean, false),
  })
  .views(self => ({
    get sortedInspections() {
      return self.inspections
        .slice()
        .sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
        );
    },
  }))
  .views(self => ({
    get onlyPublishedInspections() {
      return self.sortedInspections.filter(x => x.publishedDate);
    },
  }))
  .actions(self => ({
    initAttachment() {
      const item = FileToUploadFormModel.create({
        linkTitle: '',
        title: '',
      });
      self.inspectionNewAttachmentFormList.replace([item]);
    },
    addLineAttachment() {
      const newItem = FileToUploadFormModel.create({
        linkTitle: '',
        title: '',
      });
      self.inspectionNewAttachmentFormList.push(newItem);
    },
    removeLineAttachment(index: number) {
      self.inspectionNewAttachmentFormList.splice(index, 1);
    },
    // setCurentPage(value: number) {
    //     self.currentPage = value;
    // },
    // setPageGroup(value: number) {
    //     self.pageGroup = value;
    // }
  }))
  .actions(self => {
    const fetchInspectionById = flow(function* (
      inspectionId: string,
      publishedOnly?: boolean,
    ) {
      const params: {
        published_only?: boolean;
      } = {};
      params.published_only =
        publishedOnly !== undefined ? publishedOnly : true;
      console.log('params.published_only', params.published_only);
      const { data }: { data: InspectionResponse } = yield axios.get(
        `/inspections/${inspectionId}`,
        {
          params: params,
        },
      );
      // console.log("data",data);
      const inspection = InspectionModel.create(mapInspection(data));
      // console.log("inspection", inspection);
      self.inspections.forEach(x => {
        if (x.inspectionId === inspection.inspectionId) {
          self.inspections.remove(x);
        }
      });

      self.inspections.push(inspection);
    });

    /*const fetchInspectionCount = flow(function* () {
            try {
                const {data}: {
                    data: {
                        total_count: number;
                        new_count: number;
                    }
                } = yield axios.get(
                    `/inspections/count`,
                );

                self.inspectionTotalCount = data.total_count;
                self.inspectionNewCount = data.new_count;
            } catch (e) {
                console.log("fetchInspectionCount" + e.toString())
            }
        })*/
    /*const fetchPageableInspections = flow(function* (
            { created_after, search }: { created_after?: string, search: string }) {
            const params: {
                created_at__gt?: string,
                search?: string,
                "page": number,
                "page_size": number,
            } = {
                "page": self.currentPage,
                "page_size": self.pageSize
            };

            if (created_after) {
                params.created_at__gt = created_after;
            }
            if (search)
                params.search = search;

            try {
                const { data }: { data: InpsectionListResponse } = yield axios.get(
                    "/inspections",
                    {
                        params
                    }
                );

                const inspections = data.results.map(x =>
                    InspectionModel.create(mapInspection(x))
                );
                self.totalPages = data.total_pages;
                self.inspections.replace(inspections);
            } catch (e) {
                console.log(e);
                throw e;
            }
        });*/
    const uploadResources = flow(function* (inspectionId: string) {
      try {
        const formData = new FormData();
        let fileList: File[] = [];
        // console.log("self.inspectionNewAttachmentFormList",self.inspectionNewAttachmentFormList)

        self.inspectionNewAttachmentFormList.forEach(i => {
          console.log('i', i);
          if (i.file)
            // fileList.push(i.file)
            formData.append('files', i.file);
          else if (i.title.trim().length > 0) formData.append('links', i.title);
        });

        const { data, status } = yield axios.post(
          `/inspections/${inspectionId}/updateResources`,
          formData,
        );
        return status == 200;
      } catch (e) {
        console.log(e);
        throw e;
      }
    });
    const fetchInspections = flow(function* (created_after?: string) {
      const params: {
        created_at__gt?: string;
      } = {};

      if (created_after) {
        params.created_at__gt = created_after;
      }

      try {
        const { data }: { data: InspectionResponse[] } = yield axios.get(
          '/inspections',
          {
            params,
          },
        );

        const inspections = data.map(x =>
          InspectionModel.create(mapInspection(x)),
        );

        self.inspections.replace(inspections);
      } catch (e) {
        console.log(e);
        throw e;
      }
    });

    /*const toggleInspectionPublished = flow(function* (inspection: Inspection) {
            try {
                const publishedDate = inspection.publishedDate
                    ? null
                    : moment(new Date()).format("YYYY-MM-DD");

                const { data }: { data: InspectionResponse } = yield axios.put(
                    `/inspections/${inspection.inspectionId}/publishDateChange`,
                    {
                        ...mapInspectionForRequest(inspection),
                        published_date: publishedDate
                    }
                );

                inspection.setPublishedDate(publishedDate);

                return data;
            } catch (e) {
                console.log("toggleInpectionPublished error", e);
                throw e;
            }
        });
*/
    /*const duplicateInspection = flow(function* (inspection: Inspection) {
            try {
                const reqData = mapInspectionForRequest(inspection);

                delete reqData["inspection_id"];
                delete reqData["created_at"];
                delete reqData["updated_at"];

                const { data }: { data: InspectionResponse } = yield axios.post(
                    "/inspections",
                    {
                        ...reqData,
                        title: inspection.title + "의 사본",
                        published_date: null
                    }
                );

                return data;
            } catch (e) {
                console.log("duplicateInspection error", e);
                throw e;
            }
        });
*/
    /*      const deleteInspection = flow(function* (inspection: Inspection) {
                  try {
                      yield axios.delete(`/inspections/${inspection.inspectionId}`);
                      self.inspections.remove(inspection);
                  } catch (e) {
                      console.log("deleteInspection error", e);
                      throw e;
                  }
              });
      */
    /*const previewMailInspection = flow(function* (inspection: Inspection) {
            try {

                const { data }: { data: InspectionMailPreviewResponse } = yield axios.get(`/inspections/${inspection.inspectionId}/previewMail`);

                return InspectionMailModel.create({
                    html: data.html,
                    subject: data.subject
                });
            } catch (e) {
                console.log("previewMailInspection error", e);
                throw e;
            }
        });

        const sendMailInspection = flow(function* (inspection: Inspection) {
            try {

                const { status } = yield axios.get(`/inspections/${inspection.inspectionId}/sendMail`);
                if (status == 204)
                    AppToaster.show({
                        message: "발송 성공.",
                        intent: Intent.SUCCESS
                    });
                else
                    AppToaster.show({
                        message: "발송 실패.",
                        intent: Intent.SUCCESS
                    });

            } catch (e) {
                console.log("sendMailInspection error", e);
                throw e;
            }
        });
*/
    /* const getTitleFromUrl = flow(function* (link: string) {
             try {
                 inspection.setClientChecked(true);
                 const url = `/inspections/getTitleFromUrl?link=${link}`;

                 // budget=${budget}&showVatIncluded=${showVatIncluded}
                 yield axios.get(url, {responseType: "blob"})
                     .then((response) => {

                     });
             } catch (e) {
                 console.log("getTitleFromUrl error", e);
                 throw e;
             }
         });*/
    const downloadPDF = flow(function* (inspection: Inspection) {
      self.isPDFdownloading = true;

      try {
        self.cancelPdfDownload = false;
        // inspection.setClientChecked(true);
        const url = `/inspections/ex_download_pdf?uuid=${inspection.inspectionId}`;

        // budget=${budget}&showVatIncluded=${showVatIncluded}
        yield axios.get(url, { responseType: 'blob' }).then(response => {
          if (self.cancelPdfDownload) return;

          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          const link = document.createElement('a');
          link.href = url;
          let filename = `[시소] ${inspection.title} - 검수서 - ${moment(
            new Date(),
          ).format('YYYY-MM-DD')}.pdf`;

          link.setAttribute('download', filename); //or any other extension
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          // link.click();
          const pdfWindow = window.open();
          if (pdfWindow) pdfWindow.location.href = url;
        });
      } catch (e) {
        console.log('downloadPDF error', e);
        throw e;
      } finally {
        self.isPDFdownloading = false;
      }
    });
    const cancelPDF = flow(function* () {
      self.cancelPdfDownload = true;
    });
    /*   const toggleVatIncluded = flow(function* (inspection: Inspection) {
               try {
                   const { data }: { data: InspectionResponse } = yield axios.put(
                       `/inspections/${inspection.inspectionId}`,
                       {
                           ...mapInspectionForRequest(inspection),
                           vat_included: !inspection.vatIncluded
                       }
                   );

                   inspection.setVatIncluded(!inspection.vatIncluded);

               } catch (e) {

               }
           });*/

    /*   const fetchContractor = flow(function*(clientId: string) {
               try {
                   const { data }: { data: ClientUserResponse } = yield axios.get(
                       `/clients/${clientId}/getContractor`
                   );

                   const clientUser = ClientUserModel.create(mapResponseToClientUser(data))

                   return clientUser;
               } catch (e) {
                   console.log("fetchClient error", e);
               }
           });*/

    return {
      fetchInspectionById,
      fetchInspections,
      // fetchPageableInspections,
      // toggleInspectionPublished,
      // duplicateInspection,
      // deleteInspection,
      // previewMailInspection,
      // sendMailInspection,
      // getTitleFromUrl,
      cancelPDF,
      downloadPDF,
      // fetchInspectionCount,
      uploadResources,
      // toggleVatIncluded,
      // fetchContractor
    };
  });

type InspectionStoreType = typeof InspectionStoreModel.Type;

export interface InspectionStore extends InspectionStoreType {}

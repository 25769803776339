import { Intent, Spinner } from '@blueprintjs/core';
import { DatePicker, Form, Input, Radio, Select } from 'antd';

import 'antd/es/date-picker/style/css'; // for css
import 'antd/es/input/style/css';
import 'antd/es/select/style/css';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component, useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';

import { AppStore } from '../../../store/AppStore';

import { AppToaster } from '../../molecules/MyPage/AppToaster';

import { Reception as Comps } from '../../molecules/Receptions';
// import { inject, observer } from "mobx-react";

// 참고 ; 현재 유일하게 antd css 프레임워크가 접수페이지에서는 사용되고 있음. - 기존 홈페이지의 디자인을 그대로 유지하기 위한 방법 (캘린더, 업로더 등)
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';
const { Option } = Select;
const FormItem = Form.Item;

interface ReceptionState {
  userInfo: {
    name: string;
    email: string;
  };
  // dataPreparedValue: string,

  projAreaType1: boolean;
  projAreaType2: boolean;
  projAreaType3: boolean;

  projTypeEtcText: string;
  projType1: boolean;
  projType2: boolean;
  projType3: boolean;
  projType4: boolean;
  projEtcText: string;

  dataPrepared1: boolean;
  dataPrepared2: boolean;
  dataPrepared3: boolean;

  dataEtc1: boolean;
  dataEtc2: boolean;
  dataEtc3: boolean;
  dataEtc4: boolean;
  dataEtc5: boolean;
  referenceTxt: '';

  dataPriorityValue: string;

  dataFeature1: boolean;
  dataFeature2: boolean;
  dataFeature3: boolean;
  dataFeature4: boolean;
  dataFeature5: boolean;

  driveLink: string;

  projTitle: string;
  phone: string;
  affiliation: string;
  meetingDate: string;
  meetingTime: string;

  proDetailText: string;
  affiliationType: string;

  refLink: string;
  refFileName: string;
  loading: boolean;
  receptionistName: string;
  receptionSuccess: boolean;
  driveLinks: any[];
  receptionId: string;
  selectedBoxError: boolean;
  serviceType: string;
  // receptionInfoData: {
  //   data: {},
  //   related_receptions: []
  // }
}

interface InjectedProps extends ReceptionState {
  appStore: AppStore;
}

@inject('appStore')
@observer
class ReceptionStartPage extends Component<InjectedProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  get uStore() {
    return this.injected.appStore.myPageStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  get receptionStore() {
    return this.injected.appStore.receptionStore;
  }

  async componentDidMount() {
    window.scrollTo(0, 0);

    this.userStore.updateCurrentThemeStatus(true);
    // TODO: token 처리 부분은 로그인 모듈이 완성되면 삭제 필요 - start
    const authToken = window.localStorage.getItem('authtoken');

    if (authToken) {
      await this.refresh();
    }
  }

  // 클라이언트 정보호출 위함
  refresh = async () => {
    try {
      const { userStore } = this.injected.appStore;

      this.uStore.init();
      // 1. client 연결되면 client 값이 우선되어야 하므로 clientPersonal 호출 후 client를 호출해야함
      // 2. client 에는 협업툴정보가 없기 때문에 clientPersonal을 무조건 한 번은 호출해야함

      await this.uStore.fetchClientPersonal(userStore.userId);
      await this.uStore.fetchClient(this.uStore.clientId);
    } catch (e) {
      const error = JSON.stringify(e.response.data);
    }
  };

  // private stepInput: React.RefObject<HTMLInputElement>;
  private projTypeAreaTitle: React.RefObject<HTMLDivElement>;
  private projTypeTitle: React.RefObject<HTMLDivElement>;
  private dataEtcTitle: React.RefObject<HTMLDivElement>;
  private dataPriorityTitle: React.RefObject<HTMLDivElement>;
  private dataDetailTitle: React.RefObject<HTMLDivElement>;
  private projTypeSelectBoxTitle: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.onChangeMeetingDate = this.onChangeMeetingDate.bind(this);
    this.onChangeMeetingSelect = this.onChangeMeetingSelect.bind(this);
    this.onChangeAffiliationSelect = this.onChangeAffiliationSelect.bind(this);
    this.projTypeAreaTitle = React.createRef();
    this.projTypeTitle = React.createRef();
    this.dataEtcTitle = React.createRef();
    this.dataPriorityTitle = React.createRef();
    this.dataDetailTitle = React.createRef();
    this.projTypeSelectBoxTitle = React.createRef();
  }
  state: ReceptionState = {
    userInfo: {
      name: '',
      email: '',
    },
    // dataPreparedValue: null,

    projAreaType1: false,
    projAreaType2: false,
    projAreaType3: false,
    projTypeEtcText: '',

    projType1: false,
    projType2: false,
    projType3: false,
    projType4: false,
    projEtcText: '',

    dataPrepared1: false,
    dataPrepared2: false,
    dataPrepared3: false,

    dataEtc1: false,
    dataEtc2: false,
    dataEtc3: false,
    dataEtc4: false,
    dataEtc5: false,
    referenceTxt: '',

    dataFeature1: false,
    dataFeature2: false,
    dataFeature3: false,
    dataFeature4: false,
    dataFeature5: false,

    dataPriorityValue: '',

    driveLink: 'http://',

    projTitle: '',
    phone: '',
    affiliation: '',
    meetingDate: '',
    meetingTime: '',

    proDetailText: '',
    affiliationType: '-',

    refLink: '',
    refFileName: '',
    loading: false,
    receptionistName: '',
    receptionSuccess: false,
    driveLinks: [],
    receptionId: '',

    selectedBoxError: false,
    serviceType: '',
  };

  onChangeProrityType = (event: any) => {
    // if (this.refs.dataPriorityTitle.style.color === 'rgb(226, 74, 74)') {
    //   this.refs.dataPriorityTitle.style.color = '#2b2b2b';
    // }
    this.setState({
      dataPriorityValue: event.target.value,
    });
  };
  onChangeAffiliationSelect(affType: any) {
    this.setState({
      affiliationType: affType,
    });
  }
  disabledDate = (value: any) => {
    return value && value < moment().endOf('day').add(2, 'days');
  };
  onChangeMeetingDate(date: any, dateString: any) {
    this.setState({
      meetingDate: dateString,
    });
  }
  onChangeMeetingSelect(time: any) {
    this.setState({
      meetingTime: time,
    });
  }
  handleDeleteDocument = (e: any, index: number) => {
    e.preventDefault();
    let newArr = this.state.driveLinks;
    newArr.splice(index, 1);

    this.setState({
      driveLinks: newArr,
      driveLink: '',
    });
  };
  handleOnDocument = (e: any) => {
    e.preventDefault();
    var regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(this.state.driveLink)) {
      alert(
        'url을 제대로 입력해주세요. http나 https를 입력 하셨는 지 확인 해 주세요. ',
      );
      return;
    }
    const linkInfo: any = {
      refLink: this.state.driveLink,
      refFileName:
        this.state.driveLink.length > 50
          ? this.state.driveLink.substring(0, 50) + '...'
          : this.state.driveLink,
    };
    let newArr = this.state.driveLinks;
    newArr.push(linkInfo);

    this.setState({
      driveLinks: newArr,
      driveLink: '',
    });
  };

  handleUploadDocument = async (data: any) => {
    var reader = new FileReader();
    reader.readAsDataURL(data);

    reader.onload = () => {
      this.setState({
        refFile: reader.result,
      });
    };

    reader.onerror = error => {
      alert(
        '업로드 도중 문제가 발생했습니다. 다시 시도 해주시거나, 다른 방법으로 파일 업로드해주시면 좋겠습니다. ',
      );
    };

    const fileData = {
      reception: 15,
      file: data,
    };

    try {
      await this.receptionStore.uploadReceptionFile(fileData).then(res => {
        const linkInfo: any = {
          refLink: res.file,
          refFileName:
            res.file.length > 50 ? res.file.substring(0, 50) + '...' : res.file,
        };
        let newArr = this.state.driveLinks;
        newArr.push(linkInfo);
        this.setState({
          driveLinks: newArr,
        });
      });

      await AppToaster.show({
        message: '성공적으로 업로드 되었습니다. ',
        intent: Intent.SUCCESS,
      });
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: '알 수 없는 오류가 발생했습니다. error',
        intent: Intent.DANGER,
      });
    }
  };

  onInputChange = (event: any) => {
    this.setState({ proDetailText: event.target.value }, () => {
      if (this.state.proDetailText !== '') {
        if (this.dataDetailTitle.current) {
          this.dataDetailTitle.current.style.color = '#2b2b2b';
        }
      } else {
        if (this.dataDetailTitle.current) {
          this.dataDetailTitle.current.style.color = '#2b2b2b';
        }
      }
    });
  };

  checkError() {
    // 서비스타입 추가 추가 안했을 때
    if (this.state.serviceType.length < 1) {
      this.setState({
        selectedBoxError: true,
      });
      if (this.projTypeSelectBoxTitle.current) {
        // 타입스크립트때문에 어쩔 수 없음.
        this.projTypeSelectBoxTitle.current.style.color = '#e24a4a';
        window.scrollTo(0, this.projTypeSelectBoxTitle.current.offsetTop);
      }
      return;
    }

    if (
      !this.state.projAreaType1 &&
      !this.state.projAreaType2 &&
      !this.state.projAreaType3
    ) {
      if (this.projTypeAreaTitle.current) {
        // 타입스크립트때문에 어쩔 수 없음.
        this.projTypeAreaTitle.current.style.color = '#e24a4a';
        window.scrollTo(0, this.projTypeAreaTitle.current.offsetTop);
      }
      return;
    }

    if (
      !this.state.projType1 &&
      !this.state.projType2 &&
      !this.state.projType3 &&
      !this.state.projType4
    ) {
      if (this.projTypeTitle.current) {
        this.projTypeTitle.current.style.color = '#e24a4a';
        window.scrollTo(0, this.projTypeTitle.current.offsetTop);
      }
      return;
    }

    if (this.state.projAreaType3 && this.state.projTypeEtcText == '') {
      if (this.projTypeAreaTitle.current) {
        this.projTypeAreaTitle.current.style.color = '#e24a4a';
        alert('기타 내용을 입력해주세요');
        window.scrollTo(0, this.projTypeAreaTitle.current.offsetTop);
      }

      return;
    }
    if (this.state.projType4 && this.state.projEtcText == '') {
      if (this.projTypeTitle.current) {
        this.projTypeTitle.current.style.color = '#e24a4a';
        alert('기타 내용을 입력해주세요');
        window.scrollTo(0, this.projTypeTitle.current.offsetTop);
      }
      return;
    }

    if (this.state.dataEtc1 && this.state.referenceTxt == '') {
      if (this.dataEtcTitle.current) {
        this.dataEtcTitle.current.style.color = '#e24a4a';
        alert('레퍼런스 내용을 입력해주세요');
        window.scrollTo(0, this.dataEtcTitle.current.offsetTop);
      }

      return;
    }

    if (this.state.proDetailText == '') {
      // 다시 바꾸는 거 해야함
      if (this.dataDetailTitle.current) {
        this.dataDetailTitle.current.style.color = '#e24a4a';
        window.scrollTo(0, this.dataDetailTitle.current.offsetTop);
      }
      return;
    }

    if (this.state.dataPriorityValue == null) {
      if (this.dataPriorityTitle.current) {
        this.dataPriorityTitle.current.style.color = '#e24a4a';
        window.scrollTo(0, this.dataPriorityTitle.current.offsetTop);
      }
      return;
    }
    if (this.state.projTitle == '') {
      alert('프로젝트 타이틀을 입력 해주세요.');
      return;
    }
    if (this.state.receptionistName == '') {
      alert('이름을 입력해주세요.');
      return;
    }
    if (this.state.phone == '') {
      alert('전화번호를 입력해주세요.');
      return;
    }

    if (
      this.state.phone.length != 11 ||
      isNaN(Number(this.state.phone.substring(1)))
    ) {
      alert('전화번호 양식을 제대로 입력 해주세요.');
      return;
    }
    if (this.state.affiliation == '') {
      alert('소속을 입력해주세요.');
      return;
    }

    if (this.state.meetingDate == '' || this.state.meetingTime == '') {
      alert('미팅날짜와 시간을 입력 해 주세요.');
      return;
    }

    return true;
  }

  handleStartSubmit = async (e: any) => {
    e.preventDefault();
    const formSuccess = this.checkError();

    if (formSuccess == true) {
      this.setState({
        loading: true,
      });
      let areaTypeList = [];
      let areaList = [];
      let dataPreparedList = [];
      let dataEtcList = [];
      let dataFeatureList = [];

      this.state.projAreaType1 && areaTypeList.push('web');
      this.state.projAreaType2 && areaTypeList.push('app');
      this.state.projAreaType3 && areaTypeList.push('etc');

      this.state.projType1 && areaList.push('planning');
      this.state.projType2 && areaList.push('design');
      this.state.projType3 && areaList.push('develop');
      this.state.projType4 && areaList.push('etc');

      this.state.dataPrepared1 && dataPreparedList.push('proposal_true');
      this.state.dataPrepared2 && dataPreparedList.push('design_true');
      this.state.dataPrepared3 && dataPreparedList.push('develop_true');

      this.state.dataEtc1 && dataEtcList.push('reference');
      this.state.dataEtc2 && dataEtcList.push('communication');
      this.state.dataEtc3 && dataEtcList.push('planned');
      this.state.dataEtc4 && dataEtcList.push('nda');
      this.state.dataEtc5 && dataEtcList.push('existed_team');

      this.state.dataFeature1 && dataFeatureList.push('signup');
      this.state.dataFeature2 && dataFeatureList.push('post');
      this.state.dataFeature3 && dataFeatureList.push('message');
      this.state.dataFeature4 && dataFeatureList.push('shop');
      this.state.dataFeature5 && dataFeatureList.push('admin');

      let receptionData = {
        service_type: this.state.serviceType,
        area_type: areaTypeList,
        area_type_etc_text: this.state.projTypeEtcText,
        area: areaList,
        area_etc_text: this.state.projEtcText,

        data_prepared: dataPreparedList,
        etc_reference: dataEtcList,
        reference_text: this.state.referenceTxt,

        priority: this.state.dataPriorityValue,
        feature: dataFeatureList,
        detail: this.state.proDetailText,

        title: this.state.projTitle,
        date_meeting: this.state.meetingDate + 'T' + this.state.meetingTime,

        // 'user info'
        receptionist_name: this.state.receptionistName,
        phone: this.state.phone,
        affiliation: this.state.affiliation,
        affiliation_type: this.state.affiliationType,
        drive_links: this.state.driveLinks.map(item => item['refLink']),
      };

      // if (this.state.driveLink !== 'http://' || this.state.refLink !== "") {
      //   receptionData['google_drive_link'] = this.state.driveLink
      // // }

      try {
        const data = await this.receptionStore.addReception(receptionData);

        // const receptionData = await this.receptionStore.fetchReception
        await AppToaster.show({
          message:
            '성공적으로 접수 완료되었습니다. 2-3일 이내에 답변드리겠습니다!',
          intent: Intent.SUCCESS,
        });
        this.setState({
          receptionId: data.data.receptionNumber,
          receptionSuccess: true,
        });
        window.scrollTo(0, 0);
      } catch (e) {
        const error = JSON.stringify(e.response.data);
        AppToaster.show({
          message:
            '알 수 없는 오류가 발생했습니다. partner@seeso.kr로 문의 해주세요.',
          intent: Intent.DANGER,
        });
      } finally {
        this.setState({
          loading: false,
        });
      }
    }
  };

  handleSelectChooseBox = (val: string) => {
    if (val == this.state.serviceType) {
      this.setState({
        serviceType: '',
        selectedBoxError: true,
      });
      if (this.projTypeSelectBoxTitle.current) {
        this.projTypeSelectBoxTitle.current.style.color = '#e24a4a';
      }
    } else {
      this.setState({
        serviceType: val,
        selectedBoxError: false,
      });
      if (this.projTypeSelectBoxTitle.current) {
        this.projTypeSelectBoxTitle.current.style.color = '#2b2b2b';
      }
    }
  };

  render() {
    if (!this.state.receptionSuccess) {
      return (
        <Comps.ReceptionWrapper>
          <h1>
            안녕하세요!
            <br />
            프로젝트 시작을 도와드리겠습니다.
          </h1>
          <p className="n-open">
            시작하고자 하는 프로젝트에 대해 알려주시면,
            <br />
            영업일 기준 2-3일 내에 문자나 이메일로 연락드리겠습니다.
          </p>
          <Comps.startFormWrap>
            <Comps.startServiceTypeWrap>
              <h2 ref={this.projTypeSelectBoxTitle}>
                서비스 종류를 선택해 주세요.
              </h2>
              <span className="titleDescription">
                시소 팀이 현재 세컨팀이라는 새로운 모델로 프로젝트를 진행하는
                파일럿 서비스를 실험 중에 있습니다.
                <br />
                서비스 비교 후, 팀에 맞는 서비스를 선택해주세요. 선택하신 모델은
                영업 시 변경이 가능합니다.
              </span>
              <Comps.startServiceBoxWrap>
                <ServiceChooseBox
                  serviceType={'second'}
                  selectedBox={this.state.serviceType}
                  selectedBoxError={this.state.selectedBoxError}
                  handleSelectChooseBox={this.handleSelectChooseBox}
                />
                <ServiceChooseBox
                  serviceType={'normal'}
                  selectedBox={this.state.serviceType}
                  selectedBoxError={this.state.selectedBoxError}
                  handleSelectChooseBox={this.handleSelectChooseBox}
                />
              </Comps.startServiceBoxWrap>
            </Comps.startServiceTypeWrap>
            <Comps.startDefaultTypeWrap>
              <h2 ref={this.projTypeAreaTitle}>
                해당되는 프로젝트의 종류를 모두 선택해주세요.
              </h2>
              <Comps.choiceProject>
                <li>
                  <input
                    type="checkbox"
                    id="chPjType1"
                    checked={this.state.projAreaType1}
                    onChange={e => {
                      this.setState(
                        { projAreaType1: !this.state.projAreaType1 },
                        () => {
                          if (
                            this.state.projAreaType1 ||
                            this.state.projAreaType2 ||
                            this.state.projAreaType2
                          ) {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPjType1">
                    웹사이트<span></span>
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="chPjType2"
                    checked={this.state.projAreaType2}
                    onChange={e => {
                      this.setState(
                        { projAreaType2: !this.state.projAreaType2 },
                        () => {
                          if (
                            this.state.projAreaType1 ||
                            this.state.projAreaType2 ||
                            this.state.projAreaType3
                          ) {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPjType2">앱</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="chPjType3"
                    checked={this.state.projAreaType3}
                    onChange={e => {
                      this.setState(
                        { projAreaType3: !this.state.projAreaType3 },
                        () => {
                          if (
                            this.state.projAreaType1 ||
                            this.state.projAreaType2 ||
                            this.state.projAreaType3
                          ) {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeAreaTitle.current) {
                              this.projTypeAreaTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPjType3">기타</label>
                </li>
                {this.state.projAreaType3 ? (
                  <Comps.pInput>
                    <input
                      type="text"
                      id="wantPj"
                      placeholder="기타"
                      onChange={e => {
                        this.setState({ projTypeEtcText: e.target.value });
                      }}
                    />
                  </Comps.pInput>
                ) : (
                  ''
                )}
              </Comps.choiceProject>
            </Comps.startDefaultTypeWrap>
            <Comps.startDefaultTypeWrap>
              <h2 ref={this.projTypeTitle}>
                필요하신 작업 범위를 모두 선택해주세요.
              </h2>
              <Comps.choiceProject>
                <li>
                  <input
                    type="checkbox"
                    id="chPj1"
                    checked={this.state.projType1}
                    onChange={e => {
                      this.setState(
                        { projType1: !this.state.projType1 },
                        () => {
                          if (
                            this.state.projType1 ||
                            this.state.projType2 ||
                            this.state.projType3 ||
                            this.state.projType4 ||
                            (this.state.projType4 &&
                              this.state.projEtcText == '')
                          ) {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPj1">
                    기획<span></span>
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="chPj2"
                    checked={this.state.projType2}
                    onChange={e => {
                      this.setState(
                        { projType2: !this.state.projType2 },
                        () => {
                          if (
                            this.state.projType1 ||
                            this.state.projType2 ||
                            this.state.projType3 ||
                            (this.state.projType4 &&
                              this.state.projEtcText == '')
                          ) {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPj2">디자인</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="chPj3"
                    checked={this.state.projType3}
                    onChange={e => {
                      this.setState(
                        { projType3: !this.state.projType3 },
                        () => {
                          if (
                            this.state.projType1 ||
                            this.state.projType2 ||
                            this.state.projType3 ||
                            this.state.projType4 ||
                            (this.state.projType4 &&
                              this.state.projEtcText == '')
                          ) {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPj3">개발</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="chPj4"
                    checked={this.state.projType4}
                    onChange={e => {
                      this.setState(
                        { projType4: !this.state.projType4 },
                        () => {
                          if (
                            this.state.projType1 ||
                            this.state.projType2 ||
                            this.state.projType3 ||
                            this.state.projType4 ||
                            (this.state.projType4 &&
                              this.state.projEtcText == '')
                          ) {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#2b2b2b';
                            }
                          } else {
                            if (this.projTypeTitle.current) {
                              this.projTypeTitle.current.style.color =
                                '#e24a4a';
                            }
                          }
                        },
                      );
                    }}
                  />
                  <label htmlFor="chPj4">기타</label>
                </li>
                {this.state.projType4 ? (
                  <Comps.pInput>
                    <input
                      type="text"
                      id="wantPj"
                      placeholder="기타"
                      onChange={e => {
                        this.setState({ projEtcText: e.target.value });
                      }}
                    />
                  </Comps.pInput>
                ) : (
                  ''
                )}
              </Comps.choiceProject>
            </Comps.startDefaultTypeWrap>
            <Comps.startDefaultTypeWrap>
              <h2>
                (선택) 의뢰 건과 관련하여 준비된 자료가 있다면 해당 내용을 모두
                선택해주세요.
              </h2>
              <Comps.choiceProject>
                <li>
                  <input
                    type="checkbox"
                    id="dataPrepared1"
                    checked={this.state.dataPrepared1}
                    onChange={e => {
                      this.setState({
                        dataPrepared1: !this.state.dataPrepared1,
                      });
                    }}
                  />
                  <label htmlFor="dataPrepared1">
                    기획서가 준비되어 있습니다
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataPrepared2"
                    checked={this.state.dataPrepared2}
                    onChange={e => {
                      this.setState({
                        dataPrepared2: !this.state.dataPrepared2,
                      });
                    }}
                  />
                  <label htmlFor="dataPrepared2">
                    디자인 원본과 가이드가 준비되어 있습니다
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataPrepared3"
                    checked={this.state.dataPrepared3}
                    onChange={e => {
                      this.setState({
                        dataPrepared3: !this.state.dataPrepared3,
                      });
                    }}
                  />
                  <label htmlFor="dataPrepared3">
                    개발 소스코드 및 문서가 준비되어 있습니다.
                  </label>
                </li>
              </Comps.choiceProject>
            </Comps.startDefaultTypeWrap>
            <Comps.startDefaultTypeWrap>
              <h2 ref={this.dataEtcTitle}>
                (선택) 관련된 기타 해당사항을 모두 체크해주세요
              </h2>
              <Comps.choiceProject>
                <li>
                  <input
                    type="checkbox"
                    id="dataEtc1"
                    checked={this.state.dataEtc1}
                    onChange={e => {
                      this.setState({ dataEtc1: !this.state.dataEtc1 });
                    }}
                  />
                  <label htmlFor="dataEtc1">
                    참고할 만한 다른 서비스 및 사이트가 있습니다
                  </label>
                  {this.state.dataEtc1 ? (
                    <li>
                      <input
                        style={{}}
                        type="text"
                        id=""
                        placeholder="참고사이트 이름 혹은 링크"
                        onChange={e => {
                          this.setState({ referenceTxt: e.target.value });
                        }}
                      />
                    </li>
                  ) : (
                    ''
                  )}
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataEtc2"
                    checked={this.state.dataEtc2}
                    onChange={e => {
                      this.setState({ dataEtc2: !this.state.dataEtc2 });
                    }}
                  />
                  <label htmlFor="dataEtc2">
                    커뮤니케이션 담당자가 관련경험이 있습니다
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataEtc3"
                    checked={this.state.dataEtc3}
                    onChange={e => {
                      this.setState({ dataEtc3: !this.state.dataEtc3 });
                    }}
                  />
                  <label htmlFor="dataEtc3">
                    추후 업데이트 등 중장기적 계획이 있습니다
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataEtc4"
                    checked={this.state.dataEtc4}
                    onChange={e => {
                      this.setState({ dataEtc4: !this.state.dataEtc4 });
                    }}
                  />
                  <label htmlFor="dataEtc4">비밀 유지 계약이 필요합니다</label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataEtc5"
                    checked={this.state.dataEtc5}
                    onChange={e => {
                      this.setState({ dataEtc5: !this.state.dataEtc5 });
                    }}
                  />
                  <label htmlFor="dataEtc5">
                    추후 유지보수를 위한 개발인력이 있습니다
                  </label>
                </li>
              </Comps.choiceProject>
            </Comps.startDefaultTypeWrap>
            <Comps.startDefaultRadioWrap>
              <h2 ref={this.dataPriorityTitle}>
                희망하는 운영방식을 선택해주세요.
              </h2>
              <RadioGroup
                onChange={this.onChangeProrityType}
                value={this.state.dataPriorityValue}
                className="choiceSingleProject"
              >
                <Radio value="fast" className="inlineSelect">
                  <span>
                    <strong>빠른 런칭</strong>, 풀타임 작업이 가능한 고급
                    인력으로 팀을 구성하여 요청하신 기간안에 런칭, <br />
                    빠른 시장테스트가 필요하거나 오픈일자가 정해진 고객에게
                    추천합니다.
                  </span>
                </Radio>
                <Radio value="perfection" className="inlineSelect">
                  <span>
                    <strong>완성도 높은 런칭</strong>, 디테일한 매니징과 검수를
                    통해 확실한 결과물 런칭,
                    <br /> 안정된 결과물로 신뢰를 얻어야 하는 고객에게
                    추천합니다.
                  </span>
                </Radio>
                <Radio value="cost" className="inlineSelect">
                  <span>
                    <strong>저비용 런칭</strong>, 꼭 필요한 부분만 엄선 제작하여
                    최소비용으로 런칭, <br /> 한정된 예산안에 결과물이 필요한
                    고객에게 추천합니다.
                  </span>
                </Radio>
              </RadioGroup>
            </Comps.startDefaultRadioWrap>
            <Comps.startDefaultTypeWrap>
              <h2 ref="projTypeAreaTitle">
                (선택) 제작해야 할 필수 기능이 있다면 해당 내용을 모두
                선택해주세요.
              </h2>
              <Comps.choiceProject>
                <li>
                  <input
                    type="checkbox"
                    id="dataFeature1"
                    checked={this.state.dataFeature1}
                    onChange={e => {
                      this.setState({ dataFeature1: !this.state.dataFeature1 });
                    }}
                  />
                  <label htmlFor="dataFeature1">
                    1) 회원기능 <br />
                    이용자들이 로그인을 해야 사용할 수 있는 기능들이 있습니다.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataFeature2"
                    checked={this.state.dataFeature2}
                    onChange={e => {
                      this.setState({ dataFeature2: !this.state.dataFeature2 });
                    }}
                  />
                  <label htmlFor="dataFeature2">
                    2) 게시물 기능
                    <br />
                    이용자, 관리자가 글 혹은 사진 등의 게시물을 등록, 수정,
                    삭제할 수 있습니다.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataFeature3"
                    checked={this.state.dataFeature3}
                    onChange={e => {
                      this.setState({ dataFeature3: !this.state.dataFeature3 });
                    }}
                  />
                  <label htmlFor="dataFeature3">
                    3) 메시지 기능
                    <br />
                    이용자간 쪽지/채팅 등의 방식으로 소통할 수 있습니다.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataFeature4"
                    checked={this.state.dataFeature4}
                    onChange={e => {
                      this.setState({ dataFeature4: !this.state.dataFeature4 });
                    }}
                  />
                  <label htmlFor="dataFeature4">
                    4) 결제/쇼핑몰 기능
                    <br />
                    이용자가 카드 등을 이용해 등록된 상품을 결제할 수 있습니다.
                  </label>
                </li>
                <li>
                  <input
                    type="checkbox"
                    id="dataFeature5"
                    checked={this.state.dataFeature5}
                    onChange={e => {
                      this.setState({ dataFeature5: !this.state.dataFeature5 });
                    }}
                  />
                  <label htmlFor="dataFeature5">
                    5) 관리자 기능
                    <br />
                    제작물 혹은 이용자의 게시물을 관리하거나, 조회 및 통계
                    기능을 활용하여 현황을 살펴볼 수 있습니다.
                  </label>
                </li>
              </Comps.choiceProject>
            </Comps.startDefaultTypeWrap>
            <Comps.startUserFormWrap>
              <Comps.startH2title>접수 정보를 적어주세요.</Comps.startH2title>
              <Comps.startPjFormWrap>
                <FormItem className="startPjFormItemLayout" label="프로젝트 명">
                  <Input
                    onChange={e => {
                      this.setState({ projTitle: e.target.value });
                    }}
                    value={this.state.projTitle}
                  />
                </FormItem>
                <FormItem className="startPjFormItemLayout" label="이름">
                  <Input
                    onChange={e => {
                      this.setState({ receptionistName: e.target.value });
                    }}
                    value={this.state.receptionistName}
                  />
                </FormItem>
                <FormItem className="startPjFormItemLayout" label="이메일">
                  <Input value={this.uStore.userEmail} disabled={true} />
                </FormItem>
                <FormItem className="startPjFormItemLayout" label="전화번호">
                  <Input
                    onChange={e => {
                      this.setState({ phone: e.target.value }, () => {
                        if (this.state.phone.length == 3) {
                          this.setState({
                            phone: this.state.phone.substring(0, 4) + '-',
                          });
                        }
                      });
                    }}
                    placeholder="- 없이 입력해주세요."
                  />
                </FormItem>
                <FormItem
                  className="startPjFormItemLayout startMeetingLayout"
                  label="소속"
                >
                  <Input
                    placeholder="소속을 입력 해주세요."
                    className="affiliationInput"
                    onChange={e => {
                      this.setState({ affiliation: e.target.value });
                    }}
                  />
                  <span className="startMeetingSelect">
                    <Select
                      placeholder="추가선택"
                      onChange={this.onChangeAffiliationSelect}
                    >
                      <Option value="startup">스타트업</Option>
                      <Option value="company">중소기업</Option>
                      <Option value="enterprise">대기업</Option>
                    </Select>
                  </span>
                </FormItem>
                <FormItem
                  className="startPjFormItemLayout startMeetingLayout"
                  label="온라인 미팅 희망일 (정확한 미팅시간은 담당 PM이 연락을 드립니다)"
                >
                  <Comps.startMeetingSpan>
                    <DatePicker
                      disabledDate={this.disabledDate}
                      onChange={this.onChangeMeetingDate}
                      placeholder="일자"
                      format={dateFormat}
                    />
                  </Comps.startMeetingSpan>
                  <span className="startMeetingSelect">
                    <Select
                      placeholder="선택"
                      onChange={this.onChangeMeetingSelect}
                    >
                      <Option value="11:00">11:00</Option>
                      <Option value="13:00">13:00</Option>
                      <Option value="14:00">14:00</Option>
                      <Option value="15:00">15:00</Option>
                      <Option value="16:00">16:00</Option>
                    </Select>
                  </span>
                </FormItem>
                <FormItem className="startPjFormItemEtcLayout"></FormItem>
              </Comps.startPjFormWrap>
            </Comps.startUserFormWrap>
            <Comps.startInputPj>
              <h2 ref={this.dataDetailTitle}>
                프로젝트 의뢰에 대해 자세히 적어주세요.{' '}
              </h2>
              <div className="textareaBox">
                <TextareaAutosize
                  maxRows={4}
                  value={this.state.proDetailText}
                  id="InputPjDetail"
                  name="InputPjDetail"
                  placeholder="서비스의 자세한 소개, 그리고 원하는 결과물에 대해 구체적으로 적어주세요."
                  onChange={this.onInputChange}
                />
              </div>
            </Comps.startInputPj>
            <Comps.startStyleSltDesWrap>
              <h2 ref="linkTitle">
                회사 소개나, 자료에 대해 정리된 문서가 있으시면 아래 구글
                드라이브 링크에 공유해주세요.
              </h2>
              <span className="titleDescription">
                파일 업로드는 5MB이하의 pdf, hwp, doc, docx, ppt, pptx, xls,
                xlsx, txt 만 가능합니다. 5MB 이상의 파일은 구글 드라이브 링크로
                공유 해주십시요.
              </span>

              {this.state.driveLinks.map((item: any, index: number) => {
                return (
                  <div className="projectBox" key={index}>
                    <p>
                      <a href={item.refLink} target="_blank">
                        {item.refFileName}
                      </a>
                      <button
                        type="button"
                        onClick={e => this.handleDeleteDocument(e, index)}
                      >
                        삭제
                      </button>
                    </p>
                  </div>
                );
              })}
              <Comps.fileBox>
                {this.state.refLink == '' ? (
                  <div className="fileWrap">
                    <label htmlFor="fileName">파일 업로드</label>
                    <input
                      type="file"
                      id="fileName"
                      onChange={async (e: any) => {
                        if (e.target.files.length !== 0) {
                          const file = e.target.files[0];
                          await this.handleUploadDocument(file);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="fileWrap">
                    <label htmlFor="fileName" className="deactiveBtn">
                      파일 업로드
                    </label>
                  </div>
                )}
                <span>또는</span>
                <p>
                  <input
                    type="text"
                    value={this.state.driveLink}
                    onChange={e => {
                      this.setState({ driveLink: e.target.value }, () => {
                        if (
                          this.state.driveLink !== 'http://' &&
                          this.state.driveLink.length > 0
                        ) {
                          // this.refs.linkTitle.style.color = '#2b2b2b';
                          // this.refs.linkButton.styles.color = '#5834ff';
                        }
                      });
                    }}
                    placeholder="URL입력(구글 드라이브링크)"
                  />
                  {this.state.refLink == '' ? (
                    <button
                      type="button"
                      onClick={this.handleOnDocument}
                      className={
                        this.state.driveLink == 'http://' ? '' : 'actv'
                      }
                    >
                      추가
                    </button>
                  ) : (
                    ''
                  )}
                </p>
              </Comps.fileBox>
            </Comps.startStyleSltDesWrap>
          </Comps.startFormWrap>
          <Comps.btnToolBar>
            <Comps.btnWrap>
              <button
                onClick={this.handleStartSubmit}
                type="submit"
                className="btnSubmit"
              >
                의뢰하기
              </button>
            </Comps.btnWrap>
          </Comps.btnToolBar>
          {this.state.loading && (
            <Comps.LoadingWrapper>
              <Spinner />
            </Comps.LoadingWrapper>
          )}
        </Comps.ReceptionWrapper>
      );
    } else {
      let featuresText: string = '';

      // Reception.feature.map((item, index) => {
      //   switch(item) {
      //     case 'message':
      //       featuresText += `메시지`
      //       break;
      //     case 'signup':
      //       featuresText += `회원`
      //       break;
      //     case 'post':
      //       featuresText += `게시물`
      //       break;
      //     case 'shop':
      //       featuresText += `결제/쇼핑몰`
      //       break;
      //     case 'admin':
      //       featuresText += `관리자`
      //       break;
      //   }
      //   if (index+1 < Reception.feature.feature.length) {
      //     featuresText += `, `
      //   } else {
      //     featuresText += ``
      //   }
      // }) : ''

      return (
        <Comps.startSuccessPage>
          <h1>성공적으로 접수되었습니다!</h1>
          <p>
            등록하신 이메일({this.uStore.userEmail})에서 접수하신 내용을
            확인하실 수 있습니다. 담당자가 빠른시일내에 연락드릴 예정입니다.
            <br />
            <strong>접수번호 : {this.state.receptionId}</strong>
          </p>
          <div className="styles.rufreeInfo}">
            <h1>
              <span className="inlineType">유사 사례 -</span>
            </h1>
          </div>
          <div className="rufreesSelectRow">
            <>등록된 유사사례가 없습니다</>
            {/* {data.related_receptions.length > 0 ? data.related_receptions.map((item, index) => {
            const pTitle = String.fromCharCode(97 + index);
            return (
              <div className={styles.rufreeSelectBox}>
                <div className={styles.detailRow}>
                  <div className={styles.detailSchedule}>
                    <span>
                      프로젝트 {pTitle.toUpperCase()}
                    </span>
                  </div>
                </div>

                <div className={styles.detailRow}>
                  <div className={styles.detailSchedule}>
                    <span>
                      <strong>총 {Math.round(item.all_days/7)}주 진행</strong>
                    </span>
                  </div>
                  <div className={styles.detailSchedule}>
                    <span>
                      <strong>최종 비용 {numberWithCommas(Math.round(item.all_prices))} 만원</strong>
                    </span>
                  </div>

                </div>

            </div>
            )
          }) : <>등록된 유사사례가 없습니다</>} */}
          </div>
        </Comps.startSuccessPage>
      );
    }
  }
}

const ServiceChooseBox: React.FC<{
  serviceType: string;
  selectedBox: string;
  selectedBoxError: boolean;
  handleSelectChooseBox: (val: string) => void;
}> = props => {
  let title: string;
  let clsName: string;
  const [hovered, setHovered] = useState(false);
  const toggleHover = (val: boolean) => setHovered(val);

  props.serviceType == 'second' ? (title = '세컨팀') : (title = '일반의뢰');
  if (props.selectedBoxError) {
    clsName = 'error';
  } else {
    clsName = props.selectedBox == props.serviceType ? 'selectedBox' : '';
  }

  return (
    <Comps.startServiceBox
      className={hovered ? `activeBox ${clsName}` : `${clsName}`}
      onMouseEnter={() => {
        toggleHover(true);
      }}
      onMouseLeave={() => {
        toggleHover(false);
      }}
      onClick={() => props.handleSelectChooseBox(props.serviceType)}
    >
      <h3>{title}</h3>
      {props.serviceType == 'second' && (
        <>
          <ul>
            <li>팀웍이 중요한 중/장기 프로젝트에 적합</li>
            <li>스프린트 기간 선구매 방식</li>
            <li>일정 비용 상당의 시간제 태스크 무료 제공</li>
            <li>채용을 위한 시니어 및 주니어 실무자 추천</li>
            <li>IT 전문인력과 협업 경험 필수</li>
          </ul>
          <div className="startServiceBoxDescription">
            <a
              href="https://www.notion.so/seesorufree/Second-Team-0f29cca83bfa4a33acb53d010e89e8c0"
              target="_blank"
            >
              세컨팀에 대해 더 자세히 알아보세요 (클릭)
            </a>
          </div>
        </>
      )}
      {props.serviceType == 'normal' && (
        <ul>
          <li>저예산 단순, 단기 프로젝트에 적합</li>
          <li>매 스프린트 종료시 정산</li>
          <li>시간제 태스크 이용 제한</li>
          <li>일부 시소 서비스 이용 제한</li>
          <li>IT 전문인력과 협업할 의지 필수</li>
        </ul>
      )}
    </Comps.startServiceBox>
  );
};

export default ReceptionStartPage;

import { sortBy } from 'lodash';
import { flow, getParent, types } from 'mobx-state-tree';

import { AppStore } from '../../AppStore';
import { IUiStore } from '../../UIStore';

import * as API from '../apis';
import {
  TaskBoardResponse,
  TaskLabelSimpleResponse,
  TaskListResponse,
  TaskSprintSimpleResponse,
} from '../interfaces';
import { TaskManagerStore } from '../store';
import { BoardMemberModel } from './BoardMember';
import { ITask } from './Task';
import { ITaskList, TaskListModel } from './TaskList';

export const TaskBoardModel = types
  .model('TaskBoard', {
    id: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    taskBoardId: types.string,
    projectGroup: types.string,
    projectGroupName: types.string,
    sprintTitleList: types.array(types.string),
    labelTitleList: types.array(types.string),
    members: types.array(BoardMemberModel),
    taskLists: types.array(TaskListModel),
  })
  .views(self => ({
    get UIStore(): IUiStore | undefined {
      const rootStore = getParent(self, 2);
      if (rootStore.hasOwnProperty('uiStore')) {
        return (rootStore as AppStore).uiStore;
      }
      return undefined;
    },
    get orderedTaskList() {
      return sortBy(self.taskLists.slice(), ['order']);
    },
  }))
  .views(self => ({
    getTaskList(listId: string) {
      return self.taskLists.find(list => list.task_list_id === listId);
    },
  }))
  .actions(self => ({
    getTask(listId: string, taskId: string) {
      const tasklist = self.taskLists.find(
        list => list.task_list_id === listId,
      );
      if (tasklist) {
        return tasklist.tasks.find(task => task.task_id === taskId);
      }
      return undefined;
    },

    changeTaskListPosition(order_from: number, order_to: number) {
      const lists = self.orderedTaskList;

      lists[order_from].order = order_to;
      if (order_from > order_to) {
        for (let i = order_to; i < order_from; i++) {
          lists[i].changeOrder(lists[i].order + 1);
        }
      } else {
        for (let i = order_from + 1; i <= order_to; i++) {
          lists[i].changeOrder(lists[i].order - 1);
        }
      }

      self.taskLists.replace(lists);
    },

    showInfoToast(message: string) {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.showToast(true, true, message ? message : '로딩중입니다.');
      }
    },

    closeInfoToast() {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.closeToase();
      }
    },

    showErrorToast(message: string) {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.showToast(
          true,
          false,
          message ? message : '에러가 발생하였습니다.',
        );
      }
    },

    moveTaskToList(list: ITaskList, task: ITask) {
      const withoutLists = self.taskLists.filter(l => l.id !== list.id);
      const filteredTasks: ITask[] = list.tasks.slice();
      filteredTasks.push(task);

      list.setTasks(filteredTasks);
      self.taskLists.replace([...withoutLists, list]);
    },
  }))
  .actions(self => {
    const fetchLable = flow(function* () {
      const data: TaskLabelSimpleResponse[] = yield API.Board.getLables(
        self.id,
      );

      self.labelTitleList.replace([]);
      data.map(d => self.labelTitleList.push(d.title));
    });

    const fetchSprint = flow(function* () {
      const data: TaskSprintSimpleResponse[] = yield API.Board.getSprints(
        self.id,
      );

      self.sprintTitleList.replace([]);
      data.map(d => self.sprintTitleList.push(d.title));
    });

    const fetchTask = flow(function* (listId: string, taskId: string) {
      const taskList = self.taskLists.find(
        list => list.task_list_id === listId,
      );
      const task = taskList?.tasks.find(t => t.task_id === taskId);

      if (!taskList || !task) return;

      const withoutLists = self.taskLists.filter(l => l.id !== taskList.id);
      yield taskList.fetchTask(task.id);

      self.taskLists.replace([...withoutLists, taskList]);
    });

    const fetchTaskList = flow(function* (listId: string) {
      const taskListIndex = self.taskLists.findIndex(
        list => list.task_list_id === listId,
      );

      if (taskListIndex > -1) {
        self.showInfoToast(
          `태스크목록 로딩중입니다. ( 서버 응답속도 문제 임시알림 )`,
        );

        const oldTaskList = self.taskLists[taskListIndex];
        const data: TaskListResponse = yield API.TaskList.get(oldTaskList.id);
        const newTaskList = TaskListModel.create(data);
        self.taskLists.replace([
          ...self.taskLists.slice(0, taskListIndex),
          newTaskList,
          ...self.taskLists.slice(taskListIndex + 1),
        ]);

        self.closeInfoToast();
      }
    });

    const fetchTaskListById = flow(function* (id: number) {
      const taskListIndex = self.taskLists.findIndex(list => list.id === id);

      if (taskListIndex > -1) {
        self.showInfoToast(
          `태스크목록 로딩중입니다. ( 서버 응답속도 문제 임시알림 )`,
        );

        const oldTaskList = self.taskLists[taskListIndex];
        const data: TaskListResponse = yield API.TaskList.get(oldTaskList.id);
        const newTaskList = TaskListModel.create(data);
        self.taskLists.replace([
          ...self.taskLists.slice(0, taskListIndex),
          newTaskList,
          ...self.taskLists.slice(taskListIndex + 1),
        ]);

        self.closeInfoToast();
      }
    });

    const fetchTaskListByOrder = flow(function* (order: number) {
      const taskListIndex = self.taskLists.findIndex(
        list => list.order === order,
      );

      if (taskListIndex > -1) {
        self.showInfoToast(
          `태스크목록 로딩중입니다. ( 서버 응답속도 문제 임시알림 )`,
        );

        const oldTaskList = self.taskLists[taskListIndex];
        const data: TaskListResponse = yield API.TaskList.get(oldTaskList.id);
        const newTaskList = TaskListModel.create(data);
        self.taskLists.replace([
          ...self.taskLists.slice(0, taskListIndex),
          newTaskList,
          ...self.taskLists.slice(taskListIndex + 1),
        ]);

        self.closeInfoToast();
      }
    });

    const createTaskList = flow(function* (title: string) {
      try {
        self.showInfoToast(
          `태스크목록 추가중입니다. ( 서버 응답속도 문제 임시알림 )`,
        );
        yield API.TaskList.create(self.taskBoardId, title);
        self.closeInfoToast();
      } catch (e) {
        self.showErrorToast(e.response.data.msg);
        throw e;
      } finally {
      }
    });

    const createLabel = flow(function* (label: string) {
      try {
        yield API.Board.createLabel(self.id, label);
      } catch (e) {
        self.showErrorToast(e.response.data.msg);
        throw e;
      }
    });

    const createSprint = flow(function* (sprint: string) {
      try {
        yield API.Board.createSprint(self.id, sprint);
      } catch (e) {
        self.showErrorToast(e.response.data.msg);
        throw e;
      }
    });

    const deleteLabel = flow(function* (label: string) {
      const labelIndex = self.labelTitleList.findIndex(
        label => label === label,
      );

      try {
        yield API.Board.deleteLabel(self.id, label);

        self.labelTitleList.replace([
          ...self.labelTitleList.slice(0, labelIndex),
          ...self.labelTitleList.slice(labelIndex + 1),
        ]);

        self.labelTitleList.remove(label);
      } catch (e) {
        self.showErrorToast(e.response.data.msg);
        throw e;
      }
    });

    const deleteSprint = flow(function* (sprint: string) {
      const labelIndex = self.sprintTitleList.findIndex(
        sprint => sprint === sprint,
      );

      try {
        yield API.Board.deleteSprint(self.id, sprint);

        self.sprintTitleList.replace([
          ...self.sprintTitleList.slice(0, labelIndex),
          ...self.sprintTitleList.slice(labelIndex + 1),
        ]);

        self.sprintTitleList.remove(sprint);
      } catch (e) {
        self.showErrorToast(e.response.data.msg);
        throw e;
      }
    });

    const resetNotificationCount = flow(function* () {
      try {
        yield API.Board.resetNotificationCount(self.id);

        const boardStore = getParent(self, 1);
        (boardStore as TaskManagerStore).setUnReadNotificationCount(0);
      } catch (e) {
        throw e;
      }
    });

    return {
      createLabel,
      createSprint,
      createTaskList,
      deleteLabel,
      deleteSprint,

      fetchLable,
      fetchSprint,
      fetchTask,
      fetchTaskList,
      fetchTaskListById,
      fetchTaskListByOrder,

      resetNotificationCount,
    };
  });

type TaskBoardType = typeof TaskBoardModel.Type;
export interface TaskBoard extends TaskBoardType {}

export const mapTaskBoard = (x: TaskBoardResponse) => {
  return {
    id: x.id,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    taskBoardId: x.task_board_id,
    projectGroup: x.project_group,
    projectGroupName: x.project_group_name,
    sprintTitleList: x.sprint_title_list,
    labelTitleList: x.label_title_list,
    members: x.members,
    taskLists: x.task_lists,
  };
};

import React, { FC } from 'react';
import { ISchedule } from '../../../store/Dashboard/models/Schedule';
import { ISprint } from '../../../store/Dashboard/models/Sprint';
import { ISprintReport } from '../../../store/Dashboard/models/SprintReport';

import SprintReportOnly from './SprintReportOnly';

interface IProps {
  className?: string;
  reports: ISprintReport[];
  selectedSprint: number;
  schedule: ISchedule | null;
}
const SprintListReportOnly: FC<IProps> = ({
  className,
  reports,
  schedule,
  selectedSprint,
}) => {
  return (
    <>
      {schedule &&
        schedule.sprints.map((sprint: ISprint, i: number) => (
          <SprintReportOnly
            key={i}
            isOpen={selectedSprint ? sprint.sprint === selectedSprint : true}
            reports={reports.find(report => report.sprint === sprint.sprint)}
            title={sprint.title}
            dateEnd={sprint.dateEnd}
          />
        ))}
    </>
  );
};

export default SprintListReportOnly;

import React, { useEffect, useRef, useState } from 'react';

export interface IDescriptionProps {
  isEditable: boolean;
  description: string;
  onChange?: (description: string) => void;
}

export const Description: React.SFC<IDescriptionProps> = props => {
  const textAreaRef: React.RefObject<HTMLTextAreaElement> = useRef(null);
  const textareaLineHeight = 16.7155;
  const [rows, setRows] = useState(3);
  const [isEditing, setIsEditing] = useState(props.description ? false : true);
  const [description, setDescription] = useState(props.description);

  useEffect(() => {
    const target = textAreaRef.current;

    if (!target || description === props.description) return;

    target.rows = 3;
    target.value = props.description;
    setDescription(props.description);

    resizeTextarea();
  }, [props.description]);

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
    resizeTextarea();
  };
  const onCancel = () => {
    setDescription(props.description);
    resizeTextarea();
    setIsEditing(false);
  };
  const onSave = () => {
    description !== props.description &&
      props.onChange &&
      props.onChange(description);

    resizeTextarea();
    setIsEditing(false);
  };
  const resizeTextarea = () => {
    const target = textAreaRef.current;
    if (!target) return;

    const taHeight = target.scrollHeight;
    const currentRows = ~~(taHeight / textareaLineHeight);
    const rows = currentRows > 3 ? currentRows : 3;

    target.rows = rows;
    setRows(rows);
  };

  return (
    <div className="in-cnts text">
      <h2>
        <label htmlFor="txt">설명</label>
      </h2>
      <div
        className="d-box"
        onClick={() => props.isEditable && setIsEditing(true)}
      >
        <textarea
          id="txt"
          placeholder="설명 입력"
          value={description}
          onClick={() => setIsEditing(true)}
          onChange={onChange}
          ref={textAreaRef}
          rows={rows}
          style={{ height: 'auto', overflow: 'hidden' }}
        />
      </div>

      {props.isEditable && isEditing && description.length > 0 && (
        <div className="btn-def">
          <a href="#" onClick={onCancel}>
            취소
          </a>
          <a href="#" onClick={onSave}>
            저장
          </a>
        </div>
      )}
    </div>
  );
};

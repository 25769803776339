import { types } from 'mobx-state-tree';

type ModeCode = '' | 'read' | 'edit' | 'delete';
type CategoryCode = 'bulletin' | 'post' | 'tasklist' | 'task';
// const code: CategoryCode = 'ko'
export enum EActiveCompoment {
  // 사용자가 선택한 컴포넌트

  ALERT = 'alert',
  ACTIVITY = 'activity',
  DELETEDTASK = 'deletedtask',
  MENU = 'menu',
  NOTIFICATION = 'notification',
  SLACKSETTING = 'slacksetting',

  // TASKLISTMENU = 'tasklistmenu',
  // TASK = 'task',

  NONE = 'none',
}

export const UiStoreModel = types
  .model('UiStore', {
    isShowNotiBox: types.optional(types.boolean, false),

    activeComponent: types.optional(types.string, EActiveCompoment.NONE),

    isShowMenu: types.optional(types.boolean, false),
    isShowBulletin: types.optional(types.boolean, true),

    // 토스트 알림
    isNagativeToast: types.optional(types.boolean, false),
    isManualClose: types.optional(types.boolean, false),
    toastMessage: types.optional(types.string, ''),

    // 선택 관련 store
    selectedMode: types.optional(types.string, 'READ'), // READ: 보기, EDIT: 편집, DELETE: 삭제
    selectedCategory: types.optional(types.string, ''), // BULLETIN
    selectedIds: types.array(types.string),
  })
  .views(self => ({}))
  .actions(self => ({
    toggleSelectedId(id: string) {
      console.log(id);
      let newIds = self.selectedIds.slice();
      const index = newIds.findIndex(i => i === id);
      if (index > -1) {
        newIds = newIds.filter(item => item !== id);
      } else {
        newIds.push(id);
      }

      self.selectedIds.replace(newIds);
    },
  }))
  .actions(self => ({
    setShowNotiBox(value: boolean) {
      self.isShowNotiBox = value;

      self.isShowMenu = false;
    },
    toggleBulletin() {
      self.isShowBulletin = !self.isShowBulletin;
    },
    closePanels() {
      self.isShowNotiBox = false;
      self.isShowMenu = false;
    },
    setActiveCompoment(component?: string) {
      self.activeComponent = component ? component : EActiveCompoment.NONE;
      this.closePanels();

      switch (component) {
        case EActiveCompoment.ACTIVITY:
          self.isShowNotiBox = true;
          self.isShowMenu = false;
          break;
        case EActiveCompoment.DELETEDTASK:
          self.isShowNotiBox = true;
          self.isShowMenu = false;
          break;
        case EActiveCompoment.MENU:
          self.isShowMenu = true;
          break;
        case EActiveCompoment.NOTIFICATION:
          self.isShowNotiBox = true;
          self.isShowMenu = false;
          break;
        case EActiveCompoment.SLACKSETTING:
          self.isShowNotiBox = true;
          self.isShowMenu = false;
          break;
        case EActiveCompoment.NONE:
          break;
        default:
          break;
      }
    },

    // 토스트 알림
    showToast(nagative: boolean, manual: boolean, message: string) {
      this.closeToase();
      self.isNagativeToast = nagative;
      self.isManualClose = manual;
      self.toastMessage = message;
    },
    closeToase() {
      self.isNagativeToast = false;
      self.isManualClose = false;
      self.toastMessage = '';
    },

    // 아이템 지정 관련
    clearSelected() {
      self.selectedMode = 'READ';
      self.selectedCategory = '';
      self.selectedIds.replace([]);
    },
    isSelectedId(id: string) {
      return self.selectedIds.includes(id);
    },
    setReadTask(id: string) {
      self.selectedMode = 'READ';
      self.selectedCategory = 'TASK';
      self.selectedIds.replace([id]);
    },
    setEditTask(id: string) {
      console.log(id, self.selectedIds);
      self.selectedMode = 'EDIT';
      self.selectedCategory = 'TASK';
      self.toggleSelectedId(id);

      console.log(self.selectedIds);
    },
  }))
  .actions(self => ({}));

type UiStoreModelType = typeof UiStoreModel.Type;
export interface IUiStore extends UiStoreModelType {}

import { inject, observer } from 'mobx-react';
import React, { FC, useEffect } from 'react';

import { AppStore } from '../../../store/AppStore';
import { EProjectStatus } from '../../../store/Dashboard';
import { TIP_NOTION_URL } from '../../../store/Dashboard/models/ManagingTip';

import { Dashboard as Comps } from '../../molecules/Dashboard';

import { ProjectList } from '../../organisms/Dashboard';

interface DefaultProps {}
interface Props extends DefaultProps {
  appStore: AppStore;
}

const Dashboard: FC<Props> = inject('appStore')(
  observer(props => {
    const { dashboardStore, notificationStore, userStore } = props.appStore;

    useEffect(() => {
      (async () => {
        if (dashboardStore.projectGroups.length === 0) {
          await dashboardStore.fetchAll();
        }

        if (dashboardStore.managingTips.length === 0) {
          dashboardStore.fetchManagingTips();
        }
      })();
    }, [
      dashboardStore.projectGroups,
      dashboardStore.managingTips,
      userStore.isLoggedIn,
    ]);

    return (
      <Comps.Wrapper>
        {userStore.isLoggedIn && (
          <>
            <Comps.TitleSection>
              <Comps.Title active={true}>프로젝트 목록</Comps.Title>
              <Comps.Title>
                <a
                  href={TIP_NOTION_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  프로젝트 운영팁
                </a>
              </Comps.Title>
            </Comps.TitleSection>

            <Comps.ContentSection>
              <ProjectList
                notiIds={notificationStore.dashboardIds}
                title="진행중 프로젝트"
                status={EProjectStatus.ONGOING}
                store={dashboardStore}
              />
              <ProjectList
                notiIds={notificationStore.dashboardIds}
                title="종료된 프로젝트"
                status={EProjectStatus.END}
                store={dashboardStore}
              />
            </Comps.ContentSection>
          </>
        )}
      </Comps.Wrapper>
    );
  }),
);

export default Dashboard;

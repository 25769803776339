import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

const Wrapper = styled.div`
  width: fit-content;
  min-width: 610px;
  height: 125px;
  border-radius: 10px;
  padding: 25px 60px 0px 35px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  background-color: ${PALETTE['pale-grey']};

  font-size: 15px;
  color: ${PALETTE['black-two']};

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    min-width: 100%;
    height: fit-content;
    padding: 20px;
    margin-top: 64px;
  }
`;

const Amount = styled.div<{ highlight?: boolean }>`
  line-height: 27px;
  font-size: ${p => (p.highlight ? '22px' : '18px')};
  color: ${PALETTE['black-two']};

  &:first-child::before {
    padding: 0px;
    content: '';
  }

  span:last-child {
    font-weight: ${p => (p.highlight ? 'bold' : 'normal')};
    color: ${p => (p.highlight ? PALETTE['purpleish-blue'] : '#000000')};
    @media only screen and (max-width: ${PC}) {
      display: block;
      width: calc(100% - 63px);
      text-align: right;
    }
  }
  span:last-child::before {
    content: '₩ ';
  }
  span.highlight {
    color: ${PALETTE['color-red']};
  }

  @media only screen and (min-width: ${PC}) {
    &::before {
      padding: 50px;
      content: '|';
    }
  }
  @media only screen and (max-width: ${PC}) {
    display: flex;
    line-height: 26px;
    font-size: 16px;
    font-weight: 500;

    & + & {
      margin-top: 12px;
    }

    span:first-child {
      width: 63px;
    }
  }
`;

const AmountRow = styled.div`
  height: 33px;
  margin-top: 20px;
  display flex;
  justify-content: center;

  @media only screen and (min-width: ${PC}) {
    span + span {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    height: fit-content;
    display block;
    margin-top: 14px;
  }
`;

export { Amount, AmountRow, Wrapper };

export const PALETTE = {
  'black-two': '#333333',
  'bright-orange': '#ff6000',
  'brown-grey': '#999999',
  'brownish-grey': '#666666',
  'purpleish-blue': '#5834ff',
  'greyish-brown': '#474747',
  'very-light-pink': '#f0f0f0',
  black: '#1d1d1d',
  'color-emerald': '#1effc5',
  'color-blue': '#4b34ff',
  'color-red': '#ff5f1d',
  'color-pale-purple': '#dfe2ff',
  'color-disabled': '#9a9a9a',
  'color-disabled-bg': '#d6d3d3',
  'button-style': '#ffffff',
  'pale-grey': '#f9f9ff',

  'very-light-pink-two': '#ebebeb',
  'very-light-pink-three': '#bbbbbb',

  white: '#fafafa',
};

import { types } from 'mobx-state-tree';

export const TaskFilterModel = types
  .model('TaskFilter', {
    my_id: types.optional(types.number, 0),
    show_only_my_tasks: types.optional(types.boolean, false),
    person_in_charges: types.array(types.number),
    sprint_title_list: types.array(types.string),
    label_title_list: types.array(types.string),
    use_duedate: types.optional(types.boolean, false),
    duedate_start: types.optional(types.string, ''),
    duedate_end: types.optional(types.string, ''),
    keyword: types.optional(types.string, ''),
  })
  .views(self => ({
    get isValueSelected() {
      if (self.show_only_my_tasks) return true;
      if (self.person_in_charges.length > 0) return true;
      if (self.sprint_title_list.length > 0) return true;
      if (self.label_title_list.length > 0) return true;
      if (self.use_duedate) return true;
      if (self.keyword.length > 0) return true;

      return false;
    },
  }))
  .actions(self => ({
    reset() {
      self.show_only_my_tasks = false;
      self.person_in_charges.replace([]);
      self.sprint_title_list.replace([]);
      self.label_title_list.replace([]);
      self.use_duedate = false;
      self.duedate_start = '';
      self.duedate_end = '';
      self.keyword = '';
    },
    setMyId(id: number) {
      self.my_id = id;
    },
    toggleShowOnlyMyTasks() {
      self.person_in_charges.replace([]);
      self.show_only_my_tasks = !self.show_only_my_tasks;
    },

    addPersonInCharges(value: number) {
      self.show_only_my_tasks = false;
      const index = self.person_in_charges.indexOf(value);
      if (index === -1) {
        self.person_in_charges.push(value);
      }
    },
    removePersonInCharges(value: number) {
      const index = self.person_in_charges.indexOf(value);
      if (index > -1) {
        self.person_in_charges.splice(index, 1);
      }
    },

    addSprint(value: string) {
      const index = self.sprint_title_list.indexOf(value);
      if (index === -1) {
        self.sprint_title_list.push(value);
      }
    },
    removeSprint(value: string) {
      const index = self.sprint_title_list.indexOf(value);
      if (index > -1) {
        self.sprint_title_list.splice(index, 1);
      }
    },

    addLabel(value: string) {
      const index = self.label_title_list.indexOf(value);
      if (index === -1) {
        self.label_title_list.push(value);
      }
    },
    removeLabel(value: string) {
      const index = self.label_title_list.indexOf(value);
      if (index > -1) {
        self.label_title_list.splice(index, 1);
      }
    },

    setUseDueDate(use: boolean) {
      self.use_duedate = use;
    },
    setDueDateStart(date: string) {
      self.duedate_start = date;
    },
    setDueDateEnd(date: string) {
      self.duedate_end = date;
    },
    setKeyword(keyword: string) {
      console.log(':::setKeyword', keyword);
      self.keyword = keyword;
    },
  }));
type TaskFilterType = typeof TaskFilterModel.Type;

export interface ITaskFilter extends TaskFilterType {}

import { Spinner } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { ICalculateStore } from '../../../store/Calculate';
import { ISprintReportStore } from '../../../store/SprintReport';

import { Calculate as Comps } from '../../molecules/Calculate';
import { PayoutFilters, Payouts } from '../../organisms/Calculate';

interface Props {
  className?: string;
  store: ICalculateStore;
  sprintReportStore: ISprintReportStore;
}

const Payout: FC<Props> = observer(props => {
  return (
    <Comps.Section>
      <Comps.SectionHeader>지출 현황</Comps.SectionHeader>

      <PayoutFilters store={props.store} />

      {props.store.isLoading ? (
        <Spinner />
      ) : (
        <Payouts
          includeVAT={props.store.includeVAT}
          deposits={props.store.filteredDeposits}
          sprintReportStore={props.sprintReportStore}
        />
      )}
    </Comps.Section>
  );
});

export default Payout;

import sortBy from 'lodash/sortBy';
import { destroy, flow, getParent, types } from 'mobx-state-tree';

import { AppStore } from '../../AppStore';
import { IUiStore } from '../../UIStore';

import * as API from '../apis';
import { ITask, TaskModel } from './Task';

export const TaskListModel = types
  .model('TaskList', {
    id: types.number,
    created_at: types.string,
    updated_at: types.string,
    order: types.number,
    task_list_id: types.string,
    title: types.string,
    is_editable: types.boolean,
    color: types.string,
    person_in_charge: types.union(types.string, types.null),
    person_in_charge_list: types.array(types.number),
    task_board: types.string,
    tasks: types.array(TaskModel),
    // tasks: types.map(TaskModel)
  })
  .views(self => ({
    get UIStore(): IUiStore | undefined {
      const rootStore = getParent(self, 4);
      if (rootStore.hasOwnProperty('uiStore')) {
        return (rootStore as AppStore).uiStore;
      }
      return undefined;
    },
    get isDefault() {
      const DEFAULT_LIST_NAME = ['작업예정', '최종완료'];
      return DEFAULT_LIST_NAME.includes(self.title.replace(/\s/g, ''));
    },
    get onGoingTasks() {
      return sortBy(
        self.tasks.filter(task => !task.is_archived),
        ['order'],
      );
    },
  }))
  .actions(self => ({
    changeOrder(newOrder: number) {
      self.order = newOrder;
    },

    removeTask(task: ITask) {
      destroy(task);
    },

    setTasks(tasks: ITask[]) {
      self.tasks.replace(tasks);
    },

    showInfoToast(message: string) {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.showToast(false, true, message ? message : '로딩중입니다.');
      }
    },

    closeInfoToast() {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.closeToase();
      }
    },

    showErrorToast(message: string) {
      const uiStore = self.UIStore;
      if (uiStore) {
        uiStore.showToast(
          true,
          false,
          message ? message : '에러가 발생하였습니다.',
        );
      }
    },
  }))
  .actions(self => {
    const createTask = flow(function* (title: string) {
      if (title.length === 0) {
        self.showErrorToast('태스크 카드 타이틀은 필수 입력입니다.');
        throw '태스크 카드 타이틀은 필수 입력입니다.';
      }

      self.showInfoToast(
        `태스크 추가중입니다. ( 서버 응답속도 문제 임시알림 )`,
      );

      try {
        const data = yield API.Task.create(
          self.task_board,
          String(self.id),
          title,
        );
        self.tasks.push(TaskModel.create(data));
      } catch (e) {
        throw e;
      }

      self.closeInfoToast();
    });
    const deleteSelf = flow(function* () {
      try {
        self.showInfoToast(
          `태스크목록 삭제중입니다. ( 서버 응답속도 문제 임시알림 )`,
        );

        yield API.TaskList.delete(self.id);
        destroy(self);
      } catch (e) {
        throw e;
      } finally {
        self.closeInfoToast();
      }
    });
    const updateTitle = flow(function* (title: string) {
      try {
        yield API.TaskList.put(self.id, self.task_board, 'title', title);
        self.title = title;
      } catch (e) {
        //@ts-ignore
        self.showErrorToast(e.response.data.msg);
        throw e;
      }
    });
    const fetchTask = flow(function* (id: number) {
      const task = self.tasks.find(task => task.id === id);

      if (!task) return;

      const data = yield API.Task.fetch(id);
      const newTask = TaskModel.create(data);

      destroy(task);
      self.tasks.push(newTask);
    });
    const updatePersonInCharge = flow(function* (userIds: number[]) {
      try {
        yield API.TaskList.updatePersonInCharge(self.id, userIds);
      } catch (e) {
        throw e;
      }
    });

    return {
      createTask,
      deleteSelf,
      fetchTask,
      updateTitle,
      updatePersonInCharge,
    };
  });
type TaskListType = typeof TaskListModel.Type;

export interface ITaskList extends TaskListType {}

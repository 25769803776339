import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

const Wrapper = styled.div<{
  theme: string;
  small: boolean;
  disabled: boolean;
  unfocused: boolean;
  pcStyle: string;
  mobileStyle: string;
}>`
  width: ${(props: any) => (props.small ? '122px' : '152px')};
  height: ${(props: any) => (props.small ? '32px' : '50px')};
  padding: ${(props: any) => (props.small ? '7px 0px' : '14px 0px')};
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);

  ${(props: any) => {
    if (!props.disabled) {
      return 'cursor: pointer;';
    }
  }}

  font-family: 'Noto Sans KR', sans-serif;
  font-size: ${(props: any) => (props.small ? '12px' : '15px')};
  font-weight: ${(props: any) => (props.small ? '500' : 'bold')};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  // 색상
  ${(props: any) => {
    if (props.disabled) {
      return `
          color: ${PALETTE['color-disabled']};
          background-color: ${PALETTE['color-disabled-bg']};
        `;
    } else if (props.unfocused) {
      return `
          color: #9da6fc;
          background-color: ${PALETTE['color-pale-purple']};
        `;
    } else {
      if (props.theme === 'blue') {
        return props.small
          ? `
            color: ${PALETTE['purpleish-blue']};
            background-color: ${PALETTE['color-pale-purple']};
            :hover {
              color: ${PALETTE['button-style']};
            }
          `
          : `
            color: ${PALETTE['button-style']};
            background-color: ${PALETTE['purpleish-blue']};
            :hover {
              color: ${PALETTE['color-emerald']};
            }
          `;
      } else if (props.theme === 'white') {
        return `
            color: ${PALETTE['purpleish-blue']};
            background-color: ${PALETTE['button-style']};
            :hover {
              color: ${PALETTE['color-emerald']};
            }
          `;
      } else if (props.theme === 'emerald') {
        return `
            color: ${PALETTE['purpleish-blue']};
            background-color: ${PALETTE['color-emerald']};
            :hover {
              color: ${PALETTE['button-style']};
            }
          `;
      } else if (props.theme === 'purple-wire') {
        return `
            color: ${PALETTE['purpleish-blue']};
            background-color: ${PALETTE['white']};
            border: solid 1px ${PALETTE['purpleish-blue']};
            /*:hover {
              color: ${PALETTE['button-style']};
            }*/
          `;
      } else if (props.theme === 'purple-white') {
        return `
            background-color: ${PALETTE['purpleish-blue']};
            color: ${PALETTE['white']};
            border: solid 1px ${PALETTE['purpleish-blue']};
            /*:hover {
              color: ${PALETTE['button-style']};
            }*/
          `;
      } else {
        return `
            color: ${PALETTE['button-style']};
            background-color: ${PALETTE['color-disabled-bg']};
          `;
      }
    }
  }}

  // 전달된 스타일
  ${(props: any) => {
    return props.pcStyle;
  }}

  // 전달된 모바일 스타일
  @media only screen and (max-width: ${PC}) {
    ${(props: any) => {
      return props.mobileStyle;
    }}
  }
`;

interface ButtonProps {
  text: string;
  theme: string;
  small: boolean;
  // width: string | undefined;
  disabled: boolean;
  unfocused: boolean;
  readonly: boolean;
  pcStyle: string;
  style: any;
  textStyle: any;
  mobileStyle: string;
  onClick: () => void;
}

class Button extends Component<ButtonProps> {
  static defaultProps = {
    text: '',
    theme: 'blue',
    small: false,
    style: {},
    textStyle: {},
    // width: undefined,
    disabled: false,
    unfocused: false,
    readonly: false,
    pcStyle: '',
    mobileStyle: '',
  };

  render() {
    return (
      <Wrapper
        style={this.props.style}
        theme={this.props.theme}
        small={this.props.small}
        disabled={this.props.disabled}
        unfocused={this.props.unfocused}
        pcStyle={this.props.pcStyle}
        mobileStyle={this.props.mobileStyle}
        onClick={() => {
          if (!this.props.disabled && !this.props.readonly) {
            this.props.onClick();
          }
        }}
      >
        <span style={this.props.textStyle}>{this.props.text}</span>
        {this.props.children}
      </Wrapper>
    );
  }
}

export default Button;

import { Intent, Spinner } from '@blueprintjs/core';
import moment from 'moment';
import 'moment/locale/ko';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { EProjectStatus } from '../../../store/Dashboard/constants';

import { ExternalTool } from '../../../store/Dashboard/models';

import { IProjectGroup } from '../../../store/Dashboard/models/ProjectGroup';
import { ISchedule } from '../../../store/Dashboard/models/Schedule';
// import { ISprint } from '../../../store/Dashboard/models/Sprint';
import {
  ExternalTools,
  ProjectStatusTag,
  SprintBar,
  Summary as Comps,
} from '../../molecules/Dashboard';

moment.locale('ko');

const SummaryHeader: FC<{
  name: string;
  id: string;
  isInspectionDownloading: boolean;
  handleClickInspection: () => void;
}> = ({ name, id, isInspectionDownloading, handleClickInspection }) => {
  // const [ downloading, setDownloading ] = useState(false);
  const history = useHistory();

  return (
    <Comps.Header>
      <Comps.HeaderLeftSection>
        <Comps.BackButton onClick={() => history.push('/dashboard')} />
        <Comps.ProjectName>{name}</Comps.ProjectName>
        <div>{id}</div>
      </Comps.HeaderLeftSection>

      <Comps.HeaderRightSection>
        {isInspectionDownloading && (
          <Spinner
            size={16}
            intent={Intent.PRIMARY}
            className="inspection-spinner"
          />
        )}
        <Comps.InspectionLink
          onClick={async () => {
            await handleClickInspection();
          }}
        >
          검수서 보기
        </Comps.InspectionLink>
      </Comps.HeaderRightSection>
    </Comps.Header>
  );
};

const TodayBox: FC<{
  status: EProjectStatus;
  schedule: ISchedule | null;
  dateHolding: Date | null;
}> = ({ status, schedule, dateHolding }) => {
  return (
    <Comps.TodayWrapper>
      <Comps.BoxTitle className="today">
        TODAY
        <div className="date">{moment().format('MM.DD dddd')}</div>
      </Comps.BoxTitle>

      <ProjectStatusTag status={status} />

      {status !== EProjectStatus.MATCHING &&
        schedule &&
        status !== EProjectStatus.WAITING &&
        schedule.dateStart !== null && (
          <div className="schedule-date">
            <span>{moment(schedule.dateStart).format('YYYY.MM.DD')} </span>
            <span>~</span>
            {dateHolding && (
              <span>({moment(dateHolding).format('YYYY.MM.DD')} 홀딩) </span>
            )}
          </div>
        )}
    </Comps.TodayWrapper>
  );
};

const EndTodayBox: FC<{
  status: EProjectStatus;
  dateEnd: Date;
  dateHolding: Date | null;
  dateFlawing: Date | null;
  dateKeeping: Date | null;
  remainedDays: number;
}> = ({
  status,
  dateEnd,
  dateFlawing,
  dateKeeping,
  dateHolding,
  remainedDays,
}) => {
  return (
    <Comps.TodayWrapper>
      <Comps.BoxTitle className="today">
        TODAY
        <div className="date">{moment().format('MM.DD dddd')}</div>
      </Comps.BoxTitle>

      <Comps.BoxRow>
        <ProjectStatusTag status={status} />

        {status !== EProjectStatus.ENDHOLD && (
          <>
            {status === EProjectStatus.FLAWING && (
              <div>하자보수: {moment(dateFlawing).format('YYYY.MM.DD')} ~</div>
            )}

            {status === EProjectStatus.KEEPING && (
              <div>유지보수: {moment(dateKeeping).format('YYYY.MM.DD')} ~</div>
            )}

            {status !== (EProjectStatus.FLAWING || EProjectStatus.KEEPING) &&
              remainedDays > 0 && (
                <>
                  {status === EProjectStatus.KEEP_ACCEPT && (
                    <>
                      <div className="remainedAccept">
                        접수 가능 {remainedDays}일 남음
                      </div>
                      <Comps.RequestLink
                        onClick={e => {
                          e.stopPropagation();
                          window.open(
                            `https://www.notion.so/4426c23188b944f1a68a3c5e07d6a5e9`,
                            '_blank',
                          );
                        }}
                      >
                        유지보수 접수
                      </Comps.RequestLink>
                    </>
                  )}
                  {status === EProjectStatus.FLAW_ACCEPT && (
                    <>
                      <div className="remainedAccept">
                        접수 가능 {remainedDays}일 남음
                      </div>
                      <Comps.RequestLink
                        onClick={e => {
                          e.stopPropagation();
                          window.open(
                            `https://www.notion.so/4426c23188b944f1a68a3c5e07d6a5e9`,
                            '_blank',
                          );
                        }}
                      >
                        하자보수 접수
                      </Comps.RequestLink>
                    </>
                  )}
                </>
              )}
          </>
        )}
      </Comps.BoxRow>

      <div className="schedule-date">
        <span>{moment(dateEnd).format('YYYY.MM.DD')} 종료 </span>
        {status === EProjectStatus.ENDHOLD && (
          <span>({moment(dateHolding).format('YYYY.MM.DD')} 홀딩) </span>
        )}
      </div>
    </Comps.TodayWrapper>
  );
};

const SprintBox: FC<{
  doneCount: number;
  ongoingCount: number;
  totalCount: number;
}> = props => {
  return (
    <Comps.SprintStatusWrapper>
      <Comps.BoxTitle>전체 스프린트 진행 현황</Comps.BoxTitle>

      <div className="status-count">
        <div className="count">
          예정
          <span className="scheduled">
            {props.totalCount - props.ongoingCount - props.doneCount}
          </span>
        </div>
        <div className="count">
          진행
          <span className="ongoing">{props.ongoingCount}</span>
        </div>
        <div className="count">
          종료
          <span className="done">{props.doneCount}</span>
        </div>
      </div>

      <SprintBar className="sprint-bar" {...props} />
    </Comps.SprintStatusWrapper>
  );
};

const ToolBox: FC<{
  tools: ExternalTool.IExternalTool[];
}> = ({ tools }) => {
  return (
    <Comps.ToolWrapper>
      <Comps.BoxTitle>협업툴</Comps.BoxTitle>

      <ExternalTools
        iconSize={46}
        iconPadding={20}
        iconSizeMobile={42}
        iconPaddingMobile={10}
        tools={tools}
      />
    </Comps.ToolWrapper>
  );
};

interface IProps {
  projectGroup: IProjectGroup;
  isInspectionDownloading: boolean;
  handleClickInspection: () => void;
}
const Summary: FC<IProps> = ({
  projectGroup,
  isInspectionDownloading,
  handleClickInspection,
}) => {
  const { schedule } = projectGroup;
  const totalCount = schedule ? schedule.sprints.length : 0;
  const doneCount = schedule ? schedule.doneSprints.length : 0;
  const ongoingCount = schedule ? schedule.ongoingSprints.length : 0;
  const remainedDays =
    projectGroup.status === EProjectStatus.FLAW_ACCEPT
      ? projectGroup.remainedFlawingDays
      : projectGroup.remainedKeepingDays;

  return (
    <Comps.Wrapper>
      <SummaryHeader
        name={projectGroup.name}
        id={projectGroup.groupId}
        isInspectionDownloading={isInspectionDownloading}
        handleClickInspection={handleClickInspection}
      />

      <Comps.Body>
        {projectGroup.isEndStatus && projectGroup.dateComplete ? (
          <EndTodayBox
            status={projectGroup.status}
            dateEnd={projectGroup.dateComplete}
            dateHolding={projectGroup.dateHolding}
            dateFlawing={projectGroup.dateFlawing}
            dateKeeping={projectGroup.dateKeeping}
            remainedDays={remainedDays}
          />
        ) : (
          <TodayBox
            status={projectGroup.status}
            schedule={projectGroup.schedule}
            dateHolding={projectGroup.dateHolding}
          />
        )}

        {projectGroup.status === EProjectStatus.ONGOING && (
          <SprintBox
            totalCount={totalCount}
            doneCount={doneCount}
            ongoingCount={ongoingCount}
          />
        )}

        <ToolBox tools={projectGroup.externalTools} />
      </Comps.Body>
    </Comps.Wrapper>
  );
};

export default Summary;

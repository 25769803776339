import { DatePicker, Form, Input, Radio, Select } from 'antd';

import 'antd/es/date-picker/style/css'; // for css
import 'antd/es/select/style/css';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AppStore } from '../../../store/AppStore';

import { Reception as Comps } from '../../molecules/Receptions';

// 참고 ; 현재 유일하게 antd css 프레임워크가 접수페이지에서는 사용되고 있음. - 기존 홈페이지의 디자인을 그대로 유지하기 위한 방법 (캘린더, 업로더 등)
const RadioGroup = Radio.Group;
const dateFormat = 'YYYY-MM-DD';
// const { RangePicker } = DatePicker;
const { Option } = Select;
const FormItem = Form.Item;

interface RouteParams {
  receptionId: string;
}

interface ReceptionDetailPageProps extends RouteComponentProps<RouteParams> {}

interface InjectedProps extends ReceptionDetailPageProps {
  appStore: AppStore;
}

interface ReceptionState {
  userInfo: {
    name: string;
    email: string;
  };

  projAreaType1: boolean;
  projAreaType2: boolean;
  projAreaType3: boolean;

  projTypeEtcText: string;
  projType1: boolean;
  projType2: boolean;
  projType3: boolean;
  projType4: boolean;
  projEtcText: string;

  dataPrepared1: boolean;
  dataPrepared2: boolean;
  dataPrepared3: boolean;

  dataEtc1: boolean;
  dataEtc2: boolean;
  dataEtc3: boolean;
  dataEtc4: boolean;
  dataEtc5: boolean;
  referenceTxt: '';

  dataPriorityValue: string;

  dataFeature1: boolean;
  dataFeature2: boolean;
  dataFeature3: boolean;
  dataFeature4: boolean;
  dataFeature5: boolean;

  driveLink: string;

  projTitle: string;
  phone: string;
  affiliation: string;
  meetingDate: string;
  meetingTime: string;

  proDetailText: string;
  affiliationType: string;
  affiliationTypeText: string;

  refLink: string;
  refFileName: string;

  receptionistName: string;
  clientEmail: string;
  loading: boolean;
  driveLinks: string[];
  serviceType: string;
}

@inject('appStore')
@observer
class ReceptionViewPage extends Component<ReceptionDetailPageProps> {
  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get reception() {
    const { receptionId } = this.props.match.params;

    return this.injected.appStore.receptionStore.receptions.find(
      x => x.id === +receptionId,
    );
  }

  get userStore() {
    return this.injected.appStore.myPageStore;
  }

  constructor(props: any) {
    super(props);
  }

  state: ReceptionState = {
    userInfo: {
      name: '',
      email: '',
    },
    // dataPreparedValue: null,

    projAreaType1: false,
    projAreaType2: false,
    projAreaType3: false,
    projTypeEtcText: '',

    projType1: false,
    projType2: false,
    projType3: false,
    projType4: false,
    projEtcText: '',

    dataPrepared1: false,
    dataPrepared2: false,
    dataPrepared3: false,

    dataEtc1: false,
    dataEtc2: false,
    dataEtc3: false,
    dataEtc4: false,
    dataEtc5: false,
    referenceTxt: '',

    dataFeature1: false,
    dataFeature2: false,
    dataFeature3: false,
    dataFeature4: false,
    dataFeature5: false,

    dataPriorityValue: '',

    driveLink: 'http://',

    projTitle: '',
    phone: '',
    affiliation: '',
    meetingDate: '',
    meetingTime: '',
    receptionistName: '',
    clientEmail: '',

    proDetailText: '',
    affiliationType: '-',
    affiliationTypeText: '',

    refLink: '',
    refFileName: '',
    loading: false,
    driveLinks: [],
    serviceType: '',
  };

  async componentDidMount() {
    const { receptionId } = this.props.match.params;

    if (receptionId === 'start') {
      document.location.href = '/no-page';
      return;
    }

    const { receptionStore } = this.injected.appStore;
    try {
      await receptionStore.fetchReception(+receptionId);
    } catch (e) {}

    if (this.reception) {
      let affiliationTempText: string = '';
      switch (this.reception.affiliationType) {
        case 'startup':
          affiliationTempText = '스타트업';
          break;
        case 'enterprise':
          affiliationTempText = '대기업';
          break;
        case 'company':
          affiliationTempText = '중소기업';
          break;
      }

      let tempMeetingIndex: number = this.reception.dateMeeting.indexOf('T');

      this.setState({
        serviceType: this.reception.serviceType,
        projTypeEtcText: this.reception.areaTypeEtcText,
        projEtcText: this.reception.areaEtcText,
        referenceTxt: this.reception.referenceText,
        dataPriorityValue: this.reception.priority,
        proDetailText: this.reception.detail,
        projTitle: this.reception.title,
        driveLinks: this.reception.driveLinks,

        receptionistName: this.reception.receptionistName,
        clientEmail: this.reception.clientEmail,
        phone: this.reception.phone,
        affiliation: this.reception.affiliation,
        affiliationTypeText: affiliationTempText,

        meetingDate: this.reception.dateMeeting.substring(0, tempMeetingIndex),
        meetingTime: this.reception.dateMeeting.substring(
          tempMeetingIndex + 1,
          tempMeetingIndex + 6,
        ),
      });

      // TODO: 좀 더 효율적으롭 바꾸기. (백엔드와 데이터를 매칭 해야해서 어쩔 수 없음 ㅜㅠ)
      this.reception.areaType.map((item: string) => {
        switch (item) {
          case 'web':
            this.setState({ projAreaType1: true });
            break;
          case 'app':
            this.setState({ projAreaType2: true });
            break;
          case 'etc':
            this.setState({ projAreaType3: true });
            break;
        }
      });
      this.reception.area.map((item: string) => {
        switch (item) {
          case 'planning':
            this.setState({ projType1: true });
            break;
          case 'design':
            this.setState({ projType2: true });
            break;
          case 'develop':
            this.setState({ projType3: true });
            break;
          case 'etc':
            this.setState({ projType4: true });
            break;
        }
      });
      this.reception.dataPrepared.map((item: string) => {
        switch (item) {
          case 'proposal_true':
            this.setState({ dataPrepared1: true });
            break;
          case 'design_true':
            this.setState({ dataPrepared2: true });
            break;
          case 'develop_true':
            this.setState({ dataPrepared3: true });
            break;
        }
      });
      this.reception.etcReference.map((item: string) => {
        switch (item) {
          case 'reference':
            this.setState({ dataEtc1: true });
            break;
          case 'communication':
            this.setState({ dataEtc2: true });
            break;
          case 'planned':
            this.setState({ dataEtc3: true });
            break;
          case 'nda':
            this.setState({ dataEtc4: true });
            break;
          case 'existed_team':
            this.setState({ dataEtc5: true });
            break;
        }
      });
      this.reception.feature.map((item: string) => {
        switch (item) {
          case 'signup':
            this.setState({ dataFeature1: true });
            break;
          case 'post':
            this.setState({ dataFeature2: true });
            break;
          case 'message':
            this.setState({ dataFeature3: true });
            break;
          case 'shop':
            this.setState({ dataFeature4: true });
            break;
          case 'admin':
            this.setState({ dataFeature5: true });
            break;
        }
      });
    }
  }

  render() {
    return (
      <Comps.ReceptionWrapper>
        <h1>
          안녕하세요!
          <br />
          프로젝트 시작을 도와드리겠습니다.
        </h1>
        <p className="n-open">
          시작하고자 하는 프로젝트에 대해 알려주시면,
          <br />
          영업일 기준 2-3일 내에 문자나 이메일로 연락드리겠습니다.
        </p>
        <Comps.startFormWrap>
          <Comps.startServiceTypeWrap>
            <h2>서비스 종류를 선택해 주세요.</h2>
            <span className="titleDescription">
              시소 팀이 현재 세컨팀이라는 새로운 모델로 프로젝트를 진행하는
              파일럿 서비스를 실험 중에 있습니다.
              <br />
              서비스 비교 후, 팀에 맞는 서비스를 선택해주세요. 선택하신 모델은
              영업 시 변경이 가능합니다.
            </span>
            <Comps.startServiceBoxWrap>
              <ServiceChooseBox
                serviceType={'second'}
                selectedBox={this.state.serviceType}
              />
              <ServiceChooseBox
                serviceType={'normal'}
                selectedBox={this.state.serviceType}
              />
            </Comps.startServiceBoxWrap>
          </Comps.startServiceTypeWrap>
          <Comps.startDefaultTypeWrap>
            <h2 ref="projTypeAreaTitle">
              해당되는 프로젝트의 종류를 모두 선택해주세요.
            </h2>
            <Comps.choiceProject>
              <li>
                <input
                  type="checkbox"
                  id="chPjType1"
                  checked={this.state.projAreaType1}
                  disabled
                />

                <label htmlFor="chPjType1">
                  웹사이트<span></span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="chPjType2"
                  checked={this.state.projAreaType2}
                  disabled
                />
                <label htmlFor="chPjType2">앱</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="chPjType3"
                  checked={this.state.projAreaType3}
                  disabled
                />
                <label htmlFor="chPjType3">기타</label>
              </li>
              {this.state.projAreaType3 ? (
                <Comps.pInput>
                  <input
                    type="text"
                    id="wantPj"
                    placeholder="기타"
                    value={this.state.projTypeEtcText}
                  />
                </Comps.pInput>
              ) : (
                ''
              )}
            </Comps.choiceProject>
          </Comps.startDefaultTypeWrap>
          <Comps.startDefaultTypeWrap>
            <h2 ref="projTypeAreaTitle">
              필요하신 작업 범위를 모두 선택해주세요.
            </h2>
            <Comps.choiceProject>
              <li>
                <input
                  type="checkbox"
                  id="chPj1"
                  checked={this.state.projType1}
                  disabled
                />
                <label htmlFor="chPj1">
                  기획<span></span>
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="chPj2"
                  checked={this.state.projType2}
                  disabled
                />
                <label htmlFor="chPj2">디자인</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="chPj3"
                  checked={this.state.projType3}
                  disabled
                />
                <label htmlFor="chPj3">개발</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="chPj4"
                  checked={this.state.projType4}
                  disabled
                />
                <label htmlFor="chPj4">기타</label>
              </li>
              {this.state.projType4 ? (
                <Comps.pInput>
                  <input
                    type="text"
                    id="wantPj"
                    placeholder="기타"
                    value={this.state.projEtcText}
                    disabled
                  />
                </Comps.pInput>
              ) : (
                ''
              )}
            </Comps.choiceProject>
          </Comps.startDefaultTypeWrap>
          <Comps.startDefaultTypeWrap>
            <h2 ref="projTypeAreaTitle">
              (선택) 의뢰 건과 관련하여 준비된 자료가 있다면 해당 내용을 모두
              선택해주세요.
            </h2>
            <Comps.choiceProject>
              <li>
                <input
                  type="checkbox"
                  id="dataPrepared1"
                  checked={this.state.dataPrepared1}
                  disabled
                />
                <label htmlFor="dataPrepared1">
                  기획서가 준비되어 있습니다
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataPrepared2"
                  checked={this.state.dataPrepared2}
                  disabled
                />
                <label htmlFor="dataPrepared2">
                  디자인 원본과 가이드가 준비되어 있습니다
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataPrepared3"
                  checked={this.state.dataPrepared3}
                  disabled
                />
                <label htmlFor="dataPrepared3">
                  개발 소스코드 및 문서가 준비되어 있습니다.
                </label>
              </li>
            </Comps.choiceProject>
          </Comps.startDefaultTypeWrap>
          <Comps.startDefaultTypeWrap>
            <h2 ref="projTypeAreaTitle">
              (선택) 관련된 기타 해당사항을 모두 체크해주세요
            </h2>
            <Comps.choiceProject>
              <li>
                <input
                  type="checkbox"
                  id="dataEtc1"
                  checked={this.state.dataEtc1}
                  onChange={e => {
                    this.setState({ dataEtc1: !this.state.dataEtc1 });
                  }}
                  disabled
                />
                <label htmlFor="dataEtc1">
                  참고할 만한 다른 서비스 및 사이트가 있습니다
                </label>
                {this.state.dataEtc1 ? (
                  <li>
                    <input
                      value={this.state.referenceTxt}
                      type="text"
                      id=""
                      placeholder="참고사이트 이름 혹은 링크"
                      // onChange={(e)=>{ this.setState( { referenceTxt: e.target.value } ) }}
                    />
                  </li>
                ) : (
                  ''
                )}
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataEtc2"
                  checked={this.state.dataEtc2}
                  disabled
                />
                <label htmlFor="dataEtc2">
                  커뮤니케이션 담당자가 관련경험이 있습니다
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataEtc3"
                  checked={this.state.dataEtc3}
                  disabled
                />
                <label htmlFor="dataEtc3">
                  추후 업데이트 등 중장기적 계획이 있습니다
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataEtc4"
                  checked={this.state.dataEtc4}
                  disabled
                />
                <label htmlFor="dataEtc4">비밀 유지 계약이 필요합니다</label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataEtc5"
                  checked={this.state.dataEtc5}
                  disabled
                />
                <label htmlFor="dataEtc5">
                  추후 유지보수를 위한 개발인력이 있습니다
                </label>
              </li>
            </Comps.choiceProject>
          </Comps.startDefaultTypeWrap>
          <Comps.startDefaultRadioWrap>
            <h2 ref="dataPriorityTitle">희망하는 운영방식을 선택해주세요.</h2>
            <RadioGroup
              value={this.state.dataPriorityValue}
              className="choiceSingleProject"
              disabled={true}
            >
              <Radio value="fast" className="inlineSelect">
                <span>
                  <strong>빠른 런칭</strong>, 풀타임 작업이 가능한 고급 인력으로
                  팀을 구성하여 요청하신 기간안에 런칭, <br />
                  빠른 시장테스트가 필요하거나 오픈일자가 정해진 고객에게
                  추천합니다.
                </span>
              </Radio>
              <Radio value="perfection" className="inlineSelect">
                <span>
                  <strong>완성도 높은 런칭</strong>, 디테일한 매니징과 검수를
                  통해 확실한 결과물 런칭,
                  <br /> 안정된 결과물로 신뢰를 얻어야 하는 고객에게 추천합니다.
                </span>
              </Radio>
              <Radio value="cost" className="inlineSelect">
                <span>
                  <strong>저비용 런칭</strong>, 꼭 필요한 부분만 엄선 제작하여
                  최소비용으로 런칭, <br /> 한정된 예산안에 결과물이 필요한
                  고객에게 추천합니다.
                </span>
              </Radio>
            </RadioGroup>
          </Comps.startDefaultRadioWrap>
          <Comps.startDefaultTypeWrap>
            <h2 ref="projTypeAreaTitle">
              (선택) 제작해야 할 필수 기능이 있다면 해당 내용을 모두
              선택해주세요.
            </h2>
            <Comps.choiceProject>
              <li>
                <input
                  type="checkbox"
                  id="dataFeature1"
                  checked={this.state.dataFeature1}
                  disabled
                />
                <label htmlFor="dataFeature1">
                  1) 회원기능 <br />
                  이용자들이 로그인을 해야 사용할 수 있는 기능들이 있습니다.
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataFeature2"
                  checked={this.state.dataFeature2}
                  disabled
                />
                <label htmlFor="dataFeature2">
                  2) 게시물 기능
                  <br />
                  이용자, 관리자가 글 혹은 사진 등의 게시물을 등록, 수정, 삭제할
                  수 있습니다.
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataFeature3"
                  checked={this.state.dataFeature3}
                  disabled
                />
                <label htmlFor="dataFeature3">
                  3) 메시지 기능
                  <br />
                  이용자간 쪽지/채팅 등의 방식으로 소통할 수 있습니다.
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataFeature4"
                  checked={this.state.dataFeature4}
                  disabled
                />
                <label htmlFor="dataFeature4">
                  4) 결제/쇼핑몰 기능
                  <br />
                  이용자가 카드 등을 이용해 등록된 상품을 결제할 수 있습니다.
                </label>
              </li>
              <li>
                <input
                  type="checkbox"
                  id="dataFeature5"
                  checked={this.state.dataFeature5}
                  disabled
                />
                <label htmlFor="dataFeature5">
                  5) 관리자 기능
                  <br />
                  제작물 혹은 이용자의 게시물을 관리하거나, 조회 및 통계 기능을
                  활용하여 현황을 살펴볼 수 있습니다.
                </label>
              </li>
            </Comps.choiceProject>
          </Comps.startDefaultTypeWrap>
          <Comps.startUserFormWrap>
            <Comps.startH2title>접수 정보를 적어주세요.</Comps.startH2title>
            <Comps.startPjFormWrap>
              <FormItem className="startPjFormItemLayout" label="프로젝트 명">
                <Input disabled={true} value={this.state.projTitle} />
              </FormItem>
              <FormItem className="startPjFormItemLayout" label="이름">
                <Input value={this.state.receptionistName} disabled={true} />
              </FormItem>
              <FormItem className="startPjFormItemLayout" label="이메일">
                <Input value={this.state.clientEmail} disabled={true} />
              </FormItem>
              <FormItem className="startPjFormItemLayout" label="전화번호">
                <Input
                  value={this.state.phone}
                  disabled={true}
                  // onChange={(e)=>{ this.setState({ phone: e.target.value  }, () => {
                  // if (this.state.phone.length == 3) {
                  //   this.setState({
                  //     phone: this.state.phone.substring(0,4) + '-'
                  //   })
                  // }
                  // }) }}
                  placeholder="- 없이 입력해주세요."
                />
              </FormItem>
              <FormItem
                className="startPjFormItemLayout startMeetingLayout"
                label="소속"
              >
                <Input
                  placeholder="소속을 입력 해주세요."
                  value={this.state.affiliation}
                  disabled={true}
                />
                <span className="startMeetingSelect">
                  <Select disabled={true}>
                    <Option value="startup">스타트업</Option>
                    <Option value="company">중소기업</Option>
                    <Option value="enterprise">대기업</Option>
                  </Select>
                </span>
              </FormItem>
              <FormItem
                className="startPjFormItemLayout startMeetingLayout"
                label="온라인 미팅 희망일 (정확한 미팅시간은 담당 PM이 연락을 드립니다)"
              >
                <Comps.startMeetingSpan>
                  <DatePicker
                    disabled={true}
                    placeholder={this.state.meetingDate}
                    format={dateFormat}
                  />
                </Comps.startMeetingSpan>
                <span className="startMeetingSelect">
                  <Select
                    disabled={true}
                    placeholder={this.state.meetingTime}
                  ></Select>
                </span>
              </FormItem>
              <FormItem className="startPjFormItemEtcLayout"></FormItem>
            </Comps.startPjFormWrap>
          </Comps.startUserFormWrap>
          <Comps.startInputPj>
            <h2 ref="dataDetailTitle">
              프로젝트 의뢰에 대해 자세히 적어주세요.{' '}
            </h2>
            <div className="textareaBox">
              <textarea
                id="InputPjDetail"
                name="InputPjDetail"
                placeholder="서비스의 자세한 소개, 그리고 원하는 결과물에 대해 구체적으로 적어주세요."
                value={this.state.proDetailText}
                disabled={true}
              />
            </div>
          </Comps.startInputPj>
          <Comps.startStyleSltDesWrap>
            <h2 ref="linkTitle">
              회사 소개나, 자료에 대해 정리된 문서가 있으시면 아래 구글 드라이브
              링크에 공유해주세요.
            </h2>
            <span className="titleDescription">
              파일 업로드는 5MB이하의 pdf, hwp, doc, docx, ppt, pptx, xls, xlsx,
              txt 만 가능합니다. 5MB 이상의 파일은 구글 드라이브 링크로 공유
              해주십시요.
            </span>

            {this.state.driveLinks.map((item: any, index: number) => {
              return (
                <div className="projectBox" key={index}>
                  <p>
                    <a href={item} target="_blank">
                      {item}
                    </a>
                  </p>
                </div>
              );
            })}
          </Comps.startStyleSltDesWrap>
        </Comps.startFormWrap>
        <Comps.btnToolBar></Comps.btnToolBar>
      </Comps.ReceptionWrapper>
    );
  }
}

export default ReceptionViewPage;

const ServiceChooseBox: React.FC<{
  serviceType: string;
  selectedBox: string;
}> = props => {
  let title: string;
  let clsName: string;

  props.serviceType == 'second' ? (title = '세컨팀') : (title = '일반의뢰');
  clsName = props.selectedBox == props.serviceType ? 'selectedBox' : '';

  return (
    <Comps.startServiceBox className={clsName}>
      <h3>{title}</h3>
      {props.serviceType == 'second' && (
        <>
          <ul>
            <li>팀웍이 중요한 중/장기 프로젝트에 적합</li>
            <li>스프린트 기간 선구매 방식</li>
            <li>일정 비용 상당의 시간제 태스크 무료 제공</li>
            <li>채용을 위한 시니어 및 주니어 실무자 추천</li>
            <li>IT 전문인력과 협업 경험 필수</li>
          </ul>
          <div className="startServiceBoxDescription">
            <a
              href="https://www.notion.so/seesorufree/Second-Team-0f29cca83bfa4a33acb53d010e89e8c0"
              target="_blank"
            >
              세컨팀에 대해 더 자세히 알아보세요 (클릭)
            </a>
          </div>
        </>
      )}
      {props.serviceType == 'normal' && (
        <ul>
          <li>저예산 단순, 단기 프로젝트에 적합</li>
          <li>매 스프린트 종료시 정산</li>
          <li>시간제 태스크 이용 제한</li>
          <li>일부 시소 서비스 이용 제한</li>
          <li>IT 전문인력과 협업할 의지 필수</li>
        </ul>
      )}
    </Comps.startServiceBox>
  );
};

/*
  TODO: enum 네이밍 룰
  * E로 시작할 것
*/
export enum EResponseState {
  DONE = 'done',
  ERROR = 'error',
  INITIAL = 'initial',
  PENDING = 'pending',
}

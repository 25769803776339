import moment from 'moment';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import FileSvg from '../../../static/icons/icon-file-blue.svg';
import { IReport } from '../../../store/Dashboard/models/SprintReport';
import SprintReportDialog from './SprintReportDialog';

const Wrapper = styled.div`
  padding: 0px 20px;
  margin-top: 20px;

  @media only screen and (max-width: ${PC}) {
    display: block;
  }
`;
const Title = styled.div`
  line-height: 19px;
  // text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  padding-bottom: 6px;
  font-size: 13px;
  font-weight: bold;
  color: ${PALETTE['black-two']});
  border-bottom: solid 1px ${PALETTE['very-light-pink-two']};
}
`;
const Body = styled.div``;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 44px;
  color: ${PALETTE['brownish-grey']};
  font-size: 13px;
  padding-left: 20px;

  div:first-child {
    position: relative;
    color: ${PALETTE['purpleish-blue']};
    cursor: pointer;
    &::before {
      content: '';
      background: url('${FileSvg}') no-repeat center center;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 15px;
      left: -19px;
    }
  }

  div:last-child {
    text-align: right;
  }

  @media only screen and (max-width: ${PC}) {
    height: auto;
    display: block;
    padding-top: 15px;
    div {
      line-height: 19px;
    }

    div + div {
      margin-top: 5px;
    }

    div:last-child {
      text-align: left;
    }
  }
`;

const SprintReport: FC<{
  className?: string;
  hideTitle?: boolean;
  reports: IReport[] | undefined;
}> = ({ className, hideTitle, reports }) => {
  const [selectedReport, setSelectedReport] = useState<IReport | null>(null);
  const [isReportOpen, setIsReportOpen] = useState(false);

  return (
    <Wrapper className={className}>
      {!hideTitle && <Title>스프린트 보고서</Title>}

      <Body>
        {reports &&
          reports.map((report, i) => (
            <Row
              key={i}
              onClick={() => {
                setSelectedReport(report);
                setIsReportOpen(true);
              }}
            >
              <div>{`${report.title}.pdf`}</div>
              <div>
                발송일: {moment(report.datePublish).format('YYYY.MM.DD HH:mm')}
              </div>
            </Row>
          ))}
      </Body>

      {selectedReport && (
        <SprintReportDialog
          isOpen={isReportOpen}
          report={selectedReport}
          handleClose={() => setIsReportOpen(false)}
        />
      )}
    </Wrapper>
  );
};

export default SprintReport;

import { Button } from '@blueprintjs/core';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
// import { inject, observer } from "mobx-react";
import { PALETTE } from '../../constants/color';
import BgSVG from '../../static/images/bg-seeso.png';

const Wrapper = styled.div`
  background: ${PALETTE['purpleish-blue']} url('${BgSVG}') no-repeat 50% 100%;
  width: 100% !important;
  height: 920px;
  text-align: center;
`;

const IconSection = styled.div`
  padding-top: 350px;
`;
const MessageSection = styled.div`
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  padding-top: 12px;
`;

const RequestButton = styled(Button)`
  margin-top: 40px;
  width: 302px;
  height: 56px;
  border-radius: 3px;
  background-color: ${PALETTE['color-emerald']} !important;
  background-image: none !important;

  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: ${PALETTE['color-blue']};
  }
`;

const ASection = styled.a`
  display: block;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
`;
const LinkSection = styled(ASection)`
  margin-top: 96px;
  color: ${PALETTE['color-emerald']};
  :hover {
    color: ${PALETTE['color-emerald']};
  }
`;
const MailToSection = styled(ASection)`
  margin-top: 17px;
  color: #ffffff;
  :hover {
    color: #ffffff;
  }
`;

// @observer
class NoProjectPage extends Component {
  render() {
    return (
      <Wrapper>
        <IconSection></IconSection>

        <MessageSection>404</MessageSection>

        {/*<RequestButton text={'프로젝트 의뢰하기'} />*/}
      </Wrapper>
    );
  }
}

export default NoProjectPage;

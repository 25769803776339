import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import NotiSVG from '../../../static/icons/icon-noti-6.svg';

const MenuWrapper = styled.div`
  display: flex;
  height: 30px;
  line-height: 30px;
  a {
    position: relative;
  }
  a + a {
    margin-left: 40px;
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    height: 360px;
    a + a {
      margin-left: 0px;
    }
    a > div {
      padding-left: 28px;
    }
  }
`;

const MobileMenuItemWrapper = styled.ul``;

const MenuItemWrapper = styled.div<{
  isActive?: boolean;
  isAlert?: boolean;
}>`
  line-height: 30px;
  font-size: 15px;
  font-weight: ${p => (p.isActive ? 'bold' : 500)};
  border-bottom: ${p =>
    p.isActive ? `2px solid  ${PALETTE['purpleish-blue']}` : 'none'};
  color: ${p => (p.isActive ? PALETTE['purpleish-blue'] : '#000000')};
  a {
    color: inherit;
  }

  :after {
    ${p =>
      p.isAlert &&
      `
        content: '';
        background: url('${NotiSVG}') no-repeat center center;
        width: 6px;
        height: 6px;
        position: absolute;
        top: 5px;
        color: ${PALETTE['color-blue']};
      `};
  }

  @media only screen and (max-width: ${PC}) {
    // text-decoration: none;
    font-size: 18px;
    height: 60px;
    line-height: 60px;
    border-bottom: none;

    :after {
      ${p =>
        p.isAlert &&
        `
          top: 16px;
        `};
    }
  }
`;

const NavMenu = styled.nav`
// 여기는 모바일용만 존재 
  @media only screen and (max-width: ${PC}) {
    float: none;
    margin-top: 40px;
    .divider {
      margin: 10px 0 39px 30px;
      width: 181px;
      border-top: solid 1px #5834ff;
      height: 1px;
    }
    ul {
      padding: 0;
      margin: 0;
      // border-bottom: 1px solid #5834ff;
      padding-bottom: 40px;
      li {
        display: block;
        padding: 0;
        padding-left: 30px;
        a {
          display: block;
          padding: 15px 0 15px;
          font-size: 18px;
          font-weight: 500;
          color: #5834ff;
        }
        &.actv {
          background: #5834ff;
          // max-width: 100vw;
          // margin-left: calc( -100vw / 2 + 100% / 2);
          // margin-right: calc( -100vw / 2 + 100% / 2);
          a {
            color: #FFF;
            // padding-left: 28vw;
          }
        }
      }
      
    }
  }
  > a {
    position: static;
    display: block;
    margin: 30px 0 0 30px;
    
  }
  button {
    border: none;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 7px;
      width: 100%;
      height: 2px;
      background: #4b34ff;
      transform: rotate(45deg);
    }
    &:before {
      background: #4b34ff;
      transform: rotate(-45deg);
    }
  }
}
`;

const MenuItem: React.FC<{
  title: string;
  path: string;
  isAlert?: boolean;
  current: string;
  handleClick?: () => void;
}> = props => {
  return (
    <NavLink to={`/${props.path}`}>
      <MenuItemWrapper
        isAlert={props.isAlert}
        isActive={props.current === props.path ? true : false}
      >
        {props.title}
      </MenuItemWrapper>
    </NavLink>
  );
};

const MobileMenuItem: React.FC<{
  title: string;
  path: string;
  current: string;
  handleClose: () => void;
}> = props => {
  const history = useHistory();

  const handleMenuItem = (url: string) => {
    props.handleClose();
    history.push(`/${url}`);
  };
  return (
    <li className={props.current === props.path ? 'actv' : ''}>
      <a
        onClick={() => {
          handleMenuItem(props.path);
        }}
      >
        {props.title}
      </a>
    </li>
  );
};

const Menu: React.FC<{
  current: string;
  handleClick?: () => void;
  hasNewInspection: boolean;
  hasNewReception: boolean;
  hasNewDashboard: boolean;
  hasNewCalculate: boolean;
  notiProjectSummaries: any;
}> = props => {
  return (
    <MenuWrapper onClick={props.handleClick}>
      <MenuItem
        path="receptions"
        title="프로젝트 의뢰"
        isAlert={props.hasNewReception}
        current={props.current}
      />
      {props.notiProjectSummaries.inspectionsCount > 0 && (
        <MenuItem
          path="inspection"
          title="플랜"
          isAlert={props.hasNewInspection}
          current={props.current}
        />
      )}
      {props.notiProjectSummaries.pGCount > 0 && (
        <MenuItem
          path="dashboard"
          title="대시보드"
          isAlert={props.hasNewDashboard}
          current={props.current}
        />
      )}
      {props.notiProjectSummaries.pGFinishedSprintsCount > 0 && (
        <MenuItem
          path="calculate"
          title="정산"
          isAlert={props.hasNewCalculate}
          current={props.current}
        />
      )}
      <MenuItem path="mypage" title="마이페이지" current={props.current} />
    </MenuWrapper>
  );
};

const NotLoginMenu: React.FC<{
  current: string;
  handleClose: () => void;
}> = props => {
  return (
    <>
      <NavMenu id="snMenu">
        <MobileMenuItemWrapper>
          {/*<MobileMenuItem*/}
          {/*  {...props}*/}
          {/*  path="pricing"*/}
          {/*  title="Pricing"*/}
          {/*  current={props.current} />*/}
          <MobileMenuItem
            {...props}
            path="partners"
            title="Partners"
            current={props.current}
          />
        </MobileMenuItemWrapper>

        <div className="divider" />
      </NavMenu>
    </>
  );
};

export { Menu, NotLoginMenu };

import axios from 'axios';
import { IDepositResponse } from './models/Deposit';
import { IPayoutResponse } from './models/Payout';

export const getDeposits = async () => {
  const { data }: { data: IDepositResponse[] } = await axios.get(
    `/calculate/client`,
  );

  return data;
};

export const getPayout = async () => {
  const { data }: { data: IPayoutResponse[] } = await axios.get(
    `/calculate/rufree`,
  );

  return data;
};

import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { flow, types } from 'mobx-state-tree';
// import { AppStore } from "./AppStore";
import { AuthToken, LoginResponse } from '../types/auth';

const AUTH_TOKEN_KEY = 'authtoken';

export const UserStoreModel = types
  .model('UserStore', {
    isLoggedIn: types.optional(types.boolean, false),
    userId: types.optional(types.number, -1),
    username: types.optional(types.string, ''),
    email: types.optional(types.string, ''),
    isPM: types.optional(types.boolean, false),
    isRufree: types.optional(types.boolean, false),
    isClient: types.optional(types.boolean, false),
    clientId: types.optional(types.string, ''),
    clientName: types.optional(types.string, ''),

    // TODO: uiStore로 분리 필요.
    isCpmsTheme: types.optional(types.boolean, false), // 현재 해당 유저가 CPMS 테마인지 아닌지 확인하는 용도
    showLoginModal: types.optional(types.boolean, false),
  })
  .views(self => ({
    get authToken() {
      return window.localStorage.getItem(AUTH_TOKEN_KEY);
    },
  }))
  .actions(self => ({
    setUserInfo({
      userId,
      username,
      email,
      isPM,
      isClient,
      isRufree,
      clientId,
      clientName,
    }: {
      userId: number;
      username: string;
      email: string;
      isPM: boolean;
      isRufree: boolean;
      isClient: boolean;
      clientId: string;
      clientName: string;
    }) {
      self.userId = userId;
      self.username = username;
      self.email = email;
      self.isPM = isPM;
      self.isClient = isClient;
      self.isRufree = isRufree;
      self.clientId = clientId;
      self.clientName = clientName;
    },

    setShowLoginModal(show: boolean) {
      self.showLoginModal = show;
    },
  }))
  .actions(self => {
    const updateCurrentThemeStatus = (val: boolean) => {
      // 테마상태 업데이트
      self.isCpmsTheme = val;
    };

    const checkLoginValidPage = (url: string) => {
      // check

      // 해당 페이지를 제외하고 모두 로그인이 필요한 페이지
      // 이 곳 제외한 페이지는 모두 로그인할 수 있도록 리다이렉트
      // - parameter: 첫글자 "/" 를 제외하고, route 혹은 url 을 그대로 넣어주면 됨.

      const noNeedLoginUrlList = [
        // 로그인 안해도 볼 수 있는 페이지
        // 기준정리해서 올리기
        '',
        'signup',
        'partners',
        'pricing',
        'terms/agreement',
        'terms/privatePolicy',
        'errorpage',
      ];
      const noNeedLogin = noNeedLoginUrlList.includes(url);
      return noNeedLogin;
    };

    const checkTokenIsValid = flow(function* () {
      // try {
      //   const { data }: { data: LoginResponse } = yield axios.post(
      //     "/auth/users/signup",
      //     signUpdata
      //   );
      //   window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
      //   axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
      //   checkLoginStatus();
      // } catch (e) {
      //   console.log("login error", e);
      //   throw e;
      // }
    });

    const checkLoginStatus = () => {
      if (self.authToken) {
        self.isLoggedIn = true;
        const decoded = jwtDecode(self.authToken) as AuthToken;

        self.setUserInfo({
          userId: decoded.user_id,
          username: decoded.name,
          email: decoded.username,
          isPM: decoded.is_pm,
          isRufree: decoded.is_rufree,
          isClient: decoded.is_client,
          clientId: decoded.client_id,
          clientName: decoded.client_name,
        });
      }
    };
    const signUp = flow(function* (signUpdata: any) {
      try {
        const { data }: { data: LoginResponse } = yield axios.post(
          '/auth/users/signup',
          signUpdata,
        );

        window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

        checkLoginStatus();
      } catch (e) {
        console.log('login error', e);
        throw e;
      }
    });

    const addFcmToken = flow(function* (fcm_token: string) {
      try {
        const { status } = yield axios.post('/auth/users/addFcmToken', {
          fcm_token,
        });

        // window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        // axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;

        // checkLoginStatus();
      } catch (e) {
        console.log('login error', e);
        throw e;
      }
    });

    const login = flow(function* (email: string, password: string) {
      try {
        const { data }: { data: LoginResponse } = yield axios.post(
          '/auth/login',
          {
            email,
            password,
          },
        );

        window.localStorage.setItem(AUTH_TOKEN_KEY, data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;

        checkLoginStatus();
      } catch (e) {
        console.log('login error', e);
        throw e;
      }
    });

    const logout = () => {
      window.localStorage.removeItem(AUTH_TOKEN_KEY);
      axios.defaults.headers.common['Authorization'] = null;

      self.userId = -1;
      self.username = '';
      self.isPM = false;

      checkLoginStatus();
    };

    return {
      updateCurrentThemeStatus,
      checkLoginValidPage,
      checkLoginStatus,
      signUp,
      login,
      logout,
      addFcmToken,
    };
  });

type UserStoreModelType = typeof UserStoreModel.Type;
export interface UserStore extends UserStoreModelType {}

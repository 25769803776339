import { types } from 'mobx-state-tree';

// import { getSprintDaysRate } from "../../utils/getSprintDaysRate";

export interface SeesoServiceResponse {
  service: string;
  cost: number;
  individual: boolean;
  sprints: boolean[];
}

export const SeesoServiceModel = types
  .model('SeesoService', {
    service: types.string,
    cost: types.number,
    individual: types.boolean,
    sprints: types.array(types.boolean),
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.reduce((sum, sprint) => (sprint ? sum + 1 : sum), 0);
    },
  }))
  .views(self => ({
    get costWithTax() {
      return Math.floor(self.cost * 1.1);
    },

    get totalCost() {
      return self.totalSprintDays * self.cost;
    },
  }));
/*.views(self => ({
        costWithTaxBySprintDaysRate({
                                        sprintIndex,
                                        sprintDays
                                    }: {
            sprintIndex: number;
            sprintDays: number;
        }) {
            return getSprintDaysRate({
                sprintIndex,
                sprintDays,
                originalPrice: self.costWithTax
            });
        }
    }));*/

type SeesoServiceType = typeof SeesoServiceModel.Type;
export interface SeesoService extends SeesoServiceType {}

import { Dialog as bp3_Dialog } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { FC, useRef } from 'react';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';
import CrossSVG from '../../../static/icons/icon-cross-multi.svg';
import ModalCloseSVG from '../../../static/icons/mo-modalclose.svg';
import { Inspection as Comps } from '../../molecules/Inspection';
import Button from '../MyPage/Button';
import { FileToUploadForm } from './FileToUploadForm';
import NewReferenceBar from './NewReferenceBar';

const AttachmentModal = styled(bp3_Dialog)`
  position: relative;
  width: 785px;
  min-height: 358px;
  padding: 0px 0px 0px;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;

  @media only screen and (max-width: ${PC}) {
    width: 328px;
    min-height: 304px;
  }

  div.bp3-dialog-header {
    padding-left: 40px;
    height: 83px;
    background: ${PALETTE['color-emerald']};
    box-shadow: none;
    border-radius: 0;
    padding-top: 25px;
    padding-bottom: 25px;
    @media only screen and (max-width: ${PC}) {
      padding: 20px;
      height: 67px;
    }
  }
  h4.bp3-heading {
    height: 33px;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${PALETTE['black']};
    margin-bottom: 0px;
    @media only screen and (max-width: ${PC}) {
      height: 27px;
      font-size: 18px;
      margin-bottom: 2px;
    }
  }
  div.bp3-dialog-header button {
    display: none;
  }

  div.dialog-body {
    padding: 24px 40px 102px 40px;

    @media only screen and (max-width: ${PC}) {
      padding: 25px 20px 102px 20px;
    }
  }
  ul {
    margin: 0;
    padding: 24px 0px 0px 0px;
    @media only screen and (max-width: ${PC}) {
      padding: 0px;
    }
  }
  li {
    margin: 0;
    padding: 0;
    list-style: none outside;
    position: relative;
    // padding-top: 10px;
  }
  /*input {
    // margin-left: 50px;
    padding-left: 15px;
    
    height: 32px;
    border: solid 1px ${PALETTE['very-light-pink-three']};
    font-size: 14px;
    color: ${PALETTE['brown-grey']};
    // border-bottom: 1px solid rgba(0,0,0,.4);
  }*/
  input:focus {
    outline: none;
  }

  div.cancel {
    //   display: block;
    //   width: 150px;
    //   height: 50px;
    //   margin-top: 20px;
    //   background: #a2a2a2;
    //   color: #2b2b2b;
    //   font-size: 15px;
    //   text-align: center;
    //   line-height: 52px;
    //   radius: 100px;
    //   font-weight: 800;
  }
`;

const AdditionalMsg = styled.div`
  position: relative;
  top: -22px;
  left: 510px;

  width: 242px;
  height: 18px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #002fd8;

  @media only screen and (max-width: ${PC}) {
    position: relative;
    top: -22px;
    left: 20px;

    width: 201px;
    height: 15px;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #002fd8;
  }
`;

const CancelButton = styled.div`
  width: 144px;
  height: 48px;
  border-radius: 100px;
  background-color: #a2a2a2;

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  text-align: center;
  line-height: 48px;
  // letter-spacing: normal;

  margin-right: 15px;
`;

const DoneButton = styled.div`
  width: 144px;
  height: 48px;
  border-radius: 100px;
  background-color: ${PALETTE['purpleish-blue']};

  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  text-align: center;
  line-height: 48px;
  // letter-spacing: normal;
`;
const AttachButton = styled.div`
  width: 130px;
  height: 48px;
  opacity: 1;
  border-radius: 100px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;

  margin-right: 15px;
  // background-color: var(--button-style);

  // width: 74px;
  // height: 22px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 3;
  letter-spacing: normal;
  text-align: center;
  color: #1c2126;
`;
const CloseButton = styled.div<{
  count: number;
}>`
  background: url('${CrossSVG}') no-repeat center center;
  position: absolute;
  top: 5px;
  right: -49px;
  cursor: pointer;
  width: 29px;
  height: 29px;
  @media only screen and (max-width: ${PC}) {
    position: relative;
    background: url('${ModalCloseSVG}') no-repeat center center;
    width: 53px;
    height: 24px;
    top: 30px;
    right: -274px;
    z-index: 9999;
  }
`;

interface FileBar {
  file: File;
  title: string;
}

const NewAttachmentModal: FC<{
  attachmentList: FileToUploadForm[];
  isOpen: boolean;
  handleClose: () => void;
  handleAddNewLine: () => void;
  handleRemoveLine: (index: number) => void;
  handleChangeLine: () => void;
  submitAttachment: () => void;
  /*handleChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void,
    handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => void,
    handleLogin: () => void*/
}> = observer(props => {
  // @observable
  const ref = useRef(null);
  let height = 0;
  React.useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      height = ref.current.clientHeight;
    }
  }, []);
  return (
    <>
      <AttachmentModal
        isOpen={props.isOpen}
        // title="참고 자료 추가"
        onClose={props.handleClose}
        ref={ref}
      >
        <div className="bp3-dialog-header">
          {/*style={{display:"flex",flexDirection:"column"}}>*/}
          <h4 className="bp3-heading">참고 자료 추가</h4>
        </div>
        <AdditionalMsg>
          *업로드 가능한 파일의 용량은 최대 50MB입니다.
        </AdditionalMsg>
        <div
          className="dialog-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            minHeight: '200px',
            paddingTop: '0px',
            // marginTop: "-30px"
          }}
        >
          <div>
            <ul>
              {props.attachmentList.map((attach, index) => (
                <li>
                  <NewReferenceBar
                    index={index}
                    title={attach.title}
                    changeTitle={title => {
                      attach.setTitle(title);
                      // props.handleChangeLine();
                    }}
                    changeLine={() => {
                      props.handleChangeLine();
                    }}
                    isFile={attach.hasFile}
                    removeHandler={() => {
                      props.handleRemoveLine(index);
                      props.handleChangeLine();
                    }}
                    addNewHandler={() => {
                      props.handleAddNewLine();
                    }}
                    selectFileHandler={file => {
                      attach.setFile(file);
                      props.handleChangeLine();
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
          <Comps.NewReferenceButtonSection>
            <Button
              text="취소"
              theme=""
              small
              style={{
                width: '108px',
                height: '42px',
                borderRadius: '3px',
                border: `solid 1px ${PALETTE['very-light-pink-two']}`,
                verticalAlign: 'sub',
                backgroundColor: PALETTE['pale-grey'],
              }}
              textStyle={{
                width: '28px',
                // "height": "22px",
                // "backgroundColor": "#7c66de",
                // "fontFamily": "NotoSansCJKkr",
                fontSize: '15px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'center',
                color: '#7c66de',
              }}
              onClick={props.handleClose}
            />
            <div style={{ width: '15px' }} />
            <Button
              text="완료"
              theme="purple-white"
              small
              textStyle={{
                marginTop: '10px',
                width: '28px',
                height: '22px',
                fontSize: '15px',
                fontWeight: 'bold',
                fontStretch: 'normal',
                fontStyle: 'normal',
                lineHeight: 'normal',
                letterSpacing: 'normal',
                textAlign: 'center',
                color: PALETTE['white'],
              }}
              style={{
                width: '108px',
                height: '42px',
                borderRadius: '3px',
                verticalAlign: 'sub',
                boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.3)',
              }}
              // disabled={}
              onClick={props.submitAttachment}
            />
            {/*<CancelButton
                        onClick={props.handleClose}>
                        취소
                    </CancelButton>
                    <DoneButton
                        onClick={props.submitAttachment}>
                        완료
                    </DoneButton>*/}
          </Comps.NewReferenceButtonSection>
        </div>
        <CloseButton onClick={props.handleClose} count={height}>
          {/*<Icon icon={"cross"} iconSize={30} color={PALETTE['white']}/>*/}
          {/*<img src={CrossSVG} style={{width: "30px", height: "30px"}}/>*/}
        </CloseButton>
      </AttachmentModal>
    </>
  );
});

export default NewAttachmentModal;

import { Alignment, Switch as bp3_Switch } from '@blueprintjs/core';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import ViewArrowSVG from '../../../static/icons/icon-viewarrow-black.svg';

import { PageWrapper } from '../MainTemplate/MainTemplate';

const Wrapper = styled(PageWrapper)`
  min-height: 980px;
  margin-top: 58px;
  width: 1220px;
  // padding-left: 110px;

  & > div:first-child {
    margin-bottom: 70px;
  }

  @media only screen and (max-width: ${PC}) {
    margin-top: 35px;
    margin-left: 16px;
    width: calc(100% - 32px);
  }
`;

const DetailPopup = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  width: fit-content;

  ::after {
    content: '';
    background: url('${ViewArrowSVG}') no-repeat center center;
    width: 15px;
    height: 15px;
    position: absolute;
    margin-left: 10px;
    margin-top: 5px;
  }
`;

const Section = styled.div`
  .detail-popup {
    margin-top: 15px;
  }
`;
const SectionHeader = styled.div`
  line-height: 33px;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: solid 2px #000000;
  margin-bottom: 40px;
  display: flex;

  *:first-child {
    margin-right: 30px;
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    margin-bottom: 30px;
  }
`;

const VATCheckboxWrapper = styled.div`
  position: relative;
  min-width: 200px;
  line-height: 22px;
  input:focus {
    outline: none !important;
  }

  @media only screen and (max-width: ${PC}) {
    top: 71px;
  }
`;

const BP3SwitchWrapper = styled(bp3_Switch)`
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;

  position: absolute;
  bottom: 5px;
  left: 0;

  &.bp3-control.bp3-switch .bp3-control-indicator {
    border-radius: 12px;
    width: 38px;
    height: 20px;

    position: absolute;
    left: 152px;
  }
  &.bp3-control.bp3-switch .bp3-control-indicator::before {
    width: 16px;
    height: 16px;
  }
  &.bp3-control.bp3-switch input ~ .bp3-control-indicator {
    background: ${PALETTE['brownish-grey']};
  }
  &.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
    background: ${PALETTE['purpleish-blue']};
    border-radius: 12px;
    margin-left: 0;
  }
  &.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator::before {
    left: calc(50%);
  }
  &.bp3-control.bp3-switch.bp3-align-right {
    padding-right: 0px;
  }
  &.bp3-control.bp3-align-right .bp3-control-indicator {
    margin-left: 0;
  }
`;
const VATCheckbox: FC<{
  include: boolean;
  handleChange: () => void;
}> = ({ include, handleChange }) => {
  return (
    <VATCheckboxWrapper>
      <BP3SwitchWrapper
        alignIndicator={Alignment.RIGHT}
        label="부가세(VAT) 포함 보기"
        defaultChecked={include}
        onChange={handleChange}
      />
    </VATCheckboxWrapper>
  );
};

export { DetailPopup, Section, SectionHeader, VATCheckbox, Wrapper };

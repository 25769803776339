import { unionBy } from 'lodash';
import sortBy from 'lodash/sortBy';
import { types } from 'mobx-state-tree';
// import shortid from "shortid";
import moment from 'moment';

import { EProjectStatus } from '../constants';
import * as InspectionRufreeRole from './InspectionRufreeRole';
import * as ScheduleSprint from './ScheduleSprint';

import * as Sprint from './Sprint';

/*
 * Response interface
 */
export interface IScheduleResponse {
  created_at: string;
  date_end: string;
  date_start: string;
  updated_at: string;
  rufree_schedules: ScheduleSprint.IScheduleResponse[];
  sprints_count: {
    total_count: number;
  };
}

/*
 * mapper
 */
export const mapSchedule = (scheduleResp: IScheduleResponse) => {
  const dateEnd = moment(scheduleResp.date_end);
  const dateStart = moment(scheduleResp.date_start);

  const sprint_idx = scheduleResp.rufree_schedules.reduce(
    (acc: number[], cur) => {
      const idx = [];
      for (let i = 0; i < cur.sprints.length; i++) {
        if (!!cur.sprints[i]) idx.push(i);
      }

      return acc.concat(idx.filter(item => acc.indexOf(item) < 0));
      // return acc.concat(cur.sprintNumbers.filter((item) => acc.indexOf(item) < 0))
    },
    [],
  );

  const sprints = sprint_idx
    .filter(index => index > 0)
    .map(index =>
      Sprint.SprintModel.create({
        dateStart: null,
        dateEnd: null,
        rufrees: [],
        sprint: index,
      }),
    );

  return {
    createdAt: moment(scheduleResp.created_at).toDate(),
    dateEnd: dateEnd.isValid() ? dateEnd.toDate() : null,
    dateStart: dateStart.isValid() ? dateStart.toDate() : null,
    rufrees: scheduleResp.rufree_schedules.map(rs =>
      ScheduleSprint.mapSchedule(rs),
    ),
    sprints: sprints,
    totalSprintCount: scheduleResp.sprints_count.total_count,
    updatedAt: moment(scheduleResp.updated_at).toDate(),
  };
};

/*
 * Model
 */
export const ScheduleModel = types
  .model('Schedule', {
    createdAt: types.Date,
    dateEnd: types.maybeNull(types.Date),
    dateStart: types.maybeNull(types.Date),
    rufrees: types.array(ScheduleSprint.ScheduleSprintModel),
    sprints: types.array(Sprint.SprintModel),
    // totalSprintCount: types.number,
    updatedAt: types.Date,
  })
  .views(self => ({
    get sprintIndexies() {
      const numbers: number[] = [];
      return self.rufrees.reduce(
        (acc, cur) =>
          acc.concat(cur.sprintNumbers.filter(item => acc.indexOf(item) < 0)),
        numbers,
      );
    },
    get rufreeRoles() {
      return self.rufrees.map(rufree => rufree.role);
    },
  }))
  .views(self => ({
    get firstSprint() {
      const sortedSprint = sortBy(self.sprints, sprint => sprint.sprint);

      if (sortedSprint) {
        return sortedSprint[0];
      } else {
        return null;
      }
    },
    get ongoingSprints() {
      return sortBy(
        self.sprints.filter(
          s =>
            s.status === EProjectStatus.ONGOING ||
            s.status === EProjectStatus.HOLDING,
        ),
        sprint => sprint.sprint,
      );
    },
    get doneSprints() {
      return sortBy(
        self.sprints.filter(s => s.status === EProjectStatus.END),
        sprint => sprint.sprint,
      );
    },
    get scheduledSprints() {
      return sortBy(
        self.sprints.filter(s => s.status === EProjectStatus.SCHEDULE),
        sprint => sprint.sprint,
      );
    },
    get inspectionSprintsCount() {
      return 0;
    },
  }))
  .actions(self => ({
    initSprintRufreesByInspection(
      roles: InspectionRufreeRole.IInspectionRufreeRole[],
    ) {
      // 스프린트 정보 초기화
      const sprint_idx = roles.reduce(
        (acc: number[], cur: InspectionRufreeRole.IInspectionRufreeRole) => {
          const idx = [];
          for (let i = 0; i < cur.sprints.length; i++) {
            if (!!cur.sprints[i]) idx.push(i);
          }

          return acc.concat(idx.filter(item => acc.indexOf(item) < 0));
        },
        [],
      );

      const sprints = sprint_idx
        .filter(index => index > 0)
        .map(index =>
          Sprint.SprintModel.create({
            dateStart: null,
            dateEnd: null,
            rufrees: [],
            sprint: index,
          }),
        );

      self.sprints.replace(sprints);

      // 알유프리 정보 초기화.
      self.sprints.forEach(sprint => {
        const rufrees: Sprint.IRufreeOfRole[] = [];
        roles.forEach(role => {
          if (role.sprints[sprint.sprint]) {
            //기존에 가져온 /schedule 응답값에서 알유프리 정보 초기화.
            const rufreeFromSchedule = self.rufrees.find(
              rufree => rufree.role === role.role,
            );

            rufrees.push(
              Sprint.RufreeOfRoleModel.create({
                role: role.role,
                rufreeId: rufreeFromSchedule ? rufreeFromSchedule.rufreeId : '',
                rufreeName: rufreeFromSchedule
                  ? rufreeFromSchedule.rufreeName
                  : '',
                status: '',
              }),
            );
          }
        });

        sprint.rufrees.replace(rufrees);
      });
    },
    updateSprint(sprint: Sprint.ISprint) {
      const finded = self.sprints.find(s => s.sprint === sprint.sprint);
      if (finded) {
        // finded.setDateEnd(sprint.dateEnd);
        finded.setDateStart(sprint.dateStart);

        let dateEnds: moment.Moment[] = [];
        if (sprint.rufrees.every(rufree => !!rufree.dateEnd)) {
          // 스프린트 내, 모든 알유프리 역할이 종료되었으면,  dateEnd 중 가장 나중 날짜로 설정.
          dateEnds = sprint.rufrees.map(rufree => moment(rufree.dateEnd));
        } else {
          // 그렇지 않으면, dateExpectedEnd 중 가장 나중 날짜로 설정.
          dateEnds = sprint.rufrees
            .filter(rufree => rufree.dateExpectedEnd)
            .map(rufree => moment(rufree.dateExpectedEnd));
        }
        finded.setDateEnd(moment.max(dateEnds).toDate());

        sprint.rufrees.map(rufree => {
          const fed = finded.rufrees.find(r => r.role === rufree.role);
          fed?.setDateEnd(rufree.dateEnd);
          fed?.setDateExpectedEnd(rufree.dateExpectedEnd);
          fed?.setDatePayout(rufree.datePayout);
          fed?.setDateStart(rufree.dateStart);
          fed?.setRufreeId(rufree.rufreeId);
          fed?.setRufreeName(rufree.rufreeName);
          fed?.setStatus(rufree.status);
        });
      }
    },
    updateRufreeOfSprint(sprints: Sprint.ISprint[]) {
      let rufreeByRole: Sprint.IRufreeOfRole[] = [];

      sprints.map(sprint =>
        sprint.rufrees.map(rufree => {
          rufreeByRole.push(rufree);
        }),
      );

      rufreeByRole = unionBy(rufreeByRole, 'role');
      self.sprints.map(sprint => {
        sprint.rufrees.map(rufree => {
          const finded = rufreeByRole.find(r => r.role === rufree.role);
          if (finded) {
            rufree.setRufreeName(finded.rufreeName);
          }
        });
      });
    },
  }));

type ScheduleType = typeof ScheduleModel.Type;
export interface ISchedule extends ScheduleType {}

import React, { Component } from 'react';
import { Partners as _Comps } from '../../organisms/Etc';
import { Home as Comps } from '../../organisms/Home';

// @observer

interface Props {}

interface HomeState {
  windowWidth: number;
  slideEvent: any;
  activeElementCount: number;
  liCount: number;
  projects: string[];
}

class PartnersPage extends Component<Props> {
  private slidesUlRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.windowResizeEvent = this.windowResizeEvent.bind(this);
    this.slideIntervalEvent = this.slideIntervalEvent.bind(this);
    this.slideReactionEvent = this.slideReactionEvent.bind(this);
    this.slideMoveEvent = this.slideMoveEvent.bind(this);
    this.slidesUlRef = React.createRef();
  }

  state: HomeState = {
    windowWidth: 100,
    slideEvent: {},
    activeElementCount: 0,
    liCount: 5,
    projects: [],
  };

  componentDidMount() {
    window.addEventListener('resize', this.windowResizeEvent);
    this.setState({
      slideEvent: setInterval(this.slideIntervalEvent, 3000),
    });
    this.windowResizeEvent();
  }

  windowResizeEvent() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    this.slideMoveEvent('RESIZE');
  }

  slideIntervalEvent = () => {
    let activeElementCount = this.state.activeElementCount;
    activeElementCount =
      activeElementCount + 1 < this.state.liCount ? activeElementCount + 1 : 0;
    this.setState({
      activeElementCount: activeElementCount,
    });
  };

  slideReactionEvent = () => {
    if (this.slidesUlRef.current) {
      this.slidesUlRef.current.style.transform = `translateZ(0px) translateX(-${
        this.state.windowWidth * this.state.activeElementCount
      }px)`;
    }
  };

  slideMoveEvent = (SLIDE_MOVE: string) => {
    clearInterval(this.state.slideEvent);
    let activeElementCount = this.state.activeElementCount;
    if (SLIDE_MOVE === 'RIGHT' && activeElementCount + 1 < this.state.liCount) {
      activeElementCount += 1;
    } else if (
      SLIDE_MOVE === 'RIGHT' &&
      activeElementCount + 1 == this.state.liCount
    ) {
      activeElementCount = 0;
    } else if (SLIDE_MOVE === 'LEFT' && activeElementCount > 0) {
      activeElementCount -= 1;
    } else if (SLIDE_MOVE === 'LEFT' && activeElementCount == 0) {
      activeElementCount = this.state.liCount - 1;
    } else if (SLIDE_MOVE === 'RESIZE') {
      this.slideReactionEvent();
    }
    this.setState(
      {
        activeElementCount: activeElementCount,
      },
      () => {
        if (this.slidesUlRef.current) {
          // this.slidesUlRef.current.style.display = `none`
          this.slidesUlRef.current.style.transform = `translateZ(0px) translateX(-${
            this.state.windowWidth * this.state.activeElementCount
          }px)`;
        }
      },
    );
  };

  render() {
    return (
      <>
        <_Comps.PartnersWrapper>
          <_Comps.DataWrap>
            <div className="inner">
              <p>
                시소와의 협업을 통해 <br />
                조직의 <em>IT 역량을 고도화</em>하고 <br />
                <em>혁신적으로 활용</em>할 수 있습니다.
              </p>
              <div>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <i>누적 파트너사</i>
                    <strong>
                      410<span>개</span>
                    </strong>
                  </li>
                  <li>
                    <i>평균 이용 기간</i>
                    <strong>
                      14<span>주</span>
                    </strong>
                  </li>
                  <li>
                    <i>평균 멤버수</i>
                    <strong>
                      4.2<span>명</span>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </_Comps.DataWrap>
          {/* <!-- <table>
                    <caption>partner data</caption>
                    <thead>
                        <tr>
                            <th scope="col">No</th>
                            <th scope="col">파트너사</th>
                            <th scope="col">구분</th>
                            <th scope="col">누적 이용 기간</th>
                            <th scope="col">협업 분야</th>
                            <th scope="col">누적 팀 멤버</th>
                            <th scope="col">평균 만족도</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>231</td>
                            <td>프릭엔</td>
                            <td>기업</td>
                            <td>120주</td>
                            <td>기획, 디자인, 개발</td>
                            <td>19명</td>
                            <td>8.5</td>
                        </tr>
                        <tr>
                            <td>231</td>
                            <td>프릭엔</td>
                            <td>기업</td>
                            <td>120주</td>
                            <td>기획, 디자인, 개발</td>
                            <td>19명</td>
                            <td>8.5</td>
                        </tr>
                        <tr>
                            <td>231</td>
                            <td>프릭엔</td>
                            <td>기업</td>
                            <td>120주</td>
                            <td>기획, 디자인, 개발</td>
                            <td>19명</td>
                            <td>8.5</td>
                        </tr>
                        <tr>
                            <td>231</td>
                            <td>프릭엔</td>
                            <td>기업</td>
                            <td>120주</td>
                            <td>기획, 디자인, 개발</td>
                            <td>19명</td>
                            <td>8.5</td>
                        </tr>
                        <tr>
                            <td>231</td>
                            <td>프릭엔</td>
                            <td>기업</td>
                            <td>120주</td>
                            <td>기획, 디자인, 개발</td>
                            <td>19명</td>
                            <td>8.5</td>
                        </tr>
                    </tbody>
                </table> --> */}
          <Comps.clientWrapper>
            <h3>
              <span>리모트 팀과의 협업사례</span>
            </h3>
            <div
              ref={this.slidesUlRef}
              style={{
                width: this.state.windowWidth * this.state.liCount,
                transitionDuration: '.5s',
              }}
              className="inner"
            >
              <div className="clInfo" style={{ width: this.state.windowWidth }}>
                <strong>단골공장</strong>
                <p>
                  "창업과 동시에, 같은 에스오피오오엔지 투자사인 시소와 함께
                  디자인, 개발 프로젝트를 진행했습니다. 기획은 자체적으로 하며
                  디자이너, 백엔드 및 프론트 개발자와 한 팀이 되어 협업을 하면서
                  IT프로젝트에 대해서 단순한 외주로 홈페이지를 받아보는 것이
                  아니라, 홈페이지 구축과정에서의 개발자 디자이너와의 협업
                  프로세스도 익힐 수 있었습니다. 최근에도 개발자 채용전까지
                  지속적으로 프로젝트 협업을 해왔습니다."
                </p>
                <div className="img">
                  <img
                    src={require('../../../static/images/client_site-1.jpg')}
                    alt=""
                  />
                </div>
              </div>
              <div className="clInfo" style={{ width: this.state.windowWidth }}>
                <strong>마디</strong>
                <p>
                  "마디(mardi) 프로젝트는 초기 아이디어 단계부터 시소와 함께
                  했습니다. 기획자분과 논의를 하면서 생각을 가다듬는데 도움을
                  받았습니다. 이 후 서비스를 구체화 하며 디자인과 개발팀 모두
                  만족스럽게 진행을 했습니다. 시소를 통해 인하우스 IT팀에 준하는
                  협업을 하면서 함께 성장하고 있습니다."
                </p>
                <div className="img">
                  <img
                    src={require('../../../static/images/client_site-2.jpg')}
                    alt=""
                  />
                </div>
              </div>
              <div className="clInfo" style={{ width: this.state.windowWidth }}>
                <strong>프릭엔(아프리카TV)</strong>
                <p>
                  "시소와 함께 많은 신사업 프로젝트를 진행하며, 빠르고
                  효율적으로 일을 할 수 있었습니다. 내부의 개발팀이나 채용을
                  통해서 이뤄지는 과정에 비해 2~3배 빠르게 진행할 수 있었고,
                  새로운 시도들을 해나갈 수 있었습니다."
                </p>
                <div className="img">
                  <img
                    src={require('../../../static/images/client_site-3.jpg')}
                    alt=""
                  />
                </div>
              </div>
              <div className="clInfo" style={{ width: this.state.windowWidth }}>
                <strong>스페이스클라우드</strong>
                <p>
                  "스페이스 클라우드는 시소를 통해서 내부 개발팀이 있더라도 쉽지
                  않은 대규모 이관 프로젝트를 협업하였습니다. 내부의 개발팀이
                  없지만 시소와 함께 어려운 개발적 비즈니스적 과제를 같이
                  해결해나가는 진정한 파트너사의 역할을 하고 있습니다."
                </p>
                <div className="img">
                  <img
                    src={require('../../../static/images/client_site-4.jpg')}
                    alt=""
                  />
                </div>
              </div>
              <div className="clInfo" style={{ width: this.state.windowWidth }}>
                <strong>어썸스쿨</strong>
                <p>
                  "어썸스쿨 서비스의 온라인화의 초석을 함께 만들었습니다. 시소의
                  초창기부터 함께 기획, 디자인, 개발을 해왔고 지금까지 여러가지
                  협업 서비스들을 함께 만들었습니다. 지금은 내부 개발팀의
                  필요성을 느끼면서 채용과 팀 세팅 과정까지 함께 고민하고
                  실행해주는 시소와 지속적인 협업을 하고 있습니다."
                </p>
                <div className="img">
                  <img
                    src={require('../../../static/images/client_site-5.jpg')}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="controller">
              <button
                type="button"
                className="prev"
                onClick={() => this.slideMoveEvent('LEFT')}
              >
                <span>이전 고객</span>
              </button>
              <button
                type="button"
                className="next"
                onClick={() => this.slideMoveEvent('RIGHT')}
              >
                <span>다음 고객</span>
              </button>
              <div className="num">{this.state.activeElementCount + 1}/5</div>
            </div>
          </Comps.clientWrapper>
          <_Comps.specialR>
            <div className="inner">
              <h3>시소와의 협업이 특별한 이유</h3>
              <ul className="cpList">
                <li>빠르게 신규 서비스를 런칭이 가능합니다.</li>
                <li>다양한 분야의 전문가 자문이 가능합니다.</li>
                <li>실무자 커뮤니티를 통해 이직 헤드헌팅 기회를 제공합니다.</li>
                <li>
                  내부의 팀과 시소의 팀의 협업을 통해 함께 성장할 수 있습니다.
                </li>
                <li>프로젝트 수행후 채용의 연계가 가능합니다.</li>
              </ul>
            </div>
          </_Comps.specialR>
          <_Comps.hopeBnr>
            <div className="inner">
              <p>
                시소는 다양한 조직 파트너들과의
                <br />
                비즈니스 제휴 연계를 희망하고 있습니다.
              </p>
              <a href="mailto:partner@seeso.kr">제휴 및 제안하기</a>
            </div>
          </_Comps.hopeBnr>
        </_Comps.PartnersWrapper>
      </>
    );
  }
}

export default PartnersPage;

import { Drawer as bp3_Drawer } from '@blueprintjs/core';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';
import CloseSVG from '../../../static/icons/icon-close.svg';
import HanbugerSVG from '../../../static/icons/icon-hamburger.svg';

import SwitchSVG from '../../../static/icons/icon-switch.svg';
import ActvSwitchSVG from '../../../static/icons/icon-swith-cyan.svg';
import MobileLogoIcon from '../../../static/icons/logo-seesoP-MO.svg';

const MobileLogo = styled.div`
  background: url('${MobileLogoIcon}') no-repeat center center;
  width: 133px;
  height: 12px;
  position: absolute;
  top: 24px;
  left: 16px;
  background-size: 100% 100%;
`;

const MobileNotLoginLogoWrap = styled.h1`
  float: left;
  margin-bottom: 0px;
  display: inline-block;
  margin-top: -2px;
  position: absolute;
  top: 25px;
  left: 15px;
  a {
    display: block;
    width: 65px;
    height: 12px;
    background: url('https://seeso-cpms.s3.ap-northeast-1.amazonaws.com/uploads/logo.png')
      no-repeat 0 0;
    background-size: 158px 12px;
    span {
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
      font-size: 1px;
      line-height: 0;
      text-indent: -9999px;
      display: block;
    }
  }
`;

const MobileMenuIcon = styled.button`
  // background: url('${HanbugerSVG}') no-repeat center center;
  // width: 30px;
  // height: 20px;
  // position: absolute;
  // right: 16px;
  // top: 20px;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 20px;
  right: 15px;
  width: 30px;
  height: 20px;
  border-width: 2px 0;
  border-style: solid;
  border-color: #4b34ff;

  box-sizing: border-box;
  background: none;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    right: 0px;
    width: 100%;
    height: 2px;
    background: #4b34ff;
  }
  span {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
    display: block;
  }
`;

const MobileMenu = styled.div`
  width: 100%;
  height: 60px;
  &.dtype2 {
    background: #5834ff;
    h1 {
      a {
        background-position: 100% 0;
      }
    }
    button {
      border-color: #fff;
      &:before {
        background: #fff;
      }
    }
  }
  @media only screen and (min-width: ${PC}) {
    display: none;
  }
`;

const LoginedBody = styled.div`
  div.info {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    padding-left: 28px;
  }

  div.divider {
    margin: 10px 0 63px 30px;
    width: 181px;
    border-top: solid 1px #000000;
  }
`;

const MainMenu = styled.div`
  margin-top: 24px;
  padding-left: 0px;
  div > div {
    padding-left: 28px;
  }
`;

const ModeMenu = styled.div`
  font-size: 15px;
  font-weight: 500;

  &:after {
    content: '';
    background: url('${SwitchSVG}') no-repeat center center;
    width: 18px;
    height: 16px;
    color: ${PALETTE['color-blue']};
    position: relative;
    top: 3px;
    left: 4px;
    display: inline-block;
  }
`;

const SubMenu = styled.div`
  margin-bottom: 32px;
  div & div {
    margin-top: 32px;
  }
  div {
    text-align: center;
    cursor: pointer;
  }
  a {
    color: #000;
    font-weight: normal;
  }
  &.default {
    ${ModeMenu} {
      color: #00c9a4;
      &:after {
        background: url('${ActvSwitchSVG}') no-repeat center center;
      }
    }
    div {
      color: ${PALETTE['color-blue']};
      &:nth-child(2) {
        font-weight: 500;
      }
    }
    a {
      font-weight: 500;
      color: ${PALETTE['color-blue']};
    }
  }
`;

const BP3MobileMenuBody = styled(bp3_Drawer)`
  width: 248px !important;

  div.bp3-drawer-header {
    box-shadow: none;
    height: 57px;

    button > span {
      display: none;
    }
    button:hover {
      background: none;
    }
    button:after {
      content: '';
      background: url('${CloseSVG}') no-repeat center center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 16px;
      display: inline-block;
    }
  }
  div.bp3-drawer-body {
  }
`;

export {
  BP3MobileMenuBody,
  MobileLogo,
  MobileMenuIcon,
  LoginedBody,
  MobileMenu,
  MobileNotLoginLogoWrap,
  MainMenu,
  SubMenu,
  ModeMenu,
};

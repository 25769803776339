import moment from 'moment';
import React, { FC, useState } from 'react';

import { ETypeOptions } from '../../../store/Calculate/constants';
import { ICalculate } from '../../../store/Calculate/models/Calculate';

import { DetailDialog as Comps } from '../../molecules/Calculate';

const VAT = 1.1;

interface Props {
  className?: string;
  client: string;
  isOpen: boolean;
  isAsc: boolean;
  baseDate: Date | null;
  calculates: ICalculate[];
  balance: number;
  total: number;
  shortage: number;
  depositSum: number;
  payoutSum: number;
  handleClose: () => void;
  handleReverse: () => void;
}

const DetailDialog: FC<Props> = props => {
  const depositSum =
    Math.round(
      (Math.floor(Math.round(props.depositSum * VAT) / 10) * 10) / 100,
    ) * 100;
  const payoutSum =
    Math.round(
      (Math.floor(Math.round(props.payoutSum * VAT) / 10) * 10) / 100,
    ) * 100;
  const balance =
    Math.round((Math.floor(Math.round(props.balance * VAT) / 10) * 10) / 100) *
    100;
  const shortage =
    Math.round((Math.floor(Math.round(props.shortage * VAT) / 10) * 10) / 100) *
    100;

  const [filterType, setFilterType] = useState(ETypeOptions.ALL);

  const calculated =
    filterType === ETypeOptions.ALL
      ? props.calculates
      : props.calculates.filter(calculate => calculate.type === filterType);

  return (
    <Comps.BP3DetailDialog isOpen={props.isOpen} onClose={props.handleClose}>
      <Comps.DialogHeader>
        <div>{props.client}</div>
        <div>정산 내역</div>
      </Comps.DialogHeader>

      <Comps.DialogBody>
        <Comps.TypeMenu
          selected={filterType}
          handleChange={(type: ETypeOptions) => setFilterType(type)}
        />

        <Comps.DateInfoRow>
          <span>{moment(props.baseDate).format('YYYY.MM.DD')} 기준</span>
          <span>VAT 포함</span>
        </Comps.DateInfoRow>

        <Comps.RowWrapper>
          <Comps.HeaderRow isAsc={props.isAsc}>
            <Comps.HeaderColumn>항목</Comps.HeaderColumn>
            <Comps.HeaderColumn>
              <span onClick={props.handleReverse}>날짜</span>
            </Comps.HeaderColumn>
            <Comps.HeaderColumn>금액</Comps.HeaderColumn>
            <Comps.HeaderColumn>잔액</Comps.HeaderColumn>
          </Comps.HeaderRow>

          {calculated.map((calculate, i) => (
            <Comps.Row
              key={i}
              isDeposit={calculate.type === ETypeOptions.DEPOSIT ? true : false}
            >
              <Comps.Column>{calculate.title}</Comps.Column>
              <Comps.Column>
                {calculate.date && moment(calculate.date).format('YYYY.MM.DD')}
              </Comps.Column>
              <Comps.Column>
                <span>
                  {(
                    Math.floor(Math.round(calculate.amount * VAT) / 10) * 10
                  ).toLocaleString('en-US')}
                </span>
              </Comps.Column>
              <Comps.Column>
                <span>
                  {(
                    Math.floor(Math.round(calculate.balance * VAT) / 10) * 10
                  ).toLocaleString('en-US')}
                </span>
              </Comps.Column>
            </Comps.Row>
          ))}
        </Comps.RowWrapper>

        {filterType === ETypeOptions.ALL && (
          <Comps.AmountSumWrapper>
            <Comps.AmountSumRow>
              <div>입금합계</div>
              <div>{depositSum.toLocaleString('en-US')}</div>
            </Comps.AmountSumRow>
            <Comps.AmountSumRow>
              <div>지출합계</div>
              <div>{payoutSum.toLocaleString('en-US')}</div>
            </Comps.AmountSumRow>
            {props.balance > 0 ? (
              <Comps.RemainSumRow>
                <div>잔액</div>
                <div>{balance.toLocaleString('en-US')}</div>
              </Comps.RemainSumRow>
            ) : (
              <Comps.NeedDepositSumRow>
                <div>입금필요</div>
                <div>
                  <span>{Math.abs(shortage).toLocaleString('en-US')}</span>
                </div>
              </Comps.NeedDepositSumRow>
            )}
          </Comps.AmountSumWrapper>
        )}

        {filterType !== ETypeOptions.ALL && (
          <Comps.AmountSumWrapper>
            <Comps.AmountSumRow>
              <div>
                {`${filterType === ETypeOptions.DEPOSIT ? '입금' : '지출'}`}합계
              </div>
              <div>
                <span>
                  {(filterType === ETypeOptions.DEPOSIT
                    ? depositSum
                    : payoutSum
                  ).toLocaleString('en-US')}
                </span>
              </div>
            </Comps.AmountSumRow>
          </Comps.AmountSumWrapper>
        )}
      </Comps.DialogBody>

      <Comps.DialogFooter></Comps.DialogFooter>

      <Comps.CloseButton onClick={props.handleClose} />
    </Comps.BP3DetailDialog>
  );
};

export default DetailDialog;

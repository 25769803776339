import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import AmassOffSVG from '../../../static/icons/icon-amass-off.svg';

import AmassOnSVG from '../../../static/icons/icon-amass-on.svg';

export const SprintWrapper = styled.div`
  width: 686px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 15px;
`;

export const TotalSprint = styled.div`
  height: 54px;
  background-color: ${PALETTE['color-emerald']};
  line-height: 54px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  margin-bottom: 25px;

  .highlight {
    color: ${PALETTE['purpleish-blue']};
    padding-left: 10px;
  }
`;

export const SprintMenu = styled.div`
  display: flex;
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 20px;
  position: relative;

  .active {
    color: ${PALETTE['purpleish-blue']};
  }

  div + div::before {
    padding: 0px 12px;
    content: '|';
  }
`;

export const ReportViewButtonWrapper = styled.div<{
  isOn: boolean;
}>`
  position: absolute;
  right: 22px;
  cursor: pointer;
  font-weight: ${p => (p.isOn ? 'bold' : 'normal')};

  ::before {
    content: '' !important;
  }
  ::after {
    content: '';
    background: url('${p => (p.isOn ? AmassOnSVG : AmassOffSVG)}') no-repeat
      center center;
    width: 15px;
    height: 15px;
    position: absolute;
    top: 3px;
    margin-left: 5px;
  }
`;
export const ReportViewButton: FC<{
  className?: string;
  isOn: boolean;
  handleClick?: () => void;
}> = props => {
  return (
    <ReportViewButtonWrapper
      className={props.className}
      isOn={props.isOn}
      onClick={props.handleClick}
    >
      스프린트 보고서 모아보기
    </ReportViewButtonWrapper>
  );
};

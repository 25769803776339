import { observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import {
  IManagingTip,
  TIP_NOTION_URL,
} from '../../../store/Dashboard/models/ManagingTip';

import { Tip as Comps } from '../../molecules/Dashboard';

const DEFAULT_COUNT = 3;

interface Props {
  className?: string;
  tips: IManagingTip[];
}

const Tip: FC<Props> = observer(props => {
  const maxPage = Math.ceil(props.tips.length / DEFAULT_COUNT);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [displiedTips, setDispliedTips] = useState<IManagingTip[]>([]);

  /*
  0 - 2,
  3 - 5,
  6 - 8,
  */

  const updateDispliedTips = () => {
    const { tips } = props;

    const startIndex = DEFAULT_COUNT * (currentPageNumber - 1);
    const endIndex =
      startIndex + DEFAULT_COUNT > tips.length
        ? tips.length
        : startIndex + DEFAULT_COUNT;
    setDispliedTips(tips.slice(startIndex, endIndex));
  };

  useEffect(() => {
    updateDispliedTips();
  }, [currentPageNumber]);

  return (
    <Comps.Wrapper className={props.className}>
      <Comps.SubTitle>프로젝트 운영 팁</Comps.SubTitle>

      <Comps.Box>
        <h1>IT 프로젝트 운영과 이해에 꼭 필요한 지식</h1>
        <div className="more">
          <a href={TIP_NOTION_URL} target="_blank" rel="noopener noreferrer">
            더보기
          </a>
        </div>

        {displiedTips.map((tip, i) => {
          return (
            <a key={i} href={tip.url} target="_blank" rel="noopener noreferrer">
              <Comps.Item>
                <Comps.Thumnail className="thumnail" url={tip.thumnailUrl} />

                <div className="subject">{tip.subject}</div>

                <div className="tags">
                  {tip.tags.map((tag, i) => (
                    <span key={i}>#{tag}</span>
                  ))}
                </div>
              </Comps.Item>
            </a>
          );
        })}

        {props.tips.length > 3 && (
          <Comps.Paginator
            maxPage={maxPage}
            currentPage={currentPageNumber}
            handleClickPage={(page: number) => {
              if (0 < page && page < maxPage + 1) {
                setCurrentPageNumber(page);
              }
            }}
          />
        )}
      </Comps.Box>
    </Comps.Wrapper>
  );
});

export default Tip;

import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

export const Wrapper = styled.div`
  width: 450px;
  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;
export const Box = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 5px 20px 10px 20px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  border-radius: 10px;

  .hidden {
    display: none;
  }

  @media only screen and (max-width: ${PC}) {
    padding: 0px 13px 0px 15px;
  }
`;

export const Content = styled.div`
  display: flex;
  & + & {
    // margin-top: 15px;
    border-top: solid 1px ${PALETTE['very-light-pink-two']};
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    margin-bottom: 15px;
    padding-top: 15px;
    position: relative;
  }
`;
export const ContentTitle = styled.div`
  line-height: 50px;
  width: 70px;
  font-size: 14px;
  font-weight: bold;

  @media only screen and (max-width: ${PC}) {
    line-height: 20px;
    margin-bottom: 10px;
  }
`;
export const ContentBody = styled.div``;
export const ContentBodyRow = styled.div`
  display: flex;
  line-height: 50px;

  div:nth-child(1) {
    width: 100px;
    color: #000000;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  div:nth-child(2) {
    width: 75px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    a {
      color: ${PALETTE['purpleish-blue']};
    }
  }
  div:nth-child(3) {
    width: 165px;
  }

  span.info {
    margin-left: 10px;
    color: ${PALETTE['brownish-grey']};
    @media only screen and (max-width: ${PC}) {
      margin-left: 5px;
    }
  }
  span.diff {
    margin-left: 0px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: -25px;

    @media only screen and (max-width: ${PC}) {
      top: 0;
      left: 16px;
    }
  }

  .max-width {
    width: 100% !important;
  }
  .sub-row {
    line-height: 14px;
  }
  .rufree {
    color: ${PALETTE['purpleish-blue']};
  }
  .scheduled {
    color: ${PALETTE['brownish-grey']};
  }

  .nomatched {
    margin-left: 75px;
  }
  .matching {
    color: ${PALETTE['bright-orange']};
  }
  .matching-title {
    font-weight: 500;
    color: ${PALETTE['bright-orange']} !important;

    @media only screen and (max-width: ${PC}) {
      position: absolute;
      top: 15px;
      left: 72px;
    }
  }

  .matching-date {
    color: #000000;
  }
  @media only screen and (min-width: ${PC}) {
    .matching-date {
      height: 75px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    line-height: 20px;
    flex-wrap: wrap;
    div {
      width: fit-content !important;
    }

    &.matching-row {
      width: 300px;
    }

    .matching-date {
      margin-top: 2px;
    }
    .matching-date::before {
      content: '';
      padding-left: 0;
      padding-right: 0;
    }
    .nomatched {
      margin-left: 0px;
    }
    div + div::before {
      color: ${PALETTE['brown-grey']};
      content: '|';
      padding-left: 10px;
      padding-right: 10px;
    }
    div.empty {
      display: none;
    }
    // div.empty::before {
    //   content: '';
    // }
    & + & {
      margin-top: 12px;
      // &:nth-child(2) {
      //   margin-top: 0px;
      // }
    }
  }
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 15px;
`;

import { types } from 'mobx-state-tree';

export const SubTaskModel = types.model('SubTask', {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  sub_task_id: types.string,
  title: types.string,
  checked: types.boolean,
  sub_task_list: types.string,
});
type SubTaskType = typeof SubTaskModel.Type;

export interface SubTask extends SubTaskType {}

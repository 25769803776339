import React, { useState } from 'react';

import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';

const ENTER_KEY = 'Enter';

export interface IPostProps {
  id: number;
  title: string;
  innerRef?: any;
  draggableProps: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  // style?: object;

  handleChangeTitle: (title: string) => void;
  handleClick: () => void;
  handleDelete: () => void;
}

export const Post: React.SFC<IPostProps> = props => {
  const [isTitleEditing, setIsTitleEditing] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(props.title);

  const _onChangeTitle = (title: string) => {
    if (title !== props.title) {
      // 타이틀이 수정되었으면,
      props.handleChangeTitle(title);
    }

    setTitle(title);
    setIsTitleEditing(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER_KEY) {
      _onChangeTitle((e.target as HTMLInputElement).value);
    }
    if (e.key === 'Escape') {
      setTitle(props.title);
      setIsTitleEditing(false);
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    _onChangeTitle((e.target as HTMLInputElement).value);
  };

  return (
    <li
      className={`${isTitleEditing ? 'modify' : ''}`}
      ref={props.innerRef}
      // style={ props.style }
      {...props.draggableProps}
      {...props.dragHandleProps}
      onClick={props.handleClick}
    >
      {isTitleEditing ? (
        <input
          type="text"
          value={title}
          title="메뉴입력"
          autoFocus={true}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
        ></input>
      ) : (
        <a href="#" onClick={() => {}}>
          {props.title}
        </a>
      )}

      <button type="button" className="btn-move">
        <span>메뉴이동</span>
      </button>
      <span className="btn-md">
        <button
          type="button"
          className="modify"
          onClick={e => {
            e.stopPropagation();
            setIsTitleEditing(true);
          }}
        >
          <span>수정</span>
        </button>
        <button
          type="button"
          className="delete"
          onClick={e => {
            e.stopPropagation();
            props.handleDelete();
          }}
        >
          <span>삭제</span>
        </button>
      </span>
    </li>
  );
};

import React, { useState } from 'react';

export interface IAddTaskListProps {
  onAdd: (title: string) => void;
}

export const AddTaskList: React.SFC<IAddTaskListProps> = props => {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState<string>('');
  const inputStyle = {
    display: isEditing ? 'block' : 'none',
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onAdd && props.onAdd((e.target as HTMLInputElement).value);
    setIsEditing(false);
  };
  const onChange = (e: React.FocusEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      props.onAdd && props.onAdd((e.target as HTMLInputElement).value);
      setTitle('');
      setIsEditing(false);
    }

    if (e.key === 'Escape') {
      setTitle('');
      setIsEditing(false);
    }
  };

  return (
    <section className="card-wrap">
      <div className="inner">
        <div className="top-add">
          <button type="button" onClick={() => setIsEditing(true)}>
            추가
          </button>
          <input
            type="text"
            placeholder="목록 이름을 입력하세요"
            style={inputStyle}
            autoFocus={true}
            value={title}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyPress}
          />
        </div>
        {/* <!-- 추가 input 활성화 case -->
          <div className="top-add">
              <button type="button">추가</button>
              <input type="text" placeholder="목록 이름을 입력하세요" />
          </div>
          <!-- //추가 input 활성화 case --> */}
      </div>
    </section>
  );
};

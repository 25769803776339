import { Dialog as bp3_Dialog } from '@blueprintjs/core';
import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';

import CloseSvg from '../../../static/icons/icon-close-white.svg';
import DownloadSvg from '../../../static/icons/icon-download-black.svg';
import MainSvg from '../../../static/icons/icon-mail-black.svg';
import { IReport } from '../../../store/Dashboard/models/SprintReport';

const BP3Dialog = styled(bp3_Dialog)`
  background: #ffffff;
  box-shadow: none;

  margin: 0;
  padding: 0;
  width: 785px;
  min-height: 500px;
  position: relative;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;
const Footer = styled.div`
  height: 73px;
  margin: 14px 40px 0px 40px;
  padding-top: 14px;
  border-top: solid 1px #000000;

  display: flex;
  line-height: 19px;
  font-size: 13px;
  font-weight: bold;

  padding-left: 20px;

  div + div {
    margin-left: 50px;
  }
`;

const CloseButton = styled.div`
  background: url('${CloseSvg}') no-repeat center center;
  position: absolute;
  left: 805px;
  top: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

const FooterButton = styled.div`
  position: relative;
  cursor: pointer;
  ::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    top: 2px;
  }
`;

const MailButton = styled.a`
  color: #000000;
  position: relative;
  cursor: pointer;
  :hover {
    color: #000000;
  }
  ::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    top: 2px;
    background: url('${MainSvg}') no-repeat center center;
  }
`;
const DownloadButton = styled(FooterButton)`
  left: 50px;
  ::before {
    background: url('${DownloadSvg}') no-repeat center center;
  }
`;

const SprintReportDialog: FC<{
  isOpen: boolean;
  report: IReport;
  handleClose: () => void;
}> = ({ isOpen, report, handleClose }) => {
  return (
    <BP3Dialog isOpen={isOpen} onClose={handleClose}>
      <CloseButton onClick={handleClose} />

      {report.htmlData && (
        <div dangerouslySetInnerHTML={{ __html: report.htmlData }} />
      )}

      <Footer>
        <MailButton href="mailto:support@seeso.kr">
          경영지원팀 문의하기
        </MailButton>
        <DownloadButton
          onClick={async () => {
            report.download();
          }}
        >
          PDF 다운받기
        </DownloadButton>
      </Footer>
    </BP3Dialog>
  );
};

export default SprintReportDialog;

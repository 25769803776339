import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { Assignee } from '../components/SeesoBoard/Common/Fields';
import * as TF from '../components/SeesoBoard/TaskFilter';

import { AppStore } from '../store/AppStore';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class FilterContainer extends Component<IProps> {
  @observable labelToDelete = '';
  @observable sprintToDelete = '';

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get board() {
    return this.injected.appStore.boardStore.taskBoard;
  }

  async componentDidMount() {
    const { userId } = this.injected.appStore.userStore;
    const { setMyId } = this.injected.appStore.boardStore.taskFilters;

    setMyId(userId);
  }

  renderAssignee() {
    const { person_in_charges, addPersonInCharges, removePersonInCharges } =
      this.injected.appStore.boardStore.taskFilters;

    const firstAssignee = (() => {
      if (person_in_charges.length > 0) {
        const assigneeId = person_in_charges[0];

        if (this.board && assigneeId) {
          return this.board.members.find(m => m.id === assigneeId);
        }
      }
      return undefined;
    })();

    return (
      <TF.AssigneeSelector
        title={
          firstAssignee
            ? `${firstAssignee.name} ${
                person_in_charges.length > 1
                  ? `외 ${person_in_charges.length - 1}`
                  : ``
              }`
            : '담당자'
        }
      >
        {this.board &&
          this.board.members.map((member, i) => (
            <Assignee
              key={i}
              id={member.id}
              email={member.email}
              name={member.name}
              value={member.id + ''}
              selected={person_in_charges.includes(member.id)}
              onChange={checked => {
                if (checked) {
                  addPersonInCharges(member.id);
                } else {
                  removePersonInCharges(member.id);
                }
              }}
            />
          ))}
      </TF.AssigneeSelector>
    );
  }

  renderLabel() {
    const { label_title_list, addLabel, removeLabel } =
      this.injected.appStore.boardStore.taskFilters;

    return (
      <TF.LabelSelector
        isSelectOnly={true}
        title={
          label_title_list.length > 0
            ? `${label_title_list[0]} ${
                label_title_list.length > 1
                  ? `외 ${label_title_list.length - 1}`
                  : ``
              }`
            : '라벨'
        }
      >
        {this.board &&
          this.board.labelTitleList.slice().map((label, i) => (
            <TF.Label
              key={i}
              isSelectOnly={true}
              title={label}
              value={label}
              selected={label_title_list.includes(label)}
              handleChange={e => {
                if (e.target.checked) {
                  addLabel(e.target.value);
                } else {
                  removeLabel(e.target.value);
                }
              }}
            />
          ))}
      </TF.LabelSelector>
    );
  }

  renderSprint() {
    const { sprint_title_list, addSprint, removeSprint } =
      this.injected.appStore.boardStore.taskFilters;

    return (
      <TF.SprintSelector
        isSelectOnly={true}
        title={
          sprint_title_list.length > 0
            ? `${sprint_title_list[0]} ${
                sprint_title_list.length > 1
                  ? `외 ${sprint_title_list.length - 1}`
                  : ``
              }`
            : '스프린트'
        }
      >
        {this.board &&
          this.board.sprintTitleList.slice().map((sprint, i) => (
            <TF.Sprint
              key={i}
              isSelectOnly={true}
              title={sprint}
              value={sprint}
              selected={sprint_title_list.includes(sprint)}
              handleChange={e => {
                if (e.target.checked) {
                  addSprint(e.target.value);
                } else {
                  removeSprint(e.target.value);
                }
              }}
            />
          ))}
      </TF.SprintSelector>
    );
  }

  renderDueDate() {
    const {
      duedate_start,
      duedate_end,
      setDueDateStart,
      setDueDateEnd,
      setUseDueDate,
    } = this.injected.appStore.boardStore.taskFilters;

    return (
      <TF.DueDateRangeField
        title={
          duedate_start || duedate_end
            ? `${duedate_start} ~ ${duedate_end}`
            : '마감일'
        }
        startValue={duedate_start}
        endValue={duedate_end}
        handleChangeStart={(date: string) => {
          setDueDateStart(date);
          date && setUseDueDate(true);
        }}
        handleChangeEnd={(date: string) => {
          setDueDateEnd(date);
          date && setUseDueDate(true);
        }}
      />
    );
  }

  render() {
    const { taskFilters } = this.injected.appStore.boardStore;

    return (
      <>
        <div className="task-filter">
          {taskFilters.isValueSelected && (
            <TF.ResetButton
              handleClick={() => {
                taskFilters.reset();
              }}
            />
          )}

          {/* 내 태스크만 보기 */}
          <TF.MyTaskButton
            selected={taskFilters.show_only_my_tasks}
            handleClick={taskFilters.toggleShowOnlyMyTasks}
          />

          {/* 담당자 */}
          {this.renderAssignee()}

          {/* 라벨 */}
          {this.renderLabel()}

          {/* 스프린트 */}
          {this.renderSprint()}

          {/* 마감일 */}
          {this.renderDueDate()}

          <TF.KeywordField
            value={taskFilters.keyword}
            handleSearch={(keyword: string) => {
              taskFilters.setKeyword(keyword);
            }}
          />
        </div>
      </>
    );
  }
}

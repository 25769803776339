import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import styled from 'styled-components/macro';

import { AppStore } from '../store/AppStore';
import { EActiveCompoment } from '../store/UIStore';

export const StyledSection = styled.section`
  .in-box > fieldset input {
    margin-bottom: 3px;
    height: 30px;
    padding: 3px 10px 0 38px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    font-size: 14px;
  }

  button:not(.btn-close) {
    min-width: 87px;
    height: 29px;
    padding: 0 20px;
    border-radius: 3px;
    border: 1px solid #026cab;
    color: #0279bf;
    font-size: 12px;
    font-weight: bold;
  }

  .inbox > button {
    width: 100%;
  }

  button:disabled {
    border: 1px solid #e0e0e0;
    color: #e0e0e0;
  }
  div.members ul li {
    padding: 3px 0;
  }
  div.members ul li.header {
    font-weight: bold;
    border-bottom: 1px solid #e0e0e0;
  }
  div.members ul li span:first-child {
    display: inline-block;
    width: 100px;
    padding-left: 5px;
  }
  div.members ul li span.no-member {
    color: #e0e0e0;
  }
`;

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}
interface IState {
  keyword: string;
  batched: boolean;
}

const InputField: React.SFC<{
  legend: string;
  placeholder?: string;
  value?: string;
  onChange: (value: string) => void;
}> = props => {
  return (
    <fieldset className="sch-w">
      <legend>{props.legend}</legend>

      <input
        type="text"
        placeholder={props.placeholder}
        style={{ padding: '3px' }}
        value={props.value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange(e.target.value)
        }
      />
    </fieldset>
  );
};

const installMsg = (
  <div>
    시소담당PM이 먼저 슬랙에서 시소봇 앱을 생성해야합니다(
    <a
      target="_blank"
      href="https://docs.google.com/presentation/d/1qupRdH4E7iFf0nW8HUua7AKt7SFuEMupM40fGQgBP6Y/edit?usp=sharing"
    >
      링크
    </a>
    ). 생성 후 획득한 CLIENT_ID, CLIENT_SECRET 정보를 입력한 뒤, '시소봇 설치'
    버튼을 선택하면 슬랙 팀정보와 토큰정보가 DB에 저장됩니다.
  </div>
);
const getInfoMsg = (
  <div>
    시소담당PM은 슬랙의 채널과 사용자정보가 변경되었을 때, 이를 가져와서 DB에
    저장해둘 수 있습니다. 저장된 채널, 멤버를 기반으로 이 프로젝트 보드의
    슬랙설정을 진행할 수 있습니다.
  </div>
);
const getInfoSettinsMsg = (
  <div>
    이제 저장된 슬랙 채널과 사용자 정보를 이용하여, 이 프로젝트그룹의
    프로젝트보드에 슬랙 설정을 할 수 있습니다.
  </div>
);
const channelMsg = (
  <div>
    알림 메시지를 보낼 채널을 지정합니다. 1) 시소봇이 멤버로 추가된 채널, 2)
    공개채널(슬랙에 # 표시됨)을 지정 가능합니다.
  </div>
);
const memberMsg = (
  <div>
    팀원이름과 슬랙닉네임을 매핑합니다. 팀원은 프로젝트그룹에서 설정한
    클라이언트 실무자, 시소PM, 매칭된 알유프리를 포함합니다.
    <ul style={{ fontSize: '12px' }}>
      <li>
        <p>멤버 이메일과 슬랙 이메일이 동일하면 자동매칭됩니다.</p>
      </li>
      <li>
        <p>
          멤버 이메일과 슬랙 이메일이 다르면 아래에서 선택 후 저장하세요.
          매칭되지 않은 슬랙멤버 리스트가 나타납니다.
        </p>
      </li>
      <li>
        <p>
          멤버에 나타나지만 슬랙 멤버가 아니라면 '정보없음'으로 두세요. 이름으로
          언급되고 멘션되지 않습니다.
        </p>
      </li>
      <li>
        <p>
          멤버에 나타나지 않는 슬랙 멤버가 있다면 프로젝트그룹에서 추가하세요.
        </p>
      </li>
    </ul>
  </div>
);

@inject('appStore')
@observer
export default class SlackSettingContainer extends Component<IProps, IState> {
  @observable clientID: string = this.boardStore.slack.clientId || '';
  @observable clientSecret: string = this.boardStore.slack.clientSecret || '';
  @observable selectedChannelId: string =
    this.boardStore.slack.currentChannel || '';
  @observable memberMappings: { [k: string]: number } = {};

  get injected() {
    return this.props as InjectedProps;
  }

  get boardStore() {
    return this.injected.appStore.boardStore;
  }

  get uiStore() {
    return this.injected.appStore.uiStore;
  }

  async componentDidMount() {
    this.clientID = this.boardStore.slack.clientId || '';
    this.clientSecret = this.boardStore.slack.clientSecret || '';

    window.addEventListener('message', this.onInstallEvent);
  }

  // 앱 설치 콜백
  onInstallEvent = (e: any) => {
    const { showToast } = this.uiStore;

    if (e.data.ok === 'True') {
      showToast(false, false, '등록을 완료했습니다.');
      // this.boardStore.slack.checkSlackInstalled();
    } else if (e.data.ok === 'False') {
      showToast(true, false, e.data.message);
    }
  };

  onInstall = () => {
    const { projectGroupId, taskBoard } = this.boardStore;

    if (taskBoard && this.clientID.length > 0 && this.clientSecret.length > 0) {
      const install_url =
        `https://slack.com/oauth/v2/authorize` +
        `?scope=channels:join,channels:read,chat:write,chat:write.public,groups:read,team:read,users:read,users:read.email` +
        `&client_id=${this.clientID}` +
        `&state=${projectGroupId}-${this.clientID}-${this.clientSecret}`;

      window.open(install_url, '_blank');
    }
  };

  onSync = async () => {
    const { taskBoard, slack } = this.boardStore;
    const { showToast, closeToase } = this.uiStore;

    // if(taskBoard && slack.isAvailable) {
    //   showToast(true, true, '슬랙채널 및 사용자정보를 가지고 오는 중입니다.');
    //   await slack.syncWithSlack();
    //   closeToase();
    // }
  };

  onGetSettings = async () => {
    const { taskBoard, slack } = this.boardStore;
    const { showToast, closeToase } = this.uiStore;

    if (!taskBoard || !slack.isAvailable) return;

    showToast(false, true, '슬랙설정정보 가지고 오는 중입니다.');

    // await Promise.all([
    //   this.boardStore.slack.fetchChannels(),
    //   this.boardStore.slack.fetchMembers(),
    //   this.boardStore.slack.fetchSettings()
    // ])
    // .then(values => {
    //   closeToase();
    // })
    // .catch(error => {
    //   console.log(error)

    //   closeToase();
    //   if(error && error.hasOwnProperty('response')) {
    //     showToast(true, false, error.response.data.message);
    //   }

    //   throw error;
    // });

    closeToase();
  };

  onChangeChannel = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { taskBoard, slack } = this.boardStore;
    // const { showToast, closeToase } = this.uiStore;

    if (!taskBoard || !slack.isAvailable) return;
    console.log(e.target.value);

    this.selectedChannelId = e.target.value;

    // showToast(false, true, '채널 설정 변경중입니다.');
    // await this.boardStore.slack.updateSettings(e.target.value);
    // await this.boardStore.slack.fetchSettings();
    // closeToase();
  };

  onChangeMember = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { taskBoard, slack } = this.boardStore;

    if (!taskBoard || !slack.isAvailable) return;
    if (!e.target.dataset.hasOwnProperty('memberid')) return;

    const memberId = Number(e.target.dataset.memberid);
    const findedKey = Object.keys(this.memberMappings).find(
      key => this.memberMappings[key] === memberId,
    );

    if (findedKey) {
      delete this.memberMappings[findedKey];
    }

    this.memberMappings[e.target.value] = memberId;
  };

  onSave = async () => {
    // const { showToast, closeToase } = this.uiStore;
    // showToast(false, true, '슬랙 설정 업데이트중입니다.');
    // await this.boardStore.slack.updateSettings(
    //   this.selectedChannelId,
    //   this.memberMappings
    // );
    // await this.boardStore.slack.fetchSettings();
    // closeToase();
  };

  render() {
    const { activeComponent, setActiveCompoment } = this.uiStore;
    const className =
      activeComponent === EActiveCompoment.SLACKSETTING ? 'selected' : '';

    if (
      !this.boardStore.taskBoard ||
      activeComponent !== EActiveCompoment.SLACKSETTING
    )
      return <></>;

    return (
      <StyledSection className={`deleted-task ${className}`}>
        <h1>슬랙 설정</h1>

        <div className="dt-box">
          <div className="viewport">
            <div className="in-box">
              <p>
                <h4>1. 시소봇 설치</h4>
                {installMsg}
              </p>
              <InputField
                legend="CLIENT_ID"
                placeholder="CLIENT_ID"
                value={this.clientID}
                onChange={value => (this.clientID = value)}
              />
              <InputField
                legend="CLIENT_SECRET"
                placeholder="CLIENT_SECRET"
                value={this.clientSecret}
                onChange={value => (this.clientSecret = value)}
              />

              <button
                disabled={
                  this.clientID.length === 0 || this.clientSecret.length === 0
                }
                type="button"
                onClick={this.onInstall}
              >
                시소봇 설치
              </button>
            </div>

            <div className="in-box">
              <p>
                <h4>2. 슬랙채널 및 사용자정보 가져오기</h4>
                {getInfoMsg}
              </p>
              <button
                disabled={!this.boardStore.slack.isAvailable}
                type="button"
                onClick={this.onSync}
              >
                슬랙채널 및 사용자정보 가져오기
              </button>
            </div>

            <div className="in-box">
              <p>
                <h4>3. 슬랙설정정보 가져오기</h4>
                {getInfoSettinsMsg}
              </p>
              <button
                disabled={!this.boardStore.slack.isAvailable}
                type="button"
                onClick={this.onGetSettings}
              >
                슬랙설정 정보 가져오기
              </button>
            </div>

            <div className="in-box">
              <p>
                <h4>4. 채널 설정</h4>
                {channelMsg}
              </p>

              <select
                disabled={!this.boardStore.slack.isAvailable}
                name="channels"
                value={this.selectedChannelId}
                id="channel-select"
                onChange={this.onChangeChannel}
              >
                {this.boardStore.slack.channels.length === 0 ? (
                  <option value="">채널 정보 없음</option>
                ) : (
                  this.boardStore.slack.channels.map(
                    (channel: { id: string; name: string }) => (
                      <option value={channel.id}>{channel.name}</option>
                    ),
                  )
                )}
              </select>
            </div>

            <div className="in-box members">
              <p>
                <h4>5. 팀원 설정</h4>
                {memberMsg}
              </p>

              <ul>
                <li className="header">
                  <span> 팀원 </span>
                  <span> 슬랙 </span>
                </li>
                {this.boardStore.taskBoard.members.map(member => {
                  const slackMember = this.boardStore.slack.members.find(
                    m => m.authId === member.id,
                  );
                  return (
                    <li>
                      <span>{member.name}</span>
                      <span className={slackMember ? '' : 'no-member'}>
                        {slackMember ? (
                          slackMember.displayName
                        ) : (
                          <select
                            // disabled={ !this.boardStore.slack.isAvailable }
                            name="slack-nickname"
                            id="slack-nickname-select"
                            data-memberId={member.id}
                            onChange={this.onChangeMember}
                          >
                            <option value="">정보 없음</option>
                            {this.boardStore.slack.membersNoAuthId.length > 0 &&
                              this.boardStore.slack.membersNoAuthId.map(
                                (member: {
                                  slackId: string;
                                  displayName: string;
                                }) => (
                                  <option value={member.slackId}>
                                    {member.displayName}
                                  </option>
                                ),
                              )}
                          </select>
                        )}
                      </span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="btn">
          <button type="button" onClick={this.onSave}>
            저장
          </button>
        </div>

        <button
          type="button"
          className="btn-close"
          onClick={() => {
            setActiveCompoment();
          }}
        >
          <span>창 닫기</span>
        </button>
      </StyledSection>
    );
  }
}

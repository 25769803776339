import { Dialog as bp3_Dialog } from '@blueprintjs/core';
import React, { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PC } from '../../../constants/media';

const BP3LoginModal = styled(bp3_Dialog)`
  position: relative;
  width: 570px;
  padding: 30px 30px 30px;
  background: #fff;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);

  div.bp3-dialog-header {
    box-shadow: none;
  }

  div.dialog-body {
    padding: 27px 0 0;

    @media only screen and (max-width: ${PC}) {
      padding: 0px;
    }
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none outside;
    position: relative;
    padding-top: 10px;
  }
  input {
    width: 100%;
    height: 52px;
    border: none;
    font-size: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  input:focus {
    outline: none;
  }

  button.login-button {
    display: block;
    width: 100%;
    height: 52px;
    margin-top: 20px;
    background: #e5ff1e;
    color: #2b2b2b;
    font-size: 15px;
    text-align: center;
    line-height: 52px;
    border: none;
    font-weight: 800;
  }
  p {
    padding-top: 24px;
    color: #6f6f6f;
    font-size: 15px;
    text-align: center;
    a {
      padding-left: 5px;
      color: #5834ff;
    }
  }
`;

const LoginModal: FC<{
  modalIsOpen: boolean;
  handleModalClose: () => void;
  handleMenuClose: () => void;
  handleChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  handleLoginAndRedirect: () => void;
}> = props => {
  const history = useHistory();
  const location = useLocation();

  const handleToRegister = () => {
    props.handleModalClose();
    props.handleMenuClose();
    history.push(`/signup${location.search.trim()}`);
  };

  return (
    <BP3LoginModal
      isOpen={props.modalIsOpen}
      title=""
      onClose={props.handleModalClose}
    >
      <div className="dialog-body">
        <ul>
          <li>
            <input
              autoFocus={true}
              type="text"
              placeholder="이메일"
              onChange={props.handleChangeEmail}
            />
          </li>
          <li>
            <input
              type="password"
              placeholder="비밀번호"
              onChange={props.handleChangePassword}
              onKeyDown={(event: KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Enter') {
                  props.handleLoginAndRedirect();
                }
              }}
            />
          </li>
        </ul>
        <button className="login-button" onClick={props.handleLoginAndRedirect}>
          로그인
        </button>
        <p>아직 멤버가 아니신가요? <br/>향후 서비스를 위해<a style={{ textDecoration: 'underline' }} onClick={() => { handleToRegister() }}>멤버가입</a>후 진행 해주세요</p>
        {/* <p>
          현재 회원가입은<a href="https://second-team/#company" target="_blank">세컨팀 홈페이지</a>를 통해 가입 후 진행됩니다.
        </p> */}
      </div>
    </BP3LoginModal>
  );
};

export default LoginModal;

import styled from 'styled-components/macro';

export const PricingWrapper = styled.main`
  position: relative;
  .inner {
    width: 1100px;
    margin: 0 auto;
    line-height: 1.5;
  }
  @media only screen and (max-width: 640px) {
    .inner {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
  ol,
  ul {
    list-style: none outside;
    padding: 0;
  }
  em {
    font-style: normal;
  }
  h2 {
    text-align: center;
    font-weight: bold;
  }
  .pricing {
    position: relative;
    margin: -75px 0 -87px;

    margin-bottom: -27px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 87px;
      background: #161d2e;
    }
    @media only screen and (max-width: 640px) {
      margin: 0;
    }
  }
`;

export const TopInfo = styled.div`
  height: 675px;
  background: #5834ff
    url('${require('../../../static/images/pricing_topbg.png')}') no-repeat 50%
    100%;
  p {
    padding-top: 290px;
    color: #fff;
    font-size: 32px;
    font-weight: normal;
    text-align: center;
    line-height: 1.56;
    em {
      font-weight: bold;
    }
  }
  @media only screen and (max-width: 640px) {
    height: 270px;
    background-size: 120% auto;
    p {
      padding: 60px 20px 0;
      font-size: 20px;
    }
    br {
      display: none;
    }
  }
`;

export const Step = styled.div`
  background: #fff;
  .inner {
    @include sublay;
    padding-top: 99px;
    ol {
      padding: 0 0 70px 25px;
      border-left: 2px solid rgba(88, 52, 255, .17);
      > li {
        position: relative;
        margin-top: 99px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          top: -5px;
          left: -32px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #5834ff;
          box-shadow: 0 0 0 4px rgba(88,52,255,0.27);
        }
        
        > p {
          position: relative;
          top: -20px;
          color: #393c4c;
          font-size: 22px;
          em {
            padding-right: 20px;
            color: #4b34ff;
            font-size: 28px;
            font-weight: bold;
            text-transform: uppercase;
          }
        }
        &:first-child {
          margin-top: 0;
        }
        &:marker {
          content: none;
        }
        table {
          width: auto;
          margin: 60px 0 0 25px;
          th {
            width: 140px;
            padding-bottom: 35px;
            vertical-align: bottom;
            font-size: 16px;
            text-align: center;
          }
          td {
            width: 230px;
            padding-bottom: 35px;
            vertical-align: bottom;
            color: #161d2e;
            font-size: 16px;
            font-weight: normal;
            text-align: center;
            span {
              display: block;
              padding-top: 15px;
            }
          }
        }
        > ul {
          margin-top: 20px;
          padding-left: 20px;
          li {
            color: #5c637a;
            font-size: 18px;
            &:before {
              content: '*';
              margin-right: 5px;
            }
          }
        }
        .img {
          padding-top: 50px;
          padding-left: 25px;
          ul {
            @include clearfix;
            margin-top: 30px;
            padding-top: 25px;
            border-top: 1px solid rgba(22,29,46, .17);
            li {
              float: left;
              padding-right: 25px;
              font-size: 16px;
              em {
                padding-left: 25px;
                color: #161d2e;
                font-size: 20px;
                vertical-align: -2px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) { 
    padding: 0 20px;
    .inner {
      padding-top: 50px;
      ol {
        > li {
          margin-top: 60px;
          > p {
            font-size: 16px;
            > em {
              padding-right: 10px;
              font-size: 18px;
            }
          }
          table {
            width: 100%;
            margin: 10px 0 0;
            th {
              padding-bottom: 15px;
              font-size: 14px;
            }
            td {
              width: 25%;
              padding-bottom: 15px;
              font-size: 14px;
              img {
                width: 50px;
              }
            }
          }
          > ul {
            padding-left: 0;
            li {
              font-size: 14px;
              padding-left: 12px;
              text-indent: -12px;
            }
          }
          .img {
            padding: 0;
            ul {
              padding-top: 10px;
              li {
                font-size: 14px;
                em {
                  padding-left: 10px;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
  }
`;

export const CostList = styled.div`
  padding: 140px 0 170px;
  text-align: center;
  h3 {
    color: #21242c;
    font-size: 32px;
    font-weight: bold;
  }
  > div {
    overflow: hidden;
    display: inline-block;
    position: relative;
    width: 350px;
    height: 400px;
    margin: 45px 10px 0;
    border-radius: 3px;
    box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    vertical-align: top;
    text-align: left;
    h4 {
      margin: 0 25px;
      padding: 35px 0 20px;
      border-bottom: 1px solid rgba(22, 29, 46, 0.17);
      color: #161d2e;
      font-size: 22px;
      line-height: 1.27;
      font-weight: bold;
    }
    ul {
      padding-left: 20px;
      li {
        padding-top: 17px;
        color: #161d2e;
        font-size: 18px;
      }
    }
    strong {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 79px;
      padding-left: 28px;
      background: #4b34ff;
      color: #fff;
      font-size: 22px;
      line-height: 79px;
      box-sizing: border-box;
    }
  }
  @media only screen and (min-width: 1100px) {
    width: 1120px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 640px) {
    padding: 40px 20px 80px;
    h3 {
      padding-bottom: 10px;
      font-size: 20px;
    }
    > div {
      width: 100%;
      height: auto;
      margin: 15px 0 0;
      h4 {
        padding: 15px 0 10px;
        font-size: 18px;
      }
      ul {
        padding-top: 10px;
        li {
          padding-top: 7px;
          font-size: 16px;
        }
      }
      strong {
        position: static;
        height: 50px;
        margin-top: 15px;
        font-size: 18px;
        line-height: 50px;
      }
    }
  }
`;

export const SayBox = styled.div`
  height: 500px;
  padding: 53px 0;
  background: url('${require('../../../static/images/say_bg.png')}') no-repeat 0
    0;
  box-sizing: border-box;
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  position: relative;
  h3 {
    text-align: center;
    color: #161d2e;
    font-size: 32px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .inner {
    position: relative;
    margin-top: 95px;
    .cnts {
      display: inline-block;
      margin-top: 0px;
      max-width: 1200px;
      padding: 0 155px;
      p {
        font-size: 18px;
      }
    }

    @media only screen and (min-width: 1100px) {
      box-sizing: border-box;
      .img {
        float: left;
        padding-right: 45px;
      }
    }
  }
  button {
    position: absolute;
    top: 50%;
    width: 35px;
    height: 35px;
    transform: translate(0, -50%);
    background: none;
    border: none;
    cursor: pointer;
    span {
      overflow: hidden;
      font-size: 1px;
      line-height: 0;
      text-indent: -9999px;
      text-align: left;
      display: block;
    }
    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      border-color: #161d2e;
      border-style: solid;
      border-width: 0 2px 2px 0;
    }
    &.prev {
      left: 0;
      &:before {
        transform: rotate(135deg);
        margin-left: 12px;
      }
    }
    &.next {
      right: 0;
      &:before {
        transform: rotate(-45deg);
      }
    }
  }
  @media only screen and (max-width: 640px) {
    height: auto;
    padding: 30px 0;
    width: 100%;
    word-break: keep-all;
    h3 {
      padding: 0 10px;
      font-size: 30px;
    }
    .inner {
      margin-top: 50px;
      padding: 0 20px;
      .cnts {
        margin-top: 0;
        padding: 0 30px;
        max-width: 330px;
        word-break: keep-all;
        .img {
          width: 120px;
          margin: 0 auto 10px;
        }
        p {
          font-size: 16px;
        }
      }
    }
    button {
      top: 66%;
    }
  }
`;

export const Faq = styled.div`
  margin: 0 auto;
  margin-top: 175px;
  width: 1100px;
  position: relative;
  padding: 89px 80px 115px 50px;
  border-radius: 2px;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #f2f2f2;
  background-color: #fff;
  box-sizing: border-box;
  h2 {
    color: #21242c;
    font-size: 32px;
    text-align: center;
    text-transform: uppercase;
  }
  ul {
    li {
      margin-top: 55px;
      strong {
        color: #161d2e;
        font-size: 22px;
      }
      p {
        margin-top: 20px;
        padding-left: 25px;
        font-size: 18px;
        line-height: 1.5;
      }
    }
  }
  @media only screen and (max-width: 640px) {
    margin: 0 auto;
    padding: 50px 20px;
    width: 100%;
    ul {
      li {
        margin-top: 30px;
        strong {
          font-size: 18px;
        }
        p {
          margin-top: 10px;
          padding-left: 0;
          font-size: 16px;
        }
      }
    }
  }
`;

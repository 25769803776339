import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

const Wrapper = styled.div`
  display: flex;
`;

const LinkText = styled.a`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
  }

  :visited {
    color: ${PALETTE['purpleish-blue']};
  }
  :hover {
    color: ${PALETTE['color-red']};
    cursor: pointer;
  }
`;

const AskToBiz = styled(LinkText)`
  @media only screen and (max-width: ${PC}) {
    margin-left: auto;
  }
`;
const Agreement = styled(LinkText)`
  @media only screen and (max-width: ${PC}) {
    margin-right: auto;
  }
`;

const VLine = styled.div`
  width: 4px;
  height: 20px;
  margin-left: 8px;
  margin-right: 8px;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
  }
`;

class ContactContent extends Component {
  render() {
    return (
      <Wrapper>
        <AskToBiz href={'mailto:support@seeso.kr'} target={'_blank'}>
          경영지원팀 문의
        </AskToBiz>
        <VLine>|</VLine>
        <Agreement href={'https://seeso.kr/terms/agreement'} target={'_blank'}>
          서비스 이용약관
        </Agreement>
      </Wrapper>
    );
  }
}

export default ContactContent;

import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components/macro';
import { HEADER_HEIGHT } from '../../../constants/appConfig';
import { SectionName } from '../../pages/HomePage';
import { LoginButton } from './index';

const StyledHeader = styled.header<{ isSideMenuOpen: boolean }>`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  position: fixed;
  z-index: 10;
  top: 0;
  padding: 0 52px 0 24px;
  box-sizing: border-box;
  background: #f9fdff;

  h1 {
    margin-top: -5px;
  }
  h1 button {
    display: block;
    width: 200px;
    height: 34px;
    background: url(https://seeso-cpms.s3.ap-northeast-1.amazonaws.com/uploads/logo.png) no-repeat center center;
    background-size: contain;
  }

  h1 button span {
    overflow: hidden;
    display: inline-block;
    font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
    text-align: left;
  }

  .btn-uti {
    display: inline-flex;
    align-items: center;
    height: 38px;
    padding: 0 16px;
    border-radius: 100px;
    background-color: rgba(85, 57, 255, 0.03);
    color: #5539ff;
    font-size: 14px;
    white-space: nowrap;
  }

  .btn-menu {
    position: absolute;
    top: 19px;
    right: 14px;
    width: 40px;
    height: 40px;
    box-sizing: border-box;
  }

  .btn-menu:before,
  .btn-menu:after {
    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 10px);
    width: 20px;
    height: 2px;
    background: #5539ff;
    border-radius: 3px;
  }

  .btn-menu:before {
    top: 11px;
  }

  .btn-menu:after {
    top: 18px;
  }

  .btn-menu span {
    overflow: hidden;
    display: inline-block;
    font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
    text-align: left;
    width: 20px;
    height: 16px;
  }

  .btn-menu span:before {
    content: '';
    display: block;
    position: absolute;
    top: 25px;
    left: calc(50% - 10px);
    width: 20px;
    height: 2px;
    background: #5539ff;
    border-radius: 3px;
  }

  nav {
    display: ${({ isSideMenuOpen }) => (isSideMenuOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    margin-left: -125px;
    width: 100vw;
    height: 100vh;
    padding: 80px 40px;
    background: #5539ff;
    box-sizing: border-box;
  }

  nav a,
  nav button,
  nav .btn-uti {
    color: #fff;
    font-size: 22px;
    padding: 0;
    background: none;
  }

  nav ul {
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  }

  nav ul li {
    margin-bottom: 40px;
  }

  nav .btn-close {
    position: fixed;
    top: 40px;
    right: 14px;
    width: 40px;
    height: 40px;
  }

  nav .btn-close > span {
    overflow: hidden;
    display: inline-block;
    font-size: 1px;
    line-height: 0;
    text-indent: -9999px;
    text-align: left;
  }

  nav .btn-close:before,
  nav .btn-close:after {
    content: '';
    display: block;
    position: absolute;
    top: 7px;
    left: calc(50% - 2px);
    width: 2px;
    height: 22px;
    background: #fff;
    border-radius: 2px;
  }

  nav .btn-close:before {
    transform: rotate(-45deg);
  }

  nav .btn-close:after {
    transform: rotate(45deg);
  }

  nav.selected {
    display: block;
  }

  .utill {
    margin-left: auto;
  }

  @media (min-width: 786px) {
    padding-right: 24px;

    .btn-menu,
    .btn-close {
      display: none;
    }

    nav {
      flex: 1;
      display: flex !important;
      align-items: center;
      position: static;
      width: auto;
      height: auto;
      padding: 0 16px 0 0;
      background: none;
    }

    nav a,
    nav button {
      color: #0d1240;
      font-size: 14px;
    }

    nav ul {
      display: flex;
      align-items: center;
      margin: 0;
    }

    nav ul li {
      margin: 0 0 0 40px;
    }

    nav .btn-uti {
      margin-left: auto;
    }

    .btn-uti {
      padding: 0 16px !important;
      color: #5539ff !important;
      font-size: 14px !important;
      background-color: rgba(85, 57, 255, 0.03) !important;
    }
  }
`;

const StyledShadow = styled.div`
  display: flex;
  width: 100%;
  height: ${HEADER_HEIGHT}px;
  background: #dddddd;
`;

interface HeaderProps {
  setFocusedSection?: Dispatch<SetStateAction<SectionName>>;
  setIsSideMenuOpen?: Dispatch<SetStateAction<boolean>>;
  isSideMenuOpen?: boolean;
  isLoggedIn: boolean;
  isMobileMode: boolean;
  childProps: any;
}

const Header: FC<HeaderProps> = ({
  children,
  setFocusedSection = () => {},
  isSideMenuOpen = false,
  setIsSideMenuOpen = () => {},
  isLoggedIn,
  isMobileMode,
  childProps,
}) => {
  return (
    <>
      <StyledHeader isSideMenuOpen={isSideMenuOpen}>
        {!isLoggedIn && (
          <>
            <h1>
              <button onClick={() => setFocusedSection('top')}>
                <span>Second Team</span>
              </button>
            </h1>
            <button
              type="button"
              className="btn-menu"
              onClick={() => setIsSideMenuOpen(true)}
            >
              <span>메뉴 열림</span>
            </button>
            <nav id="snMenu">
              <ul>
                <li>
                  <button onClick={() => setFocusedSection('intro')}>
                    회사소개
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => {
                      setFocusedSection('partners');
                    }}
                  >
                    파트너
                  </button>
                </li>
                <li>
                  <button onClick={() => setFocusedSection('service')}>
                    서비스
                  </button>
                </li>
                <li>
                  <button onClick={() => setFocusedSection('rnd')}>
                    R&amp;D
                  </button>
                </li>
              </ul>
              <a href="https://second-team.com/" target="_blank" className="btn-uti">
                세컨팀 바로가기
              </a>
              <button
                className="btn-close"
                onClick={() => setIsSideMenuOpen(false)}
              >
                <span>메뉴 닫기</span>
              </button>
            </nav>
          </>
        )}
        {children}
        {isMobileMode && (isLoggedIn ? null : <LoginButton {...childProps} />)}
      </StyledHeader>
      <StyledShadow id="header-shadow" />
    </>
  );
};

export { Header };
export type { HeaderProps };

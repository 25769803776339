import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import { Alert } from '../components/SeesoBoard/Common';

import { Assignee } from '../components/SeesoBoard/Common/Fields';
import * as TF from '../components/SeesoBoard/TaskFilter';

import { AppStore } from '../store/AppStore';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class SelectionContainer extends Component<IProps> {
  @observable labelToDelete = '';
  @observable sprintToDelete = '';
  @observable deselectedModeAssignee = false; // 담당자 해제 선택.
  @observable deselectedModeLabel = false; // 라벨 해제 선택.
  @observable deselectedModeSprint = false; // 스프린트 해제 선택.

  get injected() {
    return this.props as InjectedProps;
  }

  @computed
  get board() {
    return this.injected.appStore.boardStore.taskBoard;
  }

  renderAssignee() {
    const { person_in_charge_list, clearPersonInCharge, togglePersonInChange } =
      this.injected.appStore.boardStore.taskMultiSelect;

    const firstAssignee = (() => {
      if (person_in_charge_list.length > 0) {
        const assigneeId = person_in_charge_list[0];

        if (this.board && assigneeId) {
          return this.board.members.find(m => m.id === assigneeId);
        }
      }
      return undefined;
    })();

    return (
      <TF.AssigneeSelector
        title={
          firstAssignee
            ? `${firstAssignee.name} ${
                person_in_charge_list.length > 1
                  ? `외 ${person_in_charge_list.length - 1}`
                  : ``
              }`
            : '담당자'
        }
      >
        <Assignee
          id={-1}
          name={'지정된 담당자 해제'}
          email={''}
          value={''}
          selected={this.deselectedModeAssignee}
          onChange={e => {
            this.deselectedModeAssignee = !this.deselectedModeAssignee;
            clearPersonInCharge();
          }}
        />
        {this.board &&
          this.board.members.map((member, i) => (
            <Assignee
              key={i}
              isDisabled={this.deselectedModeAssignee}
              id={member.id}
              email={member.email}
              name={member.name}
              value={member.id + ''}
              selected={person_in_charge_list.includes(member.id)}
              onChange={checked => {
                togglePersonInChange(member.id);
              }}
            />
          ))}
      </TF.AssigneeSelector>
    );
  }

  renderLabel() {
    const { label_title_list, toggleLabel, clearLabel } =
      this.injected.appStore.boardStore.taskMultiSelect;

    return (
      <TF.LabelSelector
        title={
          label_title_list.length > 0
            ? `${label_title_list[0]} ${
                label_title_list.length > 1
                  ? `외 ${label_title_list.length - 1}`
                  : ``
              }`
            : '라벨'
        }
        handleAdd={async (label: string) => {
          await this.onCreateLabel(label);
        }}
      >
        <TF.Label
          title={'지정된 라벨 해제'}
          value={''}
          selected={this.deselectedModeLabel}
          isSelectOnly={true}
          handleChange={e => {
            this.deselectedModeLabel = !this.deselectedModeLabel;
            clearLabel();
          }}
        />
        {this.board &&
          this.board.labelTitleList.slice().map((label, i) => (
            <TF.Label
              key={i}
              title={label}
              value={label}
              isDisabled={this.deselectedModeLabel}
              selected={label_title_list.includes(label)}
              handleChange={e => {
                toggleLabel(e.target.value);
              }}
              handleDelete={(label: string) => {
                console.log(label, 'to be deleted');
                this.labelToDelete = label;
              }}
            />
          ))}
      </TF.LabelSelector>
    );
  }

  renderSprint() {
    const { sprint_title_list, clearSprint, toggleSprint } =
      this.injected.appStore.boardStore.taskMultiSelect;

    return (
      <TF.SprintSelector
        title={
          sprint_title_list.length > 0
            ? `${sprint_title_list[0]} ${
                sprint_title_list.length > 1
                  ? `외 ${sprint_title_list.length - 1}`
                  : ``
              }`
            : '스프린트'
        }
        handleAdd={async (sprint: string) => {
          await this.onCreateSprint(sprint);
        }}
      >
        <TF.Sprint
          title={'지정된 스프린트 해제'}
          value={''}
          selected={this.deselectedModeSprint}
          isSelectOnly={true}
          handleChange={e => {
            this.deselectedModeSprint = !this.deselectedModeSprint;
            clearSprint();
          }}
        />
        {this.board &&
          this.board.sprintTitleList.slice().map((sprint, i) => (
            <TF.Sprint
              key={i}
              title={sprint}
              value={sprint}
              isDisabled={this.deselectedModeSprint}
              selected={sprint_title_list.includes(sprint)}
              handleChange={e => {
                toggleSprint(e.target.value);
              }}
              handleDelete={(sprint: string) => (this.sprintToDelete = sprint)}
            />
          ))}
      </TF.SprintSelector>
    );
  }

  renderDueDate() {
    const { duedate, setDueDate } =
      this.injected.appStore.boardStore.taskMultiSelect;

    return (
      <TF.DueDateField
        title={duedate ? moment(duedate).format('YYYY/MM/DD') : '마감일'}
        value={duedate}
        onChange={(date: string) => {
          setDueDate(moment(date).format('YYYY-MM-DD'));
        }}
      />
    );
  }

  render() {
    const { showToast, closeToase } = this.injected.appStore.uiStore;
    const { taskMultiSelect, taskBoard, fetchTaskBoard } =
      this.injected.appStore.boardStore;

    return (
      <>
        <div className="task-filter">
          <button type="button" className="btn-allcho">
            선택한 태스크 일괄 지정
          </button>

          {/* 담당자 */}
          {this.renderAssignee()}

          {/* 라벨 */}
          {this.renderLabel()}

          {/* 스프린트 */}
          {this.renderSprint()}

          {/* 마감일 */}
          {this.renderDueDate()}

          <div className="btn-area">
            <button
              type="button"
              onClick={() => {
                taskMultiSelect.clearTaskIds();
              }}
            >
              취소
            </button>
            <button
              type="button"
              onClick={async () => {
                console.log(taskMultiSelect.person_in_charge_list.slice());
                if (taskBoard) {
                  showToast(
                    true,
                    true,
                    '복수 태스크 업데이트 중입니다. ( 서버 응답속도 문제 임시알림 )',
                  );
                  await taskMultiSelect.update(taskBoard.taskBoardId);
                  await fetchTaskBoard();

                  taskMultiSelect.clearTaskIds();
                  closeToase();
                }
              }}
            >
              저장
            </button>
          </div>
        </div>

        <Alert
          isOpen={!!this.labelToDelete}
          onConfirm={async () => {
            await this.onDeleteLabel();
          }}
          onCancel={() => this.onCancelDeleteLabel()}
        >
          <p>
            선택한 라벨을 삭제하시겠습니까?
            <br />
            <br />
            모든 태스크에서도 선택한 라벨이 삭제됩니다.
          </p>
        </Alert>

        <Alert
          isOpen={!!this.sprintToDelete}
          onConfirm={async () => {
            await this.onDeleteSprint();
          }}
          onCancel={() => {
            this.onCancelDeleteSprint();
          }}
        >
          <p>
            선택한 스프린트를 삭제하시겠습니까?
            <br />
            <br />
            모든 태스크에서도 선택한 스프린트가 삭제됩니다.
          </p>
        </Alert>
      </>
    );
  }

  // /* 라벨 관련 처리 함수들 */
  private async onCreateLabel(label: string) {
    const { closeToase, showToast } = this.injected.appStore.uiStore;
    if (label.length === 0) return;

    showToast(true, true, '라벨 생성중입니다. ( 서버 응답속도 문제 임시알림 )');
    await this.board?.createLabel(label);
    await this.injected.appStore.boardStore.taskBoard?.fetchLable();
    closeToase();
  }

  private async onDeleteLabel() {
    const { closeToase, showToast } = this.injected.appStore.uiStore;

    showToast(true, true, '라벨 삭제중입니다. ( 서버 응답속도 문제 임시알림 )');

    await this.board?.deleteLabel(this.labelToDelete);
    this.labelToDelete = '';
    await this.injected.appStore.boardStore.taskBoard?.fetchLable();

    closeToase();
  }

  private onCancelDeleteLabel() {
    this.labelToDelete = '';
  }

  // /* 스프린트 관련 처리 함수들 */
  private async onCreateSprint(sprint: string) {
    const { closeToase, showToast } = this.injected.appStore.uiStore;
    if (sprint.length === 0) return;

    showToast(
      true,
      true,
      '스프린트 생성중입니다. ( 서버 응답속도 문제 임시알림 )',
    );
    await this.board?.createSprint(sprint);
    await this.injected.appStore.boardStore.taskBoard?.fetchSprint();
    closeToase();
  }

  private async onDeleteSprint() {
    const { closeToase, showToast } = this.injected.appStore.uiStore;

    showToast(
      true,
      true,
      '스프린트 삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
    );
    await this.board?.deleteSprint(this.sprintToDelete);
    this.sprintToDelete = '';
    await this.injected.appStore.boardStore.taskBoard?.fetchSprint();
    closeToase();
  }

  private onCancelDeleteSprint() {
    this.sprintToDelete = '';
  }
}

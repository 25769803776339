import { types } from 'mobx-state-tree';

/*
 * Response interface
 */
export interface IScheduleSprintResponse {
  date_start: string | null;
  date_end: string | null;
  manager: string | null;
}
export interface IScheduleResponse {
  rufree_id: string;
  rufree_name: string;
  role: string;
  sprints: IScheduleSprintResponse[];
  num_inspection_sprints: number;
  num_schedule_sprints: number;
}

/*
 * mapper
 */
export const mapSchedule = (resp: IScheduleResponse) => {
  const sprints: number[] = [];
  resp.sprints.forEach((sprint, i) => {
    if (!!sprint) {
      sprints.push(i);
    }
  });

  return {
    role: resp.role,
    rufreeId: resp.rufree_id,
    rufreeName: resp.rufree_name,
    sprintNumbers: sprints,
    inspectionSprintCount: resp.num_inspection_sprints,
  };
};

/*
 * Model
 */
export const ScheduleSprintModel = types
  .model('ScheduleSprint', {
    role: types.string,
    rufreeId: types.string,
    rufreeName: types.string,
    sprintNumbers: types.array(types.number),
    inspectionSprintCount: types.number,
  })
  .views(self => ({
    get haveScheduleDate() {
      if (self.sprintNumbers.length > 0) {
        //운영일정이 입력되지 않았다면,
        return true;
      }
      return false;
    },
    get joinedSprints() {
      if (self.sprintNumbers.length > 0) {
        if (self.sprintNumbers.length === 1) {
          return self.sprintNumbers[0] === 0
            ? 'PRE'
            : `S${self.sprintNumbers[0]}`;
        } else {
          return `${
            self.sprintNumbers[0] === 0 ? 'PRE' : `S${self.sprintNumbers[0]}`
          } - S${self.sprintNumbers[self.sprintNumbers.length - 1]}`;
        }
      }
      return '';
    },
  }));
type ScheduleSprintType = typeof ScheduleSprintModel.Type;
export interface IScheduleSprint extends ScheduleSprintType {}

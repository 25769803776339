import { Intent } from '@blueprintjs/core';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { IReceptionStore } from '../../../store/Reception';

import { IReception } from '../../../store/Reception/ReceptionModel';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import { ReceptionList as Comps } from '../../molecules/Receptions';
import ReceptionsCancelModal from './ReceptionsCancelModal';
import ReceptionsCardBox from './ReceptionsCardBox';

interface Props {
  receptions: IReception[];
  receptionStore: IReceptionStore;
  handleCardClick: (reception: IReception) => void;
  notiReceptions: any;
}

const ModalWrapper = styled.div`
  .ant-modal-header {
    background: #1effc5;
    color: #000;
  }
`;

const ReceptionsList: FC<Props> = observer(props => {
  const { receptions, receptionStore, handleCardClick, notiReceptions } = props;

  const [cancelModalVisible, setcancelModalVisible] = useState(false);
  const [currentReceptionId, setCurrentReceptionId] = useState(-1);

  const handleCancelPopup = (receptionId: number) => {
    setcancelModalVisible(!cancelModalVisible);
    setCurrentReceptionId(receptionId);
  };
  const handleRequestCancel = async (reasons: any, etcText: string) => {
    let choiceReasons: any[] = [];
    reasons.map((item: any) => {
      if (item.select) {
        return choiceReasons.push(item['key']);
      }
    });

    try {
      const error = await receptionStore.cancelReception(currentReceptionId, {
        reasons: choiceReasons,
        reasaon_text: etcText,
      });
      if (!error) {
        AppToaster.show({
          message: '의뢰가 취소되었습니다.',
          intent: Intent.SUCCESS,
        });
      } else {
        AppToaster.show({
          message: '취소에 실패하였습니다. 시소팀에 문의부탁드립니다.',
          intent: Intent.DANGER,
        });
      }
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: '취소에 실패하였습니다. 시소팀에 문의부탁드립니다.',
        intent: Intent.DANGER,
      });
    } finally {
      setModal1Visible(false);
    }
  };
  const setModal1Visible = (visable: boolean) => {
    setcancelModalVisible(false);
  };

  return (
    <Comps.ReceptionListWrapper>
      {receptions &&
        receptions.map((item: IReception, i: number) => (
          <ReceptionsCardBox
            key={i}
            reception={item}
            handleCardClick={handleCardClick}
            handleCancelPopup={handleCancelPopup}
            notiReceptions={notiReceptions}
          />
        ))}

      <ReceptionsCancelModal
        visible={cancelModalVisible}
        setModal1Visible={setModal1Visible}
        handleRequestCancel={handleRequestCancel}
      />
    </Comps.ReceptionListWrapper>
  );
});

export default ReceptionsList;

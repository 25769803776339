import { types } from 'mobx-state-tree';

export interface IWorkerResponse {
  email: string;
  name: string;
  phone: string;
}

export const WorkerModel = types
  .model('Worker', {
    name: types.string,
    phone: types.string,
    email: types.string,
  })
  .actions(self => ({
    setName(value: string) {
      self.name = value;
    },
    setPhone(value: string) {
      self.phone = value;
    },
    setEmail(value: string) {
      self.email = value;
    },
  }));

type WorkerType = typeof WorkerModel.Type;
export interface IWorker extends WorkerType {}

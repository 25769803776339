import { types } from 'mobx-state-tree';
import moment from 'moment';

import { ETypeOptions } from '../constants';

import { IDepositResponse } from './Deposit';

export const convertFromResp = (deposits: IDepositResponse[]) => {
  const calculates = deposits
    .map(data => {
      const payoutDate = moment(
        `${data.date_issue}T${moment(data.created_at).format(
          'HH:mm:ss+09:00',
        )}`,
      );
      const payout = [
        CalculateModel.create({
          type: ETypeOptions.PAYOUT,
          title: data.project_group_name
            ? `${data.project_group_name} ${
                data.sprints ? data.sprints.map(sprint => `S${sprint}`) : ''
              }`
            : '기타 입금',
          date: payoutDate.toDate() || null,
          amount: data.payout,
          balance: 0,
        }),
      ];
      const deposits = data.deposits.map(deposit =>
        CalculateModel.create({
          type: ETypeOptions.DEPOSIT,
          title: data.project_group_name
            ? `${data.project_group_name} ${
                data.sprints ? data.sprints.map(sprint => `S${sprint}`) : ''
              }`
            : '기타 입금',
          date: moment(deposit.date).toDate() || null,
          amount: deposit.payout,
          balance: 0,
        }),
      );

      return payout.concat(deposits);
    })
    .reduce((prev, curr) => prev.concat(curr), []);

  return calculates;
};

export const CalculateModel = types.model('Calculate', {
  type: types.string,
  title: types.string,
  date: types.maybeNull(types.Date),
  amount: types.number,
  balance: types.number,
});

type CalculateModelType = typeof CalculateModel.Type;
export interface ICalculate extends CalculateModelType {}

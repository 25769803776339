import { types } from 'mobx-state-tree';

export const TaskActivityModel = types
  .model('TaskActivity', {
    id: types.number,
    created_at: types.string,
    updated_at: types.string,
    task_action_type: types.string,
    message: types.string,
    task_board: types.string,
    task: types.maybeNull(types.string),
    task__id: types.number,
    task_list: types.maybeNull(types.string),
    task_list_from: types.maybeNull(types.string),
    task_list_to: types.maybeNull(types.string),
  })
  .actions(self => ({}))
  .actions(self => {
    return {};
  });
type TaskActivityType = typeof TaskActivityModel.Type;
export interface ITaskActivity extends TaskActivityType {}

import moment from 'moment';
import React, { useEffect, useState } from 'react';

type TDaysByWeek = { week: number; days: moment.Moment[] };

export interface ICalendarProps {
  style?: React.CSSProperties;
  selectedDate?: string | null;
  handleSelect: (date: string) => void;
}

export const Calendar: React.SFC<ICalendarProps> = props => {
  const today = moment({ h: 0, m: 0, s: 0, ms: 0 });
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(
    moment(props.selectedDate) || moment(),
  );

  useEffect(() => {
    setSelectedDate(moment(props.selectedDate));
  }, [props.selectedDate]);

  const getDaysByWeek = (): TDaysByWeek[] => {
    const startWeek = selectedDate.clone().startOf('month').week();
    const endWeek = selectedDate.clone().endOf('month').week();
    const end = endWeek < startWeek ? startWeek + 4 : endWeek;

    let calendar = [];
    for (let week = startWeek; week <= end; week++) {
      calendar.push({
        week: week,
        days: Array(7)
          .fill(0)
          .map((n, i) =>
            selectedDate
              .clone()
              .week(week)
              .startOf('week')
              .clone()
              .add(n + i, 'day'),
          ),
      });
    }

    return calendar;
  };

  const selectDate = (date: moment.Moment): void => {
    setSelectedDate(date);

    props.handleSelect?.(date.format('YYYY-MM-DD'));
  };

  return (
    <div className="calenda-w" style={props.style}>
      <div className="data-w">
        <strong>
          {selectedDate.year()} / {selectedDate.month() + 1}
        </strong>
        <button
          type="button"
          className="prev"
          onClick={() => {
            setSelectedDate(selectedDate.clone().add(-1, 'months'));
          }}
        >
          <span>이전 달</span>
        </button>
        <button
          type="button"
          className="next"
          onClick={() => {
            setSelectedDate(selectedDate.clone().add(1, 'months'));
          }}
        >
          <span>다음 달</span>
        </button>
      </div>
      <div className="date-cho">
        <table>
          <caption>날짜 선택 달력</caption>
          <thead>
            <tr>
              <th scope="col">일</th>
              <th scope="col">월</th>
              <th scope="col">화</th>
              <th scope="col">수</th>
              <th scope="col">목</th>
              <th scope="col">금</th>
              <th scope="col">토</th>
            </tr>
          </thead>
          <tbody>
            {getDaysByWeek().map(week => (
              <tr key={week.week}>
                {week.days.map(day => {
                  const isSelected =
                    moment(props.selectedDate).format('YYYYMMDD') ===
                    day.format('YYYYMMDD');
                  const isToday = day.isSame(today);
                  const d = day.date();

                  // console.log(day.format('YYYYMMDD'), isSelected)

                  if (day.month() === selectedDate.month()) {
                    return (
                      <td
                        key={day.format('YYYYMMDD')}
                        className={`${isSelected ? 'selected' : ''}`}
                      >
                        <input
                          type="radio"
                          name="mCho"
                          id={`m${d}`}
                          defaultChecked={isToday}
                          onClick={() => selectDate(day.clone())}
                        />
                        <label htmlFor={`m${d}`}>{d}</label>
                      </td>
                    );
                  } else {
                    return <td key={d} />;
                  }
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

import React, { useState } from 'react';

import useVisible from '../Common/hooks/useVisible';

export interface ISprintProps {
  title: string;
  value: string;
  selected: boolean;
  isDisabled?: boolean;
  isSelectOnly?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete?: (sprint: string) => void;
}
export interface ISprintSelectorProps {
  title: string;
  isSelectOnly?: boolean;
  handleAdd?: (label: string) => void;
}

export const Sprint: React.SFC<ISprintProps> = props => (
  <li>
    <input
      type="checkbox"
      id={`sp_${props.value}`}
      value={props.value}
      checked={props.selected}
      disabled={props.isDisabled}
      onChange={props.handleChange}
    />
    <label htmlFor={`sp_${props.value}`}>{props.title}</label>

    {!props.isSelectOnly && (
      <button
        type="button"
        className="btn-delete"
        onClick={() => {
          props.handleDelete && props.handleDelete(props.title);
        }}
      >
        <span>라벨삭제</span>
      </button>
    )}
  </li>
);

export const SprintSelector: React.SFC<ISprintSelectorProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const [newSprint, setNewSprint] = useState<string>('');

  let className = 'select-w';
  className += isVisible ? ' selected' : '';

  return (
    <div className={className} ref={ref}>
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {props.title}
      </button>
      <div className="option-box">
        {!props.isSelectOnly && (
          <fieldset className="add">
            <legend>
              <label htmlFor="laAdd">라벨추가</label>
            </legend>
            <input
              type="text"
              id="laAdd"
              placeholder="추가"
              value={newSprint}
              onChange={e => {
                setNewSprint(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  props.handleAdd && newSprint && props.handleAdd(newSprint);
                  setNewSprint('');
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                props.handleAdd && newSprint && props.handleAdd(newSprint);
                setNewSprint('');
              }}
            >
              <span>추가</span>
            </button>
          </fieldset>
        )}

        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

import { types } from 'mobx-state-tree';

export const TIP_NOTION_URL =
  'https://www.notion.so/seesorufree/seeso-Tip-e0d22504f3994937921c0f9beabb0c74';

export const ManagingTipModel = types
  .model('ManagingTip', {
    id: types.maybe(types.string),
    category: types.maybe(types.string),
    subject: types.maybe(types.string),
    roles: types.array(types.string),
    url: types.maybe(types.string),
    thumnailUrl: types.maybe(types.string),
    tags: types.array(types.string),
    date: types.maybe(types.Date),
  })
  .views(self => ({}))
  .actions(self => ({}));
type ManagingTipModelType = typeof ManagingTipModel.Type;
export interface IManagingTip extends ManagingTipModelType {}

import React, { useState } from 'react';

import { DraggableProvided } from 'react-beautiful-dnd';
import { AddSubChecklist } from './AddSubChecklist';

export interface IChecklistProps {
  id: number;
  title: string;
  isEditable: boolean;
  dndProvided: DraggableProvided;

  onAddSub: (title: string) => void;
  onChangeTitle: (title: string) => void;
  onDelete: () => void;
}

export const Checklist: React.SFC<IChecklistProps> = props => {
  const [isEditing, setIsEditing] = useState<boolean>(
    props.id < 0 ? true : false,
  );
  const [title, setTitle] = useState<string>(props.title);
  const [showAddSub, setShowAddSub] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const title = (e.target as HTMLInputElement).value;
      props.onChangeTitle(title);
      setTitle(title);
      setIsEditing(false);
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChangeTitle((e.target as HTMLInputElement).value);
    setIsEditing(false);
  };

  return (
    <div
      className={`list-w ${!props.isEditable ? 'disb' : ''}`}
      ref={props.dndProvided.innerRef}
      {...props.dndProvided.draggableProps}
      {...props.dndProvided.dragHandleProps}
    >
      <div className={`tit ${isEditing ? 'edit' : ''}`}>
        <h3 {...props.dndProvided?.dragHandleProps}>
          {props.isEditable && (
            <button type="button" className="btn-move">
              <span>메뉴이동</span>
            </button>
          )}

          {props.title}
        </h3>
        {isEditing && (
          <input
            autoFocus={true}
            type="text"
            value={title}
            title="체크리스트 타이틀 수정"
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onBlur={onBlur}
          />
        )}

        {props.isEditable && (
          <span className="btn-md">
            <button
              type="button"
              className="modify"
              onClick={() => setIsEditing(true)}
            >
              <span>수정</span>
            </button>
            <button type="button" className="delete" onClick={props.onDelete}>
              <span>삭제</span>
            </button>
          </span>
        )}
      </div>

      <ul>
        {props.children}

        {showAddSub && (
          <AddSubChecklist
            onAdd={async (title: string) => {
              props.onAddSub(title);
              setShowAddSub(false);
            }}
            onCancel={() => {
              setShowAddSub(false);
            }}
          />
        )}
      </ul>

      {props.isEditable && !showAddSub && (
        <button
          type="button"
          className="btn-item-add"
          onClick={() => setShowAddSub(true)}
        >
          아이템 추가
        </button>
      )}
    </div>
  );
};

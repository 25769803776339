import { types } from 'mobx-state-tree';

export interface ClientRoleResponse {
  role: string;
  sprints: boolean[];
}

export const ClientRoleModel = types
  .model('ClientRole', {
    role: types.string,
    sprints: types.array(types.boolean),
  })
  .views(self => ({
    get totalSprintDays() {
      return self.sprints.reduce((sum, sprint) => (sprint ? sum + 1 : sum), 0);
    },
  }));

type ClientRoleType = typeof ClientRoleModel.Type;
export interface ClientRole extends ClientRoleType {}

import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import {
  Draggable,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { AddBulletin } from '../components/SeesoBoard/Bulletin/AddBulletin';
import { Bulletin } from '../components/SeesoBoard/Bulletin/Bulletin';
import { Post } from '../components/SeesoBoard/Bulletin/Post';

import { Alert } from '../components/SeesoBoard/Common';

import { AppStore } from '../store/AppStore';

import BulletinPostDetailContainer from './BulletinPostDetailContainer';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class BulletinContainer extends Component<IProps> {
  // @observable showAddBulletin: boolean = false;
  state = {
    showAddBulletin: false,
    mode: 'read',
  };

  get appStore() {
    return (this.props as InjectedProps).appStore;
  }

  async componentDidMount() {}

  render() {
    const { email } = this.appStore.userStore;
    const { isShowBulletin, toggleBulletin } = this.appStore.uiStore;
    const {
      taskBoard,
      currentBulletinPostId,
      currentBulletinId,
      sortedBulletins: bulletins,

      deleteTaskBulletin,
      fetchTaskBulletin,
      fetchTaskBulletinByID,
      postTaskBulletin,

      setCurrentBulletinId,
      setCurrentBulletinPostId,
    } = this.appStore.boardStore;

    return (
      <aside id="snMenu">
        <div className={`inner ${isShowBulletin ? '' : 'li-close'}`}>
          <h3>자주 보는 리스트</h3>
          <button
            type="button"
            className="btn-add"
            onClick={() => {
              const { showAddBulletin } = this.state;
              this.setState({
                showAddBulletin: !showAddBulletin,
              });
            }}
          >
            리스트 추가
          </button>

          <nav>
            <Droppable
              droppableId="bulletin"
              type="BULLETIN"
              isCombineEnabled={true}
            >
              {(provided: DroppableProvided) => (
                <ul ref={provided.innerRef} {...provided.droppableProps}>
                  {this.state.showAddBulletin && (
                    <AddBulletin
                      handleAdd={async (title: string) => {
                        if (taskBoard) {
                          await postTaskBulletin(taskBoard.taskBoardId, title);
                          await fetchTaskBulletin(taskBoard.projectGroup);
                        }
                        this.setState({
                          showAddBulletin: false,
                        });
                      }}
                      handleCancel={() => {
                        this.setState({
                          showAddBulletin: false,
                        });
                      }}
                    />
                  )}

                  {bulletins.map(bulletin => (
                    <Draggable
                      key={bulletin.task_bulletin_id}
                      draggableId={`bb-${bulletin.id}`}
                      index={bulletin.order}
                    >
                      {(provided, snapshot) => (
                        <Bulletin
                          key={bulletin.id}
                          id={bulletin.id}
                          title={bulletin.title}
                          handleChangeTitle={(title: string) =>
                            bulletin.setTitle(title)
                          }
                          handleDelete={() => {
                            this.setState({ mode: 'delete' });
                            setCurrentBulletinId(bulletin.task_bulletin_id);
                            setCurrentBulletinPostId('');
                          }}
                          handleAddPost={async (title: string) => {
                            if (taskBoard) {
                              this.appStore.uiStore.showToast(
                                true,
                                true,
                                '자주보는 리스트 추가중입니다. ( 서버 응답속도 문제 임시알림 )',
                              );
                              await bulletin.addPost(title, email);
                              await fetchTaskBulletinByID(bulletin.id);
                              this.appStore.uiStore.closeToase();
                            }
                          }}
                          dndProvided={provided}
                        >
                          <Droppable
                            key={`droppable${bulletin.task_bulletin_id}`}
                            droppableId={`bb-${bulletin.id}`}
                            type="POST"
                          >
                            {(
                              providedPost: DroppableProvided,
                              snapshotPost: DroppableStateSnapshot,
                            ) => (
                              <ul
                                {...providedPost.droppableProps}
                                ref={providedPost.innerRef}
                              >
                                {bulletin.sortedTaskBulletinPosts.map(
                                  (post, j) => (
                                    <Draggable
                                      key={post.task_bulletin_post_id}
                                      draggableId={`bp-${post.id}`}
                                      index={j}
                                    >
                                      {(providedPost, snapshotPost) => (
                                        <Post
                                          key={post.id}
                                          id={post.id}
                                          title={post.title}
                                          innerRef={providedPost.innerRef}
                                          draggableProps={
                                            providedPost.draggableProps
                                          }
                                          dragHandleProps={
                                            providedPost.dragHandleProps
                                          }
                                          handleChangeTitle={async (
                                            title: string,
                                          ) => {
                                            if (taskBoard) {
                                              await post.setTitle(title);
                                              await fetchTaskBulletinByID(
                                                bulletin.id,
                                              );
                                            }
                                          }}
                                          handleClick={() => {
                                            setCurrentBulletinId(
                                              bulletin.task_bulletin_id,
                                            );
                                            setCurrentBulletinPostId(
                                              post.task_bulletin_post_id,
                                            );
                                          }}
                                          handleDelete={async () => {
                                            this.setState({ mode: 'delete' });
                                            setCurrentBulletinId(
                                              bulletin.task_bulletin_id,
                                            );
                                            setCurrentBulletinPostId(
                                              post.task_bulletin_post_id,
                                            );
                                          }}
                                        />
                                      )}
                                    </Draggable>
                                  ),
                                )}

                                {providedPost.placeholder}
                              </ul>
                            )}
                          </Droppable>
                        </Bulletin>
                      )}
                    </Draggable>
                  ))}

                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </nav>
        </div>

        <button type="button" className="btn-close" onClick={toggleBulletin}>
          <span>자주 보는 리스트 닫아두기</span>
        </button>

        {this.state.mode === 'read' && currentBulletinPostId && (
          <BulletinPostDetailContainer />
        )}

        <Alert
          isOpen={
            this.state.mode === 'delete' &&
            !!currentBulletinId &&
            !currentBulletinPostId
          }
          onConfirm={async () => {
            const bulletin = bulletins.find(
              bulletin => bulletin.task_bulletin_id === currentBulletinId,
            );

            if (!taskBoard || !bulletin) return;
            this.appStore.uiStore.showToast(
              true,
              true,
              '자주 보는 리스트를 삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
            );
            await deleteTaskBulletin(bulletin.id);
            await fetchTaskBulletin(taskBoard.projectGroup);
            this.appStore.uiStore.closeToase();

            this.setState({ mode: 'read' });
            setCurrentBulletinId('');
            setCurrentBulletinPostId('');
          }}
          onCancel={() => {
            this.setState({ mode: 'read' });
            setCurrentBulletinId('');
            setCurrentBulletinPostId('');
          }}
        >
          <p>
            선택한 자주 보는 리스트를
            <br /> 삭제하시겠습니까?
            <br />
          </p>
        </Alert>

        <Alert
          isOpen={
            this.state.mode === 'delete' &&
            !!currentBulletinId &&
            !!currentBulletinPostId
          }
          onConfirm={async () => {
            const bulletin = bulletins.find(
              bulletin => bulletin.task_bulletin_id === currentBulletinId,
            );
            const post =
              bulletin &&
              bulletin.task_bulletin_posts.find(
                post => post.task_bulletin_post_id === currentBulletinPostId,
              );

            if (!taskBoard || !bulletin || !post) return;
            this.appStore.uiStore.showToast(
              true,
              true,
              '자주보는 가삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
            );
            await bulletin.removePost(post.id);
            await fetchTaskBulletin(taskBoard.projectGroup);

            this.appStore.uiStore.closeToase();

            this.setState({ mode: 'read' });
            setCurrentBulletinId('');
            setCurrentBulletinPostId('');
          }}
          onCancel={() => {
            this.setState({ mode: 'read' });
            setCurrentBulletinId('');
            setCurrentBulletinPostId('');
          }}
        >
          <p>
            선택한 아이템을
            <br /> 삭제하시겠습니까?
            <br />
          </p>
        </Alert>
      </aside>
    );
  }
}

import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';
import { OptionWrapper, UlWrapper } from '../Calculate/PayoutFilter';

// 공통으로 사용되고 있는 곳
export const ReceptionCheckBox = styled.div``;

export const ReceptionWrapper = styled.div`
  // font-family: "AppleSDGothicNeoEB", "Roboto", "Droid Sans", "Malgun Gothic", "Helvetica", "Apple-Gothic", "\C560\D50C\ACE0\B515", "Tahoma", dotum, "\B3CB\C6C0", gulim, "\AD74\B9BC", sans-serif;
  width: 1220px;
  margin: 0 auto;
  padding-top: 54px;
  > p {
    position: relative;
    padding-top: 25px;
    color: #6f6f6f;
    font-size: 15px;
    word-break: keep-all;
  }
  h2 {
    margin-top: 0px;
    padding-top: 0px;
    font-weight: bold;
    @media only screen and (max-width: 640px) {
      padding-top: 0px;
    }
  }
  @media only screen and (max-width: ${PC}) {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px;
    padding-top: 35px;
    h1 {
      line-height: 1.3;
    }
  }
  ul {
    margin: 0;
    padding-left: 0;
    list-style: none outside;
  }

  input {
    padding-left: 5px;
  }
  input[type='checkbox'] {
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }
  input[type='checkbox']:checked + label:after {
    top: 8px;
    left: 8px;
    border-color: #fff;
    border-width: 2px;
    width: 14px;
    height: 8px;
  }
  input[type='checkbox'] + label {
    display: inline-block;
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
    margin-top: 5px;
    color: #000;
    font-size: 18px;
  }
  input[type='checkbox'] + label:after {
    top: 8px;
    left: 8px;
  }
  input[type='checkbox'] + label:before {
    display: block;
    position: absolute;
    content: '';
    width: 30px;
    height: 30px;
    background: #dce0e0;
    border: none;
    border-radius: 16px;
    left: 0;
    top: 0;
    font-size: 12px;
  }
  input[type='checkbox'] + label:after {
    display: block;
    content: '';
    width: 14px;
    height: 8px;
    position: absolute;
    top: 9px;
    left: 8px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
  }
  input[type='text'] {
    height: 42px;
    width: 425px;
    padding: 0px;
    border-radius: 0;
    height: 55px;
    padding-left: 5px;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    background: none;
    color: #2b2b2b;
    font-size: 18px;
    outline: 0 none;
    @media only screen and (max-width: ${PC}) {
      width: 100%;
    }
  }
  input[type='text'][disabled] {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
  }
  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  .ant-form-item-label {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
    position: relative;
  }
  .ant-row {
    position: relative;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    zoom: 1;
    display: block;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 650px) {
    ${OptionWrapper}, ${UlWrapper} {
      width: 132px;
    }
  }
`;
export const startFormWrap = styled.form`
  padding: 91px 0px 225px 228px;
  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 60px 0px 30px 0;
  }
`;
export const startDefaultTypeWrap = styled.div`
  h2 {
    padding-top: 123px;
    word-break: keep-all;
  }
`;

export const startServiceTypeWrap = styled.div`
  .titleDescription {
    font-size: 14px;
    line-height: 1.43;
  }
  .startServiceBoxDescription {
    position: absolute;
    width: 100%;
    bottom: -28px;
    text-align: right;
    left: 0px;
    a {
      color: #4b34ff;
      font-size: 12px;
      &:hover {
        color: #6af8c7;
      }
    }
  }
`;

export const startServiceBoxWrap = styled.div`
  width: 100%;
  margin-top: 75px;
`;
export const startServiceBox = styled.div`
    position: relative;
    display: inline-block;
    border: solid 2px #393c4c;
    width: 320px;
    border-radius: 20px;
    padding: 18px 30px 18px 50px;
    cursor: pointer;
    &:first-child {
      margin-right: 80px;
    }
    h3 {
      position: absolute;
      display: block;
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      top: -32px;
      left: 26px;
    }
    @media only screen and (max-width: 640px) {
      margin: 0 auto;
      margin-bottom: 70px;
      width: 100%;
      &:first-child {
        margin-right: auto;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  &.activeBox {
    border-color: #4b34ff;
  }
  &.selectedBox {
    border-color: #4b34ff;
    background-color: #f3f2ff;
    h3 {
      color: #4b34ff !important;
    }
  }
  &.error {
    border-color: #ff4343;
  }

  ul {
    li {
      font-size: 14px;
      margin-bottom: 15px;
      font-weight: 500;
      white-space: nowrap;
      &:before {
        content: "✔️";
        position: absolute;
        color: #000;
        left: 26px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
export const startUserFormWrap = styled.div`
  h2 {
    padding-top: 123px;
    margin-bottom: 50px;
  }
`;

export const startInputPj = styled.div`
  h2 {
    padding-top: 123px;
  }
  textarea {
    width: 100%;
    height: 54px;
    font-size: 18px;
    text-align: left;
    color: #2b2b2b;
    border: none;
    line-height: 140%;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  }
  .textareaBox {
    padding-top: 50px;
    max-width: 916px;
  }
`;

export const startDefaultRadioWrap = styled.div`
  h2 {
    padding-top: 123px;
  }
  .choiceSingleProject {
    position: relative;
    > label {
      position: relative;
      display: block;
      padding-top: 20px;
      font-size: 15px;
      margin-top: 60px;
      > span:nth-child(2) {
        font-size: 18px;
        margin-top: 3px;
        padding-top: 5px;
        padding-left: 40px;
        color: #000;
        position: absolute;
        margin-top: -33px;
        line-height: 1.5;
        @media only screen and (max-width: 640px) {
          display: inline-block;
          word-break: keep-all;
          white-space: normal;
          position: relative;
          padding-left: 0px;
          top: 8px;
          left: 40px;
          width: 90%;
        }
      }
      .inlineSelect {
        display: block;
        @media only screen and (max-width: 640px) {
          width: 90%;
          position: inherit;
          display: inline-block;
          top: 14px;
        }
      }
      @media only screen and (max-width: 640px) {
        padding-top: 0px;
        margin-top: 40px;
      }
    }
    input[type='radio'] {
      visibility: hidden;
      position: absolute;
      box-sizing: border-box;
      padding: 0;
      opacity: 0;
    }
    input[type='radio'] + span {
      border: none;
      width: 0px;
      height: 0px;
    }
    input[type='radio'] + span:before {
      display: block;
      position: absolute;
      content: '';
      width: 30px;
      height: 30px;
      background: #dce0e0;
      border: none;
      border-radius: 16px;
      left: 0;
      top: 0;
      font-size: 12px;
      top: 0px;
    }
    input[type='radio'] + span:after {
      display: block;
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 7px;

      left: 8px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
      opacity: 1;
      border-radius: 0px;
    }
    input[type='radio']:checked + span:before {
      background: #5834ff;
    }
  }
`;

export const startH2title = styled.h2`
  h2 {
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2b2b2b;
    word-break: keep-all;
  }
`;

export const choiceProject = styled.div`
  padding-top: 30px;
  list-style: none outside;

  li {
    position: relative;
    padding-top: 20px;
    font-size: 18px;
    span {
      padding-left: 5px;
      font-size: 15px;
    }
    input[type='checkbox'] {
      &:checked + label:before {
        background: #5834ff;
      }
    }
    label {
      font-size: 18px;
      color: #000;
    }
  }
`;

export const startStyleSltDesWrap = styled.div`
  h2 {
    padding-top: 123px;
    margin-bottom: 16px;
  }
  .titleDescription {
    font-size: 15px;
    color: #6f6f6f;
  }
  .projectBox {
    width: 680px;
    padding-top: 3px;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    p {
      position: relative;
      height: 39px;
      margin-top: 10px;
      padding-left: 16px;
      padding-right: 16px;
      background: #f7f7f7;
      border: 1px solid #dce0e0;
      border-radius: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      a {
        display: inline-block;
        height: 100%;
        color: #6f6f6f;
        font-size: 13px;
        line-height: 39px;
      }
      button {
        position: absolute;
        top: 4px;
        right: 5px;
        width: 25px;
        height: 25px;
        font-size: 0;
        background: transparent;
        border: none;
        cursor: pointer;
      }
      button:before,
      button:after {
        content: '';
        display: block;
        position: absolute;
        top: 8px;
        left: 13px;
        width: 1px;
        height: 15px;
        -webkit-transform: rotate(-315deg);
        transform: rotate(-315deg);
        background-color: #979797;
      }
      button:after {
        width: 15px;
        height: 1px;
        top: 15px;
        left: 6px;
      }
    }
  }
`;
export const fileBox = styled.div`
  .fileWrap {
    display: inline-block;
    padding-top: 40px;
    label {
      display: inline-block;
      height: 40px;
      padding: 0 18px;
      border-radius: 20px;
      background-color: #5834ff;
      color: #fff;
      font-size: 18px;
      line-height: 40px;
      cursor: pointer;
    }
    label.deactiveBtn {
      background-color: #dce0e0;
      cursor: not-allowed;
    }
  }
  span {
    padding: 0 29px;
    font-size: 13px;
    @media only screen and (max-width: 640px) {
      display: none;
    }
  }
  p {
    position: relative;
    display: inline-block;
    width: 75%;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
    input[type='text'] {
      width: 100%;
      height: 40px;
      padding: 0;
      @media only screen and (max-width: 640px) {
        margin-top: 20px;
      }
    }
    button {
      position: absolute;
      right: 0;
      height: 32px;
      padding: 0 15px;
      border-radius: 16px;
      background-color: #dce0e0;
      color: #fff;
      font-size: 13px;
      line-height: 32px;
      border: none;
      top: 0px;

      @media only screen and (max-width: 640px) {
        top: 20px;
      }
    }
    button.actv {
      background-color: #5834ff;
      cursor: pointer;
    }
  }
`;

export const pInput = styled.li`
  label {
    position: absolute;
    color: rgba(43,43,43,0.5);
    line-height: 55px;
  }
  input {
    width: 600px;  
    @media only screen and (max-width: 640px) { 
      width: 100%;
    }

  }
  .textareaBox {
    padding-top: 50px;
    max-width: 916px;
    textarea {
    width: 100%;
    height: 54px;
    font-size: 18px;
    text-align: left;
    color: #2b2b2b;
    border: none;
    line-height: 140%;
    background: transparent;
    border-bottom: 1px solid rgba(0,0,0,0.4);
      &:focus  {
        outline: none;
        border-bottom: 1px solid #6f6f6f;
      }
    }  
  }
}`;

export const btnToolBar = styled.div`
  width: 100%;
  bottom: 0;
  right: 0;
  height: 100px;
  line-height: 56px;
  -webkit-box-shadow: 0 -1px 2px rgba(0,0,0,.03);
  box-shadow: 0 -1px 2px rgba(0,0,0,.03);
  background: #fff;
  border-top: 1px solid #e8e8e8;
  padding: 0 24px;
  z-index: 9;
  position: fixed;
  @media only screen and (max-width: 640px) {
    height: 68px;
    padding: 0 15px;
  }
    @media only screen and (max-width: 640px) {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding: 25px 0px;
      padding-top: 5px;
    } 
  }`;

export const btnWrap = styled.div`
  margin-left: 328px;
  padding: 25px;
  margin-right: 100px;
  
  button {
    cursor: pointer;
    float: right;
    display: inline-block;
    position: relative;
    height: 52px;
    padding: 0 45px;
    background-color: #5834ff;
    color: #fff;
    font-size: 15px;
    line-height: 52px;
    box-sizing: border-box;
    vertical-align: middle;
    border: none;
    font-weight: bold;
    @media only screen and (max-width: 640px) { 
      width: 90px;
      height: 36px;
      line-height: 36px;
      padding: 0 16px;
      font-size: 13px;
      bottom: 15px;
  }
`;

export const startPjFormWrap = styled.div`
  margin-bottom: 60px;
  label {
    font-size: 13px;
    color: rgba(43, 43, 43, 0.5);
  }
  label:after {
    content: '';
  }
  input:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .startPjFormItemLayout {
    margin-bottom: 60px;
  }
  input[type='text'] {
    height: 42px;
    width: 425px;
    @media only screen and (max-width: 640px) {
      width: 100%;
    }
  }
  input[type='text'].affiliationInput {
    @media only screen and (max-width: 640px) {
      width: 60%;
    }
  }
  .startMeetingLayout {
    .titleDetail {
      margin-top: 10px;
      font-size: 15px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #6f6f6f;
    }

    .startMeetingSelect {
      // display: block;

      > div {
        width: 20%;
        margin-left: 30px;
        display: inline-block;
        @media only screen and (max-width: 640px) {
          width: 35%;
          margin-left: 15px;
        }
        > div {
          border: none;
          height: 36px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.4);
          font-size: 18px;
          border-radius: unset;
          > div {
            margin-left: 0px;
          }
        }
      }
    }
  }
`;

export const startMeetingSpan = styled.span`
  // width: 30%;
  // display: inline-block;
  .ant-picker {
    border: none;
    padding: 0px;
  }
  input {
    height: 42px;
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: unset;
    font-size: 18px;
    padding-left: 0px;
  }
  > div {
    width: 30%;
    @media only screen and (max-width: 640px) { 
      width: 60%;
    }  
`;

export const SuccessWrapper = styled.div`
  width: 1032px;
  margin: 0 auto;
  padding-top: 80px;
  @media only screen and (max-width: ${PC}) {
    width: 100%;
    padding: 0 15px;
    padding-top: 70px;
  }
  > p {
    position: relative;
    padding-top: 25px;
    padding-bottom: 80px;
    color: #6f6f6f;
    font-size: 15px;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: ${PC}) {
      padding-bottom: 40px;
      word-break: keep-all;
    }
  }
`;

export const startSuccessPage = styled.div`
  width: 1032px;
  margin: 0 auto;
  padding-top: 80px;
  @media only screen and (max-width: 640px) {
    width: 100%;
    padding: 0 15px;
    padding-top: 70px;
  }
  > p {
    position: relative;
    padding-top: 25px;
    padding-bottom: 80px;
    color: #6f6f6f;
    font-size: 15px;
    border-bottom: 1px solid;
    border-color: rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 640px) {
      padding-bottom: 40px;
      word-break: keep-all;
    }
  }
  .rufreeInfo {
    padding-top: 73px;
    padding-bottom: 65px;
    h1 {
      font-size: 30px;
      font-family: 'notoSB';
      .inlineType {
        display: block;
        font-size: 13px;
        color: #6644ff;
        > span {
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
    h1 + p {
      font-size: 17px;
      color: #6f6f6f;
      line-height: 1.53;
    }
    @media only screen and (max-width: 640px) {
      padding-top: 40px;
      padding-bottom: 50px;
      word-break: keep-all;
    }
  }
  .rufreesSelectRow {
    width: 100%;
    margin: 0 auto;
    margin-bottom: 200px;
    .rufreeSelectBox {
      min-width: 296px;
      max-width: 296px;
      // min-height: 364px;
      border: solid 1px #dce0e0;
      display: inline-block;
      margin-bottom: 5%;
      .rufreeCheckbox {
        position: relative;
        input {
          position: absolute;
        }
        input[type='checkbox']:checked::parent {
          background: #333;
        }
        label {
          right: -27px;
          position: absolute;
          top: -20px;
        }
        label:before {
          background-color: #ffffff;
          border: solid 1px #dce0e0;
        }
        label:after {
          border-color: #dce0e0;
        }
        input[type='checkbox']:checked + label:before {
          background: #23edb9;
          border-color: #23edb9;
        }
        input[type='checkbox']:checked + label:after {
          border-color: #fff;
        }
      }
      @media only screen and (max-width: 640px) {
        min-width: 96.5%;
        max-width: 96.5%;
        margin-bottom: 50px;
      }
    }
    .rufreeSelectBox:nth-child(2) {
      margin-left: 6.8%;
      margin-right: 6.8%;
      @media only screen and (max-width: 640px) {
        margin: 0px 0px 50px;
      }
    }
    .rufreeSelectBox.active {
      border: solid 2px #23edb9;
    }

    .detailRow {
      border-bottom: 1px solid #dce0e0;
      .detailPrice {
        border-right: 1px solid #dce0e0;
      }
      .detailSchedule {
        width: 100%;
        padding: 31px 0 28px 0;
        display: inline-block;
        text-align: center;
        line-height: 1;
        span {
          font-size: 22px;
          color: #979797;
          word-break: keep-all;
        }
        strong {
          font-size: 20px;
          color: #333333;
        }
      }
    }
    .userNormalRow {
      padding: 19px 16px;
      font-size: 13px;
      color: #333;
      border-bottom: 1px solid #dce0e0;
    }
    .userCommentRow {
      border: none;
      .commentsCount {
        position: relative;
        color: #979797;
        float: right;
        font-size: 13px;
        padding-right: 8px;
        padding-top: 1px;
        &.active {
          cursor: pointer;
        }
      }
    }
    @media only screen and (max-width: 640px) {
      margin-bottom: 50px;
    }
  }
`;
export const LoadingWrapper = styled.div`
  background: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 99;
`;

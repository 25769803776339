import { flow, types } from 'mobx-state-tree';
import { getSprintReport } from './Dashboard/apis/sprint';
// import axios from "axios";
import { SprintReport } from './Dashboard/models';

export const SprintReportStoreModel = types
  .model('SprintReportStore', {
    report: types.maybeNull(SprintReport.SprintReportModel),
    reports: types.array(SprintReport.SprintReportModel),
  })
  .views(self => ({}))
  .actions(self => ({
    getBySprint(sprint: number) {
      const finded = self.reports.find(report => report.sprint === sprint);
      if (finded) {
        return finded.reports;
      }
      return [];
    },
  }))
  .actions(self => {
    const fetchByProjectGroup = flow(function* (projectGroup: string) {
      try {
        const data = yield getSprintReport(projectGroup);

        self.reports.replace(SprintReport.mapper(data));
      } catch (e) {
        throw e;
      }
    });

    return {
      fetchByProjectGroup,
    };
  });

type SprintReportStoreModelType = typeof SprintReportStoreModel.Type;
export interface ISprintReportStore extends SprintReportStoreModelType {}

import { observable } from 'mobx';
import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

const Wrapper = styled.div`
  width: 446px;
  height: 55px;

  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: 78px;
  }
`;

const TextInputRequired = styled.div`
  padding-top: 3px;
  padding-left: 3px;
  width: 9px;
  height: 18px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    padding-top: 5px;
    padding-left: 0px;
    width: 4px;
    height: 15px;
    font-size: 10px;
    font-weight: 500;
  }
`;
const TextInputLabel = styled.div`
  padding-top: 2px;
  padding-left: 1px;
  width: 128px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['black']};

  @media only screen and (max-width: ${PC}) {
    padding-top: 3px;
    padding-left: 2px;
    width: 128px;
    height: 20px;
    font-size: 14px;
  }
`;
const TextInputContainer = styled.div`
  width: 305px;
  height: 55px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    width: 100%;
  }
`;
const TextInputDiv = styled.div`
  padding-left: 29px;

  @media only screen and (max-width: ${PC}) {
    padding-left: 6px;
  }
`;
const TextInputComponent = styled.input`
  width: 276px;
  height: 24px;
  border: 0;
  background-color: transparent;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  color: ${PALETTE['black']};

  padding: 0; // 모바일 사파리 대응

  &:focus {
    outline: none;
  }

  :disabled {
    background-color: ${PALETTE['very-light-pink']};
  }

  ::placeholder {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${PALETTE['color-pale-purple']};
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: 20px;
    font-size: 14px;
    padding-left: 4px;

    :disabled {
      height: 28px;
      font-size: 14px;
    }
  }
`;
const TextInputUnderline = styled.div<{ color?: string }>`
  margin-top: 4px;
  margin-left: 21px;
  width: 284px;
  height: 1px;
  background-color: ${(props: any) =>
    props.color ? props.color : PALETTE['greyish-brown']};

  @media only screen and (max-width: ${PC}) {
    margin-top: 1px;
    margin-left: 8px;
    width: calc(100% - 16px);
  }
`;
const TextInputError = styled.div`
  margin-top: 3px;
  margin-left: 23px;
  margin-right: 4px;

  width: 278px;
  height: 18px;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${PALETTE['color-red']};

  @media only screen and (max-width: ${PC}) {
    margin-top: 0px;
    margin-left: 8px;
    margin-right: 8px;

    width: calc(100% - 16px);
  }
`;

interface TextInputProps {
  required: boolean;
  disabled: boolean;
  readonly: boolean;
  label: string;
  placeholder: string;
  textValue: string;
  onChange: (newValue: string) => void;
  validateMessage?: string;
}

class TextInput extends Component<TextInputProps> {
  @observable hasFocus: boolean = false;

  static defaultProps = {
    required: false,
    disabled: false,
    readonly: false,
    placeholder: null,
  };

  handleFocusChanged = (value: boolean) => {
    this.hasFocus = value;
    this.forceUpdate();
  };

  handleTextValueChanged = (e: any) => {
    if (this.props.onChange) {
      this.props.onChange(e.currentTarget.value);
    }
  };

  render() {
    let underlineColor = '';

    if (this.hasFocus) {
      underlineColor = PALETTE['purpleish-blue'];
    }
    if (this.props.validateMessage) {
      underlineColor = PALETTE['color-red'];
    }
    if (this.props.readonly) {
      underlineColor = PALETTE['greyish-brown'];
    }

    return (
      <Wrapper>
        <TextInputRequired>{this.props.required ? '*' : ' '}</TextInputRequired>
        <TextInputLabel>{this.props.label}</TextInputLabel>
        <TextInputContainer>
          <TextInputDiv>
            <TextInputComponent
              onFocus={() => {
                this.handleFocusChanged(true);
              }}
              onBlur={() => {
                this.handleFocusChanged(false);
              }}
              onChange={this.handleTextValueChanged}
              placeholder={this.props.placeholder ? this.props.placeholder : ''}
              disabled={this.props.disabled}
              readOnly={this.props.readonly}
              value={this.props.textValue}
            />
          </TextInputDiv>
          {!this.props.disabled && (
            <>
              <TextInputUnderline color={underlineColor} />
              {this.props.validateMessage && !this.props.readonly && (
                <TextInputError>{this.props.validateMessage}</TextInputError>
              )}
            </>
          )}
        </TextInputContainer>
      </Wrapper>
    );
  }
}

export default TextInput;

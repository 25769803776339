import moment from 'moment';
import React, { useState } from 'react';

import { Alert, Calendar } from '../Common';
import useVisible from '../Common/hooks/useVisible';

export interface IDueDateProps {
  isArchived?: boolean;
  dueDate?: string | null;
  onChange: (date: string | null) => void;
}

export const DueDate: React.SFC<IDueDateProps> = props => {
  const [showResetAlert, setShowResetAlert] = useState(false);
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const isDuDateOver = moment(props.dueDate).isBefore(moment());

  return (
    <li>
      <div className={`select-w ${isVisible ? 'selected' : ''}`} ref={ref}>
        {/*<!-- 하위메뉴 열림 "slelected" className 추가 -->*/}
        <button
          type="button"
          disabled={props.isArchived ? true : false}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          마감일
        </button>

        <Calendar
          selectedDate={props.dueDate}
          handleSelect={(date: string) => {
            props.onChange(date);
            setIsVisible(!isVisible);
          }}
        />
        <div className="due-d">
          {props.dueDate && (
            <span
              className={isDuDateOver ? 'over' : ''}
              onClick={() => setShowResetAlert(true)}
            >
              <i>종료예정일</i>
              {moment(props.dueDate).format('YYYY/MM/DD')}
            </span>
          )}
        </div>

        <Alert
          isOpen={showResetAlert}
          onConfirm={async () => {
            props.onChange(null);
            setShowResetAlert(false);
          }}
          onCancel={() => {
            setShowResetAlert(false);
          }}
        >
          <p>
            지정된 마감일을 해제하시겠습니까?
            <br />
          </p>
        </Alert>
      </div>
    </li>
  );
};

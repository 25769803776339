import React, { useState } from 'react';

import useVisible from '../Common/hooks/useVisible';

// export interface ILabelItemProps {
//   id: string;
//   title: string;
// }
export interface ILabelProps {
  isArchived?: boolean;
  labels?: string[]; // 라벨 후보군들
  values: string[]; // 선택 라벨 값
  handleAdd?: (label: string) => void;
  handleRemove?: (label: string) => void;
  handleCreate?: (label: string) => void;
  handleDelete?: (label: string) => void;
}

export const Label: React.SFC<ILabelProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  // const [ isSelected, setIsSelected ] = useState<boolean>(false);
  const [newLabel, setNewLabel] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, dataset } = e.target;

    if (dataset.label) {
      if (checked) {
        props.handleAdd && props.handleAdd(dataset.label);
      } else {
        props.handleRemove && props.handleRemove(dataset.label);
      }
    }
  };

  return (
    <li>
      <div className={`select-w ${isVisible ? 'selected' : ''}`} ref={ref}>
        {/* 하위메뉴 열림 "slelected" className 추가 */}
        <button
          type="button"
          disabled={props.isArchived ? true : false}
          onClick={() => {
            setIsVisible(!isVisible);
          }}
        >
          라벨
        </button>
        <div className="option-box">
          <fieldset className="add">
            <legend>
              <label htmlFor="laAdd">라벨추가</label>
            </legend>
            <input
              type="text"
              id="laAdd"
              placeholder="추가"
              value={newLabel}
              onChange={e => {
                setNewLabel(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  props.handleCreate &&
                    newLabel &&
                    props.handleCreate(newLabel);
                  setNewLabel('');
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                props.handleCreate && newLabel && props.handleCreate(newLabel);
                setNewLabel('');
              }}
            >
              <span>추가</span>
            </button>
          </fieldset>
          <ul>
            {props.labels &&
              props.labels.map((label, i) => (
                <li key={i}>
                  <input
                    type="checkbox"
                    id={`laCh${i + 1}`}
                    data-label={label}
                    checked={props.values.includes(label)}
                    onChange={handleChange}
                  />
                  <label htmlFor={`laCh${i + 1}`}>{label}</label>

                  <button
                    type="button"
                    className="btn-delete"
                    onClick={() => {
                      props.handleDelete && props.handleDelete(label);
                    }}
                  >
                    <span>라벨삭제</span>
                  </button>
                </li>
              ))}
            {/* <li><input type="checkbox" id="laCh22" /><label htmlFor="laCh22">기획</label><button type="button" className="btn-delete"><span>라벨삭제</span></button></li>
                  <li><input type="checkbox" id="laCh23" checked={ true } onChange={ () => {} } /><label htmlFor="laCh23">디자인</label><button type="button" className="btn-delete"><span>라벨삭제</span></button></li>
                  <li><input type="checkbox" id="laCh24" /><label htmlFor="laCh24">QA</label><button type="button" className="btn-delete"><span>라벨삭제</span></button></li>
                  <li><input type="checkbox" id="laCh25" checked={ true } onChange={ () => {} } /><label htmlFor="laCh25">라벨라벨라벨라벨라벨라벨라벨…</label><button type="button" className="btn-delete"><span>라벨삭제</span></button></li> */}
          </ul>
        </div>
        <div className="cate-w">
          {props.values.map((label, i) => (
            <span key={i} className="type">
              {label}
            </span>
          ))}
        </div>
      </div>
    </li>
  );
};

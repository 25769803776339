import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

import { PageWrapper } from '../MainTemplate/MainTemplate';

export const Wrapper = styled(PageWrapper)`
  min-height: 500px;
  padding-left: 110px;
  padding-right: 90px;

  @media only screen and (max-width: ${PC}) {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;
export const TitleSection = styled.div`
  padding-top: 50px;
  display: flex;
  div + div {
    padding-left: 60px;
  }

  @media only screen and (max-width: ${PC}) {
    padding-top: 31px;
    padding-left: 41px;

    div + div {
      padding-left: 40px;
    }
  }
`;
export const Title = styled.div<{ active?: boolean }>`
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
  color: ${p => (p.active ? PALETTE['purpleish-blue'] : 'inherit')};

  ${p =>
    p.active &&
    `
      padding-bottom: 10px;
      border-bottom: 3px solid ${PALETTE['purpleish-blue']};
    `};

  & > a {
    color: ${PALETTE['black-two']};
  }
  & > a:hover {
    color: ${PALETTE['purpleish-blue']};
    padding-bottom: 10px;
    border-bottom: 3px solid ${PALETTE['purpleish-blue']};
  }

  @media only screen and (max-width: ${PC}) {
    font-size: 18px;
  }
`;
export const ContentSection = styled.div`
  margin-top: 60px;
`;

// Google Spread Sheet API
// 사용 라이브러리 : google-spreadsheet
// https://www.npmjs.com/package/google-spreadsheet
//
// 구현 참고 자료
// https://dev.to/calvinpak/how-to-read-write-google-sheets-with-react-193l
//
// 구글 서비스 계정 사용
// https://github.com/googleapis/google-api-nodejs-client#service-account-credentials
//

import { GoogleSpreadsheet } from 'google-spreadsheet';

// const SPREADSHEET_ID = process.env.REACT_APP_SPREADSHEET_ID;
// const SHEET_ID = process.env.REACT_APP_SHEET_ID;
// const CLIENT_EMAIL = process.env.REACT_APP_GOOGLE_CLIENT_EMAIL;
// const PRIVATE_KEY = process.env.REACT_APP_GOOGLE_SERVICE_PRIVATE_KEY;

const SPREADSHEET_ID = '1Knv5CqM16Wj08OyUW6qnv-97III1HZ3zg35BWvPQSfY';
const SHEET_ID = '0';
const CLIENT_EMAIL =
  'notion-manager-reader@project-tip-notion-manager.iam.gserviceaccount.com';
const BEGIN =
  '-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDKmn+LGOz3CvR9\nVNMOGYNwxDhoTZZme3kONKE1Jys7kmgDIfgVv6NcOA0rqoEfoySLQoCvRd6UvOBJ\nfwgr5mjlcLpK55YxNDjcoLoiOmuw4HYgG9Kc5V6dLIVzFdjpuQBndprEoXdTGVW+\nUBsAw6if+eEDnuvpcxp3al9sg4+pYkgELNXMWtoV+MdNr9Tr37WY3UIIdaY2tZgI\ngxNGP1g4nvouj5tDWZb5KTu7rhAKP7sm2PLqTWZsADcxVfw2e1MAAhFr3yUpQKYU\nOniMGDWAOzUiWPPzqXnxrdvJs3fQJ3DzVqOh2y8M/sbcUsUYP4sE9e3Kun1h+kxt\nPk/6nlcpAgMBAAECggEAKtaJ+z409HMziMCDs6p/WTuT9bHaCCc5TGCiaVuzz2/A\nYFExZF/VyazsU/xEOw1Cr9NK5ERwsKBTCBc9AhNAwKqaKTZYUmUJVNUDJjOWC5sa\nPuXDHXt+CtucB95ps9lzFW1Fgifl1AM6VvgXDY1RPSoXgq16kizNPsyWCpqyqIyI\nALuyOPIzk0P+KZ3TGmX+skpvfRnYqx4vNyAVyP37CEi9gbkZbh/mi1wtHzfqwmUb\n1dFnJwH1+C0aQQXc9GlfLaEImgW8Um39mTD+o+TmCUyFKFVywX14ld2jaYE4PK7Q\nqRFpxuHKAgCk1ySps2cOx9QEeOqtAsnvLKAu0U+DkQKBgQDkGOmltPfqM3ZvLgRZ\nKp3H2PC45/TWApuS2GPl4/bnrA672Vl3ItQjzqZmNeu1GXuStzM7UqrmZiM1KDN2\nOXZqirmrwR2fJhFDMJQ3A2oEqRJJuJJdwiGAYwbUBpnLCEaQakNBXCWnXdvJNXi6\ngyWKFTcqrLOTnHaA2pyVvWud3QKBgQDjYzjvdLrY4CJJrxZFToLYl1oJoAagtNpC\nRvNbjNC+UkyhiCvUXmxGfXdjtnm7kwxHH5ycHP/expGRp0rMTAJ+1wIwqbJZgSgI\nb2W/Fo3TK+kCFYCyQdu8h674HJQliAemB3U1cqpWKHbxBOoGS48ztNDE6RhV/HC2\nOXe1XwTHvQKBgQCDtq8U9Q9Dujvlr5jrNGSwBmHbJHaJLjBP94yYL69f0EchyjyT\nQxVlJ+uGkhRuGyz0Z725v97X9JrYE0LEPiJXG4xj1bwZLo5BUkgEDICn+EmLMH20\njmlyDbzfqoAcccbtla8IiwFjSBumcK4qZZjDx8R3bAq2bl8vwQ0ympMOMQKBgCS0\neJVWv26Qx5gjVjH72W1DCTqyhT9d0jmOoGvv0CZYvJv6kjCmyU2bpTEIXKAlvX6h\noLMmjzsTzJ25Zn6R6nk1FdprGioOijrKWJ8+8cfbt7IpbGvZ67GEKX9NhbCFQ87O\nN2JYTxy3TXPcejZn7gbHskeG5PHEXSFdJ3C0lN31AoGAJlpbZmdM7HGno2gvC7tZ\nu0koaodbdnRD3Nva1LgjYbk7T9ayKAmkBnsdlly67q5iYYH2CUJyP+TXRoDRXJl1\nMbL/B5xCp+gULbtXZ9Gie5JUrZQjyzGaeBg/mtZvhrzjGAVZcR5E/v6ht0sSo3Uh\nQGkdMOrs3naU5gmUbR0zK3k=\n-----END PRIVATE KEY-----\n';

const ROW_OFFSET = 8;

export const getRowsFromSheet = async () => {
  if (SPREADSHEET_ID && SHEET_ID && CLIENT_EMAIL && BEGIN) {
    const doc: GoogleSpreadsheet = new GoogleSpreadsheet(SPREADSHEET_ID);

    try {
      await doc.useServiceAccountAuth({
        client_email: CLIENT_EMAIL,
        private_key: BEGIN,
      });
    } catch (e) {
      console.error('Google 인증에 실패하였습니다.: ', e);
      return [];
    }

    await doc.loadInfo();
    const sheet = doc.sheetsById[SHEET_ID];
    const rows = await sheet.getRows({
      offset: ROW_OFFSET,
      limit: sheet.rowCount,
    });

    return rows;
  }
};

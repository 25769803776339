import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';

import * as Header from '../components/SeesoBoard/Header';

import { AppStore } from '../store/AppStore';
import { EActiveCompoment } from '../store/UIStore';
import DeletedTaskContainer from './DeletedTaskContainer';
import SlackSettingContainer from './SlackSettingContainer';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class HeaderContainer extends Component<IProps> {
  get appStore() {
    return (this.props as InjectedProps).appStore;
  }

  render() {
    const { taskBoard: board } = this.appStore.boardStore;
    const {
      activeComponent,
      isShowNotiBox,
      isShowMenu,

      setShowNotiBox,
    } = this.appStore.uiStore;
    const { username } = this.appStore.userStore;

    if (!board) return <></>;

    let countScheduled = 0;
    let countOngoing = 0;
    let countDone = 0;

    board.taskLists.map(task_list => {
      if (task_list.title === '작업예정' && !task_list.is_editable) {
        countScheduled += task_list.tasks.filter(
          task => !task.is_archived,
        ).length;
      } else if (task_list.title === '최종완료' && !task_list.is_editable) {
        countDone += task_list.tasks.filter(task => !task.is_archived).length;
      } else {
        countOngoing += task_list.tasks.filter(
          task => !task.is_archived,
        ).length;
      }
    });

    return (
      <header>
        <h1>
          <a href="/dashboard">
            <span>seeso 프로젝트 관리</span>
          </a>
        </h1>
        <h2>
          <span>프로젝트 보드</span>
          {board.projectGroupName}
        </h2>

        <Header.Progress
          countScheduled={countScheduled}
          countOngoing={countOngoing}
          countDone={countDone}
        />

        <div className="profile">
          <h3>{username} 님</h3>

          <div className={`noti ${isShowNotiBox ? 'selected' : ''}`}>
            <button type="button" onClick={this.onClickNotiIcon}>
              <span>알림</span>
              {/* <i>{ this.appStore.notificationStore.boardNotifications.filter(noti => !noti.date_read).length }</i> */}
              <i>{this.appStore.boardStore.unReadNotificationCount}</i>
            </button>

            {activeComponent === EActiveCompoment.ACTIVITY && (
              <Header.Activity
                activities={this.getActivityItems()}
                onClose={() => setShowNotiBox(false)}
                onScroll={
                  activeComponent === EActiveCompoment.ACTIVITY
                    ? this.onActivityScrollEnd
                    : undefined
                }
              />
            )}

            {activeComponent === EActiveCompoment.NOTIFICATION && (
              <Header.Notification
                notifications={this.getNotificationItems()}
                onClose={() => setShowNotiBox(false)}
              />
            )}
          </div>

          <Header.Menu
            isShow={isShowMenu}
            isManager={this.appStore.userStore.isPM}
            toggleShow={this.onClickMenuIcon}
            toggleActivityShow={this.onClickActivity}
            toggleDeletedTaskShow={this.onClickDeletedTask}
            toggleSlackSettingShow={this.onClickSlackSetting}
            onLogout={this.onClickLogout}
          />

          {/* 메뉴 서브섹션들: 삭제된 태스크, 슬랙 설정 */}
          <DeletedTaskContainer />
          <SlackSettingContainer />
        </div>
      </header>
    );
  }

  private getNotificationItems = () => {
    const { taskBoard: board } = this.appStore.boardStore;
    const { setReadTask } = this.appStore.uiStore;
    const {
      boardNotificationsByRecent,
      deleteBoardNotification,
      readBoardNotification,
    } = this.appStore.notificationStore;

    return boardNotificationsByRecent.map((notification: any) => {
      const data = notification.parameter_json.notification_data;

      return {
        id: String(notification.id),
        isNew: !notification.date_read,
        useDelete: true,
        message: data.body_template,
        date: moment(notification.date_created).format('YYYY/MM/DD hh:MM A'),
        onClick: async () => {
          if (data.event.startsWith('Task_')) {
            setReadTask(String(data.task_id));
            await readBoardNotification(notification.id);
          } else {
            if (!board) return;

            await board.fetchTaskListById(data.task_board_id);
          }
        },
        onDelete: async () => {
          await deleteBoardNotification(notification.id);
        },
      };
    });
  };

  private getActivityItems = () => {
    const { setReadTask } = this.appStore.uiStore;

    return this.appStore.boardStore.activitiesByRecent.map(activity => ({
      id: String(activity.id),
      isNew: false,
      useDelete: false,
      message: activity.message,
      date: moment(activity.updated_at).format('YYYY/MM/DD hh:MM A'),
      onClick: () =>
        activity.task_action_type.startsWith('Task_') &&
        setReadTask(String(activity.task__id)),
    }));
  };

  private onClickNotiIcon = async () => {
    const { taskBoard } = this.appStore.boardStore;
    const { activeComponent, setActiveCompoment } = this.appStore.uiStore;
    const { fetchBoardNotification } = this.appStore.notificationStore;

    if (!taskBoard) return;

    if (activeComponent === EActiveCompoment.NOTIFICATION) {
      setActiveCompoment();
    } else {
      setActiveCompoment(EActiveCompoment.NOTIFICATION);
      await fetchBoardNotification(taskBoard.taskBoardId);
      taskBoard.resetNotificationCount();
    }
  };

  private onClickMenuIcon = () => {
    const { activeComponent, setActiveCompoment } = this.appStore.uiStore;

    if (activeComponent === EActiveCompoment.MENU) {
      setActiveCompoment();
    } else {
      setActiveCompoment(EActiveCompoment.MENU);
    }
  };

  private onClickActivity = async () => {
    this.appStore.uiStore.setActiveCompoment(EActiveCompoment.ACTIVITY);
    // await this.appStore.boardStore.fetchActivities(1);
  };

  private onClickDeletedTask = () => {
    this.appStore.boardStore.taskMultiSelect.setArchivedMode(true);
    this.appStore.boardStore.taskMultiSelect.clearTaskIds();
    this.appStore.uiStore.setActiveCompoment(EActiveCompoment.DELETEDTASK);
  };

  private onClickSlackSetting = () => {
    this.appStore.uiStore.setActiveCompoment(EActiveCompoment.SLACKSETTING);
  };

  private onClickLogout = () => {
    this.appStore.userStore.logout();
    document.location.href = '/';
  };

  private onActivityScrollEnd = async (page: number) => {
    await this.appStore.boardStore.fetchActivities(page);
  };
}

import axios from 'axios';
import { action, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { HEADER_HEIGHT } from '../../constants/appConfig';

import { AppStore } from '../../store/AppStore';

import { MainTemplate as Comps } from '../molecules/MainTemplate';
import ErrorReport from '../organisms/MainTemplate/ErrorReport';
import Footer from '../organisms/MainTemplate/Footer';
import MenuBar from '../organisms/MainTemplate/MenuBar';
import { SectionName } from '../pages/HomePage';

const WithLandingProps = ({ children, current, location, history }: any) => {
  const [focusedSection, setFocusedSection] = useState<SectionName>('top');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  useEffect(
    function scrollIntoView() {
      const focusedOffsetTop =
        document.getElementById(focusedSection ?? '')?.offsetTop ?? 0;

      if (isSideMenuOpen) {
        setIsSideMenuOpen?.(false);
      }

      window.scroll({
        behavior: 'smooth',
        top: focusedOffsetTop - HEADER_HEIGHT,
      });
    },
    [focusedSection],
  );

  return (
    <>
      <MenuBar
        current={current}
        location={location}
        history={history}
        setFocusedSection={setFocusedSection}
        setIsSideMenuOpen={setIsSideMenuOpen}
        isSideMenuOpen={isSideMenuOpen}
      />
      {children}
    </>
  );
};

interface IProps extends RouteComponentProps {}

interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class MainTemplate extends Component<IProps> {
  private endRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.endRef = React.createRef();
  }
  @observable showScroll: boolean = false;
  @observable toUp: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }

  scrollTop() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
  scrollBottom() {
    window.scrollTo({ top: document.body.offsetHeight, behavior: 'smooth' });
  }

  @action.bound
  checkScrollTop() {
    if (window.pageYOffset > 400) {
      this.showScroll = true;
      if (
        window.pageYOffset >
        document.body.offsetHeight - window.innerHeight - 230
      ) {
        this.toUp = true;
      } else {
        this.toUp = false;
      }
    } else if (this.showScroll && window.pageYOffset <= 400) {
      this.showScroll = false;
    }
  }

  componentDidMount() {
    const authToken = window.localStorage.getItem('authtoken');

    if (authToken) {
      const { userStore } = this.injected.appStore;

      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
      userStore.checkLoginStatus();
    }

    window.addEventListener('scroll', this.checkScrollTop);
  }

  render() {
    return (
      <Comps.Wrapper>
        {this.showScroll && (
          <Comps.ScrollIcon
            toUp={this.toUp}
            onClick={() => (this.toUp ? this.scrollTop() : this.scrollBottom())}
          />
        )}
        <WithLandingProps
          current={this.props.location.pathname.slice(1)}
          location={this.props.location}
          history={this.props.history}
        >
          {this.props.children}
        </WithLandingProps>
        <Footer />
        <ErrorReport />
        <div ref={this.endRef} />
      </Comps.Wrapper>
    );
  }
}

export default withRouter(MainTemplate);

import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import DropdownSvg from '../../../static/icons/icon-drop-down.svg';

const FilterWrapper = styled.div`
  width: 208px;
  @media only screen and (max-width: ${PC}) {
    width: calc(100% / 2);
  }
`;
const FilterTitle = styled.div`
  line-height: 27px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;

  @media only screen and (max-width: ${PC}) {
    line-height: 24px;
    font-size: 16px;
  }
`;

export const OptionWrapper = styled.div<{
  isOpen: boolean;
}>`
  width: 100%;
  height: 47px;
  border-radius: 23.5px;
  border: solid 1px
    ${p =>
      p.isOpen ? PALETTE['purpleish-blue'] : PALETTE['very-light-pink-three']};
  box-shadow: none;
  background-color: ${p => (p.isOpen ? PALETTE['pale-grey'] : '#ffffff')};
  font-size: 18px;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;

  ::after {
    content: '';
    background: url('${DropdownSvg}') no-repeat center center;
    transform: rotate(${p => (p.isOpen ? 180 : 0)}deg);
    width: 18px;
    height: 18px;
    position: absolute;
    top: 15px;
    left: auto;
    right: 20px;
  }

  @media only screen and (max-width: ${PC}) {
    height: 42px;
    font-size: 15px;
    ::after {
      top: 12px;
    }
  }
`;
export const UlWrapper = styled.ul<{
  isOpen: boolean;
}>`
  width: 208px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  display: ${p => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  background-color: #ffffff;
  margin: 0px;
  padding: 0px;
  margin-top: 10px;
  z-index: 999;

  @media only screen and (max-width: ${PC}) {
    width: 156px;
  }
`;
const Option = styled.li`
  width: 100%;
  height: 51px;
  cursor: pointer;

  list-style-type: none;
  line-height: 27px;
  font-size: 18px;
  padding: 12px 0px 0px 20px;

  & + & {
    border-top: solid 1px ${PALETTE['very-light-pink-two']};
  }

  @media only screen and (max-width: ${PC}) {
    height: 42px;
    line-height: 22px;
    font-size: 15px;
    padding: 10px 0px 0px 20px;
  }
`;

export const DivFilter: FC<{
  options: string[];
  title: string;
  value: string;
  handleChange: (value: string) => void;
}> = props => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const clickOutside = useCallback(
    (e: MouseEvent) => {
      if (!(ref.current! as any).contains(e.target)) {
        setIsOpen(false);
      }
    },
    [ref.current],
  );

  useEffect(() => {
    document.addEventListener('click', clickOutside, true);
    return () => {
      document.removeEventListener('click', clickOutside, true);
    };
  });

  return (
    <FilterWrapper>
      <FilterTitle>{props.title}</FilterTitle>

      <OptionWrapper
        ref={ref}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {props.value}
      </OptionWrapper>

      <UlWrapper isOpen={isOpen}>
        {props.options.map((option, i) => (
          <Option
            key={i}
            onClick={() => {
              props.handleChange(option);
              setIsOpen(false);
            }}
          >
            {option}
          </Option>
        ))}
      </UlWrapper>
    </FilterWrapper>
  );
};

export const Wrapper = styled.div`
  display: flex;

  & > div {
    margin-right: 60px;
  }
  @media only screen and (max-width: ${PC}) {
    & > div {
      margin-right: 16px;
    }
  }
`;

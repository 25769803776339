import React from 'react';

import useVisible from '../Common/hooks/useVisible';

export interface IAssigneeSelectorProps {
  title: string;
}
export const AssigneeSelector: React.SFC<IAssigneeSelectorProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  let className = 'select-w';
  className += isVisible ? ' selected' : '';

  return (
    <div className={className} ref={ref}>
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {props.title}
      </button>
      <div className="option-box">
        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

import { Spinner } from '@blueprintjs/core';
import sortBy from 'lodash/sortBy';
import { inject, observer } from 'mobx-react';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { AppStore } from '../../../store/AppStore';
import { EProjectStatus } from '../../../store/Dashboard';
import { IManagingTip } from '../../../store/Dashboard/models/ManagingTip';
import { IProjectGroup } from '../../../store/Dashboard/models/ProjectGroup';
import { APP_NAME } from '../../../utils/firebase/push-notification';

import { Project as Comps } from '../../molecules/Dashboard';
import { Member, SprintSection, Summary, Tip } from '../../organisms/Dashboard';

interface DefaultProps {}
interface Props extends DefaultProps {
  appStore: AppStore;
}

const DashboardPage: FC<Props> = inject('appStore')(
  observer(props => {
    // @ts-ignore
    const { id } = useParams();
    // const { search } = useLocation();

    const params = new URLSearchParams(useLocation().search);
    const { dashboardStore, notificationStore, inspectionStore, userStore } =
      props.appStore;
    const selectedSprint = params.get('sprint');
    // const selectedReportId = params.get('reportId');

    const [projectGroup, setProjectGroup] = useState<IProjectGroup | null>(
      null,
    );
    const [managinTips, setManaginTips] = useState<IManagingTip[]>([]);

    useEffect(() => {
      (async () => {
        if (!userStore.isLoggedIn) return;

        if (
          !dashboardStore.projectGroup ||
          dashboardStore.projectGroup !== id
        ) {
          await dashboardStore.fetchById(id);

          // 웹노티가 있다면 읽음으로 처리
          notificationStore.readNotification(APP_NAME.project_dashboard, id);
        }
        if (dashboardStore.managingTips.length === 0) {
          await dashboardStore.fetchManagingTips();
        }

        if (dashboardStore.projectGroup) {
          const { tipFilterRule } = dashboardStore.projectGroup;

          setManaginTips(
            dashboardStore.getManagingTips(
              tipFilterRule.tipType,
              tipFilterRule.tipFilters,
            ),
          );
        }
        setProjectGroup(dashboardStore.projectGroup);
      })();
    }, [userStore.isLoggedIn]);

    useEffect(() => {
      (async () => {
        if (projectGroup) {
          if (!projectGroup.schedule) {
            await projectGroup.fetchSchedule();
          }

          if (projectGroup.schedule && !projectGroup.schedule.sprints) {
            await projectGroup.fetchSprint();
          }

          if (!projectGroup.sprintReports) {
            await projectGroup.fetchSprintReport();
          }
        }
      })();
    }, [projectGroup]);

    if (!projectGroup) {
      return (
        <Comps.Wrapper>
          <Spinner />
        </Comps.Wrapper>
      );
    }

    return (
      <Comps.Wrapper>
        {dashboardStore.isLoading || !projectGroup.schedule ? (
          <Spinner />
        ) : (
          <>
            <Summary
              projectGroup={projectGroup}
              isInspectionDownloading={inspectionStore.isPDFdownloading}
              handleClickInspection={async () => {
                await inspectionStore.fetchInspectionById(
                  projectGroup.inspection,
                );
                const inspections = inspectionStore.inspections.filter(
                  inspection =>
                    inspection.inspectionId === projectGroup.inspection,
                );
                if (inspections.length === 1) {
                  try {
                    inspectionStore.downloadPDF(inspections[0]);
                  } catch (e) {
                    alert('검수서 생성 중에 에러가 발생하였습니다.');
                  }
                } else {
                  alert('검수서가 없습니다.');
                }
              }}
            />

            <Comps.BodyWrapper>
              <SprintSection
                className="section"
                projectStatus={projectGroup.status}
                isEnd={projectGroup.isEndStatus}
                isProjectHolding={
                  projectGroup.status === EProjectStatus.HOLDING
                }
                machings={projectGroup.ongoingMatchings}
                selectedSprint={selectedSprint ? parseInt(selectedSprint) : 0}
                // selectedReportId = { selectedReportId }
                schedule={projectGroup.schedule}
                reports={projectGroup.sprintReports}
              />

              <div className="section">
                <Member
                  className="child-section"
                  managers={projectGroup.managers}
                  matchings={projectGroup.rufreeMatching}
                  isMatchingStatus={
                    projectGroup.status === EProjectStatus.MATCHING
                  }
                  rufrees={
                    projectGroup.schedule
                      ? sortBy(
                          projectGroup.schedule.rufrees,
                          rufree => rufree.sprintNumbers,
                        )
                      : null
                  }
                />
                <Tip className="child-section" tips={managinTips} />

                <Comps.LinkSection>
                  <Comps.Link>시소 온라인 협업 관련 규정</Comps.Link>
                  <Comps.Link>시소 서비스 이해하기</Comps.Link>
                </Comps.LinkSection>
              </div>
            </Comps.BodyWrapper>
          </>
        )}
      </Comps.Wrapper>
    );
  }),
);

export default DashboardPage;

import { Intent, Spinner } from '@blueprintjs/core';
import axios from 'axios';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import styled from 'styled-components/macro';
import { AppToaster } from './components/molecules/MyPage/AppToaster';
import { AppStore } from './store/AppStore';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface DefaultProps extends RouteComponentProps {
  history: any;
}
interface InjectedProps extends DefaultProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class CheckLogin extends Component<InjectedProps> {
  @observable readyToShow = false;

  get injected() {
    return this.props as InjectedProps;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  get notificationStore() {
    return this.injected.appStore.notificationStore;
  }

  @computed
  get isLoggedIn() {
    return this.injected.appStore.userStore.isLoggedIn;
  }

  async componentDidMount() {
    // Global axios interceptor
    // 토큰 만료시 로그인 페이지로 이동하기 위함
    axios.interceptors.response.use(
      response => {
        // window.location.href = `/?next=${window.location.pathname}`;
        return response;
      },
      error => {
        if (window.location.pathname !== '/' && error.response.status === 401) {
          this.userStore.logout();
        }

        if (
          error.response.data.detail &&
          error.response.data.detail.includes('Token has expired.')
        ) {
          this.userStore.logout();
        }

        return Promise.reject(error);
      },
    );

    const { userStore } = this.injected.appStore;
    const authToken = window.localStorage.getItem('authtoken');

    await userStore.checkLoginStatus();

    if (authToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
    }

    if (this.isLoggedIn) {
      const fcm_token = window.localStorage.getItem('fcm_token');
      try {
        fcm_token && userStore.addFcmToken(fcm_token);
      } catch (e) {
        AppToaster.show({
          message: '로그인 세션이 만료되어 다시 로그인 부탁드립니다',
          intent: Intent.DANGER,
        });
        this.userStore.logout();
        this.props.history.push('/');
      }
    }

    const noNeedLogin = await this.userStore.checkLoginValidPage(
      this.props.location.pathname.slice(1),
    );

    if (noNeedLogin) {
      this.userStore.updateCurrentThemeStatus(false);
    } else if (!noNeedLogin && !this.isLoggedIn) {
      await AppToaster.show({
        message: '로그인 세션이 만료되어 다시 로그인 부탁드립니다',
        intent: Intent.DANGER,
      });
      // await this.props.history.push("/");
    } else {
      this.userStore.updateCurrentThemeStatus(true);
      if (!this.notificationStore.notiProjectSummaries.receptionCount) {
        try {
          await this.notificationStore.fetchNotiProjectSummaries();
        } catch (e) {
          AppToaster.show({
            message: '로그인 세션이 만료되어 다시 로그인 부탁드립니다',
            intent: Intent.DANGER,
          });
          this.userStore.logout();
          this.props.history.push('/');
        }
      }
    }
    await (async () => (this.readyToShow = true))();
  }

  render() {
    if (!this.readyToShow) {
      return (
        <Wrapper>
          <Spinner />
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

export default withRouter(CheckLogin);

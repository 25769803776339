import styled from 'styled-components/macro';
import { PC } from '../../../constants/media';
import blueBellImg from '../../../static/images/blue-bell@2.png';
import grayBellImg from '../../../static/images/gray-bell@2.png';
import mailImg from '../../../static/images/mail@2.png';

export const ReceptionFilter = styled.div``;

export const ReceptionListWrapper = styled.div`
  margin-top: 100px;
  @media only screen and (max-width: 640px) {
    margin-top: 15px;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  width: 100%;
  h2 {
    display: inline-block;
    margin-right: 30px;
  }
  .emailWrap {
    position: relative;
    display: inline-block;
    float: right;
    font-size: 13px;
    color: #000;
    font-weight: bold;
    padding: 20px 0;
    a {
      color: #000;
    }
  }
  .emailWrap:before {
    content: '';
    position: absolute;
    background: url('${mailImg}') no-repeat;
    width: 18px;
    left: -18px;
    height: 18px;
    background-size: 75% 75%;
  }
  div {
    display: inline-block;
  }
  ul {
    margin-top: 10px;
  }
  @media only screen and (max-width: 640px) {
    h2 {
      margin-bottom: 40px;
    }
    .emailWrap {
      position: absolute;
      top: -14px;
      right: 0px;
    }
  }
`;

export const ReceptionCardWrapper = styled.div`
  width: calc(50% - 30px);
  display: inline-block;
  margin-right: 60px;
  margin-bottom: 70px;
  position: relative;

  &:nth-child(2n) {
    margin-right: 0px;
  }

  .ReceptionCancelBtnWrapper {
    margin-top: 18px;
    width: 100%;
    text-align: right;
    min-height: 36px;
  }
  .ReceptionCancelBtnRow {
    position: absolute;
    width: 100%;
    text-align: right;
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    padding: 0px;
    padding-top: 70px;
    margin-bottom: 30px;
    margin-right: 0px;
  }
`;

export const ReceptionCard = styled.div.attrs(props => ({
  className: props.className,
}))`
  border: 1px solid #bbbbbb;
  padding: 30px 25px;
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  &.highlighted {
    border: 2px solid #5834ff;
    .summaryDescription {
      color: #5834ff;
    }
    .summaryDescription:before {
      background: url('${blueBellImg}') no-repeat;
      background-size: 75% 75%;
    }
  }
  &.canceled {
    h4 {
      color: #999999;
    }
    .dateInfo {
      color: #666 !important;
      .dateTitle,
      dateDesc {
        color: #666 !important;
      }
    }
    .summaryInfo {
      .applyInfo {
        color: #666;
      }
    }
  }
  &.active {
    background: #f9f9ff;
  }
  h4 {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0 10px;
    max-height: 23px;
    overflow: hidden;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .summaryDescription {
    color: #666666;
    font-size: 13px;
    position: absolute;
    top: -25px;
    @media only screen and (max-width: ${PC}) {
      top: -45px;
    }
  }
  .summaryDescription:before {
    content: '';
    background: url('${grayBellImg}') no-repeat;
    position: absolute;
    width: 20px;
    left: -23px;
    height: 20px;
    background-size: 75% 75%;
  }
  .summaryInfo {
    .status {
      font-weight: bold;
      color: #fff;
      font-size: 14px;
      padding: 5px 12px 5px;
      border-radius: 20px;
      background-color: #5834ff;
    }
    .status.rejected {
      background-color: #ffe007;
      color: #000;
    }
    .status.accepted {
      background-color: #1effc5;
      color: #000;
    }
    .status.canceled {
      background-color: #fc4545;
    }

    .applyInfo {
      display: inline-block;
      float: right;
      font-size: 14px;
      font-weight: bold;
      span {
        margin-right: 10px;
      }
    }
  }
  .classInfo {
    width: 100%;
    margin-bottom: 15px;
    position: relative;
    color: #666;
    font-size: 13px;
    > div {
      margin-bottom: 5px;
    }
    .infoTitle {
      font-weight: bold;
    }
    .infoDesc {
      position: absolute;
      left: 55px;
    }
  }
  .dateInfo {
    width: 100%;
    position: relative;
    color: #000;
    font-size: 13px;
    > div {
      margin-bottom: 5px;
    }
    .dateTitle {
      font-weight: bold;
    }
    .dateDesc {
      position: absolute;
      left: 96px;
    }
  }
`;

export const ReceptionCancelBtn = styled.button`
  font-size: 14px;
  padding: 8px 25px;
  border: solid 1px #ebebeb;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
  border-radius: 3px;
  span {
    font-weight: 500;
  }
`;

import React, { FC } from 'react';

import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import ArrowSVG from '../../../static/icons/icon-movearrow-blue.svg';

export const Wrapper = styled.div`
  width: 450px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;
export const Box = styled.section`
  position: relative;
  width: 100%;
  height: 430px;
  background-color: #ffffff;
  padding: 20px 20px 0px 20px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  border-radius: 10px;

  .hidden {
    display: none;
  }

  & > h1 {
    line-height: 22px;
    font-size: 15px;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 2px;

    @media only screen and (max-width: ${PC}) {
      margin-bottom: 29px;
    }
  }

  & > .more {
    position: absolute;
    top: 22px;
    right: 34px;
    line-height: 19px;
    font-size: 13px;
    font-weight: 500;
    color: ${PALETTE['purpleish-blue']};

    ::after {
      content: '';
      background: url('${ArrowSVG}') no-repeat center center;
      width: 12px;
      height: 12px;
      position: absolute;
      margin-left: 2px;
      margin-top: 4px;
    }

    @media only screen and (max-width: ${PC}) {
      left: 15px;
      top: 45px;
      right: initial;
    }
  }

  @media only screen and (max-width: ${PC}) {
    padding: 15px;
    height: 483px;
  }
`;

export const SubTitle = styled.h1`
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  margin-bottom: 15px;
`;

export const Item = styled.section`
  color: #000000;
  position: relative;
  height: 114px;
  padding-top: 20px;

  .thumnail {
    width: 74px;
    height: 74px;

    @media only screen and (max-width: ${PC}) {
      width: 50px;
      height: 50px;
    }
  }
  .subject {
    position: absolute;
    top: 20px;
    left: 100px;
    font-size: 14px;
    line-height: 20px;

    overflow: hidden;
    text-overflow: ellipsis;
    width: 281px;
    height: 40px;
    display: block;

    @media only screen and (max-width: ${PC}) {
      // width: 233px;
      width: calc(100% - 80px);
      left: 65px;
    }
  }
  .tags {
    position: absolute;
    top: 75px;
    left: 100px;
    font-size: 12px;
    line-height: 18px;

    overflow: hidden;
    text-overflow: ellipsis;
    width: 281px;
    height: 18px;
    display: block;

    span + span {
      margin-left: 15px;
    }

    @media only screen and (max-width: ${PC}) {
      // width: 233px;
      width: calc(100% - 80px);
      left: 65px;
    }
  }

  border-bottom: solid 1px ${PALETTE['very-light-pink-two']};
`;

export const Thumnail = styled.div<{
  url?: string;
}>`
  background-image: url(${p => p.url});
  background-size: cover;
`;

export const PaginatorWrapper = styled.section`
  position: absolute;
  bottom: 13px;
  font-size: 12px;
  font-weight: 500;
  color: ${PALETTE['black-two']};

  width: 100%;
  height: 18px;

  display: flex;
  justify-content: center;

  div {
    cursor: pointer;
    width: 24px;
  }

  & > .active {
    font-weight: bold;
    color: ${PALETTE['purpleish-blue']};
  }
`;

export const Paginator: FC<{
  className?: string;
  maxPage: number;
  currentPage: number;
  handleClickPage: (page: number) => void;
}> = ({ currentPage, className, handleClickPage, maxPage }) => {
  return (
    <PaginatorWrapper>
      <div onClick={() => handleClickPage(currentPage - 1)}>&lt;</div>
      {Array.from({ length: maxPage }, (v, i) => i + 1).map((pageNumber, i) => (
        <div
          key={i}
          className={pageNumber === currentPage ? 'active' : ''}
          onClick={() => handleClickPage(pageNumber)}
        >
          {pageNumber}
        </div>
      ))}
      <div onClick={() => handleClickPage(currentPage + 1)}>&gt;</div>
    </PaginatorWrapper>
  );
};

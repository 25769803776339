import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { getSnapshot } from 'mobx-state-tree';

import moment from 'moment';
import React, { Component } from 'react';

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { TaskCard } from '../components/SeesoBoard/Task';

import {
  AddTask,
  AddTaskList,
  TaskListFooter,
  TaskListMenu,
} from '../components/SeesoBoard/TaskList';

import { AppStore } from '../store/AppStore';
import { ITask } from '../store/SeesoBoard/models/Task';
import { ITaskList } from '../store/SeesoBoard/models/TaskList';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class TaskListContainer extends Component<IProps> {
  state = {
    selectedTaskListId: '',
    isTaskAdding: false,
    // newTaskTitle: '',
  };

  get injected() {
    return this.props as InjectedProps;
  }

  get uiStore() {
    return this.injected.appStore.uiStore;
  }

  get boardStore() {
    return this.injected.appStore.boardStore;
  }

  @computed
  get taskFilter() {
    return getSnapshot(this.injected.appStore.boardStore.taskFilters);
  }

  @computed
  get tasklist() {
    if (!this.boardStore.taskBoard) return [];

    return this.boardStore.taskBoard.orderedTaskList;
  }

  @computed
  get selectedIds() {
    // TODO: UiStore 내, selectedIds.
    return this.boardStore.taskMultiSelect.task_ids.slice();
    // return this.uiStore.selectedIds.slice();
  }

  render() {
    const {
      taskBoard: board,
      fetchTaskBoard,
      taskMultiSelect,
    } = this.boardStore;
    const { taskFilter } = this;

    if (!board) return <></>;

    console.log(`> ${this.selectedIds.length} ${this.tasklist.length}`);

    return (
      <Droppable
        droppableId="board"
        type="LIST"
        direction="horizontal"
        isCombineEnabled={true}
      >
        {(provided: DroppableProvided) => (
          <div
            className="main-board"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {this.tasklist.map((tasklist: ITaskList) => {
              // 필터링
              let tasks = tasklist.onGoingTasks
                .filter(task =>
                  taskFilter.keyword
                    ? task.title.includes(taskFilter.keyword)
                    : true,
                )
                .filter(task =>
                  taskFilter.show_only_my_tasks
                    ? task.person_in_charge_list.includes(taskFilter.my_id)
                    : true,
                )
                .filter(task =>
                  taskFilter.person_in_charges.length > 0
                    ? task.person_in_charge_list.some((userId: number) =>
                        taskFilter.person_in_charges.includes(userId),
                      )
                    : true,
                )
                .filter(task =>
                  taskFilter.label_title_list.length > 0
                    ? task.labelsTitle.some((label: string) =>
                        taskFilter.label_title_list.includes(label),
                      )
                    : true,
                )
                .filter(task =>
                  taskFilter.sprint_title_list.length > 0
                    ? task.sprintsTitle.some((sprint: string) =>
                        taskFilter.sprint_title_list.includes(sprint),
                      )
                    : true,
                );
              if (taskFilter.use_duedate) {
                tasks = tasks.filter(task => {
                  if (task.due_date) {
                    const dueDate = moment(task.due_date);
                    if (
                      taskFilter.duedate_start &&
                      dueDate.isBefore(moment(taskFilter.duedate_start), 'days')
                    )
                      return false;
                    if (
                      taskFilter.duedate_end &&
                      dueDate.isAfter(moment(taskFilter.duedate_end), 'days')
                    )
                      return false;
                  }
                  if (
                    !task.due_date &&
                    (taskFilter.duedate_start || taskFilter.duedate_end)
                  )
                    return false;
                  return true;
                });
              }
              // 필터링 END

              return (
                <Draggable
                  key={tasklist.task_list_id}
                  draggableId={tasklist.task_list_id}
                  index={tasklist.order}
                >
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot,
                  ) => (
                    <section
                      data-listid={tasklist.task_list_id}
                      key={tasklist.task_list_id}
                      className="card-wrap"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                    >
                      <div className="inner">
                        <TaskListMenu
                          dndDragHandleProps={provided.dragHandleProps}
                          assignees={
                            board
                              ? board.members.map(member => ({
                                  category: `listassignee-${tasklist.task_list_id}`,
                                  id: member.id,
                                  email: member.email,
                                  name: member.name,
                                  value: String(member.id),
                                  selected:
                                    tasklist.person_in_charge_list.includes(
                                      member.id,
                                    ),
                                  onChange: (checked: boolean) => {
                                    console.log('bbbb');
                                  },
                                }))
                              : []
                          }
                          selectedAssigneeIds={tasklist.person_in_charge_list}
                          id={tasklist.task_list_id}
                          title={tasklist.title}
                          countTask={tasklist.onGoingTasks.length}
                          onChangeTitle={
                            tasklist.isDefault
                              ? undefined
                              : async (title: string) => {
                                  if (tasklist.title !== title) {
                                    await tasklist.updateTitle(title);
                                    await board?.fetchTaskList(
                                      tasklist.task_list_id,
                                    );
                                  }
                                }
                          }
                          onDelete={
                            tasklist.isDefault
                              ? undefined
                              : async () => {
                                  try {
                                    await tasklist.deleteSelf();
                                    await fetchTaskBoard();
                                  } catch (e) {
                                    this.uiStore.showToast(
                                      true,
                                      false,
                                      '태스크목록 삭제중 에러가 발생하였습니다.',
                                    );
                                  }
                                }
                          }
                          onSetAssignee={async (ids: number[]) => {
                            await tasklist.updatePersonInCharge(ids);
                            await board?.fetchTaskList(tasklist.task_list_id);
                          }}
                        />
                        <Droppable
                          key={tasklist.task_list_id}
                          droppableId={tasklist.task_list_id}
                          type="TASK"
                        >
                          {(
                            providedTask: DroppableProvided,
                            snapshotTask: DroppableStateSnapshot,
                          ) => (
                            <div
                              className="in-cnts"
                              ref={providedTask.innerRef}
                              {...providedTask.droppableProps}
                            >
                              {tasks.map((task: ITask) => {
                                return (
                                  <Draggable
                                    key={task.task_id}
                                    draggableId={task.task_id}
                                    index={task.order - 1}
                                  >
                                    {(providedTask, snapshot) => (
                                      <TaskCard
                                        key={task.id}
                                        isSelected={this.selectedIds.includes(
                                          task.id,
                                        )}
                                        assignees={task.person_in_charge_list
                                          .slice()
                                          .map((person: number) =>
                                            this.getAssigneeName(person),
                                          )}
                                        title={task.title}
                                        dueDate={task.due_date}
                                        labels={task.labelsTitle}
                                        sprints={task.sprintsTitle}
                                        subtaskCount={task.subTasks.length}
                                        doneSubtaskCount={
                                          task.doneSubtask.length
                                        }
                                        attachmentCount={task.attachmentCount}
                                        commentCount={task.task_comments.length}
                                        onClick={() => {
                                          this.uiStore.setReadTask(
                                            String(task.id),
                                          );
                                        }}
                                        onClickWithShift={() => {
                                          taskMultiSelect.setArchivedMode(
                                            false,
                                          );
                                          taskMultiSelect.toggleTaskId(task.id);
                                        }}
                                        onChangeTitle={async (
                                          title: string,
                                        ) => {
                                          if (title !== task.title) {
                                            await task.updateTitle(title);
                                            await board?.fetchTaskList(
                                              task.task_list,
                                            );
                                          }
                                        }}
                                        onDelete={async () => {
                                          try {
                                            await task.archive(true);
                                            await board?.fetchTaskList(
                                              task.task_list,
                                            );
                                          } catch (e) {
                                            this.uiStore.showToast(
                                              true,
                                              false,
                                              '에러가 발생하였습니다.',
                                            );
                                          }
                                        }}
                                        dndProvided={providedTask}
                                      />
                                    )}
                                  </Draggable>
                                );
                              })}

                              {providedTask.placeholder}

                              {this.state.isTaskAdding &&
                                this.state.selectedTaskListId ===
                                  tasklist.task_list_id && (
                                  <AddTask
                                    isShow={this.state.isTaskAdding}
                                    onAddTask={async (title: string) => {
                                      if (!board) return;

                                      await tasklist.createTask(title);
                                      await board?.fetchTaskList(
                                        tasklist.task_list_id,
                                      );
                                    }}
                                    onCloseTaskField={() => {
                                      this.setState({
                                        selectedTaskListId: '',
                                        isTaskAdding: false,
                                      });
                                    }}
                                  />
                                )}
                            </div>
                          )}
                        </Droppable>

                        <TaskListFooter
                          onClickTaskAdd={() => {
                            this.setState({
                              selectedTaskListId: tasklist.task_list_id,
                              isTaskAdding: true,
                            });
                          }}
                          onAddTasks={async (titles: string[]) => {
                            if (!board) return;
                            if (titles.length === 0) return;

                            for (const title of titles) {
                              await tasklist.createTask(title);
                            }
                            await board?.fetchTaskList(tasklist.task_list_id);
                          }}
                        />
                      </div>
                    </section>
                  )}
                </Draggable>
              );
            })}

            <AddTaskList
              onAdd={async (title: string) => {
                if (board && title) {
                  await board?.createTaskList(title);
                  await fetchTaskBoard();
                }
              }}
            />

            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  private getAssigneeName = (id: number) => {
    const { taskBoard: board } = this.boardStore;
    return board?.members.slice().find(member => member.id === id)?.name;
  };
}

import { flow, types } from 'mobx-state-tree';

import * as API from '../apis';
import { TaskBulletinPostResponse } from '../interfaces';

import { mapTaskBulletinPost } from '../store';
import { BulletinPostFileModel } from './BulletinPostFile';

import { BulletinPostLinkModel } from './BulletinPostLink';

export const TaskBulletinPostModel = types
  .model('TaskBulletinPost', {
    id: types.number,
    createdAt: types.string,
    updatedAt: types.string,
    order: types.number,
    task_bulletin_post_id: types.string,
    title: types.string,
    content: types.string,
    task_bulletin: types.string,
    writer: types.string,
    links: types.array(BulletinPostLinkModel),
    files: types.array(BulletinPostFileModel),
  })
  .actions(self => ({
    // setTitle(value: string) {
    //   self.title = value;
    // },
    // setContent(value: string) {
    //   self.content = value;
    // },
    // setOrder(value: number) {
    //   self.order = value;
    // },
    addEmptyLink() {
      self.links.unshift(
        BulletinPostLinkModel.create({
          id: -1,
          created_at: '',
          updated_at: '',
          link: '',
          task_bulletin_post: self.task_bulletin_post_id,
          writer: -1,
        }),
      );
    },
    removeEmptyLink() {
      const emptyIndex = self.links.findIndex(link => link.id === -1);

      if (emptyIndex > -1) self.links.splice(emptyIndex, 1);

      // if (emptyIndex > -1) {
      //   const newLinks = getSnapshot(self.links).splice(emptyIndex, 1);
      //   self.links.replace(newLinks);
      // self.links.replace([
      //   ...self.links.splice(0, emptyIndex),
      //   ...self.links.splice(emptyIndex+1)
      // ]);

      // if (idx > -1) a.splice(idx, 1)
      // }
    },
    removeLink(linkId: number) {
      /*
       * TODO: 해당 포스트의 링크들만 가져오는 백앤드 API로 호출할것
       */
      const findIndex = self.links.findIndex(link => link.id === linkId);
      if (findIndex > -1) {
        try {
          self.links[findIndex].remove();
          self.links.splice(findIndex, 1);
        } catch (e) {
          throw e;
        }
      }
    },
  }))
  .actions(self => {
    const fetchSelf = flow(function* () {
      try {
        console.log(yield API.TaskBulletinPost.fetch(self.id));
        const data: TaskBulletinPostResponse = yield API.TaskBulletinPost.fetch(
          self.id,
        );
        console.log(data);
        self = TaskBulletinPostModel.create(mapTaskBulletinPost(data));

        // self.merge(mapTaskBulletinPost(data));
      } catch (e) {
        throw e;
      }
    });
    const setTitle = flow(function* (title: string) {
      try {
        const data: TaskBulletinPostResponse =
          yield API.TaskBulletinPost.update(self.id, 'title', title);
        self.title = data.title;
      } catch (e) {
        throw e;
      }
    });
    const setContent = flow(function* (content: string) {
      try {
        const data: TaskBulletinPostResponse =
          yield API.TaskBulletinPost.update(self.id, 'content', content);
        self.content = data.content;
      } catch (e) {
        throw e;
      }
    });
    const remove = flow(function* () {
      try {
        yield API.TaskBulletinPost.delete(self.id);
      } catch (e) {
        throw e;
      }
    });
    // const updateTitle = flow(function* (task_bulletin: string, title: string) {
    //   try {
    //     console.log(task_bulletin, title)
    //     yield API.putTaskBulletinPostTitle(task_bulletin, self.id, title);
    //     self.setTitle(title);
    //   } catch (e) {
    //     console.log("updateTaskBulletinPostTitle error", e);
    //     throw e;
    //   }
    // });
    // const patch = flow(function* (title: string, content: string) {
    //   try {
    //     // const { data }: { data: TaskBulletinPostResponse } = yield axios.patch(
    //     //   `/taskmgr/bulletinpost/${id}`,
    //     //   {
    //     //     title: title,
    //     //     content: content
    //     //   }
    //     // );
    //     // return data;
    //     const data: TaskBulletinPostResponse = yield API.patchTaskBulletinPost(
    //       self.id, title, content
    //     )

    //   } catch (e) {
    //     console.log("TaskBulletinPost patc error", e);
    //     throw e;
    //   }
    // });

    return {
      fetchSelf,
      setTitle,
      setContent,
      remove,
    };
  });

type TaskBulletinPostType = typeof TaskBulletinPostModel.Type;

export interface TaskBulletinPost extends TaskBulletinPostType {}

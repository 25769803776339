import { Intent, Spinner } from '@blueprintjs/core';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

import { EditMode } from '../../../constants/mode';

import { AppStore } from '../../../store/AppStore';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import ClientInfoTabContent from '../../organisms/MyPage/ClientInfoTabContent';
import ContactContent from '../../organisms/MyPage/ContactContent';
import SubmitContent from '../../organisms/MyPage/SubmitContent';
import TitleContent from '../../organisms/MyPage/TitleContent';
import { MyPageTab } from './Constants';

const Wrapper = styled.div`
  width: 100%;
  min-height: 500px;
`;
const HeaderWrapper = styled.div`
  padding-top: 80px;
  margin: 0 auto;

  width: 1080px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 40px;
    padding-left: 16px;
    padding-right: 16px;

    width: 100%;
  }
`;

const TitleContainer = styled.div`
  height: 33px;
  @media only screen and (max-width: ${PC}) {
    height: 29px;
  }
`;
const ContactContainerPC = styled.div`
  position: relative;
  top: -22px;
  float: right;
  padding-right 16px;

  @media only screen and (max-width: ${PC}) {
    display: none;
  }  
`;

const HeaderHLine = styled.div`
  margin-top: 7.5px;
  width: 100%;
  height: 1px;
  border: solid 1px ${PALETTE['purpleish-blue']};
  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;

const ContentContainer = styled.div`
  padding-top: 50px;
  margin: 0 auto;

  width: 1080px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;

    width: 100%;
  }
`;

const CautionMessageMobile = styled.div`
  display: none;

  @media only screen and (max-width: ${PC}) {
    display: block;

    padding-top: 20px;
    padding-left: 16px;
    padding-right: 16px;

    width: 100%;

    font-family: 'Noto Sans KR', sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.69;
    letter-spacing: normal;
    color: ${PALETTE['color-disabled']};
  }
`;

const SubmitContainer = styled.div`
  padding-top: 40px;
  margin: 0 auto;
  width: 100%;

  @media only screen and (max-width: ${PC}) {
  }
`;

const ResignLinkContainerPC = styled.div`
  position: relative;
  top: -42px;
  right: 34px;
  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;
const ResignLinkContainerMobile = styled.div`
  padding-top: 40px;
  @media only screen and (min-width: ${PC}) {
    display: none;
  }
`;
const ResignLink = styled.div`
  cursor: pointer;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${PALETTE['color-disabled']};

  @media only screen and (max-width: ${PC}) {
    text-align: center;
    margin: 0 auto;
  }
`;
const ContactContainerMobile = styled.div`
  display: none;

  @media only screen and (max-width: ${PC}) {
    display: block;
    padding-top: 40px;

    width: 100%;
    margin: 0 auto;
  }
`;

const BottomSpace = styled.div<{ selectedTab: MyPageTab; editMode: EditMode }>`
  height: 90px;

  @media only screen and (max-width: ${PC}) {
    top: 1665px;
    ${(props: any) => {
      if (props.selectedTab === MyPageTab.COMPANY) {
        return 'top: 1665px;';
      } else {
        if (props.editMode === EditMode.UPDATE) {
          return 'top: 1161px;';
        } else {
          return 'top: 1103px;';
        }
      }
    }}
  }
`;

interface MyPageProps {}

interface InjectedProps extends MyPageProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class MyPage extends Component<MyPageProps> {
  @observable selectedTab: MyPageTab = MyPageTab.COMPANY;
  @observable editMode: EditMode = EditMode.NONE;
  @observable readyToShow: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }
  get store() {
    return this.injected.appStore.myPageStore;
  }

  async componentDidMount() {
    await this.refresh();
    this.initEditMode();
  }

  refresh = async () => {
    try {
      const { userStore } = this.injected.appStore;

      this.store.init();
      this.readyToShow = false;

      // 1. client 연결되면 client 값이 우선되어야 하므로 clientPersonal 호출 후 client를 호출해야함
      // 2. client 에는 협업툴정보가 없기 때문에 clientPersonal을 무조건 한 번은 호출해야함
      await this.store.fetchClientPersonal(userStore.userId);
      await this.store.fetchClient(this.store.clientId);

      await this.store.fetchClientFile(this.store.clientCLId, userStore.userId);

      this.readyToShow = true;
    } catch (e) {}
  };

  initEditMode = () => {
    let pc_width = Number(PC.substring(0, PC.length - 'px'.length));

    if (this.store.clientId > 0 || this.store.clientPersonalId > 0) {
      this.editMode = EditMode.READ;
      if (window.innerWidth < pc_width && this.editMode === EditMode.READ) {
        this.editMode = EditMode.UPDATE;
      }
    } else {
      this.editMode = EditMode.CREATE;
    }
    // 화면크기로 모바일인지 감지하여 view 모드이면 edit 모드로 전환 (모바일은 view 없음)
    window.onresize = () => {
      if (window.innerWidth < pc_width && this.editMode === EditMode.READ) {
        this.editMode = EditMode.UPDATE;
      }
    };
  };

  handleTabChanged = (value: MyPageTab) => {
    this.selectedTab = value;
    this.forceUpdate();
  };

  handleEditModeChanged = (value: EditMode) => {
    this.editMode = value;
    this.forceUpdate();
  };

  handleResign = () => {
    if (window.confirm('정말 탈퇴하시겠습니까?')) {
      alert('테스트중입니다. partner@seeso.kr 로 요청해주세요.');
    }
  };

  render() {
    const { userStore } = this.injected.appStore;

    if (!userStore.isLoggedIn) {
      return <Wrapper />;
    } else if (!this.readyToShow) {
      return <Spinner />;
    } else {
      return (
        <Wrapper>
          <HeaderWrapper>
            <TitleContainer>
              <TitleContent
                clientName={this.store.userName}
                clientNo={this.store.clientCLId}
              />
              <ContactContainerPC>
                <ContactContent />
              </ContactContainerPC>
            </TitleContainer>
            <HeaderHLine />
          </HeaderWrapper>
          <ContentContainer>
            <ClientInfoTabContent
              selectedTab={this.selectedTab}
              onTabChanged={this.handleTabChanged}
              editMode={this.editMode}
            />

            {this.selectedTab === MyPageTab.USER &&
              this.editMode === EditMode.UPDATE && (
                <ResignLinkContainerPC>
                  <ResignLink onClick={this.handleResign}>
                    회원 탈퇴하기
                  </ResignLink>
                </ResignLinkContainerPC>
              )}
          </ContentContainer>

          {this.editMode === EditMode.CREATE && (
            <CautionMessageMobile>
              ※ 회사당 하나의 클라이언트 번호를 부여합니다. 클라이언트 번호가
              부여된 이후 필요에 따라 시소의 관리자가 회사정보를 수정할 수
              있으며, 수정된 정보는 상호 동기화 됩니다. 회사 정보를 늘 최신
              정보로 유지해 주시고, 되도록 한 회사당 하나의 계정으로 사이트를
              이용해주세요.
            </CautionMessageMobile>
          )}

          <SubmitContainer>
            <SubmitContent
              selectedTab={this.selectedTab}
              onTabChanged={this.handleTabChanged}
              editMode={this.editMode}
              onEditModeChange={this.handleEditModeChanged}
              onSubmit={() => {
                this.refresh();
              }}
              onCancel={() => {
                this.refresh();
                AppToaster.show({
                  message: '수정내역 저장 취소',
                  intent: Intent.PRIMARY,
                });
              }}
            />
          </SubmitContainer>

          <ContactContainerMobile>
            <ContactContent />
          </ContactContainerMobile>
          {this.selectedTab === MyPageTab.USER &&
            this.editMode === EditMode.UPDATE && (
              <ResignLinkContainerMobile>
                <ResignLink onClick={this.handleResign}>
                  회원 탈퇴하기
                </ResignLink>
              </ResignLinkContainerMobile>
            )}
          <BottomSpace selectedTab={this.selectedTab} editMode={this.editMode}>
            &nbsp;
          </BottomSpace>
        </Wrapper>
      );
    }
  }
}

export default MyPage;

import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';

import LogoIcon from '../../../static/icons/logo-seesoP-PC.svg';

const Logo = styled.div`
  background: url('${LogoIcon}') no-repeat center center;
  width: 68px;
  height: 30px;
  cursor: pointer;
  margin-right: 50px;
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  line-height: 30px;
`;

export { Body, Logo, Wrapper };

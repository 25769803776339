import { Classes, Dialog, Icon, ProgressBar } from '@blueprintjs/core';
import { Intent } from '@blueprintjs/core/lib/esm/common/intent';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import NextSvg from '../../../static/icons/icon-button-next.svg';
import IconCross from '../../../static/icons/icon-upload.svg';
import blueBellImg from '../../../static/images/blue-bell@2.png';
import { AppStore } from '../../../store/AppStore';
import { Inspection } from '../../../store/Inspection/Models/Inspection';
import { Inspection as Comps } from '../../molecules/Inspection';
import NewAttachmentModal from '../../molecules/Inspection/NewAttachmentModal';
import { AppToaster } from '../../molecules/MyPage/AppToaster';
import Button from '../../molecules/MyPage/Button';

const IdRow = styled.div`
  // width: 52px;
  height: 20px;
  // font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
`;

const HR = styled.hr`
  clear: right;
  width: 100%;
  padding: -20px;
`;

const BottomArrowBar = styled.div`
    width:15px;
    height: 15px
    object-fit:contain;
    // background:url('${NextSvg}') no-repeat center center;
    // position: absolute;
`;

interface Props {
  inspection: Inspection;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject('appStore')
@observer
class CardItem extends Component<Props> {
  @observable linkExpanded = false;
  @observable newAttachment = false;
  @observable downloadLoadingOpen = false;
  @observable downloadLoadingPercent: number = 0;

  get injected() {
    return this.props as InjectedProps;
  }

  addNewDataHandler() {
    console.log('addNewDataHandler');
    // if (this.props.inspection.projectStarted) return;
    this.newAttachment = !this.newAttachment;
    this.injected.appStore.inspectionStore.initAttachment();
  }

  renderLinks(linkList: any) {
    const { inspection } = this.props;
    const totalLinks = linkList.length;
    if (totalLinks === 0)
      return (
        <>
          <Comps.NoDataMsg>등록된 자료가 없습니다.</Comps.NoDataMsg>
          <Comps.NewDataMsg
            show={
              inspection.projectgroupIds.length === 0 ||
              inspection.projectgroupIds[inspection.pg_index].date_complete ===
                null
            }
            // style={{display: inspection.projectgroupIds[inspection.pg_index].date_complete === null ? "block" : "none"}}
            onClick={() => this.addNewDataHandler()}
          >
            자료 추가 &nbsp;
            <img
              style={{
                height: '15px',
                width: '15px',
                marginBottom: '4px',
                // border: "solid 1px",
                // borderRadius: "2px"
              }}
              src={IconCross}
            />
          </Comps.NewDataMsg>
        </>
      );

    return (
      <>
        <Comps.ReferenceLinkSection>
          {linkList.map(
            (
              item: {
                link: string | undefined;
                title: React.ReactNode;
                created_date: string | undefined;
              },
              index: any,
            ) => (
              <Comps.ReferenceLinkRow key={`${index}${item.link}`}>
                <div>
                  <Comps.ReferenceLinkTitle href={item.link} target="_blank">
                    <Icon icon={'paperclip'} />{' '}
                    <span style={{ color: PALETTE['purpleish-blue'] }}>
                      {item.title}
                    </span>
                  </Comps.ReferenceLinkTitle>
                </div>
                <Comps.ReferenceLinkDate>
                  {moment(item.created_date).format('YYYY.MM.DD HH:mm')}
                </Comps.ReferenceLinkDate>
              </Comps.ReferenceLinkRow>
            ),
          )}
        </Comps.ReferenceLinkSection>
        <Comps.NewDataMsg
          show={
            inspection.projectgroupIds.length === 0 ||
            inspection.projectgroupIds[inspection.pg_index].date_complete ===
              null
          }
          // style={{display: inspection.projectgroupIds[inspection.pg_index].date_complete === null ? "block" : "none"}}
          onClick={() => this.addNewDataHandler()}
        >
          {/*자료 추가 &nbsp;<Icon icon={"insert"} iconSize={15} style={{border: "0.1px"}}></Icon>*/}
          자료 추가 &nbsp;
          <img
            style={{
              height: '15px',
              width: '15px',
              marginBottom: '4px',
              borderRadius: '2px',
            }}
            src={IconCross}
          ></img>
        </Comps.NewDataMsg>
      </>
    );
  }

  render() {
    const { inspection } = this.props;

    let published_date_time = inspection.publishedDate;
    let formated_published_date_time =
      moment(published_date_time).format('YYYY.MM.DD');
    // let formated_published_date_time = moment(published_date_time).format('YYYY.MM.DD HH:mm')
    if (inspection.publishedTime) {
      published_date_time =
        published_date_time + ' ' + inspection.publishedTime;
      formated_published_date_time =
        moment(published_date_time).format('YYYY.MM.DD HH:mm');
    }
    const projectGroupA = inspection.projectgroupIds[inspection.pg_index];
    return (
      <Comps.Wrapper>
        <Comps.TopComment>
          {inspection.notificationOn && (
            <>
              <img
                style={{
                  marginRight: '6px',
                  height: '16px',
                  paddingBottom: '2px',
                }}
                src={blueBellImg}
              />
              {inspection.published_count === 1
                ? '새로운 플랜이 발행되었습니다.'
                : '플랜이 수정되었습니다.'}
            </>
          )}
        </Comps.TopComment>
        <Comps.ItemCard new={inspection.notificationOn}>
          <Comps.MainSection
            onClick={async () => {
              try {
                this.downloadLoadingOpen = true;
                this.downloadLoadingPercent = 0.1;
                const interval = setInterval(() => {
                  this.downloadLoadingPercent +=
                    Math.random() * (0.15 - 0.01) + 0.01;
                }, 500);
                const prevCount =
                  this.injected.appStore.notificationStore.inspectionNewCount;
                this.injected.appStore.notificationStore.setInspectionNewCount(
                  prevCount - 1,
                );

                await this.injected.appStore.inspectionStore.downloadPDF(
                  inspection,
                );
                clearInterval(interval);

                this.downloadLoadingPercent = 1;
                this.downloadLoadingOpen = false;

                setTimeout(async () => {
                  try {
                    await this.injected.appStore.notificationStore.fetchNotification();
                  } catch (e) {
                    console.log(e);
                  }
                }, 1000);
              } catch (e) {
                console.log('downloadPDF Error', e);
              } finally {
                this.downloadLoadingOpen = false;
              }

              // inspection.setClientChecked(true);
              // this.injected.appStore.inspectionStore.fetchInspectionCount();
              await this.injected.appStore.inspectionStore.fetchInspections();
            }}
          >
            {/*<div>*/}
            <IdRow style={{ display: 'inline-block' }}>
              <span>플랜번호</span>
              <span style={{ marginLeft: '10px' }}>
                {inspection.inspectionId}
              </span>
              <Comps.ProjectIds>
                {projectGroupA && (
                  <>
                    <span style={{ marginLeft: '10px', marginRight: '15px' }}>
                      |
                    </span>
                    <div style={{ display: 'inline-block' }}>
                      <IdRow
                        style={{ display: 'inline-block' }}
                        key={projectGroupA.group_id + 'i'}
                      >
                        <span>프로젝트 번호</span>
                        <span style={{ marginLeft: '10px' }}>
                          {projectGroupA.group_id}
                        </span>
                      </IdRow>
                    </div>
                  </>
                )}
              </Comps.ProjectIds>
              <Comps.ProjectMobileIds>
                {projectGroupA && (
                  <>
                    <div style={{ display: 'inline-block' }}>
                      <IdRow
                        style={{ display: 'inline-block' }}
                        key={projectGroupA.group_id + 'i'}
                      >
                        <span>프로젝트 번호</span>
                        <span style={{ marginLeft: '10px' }}>
                          {projectGroupA.group_id}
                        </span>
                      </IdRow>
                    </div>
                  </>
                )}
              </Comps.ProjectMobileIds>
              {/*<Comps.ProjectIds>{inspection.projectgroupIds.map(projectGroup =>
                                <><span style={{marginLeft: "10px", marginRight: "15px"}}>|</span>
                                    <div style={{display: "inline-block"}}>
                                        <IdRow style={{display: "inline-bl트ock"}}
                                               key={projectGroup.group_id + "i"}>
                                            <span>프로젝트 번호</span>
                                            <span style={{marginLeft: "10px"}}>{projectGroup.group_id}</span>
                                        </IdRow></div>
                                </>
                            )}</Comps.ProjectIds>*/}
            </IdRow>

            {/*</div>*/}

            <Comps.TitleRow>{inspection.title}</Comps.TitleRow>
            <Comps.ClientRow>{inspection.requesterName}</Comps.ClientRow>
            <Comps.PublishDateRow>
              <Comps.PublishDateTitle>최종 발행일시</Comps.PublishDateTitle>
              <Comps.PublishDateValue>
                {formated_published_date_time}
              </Comps.PublishDateValue>
            </Comps.PublishDateRow>
            {/*<HR/>*/}
          </Comps.MainSection>
          <Comps.ReferenceSection>
            <Comps.ReferenceBar
              onClick={() => {
                this.linkExpanded = !this.linkExpanded;
              }}
            >
              <Comps.ReferenceTextSection>
                <Comps.ReferenceTitle>참고 자료</Comps.ReferenceTitle>
                <Comps.ReferenceCount>
                  ({inspection.linkList.length})
                </Comps.ReferenceCount>
              </Comps.ReferenceTextSection>
              {/*<TriangleDownButton/>*/}
              <div
                style={{ width: '24px', height: '24px', textAlign: 'center' }}
              >
                <Icon
                  style={{ width: '10px' }}
                  icon={
                    this.linkExpanded
                      ? 'symbol-triangle-up'
                      : 'symbol-triangle-down'
                  }
                  iconSize={13}
                  color={PALETTE['purpleish-blue']}
                />
              </div>
            </Comps.ReferenceBar>

            {this.linkExpanded && this.renderLinks(inspection.linkList)}
          </Comps.ReferenceSection>
        </Comps.ItemCard>

        {inspection.projectgroupIds.length > 0 &&
          (inspection.projectgroupIds[inspection.pg_index].date_complete ===
            null &&
          inspection.projectgroupIds[inspection.pg_index].project_started ? (
            <div
              style={{ float: 'right', marginTop: '10px', marginRight: '1px' }}
            >
              <Link
                to={`/dashboard/${
                  inspection.projectgroupIds[inspection.pg_index].group_id
                }`}
                target="_blank"
              >
                <Button
                  text="프로젝트 바로가기"
                  theme="emerald"
                  small
                  style={{ width: '160px', height: '36px' }}
                  textStyle={{
                    width: '107px',
                    height: '20px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#000000',
                  }}
                  // width={"168px"}
                  // disabled={}
                  onClick={() => {
                    // history.push(`/receptions/${inspection.projectgroupIds[0]}`)
                    /*var input = document.getElementById("bnk_file");
                                        if(input) {
                                            input.click();
                                        }*/
                  }}
                >
                  <img
                    style={{
                      width: '15px',
                      height: '15px',
                      objectFit: 'contain',
                      marginLeft: '6px',
                      marginRight: '5px',
                      marginBottom: '4px',
                    }}
                    src={NextSvg}
                  />
                </Button>
              </Link>
            </div>
          ) : (
            <div
              style={{ float: 'right', marginTop: '10px', marginRight: '1px' }}
            >
              <Link
                to={`/dashboard/${
                  inspection.projectgroupIds[inspection.pg_index].group_id
                }`}
                target="_blank"
              >
                <Button
                  text="프로젝트 바로가기"
                  theme="emerald"
                  small
                  style={{
                    width: '160px',
                    height: '36px',
                    borderColor: PALETTE['very-light-pink-two'],
                    backgroundColor: '#FFFFFF',
                  }}
                  textStyle={{
                    width: '107px',
                    height: '20px',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: '#000000',
                  }}
                  // width={"168px"}
                  // disabled={}
                  onClick={() => {
                    // history.push(`/receptions/${inspection.projectgroupIds[0]}`)
                    /*var input = document.getElementById("bnk_file");
                                        if(input) {
                                            input.click();
                                        }*/
                  }}
                >
                  <img
                    style={{
                      width: '15px',
                      height: '15px',
                      objectFit: 'contain',
                      marginLeft: '6px',
                      marginRight: '5px',
                      marginBottom: '4px',
                    }}
                    src={NextSvg}
                  />
                </Button>
              </Link>
            </div>
          ))}
        <NewAttachmentModal
          handleAddNewLine={() => {
            this.injected.appStore.inspectionStore.addLineAttachment();
            console.log(
              'HELO',
              this.injected.appStore.inspectionStore
                .inspectionNewAttachmentFormList.length,
            );
          }}
          handleRemoveLine={index => {
            this.injected.appStore.inspectionStore.removeLineAttachment(index);
            console.log(
              'Bye',
              this.injected.appStore.inspectionStore
                .inspectionNewAttachmentFormList.length,
            );
          }}
          handleChangeLine={() => {
            const num =
              this.injected.appStore.inspectionStore.inspectionNewAttachmentFormList.filter(
                i => {
                  return i.title.length == 0;
                },
              ).length;
            if (num == 0)
              this.injected.appStore.inspectionStore.addLineAttachment();
          }}
          attachmentList={
            this.injected.appStore.inspectionStore
              .inspectionNewAttachmentFormList
          }
          isOpen={this.newAttachment}
          handleClose={() => {
            this.newAttachment = false;
          }}
          submitAttachment={async () => {
            try {
              const timeout = setTimeout(() => {
                this.downloadLoadingOpen = true;
              }, 2000);
              this.downloadLoadingPercent = 0.1;
              const interval = setInterval(() => {
                this.downloadLoadingPercent += Math.random() * 0.03 + 0.01;
              }, 500);
              const success =
                await this.injected.appStore.inspectionStore.uploadResources(
                  inspection.inspectionId,
                );

              this.downloadLoadingPercent = 1;
              clearTimeout(timeout);
              clearInterval(interval);
              this.downloadLoadingOpen = false;

              if (success) {
                AppToaster.show({
                  message: '자료가 성공적으로 추가되었습니다.',
                  intent: Intent.SUCCESS,
                });
              } else {
                throw '실패';
              }
            } catch (e) {
              console.log(e);
              AppToaster.show({
                message: '자료 업로드를 실패하였습니다.',
                intent: Intent.DANGER,
              });
            }

            await this.injected.appStore.inspectionStore.fetchInspections();
            this.newAttachment = false;
          }}
        />

        <Dialog
          isOpen={this.downloadLoadingOpen}
          onClose={e => {
            this.downloadLoadingPercent = 0;
            this.downloadLoadingOpen = false;
            e?.stopPropagation();
            e?.preventDefault();
            this.injected.appStore.inspectionStore.cancelPDF();
          }}
          // isCloseButtonShown={false}
          autoFocus={true}
          enforceFocus={true}
        >
          <div className={Classes.DIALOG_BODY}>
            <ProgressBar
              animate={true}
              intent={Intent.PRIMARY}
              value={this.downloadLoadingPercent}
            />
          </div>
        </Dialog>
      </Comps.Wrapper>
    );
  }
}

export default CardItem;

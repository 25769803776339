import { types } from 'mobx-state-tree';

export enum EExternalToolType {
  TRELLO = 'trello',
  SLACK = 'slack',
  ZEPLIN = 'zeplin',
  GDRIVE = 'gdrive',
  GITHUP = 'githup',
  GHANGOUT = 'hangout',
}

export const mapper = (x: IExternalToolResponse) => {
  return {
    tool: x.tool,
    url: x.url,
  };
};

export interface IExternalToolResponse {
  tool: string;
  url: string;
}

export const ExternalToolModel = types
  .model('ExternalTool', {
    tool: types.string,
    url: types.string,
  })
  .actions(self => ({}));

type ExternalToolType = typeof ExternalToolModel.Type;
export interface IExternalTool extends ExternalToolType {}

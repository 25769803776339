import { types } from 'mobx-state-tree';

export const TaskCommentModel = types.model('TaskComment', {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  comment: types.string,
  task: types.string,
  writer: types.number,
});
type TaskCommentType = typeof TaskCommentModel.Type;

export interface TaskComment extends TaskCommentType {}

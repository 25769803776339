import { Spinner } from '@blueprintjs/core';
import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

import { EditMode } from '../../../constants/mode';
import { MyPageFile } from '../../../store/MyPage/MyPageModel';

import Button from '../../molecules/MyPage/Button';

const Wrapper = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
  }
`;
const SubmitDocumentLabelContainer = styled.div`
  margin-left: 50px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${PC}) {
    margin-left: 0px;
    flex-direction: row;
  }
`;
const SubmitDocumentLabelTitle = styled.div`
  padding-top: 25px;
  width: 120px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;

  @media only screen and (max-width: ${PC}) {
    padding-top: 0px;
    padding-left: 2px;
    width: auto;
    font-size: 14px;
  }
`;
const SubmitDocumentLabelHelpText = styled.div`
  padding-top: 2px;
  width: 119px;
  height: 18px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    margin-left: 11px;
    width: auto;
  }
`;
const SubmitButtonContainer = styled.div`
  display: flex;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;
const SubmitBizButtonContainer = styled.div`
  padding-left: 30px;

  @media only screen and (max-width: ${PC}) {
    padding-left: 0px;
    margin-left: 14px;
    margin-right: 28px;
  }
`;
const SubmitBankButtonContainer = styled.div`
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-left: 0px;
    margin-left: 28px;
    margin-right: auto;
  }
`;
const SubmitBizDocumentButton = styled.div`
  padding-top: 20px;
  @media only screen and (max-width: ${PC}) {
  }
`;
const SubmitBankDocumentButton = styled.div`
  padding-top: 20px;
  @media only screen and (max-width: ${PC}) {
  }
`;
const FileActionLabel = styled.div`
  width: 122px;
  text-align: center;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['greyish-brown']};
`;
const BizFileViewLabel = styled(FileActionLabel)`
  padding-top: 10px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 0px;
    margin-top: 10px;
  }
`;
const BizFileDeleteLabel = styled(FileActionLabel)`
  padding-top: 8px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 0px;
    margin-top: 16px;
  }
`;
const BankFileViewLabel = styled(FileActionLabel)`
  padding-top: 10px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 0px;
    margin-top: 10px;
  }
`;
const BankFileDeleteLabel = styled(FileActionLabel)`
  padding-top: 8px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 0px;
    margin-top: 16px;
  }
`;
const FileLinkText = styled.div`
  cursor: pointer;
  :hover {
    color: ${PALETTE['purpleish-blue']};
  }
`;
const UploadSpinner = styled(Spinner)`
  padding-top: 20px;
  padding-left: 180px;

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;
const UploadSpinnerMobile = styled.div`
  display: none;
  @media only screen and (max-width: ${PC}) {
    display: block;
    padding-top: 24px;
    width: 100%;
    text-align: center;

    & span {
      display: inline-block;
      margin: 0 -0.05em;
      animation: loading 1.2s infinite alternate;
    }

    & span:nth-child(1) {
      animation-delay: 0.2s;
    }
    & span:nth-child(2) {
      animation-delay: 0.4s;
    }
    & span:nth-child(3) {
      animation-delay: 0.6s;
    }
    & span:nth-child(4) {
      animation-delay: 0.8s;
    }
    & span:nth-child(5) {
      animation-delay: 1s;
    }
    & span:nth-child(6) {
      animation-delay: 1.2s;
    }

    @keyframes loading {
      0%,
      100% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(15px);
      }
    }
  }
`;

interface DocumentSubmitComponentProps {
  bizFile: MyPageFile;
  bnkFile: MyPageFile;
  editMode: EditMode;
  onFileSubmit: (
    action: string,
    fileModel: MyPageFile,
    file: File | null,
    filetype: string,
  ) => void;
  isFileUploading: boolean;
}

class DocumentSubmitComponent extends Component<DocumentSubmitComponentProps> {
  getFileName = (file: MyPageFile) => {
    let fileName = '';
    if (file.id && file.id > 0) {
      let idx = file.filename.lastIndexOf('.');

      fileName = idx > -1 ? file.filename.substring(0, idx) : file.filename;
      fileName =
        fileName.length > 12 ? fileName.substring(0, 8) + '(...)' : fileName;
    }
    return fileName;
  };

  getFileType = (file: MyPageFile) => {
    let fileType = '';
    if (file.id && file.id > 0) {
      let idx = file.filename.lastIndexOf('.');
      if (idx > -1) {
        fileType = file.filename.substring(idx);
      }
    }
    return fileType;
  };

  render() {
    const { bizFile, bnkFile } = this.props;
    const bizFileName = this.getFileName(bizFile);
    const bizFileType = this.getFileType(bizFile);
    const bnkFileName = this.getFileName(bnkFile);
    const bnkFileType = this.getFileType(bnkFile);

    return (
      <Wrapper>
        <SubmitDocumentLabelContainer>
          <SubmitDocumentLabelTitle>증빙서류 제출</SubmitDocumentLabelTitle>
          {(this.props.editMode === EditMode.UPDATE ||
            this.props.editMode === EditMode.CREATE) && (
            <SubmitDocumentLabelHelpText>
              버튼 클릭하여 파일 변경
            </SubmitDocumentLabelHelpText>
          )}
        </SubmitDocumentLabelContainer>
        <SubmitButtonContainer>
          {this.props.isFileUploading ? (
            <>
              <UploadSpinner />
              <UploadSpinnerMobile>
                <span>업</span>
                <span>로</span>
                <span>드</span>&nbsp;
                <span>진</span>
                <span>행</span>&nbsp;
                <span>중</span>
              </UploadSpinnerMobile>
            </>
          ) : (
            <>
              <SubmitBizButtonContainer>
                <SubmitBizDocumentButton>
                  <Button
                    text="사업자 등록증"
                    theme="blue"
                    small
                    disabled={
                      this.props.editMode === EditMode.READ ||
                      this.props.isFileUploading
                    }
                    onClick={() => {
                      var input = document.getElementById('biz_file');
                      if (input) {
                        input.click();
                      }
                    }}
                  />
                  <input
                    type="file"
                    id="biz_file"
                    style={{ display: 'none' }}
                    onChange={(event: any) => {
                      this.props.onFileSubmit(
                        'update',
                        bizFile,
                        event.currentTarget.files[0],
                        'BIZ',
                      );
                    }}
                  />
                </SubmitBizDocumentButton>
                <BizFileViewLabel>
                  {bizFile.id && bizFile.id > 0 && (
                    <FileLinkText
                      onClick={() => {
                        window.open(bizFile.url, '_blank');
                      }}
                    >
                      {bizFileName}
                      {bizFileType}
                    </FileLinkText>
                  )}
                </BizFileViewLabel>
                <BizFileDeleteLabel>
                  {bizFile.id &&
                    bizFile.id > 0 &&
                    this.props.editMode === EditMode.UPDATE && (
                      <FileLinkText
                        onClick={() => {
                          this.props.onFileSubmit('delete', bizFile, null, '');
                        }}
                      >
                        삭제하기
                      </FileLinkText>
                    )}
                </BizFileDeleteLabel>
              </SubmitBizButtonContainer>

              <SubmitBankButtonContainer>
                <SubmitBankDocumentButton>
                  <Button
                    text="통장 사본"
                    theme="blue"
                    small
                    disabled={
                      this.props.editMode === EditMode.READ ||
                      this.props.isFileUploading
                    }
                    onClick={() => {
                      var input = document.getElementById('bnk_file');
                      if (input) {
                        input.click();
                      }
                    }}
                  />
                  <input
                    type="file"
                    id="bnk_file"
                    style={{ display: 'none' }}
                    onChange={(event: any) => {
                      this.props.onFileSubmit(
                        'update',
                        bnkFile,
                        event.currentTarget.files[0],
                        'BNK',
                      );
                    }}
                  />
                </SubmitBankDocumentButton>
                <BankFileViewLabel>
                  {bnkFile.id && bnkFile.id > 0 && (
                    <FileLinkText
                      onClick={() => {
                        window.open(bnkFile.url, '_blank');
                      }}
                    >
                      {bnkFileName}
                      {bnkFileType}
                    </FileLinkText>
                  )}
                </BankFileViewLabel>
                <BankFileDeleteLabel>
                  {bnkFile.id &&
                    bnkFile.id > 0 &&
                    this.props.editMode === EditMode.UPDATE && (
                      <FileLinkText
                        onClick={() => {
                          this.props.onFileSubmit('delete', bnkFile, null, '');
                        }}
                      >
                        삭제하기
                      </FileLinkText>
                    )}
                </BankFileDeleteLabel>
              </SubmitBankButtonContainer>
            </>
          )}
        </SubmitButtonContainer>
      </Wrapper>
    );
  }
}

export default DocumentSubmitComponent;

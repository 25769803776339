import React, { useState } from 'react';

export interface IAddChecklistProps {
  handleAdd: (title: string) => void;
  handleCancel?: () => void;
}

export const AddChecklist: React.SFC<IAddChecklistProps> = props => {
  const [title, setTitle] = useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLTextAreaElement).value &&
        props.handleAdd((e.target as HTMLTextAreaElement).value);
      props.handleCancel && props.handleCancel();
      setTitle('');
    }
    if (e.key === 'Escape') {
      props.handleCancel && props.handleCancel();
      setTitle('');
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    (e.target as HTMLTextAreaElement).value &&
      props.handleAdd((e.target as HTMLTextAreaElement).value);
    props.handleCancel && props.handleCancel();
    setTitle('');
  };

  return (
    <div className="d-box">
      <div>
        <textarea
          autoFocus={true}
          placeholder="체크리스트 이름"
          value={title}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        ></textarea>
      </div>
    </div>
  );
};

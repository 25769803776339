import React, { useState } from 'react';

import useVisible from '../Common/hooks/useVisible';

export interface ILabelProps {
  title: string;
  value: string;
  selected: boolean;
  isDisabled?: boolean;
  isSelectOnly?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete?: (label: string) => void;
}
export const Label: React.SFC<ILabelProps> = props => {
  const onDelete = () => {
    props.handleDelete && props.handleDelete(props.title);
  };

  return (
    <li>
      <input
        type="checkbox"
        id={`lb_${props.value}`}
        value={props.value}
        checked={props.selected}
        disabled={props.isDisabled}
        onChange={props.handleChange}
      />
      <label htmlFor={`lb_${props.value}`}>{props.title}</label>

      {!props.isSelectOnly && (
        <button type="button" className="btn-delete" onClick={onDelete}>
          <span>라벨삭제</span>
        </button>
      )}
    </li>
  );
};

export interface ILabelSelectorProps {
  title: string;
  isSelectOnly?: boolean;
  handleAdd?: (label: string) => void;
}
export const LabelSelector: React.SFC<ILabelSelectorProps> = props => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  const [newLabel, setNewLabel] = useState<string>('');

  let className = 'select-w';
  className += isVisible ? ' selected' : '';

  return (
    <div className={className} ref={ref}>
      <button type="button" onClick={() => setIsVisible(!isVisible)}>
        {props.title}
      </button>
      <div className="option-box">
        {!props.isSelectOnly && (
          <fieldset className="add">
            <legend>
              <label htmlFor="laAdd">라벨추가</label>
            </legend>
            <input
              type="text"
              id="laAdd"
              placeholder="추가"
              value={newLabel}
              onChange={e => {
                setNewLabel(e.target.value);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  props.handleAdd && newLabel && props.handleAdd(newLabel);
                  setNewLabel('');
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                props.handleAdd && newLabel && props.handleAdd(newLabel);
                setNewLabel('');
              }}
            >
              <span>추가</span>
            </button>
          </fieldset>
        )}

        <ul>{props.children}</ul>
      </div>
    </div>
  );
};

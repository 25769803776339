import React, { ChangeEvent, FC } from 'react';
import { useHistory } from 'react-router-dom';

import { LoginModal } from '.';

const LoginButton: FC<{
  handleChangeEmail: (e: ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (e: ChangeEvent<HTMLInputElement>) => void;
  handleLogin: () => void;
  current: string;
  setIsOpen: (val: boolean) => void;
  modalIsOpen: boolean;
  setModalIsOpen: (val: boolean) => void;
}> = props => {
  const history = useHistory();

  const handleModalClose = () => {
    history.push(`/${props.current}`);
    props.setModalIsOpen(false);
  };
  const handleLoginAndRedirect = () => {
    props.handleLogin();
  };

  return (
    <>
      {props.current !== 'signup' && (
        <div className="utill">
          <button
            className="btn-uti"
            onClick={() => props.setModalIsOpen(true)}
          >
            시소 로그인
          </button>
          <LoginModal
            {...props}
            handleLoginAndRedirect={() => handleLoginAndRedirect()}
            handleModalClose={() => handleModalClose()}
            handleMenuClose={() => props.setIsOpen(false)}
          />
        </div>
      )}
    </>
  );
};

export default LoginButton;

import React, { FC, useEffect } from 'react';
import { EProjectStatus } from '../../../store/Dashboard';
import { IRufreeMatching } from '../../../store/Dashboard/models/RufreeMatching';
import { ISchedule } from '../../../store/Dashboard/models/Schedule';
import { ISprint } from '../../../store/Dashboard/models/Sprint';
import { ISprintReport } from '../../../store/Dashboard/models/SprintReport';

import Sprint from './Sprint';

interface IProps {
  className?: string;
  reports: ISprintReport[];
  isProjectEndHold: boolean;
  isProjectHolding: boolean;
  selectedSprint: number;
  schedule: ISchedule | null;
  machings: IRufreeMatching[];
}
const SprintList: FC<IProps> = ({
  className,
  isProjectEndHold,
  isProjectHolding,
  machings,
  reports,
  schedule,
  selectedSprint,
}) => {
  const refs: any = schedule?.sprints.reduce<any>((acc, value, index) => {
    acc[value.sprint] = React.createRef<React.RefObject<HTMLDivElement>>();
    return acc;
  }, {});

  const scroll = (id: string) => {
    if (refs[id].current) {
      refs[id].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  useEffect(() => {
    if (schedule && schedule.sprints.length > 0) {
      if (selectedSprint) {
        scroll(String(selectedSprint));
      }
    }
  }, [refs]);

  return (
    <>
      {schedule &&
        schedule.ongoingSprints.map((sprint: ISprint, i: number) => (
          <Sprint
            key={i}
            innerRef={refs[sprint.sprint]}
            sprintStatus={EProjectStatus.ONGOING}
            isOpen={selectedSprint ? sprint.sprint === selectedSprint : true}
            isProjectEndHold={isProjectEndHold}
            isProjectHolding={isProjectHolding}
            machings={machings}
            reports={reports.find(report => report.sprint === sprint.sprint)}
            sprint={sprint}
          />
        ))}

      {schedule &&
        schedule.scheduledSprints.map((sprint: ISprint, i: number) => (
          <Sprint
            key={i}
            innerRef={refs[sprint.sprint]}
            sprintStatus={EProjectStatus.SCHEDULE}
            isProjectEndHold={isProjectEndHold}
            isProjectHolding={isProjectHolding}
            machings={machings}
            isOpen={
              selectedSprint
                ? sprint.sprint === selectedSprint
                : schedule.ongoingSprints.length === 0 && i === 0
                ? true
                : false
            }
            reports={reports.find(report => report.sprint === sprint.sprint)}
            sprint={sprint}
          />
        ))}

      {schedule &&
        schedule.doneSprints.map((sprint: ISprint, i: number) => (
          <Sprint
            key={i}
            innerRef={refs[sprint.sprint]}
            sprintStatus={EProjectStatus.END}
            isProjectEndHold={isProjectEndHold}
            isProjectHolding={isProjectHolding}
            machings={machings}
            isOpen={selectedSprint ? sprint.sprint === selectedSprint : false}
            reports={reports.find(report => report.sprint === sprint.sprint)}
            sprint={sprint}
          />
        ))}
    </>
  );
};

export default SprintList;

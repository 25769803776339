import React, { useState } from 'react';

export interface IAddPostProps {
  handleAdd: (title: string) => void;
  handleCancel?: () => void;
}

export const AddPost: React.SFC<IAddPostProps> = props => {
  const [title, setTitle] = useState<string>('');

  const _onAdd = (title: string) => {
    if (title) {
      props.handleAdd(title);
      setTitle('');
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      _onAdd((e.target as HTMLInputElement).value);
    }
    if (e.key === 'Escape') {
      props.handleCancel && props.handleCancel();
      setTitle('');
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    _onAdd((e.target as HTMLInputElement).value);
  };

  return (
    <ul>
      <li className="modify">
        <input
          type="text"
          value={title}
          title="메뉴입력"
          autoFocus={true}
          onClick={e => {
            e.stopPropagation();
          }}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
        ></input>

        <button type="button" className="btn-move">
          <span>메뉴이동</span>
        </button>
        <span className="btn-md"></span>
      </li>
    </ul>
  );
};

import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { Pricing as Comps } from '../../organisms/Etc';

// @observer

interface Props {}

interface HomeState {
  windowWidth: number;
  slideEvent: any;
  activeElementCount: number;
  liCount: number;
  projects: string[];
}

class PricingPage extends Component<Props> {
  private slidesUlRef: React.RefObject<HTMLDivElement>;

  constructor(props: any) {
    super(props);
    this.windowResizeEvent = this.windowResizeEvent.bind(this);
    this.slideIntervalEvent = this.slideIntervalEvent.bind(this);
    this.slideReactionEvent = this.slideReactionEvent.bind(this);
    this.slideMoveEvent = this.slideMoveEvent.bind(this);
    this.slidesUlRef = React.createRef();
  }

  state: HomeState = {
    windowWidth: 100,
    slideEvent: {},
    activeElementCount: 0,
    liCount: 3,
    projects: [],
  };

  componentDidMount() {
    window.addEventListener('resize', this.windowResizeEvent);
    this.setState({
      slideEvent: setInterval(this.slideIntervalEvent, 3000),
    });
    this.windowResizeEvent();
  }

  windowResizeEvent() {
    this.setState({
      windowWidth: window.innerWidth,
    });
    this.slideMoveEvent('RESIZE');
  }

  slideIntervalEvent = () => {
    let activeElementCount = this.state.activeElementCount;
    activeElementCount =
      activeElementCount + 1 < this.state.liCount ? activeElementCount + 1 : 0;
    this.setState({
      activeElementCount: activeElementCount,
    });
  };

  slideReactionEvent = () => {
    if (this.slidesUlRef.current) {
      this.slidesUlRef.current.style.transform = `translateZ(0px) translateX(-${
        this.state.windowWidth * this.state.activeElementCount
      }px)`;
    }
  };

  slideMoveEvent = (SLIDE_MOVE: string) => {
    clearInterval(this.state.slideEvent);
    let activeElementCount = this.state.activeElementCount;
    if (SLIDE_MOVE === 'RIGHT' && activeElementCount + 1 < this.state.liCount) {
      activeElementCount += 1;
    } else if (
      SLIDE_MOVE === 'RIGHT' &&
      activeElementCount + 1 == this.state.liCount
    ) {
      activeElementCount = 0;
    } else if (SLIDE_MOVE === 'LEFT' && activeElementCount > 0) {
      activeElementCount -= 1;
    } else if (SLIDE_MOVE === 'LEFT' && activeElementCount == 0) {
      activeElementCount = this.state.liCount - 1;
    } else if (SLIDE_MOVE === 'RESIZE') {
      this.slideReactionEvent();
    }
    this.setState(
      {
        activeElementCount: activeElementCount,
      },
      () => {
        let stateWidth: number;

        if (!isMobile) {
          this.state.windowWidth > 1200
            ? (stateWidth = 1200)
            : (stateWidth = this.state.windowWidth);
        } else {
          this.state.windowWidth > 360
            ? (stateWidth = 330)
            : (stateWidth = this.state.windowWidth);
        }
        if (this.slidesUlRef.current) {
          this.slidesUlRef.current.style.transform = `translateZ(0px) translateX(-${
            stateWidth * this.state.activeElementCount
          }px)`;
        }
      },
    );
  };

  render() {
    return (
      <>
        <Comps.PricingWrapper>
          <div className="pricing">
            <Comps.TopInfo>
              <p>
                <em>비즈니스 플랜과 상황에 맞춰</em>
                <br />
                프로젝트의 규모를 산정하고 인력을 조정하여,
                <br />
                <em>합리적인 예산 설계</em>가 가능합니다
              </p>
            </Comps.TopInfo>
            <Comps.Step>
              <div className="inner" style={{ paddingTop: '99px' }}>
                <ol>
                  <li>
                    <p>
                      <em>Step1. 팀별 기준 비용 산정</em>2주 단위로 부과되는
                      팀원들의 임금을 산정합니다.
                    </p>
                    <table>
                      <tr>
                        <th scope="row">팀 구분</th>
                        <td>
                          <img
                            src={require('../../../static/images/mem_pro02.png')}
                            alt=""
                          />
                          <span>기획자</span>
                        </td>
                        <td>
                          <img
                            src={require('../../../static/images/mem_pro03.png')}
                            alt=""
                          />
                          <span>디자이너</span>
                        </td>
                        <td>
                          <img
                            src={require('../../../static/images/mem_pro01.png')}
                            alt=""
                          />
                          <span>개발자</span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">단위 비용(예시)</th>
                        <td>150 만원</td>
                        <td>160 만원</td>
                        <td>180 만원</td>
                      </tr>
                    </table>
                    <ul>
                      <li>
                        월급과 같은 개념으로, 각각의 리모트 팀 멤버들과 기본
                        업무 비용 계약을 하게 됩니다
                      </li>
                      <li>
                        단위 비용은 주어진 예산과 프로젝트 수준, 기대사항에 따라
                        변동될수 있습니다
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <em>step2. 예산 플래닝</em>리모트 팀으로 채용할 포지션을
                      구분하고, 업무플랜과 상황에 맞춰 전체 예산을 설계합니다.
                    </p>
                    <div className="img">
                      <img
                        src={require('../../../static/images/pricing.png')}
                        alt=""
                        style={{ maxWidth: '865px' }}
                      />
                      {/* <ul>
                        <li>예상 시작일<em>2019/03/11</em></li>
                        <li>예상 종료일<em>2019/10/06</em></li>
                        <li>예상 금액<em>58,000,000원</em></li>
                      </ul> */}
                    </div>
                    <ul>
                      <li>
                        예산플래닝은 프로젝트의 상황에 맞춰서 재조정이
                        가능합니다
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      <em>Step3. 비용정산</em>리모트 팀 멤버들의 임금은 2주 마다
                      시소를 통해 정산됩니다
                    </p>
                    <div className="img">
                      <img
                        src={require('../../../static/images/w_process_img.png')}
                        alt=""
                      />
                    </div>
                    <ul>
                      <li>
                        모든 비용은 시소를 통해 거래되며, 팀 멤버들에게 정산이
                        이뤄집니다.
                      </li>
                      <li>
                        일반 아웃소싱 형태로 계약시, 충전금 형태로 비용 정산이
                        이뤄집니다. (세금계산서 발급 가능)
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </Comps.Step>
            <Comps.CostList>
              <h3>실 프로젝트 사례</h3>
              <div>
                <h4>"앱 아이디어만 있습니다"</h4>
                <ul>
                  <li>시소 매니저</li>
                  <li>앱 기획자 2S</li>
                  <li>앱 디자이너 3S</li>
                  <li>리액트 네이티브 개발자 5S</li>
                  <li>백엔드 개발자 6S</li>
                </ul>
                <strong>4개월, 3,700만원</strong>
              </div>
              <div>
                <h4>"개발만 부탁드립니다"</h4>
                <ul>
                  <li>시소 매니저</li>
                  <li>퍼블리셔 1S</li>
                  <li>프론트엔드 개발자 3S</li>
                  <li>백엔드 개발자 3S</li>
                </ul>
                <strong>2개월, 1,360만원</strong>
              </div>
              <div>
                <h4>"소개페이지가 필요합니다"</h4>
                <ul>
                  <li>시소 매니저</li>
                  <li>웹 디자이너 1S</li>
                  <li>퍼블리셔 1S</li>
                  <li>WP 개발자 1S</li>
                </ul>
                <strong>1개월, 500만원</strong>
              </div>
            </Comps.CostList>
            <Comps.SayBox>
              <h3>많은 기업들이 시소를 통해 서비스를 런칭하고 있습니다</h3>
              <div
                ref={this.slidesUlRef}
                style={{
                  width: this.state.windowWidth * this.state.liCount,
                  transitionDuration: '.5s',
                }}
                className="inner"
              >
                <div className="cnts" style={{ width: this.state.windowWidth }}>
                  <div className="img">
                    {/* <img src={require("../../../static/images/@say.png")}alt="" /> */}
                  </div>
                  <p>
                    "보통 일반 외주는 정해진 가격으로 진행을 하게 되는데, 시소는
                    아웃소싱이지만 필요한 업무와 인력에 맞춰서 스프린트 형태로
                    비용이 집행된다는 점입니다. 이를 통해 내부에서 필요한 부분만
                    시소를 통한 인력과 협업을 하며 비용을 관리할 수 있었습니다."
                  </p>
                </div>
                <div className="cnts" style={{ width: this.state.windowWidth }}>
                  <div className="img">
                    {/* <img src={require("../../../static/images/@say.png")} alt="" /> */}
                  </div>
                  <p>
                    "스프린트 프로세스를 통해 프로젝트를 진행하며, 기획이 완료된
                    후 한달간 쉬면서 더 고민을 할 시간이 있었습니다. 스타트업의
                    경우 일을 진행하면서 기획적 변경이나 요구사항이 바뀌는 있을
                    수밖에 없는데, 시소의 스프린트 프로세스는 이런 경우 다른
                    외주사들과는 다르게 지속적으로 협업이 가능하다는 가장 큰
                    장점이 있었습니다."
                  </p>
                </div>
                <div className="cnts" style={{ width: this.state.windowWidth }}>
                  <div className="img">
                    {/* <img src={require("../../../static/images/@say.png")} alt="" /> */}
                  </div>
                  <p>
                    "일반 프리랜서 서비스들과는 다르게, 우리 프로젝트에 수준과
                    난이도에 필요한 멤버들을 선정해주고 진행하면서 문제가 되는
                    부분들은 시소 시스템과 매니져들을 통해 해결이 가능하다는
                    점이 가장 매력적이었습니다."
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="prev"
                onClick={() => this.slideMoveEvent('LEFT')}
              >
                <span>이전</span>
              </button>
              <button
                type="button"
                className="next"
                onClick={() => this.slideMoveEvent('RIGHT')}
              >
                <span>다음</span>
              </button>
            </Comps.SayBox>
            <Comps.Faq>
              <h2>자주묻는 질문</h2>
              <ul>
                <li>
                  <strong>
                    Q. 스프린트 기간과 비용은 어떤 기준으로 산정되나요?
                  </strong>
                  <p>
                    A. 일반적으로 2주를 기준으로 비용과 업무량을 측정합니다. 한
                    주에 15~20시간을 일하는 것을 기준으로 스타트업 실제
                    연봉수준을 비교하여 시소의 인력비용 기준을 매해 산정하고
                    있으며, 이를 토대로 1스프린트당 비용이 산정되며, 알유프리의
                    기준가는 모두 시소 기준가로 통일되어서 진행됩니다.
                  </p>
                </li>
                <li>
                  <strong>
                    Q. 스프린트 예상가는 무엇인가요? 확정금액이 아닌가요?
                  </strong>
                  <p>
                    A. 네, 스프린트 예상가는 각 시점마다 예측되는 업무 범위와
                    난이도, 투입인력에 따라 변동됩니다. 주로 기획서와 디자인이
                    없는 상황에서 개발 범위는 변경이 자주 있는 편입니다. 또한
                    스프린트가 추가되고 축소되는 것에 따라 비용도 같이 변동되게
                    됩니다.
                  </p>
                </li>
                <li>
                  <strong>Q. 시소 매니저 비용은 어떻게 책정되나요?</strong>
                  <p>
                    A. 시소 매니저는 프로젝트의 조율자로서 매 스프린트에
                    투입됩니다. 하지만 스프린트 별 난이도와, 매니징을 해야할
                    팀원의 수에 따라 매니저 비용이 달라집니다. 따라서 정확한
                    비용은 의뢰검수 단계에서 산출이 가능합니다.
                  </p>
                </li>
              </ul>
            </Comps.Faq>
          </div>
        </Comps.PricingWrapper>
      </>
    );
  }
}

export default PricingPage;

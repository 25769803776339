import { types } from 'mobx-state-tree';

import { SubTaskModel } from './SubTask';

export const SubTaskListModel = types.model('SubTaskList', {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  order: types.number,
  sub_task_list_id: types.string,
  title: types.string,
  task: types.string,
  sub_tasks: types.array(SubTaskModel),
});
type SubTaskListType = typeof SubTaskListModel.Type;

export interface SubTaskList extends SubTaskListType {}

import React from 'react';
import styled from 'styled-components/macro';

export const Footer = styled.footer`
  display: block;
  padding: 85px 0 80px;
  background: #161d2e;
  font-size: 15px;
  line-height: 28px;
  min-height: 320px;
  position: relative;
  .inner {
    max-width: 1220px;
    margin: 0 auto;
    color: #fff;
  }
  @media only screen and (max-width: 640px) {
    padding: 20px;
    font-size: 12px;
    line-height: 20px;
  }
`;

export const FooterInfo = styled.div`
  float: left;
  em {
    font-style: normal;
  }
  @media only screen and (max-width: 640px) {
    float: none;
  }
`;

export const FMenu = styled.div`
  float: right;
  ul {
    list-style: none;
    padding: 0;
    li {
      a {
        color: #fff;
      }
      &.notionSeeso {
        position: relative;
        a {
          padding-left: 25px;
          img {
            top: 2px;
            left: 0px;
            height: 22px;
            position: absolute;
            width: auto;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    float: none;
    padding-top: 30px;
  }
`;

import { inject, observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { PALETTE } from '../../constants/color';
import { PC } from '../../constants/media';

import NotFoundSVG from '../../static/icons/icon-404-error.svg';
import { AppStore } from '../../store/AppStore';
// @observer

const Wrapper = styled.section`
  min-height: 959px;
  padding-top: 216px;
  * {
    margin: 0 auto;
  }

  @media only screen and (max-width: ${PC}) {
    min-height: 750px;
    padding-top: 100px;
  }
`;
const Title = styled.h1`
  margin-top: 11px;
  font-size: 53px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    font-size: 25px;
  }
`;
const Img = styled.div`
  width: 330.4px;
  height: 154.3px;
  background: url('${NotFoundSVG}') no-repeat 100% 100%;

  @media only screen and (max-width: ${PC}) {
    width: 200px;
    height: 100px;
  }
`;

const Info = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${PALETTE['color-red']};
  margin-top: 24px;

  a,
  a:hover {
    color: ${PALETTE['color-red']};
  }

  @media only screen and (max-width: ${PC}) {
    font-size: 14px;
  }
`;

const ButtonRow = styled.div`
  width: 424px;
  margin-top: 72px;

  a {
    width: 196px;
    height: 56px;
    padding: 16px 51px 16px 52px;
    border-radius: 3px;
    background-color: ${PALETTE['purpleish-blue']};
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }

  a + a {
    margin-left: 32px;
  }

  @media only screen and (max-width: ${PC}) {
    width: 196px;
    margin-top: 50px;

    a {
      display: block;
      font-size: 15px;
      padding-top: 17px;
    }
    a + a {
      margin-left: 0;
      margin-top: 15px;
    }
  }
`;

interface Props {
  history: any;
}
interface InjectedProps extends Props {
  appStore: AppStore;
}

const NotoundPage: React.FC<Props> = inject('appStore')(
  observer(props => {
    return (
      <Wrapper>
        <Img />
        <Title>페이지를 찾을 수 없습니다.</Title>
        <Info>
          <p>주소를 잘못 입력했거나, 삭제된 페이지를 요청했을 수 있습니다. </p>
          <p>
            도움이 필요하시면{' '}
            <a href="mailto:partner@seeso.kr">partner@seeso.kr</a>으로
            연락주세요.
          </p>
        </Info>

        <ButtonRow>
          <Link to="/">홈으로 이동</Link>
          <Link to="#" onClick={props.history.goBack}>
            이전으로
          </Link>
        </ButtonRow>
      </Wrapper>
    );
  }),
);

export default NotoundPage;

import { Intent } from '@blueprintjs/core';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';

import { PC } from '../../../constants/media';

import { EditMode } from '../../../constants/mode';

import { AppStore } from '../../../store/AppStore';
import { MyPageFile } from '../../../store/MyPage/MyPageModel';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import CheckButton from '../../molecules/MyPage/CheckButton';

import DocumentSubmitComponent from '../../molecules/MyPage/DocumentSubmitComponent';
import RadioButton from '../../molecules/MyPage/RadioButton';
import TextInput from '../../molecules/MyPage/TextInput';
import { MyPageTab } from '../../pages/MyPage/Constants';

const Wrapper = styled.div``;

const TabTitleContainer = styled.div`
  display: flex;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    height: 32px;
    padding-left: 13px;
    background-color: ${PALETTE['purpleish-blue']};
    border-radius: 3px;
  }
`;
const TabContentContainer = styled.div`
  border-radius: 8px;

  @media only screen and (min-width: ${PC}) {
    width: 1080px;
    height: 610px;
    background-color: #f6f6ff;
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

const TabTitle = styled.div`
  margin-right: 24px;
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['color-disabled']};

  border-bottom: solid 2px transparent;

  :hover {
    cursor: pointer;
  }

  &.active {
    transition: 300ms;
    font-weight: bold;
    color: ${PALETTE['purpleish-blue']};
    padding-bottom: 5.5px;
    border-bottom: solid 2px ${PALETTE['purpleish-blue']};
  }

  @media only screen and (max-width: ${PC}) {
    padding-top: 4px;
    margin-right: 28px;
    font-size: 15px;
    line-height: 22px;
    color: ${PALETTE['color-pale-purple']};

    &.active {
      font-weight: bold;
      color: ${PALETTE['button-style']};
      margin-bottom: 5px;
      border-bottom: solid 1px ${PALETTE['button-style']};
    }
  }
}
`;

const TabContent = styled.div`
  display: none;

  &.active {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;
const TabContentLeftContainer = styled.div`
  width: 540px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;
const TabContentRightContainer = styled.div`
  width: 540px;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
  }
`;

const CompanyInfoSection = styled.div``;
const ContractPersonSection = styled.div``;
const TaxPersonSection = styled.div``;

const SectionTitle = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    font-size: 15px;
  }
`;

const CompanyInfoSectionTitle = styled(SectionTitle)`
  padding-top: 48px;
  padding-left: 34px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 20px;
    padding-left: 0px;
    font-size: 15px;
  }
`;

const ContractPersonInfoSectionTitle = styled(SectionTitle)`
  padding-top: 48px;
  padding-left: 50px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 30px;
    padding-left: 0px;
    font-size: 15px;
  }
`;
const TaxPersonInfoSectionTitle = styled(SectionTitle)`
  padding-top: 60px;
  padding-left: 50px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 48px;
    padding-left: 0px;
    font-size: 15px;
  }
`;

const CompanyNameInput = styled.div`
  padding-top: 30px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 24px;
    padding-left: 0px;
  }
`;
const RepresentativeNameInput = styled.div`
  padding-top: 34px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 24px;
    padding-left: 0px;
  }
`;
const BizNoInput = styled.div`
  padding-top: 6px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 16px;
    padding-left: 0px;
  }
`;
const BizPhoneInput = styled.div`
  padding-top: 6px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ContractPersonNameInput = styled.div`
  padding-top: 30px;
  padding-left: 60px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 24px;
    padding-left: 0px;
  }
`;
const ContractPersonPhoneInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;
  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ContractPersonEmailInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;
  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const TaxPersonNameInput = styled.div`
  padding-top: 30px;
  padding-left: 60px;
  @media only screen and (max-width: ${PC}) {
    padding-top: 16px;
    padding-left: 0px;
  }
`;
const TaxPersonPhoneInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;
  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const TaxPersonEmailInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;
  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ClientTypeContainer = styled.div`
  padding-top: 6px;
  padding-left: 46px;
  display: flex;

  @media only screen and (max-width: ${PC}) {
    display: block;
    padding-top: 16px;
    padding-left: 0px;
  }
`;
const ClientTypeMobileBG = styled.div`
  display: none;
  @media only screen and (max-width: ${PC}) {
    display: block;
    height: 40px;
    margin-top: 12px;
    background-color: ${PALETTE['very-light-pink']};
  }
`;
const ClientTypeLabel = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 21px;
  letter-spacing: normal;
  color: ${PALETTE['black']};

  @media only screen and (max-width: ${PC}) {
    padding-left: 6px;
  }
`;
const ClientTypeButtonContainer = styled.div`
  display: flex;
  margin-left: 70px;
  @media only screen and (max-width: ${PC}) {
    position: relative;
    top: -30px;
    width: 100%;
    margin: 0;
  }
`;
const CompanyButton = styled.div`
  @media only screen and (max-width: ${PC}) {
    margin-left: 16px;
  }
`;
const PersonButton = styled.div`
  margin-left: 24px;

  @media only screen and (max-width: ${PC}) {
    margin-left: 36px;
  }
`;

const ContractAndTaxPerson = styled.div`
  position: relative;
  top: -18px;
  left: 276px;
  @media only screen and (max-width: ${PC}) {
    position: static;

    padding-top: 14px;
    margin-left: auto

    width: 156px;
    text-align: right;
  }    
`;

const UserInfoSection = styled.div``;
const UserInfoSectionTitle = styled(SectionTitle)`
  padding-top: 48px;
  padding-left: 34px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 20px;
    padding-left: 0px;
    font-size: 15px;
  }
`;
const ToolInfoSection = styled.div``;
const ToolInfoSectionTitlePC = styled(SectionTitle)`
  padding-top: 48px;
  padding-left: 50px;

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;
const ToolInfoSectionTitleMobile = styled(SectionTitle)`
  padding-top: 48px;
  padding-left: 0px;

  @media only screen and (min-width: ${PC}) {
    display: none;
  }
`;
const UserNameInput = styled.div`
  padding-top: 30px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 20px;
    padding-left: 0px;
  }
`;
const UserEmailInput = styled.div`
  padding-top: 6px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const UserPhoneInput = styled.div`
  padding-top: 6px;
  padding-left: 40px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ToolSlackInput = styled.div`
  padding-top: 30px;
  padding-left: 60px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 20px;
    padding-left: 0px;
  }
`;
const ToolTrelloInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ToolZeplinInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const ToolGithubInput = styled.div`
  padding-top: 6px;
  padding-left: 60px;

  @media only screen and (max-width: ${PC}) {
    padding-top: 12px;
    padding-left: 0px;
  }
`;
const CautionMessage = styled.div`
  padding-top: 30px;
  padding-left: 50px;
  width: 480px;

  font-family: 'Noto Sans KR', sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['color-disabled']};

  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;

interface ClientInfoTabProps {
  selectedTab: MyPageTab;
  onTabChanged: (newTab: MyPageTab) => void;
  editMode: EditMode;
}

interface InjectedProps extends ClientInfoTabProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class ClientInfoTabContent extends Component<ClientInfoTabProps> {
  get injected() {
    return this.props as InjectedProps;
  }
  get store() {
    return this.injected.appStore.myPageStore;
  }

  handleOnClientTypeChanged = (value: string) => {
    this.store.setCompanyType(value);
    this.forceUpdate();
  };

  handleOnContractAndTaxPersonCheckedChanged = (value: boolean) => {
    this.store.setHasSameContractTaxPerson(value);
    if (this.store.hasSameContractTaxPerson) {
      this.store.setTaxPersonName(this.store.contractPersonName);
      this.store.setTaxPersonEmail(this.store.contractPersonEmail);
      this.store.setTaxPersonPhone(this.store.contractPersonPhone);
    }
    this.forceUpdate();
  };

  handleFileSubmit = async (
    action: string,
    fileModel: MyPageFile,
    file: File | null,
    filetype: string,
  ) => {
    try {
      this.store.setIsFileUploading(true);
      if (action === 'delete') {
        await this.store.updateClientFile(fileModel, action);
        AppToaster.show({
          message: '파일이 삭제되었습니다.',
          intent: Intent.SUCCESS,
        });
      } else if (action === 'update' && file) {
        const { userStore } = this.injected.appStore;

        fileModel.setFile(file);
        fileModel.setUserId(userStore.userId);
        fileModel.setFilename(file.name);
        if (this.store.clientId > 0) {
          fileModel.setClient(this.store.clientCLId);
        }
        fileModel.setFiletype(filetype);

        await this.store.updateClientFile(
          fileModel,
          fileModel && fileModel.id && fileModel.id > 0 ? 'put' : 'post',
        );
        AppToaster.show({
          message: '파일이 업데이트되었습니다.',
          intent: Intent.SUCCESS,
        });
      }
      this.forceUpdate();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: '오류: ' + error,
        intent: Intent.DANGER,
      });
    } finally {
      this.store.setIsFileUploading(false);
    }
  };

  render() {
    return (
      <Wrapper>
        <TabTitleContainer>
          <TabTitle
            className={
              this.props.selectedTab === MyPageTab.COMPANY ? 'active' : ''
            }
            onClick={() => {
              this.props.onTabChanged(MyPageTab.COMPANY);
            }}
          >
            회사정보
          </TabTitle>
          <TabTitle
            className={
              this.props.selectedTab === MyPageTab.USER ? 'active' : ''
            }
            onClick={() => {
              this.props.onTabChanged(MyPageTab.USER);
            }}
          >
            내 정보
          </TabTitle>
        </TabTitleContainer>
        <TabContentContainer>
          <TabContent
            className={
              this.props.selectedTab === MyPageTab.COMPANY ? 'active' : ''
            }
          >
            <TabContentLeftContainer>
              <CompanyInfoSection>
                <CompanyInfoSectionTitle>
                  1. 계약에 필요한 회사정보를 입력해 주세요.
                </CompanyInfoSectionTitle>
                <CompanyNameInput>
                  <TextInput
                    label="회사 이름"
                    required
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.companyName}
                    onChange={(newValue: string) => {
                      this.store.setCompanyName(newValue);
                    }}
                    validateMessage={this.store.companyNameError}
                  />
                </CompanyNameInput>
                <ClientTypeContainer>
                  <ClientTypeLabel>회사 종류 선택</ClientTypeLabel>
                  <ClientTypeMobileBG />
                  <ClientTypeButtonContainer>
                    <CompanyButton>
                      <RadioButton
                        name="client_type"
                        value="기업"
                        checked={this.store.companyType === '기업'}
                        readonly={this.props.editMode === EditMode.READ}
                        onChange={this.handleOnClientTypeChanged}
                      />
                    </CompanyButton>
                    <PersonButton>
                      <RadioButton
                        name="client_type"
                        value="개인"
                        checked={this.store.companyType === '개인'}
                        readonly={this.props.editMode === EditMode.READ}
                        onChange={this.handleOnClientTypeChanged}
                      />
                    </PersonButton>
                    <PersonButton>
                      <RadioButton
                        name="client_type"
                        value="해외법인"
                        checked={this.store.companyType === '해외법인'}
                        readonly={this.props.editMode === EditMode.READ}
                        onChange={this.handleOnClientTypeChanged}
                      />
                    </PersonButton>
                  </ClientTypeButtonContainer>
                </ClientTypeContainer>
                <RepresentativeNameInput>
                  <TextInput
                    label="대표자 이름"
                    required
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.representativeName}
                    onChange={(newValue: string) => {
                      this.store.setRepresentativeName(newValue);
                    }}
                    validateMessage={this.store.representativeNameError}
                  />
                </RepresentativeNameInput>
                <BizNoInput>
                  <TextInput
                    label="사업자 등록 번호"
                    placeholder={'000-00-00000'}
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.bizNo}
                    onChange={(newValue: string) => {
                      this.store.setBizNo(newValue);
                    }}
                  />
                </BizNoInput>
                <BizPhoneInput>
                  <TextInput
                    label="회사 주소"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.bizAddress}
                    onChange={(newValue: string) => {
                      this.store.setBizAddress(newValue);
                    }}
                  />
                </BizPhoneInput>
                <DocumentSubmitComponent
                  bizFile={this.store.bizFile}
                  bnkFile={this.store.bnkFile}
                  editMode={this.props.editMode}
                  onFileSubmit={this.handleFileSubmit}
                  isFileUploading={this.store.isFileUploading}
                />
                {(this.props.editMode === EditMode.CREATE ||
                  this.props.editMode === EditMode.READ) && (
                  <CautionMessage>
                    ※ 회사당 하나의 클라이언트 번호를 부여합니다. 클라이언트
                    번호가 부여된 이후 필요에 따라 시소의 관리자가 회사정보를
                    수정할 수 있으며, 수정된 정보는 상호 동기화 됩니다. 회사
                    정보를 늘 최신 정보로 유지해 주시고, 되도록 한 회사당 하나의
                    계정으로 사이트를 이용해주세요.
                  </CautionMessage>
                )}
              </CompanyInfoSection>
            </TabContentLeftContainer>

            <TabContentRightContainer>
              <ContractPersonSection>
                <ContractPersonInfoSectionTitle>
                  2. 계약 담당자 정보를 입력해 주세요.
                </ContractPersonInfoSectionTitle>
                <ContractPersonNameInput>
                  <TextInput
                    label="이름"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.contractPersonName}
                    onChange={(newValue: string) => {
                      this.store.setContractPersonName(newValue);
                      if (this.store.hasSameContractTaxPerson) {
                        this.store.setTaxPersonName(newValue);
                      }
                    }}
                  />
                </ContractPersonNameInput>
                <ContractPersonPhoneInput>
                  <TextInput
                    label="연락처"
                    placeholder="010-0000-0000"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.contractPersonPhone}
                    onChange={(newValue: string) => {
                      this.store.setContractPersonPhone(newValue);
                      if (this.store.hasSameContractTaxPerson) {
                        this.store.setTaxPersonPhone(newValue);
                      }
                    }}
                  />
                </ContractPersonPhoneInput>
                <ContractPersonEmailInput>
                  <TextInput
                    label="이메일"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.contractPersonEmail}
                    onChange={(newValue: string) => {
                      this.store.setContractPersonEmail(newValue);
                      if (this.store.hasSameContractTaxPerson) {
                        this.store.setTaxPersonEmail(newValue);
                      }
                    }}
                  />
                </ContractPersonEmailInput>
              </ContractPersonSection>

              <TaxPersonSection>
                <TaxPersonInfoSectionTitle>
                  <div>3. 세금계산서 담당자 정보를 입력해 주세요.</div>
                  <ContractAndTaxPerson>
                    <CheckButton
                      name=""
                      value="계약 담당자와 동일"
                      checked={this.store.hasSameContractTaxPerson}
                      readonly={this.props.editMode === EditMode.READ}
                      onChange={this.handleOnContractAndTaxPersonCheckedChanged}
                    />
                  </ContractAndTaxPerson>
                </TaxPersonInfoSectionTitle>
                <TaxPersonNameInput>
                  <TextInput
                    label="이름"
                    textValue={this.store.taxPersonName}
                    onChange={(newValue: string) => {
                      this.store.setTaxPersonName(newValue);
                    }}
                    disabled={this.store.hasSameContractTaxPerson}
                    readonly={this.props.editMode === EditMode.READ}
                  />
                </TaxPersonNameInput>
                <TaxPersonPhoneInput>
                  <TextInput
                    label="연락처"
                    placeholder="010-0000-0000"
                    textValue={this.store.taxPersonPhone}
                    onChange={(newValue: string) => {
                      this.store.setTaxPersonPhone(newValue);
                    }}
                    disabled={this.store.hasSameContractTaxPerson}
                    readonly={this.props.editMode === EditMode.READ}
                  />
                </TaxPersonPhoneInput>
                <TaxPersonEmailInput>
                  <TextInput
                    label="이메일"
                    textValue={this.store.taxPersonEmail}
                    onChange={(newValue: string) => {
                      this.store.setTaxPersonEmail(newValue);
                    }}
                    disabled={this.store.hasSameContractTaxPerson}
                    readonly={this.props.editMode === EditMode.READ}
                  />
                </TaxPersonEmailInput>
              </TaxPersonSection>
            </TabContentRightContainer>
          </TabContent>
          <TabContent
            className={
              this.props.selectedTab === MyPageTab.USER ? 'active' : ''
            }
          >
            <TabContentLeftContainer>
              <UserInfoSection>
                <UserInfoSectionTitle>
                  1. 회원 정보를 확인해 주세요.
                </UserInfoSectionTitle>
                <UserNameInput>
                  <TextInput
                    label="이름"
                    required
                    disabled
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.userName}
                    onChange={(newValue: string) => {
                      this.store.setUserName(newValue);
                    }}
                    validateMessage={this.store.userNameError}
                  />
                </UserNameInput>
                <UserEmailInput>
                  <TextInput
                    label="이메일"
                    required
                    disabled
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.userEmail}
                    onChange={(newValue: string) => {
                      this.store.setUserEmail(newValue);
                    }}
                    validateMessage={this.store.userEmailError}
                  />
                </UserEmailInput>
                <UserPhoneInput>
                  <TextInput
                    label="연락처"
                    placeholder="010-0000-0000"
                    required
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.userPhone}
                    onChange={(newValue: string) => {
                      this.store.setUserPhone(newValue);
                    }}
                    validateMessage={this.store.userPhoneError}
                  />
                </UserPhoneInput>
              </UserInfoSection>
            </TabContentLeftContainer>

            <TabContentRightContainer>
              <ToolInfoSection>
                <ToolInfoSectionTitlePC>
                  2. 온라인 협업에 필요한 각 툴의 계정 정보를 입력해 주세요.
                </ToolInfoSectionTitlePC>
                <ToolInfoSectionTitleMobile>
                  2. 온라인 협업툴의 계정 정보를 입력해 주세요.
                </ToolInfoSectionTitleMobile>
                <ToolSlackInput>
                  <TextInput
                    label="Slack 슬랙"
                    placeholder="등록된 계정의 이메일 주소"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.toolSlack}
                    onChange={(newValue: string) => {
                      this.store.setToolSlack(newValue);
                    }}
                  />
                </ToolSlackInput>
                <ToolTrelloInput>
                  <TextInput
                    label="Trello 트렐로"
                    placeholder="등록된 계정의 이메일 주소"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.toolTrello}
                    onChange={(newValue: string) => {
                      this.store.setToolTrello(newValue);
                    }}
                  />
                </ToolTrelloInput>
                <ToolZeplinInput>
                  <TextInput
                    label="Zeplin 제플린"
                    placeholder="등록된 계정의 이메일 주소"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.toolZeplin}
                    onChange={(newValue: string) => {
                      this.store.setToolZeplin(newValue);
                    }}
                  />
                </ToolZeplinInput>
                <ToolGithubInput>
                  <TextInput
                    label="Github 깃헙"
                    placeholder="등록된 계정의 유저 아이디"
                    readonly={this.props.editMode === EditMode.READ}
                    textValue={this.store.toolGithub}
                    onChange={(newValue: string) => {
                      this.store.setToolGithub(newValue);
                    }}
                  />
                </ToolGithubInput>
              </ToolInfoSection>
            </TabContentRightContainer>
          </TabContent>
        </TabContentContainer>
      </Wrapper>
    );
  }
}

export default ClientInfoTabContent;

import { flow, types } from 'mobx-state-tree';

import * as API from '../apis';

export const BulletinPostLinkModel = types
  .model('BulletinPostLink', {
    id: types.number,
    created_at: types.string,
    updated_at: types.string,
    link: types.string,
    task_bulletin_post: types.string,
    writer: types.number,
  })
  .actions(self => ({
    updateByResponses(data: any) {
      self.id = data.id;
      self.link = data.link;
      self.created_at = data.created_at;
      self.updated_at = data.updated_at;
      self.task_bulletin_post = data.task_bulletin_post;
      self.writer = data.writer;
    },
  }))
  .actions(self => {
    const create = flow(function* (link: string) {
      try {
        const data = yield API.postTaskBulletinPostLink(
          self.task_bulletin_post,
          link,
        );
        data && self.updateByResponses(data);
      } catch (e) {
        throw e;
      }
    });
    const update = flow(function* (link: string) {
      try {
        const data = yield API.patchTaskBulletinPostLink(self.id, link);
        data && self.updateByResponses(data);
      } catch (e) {
        throw e;
      }
    });
    const remove = flow(function* () {
      try {
        yield API.deleteTaskBulletinPostLink(self.id);
      } catch (e) {
        throw e;
      }
    });

    return {
      create,
      update,
      remove,
    };
  });
type BulletinPostLinkType = typeof BulletinPostLinkModel.Type;

export interface BulletinPostLink extends BulletinPostLinkType {}

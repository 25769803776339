import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import NotiSVG from '../../../static/icons/icon-noti-6.svg';

export const Wrapper = styled.div<{
  alerted?: boolean;
  active?: boolean;
  highlight?: boolean;
}>`
  width: 290px;
  height: 248px;
  margin-bottom: 100px;
  border-radius: 10px;
  cursor: pointer;
  border: solid 2px
    ${p =>
      p.highlight
        ? PALETTE['purpleish-blue']
        : PALETTE['very-light-pink-three']};
  color: ${p => (p.active ? '#000000' : PALETTE['brownish-grey'])};
  margin-right: 20px;
  position: relative;

  :after {
    ${p =>
      p.highlight &&
      `
        content: '';
        background: url('${NotiSVG}') no-repeat center center;
        width: 12px;
        height: 12px;
        position: absolute;
        top: -20px;
        left: 0px;
        color: ${PALETTE['color-blue']};
      `};
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: 235px;
    border-radius: 10px;
    margin: 0px;

    margin-top: 30px;
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 54px;
  line-height: 54px;
  padding: 0px 15px 0px 20px;
  border-bottom: solid 1px ${PALETTE['very-light-pink-two']};

  .project-status-tag {
    padding-top: 13px;
  }
`;
export const ProjectId = styled.div`
  font-size: 16px;
  font-weight: 500;
`;
export const Body = styled.div`
  padding: 12px 0px 15px 18px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: ${PC}) {
    height: 180px;
  }
`;
export const ProjectName = styled.div`
  line-height: 27px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const OngoingInfoRow = styled.div`
  color: #000000;
  line-height: 20px;
  font-size: 14px;
  & + & {
    margin-top: 10px;
  }
  span + span {
    margin-left: 10px;
  }
`;
export const EndInfoRow = styled(OngoingInfoRow)`
  color: ${PALETTE['brownish-grey']};
`;

export const HoldingRow = styled(OngoingInfoRow)`
  font-weight: 500;

  span:last-child {
    font-weight: bold;
    margin-left: 10px;
  }
`;
export const Button = styled.div`
  position: absolute;
  top: 146px;
  left: 159px;
  width: 111px;
  height: 32px;
  line-height: 32px;
  border-radius: 3px;
  background-color: ${PALETTE['purpleish-blue']};
  font-size: 14px;
  font-weight: bold;
  color: ${PALETTE['button-style']};
  text-align: center;

  @media only screen and (max-width: ${PC}) {
    left: auto;
    right: 15px;
    top: 134px;
  }
`;
export const Info = styled.div`
  height: 150px;
  padding-right: 14px;
`;
export const Footer = styled.div`
  height: 35px;
  @media only screen and (max-width: ${PC}) {
    height: 38px;
  }
`;

import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent, Component } from 'react';

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import { Alert } from '../components/SeesoBoard/Common';
import * as Fields from '../components/SeesoBoard/Common/Fields';

import * as Task from '../components/SeesoBoard/Task';
import { AddChecklist } from '../components/SeesoBoard/Task/AddChecklist';

import { AppStore } from '../store/AppStore';

interface IProps {}
interface InjectedProps extends IProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
export default class TaskDetailContainer extends Component<IProps> {
  state = {
    previousTaskListId: '', // 태스크카드에서 목록 변경 시, 기존 목록도 업데이트하기 위해.
    selectedLabel: '',
    selectedSprint: '',
    selectedChecklist: '',
    selectedSubChecklist: '',
    addChecklist: false,
    updated: false,
  };

  get appStore() {
    return (this.props as InjectedProps).appStore;
  }

  private getTaskListTitle(listId: string) {
    const { taskBoard } = this.appStore.boardStore;

    const taskList = taskBoard?.taskLists
      .slice()
      .find(tasklist => tasklist.task_list_id === listId);

    if (taskList) {
      return taskList.title;
    }
    return '';
  }

  @computed
  get checklists() {
    const { taskForm } = this.appStore.taskStore;

    return taskForm.subTaskListForms.slice();
  }

  @computed
  get task() {
    const { taskForm } = this.appStore.taskStore;
    const { taskBoard } = this.appStore.boardStore;

    if (taskBoard && taskForm) {
      return taskBoard.getTask(taskForm.taskListId, taskForm.taskId);
    }
    return undefined;
  }

  @computed
  get tasklist() {
    const { taskForm } = this.appStore.taskStore;
    const { taskBoard } = this.appStore.boardStore;

    if (taskBoard && taskForm) {
      return taskBoard.getTaskList(taskForm.taskListId);
    }
    return undefined;
  }

  async componentDidMount() {
    const currentTaskId =
      this.appStore.uiStore.selectedIds.length === 1
        ? this.appStore.uiStore.selectedIds[0]
        : undefined;
    const formStore = this.appStore.taskStore;

    if (currentTaskId) {
      await formStore.init();
      await formStore.fetch(Number(currentTaskId));

      this.setState({
        previousTaskListId: this.appStore.taskStore.taskForm.taskListId,
      });
    }
  }

  render() {
    const currentTaskId =
      this.appStore.uiStore.selectedIds.length === 1
        ? this.appStore.uiStore.selectedIds[0]
        : undefined;
    const {
      fetch,
      taskForm,
      archive,
      updateForm,
      updatePersonInCharge,
      updateComment,
      updateLabel,
      updateSprint,
      updateLink,
      updateFile,

      updateSubTask,
      updateSubTaskList,
    } = this.appStore.taskStore;
    const { taskBoard, deleteTask } = this.appStore.boardStore;
    const { userId } = this.appStore.userStore;

    return (
      <section className="dimd-layer">
        <div className="inner">
          <div className="task-detail">
            <Task.Header
              isArchived={taskForm.isArchived}
              title={taskForm.title}
              listTitle={this.getTaskListTitle(taskForm.taskListId)}
              listTitleOptions={taskForm.taskListSelections}
              onChangeListTitle={(titleId: string) => {
                this.setState({ updated: true });
                taskForm.setTaskListId(titleId);
                // if(titleId !== this.appStore.taskStore.taskForm.taskListId) return;
                // this.appStore.taskStore.updateTaskList(titleId);
              }}
              onChangeTitle={(title: string) => {
                // this.setState({updated: true});
                // taskForm.setTitle(title);
                if (title === this.appStore.taskStore.taskForm.title) return;
                this.appStore.taskStore.updateTitle(title);
              }}
              onActive={async () => {
                this.appStore.uiStore.showToast(
                  true,
                  true,
                  '선택한 태스크 카드 복구중입니다. ( 서버 응답속도 문제 임시알림 )',
                );
                await archive(false);
                await taskBoard?.fetchTaskList(taskForm.taskListId);
                this.appStore.uiStore.clearSelected();
                this.appStore.uiStore.closeToase();
                this.appStore.uiStore.showToast(
                  false,
                  false,
                  '태스크가 복구처리 되었습니다.',
                );
              }}
              onShare={() => {
                let url = window.location.href.replace('#', '');
                url =
                  url.indexOf('?') > 0
                    ? url.substring(0, url.indexOf('?'))
                    : url;
                const link = `${url}?tk=${taskForm.id}`;
                let tempElem = document.createElement('textarea');
                tempElem.value = link;
                document.body.appendChild(tempElem);

                tempElem.select();
                document.execCommand('copy');
                document.body.removeChild(tempElem);

                this.appStore.uiStore.showToast(
                  false,
                  false,
                  '태스크 공유 링크가 복사되었습니다.',
                );
              }}
              onDeactive={async () => {
                this.appStore.uiStore.showToast(
                  true,
                  true,
                  '선택한 태스크 카드 보관처리중입니다. ( 서버 응답속도 문제 임시알림 )',
                );
                await archive(true);
                this.appStore.uiStore.clearSelected();
                await taskBoard?.fetchTaskList(taskForm.taskListId);
                this.appStore.uiStore.closeToase();
                this.appStore.uiStore.showToast(
                  false,
                  false,
                  '태스크가 보관처리 되었습니다.',
                );
              }}
              onDelete={async () => {
                this.appStore.uiStore.showToast(
                  true,
                  true,
                  '선택한 태스크 카드 완전 삭제중입니다. ( 서버 응답속도 문제 임시알림 )',
                );
                await deleteTask(taskForm.id);
                this.appStore.uiStore.clearSelected();
                await taskBoard?.fetchTaskList(taskForm.taskListId);
                this.appStore.uiStore.closeToase();
                this.appStore.uiStore.showToast(
                  false,
                  false,
                  '태스크가 완전 삭제 되었습니다.',
                );
              }}
            />

            <div className="task-input">
              <div className="cnts-w">
                {/* 설명 */}
                <Fields.Description
                  isEditable={!taskForm.isArchived}
                  description={taskForm.description}
                  onChange={(description: string) => {
                    this.setState({ updated: true });
                    taskForm.setDescription(description);
                  }}
                />

                {/* 체크리스트 */}
                <div className="in-cnts check">
                  <h2>체크리스트</h2>
                  {!taskForm.isArchived && (
                    <div className="btn">
                      <button
                        type="button"
                        onClick={() => {
                          this.setState({ addChecklist: true });
                        }}
                      >
                        추가
                      </button>
                    </div>
                  )}

                  <Droppable
                    droppableId="task-checklist"
                    type="CHECKLIST"
                    isCombineEnabled={true}
                  >
                    {(provided: DroppableProvided) => (
                      <div
                        className="d-box"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {this.checklists.map(checklist => (
                          <Draggable
                            isDragDisabled={taskForm.isArchived}
                            draggableId={`ch-${checklist.id}`}
                            key={`ch-${checklist.id}`}
                            index={checklist.order}
                          >
                            {(
                              provided: DraggableProvided,
                              snapshot: DraggableStateSnapshot,
                            ) => (
                              <Task.Checklist
                                key={checklist.id}
                                id={checklist.id}
                                isEditable={!taskForm.isArchived}
                                title={checklist.title}
                                onAddSub={async (title: string) => {
                                  await updateSubTask(
                                    {
                                      title: title,
                                      subTaskListId: checklist.subTaskListId,
                                    },
                                    'post',
                                  );
                                  await fetch(Number(currentTaskId));
                                }}
                                onChangeTitle={async (title: string) => {
                                  await updateSubTaskList(
                                    {
                                      id: checklist.id,
                                      title: title,
                                    },
                                    'patch',
                                  );
                                  await fetch(Number(currentTaskId));
                                }}
                                onDelete={async () => {
                                  this.setState({
                                    selectedChecklist: checklist.id,
                                  });
                                }}
                                dndProvided={provided}
                              >
                                <Droppable
                                  key={`sub-droppable${checklist.id}`}
                                  droppableId={`ch-${checklist.id}`}
                                  type="SUBCHECKLIST"
                                >
                                  {(
                                    providedSubChecklist: DroppableProvided,
                                    snapshotTask: DroppableStateSnapshot,
                                  ) => (
                                    <ul
                                      ref={providedSubChecklist.innerRef}
                                      {...providedSubChecklist.droppableProps}
                                    >
                                      {checklist.subTasks.map((subTask, j) => (
                                        <Draggable
                                          isDragDisabled={taskForm.isArchived}
                                          key={subTask.sub_task_id}
                                          draggableId={`sch-${subTask.id}`}
                                          index={j}
                                        >
                                          {(providedSubChecklist, snapshot) => (
                                            <Task.SubChecklist
                                              key={subTask.id}
                                              id={subTask.id}
                                              isEditable={!taskForm.isArchived}
                                              title={subTask.title}
                                              isDone={subTask.checked}
                                              dndProvided={providedSubChecklist}
                                              onChangeTitle={async (
                                                title: string,
                                              ) => {
                                                await updateSubTask(
                                                  {
                                                    id: subTask.id,
                                                    title: title,
                                                    checked: subTask.checked,
                                                  },
                                                  'patch',
                                                );
                                                await fetch(
                                                  Number(currentTaskId),
                                                );
                                              }}
                                              onToggleDone={async () => {
                                                await updateSubTask(
                                                  {
                                                    id: subTask.id,
                                                    title: subTask.title,
                                                    checked: !subTask.checked,
                                                  },
                                                  'patch',
                                                );
                                                await fetch(
                                                  Number(currentTaskId),
                                                );
                                              }}
                                              onDelete={() => {
                                                this.setState({
                                                  selectedSubChecklist:
                                                    subTask.id,
                                                });
                                              }}
                                            />
                                          )}
                                        </Draggable>
                                      ))}
                                      {providedSubChecklist.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </Task.Checklist>
                            )}
                          </Draggable>
                        ))}

                        {this.state.addChecklist && (
                          <AddChecklist
                            handleAdd={async title => {
                              await updateSubTaskList(
                                {
                                  title: title,
                                },
                                'post',
                              );
                              await fetch(Number(currentTaskId));
                            }}
                            handleCancel={() => {
                              this.setState({ addChecklist: false });
                            }}
                          />
                        )}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>

                {/* 첨부자료 */}
                <div className="in-cnts file">
                  <h2>첨부 자료</h2>
                  {!taskForm.isArchived && (
                    <div className="btn">
                      <button
                        type="button"
                        onClick={(e: any) => {
                          taskForm.addEmptyLink();
                        }}
                      >
                        링크
                      </button>
                      <button
                        type="button"
                        onClick={(e: any) => {
                          e.preventDefault();
                          document.getElementById('upload-post-file')?.click();
                        }}
                      >
                        파일
                      </button>
                      <input
                        type="file"
                        id="upload-post-file"
                        hidden={true}
                        onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                          const fileList = e.currentTarget.files;
                          if (fileList) {
                            this.appStore.uiStore.showToast(
                              false,
                              true,
                              '파일 업로드 중입니다.',
                            );
                            let i = 0;
                            while (i < fileList.length) {
                              var file = fileList[i];
                              await updateFile(
                                {
                                  taskId: taskForm.taskId,
                                  file: file,
                                },
                                'post',
                              );
                              i++;
                            }

                            this.appStore.uiStore.closeToase();
                          }
                        }}
                      />
                    </div>
                  )}

                  <div className="d-box">
                    <div
                      className={`file-list ${
                        taskForm.isArchived ? 'disb' : ''
                      }`}
                    >
                      <ul>
                        {taskForm.taskFiles.map(file => (
                          <Fields.File
                            key={file.id}
                            id={file.id}
                            filename={file.filename}
                            file={file.file}
                            date={file.updated_at}
                            onChangeFilename={async (filename: string) => {
                              await this.appStore.taskStore.changeFilename(
                                file.id,
                                filename,
                              );
                            }}
                            onDelete={() => {
                              updateFile(
                                {
                                  id: file.id,
                                },
                                'delete',
                              );
                            }}
                          />
                        ))}
                        {taskForm.taskLinks.map(link => (
                          <Fields.Link
                            id={link.id}
                            key={link.id}
                            link={link.link}
                            date={link.updated_at}
                            handleUpdate={async (value: string) => {
                              if (!value) {
                                taskForm.removeEmptyLink();
                              } else {
                                const newLink = value.startsWith('http')
                                  ? value
                                  : 'http://' + value;
                                if (newLink !== link.link) {
                                  if (link.id > 0) {
                                    // 수정
                                    try {
                                      await updateLink(
                                        {
                                          id: link.id,
                                          link: newLink,
                                        },
                                        'patch',
                                      );
                                    } catch (e) {
                                      this.appStore.uiStore.showToast(
                                        true,
                                        false,
                                        e.response.statusText,
                                      );
                                      taskForm.removeEmptyLink();
                                    }
                                  } else {
                                    try {
                                      await updateLink(
                                        {
                                          taskId: taskForm.taskId,
                                          link: newLink,
                                        },
                                        'post',
                                      );
                                    } catch (e) {
                                      const errMsg =
                                        e.response.data.hasOwnProperty('link')
                                          ? e.response.data.link[0]
                                          : e.response.statusText;
                                      this.appStore.uiStore.showToast(
                                        true,
                                        false,
                                        errMsg,
                                      );
                                      taskForm.removeEmptyLink();
                                    }
                                  }
                                }
                              }
                            }}
                            handleDelete={async () => {
                              try {
                                await updateLink(
                                  {
                                    id: link.id,
                                  },
                                  'delete',
                                );
                              } catch (e) {
                                this.appStore.uiStore.showToast(
                                  true,
                                  false,
                                  e.response.statusText,
                                );
                                taskForm.removeEmptyLink();
                              }
                            }}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>

                {/* 댓글 */}
                <div className="in-cnts comt">
                  <h2>
                    <label htmlFor="cmt">댓글</label>
                  </h2>
                  <div className="d-box">
                    {!taskForm.isArchived && (
                      <Task.AddComment
                        members={
                          taskBoard
                            ? taskBoard.members.map(member => member.name)
                            : []
                        }
                        handleCreate={async (comment: string) => {
                          this.setState({ updated: true });
                          this.appStore.uiStore.showToast(
                            true,
                            true,
                            '댓글 저장중입니다 ( 서버 응답속도 문제 임시알림 )',
                          );
                          updateComment({ comment: comment }, 'post');
                          this.appStore.uiStore.closeToase();
                        }}
                      />
                    )}
                    <div
                      className={`cmt-list ${
                        taskForm.isArchived ? 'disb' : ''
                      }`}
                    >
                      <ul>
                        {taskForm.comments.map(comment => (
                          <Task.Comment
                            showAction={
                              !taskForm.isArchived && comment.writer === userId
                            }
                            key={comment.id}
                            username={comment.writerName}
                            comment={comment.comment}
                            date={comment.updatedAt}
                            members={
                              taskBoard
                                ? taskBoard.members.map(member => member.name)
                                : []
                            }
                            handleChange={async (value: string) => {
                              this.setState({ updated: true });
                              this.appStore.uiStore.showToast(
                                false,
                                true,
                                '댓글 저장중입니다.',
                              );
                              updateComment(
                                { id: comment.id, comment: value },
                                'patch',
                              );
                              this.appStore.uiStore.closeToase();
                            }}
                            handleDelete={async () => {
                              this.setState({ updated: true });
                              this.appStore.uiStore.showToast(
                                false,
                                true,
                                '댓글 삭제중입니다.',
                              );
                              updateComment({ id: comment.id }, 'delete');
                              this.appStore.uiStore.closeToase();
                            }}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <aside>
                <ul className={taskForm.isArchived ? 'disb' : ''}>
                  <Task.Assignee
                    isArchived={taskForm.isArchived}
                    assignees={taskBoard?.members.map(member => {
                      return { id: member.id, title: member.name };
                    })}
                    values={taskForm.personInChargeList.map(person => {
                      const finded = taskForm.workerSelections.find(
                        worker => worker.id === person,
                      );
                      if (finded) return finded.name;
                      else return '';
                    })}
                    handleAdd={userid => {
                      // this.setState({updated: true});
                      taskForm.addPersonInCharge(userid);
                      updatePersonInCharge();
                    }}
                    handleRemove={userid => {
                      // this.setState({updated: true});
                      taskForm.deletePersonInCharge(userid);
                      updatePersonInCharge();
                    }}
                  />

                  <Task.Label
                    isArchived={taskForm.isArchived}
                    labels={taskForm.taskLabelSelections.map(
                      label => label.title,
                    )}
                    values={taskForm.labelList.map(label => label.title)}
                    handleAdd={(value: string) => {
                      this.setState({ updated: true });
                      const labelItem = taskForm.taskLabelSelections.find(
                        label => label.title === value,
                      );
                      labelItem && updateLabel(labelItem, 'put');
                    }}
                    handleRemove={(value: string) => {
                      this.setState({ updated: true });
                      const labelItem = taskForm.taskLabelSelections.find(
                        label => label.title === value,
                      );
                      labelItem && updateLabel([labelItem], 'delete');
                    }}
                    handleCreate={(value: string) => {
                      this.setState({ updated: true });
                      const labelItem = {
                        taskLabelId: '',
                        title: value,
                      };
                      labelItem && updateLabel(labelItem, 'post');
                    }}
                    handleDelete={(value: string) => {
                      this.setState({ updated: true });
                      this.setState({ selectedLabel: value });
                    }}
                  />

                  <Task.Sprint
                    isArchived={taskForm.isArchived}
                    sprints={taskForm.taskSprintSelections.map(sprint => ({
                      id: sprint.taskSprintId,
                      title: sprint.title,
                    }))}
                    values={taskForm.sprintList.map(sprint => sprint.title)}
                    handleAdd={(sprintId: string) => {
                      // this.setState({updated: true});
                      const item = taskForm.taskSprintSelections.find(
                        sprint => sprint.taskSprintId === sprintId,
                      );
                      item && updateSprint(item, 'put');
                    }}
                    handleRemove={(sprintId: string) => {
                      // this.setState({updated: true});
                      const item = taskForm.taskSprintSelections.find(
                        sprint => sprint.taskSprintId === sprintId,
                      );
                      item && updateSprint([item], 'delete');
                    }}
                    handleCreate={(value: string) => {
                      // this.setState({updated: true});
                      const sprintItem = {
                        taskSprintId: '',
                        title: value,
                      };
                      sprintItem && updateSprint(sprintItem, 'post');
                    }}
                    handleDelete={(value: string) => {
                      this.setState({ selectedSprint: value });
                    }}
                  />

                  <Task.DueDate
                    isArchived={taskForm.isArchived}
                    dueDate={taskForm.dueDate || undefined}
                    onChange={(date: string | null) => {
                      this.setState({ updated: true });
                      taskForm.setDueDate(date);
                    }}
                  />
                </ul>
              </aside>
            </div>

            <Task.CloseButton
              handleClick={async () => {
                if (!taskBoard) return;

                this.appStore.uiStore.clearSelected();
                if (taskForm.isArchived) return;

                // this.appStore.uiStore.showToast(true, true, '태스크 업데이트중입니다. ( 서버 응답속도 문제 임시알림 )');

                // 태스크폼 업데이트
                this.state.updated && (await updateForm());

                // await taskBoard.fetchTaskList(taskForm.taskListId);
                if (this.state.previousTaskListId !== taskForm.taskListId) {
                  await Promise.all([
                    await taskBoard.fetchTaskList(taskForm.taskListId),
                    await taskBoard.fetchTaskList(
                      this.state.previousTaskListId,
                    ),
                  ]);
                  //   await taskBoard.fetchTaskList(this.state.previousTaskListId);
                } else {
                  await taskBoard.fetchTask(
                    taskForm.taskListId,
                    taskForm.taskId,
                  );
                }

                this.appStore.uiStore.clearSelected();
                // this.appStore.uiStore.closeToase();

                this.setState({ update: false });
              }}
            />
          </div>
        </div>

        <Alert
          isOpen={!!this.state.selectedLabel}
          onConfirm={async () => {
            const labelItem = taskForm.taskLabelSelections.find(
              label => label.title === this.state.selectedLabel,
            );
            labelItem && (await updateLabel([labelItem], 'delete'));

            await taskBoard?.deleteLabel(this.state.selectedLabel);
            this.setState({ selectedLabel: '' });
            await this.appStore.boardStore.fetchTaskBoard();
          }}
          onCancel={() => {
            this.setState({ selectedLabel: '' });
          }}
        >
          <p>
            선택한 라벨을 삭제하시겠습니까?
            <br />
            모든 태스크에서도 선택한 라벨이 삭제됩니다.
          </p>
        </Alert>
        <Alert
          isOpen={!!this.state.selectedSprint}
          onConfirm={async () => {
            const item = taskForm.taskSprintSelections.find(
              sprint => sprint.title === this.state.selectedSprint,
            );
            item && (await updateSprint([item], 'delete'));

            await taskBoard?.deleteSprint(this.state.selectedSprint);
            this.setState({ selectedSprint: '' });
            await this.appStore.boardStore.fetchTaskBoard();
          }}
          onCancel={() => {
            this.setState({ selectedSprint: '' });
          }}
        >
          <p>
            선택한 스프린트를 삭제하시겠습니까?
            <br />
            <br />
            모든 태스크에서도 선택한 스프린트가 삭제됩니다.
          </p>
        </Alert>
        <Alert
          isOpen={!!this.state.selectedChecklist}
          onConfirm={async () => {
            await updateSubTaskList(
              {
                id: this.state.selectedChecklist,
              },
              'delete',
            );

            this.setState({ selectedChecklist: '' });
            await fetch(Number(currentTaskId));
          }}
          onCancel={() => {
            this.setState({ selectedChecklist: '' });
          }}
        >
          <p>
            선택한 체크리스트 목록을
            <br /> 삭제하시겠습니까?
            <br />
          </p>
        </Alert>
        <Alert
          isOpen={!!this.state.selectedSubChecklist}
          onConfirm={async () => {
            await updateSubTask(
              {
                id: this.state.selectedSubChecklist,
              },
              'delete',
            );
            this.setState({ selectedSubChecklist: '' });
            await fetch(Number(currentTaskId));
          }}
          onCancel={() => {
            this.setState({ selectedSubChecklist: '' });
          }}
        >
          <p>
            선택한 체크리스트를
            <br /> 삭제하시겠습니까?
            <br />
          </p>
        </Alert>
      </section>
    );
  }
}

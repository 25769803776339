import styled from 'styled-components/macro';

export const SignUpPageWrapper = styled.section`
  position: relative;
  padding-top: 4%;
  button {
    cursor: pointer;
  }
  p.errorMsg {
    margin-top: 10px;
    font-size: 12px;
    color: #f33;
  }
`;

export const SignupPageWrap = styled.div`
  margin-top: 3%;
  margin-bottom: 124px;
  position: relative;
  display: block;

  > div {
    margin: 0 auto;
    position: relative;
    width: 570px;
    padding: 60px 30px 30px;
    background: #fff;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 450px) {
      box-shadow: none;
    }
  }
  ul {
    padding: 0 0 30px 0;
    border-bottom: 1px solid #e1e1e1;
    list-style: none;
  }
  .checkFormItem1.active {
    .agreeBox {
      display: block;
      overflow: auto;
      height: 200px;
      margin-top: 15px;
      padding: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: #dce0e0;
      border-radius: 10px;
      box-sizing: border-box;
      color: #6f6f6f;
      font-size: 13px;
      line-height: 1.85;
      margin-bottom: 16px;
    }
    button:after {
      position: absolute;
      top: -5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .checkFormItem2.active {
    .agreeBox {
      display: block;
      overflow: auto;
      height: 200px;
      margin-top: 15px;
      padding: 20px;
      border-width: 1px;
      border-style: solid;
      border-color: #dce0e0;
      border-radius: 10px;
      box-sizing: border-box;
      color: #6f6f6f;
      font-size: 13px;
      line-height: 1.85;
      margin-bottom: 16px;
    }
    button:after {
      position: absolute;
      top: -5px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  @media only screen and (max-width: 450px) {
    width: 100%;
    padding: 0 10px;
    margin: 8% 0;
    box-sizing: border-box;
    > div {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;

export const ListFormItem = styled.li`
  margin-top: 10px;
  > div {
    margin-bottom: 0px;
  }
  input {
    width: 100%;
    height: 52px;
    background: #f2f2f2;
    border: none;
    font-size: 18px;
    box-shadow: none;
    -webkit-box-shadow: none;
    &:focus {
      border-color: #f2f2f2;
      box-shadow: none;
    }
  }
  .has-error .ant-form-item {
    input {
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
`;

export const RegisterDesc = styled.div`
  margin-top: 24px;
  margin-bottom: 30px;
  padding: 0 20px;
  color: #6f6f6f;
  font-size: 14px;
  text-align: center;
  a {
    margin-left: 10px;
  }
`;

export const AgreeBox = styled.div`
  display: none;
  overflow: auto;
  height: 200px;
  margin-top: 15px;
  padding: 20px;
  border-width: 1px;
  border-style: solid;
  border-color: #dce0e0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #6f6f6f;
  font-size: 13px;
  line-height: 1.85;
  margin-bottom: 16px;
  main {
    // 컴포넌트 공통으로 사용하는 것 때문에 어쩔수 없음
    width: 100% !important;
    padding: 0;
  }
`;

export const CheckFormItems = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  p {
    float: none;
    font-size: 14px;
    display: block;
    margin-bottom: 16px;
    > button {
      border: none;
      margin-left: 0px;
      width: auto;
      height: auto;
      margin-top: 0px;
      background: none;
      color: #fff;
      text-align: center;
      line-height: 15px;
      display: inline;
      position: relative;
    }
    > button:after {
      content: '';
      display: inline-block;
      width: 9px;
      height: 9px;
      margin-left: 5px;
      border-width: 1px 0 0 1px;
      border-style: solid;
      border-color: #979797;
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
      vertical-align: 3px;
    }
  }
  label {
    span {
      font-size: 14px;
      color: #6f6f6f;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
    position: absolute;
  }
  input[type='checkbox'] + label {
    display: inline-block;
    position: relative;
    padding: 3px 0 0 40px;
    cursor: pointer;
    margin-top: 5px;
    color: #000;
    font-size: 15px;
  }
  input[type='checkbox']:checked + label:before {
    background: #23edb9;
  }
  label {
    &:before {
      display: block;
      position: absolute;
      content: '';
      width: 30px;
      height: 30px;
      background: #dce0e0;
      border: none;
      border-radius: 16px;
      left: 0;
      top: 0;
      font-size: 12px;
      top: 1px;
      width: 30px;
      height: 30px;
    }
    &:after {
      display: block;
      content: '';
      width: 14px;
      height: 8px;
      position: absolute;
      top: 9px;
      left: 8px;
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      background: transparent;
      transform: rotate(-45deg);
    }
  }
`;

export const CheckFormBtn = styled.button`
  display: block;
  width: 100%;
  height: 52px;
  margin-top: 20px;
  background: #e5ff1e;
  color: #2b2b2b;
  font-size: 15px;
  text-align: center;
  line-height: 52px;
  border: none;
`;

export const SignUpLogo = styled.h1`
  width: 120px;
  position: relative;
  margin: 0 auto;
  z-index: 20;
  text-align: center;
  @media only screen and (max-width: 650px) {
    margin: 0 auto;
    position: relative;
    top: 40px;
    left: 0%;
    overflow: visible;
    min-height: 35px;
  }
  img {
    width: 100%;
  }
`;

import React, { useEffect, useState } from 'react';

import { Alert } from '../Common';
import { Assignee, IAssigneeProps } from '../Common/Fields';

export interface ITaskListMenuProps {
  id: string;
  assignees: IAssigneeProps[];
  selectedAssigneeIds: number[];
  title: string;
  countTask: number;

  dndDragHandleProps?: any; //for react-beautiful-dnd

  onChangeTitle?: (title: string) => void;
  onDelete?: () => void;
  onSetAssignee?: (ids: number[]) => void;
}

export const TaskListMenu: React.SFC<ITaskListMenuProps> = props => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [showAssignee, setShowAssignee] = useState(false);
  const [assigneeIds, setAssigneeIds] = useState<number[]>(
    props.selectedAssigneeIds,
  );
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(props.title);

  const menuClass = showMenu ? 'edit-wrap selected' : 'edit-wrap';

  const onBlurTitle = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onChangeTitle &&
      props.onChangeTitle((e.target as HTMLInputElement).value);
    setTitle(props.title);
    setIsEditingTitle(false);
  };
  const onChangeTitle = (e: React.FocusEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPressTitle = (e: any) => {
    if (e.key === 'Enter') {
      props.onChangeTitle &&
        props.onChangeTitle((e.target as HTMLInputElement).value);
      setTitle(props.title);
      setIsEditingTitle(false);
    }
  };

  const handleDocumentClick = (e: MouseEvent) => {
    const clickedSection = e
      .composedPath()
      .find(path => (path as HTMLElement).dataset?.listid?.startsWith('TL'));
    if (
      clickedSection &&
      (clickedSection as HTMLElement).dataset.listid !== props.id
    ) {
      setShowAssignee(false);
      setShowMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleDocumentClick);
  }, []);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setAssigneeIds(props.selectedAssigneeIds);
  }, [props.selectedAssigneeIds]);

  return (
    <>
      {isEditingTitle ? (
        <>
          <h1>
            {props.title}
            <span>{props.countTask}</span>
          </h1>
          <div className="tit-modify">
            <input
              type="text"
              autoFocus={true}
              value={title}
              onBlur={onBlurTitle}
              onChange={onChangeTitle}
              onKeyPress={onKeyPressTitle}
            />
          </div>
        </>
      ) : (
        <h1 {...props.dndDragHandleProps}>
          {props.title}
          <span>{props.countTask}</span>
        </h1>
      )}

      <div className={menuClass}>
        <button type="button" onClick={() => setShowMenu(!showMenu)}>
          <span>카드 수정</span>
        </button>

        <div id={`listmenu-${props.id}`} className="edit-in">
          <h2>Menu</h2>
          <ul>
            <li>
              <div className={`select-w ${showAssignee ? 'selected' : ''}`}>
                <button
                  type="button"
                  onClick={() => setShowAssignee(!showAssignee)}
                >
                  담당자 지정
                </button>
                <div className="option-box">
                  <ul>
                    {props.assignees.map(assignee => (
                      <Assignee
                        prefix={`tl-assignee-${props.id}`}
                        key={`${props.id}-${assignee.value}`}
                        {...assignee}
                        onChange={checked => {
                          if (checked) {
                            if (!assigneeIds.includes(assignee.id)) {
                              const ids = assigneeIds.concat(assignee.id);

                              setAssigneeIds(ids);
                              props.onSetAssignee && props.onSetAssignee(ids);
                            }
                          } else {
                            if (assigneeIds.includes(assignee.id)) {
                              const ids = assigneeIds.filter(
                                id => id !== assignee.id,
                              );

                              setAssigneeIds(ids);
                              props.onSetAssignee && props.onSetAssignee(ids);
                            }
                          }
                        }}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </li>
            {props.onChangeTitle && (
              <li>
                <button type="button" onClick={() => setIsEditingTitle(true)}>
                  이름변경
                </button>
              </li>
            )}

            {props.onDelete && (
              <li>
                <button type="button" onClick={() => setShowDeleteAlert(true)}>
                  삭제
                </button>
              </li>
            )}
          </ul>
          <button className="btn-close" onClick={() => setShowMenu(false)}>
            <span>메뉴 닫기</span>
          </button>
        </div>
      </div>

      <Alert
        isOpen={showDeleteAlert}
        onConfirm={() => props.onDelete && props.onDelete()}
        onCancel={() => setShowDeleteAlert(false)}
      >
        <p>
          선택한 {props.title}을<br /> 삭제하시겠습니까?
        </p>
      </Alert>
    </>
  );
};

/*
if (this.props.canOutsideClickClose && !this.props.hasBackdrop) {
    document.addEventListener("mousedown", this.handleDocumentClick);
}

private handleDocumentClick = (e: MouseEvent) => {
    const { canOutsideClickClose, isOpen, onClose } = this.props;
    const eventTarget = (e.composed ? e.composedPath()[0] : e.target) as HTMLElement;

    const stackIndex = Overlay.openStack.indexOf(this);
    const isClickInThisOverlayOrDescendant = Overlay.openStack
        .slice(stackIndex)
        .some(({ containerElement: elem }) => {
            return elem && elem.contains(eventTarget) && !elem.isSameNode(eventTarget);
        });

    if (isOpen && canOutsideClickClose && !isClickInThisOverlayOrDescendant) {
        onClose?.(e as any);
    }
};



    https://github.com/palantir/blueprint/blob/15057034fcbddd5241b1078e698e19d4489d9901/packages/core/src/components/overlay/overlay.tsx#L188
*/

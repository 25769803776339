import React, { useEffect, useRef, useState } from 'react';

import { IMessage, Message } from './Message';

/*
 * InfiniteScroll 코드 참조: https://github.com/danbovey/react-infinite-scroller/blob/master/src/InfiniteScroll.js
 * TODO 다른 구현 방법: IntersectionObserver
 */

export interface IActivity {
  activities: IMessage[];
  onClose: () => void;
  onScroll?: (page: number) => void;
}
export const Activity: React.SFC<IActivity> = ({
  activities,
  onClose,
  onScroll,
}) => {
  const viewport = useRef<HTMLDivElement>(null);
  const target = useRef<HTMLLIElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  // const [ beforeScrollHeight, setBeforeScrollHeight] = useState(0);
  // const [ beforeScrollTop, setBeforeScrollTop ] = useState(0);

  useEffect(() => {
    activities.length === 0 && fetching();
  }, [activities]);

  useEffect(() => {
    if (!isFetching && currentPage > 0 && target.current) {
      target.current &&
        target.current.scrollIntoView({
          block: 'center',
        });
      // viewport.current.scrollTop = beforeScrollHeight
      // viewport.current.scrollHeight -
      //   beforeScrollHeight +
      //   beforeScrollTop;
    }

    // scroll event listener 등록
    attachScrollListener();
    return () => {
      // scroll event listener 해제
      detachScrollListener();
    };
  });

  const fetching = async () => {
    setIsFetching(true);
    try {
      onScroll && (await onScroll(currentPage + 1));
      setCurrentPage(currentPage + 1);
    } catch (e) {
      setIsFetching(false);
    } finally {
      setIsFetching(false);
    }
  };

  const handleScroll = async () => {
    const parentNode = viewport.current;
    const threshold = 150;

    if (!parentNode) return;

    let offset;
    offset =
      parentNode.scrollHeight - parentNode.scrollTop - parentNode.clientHeight;

    if (offset < threshold && parentNode && parentNode.offsetParent !== null) {
      detachScrollListener();
      // setBeforeScrollHeight(parentNode.clientHeight);
      // setBeforeScrollTop(parentNode.scrollTop);

      !isFetching && fetching();
    }
  };

  const attachScrollListener = () => {
    viewport.current &&
      viewport.current.addEventListener('scroll', handleScroll);
  };

  const detachScrollListener = () => {
    viewport.current &&
      viewport.current.removeEventListener('scroll', handleScroll);
  };

  return (
    <div className="noti-box" ref={viewport}>
      <h4>활동내역</h4>
      <ul>
        {activities.map((notification, i) => (
          <Message
            key={notification.id}
            innerRef={i === activities.length - 51 ? target : null}
            {...notification}
          />
        ))}
      </ul>

      <button type="button" className="btn-close" onClick={onClose}>
        <span>알림 창 닫기</span>
      </button>
    </div>
  );
};

import React, { Component } from 'react';
import { Route, Switch } from 'react-router';

import * as Pages from './components/pages';
import MainTemplate from './components/templates/MainTemplate';
import SeesoBoard from './containers/SeesoBoard';

class Routes extends Component {
  render() {
    return (
      <Switch>
        {/* 프로젝트보드  */}
        <Route
          path="/board/:groupid"
          render={({ match }) => <SeesoBoard groupId={match.params.groupid} />}
        />

        <Route path="/">
          <MainTemplate>
            <Switch>
              <Route path="/" exact={true} component={Pages.HomePage} />
              <Route exact path="/receptions/start" component={Pages.ReceptionPage.ReceptionStartPage} />
              <Route
                exact
                path="/receptions"
                component={Pages.ReceptionPage.ReceptionListPage}
              />
              <Route
                exact
                path="/receptions/:receptionId"
                component={Pages.ReceptionPage.ReceptionViewPage}
              />

              <Route path="/inspection" component={Pages.InspectionPage} />
              <Route
                exact
                path="/dashboard"
                component={Pages.DashboardPage.Dashboard}
              />
              <Route
                exact
                path="/dashboard/:id"
                component={Pages.DashboardPage.Project}
              />
              <Route
                path="/calculate"
                component={Pages.CalculatePage.Calculate}
              />
              <Route path="/mypage" component={Pages.MyPage} />

              <Route path="/norequest" component={Pages.NoRequestPage} />

              {/* 기존 seeso.kr에서 가지고 온 것  */}
              <Route path="/signup" component={Pages.EtcPage.SignupPage} />
              <Route path="/partners" component={Pages.EtcPage.PartnersPage} />
              {/*<Route path="/pricing" component={Pages.EtcPage.PricingPage} />*/}
              <Route
                path="/terms/agreement"
                component={Pages.EtcPage.AgreementPage}
              />
              <Route
                path="/terms/privatePolicy"
                component={Pages.EtcPage.PrivateTermPage}
              />

              {/* 500페이지 */}
              <Route path="/errorpage" component={Pages.ServerErrorPage} />

              {/* 404페이지 */}
              <Route component={Pages.NotFoundPage} />
            </Switch>
          </MainTemplate>
        </Route>
      </Switch>
    );
  }
}

export default Routes;

import moment from 'moment';
import React, { FC, useState } from 'react';
import { ISprintReport } from '../../../store/Dashboard/models/SprintReport';

import { Sprint as Comps, SprintReport } from '../../molecules/Dashboard';

const SprintReportOnly: FC<{
  dateEnd: Date | null;
  isOpen: boolean;
  reports?: ISprintReport;
  title: string;
}> = ({ isOpen, reports, title, dateEnd }) => {
  const [isShow, setIsShow] = useState(isOpen);

  return (
    <Comps.Box>
      <Comps.BoxHeader onClick={() => setIsShow(!isShow)}>
        <Comps.BoxHeaderLeft>
          <div>{title}</div>
          <div>{dateEnd && `${moment(dateEnd).format('MM.DD')} 종료`}</div>
        </Comps.BoxHeaderLeft>

        <Comps.BoxHeaderRight>
          <Comps.CalculateColumnReportView
            onClick={() => window.open(`/calculate`, '_blank')}
          >
            정산상세
          </Comps.CalculateColumnReportView>
        </Comps.BoxHeaderRight>
      </Comps.BoxHeader>

      <Comps.BoxBody isShow={true}>
        {reports && (
          <SprintReport hideTitle={true} reports={reports?.reports} />
        )}
      </Comps.BoxBody>
    </Comps.Box>
  );
};

export default SprintReportOnly;

import { Intent } from '@blueprintjs/core';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import React, { ChangeEvent, Component } from 'react';
import { PCMenu } from '.';

import { PC } from '../../../constants/media';
import { AppStore } from '../../../store/AppStore';
import { getChannelListener } from '../../../utils/communication/ChannelLoader';
import { EVENT } from '../../../utils/firebase/push-notification';
import { LoginButton, UserDropdown } from '../../molecules/MainTemplate';
import { Header } from '../../molecules/MainTemplate/Header';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import { HomePageProps } from '../../pages/HomePage';

interface Props extends HomePageProps {
  current: string;
  history: any;
  location: any;
}

interface InjectedProps extends Props {
  appStore: AppStore;
}

interface State {
  isOpen: boolean;
  isMobileMode: boolean;
}

@inject('appStore')
@observer
class MenuBar extends Component<Props> {
  @observable email = '';
  @observable password = '';
  state: State = {
    isOpen: false,
    isMobileMode: false,
  };

  get injected() {
    return this.props as InjectedProps;
  }

  get calculateStore() {
    return this.injected.appStore.calculateStore;
  }

  get dashboardStore() {
    return this.injected.appStore.dashboardStore;
  }

  get notificationStore() {
    return this.injected.appStore.notificationStore;
  }

  get userStore() {
    return this.injected.appStore.userStore;
  }

  async componentDidMount() {
    const { isLoggedIn } = this.userStore;

    // 윈도우 크기 변경 시
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    if (isLoggedIn) {
      await this.fetchNotification();
    } else {
      const needLoginPage = [
        'receptions',
        'mypage',
        'inspection',
        'dashboard',
        'calculate',
        'norequest',
      ];
      if (needLoginPage.includes(this.props.location.pathname.split('/')[1])) {
        this.setShowLoginModal(true);
        this.setState({ isOpen: true });
      }
    }
    // const listener = new BroadcastChannel(EVENT.project.Inspection_published_updated);
    const listener = getChannelListener(
      EVENT.project.Inspection_published_updated,
    );
    if (listener)
      listener.onmessage = e => {
        setTimeout(async () => {
          try {
            await this.fetchNotification();
          } catch (e) {
            // alert("검수서 로드 실패");
          }
        }, 1000);
        this.notificationStore.fetchNotiProjectSummaries();
      };

    // 접수 메뉴 웹노티
    // (TODO: 리스너 하나로 통합해서 사용할 수 있을 것 같음)
    // const listener_reception = new BroadcastChannel(EVENT.project.Reception_Rejected);
    const listener_reception_reject = getChannelListener(
      EVENT.project.Reception_Rejected,
    );
    const listener_reception_approved = getChannelListener(
      EVENT.project.Reception_Approved,
    );

    if (listener_reception_reject)
      listener_reception_reject.onmessage = e => {
        const { reception_id } = e.data;

        this.notificationStore.newReceptionNoti(reception_id);
        this.notificationStore.fetchNotiProjectSummaries();
      };
    if (listener_reception_approved)
      listener_reception_approved.onmessage = e => {
        const { reception_id } = e.data;

        this.notificationStore.newReceptionNoti(reception_id);
        this.notificationStore.fetchNotiProjectSummaries();
      };

    // 대시보드 메뉴 웹노티
    const listener_dashboard = getChannelListener(
      EVENT.project.ProjectGroup_updated,
    );
    if (listener_dashboard)
      listener_dashboard.onmessage = e => {
        // console.log(e.data)
        const { group_id } = e.data;

        if (group_id) {
          // console.log(group_id);
          // 웹노티 표시 처리 후,
          // if(!this.notificationStore.dashboardIds.includes(group_id)) {

          if (this.dashboardStore.isNewGroupId(group_id)) {
            // 받은 group_id가 새로운 id 면 전체 목록 fetch
            // console.log('new');

            (async () => {
              await this.dashboardStore.fetchAll();
              await this.dashboardStore.fetchById(group_id);
            })();
          } else {
            // 기존에 있던 group_id면 해당 아이템만 fetch
            // console.log('exsited');

            (async () => {
              await this.dashboardStore.fetchById(group_id);
            })();
          }

          this.notificationStore.newDashboardNoti(group_id);
          // }
        }
        this.notificationStore.fetchNotiProjectSummaries();
      };

    // 정산 메뉴 웹노티
    // const listener_calculate = new BroadcastChannel(EVENT.project.Calculate_updated);
    const listener_calculate = getChannelListener(
      EVENT.project.Calculate_updated,
    );
    if (listener_calculate)
      listener_calculate.onmessage = e => {
        this.notificationStore.newCalculateNoti('calculate'); // 정산 메뉴 웹노티는 별도 ID별 구분을 하지 않아, 'calculate' 하나로 고정 관리

        if (this.calculateStore.deposits.length > 0) {
          this.calculateStore.fetchAll();
        }
        this.notificationStore.fetchNotiProjectSummaries();
      };
  }

  handleResize = () => {
    const pc_size_reg = PC.match(/\d+/);

    if (pc_size_reg && window.innerWidth < Number(pc_size_reg[0])) {
      this.setState({
        isMobileMode: true,
      });
    } else {
      this.setState({
        isMobileMode: false,
      });
    }
  };
  // 로그인시 현재 상황을 확인하고, 기획에 따라 갈 곳을 정해주는 함수
  getHandleRedirect = async () => {
    // const { dashboardStore } = this.injected.appStore;

    let defaultUrl: string = ''; // home

    // seeso.kr에서 로그인하면 프로젝트 모드로 헤더 바뀌고,
    // 프로젝트 그룹 데이터가 있는 클라이언트 : 프로젝트모드>대시보드
    // 없는 클라이언트 : 프로젝트모드 > 프로젝트 의뢰
    console.log('this.userStore.isCpmsTheme/,', this.userStore.isCpmsTheme);
    if (this.userStore.isCpmsTheme) {
      //
      const groupsCount = this.notificationStore.notiProjectSummaries.pGCount;
      const ongoingPgs = this.notificationStore.notiProjectSummaries.ongoingPgs;
      if (ongoingPgs.length > 0) {
        // 첫번째 인것으로 가도록
        defaultUrl = `dashboard/${ongoingPgs[0]}`;
      } else if (groupsCount && groupsCount > 0) {
        defaultUrl = 'dashboard';
      } else {
        defaultUrl = 'receptions';
      }
    }

    return defaultUrl;
  };

  handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    this.email = e.target.value;
  };

  handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
  };

  handleLogin = async () => {
    try {
      const nextTo: string | undefined =
        this.props.location.pathname === '/'
          ? undefined
          : this.props.location.pathname + this.props.location.search;
      const redirectTo: any =
        qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
          .redirect_to || nextTo;

      await this.userStore.login(this.email, this.password);
      this.setIsOpen(false);
      this.userStore.setShowLoginModal(false);
      await this.fetchNotification();
      await this.notificationStore.fetchNotiProjectSummaries();
      await this.userStore.updateCurrentThemeStatus(true);

      let redirectUrl: string;
      redirectTo
        ? (redirectUrl = redirectTo)
        : (redirectUrl = await this.getHandleRedirect());
      this.props.history.push(
        redirectUrl.startsWith('/') ? redirectUrl : `/${redirectUrl}`,
      );
    } catch (e) {
      AppToaster.show({
        message: '아이디 또는 비밀번호를 다시 확인하세요.',
        intent: Intent.DANGER,
      });
    } finally {
      window.localStorage.removeItem('nexturl');
    }
  };

  fetchNotification = async () => {
    try {
      await this.notificationStore.fetchNotification();
    } catch (e) {
      // console.log(e)
    }
  };

  handleLogout = async () => {
    this.userStore.logout();
    document.location.href = '/';
  };

  setIsOpen = async (val: boolean) => {
    this.setState({
      isOpen: val,
    });
  };

  setShowLoginModal = (val: boolean) => {
    // 로그인 모달 관리
    this.userStore.setShowLoginModal(val);
  };

  changeToCpmsTheme = async (val: boolean, urlRedirecTo: string = '') => {
    // cpms테마로 변경 -> true, 아니면 else
    // urlRedirecTo 가 존재하면, 해당 url 로 보내고, 아닐 경우, 디폴트 리다이렉트 url로 보냄

    await this.userStore.updateCurrentThemeStatus(val);
    let redirectUrl: string;
    urlRedirecTo.length > 0
      ? (redirectUrl = urlRedirecTo)
      : (redirectUrl = await this.getHandleRedirect());
    this.props.history.push(`/${redirectUrl}`);
  };

  handleRedirectLogo = async () => {
    const redirectUrl = await this.getHandleRedirect();
    await this.props.history.push(`/${redirectUrl}`);
  };

  render() {
    const childProps = {
      current: this.props.current,
      isLoggedIn: this.userStore.isLoggedIn,
      // isLoggedIn: true,
      isCpmsTheme: this.userStore.isCpmsTheme,
      username: this.userStore.username,
      handleChangeEmail: this.handleChangeEmail,
      handleChangePassword: this.handleChangePassword,
      handleLogin: this.handleLogin,
      handleLogout: this.handleLogout,
      inspectionTotalCount:
        this.injected.appStore.inspectionStore.inspectionTotalCount,
      inspectionNewCount: this.notificationStore.inspectionNewCount,
      receptionNewCount: this.notificationStore.receptionIds.length,
      dashboardNewCount: this.notificationStore.dashboardIds.length,
      calculateNewCount: this.notificationStore.calculateIds.length,
      notiProjectSummaries: this.notificationStore.notiProjectSummaries,

      changeToCpmsTheme: this.changeToCpmsTheme,
      isOpen: this.state.isOpen, // pc - 서브메뉴 // 모바일 - swipe 된 메뉴
      setIsOpen: this.setIsOpen,

      modalIsOpen: this.userStore.showLoginModal, // 로그인 모달관리
      setModalIsOpen: this.setShowLoginModal,
      handleRedirectLogo: this.handleRedirectLogo,
    };
    const { setFocusedSection, setIsSideMenuOpen, isSideMenuOpen } = this.props;

    return (
      <Header
        setFocusedSection={setFocusedSection}
        setIsSideMenuOpen={setIsSideMenuOpen}
        isSideMenuOpen={isSideMenuOpen}
        isLoggedIn={childProps.isLoggedIn}
        isMobileMode={this.state.isMobileMode}
        childProps={childProps}
      >
        <PCMenu {...childProps} />
        {!this.state.isMobileMode &&
          (childProps.isLoggedIn ? (
            <UserDropdown
              current={childProps.current}
              changeToCpmsTheme={childProps.changeToCpmsTheme}
              username={childProps.username}
              isCpmsTheme={childProps.isCpmsTheme}
              handleLogout={childProps.handleLogout}
              setIsOpen={this.setIsOpen}
              isOpen={childProps.isOpen}
            />
          ) : (
            <LoginButton {...childProps} />
          ))}
      </Header>
    );
  }
}

export default MenuBar;

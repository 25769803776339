import styled from 'styled-components/macro';

export const clientWrapper = styled.div`
  position: relative;
  padding-top: 90px;
  background: #161d2e;
  box-sizing: border-box;
  overflow: hidden;
  h3 {
    position: relative;
    margin-left: 215px;
    color: #fff;
    font-size: 22px;
    span {
      position: relative;
      z-index: 2;
    }
    @media only screen and (max-width: 640px) {
      margin: 0 30px;
    }
  }
  h3:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: -5px;
    left: -15px;
    width: 27px;
    height: 27px;
    opacity: 0.8;
    background-color: #4b34ff;
    border-radius: 50%;
  }

  .inner {
    width: 100%;
    margin: 0 auto;
    padding-top: 0px;
    .clInfo {
      position: relative;
      height: 390px;
      padding-left: 215px;
      padding-right: 720px;
      display: inline-block;
      color: #fff;
      strong {
        font-size: 22px;
      }
      p {
        padding-top: 28px;
        color: #e2e2e2;
        font-size: 15px;
        font-weight: normal;
        line-height: 1.53;
      }
      .img {
        position: absolute;
        bottom: 0;
        right: 0;
        img {
          display: block;
          width: 100%;
        }
      }
    }
  }

  .controller {
    width: 1200px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    button {
      position: absolute;
      top: 295px;
      width: 40px;
      height: 40px;
      background: none;
      border: none;
      z-index: 99;
      cursor: pointer;
      span {
        display: none;
      }
    }
    button.prev::before,
    button.next::before {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      transform: rotate(-225deg);
      border-width: 0 3px 3px 0;
      border-style: solid;
      border-color: #fff;
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    }
    button.prev {
      left: 13px;
    }
    button.next {
      right: -10px;
    }
    button.next:before {
      transform: rotate(-45deg);
      box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
    }
    > div {
      position: absolute;
      top: 400px;
      left: 100px;
      a {
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 7px;
        background: #444e6e;
        border-radius: 50%;
      }
      a.selected {
        background: #a0a7bb;
      }
      > div {
        bottom: 320px;
        left: auto;
        right: 10px;
      }
      .inner {
        .clInfo {
          height: 324px;
          margin-top: 78px;
          display: inline-block;
          .img {
            height: 324px;
            overflow: hidden;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 640px) {
    // margin-top: -72px;
    // margin-top: 40px;
    padding: 0;
    padding-top: 40px;

    .inner {
      padding-top: 30px;
      padding-bottom: 30px;
      .clInfo {
        height: auto;
        padding: 0;
        height: auto;
        margin-top: 30px;
        padding: 0 30px;
        word-break: keep-all;

        strong {
          font-size: 18px;
        }
        p {
          padding-top: 14px;
          font-size: 14px;
        }
        .img {
          position: static;
          padding-top: 15px;
        }
      }
      h3 {
        margin-left: 30px;
      }
      button {
        top: 200px;
      }
      button.prev {
        left: 13px;
      }
      button.next {
        right: -10px;
      }
    }
    .controller {
      width: 100%;
    }
  }
`;

// import { ReceptionResponse } from "../../types/reception";
import axios from 'axios';
import { flow, isAlive, types } from 'mobx-state-tree';
import moment from 'moment';

export enum ReceptionStatus {
  Pending = 'pending',
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export interface ReceptionResponse {
  id: number;
  receptionNumber: string;
  title: string;
  area_type: string[];
  area_type_etc_text: string;
  area: string[];
  area_etc_text: string;
  data_prepared: string[];
  etc_reference: string[];
  exist_reference: boolean;
  reference_text: string;
  priority: string;
  feature: string[];
  detail: string;
  drive_links: string[];
  cancel_date: string;
  date_meeting: string;
  date_register: string;
  status: string;
  data_file: string;
  client_uuid: string;
  service_type: string | null;
  memo: string;
  client: string | null;
  manager: number | null;
  inspector: number | null;
  clientUser: number | null;
  receptionist_name: string;
  affiliation: string | null;
  affiliation_type: string | null;
  phone: string;
  client_email: string | null;
}

export interface ReceptionListResponse {
  results: ReceptionResponse[];
}

export const mapper = (x: ReceptionResponse) => {
  return {
    id: x.id,
    receptionNumber: x.receptionNumber,
    title: x.title,
    areaType: x.area_type,
    areaTypeEtcText: x.area_type_etc_text,
    area: x.area,
    areaEtcText: x.area_etc_text,
    dataPrepared: x.data_prepared,
    etcReference: x.etc_reference,
    existReference: x.exist_reference,
    referenceText: x.reference_text,
    priority: x.priority,
    feature: x.feature,
    detail: x.detail,
    driveLinks: x.drive_links,
    cancelDate: x.cancel_date,
    dateMeeting: x.date_meeting,
    dateRegister: x.date_register,
    status: x.status,
    dataFile: x.data_file,
    clientUuid: x.client_uuid,
    memo: x.memo,
    serviceType: x.service_type,
    client: x.client,
    manager: x.manager,
    inspector: x.inspector,
    clientUser: x.clientUser,
    receptionistName: x.receptionist_name,
    affiliation: x.affiliation,
    affiliationType: x.affiliation_type,
    phone: x.phone,
    clientEmail: x.client_email,
  };
};

export const ReceptionModel = types
  .model('Reception', {
    id: types.identifierNumber,
    receptionNumber: types.maybeNull(types.string),
    title: types.string,
    areaType: types.array(types.string),
    areaTypeEtcText: types.string,
    area: types.array(types.string),
    areaEtcText: types.string,
    dataPrepared: types.array(types.string),
    etcReference: types.array(types.string),
    existReference: types.maybeNull(types.boolean),
    referenceText: types.string,
    priority: types.string,
    feature: types.array(types.string),
    detail: types.string,
    driveLinks: types.array(types.maybeNull(types.string)),
    cancelDate: types.maybeNull(types.string),
    dateMeeting: types.string,
    dateRegister: types.string,
    status: types.string,
    dataFile: types.maybeNull(types.string),
    clientUuid: types.maybeNull(types.string),
    memo: types.string,
    serviceType: types.maybeNull(types.string),
    client: types.maybeNull(types.string),
    manager: types.maybeNull(types.number),
    inspector: types.maybeNull(types.number),
    clientUser: types.maybeNull(types.number),
    receptionistName: types.maybeNull(types.string),
    affiliation: types.maybeNull(types.string),
    affiliationType: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    clientEmail: types.maybeNull(types.string),
  })
  .views(self => ({
    get nameWithId() {
      return `${self.receptionNumber} - ${self.title} (${moment(
        self.dateRegister,
      ).format('YYYY년MM월DD일')})`;
    },
    get dateRegisterString() {
      return moment(self.dateRegister).format('YYYY.MM.DD HH:mm');
    },
    get dateMeetingString() {
      return moment(self.dateMeeting).format('YYYY.MM.DD HH:mm ');
    },
    get cancelDateString() {
      if (!self.cancelDate) {
        return '';
      }
      return moment(self.cancelDate).format('YYYY.MM.DD HH:mm ');
    },
  }))
  .actions(self => {
    // change to cancel
    const cacelReception = flow(function* () {
      try {
        const { data } = yield axios.get(`/reception/${self.id}/reject`);

        if (data.error) {
          throw {
            response: {
              data,
            },
          };
        }

        if (isAlive(self)) {
          self.status = data.status;
        }
      } catch (e) {
        console.error('cacelReception error', e);
        throw e;
      }
    });

    return {
      cacelReception,
    };
  });

type ReceptionType = typeof ReceptionModel.Type;
export interface IReception extends ReceptionType {}

import { groupBy, sortBy } from 'lodash';
import { flow, types } from 'mobx-state-tree';

import moment from 'moment';

import { downloadSprintReport } from '../apis/sprint';

export interface IResponse {
  id: number;
  project_group: string;
  project_group_name: string;
  raw_data: {
    postfix: string;
  };
  created_at: string;
  updated_at: string;
  sprint: number;
  report_id: string;
  version: number;
  date_publish: string | null;
  html_data: string;
}

const ReportModel = types
  .model({
    id: types.number,
    createdAt: types.Date,
    datePublish: types.Date,
    htmlData: types.string,
    projectGroupId: types.string,
    reportId: types.string,
    updatedAt: types.Date,
    title: types.string,
    version: types.number,
  })
  .actions(self => {
    const download = flow(function* () {
      try {
        yield downloadSprintReport(self.projectGroupId, self.id).then(
          response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const filename = `${self.title}.pdf`;

            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
          },
        );
      } catch (e) {
        throw e;
      }
    });

    return {
      download,
    };
  });

type ReportType = typeof ReportModel.Type;
export interface IReport extends ReportType {}

export const mapper = (resps: IResponse[]) => {
  const groupsBySprint = groupBy(
    sortBy(resps, resp => resp.sprint),
    resp => resp.sprint,
  );

  return Object.entries(groupsBySprint).map(([sprintNum, reports]) => {
    const projectGroupName = reports[0].project_group_name;
    const reportInstances = reports
      .filter(report => report.date_publish)
      .map(report =>
        ReportModel.create({
          createdAt: moment(report.created_at).toDate(),
          datePublish: moment(report.date_publish).toDate(),
          htmlData: report.html_data,
          id: report.id,
          projectGroupId: report.project_group,
          reportId: report.report_id,
          title: `${projectGroupName} 스프린트보고서 S${sprintNum} ${report.raw_data.postfix}`,
          updatedAt: moment(report.updated_at).toDate(),
          version: report.version,
        }),
      );

    return SprintReportModel.create({
      sprint: parseInt(sprintNum),
      reports: reportInstances,
    });
  });
};

export const SprintReportModel = types
  .model('SprintReport', {
    sprint: types.number,
    reports: types.array(ReportModel),
  })
  .views(self => ({
    get title() {
      if (self.sprint === 0) {
        return 'PRE';
      }
      return `S${self.sprint}`;
    },
  }));

type SprintReportType = typeof SprintReportModel.Type;
export interface ISprintReport extends SprintReportType {}

import { Intent } from '@blueprintjs/core';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import styled from 'styled-components/macro';

import { EditMode } from '../../../constants/mode';

import { AppStore } from '../../../store/AppStore';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import Button from '../../molecules/MyPage/Button';
import { MyPageTab } from '../../pages/MyPage/Constants';

const Wrapper = styled.div`
  display: flex;
`;

const SubmitButton = styled(Button)``;

interface SubmitContentProps {
  selectedTab: MyPageTab;
  onTabChanged: (newTab: MyPageTab) => void;
  editMode: EditMode;
  onEditModeChange: (value: EditMode) => void;
  onCancel: () => void;
  onSubmit: () => void;
}

interface InjectedProps extends SubmitContentProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class SubmitContent extends Component<SubmitContentProps> {
  get injected() {
    return this.props as InjectedProps;
  }
  get store() {
    return this.injected.appStore.myPageStore;
  }

  @computed
  get isSubmitable() {
    return (
      !this.store.isFileUploading &&
      ((this.props.selectedTab === MyPageTab.COMPANY &&
        this.store.companyName &&
        this.store.representativeName) ||
        (this.props.selectedTab === MyPageTab.USER &&
          this.store.userName &&
          this.store.userEmail &&
          this.store.userPhone))
    );
  }

  checkSubmitError = () => {
    if (!this.store.companyName) {
      AppToaster.show({
        message: '오류: 회사정보 > 회사 이름을 입력하세요.',
        intent: Intent.DANGER,
      });
      this.props.onTabChanged(MyPageTab.COMPANY);
      return false;
    }
    if (!this.store.representativeName) {
      AppToaster.show({
        message: '오류: 회사정보 > 대표자 이름을 입력하세요.',
        intent: Intent.DANGER,
      });
      this.props.onTabChanged(MyPageTab.COMPANY);
      return false;
    }
    if (!this.store.userName) {
      AppToaster.show({
        message: '오류: 내 정보 > 이름을 입력하세요.',
        intent: Intent.DANGER,
      });
      this.props.onTabChanged(MyPageTab.USER);
      return false;
    }
    if (!this.store.userEmail) {
      AppToaster.show({
        message: '오류: 내 정보 > 이메일을 입력하세요.',
        intent: Intent.DANGER,
      });
      this.props.onTabChanged(MyPageTab.USER);
      return false;
    }
    if (!this.store.userPhone) {
      AppToaster.show({
        message: '오류: 내 정보 > 연락처를 입력하세요.',
        intent: Intent.DANGER,
      });
      this.props.onTabChanged(MyPageTab.USER);
      return false;
    }
    return true;
  };

  handleSubmit = async () => {
    // console.log(`회사이름: ${this.store.companyName}`);
    // console.log(`회사종류: ${this.store.companyType}`);
    // console.log(`대표자이름: ${this.store.representativeName}`);
    // console.log(`사업자등록번호: ${this.store.bizNo}`);
    // console.log(`회사 대표 전화번호: ${this.store.bizPhone}`);
    // console.log(`계약 담당자 이름:  ${this.store.contractPersonName}`);
    // console.log(`계약 담당자 연락처: ${this.store.contractPersonPhone}`);
    // console.log(`계약 담당자 이메일: ${this.store.contractPersonEmail}`);
    // console.log(`세금계산서 담당자 이름:  ${this.store.taxPersonName}`);
    // console.log(`세금계산서 담당자 연락처: ${this.store.taxPersonPhone}`);
    // console.log(`세금계산서 담당자 이메일: ${this.store.taxPersonEmail}`);
    // console.log(`이름: ${this.store.userName}`);
    // console.log(`이메일: ${this.store.userEmail}`);
    // console.log(`연락처: ${this.store.userPhone}`);
    // console.log(`계정 Slack: ${this.store.toolSlack}`);
    // console.log(`계정 Trello: ${this.store.toolTrello}`);
    // console.log(`계정 Zeplin: ${this.store.toolZeplin}`);
    // console.log(`계정 Github: ${this.store.toolGithub}`);

    try {
      const { userStore } = this.injected.appStore;

      if (this.store.clientId > 0 && this.store.clientCLId !== '') {
        await this.store.patchClient();
      }

      if (this.store.clientPersonalId > 0) {
        await this.store.putClientPersonal(userStore.userId);
      } else {
        await this.store.postClientPersonal(userStore.userId);
      }

      AppToaster.show({
        message: '수정내역 저장 완료',
        intent: Intent.SUCCESS,
      });

      this.props.onSubmit();
    } catch (e) {
      const error = JSON.stringify(e.response.data);
      AppToaster.show({
        message: '오류: ' + error,
        intent: Intent.DANGER,
      });
    }
  };

  render() {
    return (
      <Wrapper>
        {this.props.editMode === EditMode.CREATE && (
          <SubmitButton
            text="저장 하기"
            theme="blue"
            disabled={!this.isSubmitable}
            pcStyle={`
              width: 186px;
              margin: 0 auto;
            `}
            mobileStyle={`
              width: 180px;
              margin: 0 auto;
            `}
            onClick={() => {
              if (this.checkSubmitError()) {
                this.handleSubmit();
              }
            }}
          />
        )}
        {this.props.editMode === EditMode.READ && (
          <SubmitButton
            text="수정 하기"
            theme="blue"
            pcStyle={`
              width: 186px;
              margin: 0 auto;
            `}
            mobileStyle={`
              display: none;
            `}
            onClick={() => {
              this.props.onEditModeChange(EditMode.UPDATE);
            }}
          />
        )}
        {this.props.editMode === EditMode.UPDATE && (
          <>
            <SubmitButton
              text="취소"
              unfocused
              pcStyle={`
              width: 180px;
              margin-left: auto;
            `}
              mobileStyle={`
              display: none;
            `}
              onClick={() => {
                this.props.onCancel();
                this.props.onEditModeChange(EditMode.READ);
              }}
            />
            <SubmitButton
              text="저장 하기"
              theme="blue"
              disabled={!this.isSubmitable}
              pcStyle={`
              width: 180px;
              margin-left: 40px;
              margin-right: auto;
            `}
              mobileStyle={`
              display: none;
            `}
              onClick={() => {
                if (this.checkSubmitError()) {
                  this.handleSubmit();
                  this.props.onEditModeChange(EditMode.READ);
                }
              }}
            />
            <SubmitButton
              text="수정 취소"
              unfocused
              pcStyle={`
              display: none;
            `}
              mobileStyle={`
              display: block;
              width: 146px;
              margin-left: auto;
              margin-right: 20px;
            `}
              onClick={() => {
                this.props.onCancel();
              }}
            />
            <SubmitButton
              text="수정 내역 저장"
              theme="blue"
              disabled={!this.isSubmitable}
              pcStyle={`
              display: none;
            `}
              mobileStyle={`
              display: block;
              width: 146px;
              margin-left: 20px;
              margin-right: auto;
            `}
              onClick={() => {
                if (this.checkSubmitError()) {
                  this.handleSubmit();
                }
              }}
            />
          </>
        )}
      </Wrapper>
    );
  }
}

export default SubmitContent;

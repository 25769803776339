import React, { Component } from 'react';
import { Terms as Comps } from '../../organisms/Etc';

class AgreementPage extends Component {
  render() {
    return (
      <Comps.TermsWrapper>
        <div className="term-wrap">
          <h1 style={{ textAlign: 'center' }}>서비스 이용약관</h1>
          <h3>[제1장 총칙]</h3>
          <br />
          <strong>제1조 목적</strong>
          <br />
          이 약관은 주식회사 시소(이하 “회사”)이 제공하는 시소 사이트 및 시소
          관련 제반 서비스(이하 "서비스")의 이용과 관련하여 회사와 회원과의
          권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
          <br />
          <br />
          <strong>제2조 용어의 정의</strong>
          <br />
          회원
          <br />
          회사의 서비스를 이용하기 위해 회원 가입을 하고 회원 계정을 소유하며
          약관에 따른 권리와 의무를 갖는 자를 말합니다.
          <br />
          계정
          <br />
          회원의 식별과 서비스 이용을 위해 가입 시 회원이 정하고 회사가 승인한
          고유 식별 기호입니다.
          <br />
          서비스
          <br />
          서비스는 구현되는 단말기(PC, TV, 휴대형 단말기 등 각종 유무선 장치를
          포함)와 제공 형태(웹 사이트, 모바일 애플리케이션 등)에 상관없이 회원이
          이용할 수 있는 시소 및 시소 관련 제반 서비스를 의미합니다.
          <br />
          전수자
          <br />
          자신의 자영업 관련 노하우를 제공하기 위해 사이트 내 전수내용을
          오픈하고 모임을 개설한 사람을 의미 합니다.
          <br />
          <br />
          <strong>제3조 약관 등의 명시, 효력 및 변경</strong>
          <br />
          회원이 본 약관에 동의하고, 회원에 가입함과 동시에 효력이 발생합니다.
          본 약관은 관계법령에 위배되지 않는 범위 안에서 개정될 수 있으며, 이는
          온라인 사이트(rufree.kr)에 게시하여 효력을 인정받습니다.
          <br />
          본 약관에 대한 동의는 웹 사이트나 모바일 애플리케이션을 정기적으로
          방문하여 약관의 변경 사항을 확인하는 것에 대한 동의를 포함합니다.
          변경된 약관에 대한 정보를 알지 못해 발생하는 회원의 피해는 회사에서
          책임지지 않습니다.
          <br />
          회사가 약관을 개정할 경우에는 적용 일자 및 개정사유를 명시하여
          현행약관과 함께 제1항의 방식에 따라 그 개정약관의 적용 일자 7일 전부터
          적용일자 전일까지 공지합니다. 다만, 회원에게 불리한 약관의 개정의
          경우에는 공지 외에 일정기간 계정에 입력된 전자우편, 로그인 시 동의창
          등의 전자적 수단을 통해 따로 통지하도록 합니다.
          <br />
          회원은 개정된 약관에 동의하지 않을 경우 회사에 서비스 탈퇴를 요청할 수
          있으며, 변경된 약관의 효력 발생일로부터 7일 이후에도 거부의사를
          표시하지 아니하고 서비스를 계속 이용할 경우 이는 변경된 약관에
          동의하는 것으로 간주됩니다.
          <br />
          이 약관은 회사와 회원 간에 성립되는 서비스 이용 계약의 기본
          약관입니다. 회사는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하
          "개별약관"이라 합니다)을 정하여 미리 공지할 수 있습니다. 회원이 이러한
          개별약관에 동의하고 특정 서비스를 이용하는 경우에는 개별약관이
          우선적으로 적용되고, 이 약관은 보충적인 효력을 갖습니다. 개별약관의
          변경에 관해서는 위 2항을 준용합니다.
          <br />
          <br />
          <strong>제4조 약관 외 준칙</strong>
          <br />
          이 약관에 명시되지 않은 사항은 전자거래기본법, 전자상거래 등에서의
          소비자 보호에 관한 법률, 약관의 규제에 관한 법률, 정보통신망 이용촉진
          및 정보보호 등에 관한 법률 및 기타 관련법령의 규정에 준합니다.
          <br />
          <br />
          <br />
          <h3>[제2장 서비스이용계약의 체결]</h3>
          <br />
          <br />
          <strong>제1조 서비스이용계약의 체결</strong>
          <br />
          이용계약(이하 “회원가입”이라 합니다)은 회원이 되고자 하는 자가 가입
          양식에 따라 회원 정보를 입력 후 “약관에 동의”를 거쳐 회원가입 신청하면
          회사가 승낙함으로써 성립됩니다.
          <br />
          회원가입 성립 시점은 회사가 가입완료를 신청절차 상에서 표시한 시점으로
          합니다.
          <br />
          회원이 입력한 정보는 사실로 간주됩니다. 회사는 회원이 입력한 정보의
          내용이 사실과 다를 경우(차명, 비실명, 허위정보 등)와 타인의 정보를
          도용한 것으로 의심되는 경우, 사실 여부를 확인하기 위해 회원에게
          입증자료 제출 및 해명을 요구할 수 있으며 상이한 사실이 명백하게
          확인되는 경우, 회사는 회원에게 회원의 권한을 삭제하며 서비스의
          전면적인 이용을 거부할 수 있고, 이로 인해 발생하는 모든 불이익은
          회사가 책임지지 않습니다. 또한, 회사는 이를 위하여 필요한 경우에는
          재증명을 요구할 수 있습니다.
          <br />
          회사는 회원의 상태와 이용기간, 이용횟수, 서비스 접근 방식 등에 따라
          합리적인 기준을 통해 서비스 범위에 차등을 둘 수 있으며, 회원은 해당
          내용에 따라 서비스 이용에 제약을 받을 수 있습니다.
          <br />
          회사는 1항과 같은 방법으로 회원가입을 신청한 회원이 아래 각 호에
          해당하는 조건을 충족하지 못할 경우 회원가입 승낙을 유보 또는 거부할 수
          있습니다.
          <br />
          등록내용을 허위로 기재한 사실이 있는 경우
          <br />
          가입 신청자가 회사 약관 및 서비스 이용에 관한 관계법령을 위반하여 회사
          서비스의 회원자격을 상실한 경우
          <br />
          C. 사회적 질서 및 미풍양속에 문란이 되는 행위자
          <br />
          D. 회사의 서비스 운영 목적 및 취지에 부합하지 않는 2차적인 이익 추구를
          목적으로 서비스를 이용하는 자<br />
          E. 기타 회사의 여건상 이용승낙이 곤란하거나 가입결격 사유에 해당한 자
          <br />
          ⑥ 미성년자와 계약에서 법정대리인의 동의를 받지 않아 문제가 발생하는
          경우, 미성년자인 회원 또는 그 미성년자의 법정대리인은 관련법령에 따라
          해당 계약을 취소할 수 있습니다.
          <br />
          <br />
          <strong>제2조 개인정보의 수집 등</strong>
          <br />
          회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터
          필요한 개인정보를 수집합니다.
          <br />
          <br />
          <strong>제3조 정보제공 및 광고의 게재</strong>
          <br />
          회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보 및 광고에
          대해서는 전자우편, SMS, DM(Direct Mail), 메신저 등의 방법으로 회원에게
          제공할 수 있으며, 만약 원치 않는 정보를 수신한 경우 회원은 이를
          수신거부 할 수 있습니다.
          <br />
          회사는 서비스의 운용과 관련하여 서비스화면, 홈페이지, 전자우편 등에
          광고 등을 게재할 수 있으며, 회사는 서비스를 이용하고자 하는 회원이 이
          광고게재에 대해 동의하는 것으로 간주합니다.
          <br />
          회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주와의 판촉활동에
          회원이 참가하거나 교신 또는 거래의 결과로서 발생하는 모든 손실 또는
          손해에 대해 책임을 지지 않습니다.
          <br />
          <br />
          <strong>제4조 서비스의 위탁</strong>
          <br />
          회사는 필요하다고 판단될 경우 서비스의 일부를 제휴업체에 위탁할 수
          있습니다.
          <br />
          <br />
          <strong>제5조 개인정보보호정책</strong>
          회사는 관계법령이 정하는 바에 따라 회원등록정보를 포함한 회원의
          개인정보를 보호하기 위하여 노력합니다.
          <br />
          회원의 개인정보보호에 관하여 관계법령 및 회사가 정하는 개인정보
          취급방침에 정한 바에 따릅니다. 단, 회원의 귀책사유로 노출된 정보에
          대해 회사는 일체의 책임을 지지 않습니다.
          <br />
          회사의 개인정보 취급방침은 회사의 홈페이지에 링크된 화면을 통해
          확인하실 수 있습니다.
          <br />
          <br />
          <br />
          <h3>[제3장 계약 당사자의 의무]</h3>
          <br />
          <br />
          <strong>제1조 회사의 의무</strong>
          <br />
          회사는 지속적이고 안정적인 서비스 제공을 위해 최선을 다합니다.
          <br />
          회사는 회사가 정한 약관 및 운영 정책 혹은 법령을 위반하는 회원을 강제
          탈퇴 조치하거나 혹은 관계 법령에 따라 법적 절차를 진행할 의무를
          집니다.
          <br />
          회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률 등 관계법령에
          따라 회원이 회원 가입 신청 시 기록한 개인정보, 이후에 추가로 기록한
          개인정보 및 서비스 이용 중 생성되는 개인정보를 보호하여야 합니다.
          <br />
          회사의 임직원은 서비스 제공과 관련, 회원의 개인정보를 제3자에게 누설
          또는 배포해서는 안 되며, 이를 어길 시 직위해제의 사유가 될 수
          있습니다.
          <br />
          회사는 회원에게 회사의 서비스 내용 및 이용 안내, 주요한 운영정책이나
          약관의 변경사항 등 서비스 운영에 관련한 제반 사항에 대해 약관에 따른
          방법으로 공지합니다.
          <br />
          회사는 이용계약 체결, 계약사항 변경 및 해지 등 회원과의 계약 관련 절차
          및 내용 등에 있어 회원에게 편의를 제공하도록 최선을 다합니다.
          <br />
          <br />
          <strong>제2조 회원의 의무</strong>
          <br />
          회원 가입 시 회원이 입력하는 개인정보는 입력된 기간 동안 사실이어야
          합니다. 또한, 변경되는 개인 정보는 지속적으로 웹 사이트나 모바일
          애플리케이션에 방문하여 회원 본인이 갱신하여야 합니다. 이를 이행하지
          않음으로써 발생하는 불이익은 회사가 책임지지 않습니다.
          <br />
          만일 위 정보가 허위로 작성한 사실이 적발될 경우, 회사는 회원의 서비스
          이용을 제한할 수 있으며 이로 인해 다른 회원에게 정신적, 물질적 손해가
          발생할 경우 그에 대한 민•형사상의 책임을 포함한 모든 책임은 회원
          본인에게 귀속됩니다.
          <br />
          회사 내에 게재된 각 회원의 게시물 및 인터뷰 자료는 각 등록 회원 및
          회사의 저작물로, 회사 사이트 외 제3의 공간(포털사이트 등)에 무단으로
          게재하거나 상업적 용도로 이용할 수 없으며, 해당 행위 적발 시 탈퇴조치
          및 관련 법령에 의거 의법조치 할 수 있습니다.
          <br />
          당사는 회원이 입력한 정보에 대해 보증을 하지 않습니다. 또한, 만남에서
          발생한 문제의 책임은 전적으로 회원 본인에게 귀속되므로 이를 숙지한 후
          서비스를 이용해야 합니다.
          <br />
          서비스를 통해 얻은 정보를 당사의 사전 승낙 없이 서비스 이용 이외의
          목적으로 복제하거나, 이를 출판 및 방송 등에 사용하거나, 제3자에게
          제공해서는 안 됩니다.
          <br />
          회원은 항상 회사의 공지사항 내용을 숙지하여야 합니다. 위 의무를
          성실하게 이행하지 않은 상황에서의 문제는 회사가 책임지지 않습니다.
          <br />
          회원은 아래 각호에 해당하는 행위를 해서는 안 됩니다.
          <br />
          회사 사이트의 회원정보를 부정하게 취득하는 행위
          <br />
          회사 홈페이지 혹은 애플리케이션 해킹 또는 기타 유사 프로그램을
          이용하여 정상적인 운영을 어렵게 하는 행위 (예: 해킹 또는 바이러스
          프로그램)
          <br />
          타인의 ID와 비밀번호를 도용하는 행위
          <br />
          개인 정보를 허위 또는 타인의 것으로 등록하는 행위
          <br />
          사이트 내 불법적으로 물건을 판매하는 등의 상행위
          <br />
          다른 회원을 희롱 또는 위협하거나, 특정 회원에게 지속적으로 고통 또는
          불편을 주는 행위
          <br />
          공공질서, 미풍양속에 저해하는 저작물을 등록 또는 유통시키는 행위
          <br />
          모욕적이거나 명예훼손적인 내용 또는 공서양속에 위반되는 저속, 음란한
          내용의 정보를 유포하는 행위
          <br />
          다음과 같은 행동으로 회사의 건전한 운영을 해하거나 회사의 업무를
          방해하는 행위
          <br />
          회사의 신뢰성을 해하는 행위
          <br />
          회사의 운영과정에서 직원에게 폭언 또는 음란한 언행을 하여 업무환경을
          심각하게 해하는 행위
          <br />
          회사의 운영과정에서 이유 없는 잦은 연락이나 소란 또는 협박, 인과관계가
          입증되지 않는 피해에 대한 보상(적립금, 현금, 상품, 캐시 등)요구 등으로
          업무를 방해하는 행위
          <br />
          기타 회사의 운영 정책을 위반하는 행위
          <br />
          본 약관을 위반하거나, 기타 대한민국 관계 법령을 위반하는 행위에
          대해서는 경고 없이 회원의 권한이 박탈되며, 회사는 어떠한 보상도
          회원에게 제공하지 않습니다.
          <br />
          <br />
          <br />
          <h3>[제4장 서비스 이용]</h3>
          <br />
          <br />
          <strong>제1조 서비스이용 해지</strong>
          <br />
          회원이 이용계약을 해지하고자 하는 경우에는 회원 본인이 온라인을 통하여
          탈퇴 신청을 하여야 합니다.
          <br />
          회사가 제3자에게 합병 또는 분할합병되거나 서비스를 제3자에게
          양도함으로써 서비스의 제공 주체가 변경되는 경우, 회사는 사전에
          이메일과 공지사항으로 회원에게 통지합니다. 이 경우 합병, 분할합병,
          서비스 양도에 반대하는 회원은 서비스 이용계약을 해지할 수 있습니다.
          <br />
          <br />
          <strong>제2조 서비스 이용 및 제한</strong>
          <br />
          회원은 무료로 서비스에 가입하여 일반 회원이 될 수 있습니다. 단, 일부
          서비스 이용 시 유료 회원으로 전환 가능합니다.
          <br />
          가입 후 승인이 완료된 회원은 회사 사이트에서 제공하는 전수 정보, 매장
          프로필 정보를 열람할 수 있으며, 전수 참가 및 신청 등의 서비스를 이용할
          수 있습니다.
          <br />
          회사에서는 양질의 서비스 제공을 위해 회원들의 프로필 및 전수자 내용의
          수정, 보완, 중지 등을 요청할 수 있으며 회사 내부 규정에 따라 일부
          서비스 이용을 제한할 수 있습니다.
          <br />
          회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가 있다고
          의심할만한 합리적인 사유가 발생할 경우 회사는 회원의 본 서비스 사용을
          일부 또는 전부 이용정지 할 수 있으며, 이로 인해 발생하는 불이익에 대해
          회사는 책임지지 않습니다.
          <br />
          회사는 회원이 본 약관 제2장 1조 서비스이용계약의 체결 및 제3장 2조
          회원의 의무 등 본 약관의 내용에 위배되는 행동을 한 경우를 포함하여
          다른 회원들에게 피해를 줄 수 있다고 판단될 경우, 사전 동의 없이 문제
          회원의 서비스 이용 제한, 탈퇴 처리, 계정 영구삭제 등의 후속 조치를
          취할 수 있습니다.
          <br />
          회원의 여건상 지속적으로 계약을 이행하지 못한다고 판단될 경우 임의탈퇴
          처리할 수 있습니다. (예: 사망 또는 행방불명, 3개월 이상 활동을 하지
          않는 휴면계정 회원 등)
          <br />
          회사의 서비스를 통해 만남이 진행된 뒤 회원들 간 행위의 책임은 모두
          회원들에게 귀속됩니다. 따라서 실제 만남 참가나 연락은 신중하게
          결정해야 합니다.
          <br />
          이 외 서비스 이용에 관한 상세 사항은 운영정책에 따릅니다.
          <br />
          <br />
          <strong>제3조 회원등급 및 평가제도</strong>
          <br />
          회사는 회사가 정한 기준에 따라 회원등급 및 등급에 따른 일정 혜택 등을
          부여할 수 있습니다. 상세 기준 및 내역은 해당 서비스 화면에 게재하거나
          홈페이지에 공지합니다.
          <br />
          회원이 자신에게 부여된 등급 구성요소에 대하여 이의를 제기하는 경우
          회사는 검토 후 등급 구성요소의 전부 또는 일부를 조정할 수 있습니다.
          <br />
          회원의 등급은 서비스 이용 시의 참고자료로 활용될 뿐이며, 회원의 신용을
          보증하거나 실질적인 가치를 평가하는 것은 아닙니다.
          <br />
          회원이 회원등급 및 회원평가제도의 목적과 취지에 위반하는 행위를 하는
          경우 회사는 해당 등급의 삭제 및 해당 회원에 대한 서비스 이용 정지 조치
          등을 취할 수 있습니다.
          <br />
          <br />
          <strong>제4조 게시물의 소유권</strong>
          <br />
          회원이 서비스 내에 게시한 게시물에 대한 권리와 책임은 게시한 회원에게
          귀속됩니다. 또한, 회사는 게시자의 동의 없이 게시물을 영리적인 목적으로
          이용할 수 없습니다. 단, 회사에 의해 작성된 인터뷰 등에 대한 제반
          저작권은 회사에 귀속됩니다.
          <br />
          회원은 서비스를 이용하여 취득한 정보를 임의 가공, 제3자 대상 판매 등
          기타 상업적 행위를 할 수 없습니다.
          <br />
          회사는 회사 내 콘텐츠 혹은 회사가 권리를 행사할 수 있는 저작물을
          토대로 영리적인 목적의 2차 저작물(글, 영상, 책자 등)을 제작할 수
          있으며, 이 경우 개별 회원의 저작물에 대해서는 각 회원의 동의 하에
          진행됩니다.
          <br />
          회사는 회원이 게시하거나 등록하는 서비스 내의 게시물, 게시 내용에 대해
          제2조 각호에 해당한다고 판단되거나 회사의 목적과 맞지 않는 경우
          사전통지 없이 삭제하거나 이동 또는 등록 거부할 수 있습니다.
          <br />
          <br />
          <strong>제6조 회사의 소유권</strong>
          <br />
          회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크, 로고,
          디자인, 서비스 명칭, 정보 및 상표 등과 관련된 지적 재산권 및 기타
          권리는 회사에 있습니다.
          <br />
          회원은 회사가 명시적으로 승인한 경우를 제외하고는 제1항 소정의 각
          재산에 대한 전부 또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도,
          재라이센스, 담보권설정행위, 상업적 이용행위를 할 수 없으며, 제3자로
          하여금 이와 같은 행위를 하도록 허락할 수 없습니다.
          <br />
          <br />
          <br />
          <h3>[제5장 기타사항]</h3>
          <br />
          <br />
          <strong>제1조 면책조항</strong>
          <br />
          회사는 운영상 또는 기술상의 필요에 따라 제공하고 있는 서비스를 변경할
          수 있습니다. 변경될 서비스의 내용 및 제공 일자 등에 대해서 회사가
          운영하는 홈페이지에 게시하거나 이메일, SMS로 회원에게 통지합니다. 단,
          회사가 사전에 통지할 수 없는 치명적인 버그 발생, 서버 기기결함, 긴급
          보안문제 해결 등 부득이한 사정이 있는 경우에는 사후에 통지할 수
          있습니다.
          <br />
          회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 제한하거나
          중지할 수 있습니다.
          <br />
          전시, 사변, 천재지변 또는 국가 비상사태 등 불가항력적인 사유가 있는
          경우
          <br />
          서비스용 설비의 보수 또는 공사 등 부득이한 사유가 있는 경우
          <br />
          제3자의 고의적인 서비스 방해가 있는 경우
          <br />
          회사의 제반 사정으로 서비스를 할 수 없는 경우
          <br />
          회사는 회원의 귀책사유로 인한 서비스 이용 장애에 대한 책임을 지지
          않습니다.
          <br />
          회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
          정확성 등의 내용에 관하여는 책임을 지지 않습니다.
          <br />
          회사는 회원 간 또는 회원과 제3자 상호 간에 서비스를 매개로 하여 거래
          등을 한 경우에는 책임이 면제됩니다.
          <br />
          회사에서 회원에게 무료로 제공하는 서비스의 이용과 관련해서는 어떠한
          손해도 책임을 지지 않습니다.
          <br />
          <br />
          <strong>제2조 분쟁해결</strong>
          <br />
          회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를
          보상 처리하기 위해서 피해보상처리 기구를 설치, 운영합니다.
          <br />
          회사는 이용자로부터 제출되는 불만사항 및 의견은 우선으로 그 사항을
          처리합니다. 다만 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와
          처리일정을 즉시 통보합니다.
          <br />
          <br />
          <strong>제3조 준거법 및 관할법원</strong>
          <br />
          이 약관의 해석 및 회원간의 분쟁은 대한민국의 관계법령과 상관습에
          따릅니다.
          <br />
          서비스의 이용으로 발생한 분쟁에 대해 소송이 제기되는 경우 회사의 본점
          소재지를 관할하는 법원을 관할 법원으로 합니다.
          <br />
          <br />
          <strong>[부칙]</strong>
          <br />
          이 약관은 2018년 6월 1일부터 유효합니다.
          <br />
        </div>
      </Comps.TermsWrapper>
    );
  }
}

export default AgreementPage;

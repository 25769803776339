import { inject } from 'mobx-react';
import React, { FC, useEffect } from 'react';
import { AppStore } from '../../../store/AppStore';

import { APP_NAME } from '../../../utils/firebase/push-notification';

import { Calculate as Comps } from '../../molecules/Calculate';
import { Deposit, Payout } from '../../organisms/Calculate';

interface DefaultProps {}
interface Props extends DefaultProps {
  appStore: AppStore;
}

const Calculate: FC<Props> = inject('appStore')(props => {
  const { calculateStore, notificationStore, sprintReportStore, userStore } =
    props.appStore;

  useEffect(() => {
    notificationStore.readNotification(APP_NAME.project_calculate, 'calculate');
  }, []);

  useEffect(() => {
    (async () => {
      if (calculateStore.deposits.length === 0) {
        calculateStore.init(userStore.clientName);
        calculateStore.fetchAll();
      }
    })();
  }, [calculateStore, userStore.isLoggedIn]);

  return (
    <Comps.Wrapper>
      {userStore.isLoggedIn && (
        <>
          <Deposit store={calculateStore} />

          <Payout
            store={calculateStore}
            sprintReportStore={sprintReportStore}
          />
        </>
      )}
    </Comps.Wrapper>
  );
});

export default Calculate;

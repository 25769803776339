import { types } from 'mobx-state-tree';

export const TaskSprintModel = types.model('TaskSprint', {
  id: types.number,
  title: types.string,
  task: types.string,
  task_sprint: types.string,
});
type TaskSprintType = typeof TaskSprintModel.Type;

export interface TaskSprint extends TaskSprintType {}

import React, { useEffect, useState } from 'react';

export interface ITaskListFooterProps {
  onAddTasks?: (title: string[]) => void;
  onClickTaskAdd: () => void;
}

export const TaskListFooter: React.SFC<ITaskListFooterProps> = props => {
  const [isAluralEditing, setIsAluralEditing] = useState(false);

  const [titles, setTitles] = useState('');

  // 복수 태스트 생성
  const onChangeAlural = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setTitles(e.target.value);
  };
  // 복수 태스트 생성 END

  // const onDocumentClick = (e: MouseEvent) => {
  //   // console.log(e.composedPath().find( path => (path as HTMLElement).className?.includes('add-task-input')));
  //   const clickedTarget = e.composedPath().find( path => (path as HTMLElement).className?.includes('add-task-input'));

  //   if(clickedTarget) return;

  //   if(isEditing) {
  //     if(title) {
  //       props.onAddTask && props.onAddTask(title);

  //       setTitle('');
  //       setIsEditing(false);
  //     }
  //   }
  // };

  useEffect(() => {
    // document.addEventListener("mousedown", onDocumentClick);
  }, []);

  return (
    <>
      {isAluralEditing && (
        <section className="dimd-layer altype">
          <div className="inner">
            <div className="task-db-make">
              <h1>태스크 복수 생성</h1>
              <div className="make-task">
                <textarea
                  autoFocus={true}
                  placeholder="줄바꿈된 텍스트마다 개별 태스크로 생성됩니다"
                  value={titles}
                  onChange={onChangeAlural}
                ></textarea>
                <div className="btn">
                  <a
                    href="#"
                    onClick={async e => {
                      setTitles('');
                      setIsAluralEditing(false);
                      props.onAddTasks &&
                        (await props.onAddTasks(
                          titles.split('\n').filter(title => title !== ''),
                        ));
                    }}
                  >
                    태스크 추가
                  </a>
                </div>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setTitles('');
                  setIsAluralEditing(false);
                }}
              >
                <span>태스크 복수 생성 팝업 닫기</span>
              </button>
            </div>
          </div>
        </section>
      )}

      <div className="task-btn">
        <button
          type="button"
          className="t-add"
          onClick={() => {
            setIsAluralEditing(false);
            props.onClickTaskAdd();
          }}
        >
          태스크 추가
        </button>
        <button
          type="button"
          className="t-dub-add"
          onClick={() => {
            setIsAluralEditing(true);
          }}
        >
          태스크 복수 생성
        </button>
      </div>
    </>
  );
};

export enum ETypeOptions {
  ALL = '전체',
  PAYOUT = '지출 내역',
  DEPOSIT = '입금 내역',
}

export enum EDepositType {
  ALL = '전체',
  WAIT = '입금대기',
  CONFIRM = '입금확인',
}

export enum EPayoutType {
  ALL = '전체',
  SPRINT = '스프린트 비용',
  ETC = '기타',
}

export const VAT = 1.1;

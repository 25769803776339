import React from 'react';

export interface IAlertProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const Alert: React.SFC<IAlertProps> = props => {
  return props.isOpen ? (
    <section className="dimd-layer altype">
      <div className="inner">
        <div className="alert">
          <div className="cnts">{props.children}</div>
          <div className="btn">
            <a href="#" onClick={props.onConfirm}>
              예
            </a>
            <a href="#" onClick={props.onCancel}>
              아니요
            </a>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <section />
  );
};

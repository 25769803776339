import { getParent, types } from 'mobx-state-tree';
import { TaskManagerStore } from '../store';

export const SlackModel = types
  .model('Slack', {
    clientId: types.maybe(types.string),
    clientSecret: types.maybe(types.string),

    currentChannel: types.maybe(types.string),
    channels: types.array(
      types.model({
        id: types.string,
        name: types.string,
      }),
    ),
    members: types.array(
      types.model({
        slackId: types.string,
        authId: types.maybeNull(types.number),
        name: types.string,
        displayName: types.string,
      }),
    ),
  })
  .views(self => ({
    get projectGroupId(): string | undefined {
      const taskManageStore = getParent(self);
      if (taskManageStore.hasOwnProperty('projectGroupId')) {
        return (taskManageStore as TaskManagerStore).projectGroupId;
      }
      return undefined;
    },

    get isAvailable() {
      if (self.clientId && self.clientSecret) return true;

      return false;
    },

    get membersNoAuthId() {
      return self.members.filter(member => !member.authId);
    },
  }))
  .actions(self => ({}))
  .actions(self => {
    // 슬랙 설정 관련
    // const checkSlackInstalled = flow(function* () {
    //   const { projectGroupId } = self;

    //   if(projectGroupId) {
    //     try {
    //       const data: SlackBotResponse = yield API.SlackBot.checkSlackInstalled(projectGroupId);

    //       self.clientId = data.client_id || undefined;
    //       self.clientSecret = data.client_secret || undefined;
    //     } catch (e) {
    //       throw e;
    //     }
    //   }

    // });

    // const fetchSettings = flow(function* () {
    //   const { projectGroupId } = self;

    //   if(!projectGroupId) return;

    //   try {
    //     const data: SlackSettingResponse = yield API.Slack.getSettings(projectGroupId);
    //     self.currentChannel = data.channel;
    //   } catch (e) {
    //     throw e;
    //   }
    // });

    // const updateSettings = flow(function* (channelId: string, memberMappings: {[k:string]: number}) {
    //   const { projectGroupId } = self;

    //   if(!projectGroupId) return;

    //   try {
    //     // const { data }: { data: object } = yield axios.put(
    //     //   `/slackboard/settings`,
    //     //   {
    //     //     project_group_id: projectGroupId,
    //     //     channel: channel,
    //     //     // member_mapping_updates: member_mapping_updates
    //     //   }
    //     // );

    //     yield API.Slack.updateSettings(projectGroupId, channelId, memberMappings);

    //   } catch (e) {
    //     console.log("updateSlackSettings error", e);
    //     throw e;
    //   }
    // });

    // const fetchChannels = flow(function* () {
    //   const { projectGroupId } = self;

    //   if(!projectGroupId) return;

    //   try {
    //     self.channels.replace([]);

    //     const data: SlackChannelResponse[] = yield API.Slack.getChannels(projectGroupId);
    //     self.channels.replace([]);
    //     data.map( d => self.channels.push(d));
    //   } catch (e) {
    //     throw e;
    //   }
    // });

    // const fetchMembers = flow(function* () {
    //   const { projectGroupId } = self;

    //   if(!projectGroupId) return;

    //   try {
    //     self.members.replace([]);

    //     const data: SlackMemberResponse[] = yield API.Slack.getMembers(projectGroupId);
    //     self.members.replace([]);
    //     data.map( d => self.members.push({
    //       slackId: d.id,
    //       authId: d.auth_id,
    //       name: d.name,
    //       displayName: d.profile.display_name || d.profile.real_name
    //     }));
    //   } catch (e) {
    //     throw e;
    //   }
    // });

    // const syncWithSlack = flow(function* () {
    //   const { projectGroupId } = self;

    //   if(!projectGroupId) return;

    //   try {
    //     yield API.Slack.sync(projectGroupId);

    //   } catch (e) {
    //     console.log("TaskManageStore => syncWithSlack error", e);
    //     throw e;
    //   }
    // });

    return {
      // checkSlackInstalled,
      // fetchChannels,
      // fetchMembers,
      // fetchSettings,
      // updateSettings,
      // syncWithSlack
    };
  });
type SlackType = typeof SlackModel.Type;

export interface Slack extends SlackType {}

import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { flow, types } from 'mobx-state-tree';
import moment from 'moment';

import * as API from './apis';
import { SubTaskListForm, SubTaskListFormModel } from './forms/SubTaskListForm';
import {
  SubTaskFormResponse,
  SubTaskListFormResponse,
  TaskCommentFormResponse,
  TaskFormResponse,
  TaskLabelSimpleResponse,
  TaskSprintSimpleResponse,
  WorkerResponse,
} from './interfaces';
import { SubTask, SubTaskModel } from './models/SubTask';
import { TaskFile, TaskFileModel } from './models/TaskFile';
import { TaskLink, TaskLinkModel } from './models/TaskLink';

const mapSubTaskList = (item: SubTaskListFormResponse) => {
  return {
    id: item.id,
    subTaskListId: item.sub_task_list_id,
    taskId: item.task,
    title: item.title,
    subTasks: item.sub_tasks,
    order: item.order,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
  };
};
const mapComment = (item: TaskCommentFormResponse) => {
  return {
    id: item.id,
    comment: item.comment,
    writer: item.writer,
    writerName: item.writer_name,
    createdAt: item.created_at,
    updatedAt: item.updated_at,
  };
};

const mapWorker = (item: WorkerResponse) => {
  return {
    name: item.name,
    phone: item.phone,
    email: item.email,
    id: item.id,
  };
};

export const TaskManagerWorkerModel = types.model('ProjectGroupWorker', {
  name: types.string,
  phone: types.string,
  email: types.string,
  id: types.integer,
});

type TaskManagerWorkerType = typeof TaskManagerWorkerModel.Type;

export interface TaskManagerWorker extends TaskManagerWorkerType {}

export const TaskCommentFormModel = types
  .model('TaskCommentForm', {
    id: types.number,
    comment: types.string,
    writer: types.integer,
    writerName: types.string,

    createdAt: types.string,
    updatedAt: types.string,
  })
  .actions(self => ({
    setComment(value: string) {
      self.comment = value;
    },
  }));
type TaskCommentFormModelType = typeof TaskCommentFormModel.Type;

export interface TaskCommentForm extends TaskCommentFormModelType {}

export const TaskListSimpleFormModel = types.model('TaskListSimpleForm', {
  id: types.number,
  taskListId: types.string,
  title: types.string,
});

export const TaskLabelSimpleFormModel = types
  .model('TaskLabelSimpleForm', {
    taskLabelId: types.string,
    title: types.string,
  })
  .views(self => ({
    get value() {
      return self.title;
    },
    get itemId() {
      return self.taskLabelId;
    },
  }));
type TaskLabelSimpleFormType = typeof TaskLabelSimpleFormModel.Type;

export interface TaskLabelSimpleForm extends TaskLabelSimpleFormType {}

export const TaskSprintSimpleFormModel = types
  .model('TaskSprintSimpleForm', {
    taskSprintId: types.string,
    title: types.string,
  })
  .views(self => ({
    get value() {
      return self.title;
    },
    get itemId() {
      return self.taskSprintId;
    },
  }));
type TaskSprintSimpleFormType = typeof TaskSprintSimpleFormModel.Type;

export interface TaskSprintSimpleForm extends TaskSprintSimpleFormType {}

//////////////////////////////

export const TaskFormModel = types
  .model('TaskForm', {
    id: types.optional(types.number, -1),
    taskId: types.optional(types.string, ''),
    taskNo: types.optional(types.number, -1),

    taskBoardId: types.optional(types.string, ''),
    taskListId: types.optional(types.string, ''),

    sprintList: types.array(TaskSprintSimpleFormModel),

    personInChargeList: types.optional(types.array(types.number), []),

    title: types.optional(types.string, ''),
    description: types.optional(types.string, ''),

    labelList: types.array(TaskLabelSimpleFormModel),
    dueDate: types.maybeNull(types.string),
    isArchived: types.optional(types.boolean, false),
    dateArchived: types.maybeNull(types.string),

    //SubTaskListForm
    subTaskListForms: types.array(SubTaskListFormModel),

    //댓글
    comments: types.array(TaskCommentFormModel),

    taskLinks: types.array(TaskLinkModel),
    taskFiles: types.array(TaskFileModel),

    workerSelections: types.array(TaskManagerWorkerModel),
    taskListSelections: types.array(TaskListSimpleFormModel),
    taskLabelSelections: types.array(TaskLabelSimpleFormModel),
    taskSprintSelections: types.array(TaskSprintSimpleFormModel),
    createdAt: types.optional(types.string, ''),
    updatedAt: types.optional(types.string, ''),
  })
  .actions(self => ({
    setTitle(value: string) {
      self.title = value;
    },
    setDescription(value: string) {
      self.description = value;
    },
    addSubTaskList(item: SubTaskListForm) {
      const subTaskList = item;
      self.subTaskListForms.push(subTaskList);
    },
    addSubTaskTitleTo(item: SubTask) {
      const subTaskList = self.subTaskListForms.find(
        subTaskList => subTaskList.subTaskListId === item.sub_task_list,
      );
      if (subTaskList) subTaskList.subTasks.push(item);
    },
    removeSubTaskTitleTo(subTaskListId: string, subTaskId: string) {
      const subTaskList = self.subTaskListForms.find(
        subTaskList => subTaskList.subTaskListId === subTaskListId,
      );
      const subTaskToRemove = subTaskList
        ? subTaskList.subTasks.find(
            subTask => subTask.sub_task_id === subTaskId,
          )
        : undefined;
      if (subTaskToRemove && subTaskList) {
        const idx = subTaskList.subTasks.indexOf(subTaskToRemove);
        if (idx > -1) subTaskList.subTasks.splice(idx, 1);
      }
    },
    selectLabels(labels: TaskLabelSimpleForm[]) {
      labels.forEach(label =>
        self.labelList.push(
          TaskLabelSimpleFormModel.create({
            taskLabelId: label.taskLabelId,
            title: label.title,
          }),
        ),
      );
    },
    setSelectLabel(label: TaskLabelSimpleForm) {
      self.labelList.push(
        TaskLabelSimpleFormModel.create({
          taskLabelId: label.taskLabelId,
          title: label.title,
        }),
      );
      const labelSelFound = self.taskLabelSelections.find(
        labelIt => labelIt.taskLabelId === label.taskLabelId,
      );
      if (!labelSelFound) self.taskLabelSelections.push(label);
    },
    deselectLabel(label: TaskLabelSimpleForm) {
      const labelToDeselect = self.labelList.find(
        item => item.taskLabelId === label.taskLabelId,
      );
      if (labelToDeselect) {
        const idx = self.labelList.indexOf(labelToDeselect);
        if (idx > -1) self.labelList.splice(idx, 1);
      }
    },
    setLabel(labels: TaskLabelSimpleResponse[]) {
      self.labelList.replace(
        labels.map(labelResponse => {
          return TaskLabelSimpleFormModel.create({
            taskLabelId: labelResponse.task_label,
            title: labelResponse.title,
          });
        }),
      );
    },
    setSelectSprint(sprint: TaskSprintSimpleForm) {
      self.sprintList.push(
        TaskSprintSimpleFormModel.create({
          taskSprintId: sprint.taskSprintId,
          title: sprint.title,
        }),
      );
      const sprintSelFound = self.taskSprintSelections.find(
        sprintIt => sprintIt.taskSprintId === sprint.taskSprintId,
      );
      if (!sprintSelFound) self.taskSprintSelections.push(sprint);
    },
    deselectSprint(sprint: TaskSprintSimpleForm) {
      const sprintToDeselect = self.sprintList.find(
        item => item.taskSprintId === sprint.taskSprintId,
      );
      if (sprintToDeselect) {
        const idx = self.sprintList.indexOf(sprintToDeselect);
        if (idx > -1) self.sprintList.splice(idx, 1);
      }
    },

    setSprint(sprints: TaskSprintSimpleResponse[]) {
      self.sprintList.replace(
        sprints.map(sprintResponse => {
          return TaskSprintSimpleFormModel.create({
            taskSprintId: sprintResponse.task_sprint,
            title: sprintResponse.title,
          });
        }),
      );
    },
    addPersonInCharge(userid: number) {
      self.personInChargeList.push(userid);
    },
    deletePersonInCharge(userid: number) {
      const idx = self.personInChargeList.indexOf(userid);
      if (idx > -1) self.personInChargeList.splice(idx, 1);
    },
    setTaskListId(taskListId: string) {
      self.taskListId = taskListId;
    },
    setDueDate(date: string | null) {
      self.dueDate = date;
    },
    addLabel(item: TaskLabelSimpleFormType) {
      self.labelList.push(item);
    },
    addSprint(item: TaskSprintSimpleFormType) {
      self.sprintList.push(item);
    },
    addComment(item: TaskCommentForm) {
      self.comments.unshift(item);
    },
    async setTaskLabelSelections(addedTask: TaskLabelSimpleResponse) {
      self.taskLabelSelections.push(
        TaskLabelSimpleFormModel.create({
          taskLabelId: addedTask.task_label,
          title: addedTask.title,
        }),
      );
      self.taskLabelSelections.replace(self.taskLabelSelections);
    },
    addTaskLabelSelections(label: any) {
      self.taskLabelSelections.push(
        TaskLabelSimpleFormModel.create({
          taskLabelId: label.taskLabelId,
          title: label.title,
        }),
      );
    },
    addTaskSprintSelections(sprint: any) {
      self.taskSprintSelections.push(
        TaskSprintSimpleFormModel.create({
          taskSprintId: sprint.taskSprintId,
          title: sprint.title,
        }),
      );
    },
    addEmptyLink() {
      self.taskLinks.push(
        TaskLinkModel.create({
          id: -1,
          created_at: '',
          updated_at: '',
          link: '',
          task: self.taskId,
          writer: -1,
        }),
      );
    },
    removeEmptyLink() {
      const emptyIndex = self.taskLinks.findIndex(link => link.id === -1);
      if (emptyIndex > -1) self.taskLinks.splice(emptyIndex, 1);
    },
  }));

type TaskFormType = typeof TaskFormModel.Type;

export interface TaskForm extends TaskFormType {}

//////////////////////////////
export const TaskFormStoreModel = types
  .model('TaskForm', {
    isLoading: types.optional(types.boolean, false), //상태 표시.

    taskForm: types.optional(TaskFormModel, {}),
  })
  .actions(self => {
    const init = () => {
      self.taskForm = TaskFormModel.create({
        id: 0,
        taskId: 'TK0000001',
        taskNo: 1,

        taskBoardId: 'TB000001',
        taskListId: 'TL0000001',

        sprintList: [],

        title: '',
        description: '',

        labelList: [],
        dueDate: undefined,
        isArchived: false,
        dateArchived: '2001/01/01',

        //SubTaskListForm
        subTaskListForms: [],
        comments: [],

        createdAt: '2001/01/01',
        updatedAt: '2001/01/01',
      });
    };
    const archive = flow(function* (is_archived: boolean) {
      try {
        yield API.Task.archived(self.taskForm.id, is_archived);
      } catch (e) {
        throw e;
      }
    });
    const fetch = flow(function* (id: number) {
      try {
        self.isLoading = true;
        const { data }: { data: TaskFormResponse } = yield axios.get(
          `/taskmgr/task/${id}`,
        );
        self.taskForm = TaskFormModel.create({
          id: data.id,
          taskId: data.task_id,
          taskNo: data.task_no,

          taskBoardId: data.task_board,
          taskListId: data.task_list,

          sprintList: data.sprint_list.map(item => {
            return TaskSprintSimpleFormModel.create({
              taskSprintId: item.task_sprint,
              title: item.title,
            });
          }),

          personInChargeList: data.person_in_charge_list,

          title: data.title,
          description: data.description,

          labelList: data.label_list.map(item => {
            return TaskLabelSimpleFormModel.create({
              taskLabelId: item.task_label,
              title: item.title,
            });
          }),
          dueDate: data.due_date,
          isArchived: data.is_archived,
          dateArchived: data.date_archived,

          //SubTaskListForm
          subTaskListForms: data.sub_task_lists.map(item => {
            return SubTaskListFormModel.create({
              id: item.id,
              subTaskListId: item.sub_task_list_id,
              taskId: item.task,
              title: item.title,
              subTasks: item.sub_tasks.map(subTask => {
                return SubTaskModel.create({
                  id: subTask.id,
                  sub_task_id: subTask.sub_task_id,
                  sub_task_list: subTask.sub_task_list,
                  title: subTask.title,
                  checked: subTask.checked,
                  order: subTask.order,
                  created_at: subTask.created_at,
                  updated_at: subTask.updated_at,
                });
              }),
              order: item.order,
              createdAt: item.created_at,
              updatedAt: item.updated_at,
            });
          }), //

          comments: data.task_comments.map(
            (comment: TaskCommentFormResponse) => {
              return TaskCommentFormModel.create(mapComment(comment));
            },
          ),
          taskLinks: data.task_links,
          taskFiles: data.task_files,

          workerSelections: data.worker_selections.map(worker => {
            return TaskManagerWorkerModel.create(mapWorker(worker));
          }),
          taskListSelections: data.task_list_selections.map(taskList => {
            return TaskListSimpleFormModel.create({
              id: taskList.id,
              taskListId: taskList.task_list_id,
              title: taskList.title,
            });
          }),
          taskLabelSelections: data.task_label_selections.map(item => {
            return TaskLabelSimpleFormModel.create({
              taskLabelId: item.task_label,
              title: item.title,
            });
          }),
          taskSprintSelections: data.task_sprint_selections.map(item => {
            return TaskSprintSimpleFormModel.create({
              taskSprintId: item.task_sprint,
              title: item.title,
            });
          }),
          createdAt: data.created_at,
          updatedAt: data.updated_at,
        });
        return data;
      } catch (e) {
        console.log('TaskFormStore=>loadForm error', e);
        throw e;
      } finally {
        self.isLoading = false;
      }
    });
    const updateForm = flow(function* () {
      try {
        const { data } = yield axios.patch(
          `/taskmgr/task/${self.taskForm.id}`,
          {
            id: self.taskForm.id,
            task_id: self.taskForm.taskId,
            task_no: self.taskForm.taskNo,
            task_board: self.taskForm.taskBoardId,
            task_list: self.taskForm.taskListId,

            title: self.taskForm.title,
            description: self.taskForm.description,
            due_date: self.taskForm.dueDate
              ? moment(self.taskForm.dueDate).format('YYYY-MM-DDT00:00')
              : self.taskForm.dueDate,

            is_archived: self.taskForm.isArchived,
            date_archived: self.taskForm.dateArchived,
          },
        );
      } catch (e) {
        console.log('TaskFormStore=>putForm error', e);
        throw e;
      }
    });

    const updateTitle = flow(function* (title: string) {
      try {
        const data = yield API.Task.updateTitle(self.taskForm.id, title);
        self.taskForm.setTitle(data.title);
      } catch (e) {
        throw e;
      }
    });
    const updateTaskList = flow(function* (listId: string) {
      try {
        yield API.Task.updateTaskList(self.taskForm.id, listId);
        self.taskForm.setTaskListId(listId);
      } catch (e) {
        throw e;
      }
    });
    const updatePersonInCharge = flow(function* () {
      try {
        const { data } = yield axios.put(
          `/taskmgr/task/${self.taskForm.id}/person-in-charge`,
          {
            id: self.taskForm.id,
            task_id: self.taskForm.taskId,
            person_in_charge_list: self.taskForm.personInChargeList,
          },
        );
      } catch (e) {
        throw e;
      }
    });
    const updateLabel = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: TaskLabelSimpleResponse[] } =
            yield axios.post(`/taskmgr/task/${self.taskForm.id}/label`, {
              task_label_id: item.taskLabelId,
              title: item.title,
            });
          const addedTask = data.find(label => label.title === item.title);

          self.taskForm.addTaskLabelSelections(item);
          self.taskForm.setLabel(data);
          return addedTask;
        } else if (method === 'put') {
          const { data }: { data: TaskLabelSimpleResponse[] } = yield axios.put(
            `/taskmgr/task/${self.taskForm.id}/label`,
            {
              task_label_id: item.taskLabelId,
              title: item.title,
            },
          );
          self.taskForm.setLabel(data);
        } else if (method === 'delete') {
          const { data }: { data: TaskLabelSimpleResponse[] } =
            yield axios.delete(`/taskmgr/task/${self.taskForm.id}/label`, {
              data: {
                list: item,
              },
            });
          self.taskForm.setLabel(data);
        }
      } catch (e) {
        console.log('TaskFormStore=>updateLabel error', e);
        throw e;
      }
    });
    const updateSprint = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: TaskSprintSimpleResponse[] } =
            yield axios.post(`/taskmgr/task/${self.taskForm.id}/sprint`, {
              task_sprint_id: item.taskSprintId,
              title: item.title,
            });
          const addedSprint = data.find(sprint => sprint.title === item.title);
          self.taskForm.addTaskSprintSelections(item);
          self.taskForm.setSprint(data);
        } else if (method === 'put') {
          const { data }: { data: TaskSprintSimpleResponse[] } =
            yield axios.put(`/taskmgr/task/${self.taskForm.id}/sprint`, {
              task_sprint_id: item.taskSprintId,
              title: item.title,
            });
          self.taskForm.setSprint(data);
        } else if (method === 'delete') {
          const { data }: { data: TaskSprintSimpleResponse[] } =
            yield axios.delete(`/taskmgr/task/${self.taskForm.id}/sprint`, {
              data: {
                list: item,
              },
            });
          self.taskForm.setSprint(data);
        }
      } catch (e) {
        console.log('TaskFormStore=>updateLabel error', e);
        throw e;
      }
    });
    const updateSubTaskList = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: SubTaskListFormResponse } = yield axios.post(
            `/taskmgr/subtasklist`,
            {
              title: item.title,
              task: self.taskForm.taskId,
            },
          );
          self.taskForm.addSubTaskList(
            SubTaskListFormModel.create(mapSubTaskList(data)),
          );
        } else if (method === 'patch') {
          const { data }: { data: SubTaskListFormResponse } = yield axios.patch(
            `/taskmgr/subtasklist/${item.id}`,
            {
              title: item.title,
            },
          );
          return data;
        } else if (method === 'delete') {
          const { data }: { data: SubTaskListFormResponse[] } =
            yield axios.delete(`/taskmgr/subtasklist/${item.id}`);

          const subTaskList: SubTaskListForm[] = data.map(x =>
            SubTaskListFormModel.create(mapSubTaskList(x)),
          );
          subTaskList && self.taskForm.subTaskListForms.replace(subTaskList);
        }
      } catch (e) {
        console.log('TaskFormStore=>updateSubTaskList error', e);
        throw e;
      }
    });
    const updateSubTask = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: SubTaskFormResponse } = yield axios.post(
            `/taskmgr/subtask`,
            {
              title: item.title,
              sub_task_list: item.subTaskListId,
            },
          );
          // const subTask = SubTaskModel.create(mapSubTask(data));
          const subTask = SubTaskModel.create(data);
          self.taskForm.addSubTaskTitleTo(subTask);
        } else if (method === 'patch') {
          const { data }: { data: SubTaskFormResponse } = yield axios.patch(
            `/taskmgr/subtask/${item.id}`,
            {
              title: item.title,
              checked: item.checked,
            },
          );
        } else if (method === 'delete') {
          yield axios.delete(`/taskmgr/subtask/${item.id}`);
          self.taskForm.removeSubTaskTitleTo(
            item.subTaskListId,
            item.subTaskId,
          );
        }
      } catch (e) {
        console.log('TaskFormStore=>updateSubTaskList error', e);
        throw e;
      }
    });

    const updateComment = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: TaskCommentFormResponse[] } =
            yield axios.post(`/taskmgr/comment`, {
              task: self.taskForm.taskId,
              comment: item.comment,
            });
          const taskComments = data.map(item =>
            TaskCommentFormModel.create(mapComment(item)),
          );
          self.taskForm.comments.replace(taskComments);
        } else if (method === 'patch') {
          const { data }: { data: TaskCommentFormResponse[] } =
            yield axios.patch(`/taskmgr/comment/${item.id}`, {
              comment: item.comment,
            });
          const taskComments = data.map(item =>
            TaskCommentFormModel.create(mapComment(item)),
          );
          self.taskForm.comments.replace(taskComments);
        } else if (method === 'delete') {
          const { data }: { data: TaskCommentFormResponse[] } =
            yield axios.delete(`/taskmgr/comment/${item.id}`);
          const taskComments = data.map(item =>
            TaskCommentFormModel.create(mapComment(item)),
          );
          self.taskForm.comments.replace(taskComments);
          // self.taskForm.removeSubTaskTitleTo(item.subTaskListId, item.subTaskId);
        }
      } catch (e) {
        console.log('TaskFormStore=>updateSubTaskList error', e);
        throw e;
      }
    });

    const updateFile = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const formData = new FormData();
          formData.append('file', item.file!);
          formData.append('task', item.taskId);

          const { data }: { data: TaskFile[] } = yield axios.post(
            `/taskmgr/taskfile`,
            formData,
          );
          self.taskForm.taskFiles.replace(data);
        } else if (method === 'patch') {
        } else if (method === 'delete') {
          const { data }: { data: TaskFile[] } = yield axios.delete(
            `/taskmgr/taskfile/${item.id}`,
          );
          self.taskForm.taskFiles.replace(data);
        }
      } catch (e) {
        console.log('TaskFormStore=>updateFile error', e);
        throw e;
      }
    });

    const changeFilename = flow(function* (id: number, filename: string) {
      try {
        const fileItem = self.taskForm.taskFiles.find(file => file.id === id);

        if (!fileItem) return;

        const { data }: any = yield axios.patch(
          `/taskmgr/taskfile/${fileItem.id}`,
          {
            filename: filename,
          },
        );
        fileItem.filename = data.filename;
      } catch (e) {
        throw e;
      }
    });

    const updateLink = flow(function* (item: any, method: string) {
      try {
        if (method === 'post') {
          const { data }: { data: TaskLink[] } = yield axios.post(
            `/taskmgr/tasklink`,
            {
              task: item.taskId,
              link: item.link,
            },
          );
          self.taskForm.taskLinks.replace(data);
        } else if (method === 'patch') {
          const { data }: { data: TaskLink[] } = yield axios.patch(
            `/taskmgr/tasklink/${item.id}`,
            {
              link: item.link,
            },
          );
          self.taskForm.taskLinks.replace(data);
        } else if (method === 'delete') {
          const { data }: { data: TaskLink[] } = yield axios.delete(
            `/taskmgr/tasklink/${item.id}`,
          );
          self.taskForm.taskLinks.replace(data);
        }
      } catch (e) {
        throw e;
      }
    });

    const moveSubTaskList = flow(function* (
      subtasklist_id: number,
      order_from: number,
      order_to: number,
    ) {
      // 프론트에서 이동을 반영하고, 문제 발생시에는 DB의 원래 값을 읽어온다
      const subtasklist = sortBy(self.taskForm.subTaskListForms.slice(), [
        'order',
      ]);

      subtasklist[order_from].order = order_to;
      if (order_from > order_to) {
        // 아래에서 위로 올라간 경우
        for (let i = order_to; i < order_from; i++) {
          subtasklist[i].order = subtasklist[i].order + 1;
        }
      } else {
        // 위에서 아래로 내려간 경우
        for (let i = order_from + 1; i <= order_to; i++) {
          subtasklist[i].order = subtasklist[i].order - 1;
        }
      }
      self.taskForm.subTaskListForms.replace(subtasklist);

      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/subtasklist/${subtasklist_id}/moved`,
          {
            order_to: order_to,
          },
        );
      } catch (e) {
        console.log('moveSubTaskList error', e);
        throw e;
      }
    });

    const moveSubTask = flow(function* (
      src_subtask_id: number,
      src_subtasklist_id: number,
      dst_subtasklist_id: number,
      order_from: number,
      order_to: number,
    ) {
      // 깜빡거림을 막기 위해 모델 리프레시 전에 위치값을 조정해준다
      const subtasklists = self.taskForm.subTaskListForms;
      const src_subtasklist =
        self.taskForm.subTaskListForms[
          subtasklists.findIndex(l => l.id === src_subtasklist_id)
        ];
      const dst_subtasklist =
        self.taskForm.subTaskListForms[
          subtasklists.findIndex(l => l.id === dst_subtasklist_id)
        ];

      if (src_subtasklist === dst_subtasklist) {
        // subtasklist 내부에서 이동
        const subtasks = sortBy(dst_subtasklist.subTasks.slice(), ['order']);

        subtasks[order_from].order = order_to;
        if (order_from > order_to) {
          // 아래에서 위로 올라간 경우
          for (let i = order_to; i < order_from; i++) {
            subtasks[i].order = subtasks[i].order + 1;
          }
        } else {
          // 위에서 아래로 내려간 경우
          for (let i = order_from + 1; i <= order_to; i++) {
            subtasks[i].order = subtasks[i].order - 1;
          }
        }
        dst_subtasklist.subTasks.replace(subtasks);
      } else {
        // subtasklist 외부로 이동
        const src_subtasks = sortBy(src_subtasklist.subTasks.slice(), [
          'order',
        ]);
        const dst_subtasks = sortBy(dst_subtasklist.subTasks.slice(), [
          'order',
        ]);

        let src_subtask = src_subtasks.splice(order_from, 1)[0];
        for (let i = order_from; i < src_subtasks.length; i++) {
          src_subtasks[i].order -= 1;
        }

        const dst_subtask = SubTaskModel.create({
          id: src_subtask.id,
          sub_task_id: src_subtask.sub_task_id,
          sub_task_list: dst_subtasklist.subTaskListId,
          title: src_subtask.title,
          checked: src_subtask.checked,
          order: order_to,
          created_at: src_subtask.created_at,
          updated_at: src_subtask.updated_at,
        });

        dst_subtasks.splice(order_to, 0, dst_subtask);
        for (let i = order_to + 1; i < dst_subtasks.length; i++) {
          dst_subtasks[i].order += 1;
        }

        src_subtasklist.subTasks.replace(src_subtasks);
        dst_subtasklist.subTasks.replace(dst_subtasks);
      }

      try {
        const { data }: { data: object } = yield axios.put(
          `/taskmgr/subtask/${src_subtask_id}/moved`,
          {
            dst_subtasklist_id: dst_subtasklist_id,
            order_to: order_to,
          },
        );
      } catch (e) {
        console.log('moveSubTask error', e);
        throw e;
      }
    });

    return {
      archive,
      init,
      fetch,
      changeFilename,

      updateForm,
      updatePersonInCharge,
      updateLabel,
      updateSprint,
      updateSubTaskList,
      updateSubTask,
      updateComment,
      updateFile,
      updateLink,
      updateTitle,
      updateTaskList,

      moveSubTaskList,
      moveSubTask,
    };
  });

type TaskFormStoreType = typeof TaskFormStoreModel.Type;

export interface TaskFormStore extends TaskFormStoreType {}

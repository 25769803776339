import moment from 'moment';
import React from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import AmassSVG from '../../../static/icons/icon-amass-off.svg';

import MailSVG from '../../../static/icons/icon-mailsend-black.svg';

import { VAT } from '../../../store/Calculate/constants';

import { IDeposit } from '../../../store/Calculate/models/Deposit';

const PayoutWrapper = styled.div`
  margin-bottom: 60px;
`;

const PayoutBody = styled.div`
  height: 144px;
  border-radius: 10px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  background-color: ${PALETTE['button-style']};
  padding: 25px 30px 0px 30px;
  margin-bottom: 10px;
  position: relative;

  @media only screen and (max-width: ${PC}) {
    padding: 20px;
    height: 238px;
    padding: 20px;
  }
`;

const SubTitle = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;

  @media only screen and (max-width: ${PC}) {
    line-height: 27px;
    font-size: 18px;
    margin-bottom: 10px;
  }
`;
const Body = styled.div`
  line-height: 33px;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${PC}) {
    display: block;
    line-height: 29px;
    font-size: 20px;
    margin-bottom: 12px;
  }
`;
const LeftBody = styled.div`
  // display: flex;
  // div:last-child {
  //   margin-left: 10px;
  // }

  .title {
    div + div {
      padding-left: 10px;
    }
    display: flex;
    @media only screen and (max-width: ${PC}) {
      div:last-child {
        position: absolute;
        right: 20px;
        top: 20px;
      }
    }
  }
  @media only screen and (max-width: ${PC}) {
    // div:last-child {
    //   position: absolute;
    //   top: 20px;
    //   right: 20px;
    // }
  }
`;
const RightBody = styled.div`
  display: flex;
  * + * {
    margin-left: 30px;
  }
`;
const Amount = styled.div<{ isActive?: boolean }>`
  font-size: 22px;
  font-weight: bold;
  color: ${p =>
    p.isActive ? PALETTE['purpleish-blue'] : PALETTE['brownish-grey']};
  position: absolute;
  top: 55px;
  right: 167px;

  span::before {
    content: '₩ ';
  }

  @media only screen and (max-width: ${PC}) {
    position: initial;
    margin-top: 15px;
  }
`;
const Status = styled.div<{ isWait?: boolean }>`
  width: 107px;
  height: 45px;
  border-radius: 23.5px;
  line-height: 27px;
  padding-top: 8px;
  background-color: ${PALETTE['color-disabled']};
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #ffffff;

  position: absolute;
  top: 50px;
  right: 30px;

  ${p =>
    p.isWait &&
    `
    border: solid 1px ${PALETTE['purpleish-blue']};
    background-color: #ffffff;
    color: ${PALETTE['purpleish-blue']};
    `}

  @media only screen and (max-width: ${PC}) {
    line-height: 20px;
    bottom: 20px;
    right: 20px;
    height: 38px;
    width: 84px;
    font-size: 14px;
    top: initial;
  }
`;

const Info = styled.div<{ isActive?: boolean }>`
  font-weight: normal;
  line-height: 20px;
  font-size: 14px;
  margin-top: 12px;
  color: ${p =>
    p.isActive ? PALETTE['purpleish-blue'] : PALETTE['black-two']};
`;
const ActionRow = styled.div`
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: ${PC}) {
    justify-content: left;
    padding-left: 20px;
  }
`;
const Action = styled.div`
  line-height: 19px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 40px;
  position: relative;
  cursor: pointer;

  ::before {
    content: '';
    background: url('${AmassSVG}') no-repeat center center;
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    top: 2px;
  }

  @media only screen and (max-width: ${PC}) {
    margin-left: 50px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
const MainAction = styled.a`
  color: #000000;
  line-height: 19px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 40px;
  position: relative;
  cursor: pointer;

  :hover {
    color: #000000;
  }

  ::before {
    content: '';
    background: url('${MailSVG}') no-repeat center center;
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    top: 2px;
  }

  @media only screen and (max-width: ${PC}) {
    margin-left: 50px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;
const Wrapper = styled.div`
  margin-top: 30px;
`;

// const Payout: FC<{
//   includeVAT: boolean;
//   reff?: React.RefObject<HTMLDivElement>;
//   payout: IPayoutBySprint;
//   handleOpenReportPopup: () => void;
// }> = ({ includeVAT, reff, handleOpenReportPopup, payout }) => {
class Payout extends React.Component<{
  includeVAT: boolean;
  reff?: React.RefObject<HTMLDivElement>;
  // payout: IPayoutBySprint;
  deposit: IDeposit;
  handleOpenReportPopup: () => void;
}> {
  render() {
    const { deposit, includeVAT, reff, handleOpenReportPopup } = this.props;
    // const deposit: IDeposit | undefined = payout.deposit;

    const isDeposited = deposit.allDeposit;
    const payment = includeVAT
      ? Math.floor(Math.round(deposit.payout * VAT) / 10) * 10
      : deposit.payout;

    const mailBody = `
  %0D%0A%0D%0A
  -::~:~::~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~:~::~:~::-%0D%0A
  설명의 이 섹션을 수정하지 마시기 바랍니다.%0D%0A%0D%0A
  ${deposit.title}%0D%0A
  ${
    deposit
      ? `세금발행일: ${moment(deposit.dateIssue).format('YYYY.MM.DD')}`
      : `세금계산서 발행 전`
  }%0D%0A
  금액 : ${payment.toLocaleString('en-US')} ( ${
      isDeposited ? '입금확인' : '입금대기'
    } )
  `;

    return (
      <PayoutWrapper ref={reff}>
        <PayoutBody>
          <SubTitle>{deposit.projectGroup}</SubTitle>

          <Body>
            <LeftBody>
              <div className="title">
                <div>
                  {deposit.sprints.length > 0
                    ? deposit.projectGroupName
                    : '기타'}
                </div>
                <div>{deposit.sprintTitle}</div>
              </div>

              {deposit ? (
                deposit.allDeposit ? (
                  <Info>
                    입금일 - {moment(deposit.dateDeposit).format('YYYY.MM.DD')}
                  </Info>
                ) : (
                  <Info isActive={true}>
                    세금계산서 발행 완료 -{' '}
                    {moment(deposit.dateIssue).format('YYYY.MM.DD')}
                  </Info>
                )
              ) : (
                <Info isActive={true}>세금계산서 발행 전</Info>
              )}
            </LeftBody>

            <RightBody>
              <Amount isActive={!isDeposited}>
                <span>{payment.toLocaleString('en-US')}</span>
              </Amount>
              <Status isWait={!isDeposited}>
                {isDeposited ? '입금확인' : '입금대기'}
              </Status>
            </RightBody>
          </Body>
        </PayoutBody>

        <ActionRow>
          <MainAction href={`mailto:support@seeso.kr?body=${mailBody}`}>
            경영지원팀 문의하기
          </MainAction>
          {deposit.sprints.length === 1 && (
            <Action onClick={handleOpenReportPopup}>스프린트 보고서</Action>
          )}
        </ActionRow>
      </PayoutWrapper>
    );
  }
}

export { Payout, Wrapper };

import * as React from 'react';

export interface IProgressProps {
  countScheduled: number;
  countOngoing: number;
  countDone: number;
}

export const Progress: React.FC<IProgressProps> = ({
  countScheduled,
  countOngoing,
  countDone,
}) => {
  const total = countScheduled + countOngoing + countDone;

  return (
    <div className="pregress">
      <h3>진행 현황</h3>
      <div>
        <span
          className="ready"
          style={{ minWidth: `${(countScheduled / total) * 100}%` }}
        >
          작업 예정
        </span>
        <span
          className="ing"
          style={{ minWidth: `${(countOngoing / total) * 100}%` }}
        >
          진행 중
        </span>
        <span
          className="end"
          style={{ minWidth: `${(countDone / total) * 100}%` }}
        >
          완료
        </span>
      </div>
    </div>
  );
};

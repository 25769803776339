import moment from 'moment';
import React, { useState } from 'react';

export interface ITaskCardProps {
  isSelected?: boolean;
  title: string;
  dueDate: string | null;
  assignees: (string | undefined)[];
  sprints: string[];
  labels: string[];
  attachmentCount: number;
  subtaskCount: number;
  doneSubtaskCount: number;
  commentCount: number;

  dndProvided?: any;

  onClickWithShift?: () => void;
  onClick?: () => void;
  onChangeTitle?: (title: string) => void;
  onDelete?: () => void;
}

export const TaskCard: React.SFC<ITaskCardProps> = props => {
  const [isEditingTitle, setIsEditingTitle] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(props.title);
  // const [ selected, setSelected ] = useState<boolean>(props.isSelected ? props.isSelected : false);

  // useEffect(() => {
  //   setSelected(props.isSelected ? props.isSelected : false)
  // }, [props.isSelected]);

  const assigneeTitle =
    props.assignees.length > 0
      ? `${props.assignees[0]} ${
          props.assignees.length > 1 ? `+${props.assignees.length - 1}` : ``
        }`
      : '';
  const isDuDateOver = moment(props.dueDate).isBefore(moment());
  // console.log(props.draggableProps)

  const onBlurTitle = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    props.onChangeTitle &&
      props.onChangeTitle((e.target as HTMLTextAreaElement).value);
    setIsEditingTitle(false);
  };
  const onChangeTitle = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
  };
  const onKeyPressTitle = (e: any) => {
    if (e.key === 'Enter') {
      props.onChangeTitle &&
        props.onChangeTitle((e.target as HTMLTextAreaElement).value);
      setIsEditingTitle(false);
    }
  };

  return (
    <div
      className={`card ${props.isSelected ? 'selected' : ''} ${
        isEditingTitle ? 'modify' : ''
      }`}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const { onClick, onClickWithShift } = props;

        e.preventDefault();
        e.stopPropagation();

        if (e.shiftKey) {
          onClickWithShift && onClickWithShift();
        } else {
          onClick && onClick();
        }
      }}
      ref={props.dndProvided?.innerRef}
      {...props.dndProvided?.draggableProps}
      {...props.dndProvided?.dragHandleProps}
    >
      {isEditingTitle && (
        <textarea
          style={{
            height:
              Math.floor(title.length / 24) === 0
                ? '34px'
                : `${25 * (Math.floor(title.length / 24) + 1)}px`,
          }}
          autoFocus={true}
          value={title}
          onBlur={onBlurTitle}
          onChange={onChangeTitle}
          onKeyPress={onKeyPressTitle}
          onFocus={e =>
            ((e.target as HTMLTextAreaElement).selectionStart =
              title.length + 1)
          }
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
          }}
        />
      )}
      <div className="empty-link">
        {!isEditingTitle && <h2>{props.title}</h2>}

        <div className="cate-w">
          {props.sprints.length > 0 && (
            <div>
              {props.sprints.map((sprint, i) => (
                <span key={i} className="spt">
                  {sprint}
                </span>
              ))}
            </div>
          )}
          {props.labels.length > 0 && (
            <div>
              {props.labels.map((label, i) => (
                <span key={i} className="type">
                  {label}
                </span>
              ))}
            </div>
          )}
        </div>

        {props.dueDate && (
          <div className="due-d">
            <span className={isDuDateOver ? 'over' : ''}>
              <i>종료예정일</i>
              {moment(props.dueDate).format('MM/DD')}
            </span>
          </div>
        )}

        <div className="info">
          <span className="list">
            <i>작업목록</i>
            {props.doneSubtaskCount}/{props.subtaskCount}
          </span>
          <span className="file">
            <i>첨부파일</i>
            {props.attachmentCount}
          </span>
          <span className="comt">
            <i>댓글</i>
            {props.commentCount}
          </span>
          <span className="invt">
            <i>카드참여자</i>
            {assigneeTitle}
          </span>
        </div>
      </div>
      <span className="btn-md">
        <button
          type="button"
          className="modify"
          onClick={e => {
            e.stopPropagation();
            setIsEditingTitle(true);
            // props.onChangeTitle && props.onChangeTitle();
          }}
        >
          <span>수정</span>
        </button>
        <button
          type="button"
          className="delete"
          onClick={e => {
            e.stopPropagation();
            props.onDelete && props.onDelete();
          }}
        >
          <span>삭제</span>
        </button>
      </span>
    </div>
  );
};

import { observer } from 'mobx-react';
import moment from 'moment';
import React, { FC } from 'react';
import { IRufreeMatching } from '../../../store/Dashboard/models/RufreeMatching';
import { IScheduleSprint } from '../../../store/Dashboard/models/ScheduleSprint';

import { IWorker } from '../../../store/Dashboard/models/Worker';

import { Member as Comps } from '../../molecules/Dashboard';

interface Props {
  className?: string;
  managers: IWorker[];
  rufrees: IScheduleSprint[] | null;
  isMatchingStatus: boolean;
  matchings: IRufreeMatching[];
}

const Member: FC<Props> = observer(
  ({ className, managers, matchings, isMatchingStatus, rufrees }) => {
    return (
      <Comps.Wrapper className={className}>
        <Comps.SubTitle>팀원 정보</Comps.SubTitle>

        <Comps.Box>
          <Comps.Content>
            <Comps.ContentTitle>시소</Comps.ContentTitle>
            <Comps.ContentBody>
              {managers.map((manager, i) => (
                <Comps.ContentBodyRow key={i}>
                  <div>PM</div>
                  <div>{manager.name}</div>
                  <div>{manager.email}</div>
                </Comps.ContentBodyRow>
              ))}
            </Comps.ContentBody>
          </Comps.Content>

          <Comps.Content>
            <Comps.ContentTitle>알유프리</Comps.ContentTitle>
            <Comps.ContentBody>
              {isMatchingStatus &&
                matchings.every(matching => !matching.matched_rufree) && (
                  <Comps.ContentBodyRow>
                    <div className="max-width matching matching-title">
                      최적의 팀으로 매칭 중입니다
                    </div>
                  </Comps.ContentBodyRow>
                )}

              {rufrees?.map((rufree, i) => {
                const matching = matchings
                  .filter(matching => matching.status === '제안중')
                  .find(
                    matching =>
                      !!matching.date_request && matching.role === rufree.role,
                  );

                return rufree.rufreeName ? (
                  <Comps.ContentBodyRow key={i}>
                    <div>{rufree.role}</div>
                    <div className="rufree">
                      <a
                        href={`https://rufree.kr/users?uid=${rufree.rufreeId}`}
                        target="_blank"
                      >
                        {rufree.rufreeName}
                      </a>
                    </div>
                    <div>
                      {matching && !rufree.haveScheduleDate ? (
                        <>
                          매칭 완료
                          <span
                            className="scheduled"
                            style={{ marginLeft: '10px' }}
                          >
                            {moment(matching.matched_time).format('YYYY.MM.DD')}
                          </span>
                        </>
                      ) : (
                        rufree.joinedSprints
                      )}
                    </div>
                  </Comps.ContentBodyRow>
                ) : (
                  <Comps.ContentBodyRow
                    key={i}
                    className={matching ? 'matching-row' : 'scheduled-row'}
                  >
                    <div className={`${matching ? '' : 'scheduled'}`}>
                      {rufree.role}
                    </div>
                    {matching ? (
                      <div className={`${matching ? 'matching' : 'scheduled'}`}>
                        매칭중
                      </div>
                    ) : (
                      <>
                        <div className="empty" />
                        <div className="scheduled">진행예정</div>
                      </>
                    )}
                    {matching ? (
                      <div
                        className={`${
                          matching ? 'matching-date' : 'scheduled'
                        }`}
                      >
                        매칭 시작일
                        <span className="info">
                          {moment(matching.date_request).format('MM.DD')}
                        </span>
                        <span className="sub-row info diff">
                          소요기간{' '}
                          {moment().diff(moment(matching.date_request), 'days')}
                          일
                        </span>
                      </div>
                    ) : (
                      <div className="empty" />
                    )}
                  </Comps.ContentBodyRow>
                );
              })}
            </Comps.ContentBody>
          </Comps.Content>
        </Comps.Box>
      </Comps.Wrapper>
    );
  },
);

export default Member;

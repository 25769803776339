import moment from 'moment';
import React, { FC } from 'react';
import { SprintReport } from '../../../store/Dashboard/models';

import { SprintReportListPopup as Comps } from '../../molecules/Calculate';

interface Props {
  className?: string;
  isOpen: boolean;
  reports: SprintReport.IReport[];
  handleClose: () => void;
}

const SprintReportListPopup: FC<Props> = props => {
  return (
    <Comps.BP3Dialog isOpen={props.isOpen} onClose={props.handleClose}>
      <Comps.Header>스프린트 보고서</Comps.Header>

      <Comps.Body>
        {props.reports.map(report => (
          <Comps.Row>
            <div
              onClick={async () => {
                report.download();
              }}
            >
              {report.title}
            </div>
            <div>
              발송일 : {moment(report.datePublish).format('YYYY.MM.DD HH:mm')}
            </div>
          </Comps.Row>
        ))}
      </Comps.Body>

      <Comps.Footer />

      <Comps.CloseButton onClick={props.handleClose} />
    </Comps.BP3Dialog>
  );
};

export default SprintReportListPopup;

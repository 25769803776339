import { Modal } from 'antd';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import styled from 'styled-components/macro';

interface CancelModalProps {
  visible: any;
  handleRequestCancel: any;
  setModal1Visible: any;
}

const ModalWrapper = styled.div`
  h4 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`;

const CheckboxWrapper = styled.div`
  h4 {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  textarea {
    display: block;
    margin-top: 10px;
    min-height: 135px;
    padding: 10px 15px;
    width: 94%;
    margin-left: 6%;
    border-color: #bbbbbb;
  }
`;

const CancelCheckBoxRow = styled.div`
  display: block;
  margin-bottom: 14px;
  label {
    font-size: 14px;
  }
  input[type='checkbox'] {
    visibility: hidden;
    position: absolute;
    opacity: 0;
  }
  input[type='checkbox'] + label {
    display: inline-block;
    position: relative;
    padding: 3px 0 0 27px;
    cursor: pointer;
    color: #000;
    font-size: 14px;
  }
  input[type='checkbox'] + label:before {
    display: block;
    position: absolute;
    content: '';
    width: 16px;
    height: 16px;
    background: #fff;
    left: 0;
    top: 6px;
    border: 1px solid #474747;
    border-radius: 3px;
  }
  input[type='checkbox']:checked + label {
    font-weight: 500;
  }
  input[type='checkbox']:checked + label:before {
    background: #5834ff;
  }
`;

const ReceptionsCancelModal: FC<CancelModalProps> = observer(props => {
  const ReasonValue: Array<any> = [
    {
      key: 'outsourcing',
      select: false,
      text: '다른 아웃소싱 업체 선택',
    },
    {
      key: 'cost',
      select: false,
      text: '예상보다 비용이 높음',
    },
    {
      key: 'date',
      select: false,
      text: '프로젝트 일정 변경',
    },
    {
      key: 'resource',
      select: false,
      text: '내부 리소스 및 의사결정 변경',
    },
    {
      key: 'not-suitable',
      select: false,
      text: '시소의 업무 프로세스가 프로젝트에 적합하지 않음',
    },
    {
      key: 'etc',
      select: false,
      text: '기타',
    },
  ];

  const { visible, handleRequestCancel, setModal1Visible } = props;
  const [cancelReasons, setCancelReasons] = useState(ReasonValue);
  const [etcText, setetcText] = useState('');
  const [validCancel, setvalidCancel] = useState(true);

  const updateCancelVal = (index: any) => (e: any) => {
    let newArr = [...cancelReasons];
    newArr[index]['select'] = !newArr[index]['select'];
    setCancelReasons(newArr);

    const checkArr = newArr.filter(item => {
      return item['select'];
    });

    if (checkArr.length > 0) {
      const checkArr2 = checkArr.filter(item => {
        return item['key'] == 'etc';
      });
      if (checkArr2.length > 0) {
        etcText.length > 0 ? setvalidCancel(false) : setvalidCancel(true);
      } else {
        return setvalidCancel(false);
      }
    } else {
      return setvalidCancel(true);
    }
  };

  const updateEtcVal = (e: any) => {
    setetcText(e.target.value);
    e.target.value.length > 0 ? setvalidCancel(false) : setvalidCancel(true);
  };
  const handleCancelBtn = (e: any) => {
    // 하나이상 선택 취소 만들기 !
    let newArr = [...cancelReasons];
    newArr.map(item => {
      item['select'] = false;
    });
    setCancelReasons(newArr);
    setvalidCancel(true);
    setModal1Visible(false);
  };

  return (
    <Modal
      className="ReceptionCancelModal"
      title="프로젝트 의뢰 취소"
      visible={visible}
      onOk={() => handleRequestCancel(cancelReasons, etcText)}
      cancelText="취소"
      okText="제출"
      closeIcon={<span className="svg-icon"></span>}
      okButtonProps={{ disabled: validCancel }}
      onCancel={e => handleCancelBtn(e)}
    >
      <ModalWrapper>
        <h4>의뢰를 취소하는 이유는 무엇인가요? (복수 선택)</h4>
        <CheckboxWrapper>
          {cancelReasons.map((item: any, i: number) => {
            return (
              <CancelCheckBoxRow key={i}>
                <input
                  type="checkbox"
                  id={item.key}
                  checked={item.select}
                  onChange={updateCancelVal(i)}
                />
                <label htmlFor={item.key}>{item.text}</label>
                {item.key == 'etc' && item.select == true ? (
                  <textarea
                    id="etcText"
                    name="etcText"
                    placeholder="기타 사유 입력"
                    value={etcText}
                    onChange={updateEtcVal}
                  />
                ) : (
                  ''
                )}
              </CancelCheckBoxRow>
            );
          })}
        </CheckboxWrapper>
      </ModalWrapper>
    </Modal>
  );
});

export default ReceptionsCancelModal;

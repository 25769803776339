import styled from 'styled-components/macro';

import { PC } from '../../../constants/media';

export const MenuHeader = styled.header`
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  background: inherit;
  position: relative;
  z-index: 10;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;
  padding-top: 42px;
  box-sizing: border-box;
  h1 {
    margin-right: 55px;

    a {
      display: block;
      width: 65px;
      height: 12px;
      background: url('https://seeso-cpms.s3.ap-northeast-1.amazonaws.com/uploads/logo.png')
        no-repeat 0 0;
      background-size: 158px 12px;
      span {
        overflow: hidden;
        position: absolute;
        width: 1px;
        height: 1px;
        font-size: 1px;
        line-height: 0;
        text-indent: -9999px;
        display: block;
      }
    }
  }
  nav {
    float: left;
    padding: {
      bottom: 5px;
      left: 45px;
    }
    ul {
      li {
        display: inline-block;
        text-transform: uppercase;
        vertical-align: top;
        box-sizing: border-box;
        a {
          display: inline-block;
          color: #4b34ff;
          font-size: 15px;
        }
        /* &.selected,
				&:hover {
					a {
						border-bottom: 1px solid #4b34ff;
					}
				} */
      }
    }
  }
  > div {
    > a {
      position: absolute;
      right: 0;
      top: 30px;
      width: 150px;
      height: 51px;
      border-radius: 3px;
      background-color: #4b34ff;
      color: #fff;
      font-size: 15px;
      text-align: center;
      line-height: 51px;
    }
  }
  &.dtype2 {
    background: #5834ff;
    h1 {
      a {
        background-position: 100% 0;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    min-height: 68px;
    position: relative;
    z-index: 10;
    max-width: 1220px;
    margin: 0 auto;
    box-sizing: border-box;
    width: 1220px;
    button {
      display: none;
    }
    nav {
      ul {
        li {
          width: 120px;
          //padding: 0 17px;
        }
      }
    }
    &.dtype2 {
      nav {
        ul {
          li {
            a {
              color: #fff;
            }
            &.selected,
            &:hover {
              a {
                border-color: #fff;
              }
            }
          }
        }
      }
      > div {
        > a {
          background: #fff3fa;
          color: #4b34ff;
        }
      }
    }
  }
  @media only screen and (max-width: ${PC}) {
    height: 60px;
    display: none; // 모바일에서는 하나의 메뉴바로 합침
    h1 {
      position: absolute;
      top: 25px;
      left: 15px;
    }

    > div {
      display: none;
      &.active {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10;
        width: 100%;
        height: 100%;
        background: #fff;
        nav {
          float: none;
          margin-top: 70px;
          padding: 0 30px;
          ul {
            li {
              display: block;
              padding: 0;
              a {
                display: block;
                padding: 30px 0 0;
                font-size: 18px;
              }
            }
          }
        }
        > a {
          position: static;
          display: block;
          margin: 30px 0 0 30px;
        }
        button {
          border: none;
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 7px;
            width: 100%;
            height: 2px;
            background: #4b34ff;
            transform: rotate(45deg);
          }
          &:before {
            background: #4b34ff;
            transform: rotate(-45deg);
          }
        }
      }
    }
    &.dtype2 {
      button {
        border-color: #fff;
        &:before {
          background: #fff;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: ${PC}) {
    width: 100%;
    min-height: 68px;
    position: relative;
    z-index: 10;
    max-width: 1220px;
    margin: 0 auto;
    box-sizing: border-box;
  }
`;

import {
  Alert as bp3_Alert,
  Dialog as bp3_Dialog,
  Spinner,
} from '@blueprintjs/core';
import React from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import CloseSVG from '../../../static/icons/icon-close-white.svg';
import ErrorUserActiveSVG from '../../../static/icons/icon-error-user-active.svg';
import ErrorUserSVG from '../../../static/icons/icon-error-user.svg';

/*
  리포트 플로팅 버튼 컴포넌트
*/
export const FloatingButton = styled.div<{
  // isActive?: boolean;
}>`
  position: fixed;
  right: 54px;
  bottom: 30px;
  background-color: ${PALETTE['bright-orange']};
  border-radius: 28px;
  width: 56px;
  height: 56px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  cursor: pointer;

  &:before {
    content: '';
    background: url('${ErrorUserSVG}') no-repeat center center;
    width: 16px;
    height: 16px;
    position: absolute;
    left: 20px;
    top: 20px;
  }

  &:hover,
  &.active {
    width: 132px;
    &:after {
      content: '오류 접수';
      width: 63px;
      height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: ${PALETTE['white']};
      position: absolute;
      left: 48.5px;
      top: 17px;
    }
  }

  &.active {
    &:before {
      background: url('${ErrorUserActiveSVG}') no-repeat center center;
    }

    &:after {
      content: '오류 접수';
      width: 63px;
      height: 24px;
      font-size: 16px;
      font-weight: bold;
      color: ${PALETTE['purpleish-blue']};
      position: absolute;
      left: 48.5px;
      top: 17px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    z-index: 19;
    left: 15px;
    bottom: 30px;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  margin-top: 20px;

  label {
    color: ${PALETTE['purpleish-blue']};
    font-size: 13px;
    font-weight: 500;
  }

  input,
  textarea {
    margin-top: 4px;
    width: 100%;
    border-radius: 6px;
    border: solid 1px ${PALETTE['brown-grey']};
    line-height: 19px;
    font-size: 13px;
  }

  input {
    height: 37px;
    padding: 8px 12px;
  }

  div.invalid-message {
    color: ${PALETTE['color-red']};
    position: absolute;
    right: 0;
    font-size: 12px;
    font-weight: 500;
    display: none;
  }

  &.invalid {
    div.invalid-message {
      display: inherit;
    }
    label {
      color: ${PALETTE['color-red']};
    }
    input,
    textarea {
      border: solid 1px ${PALETTE['color-red']};
    }
  }
`;

const TextareaWrapper = styled(InputWrapper)`
  textarea {
    padding: 10px 13px;
    height: 220px;
    resize: none;
  }
`;
export const InputField: React.FC<{
  title: string;
  name: string;
  value: string;
  disabled: boolean;
  isValid?: boolean;
  onChange: (value: string) => void;
}> = props => {
  return (
    <InputWrapper className={props.isValid ? '' : 'invalid'}>
      <label htmlFor={props.name}>{props.title}</label>
      <input
        id={props.name}
        name={props.name}
        required={true}
        disabled={props.disabled}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          props.onChange(e.target.value)
        }
      />
      <div className="invalid-message">*필수입력</div>
    </InputWrapper>
  );
};

export const TextareaField: React.FC<{
  title: string;
  name: string;
  placeholder: string;
  disabled: boolean;
  value: string;
  isValid?: boolean;
  onChange: (value: string) => void;
}> = props => {
  return (
    <TextareaWrapper className={props.isValid ? '' : 'invalid'}>
      <label htmlFor={props.name}>{props.title}</label>
      <textarea
        id={props.name}
        name={props.name}
        placeholder={props.placeholder}
        required={true}
        disabled={props.disabled}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
          props.onChange(e.target.value)
        }
      />
      <div className="invalid-message">*필수입력</div>
    </TextareaWrapper>
  );
};

/*
  리포트 모달 컴포넌트
*/
const ModalWrapper = styled(bp3_Dialog)`
  width: 450px;
  height: 622px;
  margin: 0;
  padding: 0;
  border-radius: 30px 30px 25px 25px;

  div.header {
    width: 100%;
    height: 102px;
    border-radius: 25px 25px 0 0;
    background-color: ${PALETTE['purpleish-blue']};
    padding: 0 24px;
    color: ${PALETTE['white']};
    position: relative;

    div.title {
      width: 171px;
      height: 31px;
      font-size: 21px;
      font-weight: bold;
      margin-top: 26px;
    }

    div.description {
      font-size: 13px;
      margin-top: 5px;
      height: 19px;
    }

    span.icon {
      position: absolute;
      top: 18px;
      width: 18px;
      height: 18px;
      right: 18px;
      cursor: pointer;

      &.close {
        background: url(${CloseSVG}) no-repeat center center;
      }
    }

    @media only screen and (max-width: ${PC}) {
      border-radius: inherit;
    }
  }

  div.body {
    padding-left: 24px;
    padding-right: 24px;
  }

  div.footer {
    padding-top: 32px;
    display: flex;
    margin: auto;
    margin-top: 0;

    button {
      cursor: pointer;
      width: 90px;
      height: 32px;
      border-radius: 3px;
      border: none;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
      background-color: ${PALETTE['color-disabled-bg']};
      color: ${PALETTE['color-disabled']};
      font-size: 12px;
      font-weight: 500;
    }
    button.confirm-btn {
      background-color: ${PALETTE['color-pale-purple']};
      font-size: 12px;
      font-weight: 500;
      color: ${PALETTE['purpleish-blue']};
    }

    button + button {
      margin-left: 40px;
    }
  }

  @media only screen and (max-width: ${PC}) {
    border-radius: inherit;
    width: 100%;
  }
`;
export const ReportModal: React.FC<{
  isOpen: boolean;
  isLoading: boolean;

  onClose: () => void;
  onSend: () => void;
}> = props => {
  /*
   * blueprinjs - Dialog 컴포넌트 customize ( https://blueprintjs.com/docs/#core/components/dialog )
   */

  return (
    <ModalWrapper {...props}>
      <div className="header">
        <div className="title">오류가 발생했나요?</div>
        <div className="description">
          기능 작동 이상, 버그, 디자인 깨짐 등의 이슈를 알려주세요.
        </div>

        <span className="icon close" onClick={props.onClose} />
      </div>

      <div className={`body`}>{props.children}</div>

      <div className="footer">
        <button onClick={props.onClose}>취소</button>
        <button className="confirm-btn" type="submit" onClick={props.onSend}>
          {props.isLoading ? <Spinner size={18} /> : <span>접수</span>}
        </button>
      </div>
    </ModalWrapper>
  );
};

/*
  리포트 성공 노티 컴포넌트
*/
const AlertWrapper = styled(bp3_Alert)`
  max-width: inherit;
  width: 450px;
  height: 118px;
  border-radius: 25px;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 4px 5px 0 rgba(0, 0, 0, 0.14);
  background-color: ${PALETTE['purpleish-blue']};
  color: ${PALETTE['white']};
  padding: inherit;
  margin: inherit;
  font-size: 13px;
  font-weight: bold;
  padding: 0 24px;
  position: relative;

  span.icon {
    position: absolute;
    top: 18px;
    width: 18px;
    height: 18px;
    right: 18px;
    cursor: pointer;

    &.close {
      background: url(${CloseSVG}) no-repeat center center;
    }
  }

  div.alert-title {
    font-size: 21px;
    font-weight: bold;
    margin-top: 34px;
    margin-left: 48px;
    margin-bottom: 7px;
    height: 31px;

    @media only screen and (max-width: ${PC}) {
      font-size: 18px;
      margin-left: 0;
      margin-top: 28px;
    }
  }

  div.alert-body {
    display: flex;
    @media only screen and (max-width: ${PC}) {
      display: block;
    }
    div + div {
      margin-left: 2px;
    }
  }

  div.bp3-alert-footer {
    display: none;
  }

  @media only screen and (max-width: ${PC}) {
    padding: 0 15px;
    font-size: 12px;
  }
`;
export const SendSuccessAlert: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = props => {
  /*
   * blueprinjs - Alert 컴포넌트 customize ( https://blueprintjs.com/docs/#core/components/alert )
   */

  return (
    <AlertWrapper {...props} canOutsideClickCancel={true}>
      <div className="alert-title">오류가 성공적으로 접수되었습니다.</div>
      <div className="alert-body">
        <div>불편을 드려 죄송합니다.</div>
        <div>확인 후 적어주신 이메일 주소로 연락드리겠습니다.</div>
      </div>

      <span className="icon close" onClick={props.onClose} />
    </AlertWrapper>
  );
};

import React, { useEffect, useState } from 'react';

import { Alert } from '../Common';
import { Title } from '../Common/Fields';

export interface ITaskListProps {
  id: number;
  taskListId: string;
  title: string;
}

export interface IHeaderProps {
  isArchived?: boolean;
  title: string;
  listTitle: string;
  listTitleOptions: ITaskListProps[];

  onChangeListTitle: (title: string) => void;
  onChangeTitle: (title: string) => void;
  onShare: () => void;
  onActive: () => void;
  onDeactive: () => void;
  onDelete: () => void;
}

export const Header: React.SFC<IHeaderProps> = props => {
  // const [ isEditing, setIsEditing ] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(props.title);

  const [showListTitle, setShowListTitle] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeactiveAlert, setShowDeactiveAlert] = useState(false);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  return (
    <>
      <div className={`hgroup ${props.isArchived ? 'delete-task' : ''}`}>
        {props.isArchived && <p>삭제된 태스크입니다</p>}

        <Title
          isEditable={!props.isArchived}
          title={title}
          onChange={(title: string) =>
            props.onChangeTitle && props.onChangeTitle(title)
          }
        />

        {/* <div className="tit">
          {
            isEditing &&
              <textarea
                autoFocus={ true }
                title="타이틀 수정"
                value={ title }
                onBlur={ (e: React.FocusEvent<HTMLTextAreaElement>) => {
                  props.onChangeTitle && props.onChangeTitle((e.target as HTMLTextAreaElement).value);
                  setIsEditing(false);
                }}
                onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => setTitle(e.target.value)}
                onKeyPress={ (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
                  if (e.key === 'Enter') {
                    props.onChangeTitle && props.onChangeTitle((e.target as HTMLTextAreaElement).value);
                    setIsEditing(false);
                  }
                }}
              />
          }
          <h1 onClick={ () => setIsEditing(true) }>{ props.title }</h1>

        </div> */}

        {props.isArchived ? (
          <span>{props.listTitle}</span>
        ) : (
          <div className="ready-w">
            <span onClick={() => setShowListTitle(!showListTitle)}>
              {props.listTitle}
            </span>
            <div
              className="user-box"
              style={{ display: showListTitle ? 'block' : 'none' }}
            >
              <ul>
                {props.listTitleOptions.map((listTitle: any, i: number) => (
                  <li
                    key={i}
                    onClick={() => {
                      props.onChangeListTitle(listTitle.taskListId);
                      setShowListTitle(false);
                    }}
                  >
                    <a href="#">{listTitle.title}</a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}

        <div className="menu">
          <button type="button">
            <span>태스크 메뉴 열림</span>
          </button>
          <div>
            <ul>
              {props.isArchived ? (
                <>
                  <li
                    onClick={() => {
                      props.onActive();
                    }}
                  >
                    <a href="#">태스크 복구</a>
                  </li>
                  <li
                    onClick={() => {
                      setShowDeleteAlert(true);
                    }}
                  >
                    <a href="#">완전 삭제</a>
                  </li>
                </>
              ) : (
                <>
                  <li
                    onClick={() => {
                      props.onShare();
                    }}
                  >
                    <a href="#">태스크 공유</a>
                  </li>
                  <li onClick={() => setShowDeactiveAlert(true)}>
                    <a href="#">태스크 삭제</a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>

      <Alert
        isOpen={showDeactiveAlert}
        onCancel={() => setShowDeactiveAlert(false)}
        onConfirm={() => {
          props.onDeactive();
          setShowDeactiveAlert(false);
        }}
      >
        <p>
          선택한 태스크를
          <br /> 삭제하시겠습니까?
        </p>
      </Alert>

      <Alert
        isOpen={showDeleteAlert}
        onCancel={() => setShowDeleteAlert(false)}
        onConfirm={() => {
          props.onDelete();
          setShowDeleteAlert(false);
        }}
      >
        <p>
          선택한 태스크를
          <br /> 완전 삭제하시겠습니까?
        </p>
      </Alert>
    </>
  );
};

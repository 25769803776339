import { types } from 'mobx-state-tree';
import { ClientRoleModel, ClientRoleResponse } from './ClientRole';
import { RufreeRoleModel, RufreeRoleResponse } from './RufreeRole';
import { SeesoServiceModel, SeesoServiceResponse } from './SeesoService';

// export const convertFromResp = () =>{}

export const mapInspection = (x: InspectionResponse) => {
  return {
    inspectionId: x.inspection_id,
    client: x.client,
    sprintDays: x.sprint_days,
    clientRoles: x.client_roles,
    rufreeRoles: x.rufree_roles,
    seesoServices: x.seeso_services,
    createdAt: x.created_at,
    updatedAt: x.updated_at,
    title: x.title,
    requesterName: x.requester_name,
    requesterEmail: x.requester_email,
    inspectorName: x.inspector_name,
    inspectorEmail: x.inspector_email,
    referenceLinks: x.reference_links,
    referenceLinkTitles: x.reference_link_titles,
    linkList: x.link_list,
    references: x.references,
    dateStart: x.date_start,
    dateEnd: x.date_end,
    vatIncluded: x.vat_included,
    budget: x.budget,
    review: x.review,
    attention: x.attention,
    publishedDate: x.published_date,
    publishedTime: x.published_time,
    receptions: x.receptions,
    historyUrl: x.history_url,
    projectgroupIds: x.projectgroup_ids,
    // historyList: x.history_list,
    // first_published: x.first_published,
    pg_index: x.pg_index,
    published_count: x.published_count,
    // clientChecked: x.client_checked ? x.client_checked : false,
    notificationOn: x.notification_on,
    externalRef: x.external_ref,
    projectStarted: x.project_started ? x.project_started : false,
    inspection_files: x.inspection_files
      ? x.inspection_files.map(i => {
          return {
            file_url: i.file_url,
            file_name: i.file_name,
          };
        })
      : [],
    // rufree_seeso_per_sprint: x.rufree_seeso_per_sprint,
  };
};

export interface ReferenceLinkResponse {
  link: string;
  comment: string;
}

export interface InspectionResponse {
  client: string;
  sprint_days: number[];
  client_roles: ClientRoleResponse[];
  rufree_roles: RufreeRoleResponse[];
  seeso_services: SeesoServiceResponse[];
  created_at: string;
  updated_at: string;
  inspection_id: string;
  title: string;
  requester_name: string;
  requester_email: string;
  inspector: number;
  inspector_name: string;
  inspector_email: string;
  reference_links: string[];
  reference_link_titles: string[];
  link_list: { title: string; link: string; created_date: string }[];
  references: ReferenceLinkResponse[];
  date_start: string | null;
  date_end: string | null;
  vat_included: boolean;
  budget: number;
  review: string;
  published_date: string | null;
  published_time: string | null;
  attention: string;
  receptions: number[];
  history_url: string;
  projectgroup_ids: {
    group_id: string;
    name: string;
    date_complete: string | null;
    project_started: boolean | null;
  }[];
  pg_index: number;
  // first_published: boolean;
  published_count: number;
  // client_checked: boolean;
  notification_on: boolean;
  external_ref: string;
  project_started: boolean;
  inspection_files: {
    file_url: string;
    file_name: string;
  }[];
  /*rufree_seeso_per_sprint: {[key:string]:{
                    total: number,
                    seeso_total: number,
                    seeso_total_vat: number,
                    seeso_list: {
                            service: string,
                            cost: number,
                            individual: boolean,
                    }[],
                    rufree_total: number,
                    rufree_total_vat: number,
                    rufree_list: {
                            role: string,
                            wage: number,
                    }[],
                    sprint_name: string,
            };
    };*/
}

export interface InpsectionListResponse {
  count: number;
  next: string | null;
  previous: string | null;
  page: number;
  total_pages: number;
  results: InspectionResponse[];
}

export const InspectionModel = types.model('Inspection', {
  inspectionId: types.identifier,
  client: types.maybeNull(types.string),
  sprintDays: types.array(types.number),
  clientRoles: types.array(ClientRoleModel),
  rufreeRoles: types.array(RufreeRoleModel),
  seesoServices: types.array(SeesoServiceModel),
  createdAt: types.string,
  updatedAt: types.string,

  title: types.string,
  requesterName: types.string,
  requesterEmail: types.string,

  inspector: types.maybeNull(types.number),
  inspectorName: types.maybeNull(types.string),
  inspectorEmail: types.maybeNull(types.string),

  referenceLinks: types.array(types.string),
  referenceLinkTitles: types.array(types.string),
  linkList: types.array(
    types.model('LinkReference', {
      link: types.string,
      title: types.string,
      created_date: types.string,
    }),
  ),
  inspection_files: types.array(
    types.model('InspectionFiles', {
      file_url: types.string,
      file_name: types.string,
    }),
  ),
  references: types.array(
    types.model('InspectionReference', {
      link: types.string,
      comment: types.string,
    }),
  ),
  dateStart: types.maybeNull(types.string),
  dateEnd: types.maybeNull(types.string),
  vatIncluded: types.boolean,
  budget: types.number,
  review: types.string,
  attention: types.maybeNull(types.string),
  receptions: types.array(types.number),
  publishedDate: types.maybeNull(types.string),
  publishedTime: types.maybeNull(types.string),
  historyUrl: types.maybeNull(types.string),
  projectgroupIds: types.array(
    types.model('ProjectgroupIds', {
      group_id: types.string,
      name: types.string,
      date_complete: types.maybeNull(types.string),
      project_started: types.maybeNull(types.boolean),
    }),
  ),
  pg_index: types.number,
  published_count: types.number,
  // first_published: types.boolean,
  // clientChecked: types.boolean,
  notificationOn: types.boolean,
  externalRef: types.string,
  projectStarted: types.boolean,
  // rufree_seeso_per_sprint: types.maybeNull(types.map(RufreeSeesoPerSprintModel))
});
// .actions(self => ({
//     setClientChecked(value: boolean) {
//         self.clientChecked = value;
//     }
// }))

type InspectionType = typeof InspectionModel.Type;

export interface Inspection extends InspectionType {}

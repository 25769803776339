import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import styled from 'styled-components/macro';
import { PC } from '../../../constants/media';
import MailSVG from '../../../static/icons/icon-mailsend-black.svg';
import { AppStore } from '../../../store/AppStore';
import { getChannelListener } from '../../../utils/communication/ChannelLoader';
import { EVENT } from '../../../utils/firebase/push-notification';

import { PageWrapper } from '../../molecules/MainTemplate/MainTemplate';
import CardList from '../../organisms/Inspection/CardList';

const Wrapper = styled(PageWrapper)`
  max-width: 1280px;

  padding-top: 50px;
  min-height: 500px;

  & > div:first-child {
    margin-bottom: 90px;
  }

  @media only screen and (max-width: ${PC}) {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
  }
`;

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 40px;
  margin-left: 30px;
  margin-bottom: 20px;
  max-width: 1220px;
  @media only screen and (max-width: ${PC}) {
    margin-right: 40px;
  }
`;
const Title = styled.div`
  height: 26px;
  font-family: .AppleSystemUIFont;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
`;

const MainAction = styled.a`
  color: #000000;
  line-height: 19px;
  font-size: 13px;
  font-weight: bold;
  margin-left: 40px;
  position: relative;
  cursor: pointer;

  :hover {
    color: #000000;
  }

  ::before {
    content: '';
    background: url('${MailSVG}') no-repeat center center;
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    top: 2px;
  }

  @media only screen and (max-width: ${PC}) {
    margin-left: 50px;
    &:first-child {
      margin-left: 0px;
    }
  }
`;

interface InjectedProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class InspectionPage extends Component {
  componentDidMount() {
    setTimeout(async () => {
      try {
        await this.injected.appStore.inspectionStore.fetchInspections();
      } catch (e) {}
    }, 50);

    const listener = getChannelListener(
      EVENT.project.Inspection_published_updated,
    );
    if (listener)
      listener.onmessage = e => {
        setTimeout(async () => {
          try {
            await this.injected.appStore.inspectionStore.fetchInspections();
          } catch (e) {}
        }, 1000);
      };
  }

  async componentDidUpdate() {
    await this.injected.appStore.inspectionStore.fetchInspections();
  }

  get injected() {
    return this.props as InjectedProps;
  }

  render() {
    return (
      <Wrapper>
        <TitleBar>
          <Title>프로젝트 플랜</Title>
          <MainAction href={`mailto:support@seeso.kr`}>
            플랜 관련 문의
          </MainAction>
        </TitleBar>
        <CardList
          inspections={this.injected.appStore.inspectionStore.inspections}
        />
      </Wrapper>
    );
  }
}

export default InspectionPage;

import { types } from 'mobx-state-tree';

export const BulletinPostFileModel = types.model('BulletinPostFile', {
  id: types.number,
  created_at: types.string,
  updated_at: types.string,
  file: types.string,
  task_bulletin_post: types.string,
  writer: types.number,
  is_image: types.boolean,
  filename: types.string,
});
type BulletinPostFileType = typeof BulletinPostFileModel.Type;

export interface BulletinPostFile extends BulletinPostFileType {}

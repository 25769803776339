import React, { Component } from 'react';
import { Terms as Comps } from '../../organisms/Etc';

class PrivateTermPage extends Component {
  render() {
    return (
      <Comps.TermsWrapper>
        <div className="term-wrap">
          <h1 style={{ textAlign: 'center' }}>개인정보 수집 및 사용</h1>
          주식회사 시소에서 운영하는 서비스(이하 "시소”라 함) 는
          보통신망이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’) 등에
          따라 회원의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게
          처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을
          수립·공개합니다.
          <br />
          <br />
          <strong>제1조 개인정보의 처리목적</strong>
          <br />
          시소은(는) 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는
          개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이
          변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는 등
          필요한 조치를 이행할 예정입니다. <br />
          <br />
          1. 페이지 회원 가입 및 관리
          <br />
          회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별․인증, 회원자격
          유지․관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용
          방지, 만 14세 미만 아동의 개인정보 처리시 법정대리인의 동의여부 확인,
          각종 고지․통지, 고충처리 등을 목적으로 개인정보를 처리합니다.
          <br />
          <br />
          2. 재화 또는 서비스 제공 <br />
          물품배송, 서비스 제공, 계약서․청구서 발송, 콘텐츠 제공, 맞춤서비스
          제공, 본인인증, 연령인증, 요금결제․정산, 채권추심 등을 목적으로
          개인정보를 처리합니다. <br />
          <br />
          3. 고충처리 <br />
          민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락․통지, 처리결과
          통보 등의 목적으로 개인정보를 처리합니다. <br />
          <br />
          4. 시소의 개인정보 처리업무
          <br />
          개인정보 처리업무에 따른 처리목적으로 개인정보를 처리합니다. <br />
          <br />
          <strong>제 2조(개인정보의 처리 및 보유기간)</strong>
          <br />
          <br />
          ① 시소 은(는) 법령에 따른 개인정보 보유․이용기간 또는 정보주체로부터
          개인정보를 수집시에 동의받은 개인정보 보유․이용기간 내에서 개인정보를
          처리․보유합니다. <br />
          ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다. <br />
          <br />
          1. 홈페이지 회원 가입 및 관리 : 사업자/단체 홈페이지 탈퇴시까지 <br />
          다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지 <br />
          1) 관계 법령 위반에 따른 수사․조사 등이 진행중인 경우에는 해당
          수사․조사 종료시까지 <br />
          2) 홈페이지 이용에 따른 채권․채무관계 잔존시에는 해당 채권․채무관계
          정산시까지 <br />
          3) '예외 사유' 시에는 보유기간까지 해당
          <br />
          <br />
          2. 재화 또는 서비스 제공 : 재화․서비스 공급완료 및 요금결제․정산
          완료시까지
          <br />
          다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료시까지 <br />
          1) 「전자상거래 등에서의 소비자 보호에 관한 법률」에 따른 표시․광고,
          계약내용 및 이행 등 거래에 관한 기록 <br />
          - 표시․광고에 관한 기록 : 6월 <br />
          - 계약 또는 청약철회, 대금결제, 재화 등의 공급기록 : 5년 <br />
          - 소비자 불만 또는 분쟁처리에 관한 기록 : 3년 <br />
          2)「통신비밀보호법」제41조에 따른 통신사실확인자료 보관
          <br />
          - 가입자 전기통신일시, 개시․종료시간, 상대방 가입자번호, 사용도수,
          발신기지국 위치추적자료 : 1년 <br />
          - 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적자료 : 3개월
          <br />
          3) 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」시행령 제29조에
          따른 본인확인정보 보관 : 게시판에 정보 게시가 종료된 후 6개월
          <br />
          4) 개인정보 보유기간 근거법령 및 조문 : 보유기간 내<br />
          <br />
          3. 개인정보 처리업무 : 보유기간 내<br />
          <br />
          <strong>제 3조(개인정보의 제3자 제공)</strong>
          <br />
          ① 시소은(는) 정보주체의 개인정보를 제1조(개인정보의 처리 목적)에서
          명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등
          개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를
          제3자에게 제공합니다. <br />
          <br />
          <strong>제 4조(정보주체와 법정대리인의 권리․의무 및 행사방법)</strong>
          <br />
          <br />
          ① 정보주체는 시소에 대해 언제든지 개인정보 열람․정정․삭제․처리정지
          요구 등의 권리를 행사할 수 있습니다.
          <br />
          ② 제1항에 따른 권리 행사는 시소에 대해 개인정보보호법 시행령
          제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수
          있으며, 시소은(는) 이에 대해 지체없이 조치하겠습니다. <br />
          ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등
          대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙
          별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. <br />
          ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조
          제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. <br />
          ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집
          대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. <br />
          ⑥ 시소 은(는) 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구,
          처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한
          대리인인지를 확인합니다. <br />
          <br />
          <strong>제 5조 (처리하는 개인정보 항목)</strong>
          <br />
          <br />
          1. 홈페이지 회원 가입 및 관리 <br />
          필수항목 : 성명, 비밀번호, 전화번호, 이메일주소, 소속, 경력
          <br />
          선택항목 : 이력 및 과거 프로젝트 이력
          <br />
          2. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어
          수집될 수 있습니다. <br />
          ․IP주소,쿠키, 서비스 이용기록, 방문기록
          <br />
          <br />
          <strong>제 6조 (개인정보의 처리위탁)</strong>
          <br />
          <br />
          회사는 원활한 서비스 제공을 위하여 아래와 같이 개인정보를 위탁하고
          있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수
          있도록 필요한 사항을 규정하고 있습니다.
          <br />
          <table>
            <colgroup>
              <col width="*"></col>
            </colgroup>
            <tbody>
              <tr>
                <td>아마존 웹 서비스(영어: Amazon Web Services; AWS)</td>{' '}
                <td>호스팅 서비스 제공, 이메일 발송</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <strong>제 7조(개인정보의 파기)</strong>
          <br />
          <br />
          ① 시소은(는) 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가
          불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다. <br />
          ② 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이
          달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야
          하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나
          보관장소를 달리하여 보존합니다. <br />
          ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다. <br />
          1. 파기절차 <br />
          시소은(는) 파기 사유가 발생한 개인정보를 선정하고, 시소 개인정보
          보호책임자의 승인을 받아 개인정보를 파기합니다. <br />
          2. 파기방법 <br />
          시소 은(는) 전자적 파일 형태로 기록․저장된 개인정보는 기록을 재생할 수
          없도록 파기하며, 종이 문서에 기록․저장된 개인정보는 분쇄기로
          분쇄하거나 소각하여 파기합니다. <br />
          <br />
          <strong>
            제 8조(개인정보 자동 수집 장치의 설치∙운영 및 거부에 관한 사항){' '}
          </strong>
          <br />
          <br />
          시소은(는) 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고
          있습니다. <br />
          1. 관리적 조치 : 내부관리계획 수립․시행, 정기적 직원 교육 등 <br />
          2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템
          설치, 고유식별정보 등의 암호화, 보안프로그램 설치 <br />
          3. 물리적 조치 : 호스팅 서버 위치한 곳 등의 접근통제 <br />
          <br />
          <strong>제 9조(개인정보의 안전성 확보조치)</strong>
          <br />
          <br />
          시소은(는) 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를
          저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다.
          <br />
          ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터
          브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의
          하드디스크에 저장되기도 합니다.
          <br />
          <br />
          가. 쿠키의 사용목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한
          방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게
          최적화된 정보 제공을 위해 사용됩니다.
          <br />
          나. 쿠키의 설치∙운영 및 거부 : 웹브라우저 상단의 도구 &gt; 인터넷 옵션
          &gt; 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다.
          <br />
          다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수
          있습니다.
          <br />
          <br />
          <strong>제 10조(개인정보의 안전성 확보조치)</strong>
          <br />
          <br />
          ① 시소은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보
          처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
          개인정보 보호책임자를 지정하고 있습니다. <br />
          <br />
          ▶ 개인정보 보호책임자 <br />
          성명 : 김은성 <br />
          직책 : 이사 <br />
          연락처 : 070-8882-4280
          <br />
          ※ 개인정보 보호 담당부서로 연결됩니다. <br />
          <br />
          ② 정보주체께서는 시소의 서비스(또는 사업)을 이용하시면서 발생한 모든
          개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
          보호책임자 및 담당부서로 문의하실 수 있습니다. 시소은(는) 정보주체의
          문의에 대해 지체없이 답변 및 처리해드릴 것입니다. <br />
          <br />
          <strong>제 11조(권익침해 구제방법)</strong>
          정보주체는 아래의 기관에 대해 개인정보 침해에 대한 피해구제, 상담 등을
          문의하실 수 있습니다. <br />
          <br />
          아래의 기관은 시소과는 별개의 기관으로서, 시소 의 자체적인 개인정보
          불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이
          필요하시면 문의하여 주시기 바랍니다
          <br />
          <br />
          ▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영) <br />
          - 소관업무 : 개인정보 침해사실 신고, 상담 신청 <br />
          - 홈페이지 : privacy.kisa.or.kr <br />
          - 전화 : (국번없이) 118 <br />
          - 주소 : (58324) 전남 나주시 진흥길 9(빛가람동 301-2) 3층
          개인정보침해신고센터
          <br />
          <br />
          ▶ 개인정보 분쟁조정위원회
          <br />
          - 소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결) <br />
          - 홈페이지 : www.kopico.go.kr <br />
          - 전화 : (국번없이) 1833-6972
          <br />
          - 주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
          <br />
          <br />
          ▶ 대검찰청 사이버범죄수사단 : 02-3480-3573 (www.spo.go.kr)
          <br />
          <br />
          ▶ 경찰청 사이버안전국 : 182 (http://cyberbureau.police.go.kr)
          <br />
          <br />
          <strong>제 12조(개인정보 처리방침 변경)</strong>
          <br />
          <br />
          ① 이 개인정보 처리방침은 2919년 8월 20일부터 적용됩니다. <br />
          ② 이전의 개인정보 처리방침은 아래에서 확인하실 수 있습니다.
          <br />
          - 2018. 6. 1 ~ 2019. 8. 19 적용 (클릭) <br />
        </div>
      </Comps.TermsWrapper>
    );
  }
}

export default PrivateTermPage;

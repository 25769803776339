// import { observer } from "mobx-react";
import { Spinner } from '@blueprintjs/core';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { EProjectStatus } from '../../../store/Dashboard/constants';
import { IProjectGroup } from '../../../store/Dashboard/models/ProjectGroup';

import {
  ExternalTools,
  ProjectBox as Comps,
  ProjectStatusTag,
} from '../../molecules/Dashboard';

interface Props {
  className?: string;
  item: IProjectGroup;
  isAlert: boolean;
}

const ProjectBox: FC<Props> = ({ className, item, isAlert }) => {
  const history = useHistory();
  const [dateStart, setDateStart] = useState<Date | null>(null);

  useEffect(() => {
    (async () => {
      if (!item.schedule) {
        await item.fetchSchedule();
        await item.fetchSprint();
      }
    })();
  }, []);

  useEffect(() => {
    if (item.schedule) {
      const { dateStart, dateEnd } = item.schedule;
      setDateStart(dateStart);
    }
  }, [item]);

  return (
    <Comps.Wrapper
      highlight={isAlert}
      active={!item.isEndStatus}
      className={className}
      onClick={() => history.push(`dashboard/${item.groupId}`)}
    >
      <Comps.Header>
        <Comps.ProjectId>{item.groupId}</Comps.ProjectId>
        {!item.isLoading && (
          <ProjectStatusTag
            className="project-status-tag"
            status={item.status}
          />
        )}
      </Comps.Header>

      <Comps.Body>
        {!item.isLoading ? (
          <>
            <Comps.Info>
              <Comps.ProjectName>{item.name}</Comps.ProjectName>

              {!item.isEndStatus &&
                item.status !== EProjectStatus.MATCHING &&
                item.status !== EProjectStatus.WAITING &&
                dateStart !== null && (
                  <>
                    <Comps.OngoingInfoRow>
                      <span>{moment(dateStart).format('YYYY.MM.DD')}</span>
                      <span>~</span>
                    </Comps.OngoingInfoRow>
                  </>
                )}

              {!item.isEndStatus && item.isHolding && (
                <Comps.HoldingRow>
                  <span>홀딩 남은 기간</span>
                  <span>{item.remainedHoldingDays}일</span>
                </Comps.HoldingRow>
              )}

              {item.isEndStatus && (
                <>
                  <Comps.EndInfoRow>
                    <span className="end">종료</span>
                    <span>
                      {item.dateComplete &&
                        moment(item.dateComplete).format('YYYY.MM.DD')}
                    </span>
                  </Comps.EndInfoRow>

                  {item.status === EProjectStatus.ENDHOLD && (
                    <Comps.EndInfoRow>
                      홀딩 {moment(item.dateHolding).format('YYYY.MM.DD')} ~{' '}
                      {moment(item.dueDateHold).format('YYYY.MM.DD')}
                    </Comps.EndInfoRow>
                  )}

                  {item.status === EProjectStatus.FLAWING && (
                    <Comps.EndInfoRow>
                      하자보수 {moment(item.dateFlawing).format('YYYY.MM.DD')} ~{' '}
                    </Comps.EndInfoRow>
                  )}

                  {item.status === EProjectStatus.KEEPING && (
                    <Comps.EndInfoRow>
                      유지보수 {moment(item.dateKeeping).format('YYYY.MM.DD')} ~
                    </Comps.EndInfoRow>
                  )}

                  {item.status === EProjectStatus.KEEP_ACCEPT && (
                    <Comps.EndInfoRow>
                      <span>접수 가능</span>
                      <span>{item.remainedKeepingDays}일 남음</span>
                    </Comps.EndInfoRow>
                  )}
                  {item.status === EProjectStatus.FLAW_ACCEPT && (
                    <>
                      <Comps.EndInfoRow>
                        <span>접수 가능</span>
                        <span>{item.remainedFlawingDays}일 남음</span>
                      </Comps.EndInfoRow>
                      <Comps.Button
                        onClick={e => {
                          e.stopPropagation();
                          window.open(
                            `https://www.notion.so/4426c23188b944f1a68a3c5e07d6a5e9`,
                            '_blank',
                          );
                        }}
                      >
                        하자보수 접수
                      </Comps.Button>
                    </>
                  )}
                </>
              )}
            </Comps.Info>

            <Comps.Footer>
              {!item.isEndStatus && (
                <ExternalTools
                  iconSize={34}
                  iconPadding={10}
                  iconSizeMobile={38}
                  iconPaddingMobile={12}
                  tools={item.externalTools}
                />
              )}

              {item.status === EProjectStatus.KEEP_ACCEPT && (
                <Comps.Button
                  onClick={e => {
                    e.stopPropagation();
                    window.open(
                      `https://www.notion.so/4426c23188b944f1a68a3c5e07d6a5e9`,
                      '_blank',
                    );
                  }}
                >
                  유지보수 접수
                </Comps.Button>
              )}
            </Comps.Footer>
          </>
        ) : (
          <Spinner />
        )}
      </Comps.Body>
    </Comps.Wrapper>
  );
};

export default ProjectBox;

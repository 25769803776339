import { Instance, types } from 'mobx-state-tree';
import { CalculateStoreModel } from './Calculate';
import { DashboardStoreModel } from './Dashboard';
import { FeaturedProjectStoreModel } from './EtcStore';
import { InspectionStoreModel } from './Inspection';
import { MyPageStoreModel } from './MyPage/MyPageStore';
import { NotificationStoreModel } from './NotificationStore';

import { ReceptionStoreModel } from './Reception/ReceptionStore';

import { TaskManagerStoreModel } from './SeesoBoard/store';
import { TaskFormStoreModel } from './SeesoBoard/taskFormStore';
import { SprintReportStoreModel } from './SprintReport';
import { UiStoreModel } from './UIStore';
import { UserStoreModel } from './UserStore';

export const AppStoreModel = types.model('AppStore', {
  receptionStore: types.optional(ReceptionStoreModel, {}),
  calculateStore: types.optional(CalculateStoreModel, {}),
  userStore: types.optional(UserStoreModel, {}),
  myPageStore: types.optional(MyPageStoreModel, {}),
  dashboardStore: types.optional(DashboardStoreModel, {}),
  sprintReportStore: types.optional(SprintReportStoreModel, {}),
  inspectionStore: types.optional(InspectionStoreModel, {}),
  notificationStore: types.optional(NotificationStoreModel, {}),
  etcStore: types.optional(FeaturedProjectStoreModel, {}),

  // 프로젝트보드
  boardStore: types.optional(TaskManagerStoreModel, {}),
  taskStore: types.optional(TaskFormStoreModel, {}),

  uiStore: types.optional(UiStoreModel, {}),
});

export interface AppStore extends Instance<typeof AppStoreModel> {}

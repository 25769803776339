import { types } from 'mobx-state-tree';
import { SubTaskModel } from '../models/SubTask';

export const SubTaskListFormModel = types
  .model('SubTaskListForm', {
    id: types.number,
    subTaskListId: types.string,
    taskId: types.string, //연관 테스크 아이디

    title: types.string,
    subTasks: types.array(SubTaskModel),

    order: types.integer,
    createdAt: types.string,
    updatedAt: types.string,
  })
  .actions(self => ({
    setTitle(value: string) {
      self.title = value;
    },
  }));

type SubTaskListFormType = typeof SubTaskListFormModel.Type;
export interface SubTaskListForm extends SubTaskListFormType {}

import React, { useState } from 'react';

export interface IAddSubChecklistProps {
  onAdd: (title: string) => void;
  onCancel?: () => void;
}

export const AddSubChecklist: React.SFC<IAddSubChecklistProps> = props => {
  const [title, setTitle] = useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      (e.target as HTMLInputElement).value &&
        props.onAdd((e.target as HTMLInputElement).value);
      props.onCancel && props.onCancel();
      setTitle('');
    }
    if (e.key === 'Escape') {
      props.onCancel && props.onCancel();
      setTitle('');
    }
  };
  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value &&
      props.onAdd((e.target as HTMLInputElement).value);
    props.onCancel && props.onCancel();
    setTitle('');
  };

  return (
    <li className="edit">
      <button type="button" className="btn-move">
        <span>메뉴이동</span>
      </button>
      <input type="checkbox" id="newSubChecklist" />
      <label htmlFor="newSubChecklist">{title}</label>
      <input
        autoFocus={true}
        type="text"
        value={title}
        onClick={e => {
          e.stopPropagation();
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
        // onBlur={ onBlur }
      />
    </li>
  );
};

import axios from 'axios';
import { flow, types } from 'mobx-state-tree';
import moment from 'moment';
import { getSprintReport } from '../apis/sprint';

import { EDAYS, END_STATUS, EProjectStatus } from '../constants';

import * as ExternalTool from './ExternalTool';
import * as InspectionRufreeRole from './InspectionRufreeRole';
import * as RufreeMatching from './RufreeMatching';
import * as Schedule from './Schedule';
import * as Sprint from './Sprint';
import * as SprintReport from './SprintReport';
import * as Worker from './Worker';

export interface IProjectGroupResponse {
  client: string;
  client_name: string;
  created_at: string;
  date_complete: string;
  date_holding: string;
  date_flawing: string;
  date_flawing_complete: string;
  date_keeping: string;
  date_keeping_complete: string;
  due_date_flaw: string;
  due_date_hold: string;
  due_date_keep: string;
  group_id: string;
  inspections: string;
  inspection_rufree_roles: InspectionRufreeRole.IInspectionRufreeRoleResponse[];
  is_holding: boolean;
  managers: Worker.IWorkerResponse[];
  name: string;
  rufree_matching: RufreeMatching.IRufreeMatchingResponse[];
  sprint: Sprint.ISprintResponse[];
  tool_links: ExternalTool.IExternalToolResponse[];
  updated_at: string;
}

export const mapper = (x: IProjectGroupResponse) => {
  return {
    client: x.client,
    clientName: x.client_name,
    createdAt: moment(x.created_at).toDate(),
    dateComplete: x.date_complete ? moment(x.date_complete).toDate() : null,
    dateHolding: x.date_holding ? moment(x.date_holding).toDate() : null,
    dateFlawing: x.date_flawing ? moment(x.date_flawing).toDate() : null,
    dateFlawingComplete: x.date_flawing_complete
      ? moment(x.date_flawing_complete).toDate()
      : null,
    dateKeeping: x.date_keeping ? moment(x.date_keeping).toDate() : null,
    dateKeepingComplete: x.date_keeping_complete
      ? moment(x.date_keeping_complete).toDate()
      : null,
    dueDateFlaw: x.due_date_flaw ? moment(x.due_date_flaw).toDate() : null,
    dueDateHold: x.due_date_hold ? moment(x.due_date_hold).toDate() : null,
    dueDateKeep: x.due_date_keep ? moment(x.due_date_keep).toDate() : null,
    externalTools: x.tool_links.map(t => ExternalTool.mapper(t)),
    groupId: x.group_id,
    inspection: x.inspections.length > 0 ? x.inspections[0] : '',
    inspectionRufreeRoles: x.inspection_rufree_roles.map(role =>
      InspectionRufreeRole.mapper(role),
    ),
    isHolding: x.is_holding,
    managers: x.managers,
    name: x.name,
    rufreeMatching: x.rufree_matching.map(matcing =>
      RufreeMatching.mapper(matcing),
    ),
    updatedAt: moment(x.updated_at).toDate(),
  };
};

/*
  - 검수서 필드 특이사항:
    서버로 부터 array 형태로 오지만, single 값으로 처리함. 중간에 기획 변경됨. 서버응답 필드명 'inspections'
  - 백앤드의 schedule + sprint -> schedule로 합침.
*/
export const ProjectGroupModel = types
  .model('ProjectGroup', {
    client: types.string,
    clientName: types.string,
    createdAt: types.Date,
    dateComplete: types.maybeNull(types.Date),
    dateHolding: types.maybeNull(types.Date),
    dateFlawing: types.maybeNull(types.Date),
    dateFlawingComplete: types.maybeNull(types.Date),
    dateKeeping: types.maybeNull(types.Date),
    dateKeepingComplete: types.maybeNull(types.Date),
    dueDateFlaw: types.maybeNull(types.Date),
    dueDateHold: types.maybeNull(types.Date),
    dueDateKeep: types.maybeNull(types.Date),
    externalTools: types.array(ExternalTool.ExternalToolModel),
    groupId: types.identifier,
    inspection: types.string,
    inspectionRufreeRoles: types.array(
      InspectionRufreeRole.InspectionRufreeRoleModel,
    ),
    isHolding: types.boolean,
    managers: types.array(Worker.WorkerModel),
    name: types.string,
    rufreeMatching: types.array(RufreeMatching.RufreeMatchingModel),
    schedule: types.maybeNull(Schedule.ScheduleModel),
    sprintReports: types.array(SprintReport.SprintReportModel),
    updatedAt: types.Date,

    isLoading: types.maybe(types.boolean),
  })
  .views(self => ({
    get inspectionURL() {
      // return `/inspection/${self.inspection}`;
      return `/inspections/ex_download_pdf?uuid=${self.inspection}`;
    },

    get remainedHoldingDays() {
      const days = moment(self.dueDateHold).diff(moment(), 'days');
      return days > 0 ? days : 0;
    },

    get remainedKeepingDays() {
      const days = moment(self.dueDateKeep).diff(moment(), 'days');
      return days > 0 ? days : 0;
    },

    get remainedFlawingDays() {
      const days = moment(self.dueDateFlaw).diff(moment(), 'days');
      return days > 0 ? days : 0;
    },

    get ongoingMatchings() {
      return self.rufreeMatching
        .filter(matching => matching.status === '제안중')
        .filter(
          matching => matching.status !== '취소' && !matching.matched_time,
        );
    },

    // get ongoingRufreeMatchings() {
    //   return self.rufreeMatching.filter(matching => matching.status === '제안중')
    // }
  }))
  .views(self => ({
    get status() {
      /*
       * return  순서 중요!!
       */
      // if(!self.schedule) {
      //   return EProjectStatus.NONE;
      // }

      const today = moment();

      // 종료 - 유지보수가 완료되면 자동 종료처리
      if (self.dateKeepingComplete) {
        return EProjectStatus.END;
      }

      if (self.dateComplete && self.isHolding) {
        return EProjectStatus.ENDHOLD;
      }

      // 종료 - 하자보수 진행중
      if (self.dateComplete && self.dateFlawing && !self.dateFlawingComplete) {
        return EProjectStatus.FLAWING;
      }
      // 종료 - 하자보수 접수 가능
      if (
        self.dateComplete &&
        !self.dateFlawing &&
        !self.dateKeeping &&
        moment(self.dateComplete).add(EDAYS.FLAW_ACCEPT, 'days').isAfter(today)
      ) {
        return EProjectStatus.FLAW_ACCEPT;
      }

      // 종료 - 유지보수 진행중
      if (self.dateComplete && self.dateKeeping && !self.dateKeepingComplete) {
        return EProjectStatus.KEEPING;
      }
      // 종료 - 유지보수 접수 가능
      if (
        self.dateComplete &&
        (self.dateFlawingComplete ||
          moment(self.dateComplete)
            .add(EDAYS.KEEP_ACCEPT, 'days')
            .isAfter(today))
      ) {
        return EProjectStatus.KEEP_ACCEPT;
      }

      // 종료
      if (self.dateComplete) {
        return EProjectStatus.END;
      }

      // 종료 - 장기 홀드
      if (self.isHolding && self.remainedHoldingDays < 1) {
        return EProjectStatus.ENDHOLD;
      }

      // 홀딩중
      if (self.isHolding) {
        return EProjectStatus.HOLDING;
      }

      if (self.schedule) {
        const firstSprint = self.schedule.firstSprint;

        // 진행중
        if (
          firstSprint &&
          firstSprint.dateStart &&
          moment(firstSprint.dateStart).isSameOrBefore(moment())
        ) {
          return EProjectStatus.ONGOING;
        }

        // 알유프리 매칭 중
        if (
          self.rufreeMatching.filter(
            rm => rm.status === '제안중' || rm.status === '매칭완료',
          ).length > 0 &&
          firstSprint &&
          (!firstSprint.dateStart ||
            moment(firstSprint.dateStart).isAfter(moment()))
        ) {
          return EProjectStatus.MATCHING;
        }
      }

      // 대기중
      return EProjectStatus.WAITING;
    },

    get isEndStatus() {
      const status = this.status;

      if (END_STATUS.includes(status)) {
        return true;
      }
      return false;
    },
  }))
  .views(self => ({
    get tipFilterRule() {
      let tipType: string = '';
      let tipFilters: string[] = [];
      const { status, isEndStatus } = self;

      // 진행중 프로젝트일 경우,
      if (status === EProjectStatus.ONGOING) {
        tipType = 'role';
        self.schedule?.ongoingSprints.forEach(sprint =>
          sprint.roles.forEach(
            role =>
              tipFilters.findIndex(r => r === role) < 0 &&
              tipFilters.push(role),
          ),
        );
      }
      // 종료된 프로젝트일 경우,
      if (isEndStatus) {
        tipType = 'role';
        self.schedule?.sprints.forEach(sprint =>
          sprint.roles.forEach(
            role =>
              tipFilters.findIndex(r => r === role) < 0 &&
              tipFilters.push(role),
          ),
        );
      }
      // 대기중,매칭중 프로젝트일 경우,
      if (
        status === EProjectStatus.WAITING ||
        status === EProjectStatus.MATCHING
      ) {
        tipType = 'category';
        tipFilters = ['Planning'];
      }
      // 홀드 프로젝트일 경우,
      if (status === EProjectStatus.HOLDING) {
        tipType = 'category';
        tipFilters = ['General'];
      }

      return { tipType: tipType, tipFilters: tipFilters };
    },
  }))
  .actions(self => ({}))
  .actions(self => {
    const fetchSchedule = flow(function* () {
      self.isLoading = true;

      try {
        const { data }: { data: Schedule.IScheduleResponse } = yield axios.get(
          `/projectGroups/${self.groupId}/schedule`,
        );

        const schedule = Schedule.ScheduleModel.create(
          Schedule.mapSchedule(data),
        );
        self.schedule = schedule;

        self.schedule.initSprintRufreesByInspection(self.inspectionRufreeRoles);
      } catch (e) {
        throw e;
      } finally {
        self.isLoading = false;
      }
    });

    const fetchSprint = flow(function* () {
      self.isLoading = true;

      if (self.schedule) {
        try {
          const { data }: { data: Sprint.ISprintResponse[] } = yield axios.get(
            `/projectGroups/${self.groupId}/sprints`,
          );

          self.schedule.updateRufreeOfSprint(Sprint.mapper(data));
          Sprint.mapper(data).map(
            sprint => self.schedule && self.schedule.updateSprint(sprint),
          );
        } catch (e) {
          throw e;
        } finally {
          self.isLoading = false;
        }
      }
    });

    const fetchSprintReport = flow(function* () {
      self.isLoading = true;
      if (self.schedule) {
        try {
          const data = yield getSprintReport(self.groupId);
          self.sprintReports.replace(SprintReport.mapper(data));
        } catch (e) {
          throw e;
        } finally {
          self.isLoading = false;
        }
      }
    });

    return {
      fetchSchedule,
      fetchSprint,
      fetchSprintReport,
    };
  });

type ProjectGroupType = typeof ProjectGroupModel.Type;
export interface IProjectGroup extends ProjectGroupType {}

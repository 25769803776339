import React from 'react';

export interface IMyTaskButton {
  selected: boolean;
  handleClick?: () => void;
}

export const MyTaskButton: React.SFC<IMyTaskButton> = props => {
  let className = 'btn-onlym-view';
  className += props.selected ? ' selected' : '';

  return (
    <button type="button" className={className} onClick={props.handleClick}>
      내 태스크만 보기
    </button>
  );
};

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import AddSVG from '../../../static/icons/icon-file-add.svg';
import RemoveSVG from '../../../static/icons/icon-file-minus.svg';

const Wrapper = styled.div`
  margin: 30px;

  @media only screen and (max-width: ${PC}) {
    margin: 0px;
    margin-bottom: 70px;
    width: 100%;
    min-width: 328px;
  }
`;
const ViewButton = styled.div<{ highlight?: boolean }>`
  margin-top: 20px;
  width: 501px;
  height: 48px;
  border-radius: 100px;

  line-height: 48px;
  background-color: ${p =>
    p.highlight ? PALETTE['purpleish-blue'] : PALETTE['white']} !important;

  cursor: ${p => (p.highlight ? 'pointer' : 'default')};
  color: ${p => (p.highlight ? PALETTE['white'] : PALETTE['black'])} !important;

  box-shadow: 1px 3px 8px 6px #cccccc;

  font-family: NotoSans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
`;

const ItemCard = styled.div<{ new?: boolean }>`
  width: 580px;
  min-height: 258px;
  border-radius: 10px;
  // padding: 25px 35px 0px 35px;
  border: solid 1px
    ${p =>
      p.new ? PALETTE['purpleish-blue'] : PALETTE['very-light-pink-three']};
  // background-color: ${PALETTE['white']};

  font-size: 15px;
  color: ${PALETTE['black-two']};

  @media only screen and (max-width: ${PC}) {
    // width: 328px;
    width: 100%;
    min-height: 225px;
    // padding: 20px;
    // margin-top: 64px;
  }
`;

const ProjectIds = styled.span`
  @media only screen and (max-width: ${PC}) {
    display: none;
  }
`;
const ProjectMobileIds = styled.span`
  display: none;
  margin-top: 5px;
  @media only screen and (max-width: ${PC}) {
    display: block;
  }
`;

const MainSection = styled.div`
  border-radius: 10px;
  // background: ${PALETTE['white']};
  &:hover {
    background: ${PALETTE['pale-grey']};
  }
  padding: 30px 25px 30px 25px;
  cursor: pointer;

  @media only screen and (max-width: ${PC}) {
    padding: 20px;
    // margin-top: 64px;
  }
`;
const TitleRow = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: 530px;
  margin-top: 12px;
  height: 33px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: ${PC}) {
    font-size: 18px;
    width: 100%;
    height: 100%;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    white-space: normal;
    word-break: break-all;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;

const ClientRow = styled.div`
  // width: 123px;
  margin-top: 6px;
  height: 27px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: ${PC}) {
    height: 24px;
    font-size: 16px;
  }
`;

const PublishDateRow = styled.div`
  margin-top: 15px;
  // margin-bottom: 30px;
  height: 20px;
  @media only screen and (max-width: ${PC}) {
    height: 19px;
    margin-bottom: 0px;
  }
`;

const PublishDateTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: left;
  color: ${PALETTE['brown-grey']} @media only screen and (max-width: ${PC}) {
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
`;
const PublishDateValue = styled.span`
  margin-left: 15px;
  width: 112px;
  height: 20px;
  // font-family: NotoSansCJKkr;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['brown-grey']};
`;

const ReferenceSection = styled.div`
  padding: 20px 25px 20px 25px;
  border-top: 1px solid ${PALETTE['very-light-pink-three']};
  @media only screen and (max-width: ${PC}) {
    padding: 16px 20px 16px 20px;
  }
`;
const ReferenceBar = styled.div`
  height: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const NoDataMsg = styled.div`
  margin-top: 16px;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['greyish-brown']};
`;
const NewDataMsg = styled.div<{
  show?: boolean;
}>`
  display: ${p => (p.show ? 'block' : 'none')};

  margin-top: 30px;
  cursor: pointer;

  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  @media only screen and (max-width: ${PC}) {
    margin-top: 15px;
  }
`;
const ReferenceTextSection = styled.div`
  // width: 74px;
  // height: 23px;
  // font-family: .AppleSystemUIFont;
`;
const ReferenceTitle = styled.span`
  // text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: left;
  color: ${PALETTE['purpleish-blue']};

  @media only screen and (max-width: ${PC}) {
    height: 22px;
    font-size: 15px;
  }
`;
const ReferenceCount = styled.span`
  margin-left: 6px;
  // text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  // text-align: left;
  color: ${PALETTE['purpleish-blue']};
  @media only screen and (max-width: ${PC}) {
    font-size: 15px;
    height: 22px;
  }
`;
const ReferenceLinkSection = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  @media only screen and (max-width: ${PC}) {
    margin-top: 16px;
    margin-left: 0px;
  }
`;
const ReferenceLinkRow = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: ${PC}) {
    display: inline;
  }
`;
const ReferenceLinkTitle = styled.a`
  width: 238px;
  height: 19px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};
  @media only screen and (max-width: ${PC}) {
    width: 288px;
  }
`;

const ReferenceLinkDate = styled.div`
  width: 111px;
  height: 19px;
  // font-family: NotoSansCJKkr;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${PALETTE['brownish-grey']} @media only screen and (max-width: ${PC}) {
    margin-top: 5px;
  }
`;

const TopComment = styled.div`
  // width: 163px;
  margin-left: 20px;
  margin-bottom: 10px;
  height: 18px;
  // font-family: NotoSansCJKkr;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${PALETTE['purpleish-blue']};
`;

const NewReferenceButton = styled.div`
  width: 24px;
  height: 24px;
  // margin-top: 24px;
  // margin-bottom: 21px;
  cursor: pointer;

  display: inline-block;
  @media only screen and (max-width: ${PC}) {
    width: 18px;
    height: 18px;
  }
`;
const NewReferenceInput = styled.div`
  margin-top: 15px;
  margin-bottom: 14px;
  display: inline-block;
  margin-left: 15px;
  // width: 545px;
  @media only screen and (max-width: ${PC}) {
    margin-left: 10px;
  }
  // height: 40px;
`;

const AddButton = styled.div`
  background: url('${AddSVG}') no-repeat center center;
  position: absolute;
  top: 23px;
  left: 0px;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer;

  @media only screen and (max-width: ${PC}) {
    background-size: 18px 18px;
    top: 26px;
    width: 18px;
    height: 18px;
  }
`;

const RemoveButton = styled.div`
  background: url('${RemoveSVG}') no-repeat center center;
  position: absolute;
  top: 23px;
  left: 0px;
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  cursor: pointer;

  @media only screen and (max-width: ${PC}) {
    background-size: 18px 18px;
    top: 26px;
    width: 18px;
    height: 18px;
  }
`;

const NewReferenceButtonSection = styled.div`
  display: flex;
  // justifyContent: flex-end;
  // margin-top: 30px;
  position: absolute;
  bottom: 30px;
  right: 40px;
  @media only screen and (max-width: ${PC}) {
    // bottom: -30px;
    right: 48px;
    // margin-left: 28px;
  }
`;

const UploadFileButtonSection = styled.div`
  margin-left: 10px;
  display: inline-block;
  vertical-align: 11%;
  @media only screen and (max-width: ${PC}) {
    margin-left: 28px;
    display: block;
  }
`;

/*

const ItemCardRow = styled.div`
  height: 33px;
  margin-top: 20px;
  display flex;

  @media only screen and (min-width: ${ PC }) {
    span + span {
      margin-left: 20px;
    }
  }

  @media only screen and (max-width: ${ PC }) {
    display block;
    margin-top: 14px;
  }
`;

const ItemCard = styled.div<{ highlight?: boolean }>`
  line-height: 27px;
  font-size: ${ p => p.highlight ? '22px' : '18px' };
  color: ${ PALETTE['black-two']};

  &:first-child::before {
    padding: 0px;
    content: '';
  }

  span:last-child {
    font-weight: ${ p => p.highlight ? 'bold' : 'normal' };
    color: ${ p => p.highlight ? PALETTE['purpleish-blue'] : '#000000' };
    @media only screen and (max-width: ${ PC }) {
      display:block;
      width: calc(100% - 63px);
      text-align: right;
    }
  }
  span:last-child::before {
    content: '₩ '
  }
  span.highlight {
    color: ${ PALETTE['color-red'] };
  }

  @media only screen and (min-width: ${ PC }) {
    &::before {
      padding: 50px;
      content: '|';
    }
  }
  @media only screen and (max-width: ${ PC }) {
    display: flex;
    line-height: 26px;
    font-size: 16px;
    font-weight: 500;

    & + & {
      padding-top: 12px;
    }

    span:first-child {
      width: 63px;
    }
  }
`;
*/

export {
  // ItemCard,
  // ItemCardRow,
  ViewButton,
  ItemCard,
  ProjectIds,
  ProjectMobileIds,
  MainSection,
  TitleRow,
  ClientRow,
  PublishDateRow,
  ReferenceSection,
  ReferenceBar,
  NoDataMsg,
  NewDataMsg,
  ReferenceTextSection,
  ReferenceTitle,
  ReferenceCount,
  ReferenceLinkSection,
  ReferenceLinkRow,
  ReferenceLinkTitle,
  ReferenceLinkDate,
  PublishDateTitle,
  PublishDateValue,
  TopComment,
  Wrapper,
  NewReferenceButton,
  NewReferenceInput,
  NewReferenceButtonSection,
  UploadFileButtonSection,
  AddButton,
  RemoveButton,
};

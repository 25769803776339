import { Intent } from '@blueprintjs/core';
import { Input } from 'antd';
import { inject, observer } from 'mobx-react';
import qs from 'qs';
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { AppStore } from '../../../store/AppStore';

import { AppToaster } from '../../molecules/MyPage/AppToaster';
import { Signup as Comps } from '../../organisms/Etc';
import AgreementPage from './AgreementPage';
import PrivateTermPage from './PrivateTermPage';

interface SingUpState {
  name: string;
  email: string;
  pw1: string;
  pw2: string;
  registeredEmailError: boolean;
  activeUserTerm: boolean;
  activatePrivateTerm: boolean;
  userTerm: boolean;
  privateTerm: boolean;
  errorMsg: string;
}
interface DefaultProps extends RouteComponentProps {
  history: any;
  location: any;
}
interface InjectedProps extends DefaultProps {
  appStore: AppStore;
}

@inject('appStore')
@observer
class SignupPage extends Component<InjectedProps> {
  state: SingUpState = {
    name: '',
    email: '',
    pw1: '',
    pw2: '',
    registeredEmailError: false,
    activeUserTerm: false,
    activatePrivateTerm: false,
    userTerm: false,
    privateTerm: false,
    errorMsg: '',
  };
  get injected() {
    return this.props as InjectedProps;
  }

  checkError() {
    if (this.state.name.length < 1) {
      this.setState({
        errorMsg: '이름을 입력해주세요.',
      });
      return false;
    }

    const regexEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (this.state.email.length < 1 || !regexEmail.test(this.state.email)) {
      this.setState({
        errorMsg: '올바른 이메일 주소를 입력해주세요.',
      });
      return false;
    }
    if (!this.state.userTerm) {
      this.setState({
        errorMsg: '서비스이용약관에 동의해주세요.',
      });
      return false;
    }
    if (!this.state.privateTerm) {
      this.setState({
        errorMsg: '개인정보취급방침에 동의해주세요.',
      });
      return false;
    }
    if (this.state.pw1.length < 1) {
      this.setState({
        errorMsg: '비밀번호를 입력 해주세요.',
      });
      return false;
    }
    if (this.state.pw1 !== this.state.pw2) {
      this.setState({
        errorMsg: '비밀번호가 동일하지 않습니다. 다시 확인해 주세요.',
      });
      return false;
    }

    this.setState({
      errorMsg: '',
    });
    return true;
  }

  validatePw1 = () => {
    const re = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    if (!re.test(this.state.pw1)) {
      return false;
    }
    return true;
  };

  validatePw2 = () => {
    return this.state.pw2.length >= 8 && this.state.pw1 === this.state.pw2;
  };

  validPwError = () => {
    if (!!this.state.pw1) {
      if (this.validatePw1()) {
        if (!!this.state.pw2) {
          if (this.validatePw2()) {
            return this.setState({
              errorMsg: '',
            });
          } else {
            return this.setState({
              errorMsg: '비밀번호가 동일하지 않습니다. 다시 확인해 주세요.',
            });
          }
        }
        return this.setState({
          errorMsg: '',
        });
      } else {
        return this.setState({
          errorMsg:
            '비밀번호는 영문,숫자,특수문자(!@$%^&* 만 허용)를 조합하여 8자 이상 입력해주세요.',
        });
      }
    }
  };

  handleSubmit = async (e: any) => {
    e.preventDefault();
    const formSuccess = this.checkError();
    const { userStore } = this.injected.appStore;
    if (formSuccess) {
      const signupData = {
        email: this.state.email,
        password: this.state.pw1,
        name: this.state.name,
        phone: '01000000000',
      };
      try {
        await userStore.signUp(signupData);

        const redirectTo: any = qs.parse(this.props.location.search, {
          ignoreQueryPrefix: true,
        }).redirect_to;

        let redirectUrl: string;
        redirectTo ? (redirectUrl = redirectTo) : (redirectUrl = '');
        AppToaster.show({
          message: '시소에 가입하신 걸 환영합니다.',
          intent: Intent.SUCCESS,
        });
        await this.props.history.push(`/${redirectUrl}`);
      } catch (e) {
        if (e.response.data.email) {
          this.setState({
            errorMsg:
              '이미 가입된 이메일입니다. 다른 이메일로 가입부탁드립니다.',
          });
        }
        throw e;
      }
    }
  };

  handleSignUserTerm = (e: any) => {
    e.preventDefault();
    this.setState({
      activeUserTerm: !this.state.activeUserTerm,
    });
  };
  handlePrivateTerm = (e: any) => {
    e.preventDefault();
    this.setState({
      activatePrivateTerm: !this.state.activatePrivateTerm,
    });
  };

  render() {
    return (
      <Comps.SignUpPageWrapper>
        <Comps.SignUpLogo>
          <img
            src="https://seeso-cpms.s3-ap-northeast-1.amazonaws.com/uploads/seeso-logo-black.png"
            alt="seeso로고"
          />
        </Comps.SignUpLogo>
        <Comps.SignupPageWrap>
          <div>
            <form>
              <fieldset>
                <ul>
                  <Comps.ListFormItem>
                    <Input
                      type="text"
                      placeholder="이름"
                      value={this.state.name}
                      onChange={e => {
                        this.setState({ name: e.target.value });
                      }}
                    />
                  </Comps.ListFormItem>
                  <Comps.ListFormItem>
                    <Input
                      value={this.state.email}
                      onChange={e => {
                        this.setState({ email: e.target.value });
                      }}
                      type="email"
                      placeholder="이메일"
                    />
                  </Comps.ListFormItem>
                  <Comps.ListFormItem>
                    <Input
                      value={this.state.pw1}
                      onChange={e => {
                        this.setState({ pw1: e.target.value }, () =>
                          this.validPwError(),
                        );
                      }}
                      type="password"
                      id="pw1"
                      placeholder="비밀번호"
                    />
                  </Comps.ListFormItem>
                  <Comps.ListFormItem>
                    <Input
                      value={this.state.pw2}
                      onChange={e => {
                        this.setState({ pw2: e.target.value }, () =>
                          this.validPwError(),
                        );
                      }}
                      type="password"
                      id="pwConfirm"
                      placeholder="비밀번호 확인"
                    />
                  </Comps.ListFormItem>
                </ul>
                {/* {registeredEmailError ? <div style={{color: '#ff3333', paddingTop: '5px'}}>이미 가입된 계정입니다.</div> : ''} */}
                <Comps.CheckFormItems>
                  <div
                    className={
                      this.state.activeUserTerm
                        ? 'checkFormItem1 active'
                        : 'checkFormItem'
                    }
                  >
                    <p>
                      <input
                        type="checkbox"
                        id="userTerm"
                        checked={this.state.userTerm}
                        onClick={() => {
                          this.setState({ userTerm: !this.state.userTerm });
                        }}
                      />
                      <label htmlFor="userTerm">
                        이용약관<span> (필수)</span>
                      </label>
                      <button
                        onClick={e => this.handleSignUserTerm(e)}
                      ></button>
                    </p>
                    <Comps.AgreeBox
                      style={
                        this.state.activeUserTerm
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <AgreementPage />
                    </Comps.AgreeBox>
                  </div>
                  <div
                    className={
                      this.state.activatePrivateTerm
                        ? 'checkFormItem2 active'
                        : 'checkFormItem'
                    }
                  >
                    <p>
                      <input
                        type="checkbox"
                        id="privateTerm"
                        checked={this.state.privateTerm}
                        onClick={() => {
                          this.setState({
                            privateTerm: !this.state.privateTerm,
                          });
                        }}
                      />
                      <label htmlFor="privateTerm">
                        개인정보취급방침<span> (필수)</span>
                      </label>
                      <button onClick={e => this.handlePrivateTerm(e)}></button>
                    </p>
                    <Comps.AgreeBox
                      style={
                        this.state.activatePrivateTerm
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <PrivateTermPage />
                    </Comps.AgreeBox>
                  </div>
                </Comps.CheckFormItems>
                <p className="errorMsg">{this.state.errorMsg}</p>
                <Comps.CheckFormBtn
                  onClick={e => this.handleSubmit(e)}
                  type="submit"
                >
                  <strong>가입하기</strong>
                </Comps.CheckFormBtn>
              </fieldset>
            </form>
          </div>
        </Comps.SignupPageWrap>
      </Comps.SignUpPageWrapper>
    );
  }
}

export default SignupPage;

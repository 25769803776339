import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';

import { ICalculateStore } from '../../../store/Calculate';

import { VAT } from '../../../store/Calculate/constants';

import { Calculate as Comps } from '../../molecules/Calculate';
import { DepositSummary, DetailDialog } from '../../organisms/Calculate';

interface Props {
  className?: string;
  store: ICalculateStore;
}

const Deposit: FC<Props> = observer(props => {
  const { store } = props;
  const { includeVAT } = store;

  const [isDetailAsc, setIsDetailAsc] = useState(true);
  // const [ isIncludeVAT, setIsIncludeVAT ] = useState(true);
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  const { deposited, shortage, payoutSum } = store;
  const balance = deposited - payoutSum;

  return (
    <Comps.Section>
      <Comps.SectionHeader>
        <div>입금 / 잔액 현황</div>

        <Comps.VATCheckbox
          include={includeVAT}
          handleChange={() => store.setIncludeVAT(!includeVAT)}
        />
      </Comps.SectionHeader>

      <DepositSummary
        isLoading={store.isLoading}
        shortage={includeVAT ? shortage * VAT : shortage}
        balance={balance > 0 ? (includeVAT ? balance * VAT : balance) : 0}
        total={includeVAT ? deposited * VAT : deposited}
      />

      <Comps.DetailPopup
        className="detail-popup"
        onClick={() => setIsDetailOpen(true)}
      >
        정산 내역 상세
      </Comps.DetailPopup>

      <DetailDialog
        client={store.client}
        isOpen={isDetailOpen}
        isAsc={isDetailAsc}
        baseDate={store.baseDate}
        calculates={store.calculates}
        shortage={shortage}
        balance={balance}
        total={deposited}
        depositSum={store.depositSum}
        payoutSum={store.payoutSum}
        handleClose={() => setIsDetailOpen(false)}
        handleReverse={() => {
          store.reverseCalculatesByDate();
          setIsDetailAsc(!isDetailAsc);
        }}
      />
    </Comps.Section>
  );
});

export default Deposit;

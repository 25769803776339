import React from 'react';

export interface IAssigneeProps {
  id: number;
  name: string;
  email: string;
  value: string;
  selected: boolean;
  prefix?: string;

  isDisabled?: boolean;

  onChange: (checked: boolean) => void;
}
export const Assignee: React.SFC<IAssigneeProps> = props => (
  <li>
    <input
      type="checkbox"
      id={`${props.prefix ? props.prefix : 'as'}_${props.value}`}
      value={props.value}
      checked={props.selected}
      disabled={props.isDisabled}
      onClick={e => e.stopPropagation()}
      onChange={e => props.onChange(e.target.checked)}
    />
    <label htmlFor={`${props.prefix ? props.prefix : 'as'}_${props.value}`}>
      {props.name}
    </label>
  </li>
);

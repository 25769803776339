import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IReception } from '../../../store/Reception/ReceptionModel';
import { ReceptionList as Comps } from '../../molecules/Receptions';

interface ReceptionsCardProps {
  reception: IReception;
  handleCancelPopup: any;
  handleCardClick: (reception: IReception) => void;
  notiReceptions: any;
}

export const getStatusText = (status: string) => {
  switch (status) {
    case 'pending':
      return '접수 완료';
    case 'accepted':
      return '승인됨';
    case 'rejected':
      return '거절됨';
    case 'canceled':
      return '의뢰 취소';
    default:
      return '알수없음';
  }
};

export const getStatusDescription = (status: string) => {
  switch (status) {
    case 'pending':
      return '접수 후 답변까지 2~3일(영업일 기준)정도 소요됩니다.';
    case 'accepted':
      return '의뢰서가 승인되었습니다. 시소 담당자가 2~3일 내로 연락드릴 예정입니다.';
    case 'rejected':
      return '프로젝트를 시작하기에 자료가 충분하지 않습니다. 검토 후 재접수해주세요.';
    case 'canceled':
      return '취소된 의뢰내역 입니다. 동일한 내용으로 의뢰를 희망한다면, 새로 접수해주세요.';
    default:
      return '';
  }
};

const ReceptionsCardBox: FC<ReceptionsCardProps> = observer(props => {
  const { reception, handleCancelPopup, handleCardClick, notiReceptions } =
    props;
  const [hovered, setHovered] = useState(false);
  const toggleHover = (val: boolean) => setHovered(val);
  const history = useHistory();

  const clsName: string = notiReceptions.includes(reception.id.toString())
    ? `highlighted ${reception.status}`
    : `${reception.status}`;

  return (
    <Comps.ReceptionCardWrapper>
      <Comps.ReceptionCard
        className={hovered ? `active ${clsName}` : `${clsName}`}
        onMouseEnter={() => {
          toggleHover(true);
        }}
        onMouseLeave={() => {
          toggleHover(false);
        }}
        onClick={() => {
          handleCardClick(reception);
        }}
      >
        <div className="summaryDescription">
          {getStatusDescription(reception.status)}
        </div>
        <div className="summaryInfo">
          <span className={`status ${reception.status}`}>
            {getStatusText(reception.status)}
          </span>
          <div className="applyInfo">
            <span>접수번호</span>
            {reception.receptionNumber}
          </div>
        </div>
        <h4>{reception.title}</h4>
        <div className="classInfo">
          <div>
            <span className="infoTitle">소속</span>
            <span className="infoDesc">{reception.affiliation}</span>
          </div>
          <div>
            <span className="infoTitle">의뢰자</span>
            <span className="infoDesc">{reception.receptionistName}</span>
          </div>
        </div>
        <div className="dateInfo">
          <div>
            <span className="dateTitle">접수일</span>
            <span className="dateDesc">{reception.dateRegisterString}</span>
          </div>
          {reception.status !== 'canceled' ? (
            <div>
              <span className="dateTitle">미팅 희망 일시</span>
              <span className="dateDesc">{reception.dateMeetingString}</span>
            </div>
          ) : (
            <div>
              <span className="dateTitle">의뢰 취소일</span>
              <span className="dateDesc">{reception.cancelDateString}</span>
            </div>
          )}
        </div>
      </Comps.ReceptionCard>
      <div className="ReceptionCancelBtnWrapper">
        <div className="ReceptionCancelBtnRow">
          {reception.status == 'pending' || reception.status == 'accepted' ? (
            <Comps.ReceptionCancelBtn
              onClick={() => handleCancelPopup(reception.id)}
            >
              <span>의뢰 취소</span>
            </Comps.ReceptionCancelBtn>
          ) : (
            <></>
          )}
        </div>
      </div>
    </Comps.ReceptionCardWrapper>
  );
});

export default ReceptionsCardBox;

import { Spinner } from '@blueprintjs/core';
import 'antd/es/modal/style/css';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import '../../../static/css/modal/reception-modal.css';
import { AppStore } from '../../../store/AppStore';
import { ERecType } from '../../../store/Reception/constants';

import { APP_NAME } from '../../../utils/firebase/push-notification';
import { PayoutFilter as _Comps } from '../../molecules/Calculate';
import {
  Reception as styles,
  ReceptionList as Comps,
} from '../../molecules/Receptions';
import ReceptionsList from '../../organisms/Receptions/ReceptionsList';
import NoReceptionPage from './NoReceptionPage';

interface Props {}

interface InjectedProps extends Props {
  appStore: AppStore;
}

@inject('appStore')
@observer
class ReceptionList extends Component<InjectedProps> {
  @observable readyToShow: boolean = false;

  get injected() {
    return this.props as InjectedProps;
  }
  get receptionStore() {
    return this.injected.appStore.receptionStore;
  }
  get notificationStore() {
    return this.injected.appStore.notificationStore;
  }

  async componentDidMount() {
    try {
      await this.receptionStore.fetchReceptions();
    } catch (e) {
    } finally {
      this.readyToShow = true;
    }
  }

  handleCardClick = async (reception: any) => {
    const recptionId = reception.id.toString();
    if (this.notificationStore.receptionIds.includes(recptionId)) {
      await this.notificationStore.readNotification(
        APP_NAME.project_reception,
        recptionId,
      );
      await this.notificationStore.receptionIds;
    }
    window.open(`/receptions/${reception.id}`);
  };

  handleFilter = (val: string) => {
    this.receptionStore.setFilterReception(val);
  };

  render() {
    const { sortedReceptions, setFilterReception, filterReception } =
      this.receptionStore;

    if (!this.readyToShow) {
      return <Spinner />;
    } else if (sortedReceptions.length > 0 || filterReception !== '모두 보기') {
      return (
        <styles.ReceptionWrapper>
          <Comps.TitleWrapper>
            <h2>프로젝트 의뢰 내역</h2>
            <_Comps.DivFilter
              title=""
              value={filterReception}
              options={Object.values(ERecType)}
              handleChange={(value: string) => this.handleFilter(value)}
            />
            <div className="emailWrap">
              <a href="mailto:partner@seeso.kr">의뢰 접수 관련 문의</a>
            </div>
          </Comps.TitleWrapper>
          <ReceptionsList
            receptionStore={this.receptionStore}
            receptions={sortedReceptions}
            handleCardClick={this.handleCardClick}
            notiReceptions={this.notificationStore.receptionIds}
          />
        </styles.ReceptionWrapper>
      );
    } else {
      return <NoReceptionPage />;
    }
  }
}

export default ReceptionList;

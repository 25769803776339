import moment from 'moment';
import React, { useState } from 'react';

export interface IFileProps {
  isEditing?: boolean;
  id: number;
  filename: string;
  file: string;
  date: string;

  onChangeFilename?: (filename: string) => void;
  onDelete?: () => void;
}

export const File: React.SFC<IFileProps> = props => {
  const fname = props.filename.replace(/\.[^/.]+$/, '');
  const extension = /[^.]+$/.exec(props.filename);

  const [isEditing, setIsEditing] = useState(props.isEditing);
  const [name, setName] = useState(fname);

  const save = (value: string) => {
    if (value !== fname) {
      props.onChangeFilename && props.onChangeFilename(`${name}.${extension}`);
    }

    setName(value);
    setIsEditing(false);
  };
  const onClickFile = () => window.open(props.file, '_blank');
  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    save((e.target as HTMLInputElement).value);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      save((e.target as HTMLInputElement).value);
    }

    if (e.key === 'Escape') {
      setName(fname);
      setIsEditing(false);
    }
  };

  return (
    <li className={`${isEditing ? 'edit' : ''}`} onClick={onClickFile}>
      <i>
        <span>파일업로드</span>
      </i>
      <div>
        <strong>
          {isEditing ? (
            <span>
              <input
                autoFocus={true}
                type="text"
                value={name}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onClick={e => e.stopPropagation()}
              />
              {`.${extension}`}
            </span>
          ) : (
            props.filename
          )}
        </strong>

        {props.date && (
          <span>{moment(props.date).format('YYYY/MM/DD hh:mm A')}</span>
        )}
      </div>

      {props.id > 0 && (
        <span className="btn-md">
          <button
            type="button"
            className="modify"
            onClick={e => {
              e.stopPropagation();
              setIsEditing(true);
            }}
          >
            <span>수정</span>
          </button>
          <button
            type="button"
            className="delete"
            onClick={e => {
              e.stopPropagation();
              props.onDelete && props.onDelete();
            }}
          >
            <span>삭제</span>
          </button>
        </span>
      )}
    </li>
  );
};

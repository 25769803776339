import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';

import GdriveSVG from '../../../static/icons/icon-ggdrive.svg';
import GhangoutSVG from '../../../static/icons/icon-gghangout.svg';
import GithubSVG from '../../../static/icons/icon-github.svg';
import TrelloSVG from '../../../static/icons/icon-projectboard.svg';
import SlackSVG from '../../../static/icons/icon-slack.svg';
import ZeplinSVG from '../../../static/icons/icon-zeplin.svg';

import {
  EExternalToolType,
  IExternalTool,
} from '../../../store/Dashboard/models/ExternalTool';

export const ExternalToolsWrapper = styled.div`
  display: flex;
`;
export const ToolWrapper = styled.div<{
  iconSize: number;
  iconPadding: number;
  iconSizeMobile: number;
  iconPaddingMobile: number;
  isActive: boolean;
  svg: string;
}>`
  width: ${p => p.iconSize}px;
  height: ${p => p.iconSize}px;
  background-color: ${PALETTE['button-style']};

  cursor: pointer;
  display: ${p => (p.isActive ? 'block' : 'none')};
  background: url(${p => p.svg}) no-repeat center center;

  margin-right: ${p => p.iconPadding}px;
  &:last-child {
    margin-right: 0px;
  }

  @media only screen and (max-width: ${PC}) {
    width: ${p => p.iconSizeMobile}px;
    height: ${p => p.iconSizeMobile}px;
    margin-right: ${p => p.iconPaddingMobile}px;
  }
`;

const ExternalTools: FC<{
  className?: string;
  iconSize: number;
  iconPadding: number;
  iconSizeMobile: number;
  iconPaddingMobile: number;
  tools: IExternalTool[];
}> = ({
  className,
  iconSize,
  iconPadding,
  iconSizeMobile,
  iconPaddingMobile,
  tools,
}) => {
  return (
    <ExternalToolsWrapper>
      {tools.map((tool: IExternalTool, i: number) => {
        let svg = TrelloSVG;

        switch (tool.tool) {
          case EExternalToolType.GDRIVE:
            svg = GdriveSVG;
            break;
          case EExternalToolType.GITHUP:
            svg = GithubSVG;
            break;
          case EExternalToolType.SLACK:
            svg = SlackSVG;
            break;
          case EExternalToolType.TRELLO:
            svg = TrelloSVG;
            break;
          case EExternalToolType.ZEPLIN:
            svg = ZeplinSVG;
            break;
          case EExternalToolType.GHANGOUT:
            svg = GhangoutSVG;
            break;
          default:
        }

        return (
          <ToolWrapper
            key={i}
            iconSize={iconSize}
            iconPadding={iconPadding}
            iconSizeMobile={iconSizeMobile}
            iconPaddingMobile={iconPaddingMobile}
            isActive={!!tool.url}
            svg={svg}
            onClick={e => {
              // e.preventDefault();
              e.stopPropagation();
              window.open(`${tool.url}`, '_blank');
            }}
          />
        );
      })}
    </ExternalToolsWrapper>
  );
};

export default ExternalTools;

import React, { useEffect, useState } from 'react';

export interface IKeywordFieldProps {
  value: string;
  handleSearch: (search: string) => void;
}

export const KeywordField: React.SFC<IKeywordFieldProps> = props => {
  const [keyword, setKeyword] = useState<string>(props.value);

  useEffect(() => {
    setKeyword(props.value);
  }, [props.value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value);
    if (e.target.value === '') {
      props.handleSearch(e.target.value);
    }
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      props.handleSearch(keyword);
    }
  };

  return (
    <fieldset className="sch-w">
      <legend>검색</legend>

      <input
        type="search"
        placeholder="검색"
        value={keyword}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {keyword.length > 0 && (
        <button
          type="button"
          className="btn-del"
          onClick={() => {
            setKeyword('');
            props.handleSearch('');
          }}
        >
          <span>검색어 삭제</span>
        </button>
      )}

      <button type="submit" onClick={e => props.handleSearch(keyword)}>
        <span>검색</span>
      </button>
    </fieldset>
  );
};

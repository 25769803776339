import React, { useEffect } from 'react';

export interface IToastProps {
  isShow?: boolean;
  isNagative?: boolean;
  manualClose?: boolean;
  message: string;
  onClose?: () => void;
}

/*
 * 3초 뒤 자동으로 close 처리
 * 수동으로 close 처리 시, manualClose true로 세팅.
 */
export const Toast: React.SFC<IToastProps> = props => {
  useEffect(() => {
    if (!props.manualClose) {
      setTimeout(() => props.onClose && props.onClose(), 2000);
    }
  }, [props.message]);

  return (
    <div
      className="toast-noti"
      style={{ display: props.isShow ? 'flex' : 'none' }}
    >
      <p className={props.isNagative ? 'nagative' : ''}>
        <span>{props.message}</span>
      </p>
    </div>
  );
};

import React, { FC } from 'react';
import styled from 'styled-components/macro';

import { PALETTE } from '../../../constants/color';
import { EProjectStatus } from '../../../store/Dashboard/constants';

const DEFAULT_BACKGROUND_COLOR = PALETTE['color-disabled'];
const DEFAULT_COLOR = '#ffffff';
const Wrapper = styled.div<{
  backgroundColor: string;
  borderColor?: string;
  color?: string;
  fontWeight?: string | number;
}>`
  width: fit-content;
  // height: 28px;
  line-height: 28px;
  border-radius: 14px;
  font-size: 14px;
  font-weight: ${p => (p.fontWeight ? p.fontWeight : 'bold')};
  background-color: ${p =>
    p.backgroundColor ? p.backgroundColor : DEFAULT_BACKGROUND_COLOR};
  color: ${p => (p.color ? p.color : DEFAULT_COLOR)};
  padding: 0px 12px;
  ${p => (p.borderColor ? `border: solid 1px ${p.borderColor};` : ``)};
`;

const Status: FC<{
  backgroundColor: string;
  color?: string;
  fontWeight?: string | number;
  title: string;
}> = props => {
  const { title, ...style_props } = props;
  return <Wrapper {...style_props}>{title}</Wrapper>;
};

const EndStatus: FC<{ title: string }> = ({ title }) => {
  const newProps = {
    backgroundColor: PALETTE['color-disabled'],
    title: title,
  };
  return <Status {...newProps} />;
};

const OngoingStatus: FC<{}> = () => {
  const newProps = {
    backgroundColor: PALETTE['color-emerald'],
    color: '#000000',
    title: EProjectStatus.ONGOING,
  };
  return <Status {...newProps} />;
};

const HoldingStatus: FC<{}> = () => {
  const newProps = {
    backgroundColor: '#b3ff00',
    color: '#000000',
    title: EProjectStatus.HOLDING,
  };
  return <Status {...newProps} />;
};

const ScheduleStatus: FC<{}> = () => {
  const newProps = {
    backgroundColor: '#ffffff',
    color: '#666666',
    title: EProjectStatus.SCHEDULE,
    borderColor: '#979797',
    fontWeight: 500,
  };
  return <Status {...newProps} />;
};

const WaitingStatus: FC<{ title: string }> = ({ title }) => {
  const newProps = {
    backgroundColor: '#ffe007',
    color: '#000000',
    title: title,
  };
  return <Status {...newProps} />;
};

const AcceptStatus: FC<{ title: string }> = ({ title }) => {
  const newProps = {
    backgroundColor: '#6e019d',
    color: '#ffffff',
    title: title,
  };
  return <Status {...newProps} />;
};

const FlawingStatus: FC<{ title: string }> = ({ title }) => {
  const newProps = {
    backgroundColor: '#b63e92',
    color: '#ffffff',
    title: title,
  };
  return <Status {...newProps} />;
};

const ProjectStatusTag: FC<{
  className?: string;
  isHolding?: boolean;
  status: EProjectStatus;
}> = props => {
  let comp = <EndStatus title={''} />;

  switch (props.status) {
    case EProjectStatus.HOLDING:
      comp = <HoldingStatus />;
      break;
    case EProjectStatus.ONGOING:
      comp = <OngoingStatus />;
      break;
    case EProjectStatus.END:
      comp = <EndStatus title={props.status} />;
      break;
    case EProjectStatus.ENDHOLD:
      comp = <EndStatus title={props.status} />;
      break;
    case EProjectStatus.KEEP_ACCEPT:
      comp = <AcceptStatus title={props.status} />;
      break;
    case EProjectStatus.FLAW_ACCEPT:
      comp = <AcceptStatus title={props.status} />;
      break;
    case EProjectStatus.KEEPING:
      comp = <FlawingStatus title={props.status} />;
      break;
    case EProjectStatus.FLAWING:
      comp = <FlawingStatus title={props.status} />;
      break;
    case EProjectStatus.SCHEDULE:
      comp = <ScheduleStatus />;
      break;
    case EProjectStatus.MATCHING:
      comp = <WaitingStatus title={props.status} />;
      break;
    case EProjectStatus.WAITING:
      comp = <WaitingStatus title={props.status} />;
      break;
    default:
  }

  if (
    props.isHolding &&
    (props.status === EProjectStatus.ONGOING ||
      props.status === EProjectStatus.SCHEDULE ||
      props.status === EProjectStatus.MATCHING ||
      props.status === EProjectStatus.WAITING)
  ) {
    comp = <HoldingStatus />;
  }

  return <div className={props.className}>{comp}</div>;
};

export default ProjectStatusTag;

import styled from 'styled-components/macro';
import { PALETTE } from '../../../constants/color';
import { PC } from '../../../constants/media';
import BackSvg from '../../../static/icons/icon-back-btn-blue.svg';
import BackHoverSvg from '../../../static/icons/icon-back-hover-blue.svg';

import MoveSvg from '../../../static/icons/icon-movearrow-blue.svg';
import ViewArrowSvg from '../../../static/icons/icon-viewarrow-black.svg';

export const Wrapper = styled.div`
  height: 190px;
  padding: 0px 110px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media only screen and (max-width: ${PC}) {
    margin: 0px;
    margin-top: 30px;
    height: fit-content;
    padding: 0px 16px 0px 16px;
  }
`;
export const Body = styled.div`
  display: flex;
  margin-top: 20px;
  height: 137px;

  & > div {
    margin-left: 30px;
  }
  & > div:first-child {
    margin-left: 0px;
  }

  @media only screen and (max-width: ${PC}) {
    display: block;
    height: fit-content;

    & > div {
      margin: 0px;
      margin-top: 20px;
    }
  }
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: 33px;
  line-height: 33px;
  font-size: 16px;
  font-weight: 500;

  @media only screen and (max-width: ${PC}) {
    display: block;
    height: fit-content;
    position: relative;
  }
`;

export const BackButton = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 3px;
  object-fit: contain;
  cursor: pointer;
  background: url(${BackSvg}) no-repeat center center;

  :hover {
    background: url(${BackHoverSvg}) no-repeat center center;
  }
`;

export const HeaderLeftSection = styled.div`
  display: flex;

  @media only screen and (max-width: ${PC}) {
    display: block;
  }
`;

export const HeaderRightSection = styled.div`
  display: flex;

  position: relative;
  margin-right: 20px;

  .inspection-spinner {
    margin-right: 5px;
    width: inherit;
    min-height: auto;
  }

  @media only screen and (max-width: ${PC}) {
    position: absolute;
    bottom: 0;
    right: 16px;
  }
`;

export const ProjectName = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: ${PALETTE['purpleish-blue']};
  margin-right: 20px;
  margin-left: 8px;

  @media only screen and (max-width: ${PC}) {
    margin: 0px;
  }
`;

export const InspectionLink = styled.div`
  font-weight: bold;
  cursor: pointer;
  &::after {
    content: '';
    background: url('${ViewArrowSvg}') no-repeat center center;
    width: 15px;
    height: 15px;
    position: absolute;
    right: -20px;
    top: 8px;
  }
`;

export const Box = styled.div`
  padding: 15px 23px 20px 24px;
  height: 137px;
  border: solid 1px ${PALETTE['very-light-pink-three']};
  border-radius: 10px;
  position: relative;
  // & + & {
  //   margin-left: 40px;
  // }

  .date {
    font-size: 14px;
    margin-left: 12px;
    color: ${PALETTE['black-two']};
  }
  .schedule-date {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #000000;
    margin-top: 15px;
    span + span {
      margin-left: 3px;
    }

    @media only screen and (max-width: ${PC}) {
      margin-top: 15px;
    }
  }
  .status-count {
    display: flex;
    margin-top: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    .count + .count {
      margin-left: 20px;
    }
    .scheduled {
      font-weight: bold;
      color: #f0b500;
      margin-left: 5px;
    }
    .ongoing {
      font-weight: bold;
      color: #00ba90;
      margin-left: 5px;
    }
    .done {
      font-weight: bold;
      color: ${PALETTE['brownish-grey']};
      margin-left: 5px;
    }
  }

  .sprint-bar {
    margin-top: 15px;
  }

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin: 0px;
    padding: 15px 15px 25px 15px;
    & + & {
      margin: 0px;
    }
  }
`;

export const BoxTitle = styled.div`
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 17px;

  &.today {
    display: flex;
  }
`;

export const TodayWrapper = styled(Box)`
  min-width: 290px;
`;
export const SprintStatusWrapper = styled(Box)`
  width: 444px;

  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: 134px;
    margin-top: 20px !important;
  }
`;
export const ToolWrapper = styled(Box)`
  width: 426px;
  div:first-child {
    margin-bottom: 25px;
  }
  @media only screen and (max-width: ${PC}) {
    width: 100%;
    height: 110px;
    margin-top: 20px !important;
    div:first-child {
      margin-bottom: 18px;
    }
  }
`;

export const BoxRow = styled.div`
  display: flex;
  position: relative;
  line-height: 28px;
  * + * {
    margin-left: 15px;
  }

  @media only screen and (max-width: ${PC}) {
    .remainedAccept {
      position: absolute;
      top: 28px;
      margin-left: 0px;
      margin-top: 6px;
    }
  }
`;

export const RequestLink = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${PALETTE['purpleish-blue']};

  position: relative;
  cursor: pointer;
  &::after {
    content: '';
    background: url('${MoveSvg}') no-repeat center center;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 8px;
    left: 76px;
  }
`;

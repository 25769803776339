import { types } from 'mobx-state-tree';

export const TaskLabelModel = types.model('TaskLabel', {
  id: types.number,
  title: types.string,
  task: types.string,
  task_label: types.string,
});
type TaskLabelType = typeof TaskLabelModel.Type;

export interface TaskLabel extends TaskLabelType {}
